import { default as axios, default as Axios } from 'axios'
import Cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { User } from 'shared'

const apiAddress = process.env.REACT_APP_API_ADDRESS || ''

export const unsetToken = (): void => {
  Cookie.remove('jwt')

  // to support logging out from all windows
  //window.localStorage.setItem("logout", Date.now());
  //Router.push("/");
}

export const getUserFromCookie = (): Pick<User, 'cardmarketUsername' | 'username'> & {
  userID: string
} => {
  if (Cookie.get('jwt')) {
    const { sub, username } = jwtDecode(String(Cookie.get('jwt')))
    return {
      cardmarketUsername: Cookie.get('cardmarketUsername') || '',
      userID: sub,
      username,
    }
  }

  return { username: '', cardmarketUsername: '', userID: '' }
}

type LoginCredentials = {
  accessToken: string
}

export const loginUser = async (credentials: LoginCredentials): Promise<void> => {
  return await axios
    .post(`${apiAddress}/api/auth/login`, credentials, { withCredentials: true })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const logoutUser = async (): Promise<void> => {
  unsetToken()
  localStorage.clear()
}

export const connectCardMarket = async (requestToken: string): Promise<User> => {
  return await Axios.post(
    `${apiAddress}/api/user/cardmarket/account`,
    { requestToken },
    { withCredentials: true }
  )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}
