import { PricingSettingsPresenter } from 'components/domain/stockPricing/PricingSettings/PricingSettingsPresenter'
import { AppLayout } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const PricingSettings = (): JSX.Element => {
  const { user } = useUser()

  if (user?.subscription.allFeaturesDisabled) {
    return <Redirect to="/" />
  }

  return (
    <AppLayout noDOMLayout>
      <PricingSettingsPresenter />
    </AppLayout>
  )
}
