import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export interface TabBoxProps {
  active: boolean
  text: string
  count: number
  selectedCount: number
  onClick: () => void
  children: React.ReactNode
}

export const TabBox = ({
  active,
  text,
  count,
  onClick,
  selectedCount,
  children,
  ...rest
}: TabBoxProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      marginRight={1}
      padding={0.5}
      position="relative"
      minWidth="80px"
      onClick={onClick}
      style={{
        backgroundColor: active ? 'white' : '#ffffff77',
        cursor: active ? 'initial' : 'pointer',
        borderRadius: '6px',
        height: 'auto',
        textAlign: 'center',
        border: text === 'edited' ? '2px solid #d415c2' : '',
      }}
      {...rest}
    >
      <Box padding={1} minWidth="90px" marginRight={active ? 1 : 0}>
        <Typography
          style={{ fontWeight: active ? 'bold' : 'initial', marginTop: '1rem' }}
          color={active ? 'primary' : 'initial'}
          data-testid="count-articles"
        >
          {count} {t(`inventory.tabs.${text}`)}
        </Typography>
      </Box>
      {active && <>{children}</>}
    </Box>
  )
}
