import { TextFieldProps } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { useEffect, useState } from 'react'

const commaRegex = /^\d+(,\d{0,2})?$/
const dotRegex = /^\d+(\.\d{0,2})?$/

const NumberField = ({ value, onChange, ...otherProps }: TextFieldProps): JSX.Element => {
  const [stringValue, setStringValue] = useState(`${value}`)
  const onChangeTextField = (event: any): void => {
    if (onChange && (commaRegex.test(event.target.value) || dotRegex.test(event.target.value))) {
      setStringValue(event.target.value)
      event.target.value = Number(event.target.value.replace(',', '.'))
      onChange(event)
    }
  }

  useEffect(() => {
    if (value != Number(stringValue.replace(',', '.'))) setStringValue(`${value}`)
  }, [value])

  return <TextField {...otherProps} value={stringValue} onChange={onChangeTextField} />
}

export default NumberField
