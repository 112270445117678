import { Box, Drawer, Typography } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { CloseIcon, CustomTooltip } from 'components/facets'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InventoryArticle, roundTo2Decimals } from 'shared'
import { InventoryPresenter } from '../..'

interface AffectedStockPresenterProps {
  affectedStock: InventoryArticle[]
  totalStockCount: number
}

export const AffectedStockPresenter = ({
  affectedStock,
  totalStockCount,
}: AffectedStockPresenterProps): JSX.Element => {
  const [cardPreviewOpen, setCardPreviewOpen] = useState(false)
  const { t } = useTranslation()

  const [stockState, setStockState] = useState({
    stock: affectedStock,
    sealedStock: [] as InventoryArticle[],
    fetchedAt: new Date().toLocaleString('sk-SK'),
  })

  useEffect(() => {
    setStockState({
      stock: affectedStock,
      sealedStock: [] as InventoryArticle[],
      fetchedAt: new Date().toLocaleString('sk-SK'),
    })
  }, [affectedStock])

  const extraActions = useMemo(
    () => [
      {
        Icon: CloseIcon,
        fn: () => setCardPreviewOpen(false),
        tooltip: () => <>{t('common.close')}</>,
        actionLabel: t('common.close'),
        key: 'close-stock-drawer-btn',
      },
    ],
    [setCardPreviewOpen]
  )

  return (
    <>
      <CustomTooltip
        title={
          <Box>
            <Typography variant="caption">{t('priceUpdates.countExplainer')}</Typography>
            {affectedStock.length > 1 && (
              <Typography variant="caption" display="block">
                <b>{t('priceUpdates.clickToSee')}</b>
              </Typography>
            )}
          </Box>
        }
      >
        <Box
          style={{ cursor: affectedStock.length ? 'pointer' : 'initial' }}
          onClick={() => setCardPreviewOpen(Boolean(affectedStock.length && true))}
        >
          <Box paddingTop={'16px'}>
            {!!totalStockCount && (
              <Typography color={'primary'} variant="caption" display="block">
                <b>{affectedStock.length}</b>
              </Typography>
            )}
            <ArrowRightAltIcon
              style={{
                fontSize: '64px',
                marginTop: '-22px',
                marginBottom: '-24px',
                marginLeft: '4px',
              }}
              color="primary"
            />
            {!!totalStockCount && (
              <Typography color="textSecondary" variant="caption" display="block">
                {(function () {
                  // body of the function
                  if (affectedStock.length === 0) return '0%'
                  const percentage = roundTo2Decimals(
                    (affectedStock.length / totalStockCount) * 100
                  )
                  if (percentage > 0.1) return `${percentage}%`
                  return `<0.1%`
                })()}
              </Typography>
            )}
          </Box>
        </Box>
      </CustomTooltip>

      <Drawer open={cardPreviewOpen} anchor="right" onClose={() => setCardPreviewOpen(false)}>
        {cardPreviewOpen && (
          <InventoryPresenter
            tabs={['articles']}
            determineTab={() => 'articles'}
            splitStockByTab={(stock) => ({ articles: stock })}
            emptyStockText="placeholder"
            stockState={stockState}
            setStockState={setStockState}
            viewOnly
            hideSettingsButton
            hideExportButton
            extraDockActions={extraActions}
          />
        )}
      </Drawer>
    </>
  )
}
