import { useUser } from 'components/providers/UserProvider'
import { localDB } from 'config/localforage'
import { useCallback, useState } from 'react'

export const useLazyForageState = <T>(
  defaultValue: T,
  name: string,
  config: {
    duckFunction?: (value: T) => void
    hydrate?: (value: T) => T
    lazy?: boolean
  } = {}
): readonly [T | null, (newValue: T | ((arg: T) => T)) => void, boolean, () => void] => {
  const { hydrate, duckFunction, lazy } = config
  const [value, setValue] = useState<T | null>(null)
  const [loading, setLoading] = useState(lazy ? false : true)
  const { activeGame } = useUser()

  const loadValues = useCallback(() => {
    setLoading(true)
    localDB.getItem(`${activeGame.idGame}-${name}`).then((value) => {
      try {
        //@ts-ignore
        duckFunction && duckFunction(value)
        const hydrated = hydrate ? hydrate(value as T) : value
        setValue((hydrated as T) || defaultValue)
      } catch (err) {
        //@ts-ignore
        setValue(defaultValue)
      }
      setLoading(false)
    })
  }, [setLoading, setValue, activeGame])

  const setLocalForageState = useCallback((newValue): void => {
    if (typeof newValue === 'function') {
      setValue((val) => {
        localDB.setItem(`${activeGame.idGame}-${name}`, newValue(val))
        return newValue(val)
      })
    } else {
      localDB.setItem(`${activeGame.idGame}-${name}`, newValue)
      setValue(newValue)
    }
  }, [])

  return [value, setLocalForageState, loading, loadValues]
}
