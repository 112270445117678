import { SignInPresenter } from 'components/domain/auth'
import { AuthLayout } from 'components/facets'

export const SignIn = (): JSX.Element => {
  return (
    <AuthLayout>
      <SignInPresenter />
    </AuthLayout>
  )
}
