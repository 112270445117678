import { AppLayout } from 'components/facets'
import { DebugPresenter } from '../domain/debug'

export const Debug = (): JSX.Element => {
  return (
    <AppLayout noDOMLayout>
      <DebugPresenter />
    </AppLayout>
  )
}
