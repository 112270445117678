import { Radio as MuiRadio, RadioProps } from '@material-ui/core'
import { theme } from 'config'

export const RadioButton = (props: RadioProps): JSX.Element => {
  return (
    <MuiRadio
      {...props}
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        color: `${theme.palette.primary.main}`,
      }}
    />
  )
}
