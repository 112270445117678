import { Box, IconButton, Typography, useTheme } from '@material-ui/core'
import { Button, CloseIcon } from 'components/facets'
import { SnackbarProps, useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorageState } from '.'

export function useIntroSnackbar(
  key: string,
  content: string | JSX.Element,
  props?: SnackbarProps
) {
  // State and setters for debounced value

  const [shouldOpen, setShouldOpen] = useLocalStorageState(true, `intro-${key}`, {
    gameSpecific: false,
  })

  const { palette } = useTheme()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { t } = useTranslation()
  const Message = () => {
    const [minimized, setMinimized] = useState(false)

    return (
      <Box color="black" maxWidth="50vw">
        {!minimized && (
          <Box padding={1} paddingBottom={0}>
            {content}{' '}
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Button
            data-testid={`${key}-dont-show-again`}
            onClick={() => {
              setShouldOpen(false)
              closeSnackbar(key)
            }}
          >
            <Typography variant="caption" color="primary">
              {t('common.dontShowAgain')}
            </Typography>
          </Button>{' '}
          <Button
            onClick={() => {
              setMinimized((minimzed) => !minimzed)
            }}
          >
            <Typography variant="caption" color="primary">
              {minimized ? t('common.open') : t('common.minimize')}
            </Typography>
          </Button>
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    if (shouldOpen) {
      enqueueSnackbar(<Message />, {
        ...props,
        persist: true,
        key,
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        ),
        ContentProps: { style: { backgroundColor: palette.lightBackground } },
      })
    }
  }, [shouldOpen])

  useEffect(() => {
    return () => closeSnackbar(key)
  }, [])
}
