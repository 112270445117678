import { Box, Typography } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ClosableDialog } from 'components/controls/dialogs'
import { Button, CircularLoading, LocalArticle } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { publishArticles } from 'lib/api'
import { useErrorHandler } from 'lib/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InventoryArticle, RichArticle } from 'shared'
import { SetState } from 'types'
import cardmarketError from 'utils/cardmarketError'

interface PublishDialogProps {
  articles: InventoryArticle[]
  onArticlesPublished: (failed: InventoryArticle[]) => void
  open: boolean
  setOpen: SetState<boolean>
}

export const PublishDialog = ({
  open,
  articles,
  onArticlesPublished,
  setOpen,
}: PublishDialogProps): JSX.Element => {
  const { user, setUser, activeGame } = useUser()
  const [articlesAddedCount, setArticlesAddedCount] = useState(0)
  const [failures, setFailures] = useState<{ article: RichArticle; error: string }[]>([])
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { handle } = useErrorHandler()
  const [progress, setProgress] = useState(0)

  if (!user) return <></>

  const handleClose = (): void => {
    setArticlesAddedCount(0)
    setOpen(false)
  }

  const handlePublish = async (): Promise<void> => {
    setLoading(true)
    try {
      const response = await publishArticles(
        articles,
        setProgress,
        activeGame.idGame,
        user.cardmarketUsername
      )

      const failed = response.failed

      setArticlesAddedCount(response.insertedCount)
      setUser({
        ...user!,
        articlesPublishedToday: (user.articlesPublishedToday || 0) + response.insertedCount,
      })

      setFailures([...failed])
      onArticlesPublished([...failed.map(({ article }) => article)])
    } catch (err) {
      handle(err)
    }

    setLoading(false)
  }

  return (
    <ClosableDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      hideButton={true}
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
        {t('publish.newArticles')}
      </DialogTitle>
      {articlesAddedCount || failures.length ? (
        <>
          <DialogContent>
            {articlesAddedCount ? (
              <Typography variant="body2">
                {t('publish.successAdded', { count: articlesAddedCount })}
              </Typography>
            ) : null}
            {failures.length ? (
              <>
                <Typography variant="h6">{t('publish.error')}</Typography>
                <Typography variant="body2">{t('publish.errorHelper')}</Typography>
                {failures.map(({ article, error }) => (
                  <>
                    <LocalArticle article={article} />
                    <Typography variant="body2">{cardmarketError(error)}</Typography>
                  </>
                ))}
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} color="primary">
              {t('common.close')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            {/*{true ? (*/}
            {loading ? (
              <Box
                height="250px"
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {progress === 100 ? (
                  'Finishing...'
                ) : (
                  <>
                    <CircularLoading value={progress} />

                    <Box marginTop={5}>
                      <Typography>{t('publish.pricingBotWarning1')}</Typography>
                      <Typography>{t('publish.pricingBotWarning2')}</Typography>
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <>
                <Box style={{ textAlign: 'center' }}>
                  {t('publish.confirmAdd', {
                    count: articles.length,
                    accountName: user.cardmarketUsername,
                  })}
                </Box>
              </>
            )}
          </DialogContent>
          <Box height="1rem"></Box>
          <DialogActions>
            {!loading && (
              <>
                <Button variant="outlined" onClick={handleClose} color="primary" disabled={loading}>
                  {t('common.close')}
                </Button>
                <Button
                  data-testid="start-publish-btn"
                  variant="contained"
                  onClick={() => handlePublish()}
                  tooltip={
                    user.subscription.dailyArticlePublishLimit &&
                    user.articlesPublishedToday + articles.length >
                      user.subscription.dailyArticlePublishLimit
                      ? t('addArticles.articlesPublishedOverLimit', {
                          count:
                            user.subscription.dailyArticlePublishLimit -
                            user.articlesPublishedToday,
                        })
                      : ''
                  }
                  disabled={
                    loading ||
                    (!!user.subscription.dailyArticlePublishLimit &&
                      user.articlesPublishedToday + articles.length >
                        user.subscription.dailyArticlePublishLimit)
                  }
                  color="primary"
                >
                  {loading ? t('publish.publishing') : t('common.publish')}
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </ClosableDialog>
  )
}
