import { AppLanguage, Card, CardDataItem, Game, LocalizedCard } from 'shared'

const stripDiacritics = (string: string): string => {
  // normalize to UTF decomposition, then remove diacritics
  if (string.normalize) string.normalize('NFD')
  return string.replace(/[\u0300-\u036f]/g, '')
}

const normalizeCardinfo = (string: string): string => {
  if (!string) return ''
  // 0) strip diacritics
  // 1) turn lowercase
  // 4) remove everything except letters, numbers
  return stripDiacritics(string)
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '')
}

const normalizeName = (name: string): [string, string[]] => {
  if (!name) return ['', []]
  // 0) strip diacritics
  // 1) turn lowercase
  // 2) normalize special characters
  // 3) remove everything except letters, numbers
  const transformed = stripDiacritics(name)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[^a-z0-9 ]/g, '')

  return [transformed.replace(/ /g, ''), transformed.split(' ')]
}

const cardLabel = (card: Card, rarities: Partial<Game['rarities']> = {}): string => {
  const rarityLabel = rarities[card.rarity]?.filterLabel || ''
  if (card.name && card.set) {
    return `${card.name} ${card.set} ${rarityLabel}`
  } else {
    return ''
  }
}

function startsWith(a: string, b: string): boolean {
  if (!b) return false
  return a.lastIndexOf(b, 0) === 0
}

const sortByLabelReversed = (card1: LocalizedCard, card2: LocalizedCard, query: string): number => {
  //lukas: first display those that start with the query, then according to the following:
  // sort cards reverse lexicographical by name + set
  // this is done so that they show up lexicographically from left to right
  const label1 = cardLabel(card1)
  const label2 = cardLabel(card2)

  const label1startsWithQuery = normalizeCardinfo(label1).startsWith(query)
  const label2startsWithQuery = normalizeCardinfo(label2).startsWith(query)

  if (label1startsWithQuery < label2startsWithQuery) {
    return 1
  } else if (label1startsWithQuery > label2startsWithQuery) {
    return -1
  } else {
    if (label1 < label2) {
      return -1
    } else if (label1 > label2) {
      return 1
    } else {
      return 0
    }
  }
}

export const filterOptions = function (
  options: LocalizedCard[],
  query: string,
  language: AppLanguage = 'en'
): CardDataItem[] {
  const normalizeInput = (
    string: string
  ): {
    exactMatch: boolean
    firstPart: string
    finalPart: string
    combined: string
    firstWord: string
  } => {
    const EXACT_CHAR = '!'
    const exactMatch = string.indexOf(EXACT_CHAR) !== -1
    // 0) strip diacritics
    // 1) turn lowercase
    // 2) turn all whitespaces (one or more) into a single space
    // 3) normalize special characters
    // 4) remove everything except letters, numbers, spaces. Also leading & trailing spaces.
    const normalized = stripDiacritics(string)
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .normalize('NFD')
      .replace(/[^a-z0-9 ]|^ | $/g, '')
    const split = normalized.split(' ')
    let firstPart
    let finalPart
    if (split.length === 1) {
      firstPart = split[0]
      finalPart = ''
    } else {
      firstPart = split.slice(0, -1).join('')
      finalPart = split[split.length - 1]
    }
    return {
      exactMatch: exactMatch,
      firstPart: firstPart,
      finalPart: finalPart,
      combined: firstPart + finalPart,
      firstWord: split[0],
    }
  }

  const normalizedInput = normalizeInput(query)
  const { exactMatch, firstPart, finalPart, combined, firstWord } = normalizedInput

  if (combined.length <= 2) return []

  const match = (card: LocalizedCard): boolean => {
    const [name, wordsInName] = normalizeName(card.localizedName(language))

    const set = normalizeCardinfo(card.set)
    let nr = null
    if (typeof card.cn !== 'undefined' && card.cn) {
      nr = normalizeCardinfo(card.cn.toString().padStart(3, '0'))
    }

    const setNr = set + (nr || '')
    const nrName = (nr || '') + name
    if (exactMatch) {
      return (
        (nr && setNr === combined) ||
        name === combined ||
        (name === firstPart && startsWith(setNr, finalPart))
      )
    } else {
      return (
        (nr && startsWith(setNr, combined)) || //wwk 031
        (nr && startsWith(nrName, combined)) || //031 jace
        startsWith(set + name, combined) || // wwk jace
        (wordsInName.some((word) => startsWith(word, firstWord)) &&
          (name.includes(combined) || // mind sculptor
            (startsWith(setNr, finalPart) && name.includes(firstPart)))) || // mind sculptor wwk
        startsWith(name, combined) // jacethemindsculptor
      )
    }
  }
  const limit = 15
  const results = []
  for (const card of options) {
    if (match(card)) {
      results.push(card)
      if (results.length >= limit) {
        break
      }
    }
  }
  results.sort((a, b) => sortByLabelReversed(a, b, firstPart))

  const cardDataItems = results.map((card) => card.toPlainObject())

  return cardDataItems
}
