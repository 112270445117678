import { ListingAndAppraisalPresenter } from 'components/domain/csv'
import { AppLayout } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const ListingAndAppraisal = (): JSX.Element => {
  const { user } = useUser()

  if (user?.subscription.allFeaturesDisabled || user?.featureFlags?.banned) {
    return <Redirect to="/" />
  }

  return (
    <AppLayout noDOMLayout>
      <ListingAndAppraisalPresenter />
    </AppLayout>
  )
}
