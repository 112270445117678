import localForage from 'localforage'
import { useEffect, useState } from 'react'
import { SetState } from 'types'

const store = localForage.createInstance({ name: 'csvHeaders' })

const useHeaderColumnNamesState = (
  header: Array<string>,
  initialState: Array<string>
): [Array<string>, SetState<string[]>, () => void] => {
  const [headerColumnNames, setHeaderColumnNames] = useState(initialState)
  const key = header.join('-')

  useEffect(() => {
    store
      .getItem(key)
      .then((value) => {
        if (value) setHeaderColumnNames(value as string[])
      })
      .catch(console.log)
  }, [key])

  const saveHeaderColumnNames = (): void => {
    store.setItem(key, headerColumnNames).then(null)
  }

  return [headerColumnNames, setHeaderColumnNames, saveHeaderColumnNames]
}

export default useHeaderColumnNamesState
