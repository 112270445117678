import { LoadingScreen } from 'components/facets/LoadingScreen'
import { cardDataDB } from 'config/localforage'
import { getCurrencyRate, getPriceGuideMap } from 'lib/api'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import React, { useCallback, useContext, useEffect } from 'react'
import { AppCurrency, CardPriceGuide, CardPriceGuideAttributes, PriceGuide } from 'shared'
import { ElementWithChildren } from 'types'
import { useUser } from './UserProvider'

const PriceGuideContext = React.createContext<{
  getPriceGuide: (id: number) => PriceGuide
}>({ getPriceGuide: () => new CardPriceGuide({ priceGuide: {} }) })

export const usePriceGuide = (): { getPriceGuide: (id: number) => PriceGuide } => {
  const value = useContext(PriceGuideContext)

  if (!value) throw new Error('usePriceGuide was called outside of priceguide context provider')

  return value
}

type PriceGuideMap = Record<number, CardPriceGuideAttributes>

const convertPriceGuideMap = async (
  priceGuideMap: PriceGuideMap,
  from: AppCurrency,
  to: AppCurrency
): Promise<PriceGuideMap> => {
  const response = await getCurrencyRate(from, to)

  if (!response.rate) console.error(`could not get rate from ${from} to ${to}`)
  else {
    Object.keys(priceGuideMap).forEach((key) => {
      priceGuideMap[Number(key)] = new CardPriceGuide({
        attributes: priceGuideMap[Number(key)],
      }).convert(response.rate!).attributes
    })
  }

  return priceGuideMap
}

export const PriceGuideContextProvider = ({ children }: ElementWithChildren): JSX.Element => {
  const { activeGame, user } = useUser()

  const [priceGuideState, setPriceGuideState, loadingFromLocal] = useLocalForageState<{
    priceGuideMap: PriceGuideMap | null
    version?: string
    currency: AppCurrency
  }>({ priceGuideMap: null, currency: 'EUR' }, `priceGuide`, { customLocalDB: cardDataDB })

  useEffect(() => {
    if (!user?.username) return
    const update = async () => {
      try {
        if (!loadingFromLocal) {
          const response = await getPriceGuideMap(activeGame.idGame, priceGuideState.version)
          if (response?.clientIsUpToDate) return

          let priceGuideMap = response.resourceData

          if (user?.currency !== 'EUR') {
            priceGuideMap = await convertPriceGuideMap(priceGuideMap, 'EUR', user!.currency)
          }
          setPriceGuideState({
            priceGuideMap,
            version: response.version,
            currency: user!.currency,
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
    update()

    const interval = setInterval(update, 1000 * 60 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [loadingFromLocal, user?.username])

  useEffect(() => {
    const convert = async () => {
      if (user?.currency !== priceGuideState.currency && priceGuideState.priceGuideMap) {
        const priceGuideMap = await convertPriceGuideMap(
          priceGuideState.priceGuideMap,
          priceGuideState.currency,
          user!.currency
        )
        setPriceGuideState({
          priceGuideMap,
          version: priceGuideState.version,
          currency: user!.currency,
        })
      }
    }
    convert()
  }, [user?.currency, priceGuideState.priceGuideMap])

  const getPriceGuide = useCallback(
    (id: number): PriceGuide => {
      const attributes = priceGuideState?.priceGuideMap?.[id]
      if (!attributes) return new CardPriceGuide({ priceGuide: {} }).toPlainObject()
      return new CardPriceGuide({ attributes }).toPlainObject()
    },
    [priceGuideState]
  )

  if (!priceGuideState.priceGuideMap) return <LoadingScreen />

  return (
    <PriceGuideContext.Provider
      value={{
        getPriceGuide,
      }}
    >
      {children}
    </PriceGuideContext.Provider>
  )
}
