import { Box, BoxProps, IconButton, Paper, SvgIconTypeMap, useTheme } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { CheckCircle } from '@material-ui/icons'
import { CustomTooltip } from 'components/facets'
import { CloneIcon, DeleteIcon } from 'components/facets/Icons'
import { useConfirm } from 'lib/hooks'
import { useTranslation } from 'react-i18next'

export interface EntityBoxProps {
  children?: React.ReactNode
  contentRow: React.ReactNode
  titleRow?: React.ReactNode | null
  onDelete?: () => void
  onClone?: () => void
  viewOnly?: boolean
  disabled?: boolean
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> | null
  dataTestid?: string
  outerBoxProps?: Partial<BoxProps>
  innerBoxProps?: Partial<BoxProps>
  containerProps?: Partial<BoxProps>
  competitorOrStrategy?: boolean
  bulkSettings?: boolean
  hideTitleUnderline?: boolean
  selected?: boolean
}

export const EntityBox = ({
  onDelete,
  contentRow,
  titleRow,
  Icon,
  viewOnly = false,
  children,
  dataTestid,
  disabled = false,
  outerBoxProps = {},
  innerBoxProps = {},
  competitorOrStrategy = false,
  bulkSettings = false,
  containerProps = {},
  onClone,
  hideTitleUnderline = false,
  selected = false,
}: EntityBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const [confirmDelete, ConfirmDeleteDialog] = useConfirm(
    t('common.confirmDelete'),
    '',
    () => onDelete && onDelete()
  )
  const { palette } = useTheme()

  const competitorAndStrategyProps = {
    outerBox: {
      maxWidth: '480px',
      margin: 1,
      marginBottom: 3,
    },
    container: {
      padding: 1,
      paddingRight: 1.5,
      paddingLeft: 2,
      borderLeft: `3px solid ${disabled ? palette.lightGray : palette.primary.main}`,
      borderRadius: '5px',
      color: disabled ? palette.disabled : 'inherit',
    },
    innerBox: {
      minHeight: '120px',
    },
  }

  const bulkSettingsProps = {
    outerBox: {
      maxWidth: '100%',
      margin: 1,
      marginBottom: 3,
    },
    container: {
      padding: 1,
      paddingRight: 1.5,
      paddingLeft: 2,
      borderLeft: `3px solid ${disabled ? palette.lightGray : palette.primary.main}`,
      borderRadius: '5px',
      color: disabled ? palette.disabled : 'inherit',
    },
    innerBox: {
      minHeight: '120px',
    },
  }

  return (
    <Box
      {...(competitorOrStrategy
        ? competitorAndStrategyProps.outerBox
        : bulkSettings
        ? bulkSettingsProps.outerBox
        : {})}
      {...outerBoxProps}
    >
      <Paper elevation={1}>
        <Box
          //@ts-ignore
          borderLeft={`3px solid ${disabled ? palette.lightGray : palette.primary.main}`}
          //@ts-ignore
          borderRadius="5px"
          //@ts-ignore
          color={disabled ? palette.disabled : 'inherit'}
          //@ts-ignore
          padding={1}
          paddingBottom={2}
          {...(competitorOrStrategy ? competitorAndStrategyProps.container : {})}
          {...containerProps}
        >
          <Box
            display="flex"
            alignItems="center"
            {...(competitorOrStrategy ? competitorAndStrategyProps.innerBox : {})}
            {...innerBoxProps}
          >
            <Box>
              {onClone && (
                <Box marginRight={2} alignSelf="center">
                  {!viewOnly && (
                    <CustomTooltip title="Duplicate">
                      <IconButton onClick={onClone}>
                        <CloneIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </Box>
              )}
              {onDelete && (
                <Box marginRight={2} alignSelf="center">
                  {!viewOnly && (
                    <CustomTooltip title="Delete">
                      <IconButton onClick={confirmDelete} data-testid={`${dataTestid}`}>
                        <DeleteIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </Box>
              )}
            </Box>
            <Box width={'95%'}>
              {titleRow && (
                <Box
                  //marginBottom={1}
                  width={'fit-content'}
                  borderBottom={hideTitleUnderline ? '' : '1px solid #dbd7f3'}
                  display="flex"
                  alignItems="center"
                >
                  {titleRow}
                </Box>
              )}
              <Box height="70%" display="flex" alignItems="center">
                {contentRow}
              </Box>
            </Box>
            {Icon && (
              <Box>
                <Icon style={{ color: palette.lightBlue, fontSize: '2rem' }} />
              </Box>
            )}
            {competitorOrStrategy && selected && (
              <CheckCircle style={{ position: 'absolute', right: 10, bottom: 8 }} />
            )}
          </Box>
          {children && children}
        </Box>
      </Paper>
      <ConfirmDeleteDialog />
    </Box>
  )
}
