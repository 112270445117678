import { StockPricingPresenter } from 'components/domain/stockPricing/StockPricingPresenter'
import { AppLayout } from 'components/facets'

export const StockPricing = (): JSX.Element => {
  return (
    <AppLayout noDOMLayout>
      <StockPricingPresenter />
    </AppLayout>
  )
}
