import { Box, Typography } from '@material-ui/core'

export interface SidebarButtonProps {
  label: string
  subLabel?: string
  quantity?: number
  secondQuantity?: number
  secondLabel?: string
  selected: boolean
  onClick: () => void
}

export const SidebarButton = ({
  label,
  subLabel,
  quantity,
  secondQuantity,
  secondLabel,
  selected,
  onClick,
}: SidebarButtonProps): JSX.Element => {
  return (
    <>
      <Box
        display="flex"
        style={{ cursor: 'pointer', backgroundColor: selected ? 'white' : '' }}
        onClick={onClick}
      >
        <Box
          style={{
            minWidth: '3px',
            maxWidth: '3px',
            minHeight: '60px',
            maxHeight: '60px',
            backgroundColor: 'grey',
          }}
        ></Box>
        <Box alignSelf="center" marginLeft="5px">
          <Typography variant="body1">
            <strong>{label}</strong>
          </Typography>
          <Typography variant="subtitle2">
            {quantity} {subLabel}
          </Typography>
          {secondLabel && (
            <Typography variant="subtitle2">
              ({secondQuantity} {secondLabel})
            </Typography>
          )}
        </Box>
      </Box>
    </>
  )
}
