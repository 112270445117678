import { Box, Paper, SvgIconTypeMap, Typography, useTheme } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { CheckCircle } from '@material-ui/icons'
import { ClickableBox } from 'components/facets'
import { useTranslation } from 'react-i18next'

export interface OptionBoxProps {
  onClick: () => void
  title: string
  width?: string
  description?: string
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  recommended?: boolean
  iconSize?: string
  dataTestid?: string
  disabled?: boolean
  selected?: boolean
}

export const OptionBox = ({
  onClick,
  title,
  Icon,
  description,
  width = '300px',
  iconSize = '5rem',
  recommended = false,
  dataTestid,
  disabled,
  selected,
}: OptionBoxProps): JSX.Element => {
  const { palette } = useTheme()
  const { t } = useTranslation()
  const padding = 24

  return (
    <ClickableBox onClick={disabled ? undefined : onClick} margin={2}>
      <Paper elevation={2}>
        <Box
          style={{ padding: `${padding}px`, color: disabled ? palette.disabled : 'inherit' }}
          paddingBottom={5}
          textAlign="center"
          width={width}
          position="relative"
          margin="0 auto"
          data-testid={dataTestid}
        >
          <Typography align="center" variant="h6">
            {title}
          </Typography>
          {Icon && <Icon style={{ color: palette.primary.light, fontSize: iconSize }} />}
          <Typography gutterBottom variant="body1">
            {description}
          </Typography>
          {recommended && (
            <Box width={width} textAlign="center" position="absolute" bottom="5px" right={`0px`}>
              <Typography color="primary">{t('common.recommended')}</Typography>
            </Box>
          )}
          {selected && <CheckCircle style={{ position: 'absolute', right: 10, bottom: 8 }} />}
        </Box>
      </Paper>
    </ClickableBox>
  )
}
