import { Box, Button, Grid, Typography } from '@material-ui/core'
import { ClosableDialog } from 'components/controls/dialogs'
import { useUser } from 'components/providers/UserProvider'
import { getSubscriptionOffer, recordUsage, removeCancelSubscription } from 'lib/api'
import { useLocalStorageState } from 'lib/hooks'
import { ApiCallStatus, useApiCall } from 'lib/hooks/useApiCall'
import { getPriceFormat } from 'lib/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Activity,
  roundTo2Decimals,
  Subscription,
  SubscriptionId,
  SUBSCRIPTION_TIERS,
} from 'shared'
import { CancellationPresenter } from './CancellationPresenter'
import { ConfirmCancellationDialog } from './ConfirmCancellationDialog'
import { UpgradeSubscriptionPresenter } from './UpgradeSubscriptionPresenter'

interface SubscriptionPresenterProps {
  subscription: Subscription
  hideCancel?: boolean
}

const SubscriptionCostRequest = (): JSX.Element => {
  const apiCall = useApiCall(getSubscriptionOffer, [], true)
  const [lastRequest, setLastRequest] = useLocalStorageState<number>(
    0,
    'lastSubscriptionCostRequestTimestamp'
  )
  const { t } = useTranslation()

  const isOnCooldown = lastRequest + 1000 * 60 * 60 * 5 > Date.now()

  return (
    <Box padding={1}>
      {!isOnCooldown && [ApiCallStatus.Idle, ApiCallStatus.Fetching].includes(apiCall.status) && (
        <Button
          variant="contained"
          color="primary"
          disabled={apiCall.status === ApiCallStatus.Fetching}
          onClick={() => {
            apiCall.performCall()
            setLastRequest(Date.now())
          }}
        >
          {t('subscription.findOutCost')}
        </Button>
      )}
      {isOnCooldown && <Typography>{t('subscription.weAreProcessingData')}</Typography>}
    </Box>
  )
}

export const SubscriptionPresenter = ({
  subscription,
  hideCancel = false,
}: SubscriptionPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { fetchUser, user } = useUser()

  const [unsubDialogOpen, setUnsubDialogOpen] = useState(false)
  const [openConfirmCancellationDialog, setOpenConfirmCancellationDialog] = useState(false)

  const currentDiscount = user?.discounts?.find(({ applied }) => !applied)

  const nextMonthSubscription = SUBSCRIPTION_TIERS.find(
    (tier) => tier.id === user.upcomingSubscriptionChange?.newTierId
  )

  const getStartBillingDate = () => {
    const startBillingDate = new Date(subscription?.renewalDate ?? '')
    startBillingDate.setDate(startBillingDate.getDate() - 30)
    return startBillingDate.toLocaleDateString('sk-SK')
  }

  const getTotalLast30DaysVolume = () => {
    let total = 0
    let currency = 'EUR'
    user.assignedCardmarketAccounts.forEach((acc) => {
      if (acc.last30daysVolume) {
        total += acc.last30daysVolume
        currency = acc.currency
      }
    })
    return getPriceFormat(total, currency)
  }

  const getSubscriptionStatus = () => {
    if (subscription.id === SubscriptionId.No) {
      return t('subscription.noSubscription')
    } else if (subscription.id === SubscriptionId.Trial) {
      return t('subscription.trialing')
    } else if (subscription.id === SubscriptionId.Full) {
      return t('subscription.active')
    } else if (subscription.id === SubscriptionId.FullCancelling) {
      return t('subscription.cancelling')
    } else if (subscription.id === SubscriptionId.FullPastDue) {
      return t('subscription.pastDue')
    }
  }

  const leftColumnSize = 6
  const rightColumnSize = 6

  return (
    <Box paddingLeft={2.5}>
      <Typography>
        <Grid container>
          <Grid item xs={leftColumnSize}>
            {t('subscription.currentSubscription')}
          </Grid>
          <Grid item xs={rightColumnSize}>
            {subscription.name}
          </Grid>

          <Grid item xs={leftColumnSize}>
            {t('subscription.status')}
          </Grid>
          <Grid item xs={rightColumnSize}>
            {getSubscriptionStatus()}
          </Grid>

          <Grid item xs={leftColumnSize}>
            {t('subscription.startBillingPeriod')}
          </Grid>
          <Grid item xs={rightColumnSize}>
            {getStartBillingDate()}
          </Grid>

          <Grid item xs={leftColumnSize}>
            {t('subscription.renewalDate')}
          </Grid>
          <Grid item xs={rightColumnSize}>
            {new Date(subscription?.renewalDate ?? '').toLocaleDateString('sk-SK')}
          </Grid>

          <Grid item xs={leftColumnSize}>
            {t('subscription.currentSalesVolume')}
          </Grid>
          <Grid item xs={rightColumnSize}>
            {getTotalLast30DaysVolume()}
          </Grid>
        </Grid>
      </Typography>
      <Box margin={4}></Box>
      {subscription.id === SubscriptionId.Trial && (
        <Box>
          <Typography>
            {t('account.freeTrialMsgPart1')}{' '}
            {new Date(subscription?.renewalDate ?? '').toLocaleString('sk-SK')}
            {t('account.freeTrialMsgPart2')}{' '}
          </Typography>
        </Box>
      )}
      {subscription.id === SubscriptionId.FreeDayPass && (
        <Box>
          <Typography>
            You have a Day Pass active for this account until{' '}
            {new Date(subscription.renewalDate ?? '').toLocaleString('sk-SK')}.
          </Typography>
        </Box>
      )}
      {subscription.id === SubscriptionId.Full && (
        <Box>
          <Box>
            <Typography>
              {nextMonthSubscription
                ? `${t('subscription.yourCostNextMonth')} ${nextMonthSubscription.cost - 0.01}€`
                : t('subscription.weWillLetYouKnow')}
            </Typography>
          </Box>
        </Box>
      )}
      {subscription.id === SubscriptionId.FullCancelling && (
        <Box>
          <Button
            onClick={async () => {
              await removeCancelSubscription()
              fetchUser()
            }}
            color="primary"
          >
            {t('subscription.reactivate')}
          </Button>
        </Box>
      )}
      {subscription.id === SubscriptionId.FullPastDue && (
        <Box>
          <Typography>{t('subscription.couldNotCharge')}</Typography>
        </Box>
      )}
      {[SubscriptionId.Free, SubscriptionId.FreeRoca, SubscriptionId.No].includes(
        subscription.id
      ) &&
        user?.cardmarketUsername && (
          <Box>
            <Box display="flex">
              {user?.currentSubscriptionOffer !== undefined && (
                <Box padding={1}>
                  <UpgradeSubscriptionPresenter
                    onSuccessfulPurchase={async () => {
                      await recordUsage(
                        Activity.UpgradeSubscription,
                        {},
                        {
                          category: 'conversion',
                          action: 'upgrade_subscription',
                          value: user?.currentSubscriptionOffer,
                        }
                      )
                    }}
                  />
                </Box>
              )}
            </Box>
            {
              <Box>
                {user?.currentSubscriptionOffer !== undefined ? (
                  <Typography variant="body1">
                    {t('subscription.basedOnSales')}{' '}
                    <Typography
                      variant="h6"
                      component="span"
                      color="primary"
                      style={{
                        textDecoration: currentDiscount ? 'line-through' : 'underline',
                        textDecorationThickness: '2px',
                      }}
                    >
                      <b>
                        {user?.currentSubscriptionOffer > 0
                          ? user?.currentSubscriptionOffer - 0.01
                          : 0}
                        €
                      </b>
                    </Typography>
                    {currentDiscount && (
                      <Box marginLeft={1} display="inline">
                        <Typography variant="h6" component="span" color="primary">
                          <b>
                            {roundTo2Decimals(
                              (user?.currentSubscriptionOffer * (100 - currentDiscount.value)) /
                                100 -
                                0.01
                            )}
                            €
                          </b>{' '}
                          ({currentDiscount.description})
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                ) : (
                  <SubscriptionCostRequest />
                )}
              </Box>
            }
          </Box>
        )}
      <Box marginTop={2}>
        <Typography>
          <a href="https://tcgpowertools.com/pricing" target="_blank" rel="noreferrer">
            {t('account.pricingPageLink')}
          </a>
        </Typography>
      </Box>
      {!hideCancel && (
        <Box paddingTop={2}>
          <Button variant="outlined" onClick={() => setOpenConfirmCancellationDialog(true)}>
            Cancel Subscription
          </Button>
        </Box>
      )}

      <ConfirmCancellationDialog
        open={openConfirmCancellationDialog}
        onClose={() => setOpenConfirmCancellationDialog(false)}
        onConfirm={() => {
          setOpenConfirmCancellationDialog(false)
          setUnsubDialogOpen(true)
        }}
      />

      <ClosableDialog open={unsubDialogOpen} onClose={() => setUnsubDialogOpen(false)}>
        <CancellationPresenter onDone={() => setUnsubDialogOpen(false)} />
      </ClosableDialog>
    </Box>
  )
}
