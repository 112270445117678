import { Box, Typography } from '@material-ui/core'
import { ArticleLine } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { currencySymbol } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import { CardmarketArticle, CardmarketArticleAttributes, PriceReport } from 'shared'

export interface PriceReportPresenterProps {
  priceReport: PriceReport
}

export const PriceReportPresenter = ({ priceReport }: PriceReportPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <Box>
      <Typography>
        <b>{t('autopriceArticles.summary.pricingStrategy')}: </b>
        {priceReport.pricingStrategy?.name || 'Invalid strategy'}
      </Typography>
      {priceReport.failure && (
        <Typography>
          <b>Error:</b> {priceReport.failure}
        </Typography>
      )}
      {priceReport.base?.type === 'competition' && (
        <>
          <Typography>
            <b>Found Article:</b>
          </Typography>
          <ArticleLine
            article={new CardmarketArticle(priceReport.base.value as CardmarketArticleAttributes)}
          />
        </>
      )}
      {priceReport.base?.type === 'priceGuide' && (
        <Typography variant="body2">
          <b>{priceReport.pricingStrategy?.base.tactic.parameter}:</b> {priceReport.base.value}
          {currencySymbol(user?.currency)}
        </Typography>
      )}
      <Typography variant="body2">
        <b>{priceReport.base?.usedBulkPrice && t('autopriceArticles.bulkPriced')}</b>
      </Typography>{' '}
      <Typography variant="body2">
        <b>{priceReport.base?.hitDecreaseCap && t('autopriceArticles.decreaseCapped')}</b>
      </Typography>
      <Typography variant="body2">
        <b>{priceReport.base?.hitIncreaseCap && t('autopriceArticles.increaseCapped')}</b>
      </Typography>
    </Box>
  )
}
