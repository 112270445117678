import { Box } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import {
  createUserSubEntity,
  deleteUserSubEntity,
  editUserAttribute,
  editUserSubEntity,
} from 'lib/api'
import { getUniqueNewName } from 'lib/utils'
import qs from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PricingSettingsTabs } from 'shared'
import { useHandleSettingsChange } from '../StockPricingPresenter'
import { BulkpriceEditor } from './BulkpriceEditor'
import { CompetitorPresenter } from './CompetitorPresenter'
import { CreateCompetitorDialog } from './CreateFlow/CreateCompetitorPresenter/CreateCompetitorDialog'
import { CreateStrategyDialog } from './CreateFlow/CreateStrategyPresenter/CreateStrategyDialog'
import { PricingSettingsMenu } from './PricingSettingsMenu'
import { PricingStrategiesPresenter } from './PricingStrategiesPresenter'

export const PricingSettings = (): JSX.Element => {
  const { user, activeGame } = useUser()
  const [createStrategyDialogOpen, setCreateStrategyDialogOpen] = useState(false)
  const [createCompetitorDialogOpen, setCreateCompetitorDialogOpen] = useState(false)
  if (!user) return <></>

  const handleChange = useHandleSettingsChange()

  const [activeTab, setActiveTab] = useState(PricingSettingsTabs.BulkSettings)

  const location = useLocation()
  const isFirstRender = useRef(true)
  useEffect(() => {
    const { settingsTab } = qs.parse(location.search)
    if (isFirstRender.current) {
      if (settingsTab && Object.values(PricingSettingsTabs).includes(Number(settingsTab)))
        setActiveTab(Number(settingsTab) as PricingSettingsTabs)
      else if (!Object.values(PricingSettingsTabs).includes(Number(activeTab))) {
        setActiveTab(PricingSettingsTabs.BulkSettings)
      }
      isFirstRender.current = false
    }
  }, [])

  const bulkSettingsForActiveGame = user.bulkSettings.filter((bulkSettings) => {
    return bulkSettings.gameId === activeGame.idGame
  })

  const onTabClick = (tabId: number) => {
    setActiveTab(tabId)
  }

  const WIDTH = '300px'

  return (
    <>
      <Box display="flex">
        <Box
          style={{
            minHeight: '100vh',
            minWidth: WIDTH,
            maxWidth: WIDTH,
          }}
        >
          <PricingSettingsMenu onTabClick={onTabClick} />
        </Box>
        {activeTab === PricingSettingsTabs.BulkSettings && (
          <Box padding={4}>
            <BulkpriceEditor />
          </Box>
        )}
        {activeTab === PricingSettingsTabs.Competitors && (
          <>
            <Box padding={4}>
              <CompetitorPresenter
                disabled={user.subscription.featuresDisabled.includes(
                  'stockPricing.competitionPricing'
                )}
                competitors={user?.competitors}
                onChange={async (competitor) => {
                  handleChange(() => editUserSubEntity(competitor, 'competitors'))
                }}
                onCreate={async () => setCreateCompetitorDialogOpen(true)}
                onDelete={async (id) => {
                  handleChange(() => deleteUserSubEntity(id, 'competitors'))
                }}
                onClone={async (competitor) => {
                  handleChange(() =>
                    createUserSubEntity(
                      {
                        ...competitor,
                        name: getUniqueNewName(competitor.name, user.competitors),
                      },
                      'competitors'
                    )
                  )
                }}
              />
            </Box>
          </>
        )}
        {activeTab === PricingSettingsTabs.Strategies && (
          <Box padding={4}>
            <PricingStrategiesPresenter
              competitionDisabled={user.subscription.featuresDisabled.includes(
                'stockPricing.competitionPricing'
              )}
              competitors={user?.competitors}
              pricingStrategies={user?.pricingStrategies}
              onDelete={async (id) => {
                handleChange(() => deleteUserSubEntity(id, 'pricingStrategies'))
              }}
              onCreate={async () => setCreateStrategyDialogOpen(true)}
              onChange={async (pricingStrategy) => {
                handleChange(() => editUserSubEntity(pricingStrategy, 'pricingStrategies'))
              }}
              onClone={async (pricingStrategy) => {
                await handleChange(() =>
                  createUserSubEntity(
                    {
                      ...pricingStrategy,
                      name: getUniqueNewName(pricingStrategy.name, user.pricingStrategies),
                    },
                    'pricingStrategies'
                  )
                )
                if (!user.canUsePricingBot) {
                  await handleChange(() => editUserAttribute(true, 'canUsePricingBot'))
                }
              }}
              bulkSettings={bulkSettingsForActiveGame}
            />
          </Box>
        )}
        <CreateStrategyDialog
          onClose={() => setCreateStrategyDialogOpen(false)}
          open={createStrategyDialogOpen}
        />
        <CreateCompetitorDialog
          onClose={() => setCreateCompetitorDialogOpen(false)}
          open={createCompetitorDialogOpen}
        />
      </Box>
    </>
  )
}
