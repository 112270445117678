import { Box, CircularProgress } from '@material-ui/core'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import fileDownload from 'js-file-download'
import { getCsvProducts } from 'lib/api'
import { useDialog } from 'lib/hooks'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export const ExportAllProductsCsv = (): JSX.Element => {
  const { activeGame } = useUser()
  const { t } = useTranslation()
  const [setFetchingDialogOpen, FetchingDialog] = useDialog()

  const filename = `export-${activeGame.name}-${moment(Date.now()).format('DD-MM-YYYY')}.csv`

  const handleClick = async () => {
    setFetchingDialogOpen(true)
    const resource = await getCsvProducts(activeGame.idGame)
    const resourceData = resource.resourceData as Blob
    fileDownload(resourceData, filename)
    setFetchingDialogOpen(false)
  }

  return (
    <>
      {
        <Button fullWidth variant="contained" color="primary" onClick={handleClick}>
          {t('csv.download')}
          <FetchingDialog
            title={t('csv.exportingProductCatalog')}
            content={
              <Box marginTop={3} height="8rem" width="fit-content" margin="0 auto">
                <CircularProgress size={100} />
              </Box>
            }
          />
        </Button>
      }
    </>
  )
}
