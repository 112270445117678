import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { AllSellersIcon, PowerSellerSellersIcon, ProfessionalSellersIcon } from 'components/facets'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkSettings, Competitor, PricingStrategy, Reputation, SellerLevel } from 'shared'
import { COUNTRIES } from 'utils/constants'
import { CompetitorBox, FromCountries, REPUTATION_MAP } from '../../CompetitorBox'
import { EditableElement } from '../../EditableElement'
import { OptionBox } from '../OptionBox'
import { ProgressStepper } from '../ProgressStepper'
import { RestartButton } from '../RestartButton'
import { StepBox } from '../StepBox'

export interface CreateCompetitorPresenterProps {
  newName: string
  onRestart?: () => void
  onDone: (competitor: Competitor) => void
}

enum Step {
  Professionality = 1,
  Settings,
  ExcludeUsers,
  Finalize,
}

export const CreateCompetitorPresenter = ({
  newName,
  onRestart,
  onDone,
}: CreateCompetitorPresenterProps): JSX.Element => {
  const [step, setStep] = useState<Step>(Step.Professionality)
  const [progress, setProgress] = useState<Step>(Step.Professionality)

  const defaultValues = {
    _id: '',
    countries: [],
    reputation: Reputation.VeryGood,
    sellCount: 100,
    excludedUsers: [],
    name: newName,
    level: SellerLevel.Private,
  }

  const [competitor, setCompetitor] = useState<Competitor>(defaultValues)
  const { t } = useTranslation()

  const handleChange = (
    newValue: unknown,
    attributeName?: keyof Competitor | keyof PricingStrategy | keyof BulkSettings
  ): void => {
    if (!attributeName) return
    const lastCharterDifferentOfSpace = /\S/.test(String(newValue))
    if (attributeName == 'name' && !lastCharterDifferentOfSpace) return
    setCompetitor({ ...competitor, [attributeName]: newValue })
  }

  return (
    <Grid style={{ width: '900px', height: '480px' }} container>
      <Grid item xs={1}>
        <ProgressStepper
          activeStep={step}
          progress={progress}
          handleStep={(index) => setStep(index)}
          steps={[
            t('competitor.level'),
            `${t('competitor.countries')}, ${t('competitor.reputation')}, ${t(
              'competitor.sellCount'
            )}`,
            t('competitor.excludedUsers'),
            t('competitor.name'),
          ]}
        />
      </Grid>
      <Grid item xs={11}>
        <Box height="98%" padding={3}>
          <Typography variant="h4">
            {t('competitor.create.title')}{' '}
            <RestartButton
              onClick={() => {
                if (onRestart) onRestart()
                else {
                  setCompetitor(defaultValues)
                  setProgress(1)
                  setStep(1)
                }
              }}
              dataTestid={'restart-btn'}
            />
          </Typography>
          <Box height="inherit" paddingTop={1}>
            {
              {
                [Step.Professionality]: (
                  <StepBox>
                    <Typography variant="body1">{t('competitor.tooltip.level')}</Typography>
                    <Box width="fit-content" margin="0 auto" display="flex">
                      <OptionBox
                        width="200px"
                        onClick={() => {
                          setCompetitor({ ...competitor, level: SellerLevel.Private })
                          setProgress(Step.Professionality + 1)
                          setStep(Step.Professionality + 1)
                        }}
                        Icon={AllSellersIcon}
                        title={`All`}
                        description={``}
                        dataTestid={`competitor-all`}
                        selected={competitor && competitor.level === SellerLevel.Private}
                      />
                      <OptionBox
                        width="200px"
                        onClick={() => {
                          setCompetitor({ ...competitor, level: SellerLevel.Professional })
                          setProgress(Step.Professionality + 1)
                          setStep(Step.Professionality + 1)
                        }}
                        Icon={ProfessionalSellersIcon}
                        title={`Professional`}
                        description={``}
                        dataTestid={`competitor-professional`}
                        selected={competitor && competitor.level === SellerLevel.Professional}
                      />
                      <OptionBox
                        width="200px"
                        onClick={() => {
                          setCompetitor({ ...competitor, level: SellerLevel.Powerseller })
                          setProgress(Step.Professionality + 1)
                          setStep(Step.Professionality + 1)
                        }}
                        Icon={PowerSellerSellersIcon}
                        title={`Powerseller`}
                        description={``}
                        dataTestid={`competitor-powerseller`}
                        selected={competitor && competitor.level === SellerLevel.Powerseller}
                      />
                    </Box>
                  </StepBox>
                ),
                [Step.Settings]: (
                  <StepBox
                    onNext={() => {
                      setProgress(Math.max(Step.Settings + 1, progress))
                      setStep(Math.max(Step.Settings + 1, progress))
                    }}
                  >
                    <Box>
                      <Typography variant="body1">
                        {t('competitor.create.fromDescription')}
                      </Typography>
                      <Box padding={2} marginBottom={2}>
                        <Typography variant="h6">
                          {t('competitor.create.IWantToCompete')}{' '}
                          <EditableElement
                            attribute="countries"
                            onChange={handleChange}
                            options={COUNTRIES.map((country) => ({
                              value: country.code,
                              label: country.name,
                            }))}
                            name={t('competitor.countries')}
                            viewOnly={false}
                            type="multiple-select"
                            existingValue={competitor.countries}
                            tooltipText={t('competitor.tooltip.countries')}
                            value={t('competitor.from')}
                            showBorder
                            dataTestid="competitor-country"
                          />{' '}
                          <FromCountries
                            countries={competitor.countries || []}
                            anywhereText={t('competitor.fromAnywhere')}
                          />
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        {t('competitor.create.reputationDescription')}
                      </Typography>
                      <Box padding={2} marginBottom={2}>
                        <Typography variant="h6">
                          {t('competitor.create.IWantToCompete')}{' '}
                          {t('competitor.create.thatHaveAtleast')}{' '}
                          <EditableElement
                            attribute="reputation"
                            onChange={handleChange}
                            showBorder
                            existingValue={competitor.reputation || Reputation.VeryGood}
                            viewOnly={false}
                            name={t('competitor.reputation')}
                            tooltipText={t('competitor.tooltip.reputation')}
                            options={Object.keys(REPUTATION_MAP).map((key) => ({
                              value: key,
                              label: REPUTATION_MAP[Number(key)],
                            }))}
                            value={REPUTATION_MAP[competitor.reputation || Reputation.VeryGood]}
                            dataTestid="competitor-reputation"
                          />{' '}
                          {t('competitor.reputation')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        {t('competitor.create.salesDescription')}
                      </Typography>
                      <Box padding={2} marginBottom={2}>
                        <Typography variant="h6">
                          {t('competitor.create.IWantToCompete')}{' '}
                          {t('competitor.create.thatHaveAtleast')}{' '}
                          <EditableElement
                            existingValue={String(competitor.sellCount)}
                            attribute="sellCount"
                            onChange={handleChange}
                            showBorder
                            name={t('competitor.minSales')}
                            viewOnly={false}
                            tooltipText={t('competitor.tooltip.minSales')}
                            type="number"
                            value={String(competitor.sellCount || 0)}
                            dataTestid="competitor-sales"
                          />{' '}
                          {t('competitor.sales')}
                        </Typography>
                      </Box>
                    </Box>
                  </StepBox>
                ),
                [Step.ExcludeUsers]: (
                  <StepBox
                    //disableNext={progress > Step.ExcludeUsers}
                    onNext={() => {
                      setProgress(Math.max(Step.ExcludeUsers + 1, progress))
                      setStep(Math.max(Step.ExcludeUsers + 1, progress))
                    }}
                  >
                    <Typography gutterBottom variant="body1">
                      {t('competitor.create.excludedUsersDescription')}{' '}
                    </Typography>
                    <Typography gutterBottom variant="body1">
                      {t('competitor.create.excludedUsersDescription2')}{' '}
                    </Typography>
                    <Box margin={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={8}
                        value={(competitor.excludedUsers || []).join('\n')}
                        onChange={(e) =>
                          setCompetitor({
                            ...competitor,
                            excludedUsers: e.target.value ? e.target.value.split('\n') : [],
                          })
                        }
                        label={t('competitor.excludedUsers')}
                        placeholder="someCardmarketUsername"
                        data-testid="competitor-exludedUser"
                      />
                    </Box>
                    {Boolean(competitor.excludedUsers?.length) && (
                      <Typography
                        gutterBottom
                        variant="body1"
                        data-testid="competitor-exludedUser-list"
                      >
                        {t('competitor.create.weWillExclude')}{' '}
                        <b>{competitor.excludedUsers?.join(', ')}</b>
                      </Typography>
                    )}
                  </StepBox>
                ),
                [Step.Finalize]: (
                  <StepBox
                    onNext={() => onDone(competitor)}
                    isLast
                    disableNext={competitor.name.trim() === ''}
                  >
                    {' '}
                    <Typography gutterBottom variant="body1">
                      {t('competitor.create.almostDone')}{' '}
                    </Typography>
                    <Box margin={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={competitor.name}
                        autoFocus
                        onFocus={(event) => {
                          event.target.select()
                        }}
                        onChange={(e) =>
                          setCompetitor({
                            ...competitor,
                            name: e.target.value,
                          })
                        }
                        label={t('competitor.name')}
                        data-testid="competitor-name"
                      />
                      <Box width="fit-content" margin="48px auto">
                        <CompetitorBox competitor={competitor} />
                      </Box>
                    </Box>
                  </StepBox>
                ),
              }[step]
            }
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
