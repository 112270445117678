import { Box, FormControl, FormHelperText, MenuItem, Select as MuiSelect } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useUser } from 'components/providers/UserProvider'
import { editUserAttribute } from 'lib/api'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { UserAttribute, UserAttributeName } from 'shared'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '300px',
    '& .MuiInputBase-input': {
      width: '100%',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

export interface AccountSettingSelectProps {
  attribute: UserAttributeName
  options: { name: string; value: string }[]
  onChange?: (value: unknown) => void
  helperText?: string
}

export const AccountSettingSelect = ({
  attribute,
  options,
  onChange,
  helperText,
}: AccountSettingSelectProps): JSX.Element => {
  const { user, setUser } = useUser()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box>
        <Box>
          <FormControl>
            {user && (
              <MuiSelect
                onChange={async (e) => {
                  try {
                    const response = await editUserAttribute(
                      e.target.value as UserAttribute,
                      attribute
                    )
                    enqueueSnackbar(t('changesSaved'), {
                      variant: 'success',
                    })
                    setUser({
                      ...user,
                      ...response,
                    })
                    onChange?.(response.language)
                  } catch (error) {
                    enqueueSnackbar(String(error), {
                      variant: 'error',
                    })
                  }
                }}
                // label={t('card.language')}
                value={user[attribute]}
                data-testid={`choose-${attribute}`}
                style={{
                  border: '1px solid rgba(0,0,0,0.42)',
                  borderRadius: '5px',
                  padding: '5px',
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value} style={{ fontSize: '14px' }}>
                    {option.name}
                  </MenuItem>
                ))}
              </MuiSelect>
            )}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}
