import { Box, IconButton, Paper } from '@material-ui/core'
import { DragIcon } from 'components/facets'
import { useState } from 'react'
import { PricingPlanNodeCondition } from 'shared'
import { ConditionPresenter } from '../ConditionPresenter'

function isDescendant(older: any, younger: any) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some((child: any) => child === younger || isDescendant(child, younger))
  )
}

// eslint-disable-next-line react/prefer-stateless-function
export const MinimalThemeNodePreviewRenderer = ({
  connectDragPreview,
  connectDragSource,
  node,
  draggedNode,
  canDrag,
  style,
}: any) => {
  //const nodeSubtitle = subtitle || node.subtitle
  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
  //const isLandingPadActive = !didDrop && isDragging

  const [isHoveringOnDrag, setHoveringOnDrag] = useState(false)

  const handle = (
    <div>
      <Box>
        <IconButton
          color={'primary'}
          data-testid="preview-node-handle"
          onMouseEnter={() => setHoveringOnDrag(true)}
          onMouseLeave={() => setHoveringOnDrag(false)}
          disabled={!canDrag}
          style={{ cursor: 'move' }}
        >
          <DragIcon style={{ fontSize: '1.9rem' }} />
        </IconButton>
      </Box>
    </div>
  )

  const conditions: PricingPlanNodeCondition[] = node.conditions || []

  const nodeContent = connectDragPreview(
    <div>
      <div style={{ display: 'flex' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            {canDrag
              ? connectDragSource(handle, {
                  dropEffect: 'copy',
                })
              : handle}
          </div>
        </Box>

        <div>
          <div>
            <span>
              <Box display="flex">
                <Box>
                  {conditions.map((condition) => (
                    <div key={JSON.stringify(condition)}>
                      <ConditionPresenter maxHeight={6} condition={condition} displayType={true} />
                    </div>
                  ))}
                </Box>
              </Box>
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Box style={{ cursor: 'initial' }}>
      <Box>
        <Paper elevation={isHoveringOnDrag ? 5 : 1}>
          <Box padding={1.5} display="flex">
            <div>
              <div
                style={{
                  opacity: isDraggedDescendant ? 0.5 : 1,
                  //paddingLeft: scaffoldBlockPxWidth,
                  ...style,
                }}
              >
                {nodeContent}
              </div>
            </div>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
