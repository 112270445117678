import { Box, Checkbox, FormHelperText, Typography, useTheme } from '@material-ui/core'
import { ButtonDialog } from 'components/controls/dialogs'
import { Button, CircularLoading } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { changeSubscription } from 'lib/api'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { roundTo2Decimals } from 'shared'

export interface UpgradeSubscriptionPresenterProps {
  onSuccessfulPurchase: () => Promise<void>
}

export const UpgradeSubscriptionPresenter = ({
  onSuccessfulPurchase,
}: UpgradeSubscriptionPresenterProps): JSX.Element => {
  const { fetchUser, user } = useUser()

  if (!user) return <></>
  const { outseta, stripePaymentMethod } = user
  const hasCreditCard = !!outseta?.hasCreditCard
  const offeredCost = Number(user.currentSubscriptionOffer)

  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const { palette } = useTheme()
  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true)
      await changeSubscription(offeredCost)
      await fetchUser()
      try {
        await onSuccessfulPurchase()
      } catch (_err) {}
      enqueueSnackbar(t('account.subscriptionUpgraded'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('error.generic'), {
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const currentDiscount = user?.discounts?.find(({ applied }) => !applied)

  const discountValue = -(offeredCost * Number(currentDiscount?.value)) / 100

  const readyForCheckout =
    (stripePaymentMethod || hasCreditCard) && user.currentSubscriptionOffer !== undefined

  const getFormattedCost = (cost: number) => {
    return roundTo2Decimals(cost < 0 ? 0 : cost)
  }

  return (
    <ButtonDialog
      label={''}
      renderComponent={(_title, onClick) => (
        <Button variant="contained" onClick={onClick}>
          Start subscribing
        </Button>
      )}
      title={t('account.upgrade.startSubscribing')}
      content={
        <Box maxWidth="800px">
          <Typography gutterBottom>{t('account.upgrade.info1')}</Typography>
          {loading ? (
            <Box height="200px" display="flex" alignItems="center" justifyContent="center">
              <CircularLoading />
            </Box>
          ) : (
            <Box>
              {readyForCheckout && (
                <Box
                  //width="99%"
                  margin="30px auto"
                >
                  <Box
                    padding={1}
                    borderTop={`1px solid ${palette.primary.main}`}
                    borderBottom="1px solid black"
                  >
                    <Box display="flex">
                      <Box height="fit-content" width="90%">
                        <Typography variant="h5">{t('account.upgrade.lineItem')}</Typography>
                        <FormHelperText>{t('account.upgrade.lineItemDescription')}</FormHelperText>
                      </Box>
                      <Typography color="primary" style={{ textAlign: 'right' }} variant="h4">
                        {getFormattedCost(offeredCost - 0.01)}€/month
                      </Typography>
                    </Box>{' '}
                    {currentDiscount && (
                      <Box display="flex">
                        <Box height="fit-content" width="90%">
                          <Typography variant="h5">{currentDiscount.description}</Typography>
                          <FormHelperText>Discount (1 month)</FormHelperText>
                        </Box>
                        <Typography color="primary" style={{ textAlign: 'right' }} variant="h4">
                          {discountValue}€
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" padding={1}>
                    <Box height="fit-content" width="90%">
                      <Typography variant="h4" color="primary">
                        <b> TOTAL</b>
                      </Typography>
                    </Box>
                    <Typography color="primary" style={{ textAlign: 'right' }} variant="h4">
                      <b> {getFormattedCost(offeredCost + (discountValue || 0) - 0.01)}€</b>
                    </Typography>
                  </Box>
                </Box>
              )}

              {!hasCreditCard && (
                <Typography variant="h6">{t('account.missingCreditCard')}</Typography>
              )}
            </Box>
          )}
        </Box>
      }
      actions={(_handleClose) => (
        <>
          <Box display="flex" alignItems="center" width="89%">
            <Checkbox
              data-testid="publish-changes-checkbox"
              onChange={() => setAgreedToTerms(!agreedToTerms)}
              name="checkedB"
              color="primary"
              disabled={!readyForCheckout}
            />
            <Typography variant="body1">
              {t('account.agreeWithTermsOfService')}{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.iubenda.com/terms-and-conditions/58883036"
              >
                {t('account.termsOfService')}
              </a>
            </Typography>
          </Box>
          <Button
            disabled={!agreedToTerms || loading}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {t('account.buyDayPass')}
          </Button>
        </>
      )}
    />
  )
}
