import { CardLanguage, RichArticle } from 'shared'

export const exampleMagicArticles: (Omit<RichArticle, 'price'> & { price: number })[] = (
  [
    {
      card: {
        _id: 364121,
        rarity: 7,
        cn: '183',
        name: 'Knight of Autumn',
        locNames: {
          DE: 'Knight of Autumn',
          ES: 'Knight of Autumn',
          FR: 'Knight of Autumn',
          IT: 'Knight of Autumn',
        },
        set: 'GRN',
        expansion: 'Guilds of Ravnica',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 1.56,
      id: 'df4f2473-63b8-41e2-a3e8-9fb61257432f',
    },
    {
      card: {
        _id: 266040,
        rarity: 4,
        cn: '89',
        name: 'Bolt of Keranos',
        locNames: {
          DE: 'Bolt of Keranos',
          ES: 'Bolt of Keranos',
          FR: 'Bolt of Keranos',
          IT: 'Bolt of Keranos',
        },
        set: 'BNG',
        expansion: 'Born of the Gods',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.04,
      id: '9b071e51-a16c-47ad-b960-c654c55c5468',
    },
    {
      card: {
        _id: 296795,
        rarity: 5,
        cn: '127',
        name: 'Deem Worthy',
        locNames: {
          DE: 'Deem Worthy',
          ES: 'Deem Worthy',
          FR: 'Deem Worthy',
          IT: 'Deem Worthy',
        },
        set: 'AKH',
        expansion: 'Amonkhet',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: true,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.2,
      id: '2c800c5b-6b01-4076-8ab8-032a2deaa5c8',
    },
    {
      card: {
        _id: 296933,
        rarity: 5,
        cn: '208',
        name: 'Wayward Servant',
        locNames: {
          DE: 'Wayward Servant',
          ES: 'Wayward Servant',
          FR: 'Wayward Servant',
          IT: 'Wayward Servant',
        },
        set: 'AKH',
        expansion: 'Amonkhet',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: true,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.04,
      id: '7c64bc16-5f75-4d92-9fe1-8a8f9beb0f68',
    },
    {
      card: {
        _id: 366189,
        rarity: 8,
        cn: '187',
        name: 'Tarmogoyf',
        locNames: {
          DE: 'Tarmogoyf',
          ES: 'Tarmogoyf',
          FR: 'Tarmogoyf',
          IT: 'Tarmogoyf',
        },
        set: 'UMA',
        expansion: 'Ultimate Masters',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 23.31,
      id: '32abdc40-29f4-4cad-a424-07bd2792cf52',
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleYugiohArticles: (Omit<RichArticle, 'price'> & { price: number })[] = (
  [
    {
      card: {
        _id: 502870,
        rarity: 8,
        cn: null,
        name: 'Dark Magician',
        locNames: {
          DE: 'Dark Magician',
          ES: 'Dark Magician',
          FR: 'Dark Magician',
          IT: 'Dark Magician',
        },
        set: 'WCCP',
        expansion: 'World Championship Celebration Promos',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.1,
      id: 'df4f2473-63b8-41e2-a3e8-9fb61257432f',
    },
    {
      card: {
        _id: 319668,
        rarity: 1,
        cn: '65',
        name: 'Dragon Master Knight',
        locNames: {
          DE: 'Dragon Master Knight',
          ES: 'Dragon Master Knight',
          FR: 'Dragon Master Knight',
          IT: 'Dragon Master Knight',
        },
        set: 'LCKC',
        expansion: 'Legendary Collection Kaiba Mega Pack',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 2.21,
      id: '9b071e51-a16c-47ad-b960-c654c55c5468',
    },
    {
      card: {
        _id: 293072,
        rarity: 3,
        cn: null,
        name: 'Blue-Eyes White Dragon',
        locNames: {
          DE: 'Blue-Eyes White Dragon',
          ES: 'Blue-Eyes White Dragon',
          FR: 'Blue-Eyes White Dragon',
          IT: 'Blue-Eyes White Dragon',
        },
        set: 'LDK2',
        expansion: 'Legendary Decks II',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.75,
      id: '2c800c5b-6b01-4076-8ab8-032a2deaa5c8',
    },
    {
      card: {
        _id: 296166,
        rarity: 15,
        cn: null,
        name: 'Obelisk the Tormentor',
        locNames: {
          DE: 'Obelisk the Tormentor',
          ES: 'Obelisk the Tormentor',
          FR: 'Obelisk the Tormentor',
          IT: 'Obelisk the Tormentor',
        },
        set: 'MVP1',
        expansion: 'The Dark Side of Dimensions Movie Pack',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 5.67,
      id: '7c64bc16-5f75-4d92-9fe1-8a8f9beb0f68',
    },
    {
      card: {
        _id: 442703,
        rarity: 1,
        cn: '53',
        name: 'Relinquished Anima',
        locNames: {
          DE: 'Relinquished Anima',
          ES: 'Relinquished Anima',
          FR: 'Relinquished Anima',
          IT: 'Relinquished Anima',
        },
        set: 'DUOV',
        expansion: 'Duel Overload',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 9.86,
      id: '32abdc40-29f4-4cad-a424-07bd2792cf52',
    },
  ] as const
).map((article) => new RichArticle(article))

export const examplePokemonArticles: (Omit<RichArticle, 'price'> & { price: number })[] = (
  [
    {
      card: {
        _id: 295142,
        rarity: 1,
        cn: '6',
        name: 'Pikachu',
        locNames: {
          DE: 'Pikachu',
          ES: 'Pikachu',
          FR: 'Pikachu',
          IT: 'Pikachu',
        },
        set: 'MCD16',
        expansion: "McDonald's Collection 2016",
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: true,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 1.56,
      id: 'df4f2473-63b8-41e2-a3e8-9fb61257432f',
    },
    {
      card: {
        _id: 450418,
        rarity: 4,
        cn: '63',
        name: 'Squirtle',
        locNames: {
          DE: 'Squirtle',
          ES: 'Squirtle',
          FR: 'Squirtle',
          IT: 'Squirtle',
        },
        set: 'CG',
        expansion: 'EX Crystal Guardians',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.04,
      id: '9b071e51-a16c-47ad-b960-c654c55c5468',
    },
    {
      card: {
        _id: 371999,
        rarity: 1,
        cn: null,
        name: 'Bulbasaur',
        locNames: {
          DE: 'Bulbasaur',
          ES: 'Bulbasaur',
          FR: 'Bulbasaur',
          IT: 'Bulbasaur',
        },
        set: 'SM',
        expansion: 'SM Black Star Promos',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isReverseHolo: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.2,
      id: '2c800c5b-6b01-4076-8ab8-032a2deaa5c8',
    },
    {
      card: {
        _id: 550136,
        rarity: 1,
        cn: '3',
        name: 'Charizard',
        locNames: {
          DE: 'Charizard',
          ES: 'Charizard',
          FR: 'Charizard',
          IT: 'Charizard',
        },
        set: 'DRM',
        expansion: 'Dragon Majesty',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: true,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 0.04,
      id: '7c64bc16-5f75-4d92-9fe1-8a8f9beb0f68',
    },
    {
      card: {
        _id: 483579,
        rarity: 5,
        cn: '140',
        name: 'Lugia',
        locNames: {
          DE: 'Lugia',
          ES: 'Lugia',
          FR: 'Lugia',
          IT: 'Lugia',
        },
        set: 'DAA',
        expansion: 'Darkness Ablaze',
      },
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      comment: '',
      quantity: 1,
      price: 23.31,
      id: '32abdc40-29f4-4cad-a424-07bd2792cf52',
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleFFArticles: (Omit<RichArticle, 'price'> & { price: number })[] = (
  [
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 5,
      card: {
        _id: 370989,
        cn: '79',
        name: 'Noctis (8-079)',
        locNames: {
          DE: 'Noctis (8-079)',
          ES: 'Noctis (8-079)',
          FR: 'Noctis (8-079)',
          IT: 'Noctis (8-079)',
        },
        rarity: 5,
        set: '8',
        expansion: 'Opus VIII',
      },
      id: 'ebe1debf-6347-432c-aaf4-8fc524907a0a',
    },
    {
      condition: 'EX',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 10,
      card: {
        _id: 370802,
        cn: '115',
        name: 'Zidane (8-115)',
        locNames: {
          DE: 'Zidane (8-115)',
          ES: 'Zidane (8-115)',
          FR: 'Zidane (8-115)',
          IT: 'Zidane (8-115)',
        },
        rarity: 6,
        set: '8',
        expansion: 'Opus VIII',
      },
      id: 'ce60c02c-88ee-4d9b-8c03-f98b98df442e',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: true,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 0.4,
      card: {
        _id: 370931,
        cn: '146',
        name: 'Thancred (8-146)',
        locNames: {
          DE: 'Thancred (8-146)',
          ES: 'Thancred (8-146)',
          FR: 'Thancred (8-146)',
          IT: 'Thancred (8-146)',
        },
        rarity: 2,
        set: '8',
        expansion: 'Opus VIII',
      },
      id: 'cfc53841-3fb4-4bfc-bcda-27ac13e50b75',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 0.07,
      card: {
        _id: 371052,
        cn: '112',
        name: 'Eiko (8-112)',
        locNames: {
          DE: 'Eiko (8-112)',
          ES: 'Eiko (8-112)',
          FR: 'Eiko (8-112)',
          IT: 'Eiko (8-112)',
        },
        rarity: 4,
        set: '8',
        expansion: 'Opus VIII',
      },
      id: '83f65f68-a360-47d7-8492-8873520ceefa',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 4,
      price: 0.05,
      card: {
        _id: 371000,
        cn: '95',
        name: 'Black Mage (8-095)',
        locNames: {
          DE: 'Black Mage (8-095)',
          ES: 'Black Mage (8-095)',
          FR: 'Black Mage (8-095)',
          IT: 'Black Mage (8-095)',
        },
        rarity: 3,
        set: '8',
        expansion: 'Opus VIII',
      },
      id: '50bd8bf7-53e2-4f25-8792-5b2cdc7bc570',
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleDBSArticles: (Omit<RichArticle, 'price'> & { price: number })[] = (
  [
    {
      condition: 'EX',
      idLanguage: CardLanguage.English,

      isFoil: true,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 0.2,
      card: {
        _id: 549841,
        cn: '29',
        name: 'A Sudden Escape',
        locNames: {
          DE: 'A Sudden Escape',
          ES: 'A Sudden Escape',
          FR: 'A Sudden Escape',
          IT: 'A Sudden Escape',
        },
        rarity: 5,
        set: 'BT13',
        expansion: 'Supreme Rivalry',
      },
      id: 'a2000f5d-c589-41e5-9fc4-21dde3626afb',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 0.06,
      card: {
        _id: 549701,
        cn: '1',
        name: 'Bardock // SS Bardock, the Legend Awakened',
        locNames: {
          DE: 'Bardock // SS Bardock, the Legend Awakened',
          ES: 'Bardock // SS Bardock, the Legend Awakened',
          FR: 'Bardock // SS Bardock, the Legend Awakened',
          IT: 'Bardock // SS Bardock, the Legend Awakened',
        },
        rarity: 6,
        set: 'BT13',
        expansion: 'Supreme Rivalry',
      },
      id: 'df0acc38-d713-4acc-943a-298e8236f44c',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 0.24,
      card: {
        _id: 549966,
        cn: '54',
        name: 'Bujin, the Evildoer',
        locNames: {
          DE: 'Bujin, the Evildoer',
          ES: 'Bujin, the Evildoer',
          FR: 'Bujin, the Evildoer',
          IT: 'Bujin, the Evildoer',
        },
        rarity: 7,
        set: 'BT13',
        expansion: 'Supreme Rivalry',
      },
      id: '962c5c73-9ec8-4afa-ab4e-966849110540',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 7.63,
      card: {
        _id: 549996,
        cn: '60',
        name: 'Galactic Buster',
        locNames: {
          DE: 'Galactic Buster',
          ES: 'Galactic Buster',
          FR: 'Galactic Buster',
          IT: 'Galactic Buster',
        },
        rarity: 8,
        set: 'BT13',
        expansion: 'Supreme Rivalry',
      },
      id: '740193a0-b4bb-420b-b4b3-01d61952b16b',
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleVanguardArticles: (Omit<RichArticle, 'price'> & { price: number })[] = (
  [
    {
      condition: 'EX',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 2,
      price: 0.59,
      card: {
        _id: 308897,
        cn: '1',
        name: 'School Etoile, Olyvia [G Format] (V.2 - Generation Rare)',
        locNames: {
          DE: 'School Etoile, Olyvia [G Format] (V.2 - Generation Rare)',
          ES: 'School Etoile, Olyvia [G Format] (V.2 - Generation Rare)',
          FR: 'School Etoile, Olyvia [G Format] (V.2 - Generation Rare)',
          IT: 'School Etoile, Olyvia [G Format] (V.2 - Generation Rare)',
        },
        rarity: 9,
        set: 'G-CB01',
        expansion: 'Academy of Divas',
      },
      id: '029d72f8-4677-4bd5-b065-2e274fb57877',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 6.3,
      card: {
        _id: 308900,
        cn: '5',
        name: 'Ideal Walking Weather, Emilia [G Format] (V.2 - Triple Rare)',
        locNames: {
          DE: 'Ideal Walking Weather, Emilia [G Format] (V.2 - Triple Rare)',
          ES: 'Ideal Walking Weather, Emilia [G Format] (V.2 - Triple Rare)',
          FR: 'Ideal Walking Weather, Emilia [G Format] (V.2 - Triple Rare)',
          IT: 'Ideal Walking Weather, Emilia [G Format] (V.2 - Triple Rare)',
        },
        rarity: 7,
        set: 'G-CB01',
        expansion: 'Academy of Divas',
      },
      id: '10447f16-44e4-47e6-8911-75652f022e19',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: true,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 0.15,
      card: {
        _id: 308906,
        cn: '12',
        name: 'Cherished Phrase, Reina [G Format] (V.2 - Double Rare)',
        locNames: {
          DE: 'Cherished Phrase, Reina [G Format] (V.2 - Double Rare)',
          ES: 'Cherished Phrase, Reina [G Format] (V.2 - Double Rare)',
          FR: 'Cherished Phrase, Reina [G Format] (V.2 - Double Rare)',
          IT: 'Cherished Phrase, Reina [G Format] (V.2 - Double Rare)',
        },
        rarity: 5,
        set: 'G-CB01',
        expansion: 'Academy of Divas',
      },
      id: '3a1ae30e-4e18-4918-b6cf-a9ec7b2e30b9',
    },
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,

      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      comment: '',
      quantity: 1,
      price: 0.09,
      card: {
        _id: 308919,
        cn: '28',
        name: 'Mystery Solving Time, Ithil [G Format]',
        locNames: {
          DE: 'Mystery Solving Time, Ithil [G Format]',
          ES: 'Mystery Solving Time, Ithil [G Format]',
          FR: 'Mystery Solving Time, Ithil [G Format]',
          IT: 'Mystery Solving Time, Ithil [G Format]',
        },
        rarity: 2,
        set: 'G-CB01',
        expansion: 'Academy of Divas',
      },
      id: '9f1757c0-2f52-4a7d-847a-65017396f190',
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleWSArticles: (Omit<RichArticle, 'price'> & { price: number })[] = (
  [
    {
      card: {
        _id: 555838,
        rarity: 1,
        cn: 'T20',
        name: 'Searching for Yoshinon',
        set: 'DAL/W79',
        expansion: 'Date A Live',
        locNames: {
          DE: 'Searching for Yoshinon',
          ES: 'Searching for Yoshinon',
          FR: 'Searching for Yoshinon',
          IT: 'Searching for Yoshinon',
        },
      },
      id: '4ec49dd9-92eb-442c-9625-5421e6a20aea',
      condition: 'NM',
      price: 0.15,
    },
    {
      card: {
        _id: 555788,
        rarity: 1,
        cn: 'T07',
        name: 'Date Day, Tohka',
        set: 'DAL/W79',
        expansion: 'Date A Live',
        locNames: {
          DE: 'Date Day, Tohka',
          ES: 'Date Day, Tohka',
          FR: 'Date Day, Tohka',
          IT: 'Date Day, Tohka',
        },
      },
      price: 0.2,
      id: 'd0573e25-734d-41f3-a1ab-12c32eeb107c',
    },
    {
      card: {
        _id: 555832,
        rarity: 1,
        cn: 'T18',
        name: 'My Little Shido (TD)',
        set: 'DAL/W79',
        expansion: 'Date A Live',
        locNames: {
          DE: 'My Little Shido (TD)',
          ES: 'My Little Shido (TD)',
          FR: 'My Little Shido (TD)',
          IT: 'My Little Shido (TD)',
        },
      },
      price: 1,
      id: '54d6905b-4d8d-4618-b8d3-2dbfed22f6c2',
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleFABArticles = (
  [
    {
      price: 1,
      id: '54d6905b-4d8d-4618-b8d3-2dbfed22f6c2',
      card: {
        _id: 600072,
        cn: 'WTR032-C',
        name: 'Awakening Bellow (Red) (Rainbow Foil)',
        set: 'WTR-A',
        expansion: 'Welcome to Rathe - Alpha',
        rarity: 1,
        locNames: {
          DE: 'Awakening Bellow (Red) (Rainbow Foil)',
          ES: 'Awakening Bellow (Red) (Rainbow Foil)',
          FR: 'Awakening Bellow (Red) (Rainbow Foil)',
          IT: 'Awakening Bellow (Red) (Rainbow Foil)',
        },
      },
    },
  ] as const
).map((article) => new RichArticle(article))

//todo
export const exampleDigimonArticles = (
  [
    {
      price: 1,
      id: '54d6905b-4d8d-4618-b8d3-2dbfed22f6c2',
      card: {
        _id: 621045,
        cn: 'P-035',
        name: 'Red Memory Boost! (P-035) (V.1)',
        set: 'WTR-A',
        expansion: 'Welcome to Rathe - Alpha',
        rarity: 5,
        locNames: {
          DE: 'Red Memory Boost! (P-035) (V.1)',
          ES: 'Red Memory Boost! (P-035) (V.1)',
          FR: 'Red Memory Boost! (P-035) (V.1)',
          IT: 'Red Memory Boost! (P-035) (V.1)',
        },
      },
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleOnePieceArticles = (
  [
    {
      price: 1,
      id: '54d6905b-4d8d-4618-b8d3-2dbfed22f6c2',
      card: {
        _id: 690810,
        cn: 'OP01-013',
        name: 'Sanji (OP01-013) (V.2)',
        set: 'OP-01',
        expansion: 'Romance Dawn',
        rarity: 5,
        locNames: {
          DE: 'Sanji (OP01-013) (V.2)',
          ES: 'Sanji (OP01-013) (V.2)',
          FR: 'Sanji (OP01-013) (V.2)',
          IT: 'Sanji (OP01-013) (V.2)',
        },
      },
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleLorcanaArticles = (
  [
    {
      price: 1,
      id: '54d6905b-4d8d-4618-b8d3-2dbfed22f6c2',
      card: {
        _id: 727081,
        cn: '1',
        name: 'Ariel - On Human Legs',
        set: '1TFC',
        expansion: 'The First Chapter',
        rarity: 2,
        locNames: {
          DE: 'Arielle - Auf menschlichen Beinen',
          ES: 'Ariel - On Human Legs',
          FR: 'Ariel - Sur des jambes humaines',
          IT: 'Ariel - On Human Legs',
        },
      },
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleBSSArticles = (
  [
    {
      price: 1,
      id: '54d6905b-4d8d-4618-b8d3-2dbfed22f6c2',
      card: {
        _id: 728581,
        cn: '020',
        name: 'Vajranoceros',
        set: 'BSS01',
        expansion: 'Dawn of History',
        rarity: 2,
        locNames: {
          DE: 'Vajranoceros',
          ES: 'Vajranoceros',
          FR: 'Vajranoceros',
          IT: 'Vajranoceros',
        },
      },
    },
  ] as const
).map((article) => new RichArticle(article))

export const exampleSWUArticles = (
  [
    {
      price: 1,
      id: '54d6905b-4d8d-4618-b8d3-2dbfed22f6c2',
      card: {
        _id: 754965,
        cn: '005',
        name: 'Luke Skywalker, Faithful Friend',
        set: 'SOR',
        expansion: 'Spark of Rebellion',
        rarity: 2,
        locNames: {
          DE: 'Luke Skywalker, Getreuer Freund',
          ES: 'Luke Skywalker, Amigo Leal',
          FR: 'Luke Skywalker, Ami Fidèle',
          IT: 'Luke Skywalker, Amico Fidato',
        },
      },
    },
  ] as const
).map((article) => new RichArticle(article))
