import { Box, CircularProgress, Typography } from '@material-ui/core'
import { InventoryPresenter, StockState } from 'components/domain/stockPricing'
import { LocalArticle, RefreshStockIcon, SealedIcon, SinglesStockIcon } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useConfirm, useDialog, useErrorHandler } from 'lib/hooks'
import { useFetchUserStock } from 'lib/hooks/useFetchUserStock'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import { useStockStore } from 'lib/hooks/useStockStore'
import { isArticleEdited } from 'lib/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import {
  createInventoryArticle,
  InventoryArticle,
  InventoryFetchState,
  PublishChangesResponse,
  StockTab,
  STOCK_TYPES,
  User,
} from 'shared'
import { PublishChangesDialog } from './PublishChangesDialog'

export const useHandleSettingsChange = () => {
  const { handle } = useErrorHandler()
  const { setUser } = useUser()

  //change this to do naive update and then validate

  const handleChange = useCallback(
    async (apiRequest: () => Promise<Partial<User>>): Promise<void> => {
      try {
        const response = await apiRequest()
        setUser((user) => ({ ...user!, ...response }))
      } catch (err: any) {
        handle(err)
      }
    },
    [setUser]
  )
  return handleChange
}

const tabs = ['unedited', 'failedAutopricing', 'edited'] as const

export const StockPricingPresenter = (): JSX.Element => {
  const { activeGame, user, setUser } = useUser()
  const { handle } = useErrorHandler()
  const { t } = useTranslation()

  const [setFetchingDialogOpen, FetchingDialog] = useDialog()
  const [publishChangesDialogOpen, setPublishChangesDialogOpen] = useState(false)

  const [setPublishErrorsDialogOpen, PublishErrorsDialog] = useDialog()
  const [publishFailures, setPublishFailures] = useState<PublishChangesResponse>()

  const fetchUserStock = useFetchUserStock()
  const setActiveStockType = useStockStore((state) => state.setActiveStockType)

  const [fetchState, setFetchState] = useState(InventoryFetchState.FETCHING)

  const [inventoryState, setInventoryState, loadingFromLocal] = useLocalForageState<StockState>(
    { stock: [], sealedStock: [], fetchedAt: new Date().toLocaleString('sk-SK') },
    `${user?.cardmarketUsername}-stock`,
    {
      duckFunction: (value) => value.stock.find((article) => article.card._id),
      hydrate: (value) => ({
        ...value,
        stock: value.stock.map((article) => createInventoryArticle(article)),
        sealedStock: value.sealedStock.map((article) => createInventoryArticle(article)),
      }),
    }
  )
  const activeStockType = useStockStore((state) => state.activeStockType)

  useEffect(() => {
    if (!loadingFromLocal && !inventoryState.stock.length) {
      fetchStock()
    }
  }, [loadingFromLocal])

  useEffect(() => {
    if (loadingFromLocal === true) setFetchState(InventoryFetchState.FETCHING)
    if (loadingFromLocal === false) setFetchState(InventoryFetchState.IDLE)
  }, [loadingFromLocal])

  const fetchStock = useCallback(async () => {
    setFetchState(InventoryFetchState.FETCHING)
    setFetchingDialogOpen(true)
    try {
      if (!user?.cardmarketUsername) throw "You don't have a Cardmarket account connected."
      const stockData = await fetchUserStock(activeGame.idGame)
      const sealedStockData = await fetchUserStock(activeGame.idGame, true)
      const genericSealedStockData = await fetchUserStock(activeGame.idGame, true, true)
      sealedStockData.push(...genericSealedStockData)

      const newState = {
        stock: stockData,
        sealedStock: sealedStockData,
        fetchedAt: new Date().toLocaleString('sk-SK'),
      }

      setFetchState(InventoryFetchState.IDLE)
      setInventoryState(newState)
    } catch (error: any) {
      handle(error)
      if (error?.response?.data?.error?.message === 'missingCardmarketConnection') {
        setUser((user) => ({ ...user!, cardmarketUsername: '' }))
        setFetchState(InventoryFetchState.FAILED_DISCONNECTED_ACCOUNT)
        throw new Error('missingCardmarketConnection')
      } else {
        setFetchState(InventoryFetchState.FAILED_UNKNOWN)
      }
    }
    setFetchingDialogOpen(false)
  }, [user?.cardmarketUsername, activeGame.idGame, fetchUserStock])

  const someArticleEdited = Boolean(
    inventoryState.stock.find((article) => isArticleEdited(article))
  )

  const [confirmFetchStock, ConfirmFetchStockDialog] = useConfirm(
    t('stockPricing.areYouSureRefresh'),
    '',
    () => fetchStock()
  )

  const onFetchStock = useCallback(async () => {
    if (someArticleEdited) {
      confirmFetchStock()
    } else {
      fetchStock()
    }
  }, [someArticleEdited, fetchStock])

  const determineTab = useCallback((article: InventoryArticle): StockTab => {
    if (article.failedAutopricing) return 'failedAutopricing'
    else if (article.isEdited()) return 'edited'
    else return 'unedited'
  }, [])

  const splitStockByTab = useCallback(
    (stock: InventoryArticle[]) => {
      const stockByTab: Record<StockTab, InventoryArticle[]> = {
        unedited: [],
        edited: [],
        failedAutopricing: [],
      }

      stock.forEach((article) => {
        stockByTab[determineTab(article)].push(article)
      })

      return stockByTab
    },
    [determineTab]
  )
  if (user?.subscription.allFeaturesDisabled || user?.featureFlags?.banned) {
    return <Redirect to="/" />
  }

  const refreshStockAction = useMemo(
    () => ({
      key: 'get-stock-btn',
      fn: onFetchStock,
      Icon: RefreshStockIcon,
      actionLabel: t('stockPricing.actions.refreshStock'),
      tooltip: (fetchedAt: string) => (
        <>
          <Typography variant="body2">
            {t('stockPricing.stockFetchedOn')} <b>{fetchedAt}</b>
          </Typography>
          <Typography variant="body2">
            <b>{t('stockPricing.clickToRefresh')}</b>
          </Typography>
        </>
      ),
    }),
    [onFetchStock]
  )

  const extraDockActions = [
    {
      key: 'singles-stock-btn',
      Icon: SinglesStockIcon,
      actionLabel: t('stockPricing.actions.singlesStock'),
      fn: () => setActiveStockType(STOCK_TYPES.SINGLES),
    },
    {
      key: 'sealed-stock-btn',
      Icon: SealedIcon,
      actionLabel: t('stockPricing.actions.sealedStock'),
      fn: () => setActiveStockType(STOCK_TYPES.SEALED),
    },
  ]

  const openPublishDialog = useCallback(() => {
    setPublishChangesDialogOpen(true)
  }, [setPublishChangesDialogOpen])

  const emptyStockText = {
    [InventoryFetchState.FETCHING]: t('stockPricing.loadingStock'),
    [InventoryFetchState.IDLE]: t('stockPricing.noStock'),
    [InventoryFetchState.FAILED_DISCONNECTED_ACCOUNT]: t('error.fetchStock'),
    [InventoryFetchState.FAILED_UNKNOWN]: t('error.fetchStockUnknown'),
  }[fetchState]

  const handleChangesPublished = (failed: PublishChangesResponse) => {
    if (failed && failed.length > 0) {
      setPublishFailures(failed)
      setPublishErrorsDialogOpen(true)
    }
    fetchStock()
  }

  return (
    <>
      <InventoryPresenter
        tabs={tabs}
        determineTab={determineTab}
        splitStockByTab={splitStockByTab}
        emptyStockText={emptyStockText}
        stockState={inventoryState}
        setStockState={setInventoryState}
        refreshStockAction={refreshStockAction}
        extraDockActions={extraDockActions}
        openPublishDialog={openPublishDialog}
      />
      <FetchingDialog
        title={t('stockPricing.loadingStock')}
        content={
          <>
            <Box marginTop={3} height="8rem" width="fit-content" margin="0 auto">
              <CircularProgress size={100} />
            </Box>
            <Box marginTop={2} width="fit-content">
              <Typography variant="body1">{t('stockPricing.loadingStockSlow')}</Typography>
            </Box>
          </>
        }
      />
      <ConfirmFetchStockDialog />
      {publishChangesDialogOpen && (
        <PublishChangesDialog
          articles={
            activeStockType === 'singles'
              ? inventoryState.stock.filter((item) => item.isEdited() && item.idArticle)
              : inventoryState.sealedStock.filter((item) => item.isEdited() && item.idArticle)
          }
          handleChangesPublished={handleChangesPublished}
          open={publishChangesDialogOpen}
          setOpen={setPublishChangesDialogOpen}
        />
      )}
      <PublishErrorsDialog
        title={t('publish.error')}
        content={
          <Box>
            {publishFailures?.map((f) => (
              <>
                <LocalArticle article={f.article} />
                {f.attributeError}
              </>
            ))}
          </Box>
        }
      />
    </>
  )
}
