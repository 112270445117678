import { Box, Dialog, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useTranslation } from 'react-i18next'
import { Order } from 'shared'

interface RequestCancellationDialogProps {
  order?: Order
  open: boolean
  onClose: () => void
  onConfirm: (reason: string, relistItems: boolean) => void
}

export const RequestCancellationDialog = ({
  order,
  open,
  onClose,
  onConfirm,
}: RequestCancellationDialogProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Box padding={2}>
        <Box padding={2}>
          <Typography variant="body1">
            {t('orders.confirmPackOrder', { idOrder: order?.idOrder })}
          </Typography>
        </Box>
        <Box marginTop={1} style={{ float: 'right' }}>
          <Button variant="outlined" onClick={onClose} style={{ marginRight: '15px' }}>
            {t('common.close')}
          </Button>
          <Button variant="contained" onClick={() => onConfirm('', false)}>
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
