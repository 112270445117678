//import cardData from "../data/cardData";
import { SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import axios from 'axios'
import { Playset3Icon, Playset4Icon } from 'components/facets'
import { OptionsObject } from 'notistack'
import {
  AppCurrency,
  Article,
  Card,
  CardDataItem,
  CardmarketAccount,
  getGameMetaData,
  InventoryArticle,
  Order,
  Product,
  RichArticle,
  User,
} from 'shared'
import { fetchProducts } from './api'

export const getArticleQty = (article: InventoryArticle): number =>
  typeof article.newQuantity === 'number' ? article.newQuantity : article.quantity

export const isArticleEdited = (article: InventoryArticle): boolean =>
  typeof article.newComment === 'string' ||
  typeof article.newPrice === 'number' ||
  typeof article.newQuantity === 'number'

export const getPlaysetIcon = (
  playsetCount: number
): OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> => {
  if (playsetCount === 3) return Playset3Icon
  else return Playset4Icon
}

export const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const currentCardmarketAccount = (
  user: User,
  shouldThrow = false
): CardmarketAccount | null => {
  const account = user.assignedCardmarketAccounts.find(
    ({ username }) => username === user.cardmarketUsername
  )
  if (!account) {
    if (shouldThrow) throw new Error('could not find assigned account')
    else {
      console.log('could not find assigned account')
      return null
    }
  }
  return account
}

export const attributesToRichArticle = (
  attributes: Article['attributes'],
  cardData: Card[]
): RichArticle => {
  const getCardByProductId = (idProduct: number): CardDataItem | undefined => {
    const found = cardData.find((card) => card._id === idProduct)
    return found?.toPlainObject()
  }
  const article = new Article({ attributes })

  return new RichArticle({
    ...article,
    card: getCardByProductId(Number(article.idProduct))!,
  })
}

export const getProperty = (
  propertyName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string | number | symbol, any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string | number | symbol, any> => {
  const parts = propertyName.split('.')
  const length = parts.length
  let i,
    property = object || this

  for (i = 0; i < length; i++) {
    property = property[parts[i]]
  }

  return property
}

export const formatNumber = (content: string | number, unit: AppCurrency | null): string => {
  content = (Math.round(Number(content) * 10000) / 10000).toString()

  let isCurrency = false
  if (['EUR', 'GBP', 'USD'].includes(unit || '')) isCurrency = true
  // eslint-disable-next-line prefer-const
  let [beforeDecimal, afterDecimal] = content.split('.')

  if (isCurrency && afterDecimal?.length === 1) afterDecimal += `0`

  const readableAmount = beforeDecimal
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .concat(afterDecimal ? `.${afterDecimal}` : '')
  if (isCurrency)
    content = `${readableAmount}${afterDecimal ? currencySymbol(unit as AppCurrency) : ''}`

  return content
}

export const calcPriceDiff = (price: number, originalPrice: number): number => {
  return Math.floor((price / originalPrice - 1) * 10000) / 100
}

export const exposeToCypress = (obj: Record<string, unknown>): void => {
  //@ts-ignore
  if (window.Cypress) Object.keys(obj).forEach((key) => (window[key] = obj[key]))
}

export const handleError = (
  error: Error,
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined
  ) => React.ReactText
): void => {
  if (axios.isAxiosError(error)) {
    enqueueSnackbar(error?.response?.data.error.message || 'Unknown error occured', {
      variant: 'error',
    })
  } else {
    enqueueSnackbar(error.message, { variant: 'error' })
  }
}

export const currencySymbol = (currency: AppCurrency = 'EUR'): string => {
  const currencyMap = {
    EUR: '€',
    GBP: '£',
  }

  return currencyMap[currency]
}

export const getCurrencySymbol = (currency: string): string => {
  const currencyMap: {
    [key: string]: string
  } = {
    EUR: '€',
    GBP: '£',
  }

  return currencyMap[currency]
}

export const getPriceFormat = (price: number, currency: string): string => {
  if (price !== undefined && price !== null && price - Math.floor(price) !== 0) {
    return `${String((Math.round(price * 100) / 100).toFixed(2))} ${getCurrencySymbol(currency)}`
  }
  return `${price.toString()} ${getCurrencySymbol(currency)}`
}

export const getUniqueNewName = (name: string, entities: { name: string }[]): string => {
  let uniqueName = name

  const num = entities.filter((entity) => entity.name.includes(name)).length

  if (num) {
    for (let i = 2; i < num + 2; i++) {
      if (!entities.filter((entity) => entity.name.includes(`${name} (${i})`)).length)
        uniqueName = `${name} (${i})`
    }
  }
  return uniqueName
}

export const localizeArticles = async (
  articles: InventoryArticle[]
): Promise<InventoryArticle[]> => {
  const locArticles: InventoryArticle[] = []
  const ids = articles.map((a) => a.card._id)
  const products = await fetchProducts(ids)

  // create product map for O(1) retrieval
  const productMap: {
    [key: number]: Product
  } = {}
  products.forEach((p) => {
    productMap[p._id] = p
  })

  articles.forEach((a) => {
    // const p = productMap[a.card._id]
    // if (p) {
    //   a.nameDE = p.locNames.DE
    //   a.nameES = p.locNames.ES
    //   a.nameFR = p.locNames.FR
    //   a.nameIT = p.locNames.IT
    //   a.rarity = p.rarity
    // }
    locArticles.push(a)
  })

  return locArticles
}

export const truncateText = (text: string, maxChars: number) => {
  let newText = text
  if (newText.length > maxChars) {
    newText = newText.slice(0, maxChars - 3) + '...'
  }
  return newText
}

export const getPickingList = (order: Order) => {
  if (order.pt.picking && order.pt.picking.pickingList) {
    return order.pt.picking.pickingList
  }
  return ''
}

export const getPickingId = (order: Order) => {
  if (order.pt.picking && order.pt.picking.pickingId) {
    return order.pt.picking.pickingId
  }
  return ''
}

export const getPickingProgress = (order: Order) => {
  if (order.pt.picking && order.pt.picking.pickingList) {
    const numPickedArticles = order.pt.article
      ? order.pt.article.filter((a) => a.articleState === 'picked').length
      : 0
    return Math.round((numPickedArticles / order.articleCount) * 100)
  } else {
    return -1
  }
}

export const getPlaysetCoefficient = (idGame: number) => {
  const game = getGameMetaData(idGame)
  return game.playsetCount || 1
}

export const getGameCodeFromName = (name?: string) => {
  const gameCodeMap: {
    [key: string]: string
  } = {
    Magic: 'mtg',
    Yugioh: 'yug',
    Pokemon: 'pok',
    Vanguard: 'van',
    Digimon: 'dig',
    FAB: 'fab',
    FF: 'ff',
    DBS: 'dbs',
    OnePiece: 'op',
    Lorcana: 'lor',
    BSS: 'bss',
    SWU: 'swu',
  }
  if (name) {
    return gameCodeMap[name]
  } else {
    return undefined
  }
}
