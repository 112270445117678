import { SignUpPresenter } from 'components/domain/auth'
import { AuthLayout } from 'components/facets'

export const SignUp = (): JSX.Element => {
  return (
    <AuthLayout>
      <SignUpPresenter />
    </AuthLayout>
  )
}
