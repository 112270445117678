import create from 'zustand'

export interface StockStoreState {
  activeStockType: string
  setActiveStockType: (newActiveStockType: string) => void
}

export const useStockStore = create<StockStoreState>()((set) => ({
  activeStockType: 'singles',
  setActiveStockType: (newActiveStockType: string) => set({ activeStockType: newActiveStockType }),
}))
