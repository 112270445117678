import { Box, Grid, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { ExistingStockPresenter } from 'components/domain/addArticles/AddArticleDock/ExistingStock/ExistingStockPresenter'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import playsetIcon from 'img/playsetIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { useHotkeyConfig } from 'lib/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { CardLanguage, isSingle, RichArticle } from 'shared'
import { SetState } from 'types'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { Condition } from './Condition'

interface HotkeyLegendProps {
  addByExp?: boolean
  disabled?: boolean
  article: RichArticle
  onClick: () => void
  setInputs: SetState<RichArticle>
  showExistingStock: boolean
}

interface HotkeyProps {
  hotkey: string[]
}

const keyToKeyCode = (key: string): number => {
  if (key.length === 1) return key.toUpperCase().charCodeAt(0)
  else if (key.toLowerCase() === 'del') return 46
  else if (key.toLowerCase() === 'esc') return 46
  else if (key.toLowerCase() === 'enter') return 13
  else if (key.toLowerCase() === 'spacebar') return 13
  else if (key.toLowerCase() === 'enter / spacebar') return 13
  return 0
}

const SIZE = 16

export const HotkeyLegend = ({
  addByExp,
  disabled,
  onClick,
  article,
  showExistingStock,
  setInputs,
}: HotkeyLegendProps): JSX.Element => {
  const { t } = useTranslation()

  const defaultHotkeys = [
    ['c', t('addArticles.hotkeys.create&copy'), 'create&copy'],
    // ['del', t('addArticles.hotkeys.back'), 'back'],
    ['enter / spacebar', t('addArticles.hotkeys.confirm'), 'confirm'],
    ['esc', t('addArticles.hotkeys.cancel'), 'cancel'],
  ]

  const { palette } = useTheme()

  const isSealedArticle = !isSingle(article.card)

  if (addByExp)
    defaultHotkeys.push(
      ...[
        ['v', 'prev', 'prev'],
        ['b', 'next', 'next'],
      ]
    )

  const hotkeys = [...defaultHotkeys, ...useHotkeyConfig()]

  const Hotkey = ({ hotkey }: HotkeyProps): JSX.Element | null => {
    if (!hotkey) return null
    const [, attribute, value] = hotkey
    const [isPressed, setPressed] = useState(false)
    const Icon = ({ src }: { src: string }): JSX.Element => (
      <img
        alt="icon"
        style={{ display: 'block', margin: '0 auto' }}
        height={`${SIZE * 1.5}px`}
        src={src}
      />
    )

    const Legend = (): JSX.Element => {
      switch (attribute) {
        case 'condition':
          return <Condition id="HotkeyLegend-condition" value={value} />
        case 'language':
          return (
            <Flag
              style={{ padding: '0px 5px' }}
              //@ts-ignore
              fallback={<span>Unknown</span>}
              height={SIZE}
              code={LANGUAGE_MAP_ICONS[CardLanguage[value as keyof typeof CardLanguage]] || 'gg'}
              data-testid={`${
                LANGUAGE_MAP_ICONS[CardLanguage[value as keyof typeof CardLanguage]]
              }-flag`}
            />
          )
        case 'isFoil':
          return <Icon src={foilIcon} />
        case 'isPlayset':
          return <Icon src={playsetIcon} />
        case 'isSigned':
          return <Icon src={signedIcon} />
        case 'isFirstEd':
          return <Icon src={firstEdIcon} />
        case 'isReverseHolo':
          return <Icon src={reverseHoloIcon} />
        default:
          return (
            <Box
              style={{
                fontSize: `${SIZE}PX`,
                fontWeight: 300,
                fontStyle: 'italic',
              }}
            >
              {attribute}
            </Box>
          )
      }
    }

    return (
      <Box
        minWidth="34px"
        padding="0 2px"
        onMouseUp={() => {
          setPressed(false)
          const keyEventConfig = {
            bubbles: true,
            keyCode: keyToKeyCode(hotkey[0]),
          }

          const event = new KeyboardEvent('keydown', keyEventConfig)
          document.activeElement?.dispatchEvent(event)
          const event2 = new KeyboardEvent('keyup', keyEventConfig)
          document.activeElement?.dispatchEvent(event2)
        }}
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (!disabled) setPressed(true)
        }}
        onMouseLeave={() => {
          setPressed(false)
        }}
        style={{
          border: isPressed ? '1px solid grey' : `2px solid ${palette.primary.main}`,
          borderRadius: '5px',
          margin: `${SIZE / 8}px`,
          padding: `${SIZE / 4}px`,
          fontSize: `${SIZE}px`,
          minWidth: `${SIZE * 3}px`,
          color: palette.primary.main,
          cursor: 'pointer',
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px -2px rgba(0,0,0,0.5)',
        }}
      >
        <Box>{<Legend />}</Box>
        <Box textAlign="center" fontWeight="bold">
          {hotkey[0].toUpperCase()}
        </Box>
      </Box>
    )
  }

  return (
    <Box
      data-testid="hotkey-legend"
      textAlign="center"
      boxSizing="border-box"
      onClick={onClick}
      onMouseDown={(e) => {
        e.preventDefault()
        //e.stopPropagation()
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ opacity: disabled ? 0.2 : 'initial' }}>
            {!isSealedArticle && (
              <Box display="flex" sx={{ justifyContent: 'center' }}>
                {['q', 'w', 'e', 'r', 't', 'y', 'u'].map((letter) => (
                  <Hotkey key={letter} hotkey={hotkeys.find((hkey) => hkey[0] === letter)!} />
                ))}
              </Box>
            )}
            <Box display="flex" sx={{ justifyContent: 'center' }}>
              {['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'n', 'm', ','].map((letter) => (
                <Hotkey key={letter} hotkey={hotkeys.find((hkey) => hkey[0] === letter)!} />
              ))}
            </Box>
            {!isSealedArticle && (
              <Box display="flex" sx={{ justifyContent: 'center' }}>
                {['i', 'o', 'p', '[', ']'].map((letter) => (
                  <Hotkey key={letter} hotkey={hotkeys.find((hkey) => hkey[0] === letter)!} />
                ))}
              </Box>
            )}
            <Box display="flex" sx={{ justifyContent: 'center' }}>
              {['x', 'c', 'v', 'b', 'esc', 'enter / spacebar', 'del'].map((letter) => (
                <Hotkey key={letter} hotkey={hotkeys.find((hkey) => hkey[0] === letter)!} />
              ))}
            </Box>
            <Box display="flex" sx={{ justifyContent: 'center' }}>
              <Box margin={2}>
                <Typography variant="body1">{t('addArticles.hotkeys.titleExplain')}</Typography>
                <Typography variant="body1">{t('addArticles.hotkeys.tab')}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {showExistingStock && (
        <Box marginTop={1}>
          <ExistingStockPresenter setInputs={setInputs} article={article} />
        </Box>
      )}
    </Box>
  )
}
