import { Box, SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { CustomTooltip, Warning2ToneIcon } from 'components/facets'
import { useWindowDimensions } from '../../lib/hooks'

export interface WarningBoxProps {
  color: string
  tooltipText: string
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  toRight: boolean
}

export const WarningBox = ({ color, tooltipText, Icon, toRight }: WarningBoxProps): JSX.Element => {
  const { width } = useWindowDimensions()

  return (
    <Box>
      <CustomTooltip title={tooltipText as string}>
        <Box
          borderRadius="5px"
          border={`5px solid ${color}`}
          width={'75px'}
          borderBottom={`1px solid ${color}`}
          borderTop={`1px solid ${color}`}
          borderRight={`1px solid ${color}`}
          display="flex"
          alignItems="center"
          position={toRight && width > 1295 ? 'absolute' : ''}
          right={'0'}
        >
          <Box width={'40%'} margin={'auto'} marginTop={'4px'}>
            <Warning2ToneIcon fontSize="large" style={{ color: `${color}` }} />
          </Box>
          <Box width={'40%'} margin={'auto'} marginTop={'4px'}>
            <Icon fontSize="large" style={{ color: `${color}` }} />
          </Box>
        </Box>
      </CustomTooltip>
    </Box>
  )
}
