import { Box, Button, makeStyles, Switch, Typography } from '@material-ui/core'
import { CropOriginal, MoreHoriz, MoreVert, ViewList } from '@material-ui/icons'
import { changeArticleProp, getArticlesToPick, updatePickingList } from 'lib/api'
import { useOrderStore } from 'lib/hooks'
import { getPlaysetCoefficient } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderArticle } from 'shared'
import { ArticleSorter } from './ArticleSorter'
import { PickingImageDisplay } from './PickingImageDisplay'
import { PickingTable } from './PickingTable'
import { ReportIssueDialog } from './ReportIssueDialog'
import { SingleImagePicking } from './SingleImagePicking'

export interface PickingPresenterProps {
  handleChangeOrderState: (orderIds: number[], newState: string) => void
}

export const PickingPresenter = ({
  handleChangeOrderState,
}: PickingPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const activePickingList = useOrderStore((state) => state.activePickingList)
  const setActivePickingList = useOrderStore((state) => state.setActivePickingList)
  const selectedArticle = useOrderStore((state) => state.selectedArticle)
  const setSelectedArticle = useOrderStore((state) => state.setSelectedArticle)
  const articlesToPick = useOrderStore((state) => state.articlesToPick)
  const setArticlesToPick = useOrderStore((state) => state.setArticlesToPick)
  const articlesPicked = useOrderStore((state) => state.articlesPicked)
  const setArticlesPicked = useOrderStore((state) => state.setArticlesPicked)
  const articlesWithIssues = useOrderStore((state) => state.articlesWithIssues)
  const setArticlesWithIssues = useOrderStore((state) => state.setArticlesWithIssues)
  const setLastPicked = useOrderStore((state) => state.setLastPicked)
  const setArticlesReadyForSorting = useOrderStore((state) => state.setArticlesReadyForSorting)
  const expansionMap = useOrderStore((state) => state.expansionMap)
  const categoryMap = useOrderStore((state) => state.categoryMap)

  // const [articleBeingPicked, setArticleBeingPicked] = useState<OrderArticle | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [openReportIssueDialog, setOpenReportIssueDialog] = useState<boolean>(false)
  const [view, setView] = useState<string>('list')
  const [currentArticleIdx, setCurrentArticleIdx] = useState<number>(0)
  setCurrentArticleIdx
  const [oneClickPick, setOneClickPick] = useState<boolean>(() =>
    localStorage.getItem('oneClickPick') === 'true' ? true : false
  )
  setOneClickPick

  const useStyles = makeStyles(() => ({
    toBePickedStrip: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: 'white',
    },
    pickedStrip: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#70bf4c',
      color: 'white',
      width: 'calc(100vw - 300px) !important',
    },
    withIssuesStrip: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#f15e3e',
      color: 'white',
      width: 'calc(100vw - 300px) !important',
    },
    imageList: {
      flexWrap: 'nowrap',
      padding: '20px',
    },
  }))
  const styles = useStyles()

  useEffect(() => {
    if (activePickingList && activePickingList.state === 'open') {
      setLoading(true)
      setArticlesReadyForSorting(false)
      getArticlesToPick(activePickingList._id).then((data) => {
        const newArticlesToPick: OrderArticle[] = []
        const newArticlesPicked: OrderArticle[] = []
        const newArticlesWithIssues: OrderArticle[] = []
        data.forEach((a) => {
          a.product.expansionCode = expansionMap[a.product.expansion]?.code
          a.product.category = categoryMap[a.idProduct]?.category
          a.product.colors = categoryMap[a.idProduct]?.colors
          if (a.issues.length > 0) {
            newArticlesWithIssues.push(a)
          } else if (a.articleState === 'picked') {
            newArticlesPicked.push(a)
          } else {
            newArticlesToPick.push(a)
          }
        })
        setArticlesToPick(newArticlesToPick)
        setArticlesPicked(newArticlesPicked)
        setArticlesWithIssues(newArticlesWithIssues)

        setArticlesReadyForSorting(true)
        setLoading(false)
      })
    }
  }, [activePickingList])

  useEffect(() => {
    if (
      !loading &&
      articlesToPick.length === 0 &&
      (articlesPicked.length > 0 || articlesWithIssues.length > 0)
    ) {
      handleClosePickingList()
    }
  }, [articlesPicked, articlesWithIssues])

  const handleChooseAnotherList = () => {
    setActivePickingList(undefined)
  }

  const handleClosePickingList = async () => {
    if (activePickingList) {
      const newActivePickingList = await updatePickingList(activePickingList._id, 'state', 'closed')
      const pickedOrderIds = activePickingList.orders?.map((order) => order.orderId)
      handleChangeOrderState(pickedOrderIds, 'picked')
      setActivePickingList(newActivePickingList)
    }
  }

  const removeArticleToBePicked = (article: OrderArticle | null) => {
    const newArticlesToPick = [...articlesToPick]
    const idx = newArticlesToPick.findIndex((a) => a.idArticle === article?.idArticle)
    if (idx !== -1) {
      newArticlesToPick.splice(idx, 1)
    }
    setArticlesToPick(newArticlesToPick)
  }

  const addArticleToBePicked = (article: OrderArticle) => {
    const newArticlesToPick = [...articlesToPick]
    newArticlesToPick.push(article)
    setArticlesToPick(newArticlesToPick)
  }

  const handlePick = (article: OrderArticle) => {
    setLastPicked(article)
    if (oneClickPick)
      handleCompletePicking(
        article,
        article.isPlayset
          ? article.count * getPlaysetCoefficient(article.product.idGame)
          : article.count
      )
  }

  const addArticleToPickedList = (article: OrderArticle) => {
    const newArticlesPicked = [...articlesPicked]
    newArticlesPicked.push(article)
    setArticlesPicked(newArticlesPicked)
  }

  const addArticleToWithIssuesList = (article: OrderArticle) => {
    const newArticlesWithIssues = [...articlesWithIssues]
    newArticlesWithIssues.push(article)
    setArticlesWithIssues(newArticlesWithIssues)
  }

  const handleCompletePicking = async (article: OrderArticle, quantity: number) => {
    await changeArticleProp(article.idOrder, article.idArticle, 'articleState', 'picked')
    await changeArticleProp(article.idOrder, article.idArticle, 'pickedQuantity', quantity)
    if (quantity === article?.count) {
      removeArticleToBePicked(article)
      addArticleToPickedList(article)
    }
  }

  const handleReportIssue = (a: OrderArticle) => {
    setSelectedArticle(a)
    setOpenReportIssueDialog(true)
  }

  const handleIssues = (a: OrderArticle) => {
    setSelectedArticle(a)
    setOpenReportIssueDialog(true)
  }

  const handleBackToPicking = async (a: OrderArticle) => {
    const foundPickedIdx = articlesPicked.findIndex((picked) => picked.idArticle === a.idArticle)
    const foundWithIssuesIdx = articlesWithIssues.findIndex(
      (withIssues) => withIssues.idArticle === a.idArticle
    )
    if (foundPickedIdx !== -1) {
      await changeArticleProp(a.idOrder, a.idArticle, 'articleState', 'picking')
      const newArticlesPicked = [...articlesPicked]
      newArticlesPicked.splice(foundPickedIdx, 1)
      setArticlesPicked(newArticlesPicked)
    } else if (foundWithIssuesIdx !== -1) {
      a.issues = []
      await changeArticleProp(a.idOrder, a.idArticle, 'issues', [])
      const newArticlesWithIssues = [...articlesWithIssues]
      newArticlesWithIssues.splice(foundWithIssuesIdx, 1)
      setArticlesWithIssues(newArticlesWithIssues)
    }
    addArticleToBePicked(a)
    setLastPicked(undefined)
  }

  const handleCloseReportIssueDialog = () => {
    setSelectedArticle(undefined)
    setOpenReportIssueDialog(false)
  }

  const handleConfirmReportIssueDialog = async (issue: string) => {
    if (selectedArticle) {
      const newIssues = [...(selectedArticle.issues || [])]
      newIssues.push({
        description: issue,
        solved: false,
        solution: '',
      })
      selectedArticle.issues = newIssues
      await changeArticleProp(
        selectedArticle.idOrder,
        selectedArticle.idArticle,
        'issues',
        newIssues
      )
      // const mkmOrder = await changeArticleProp(
      //   selectedArticle.idOrder,
      //   selectedArticle.idArticle,
      //   'issues',
      //   newIssues
      // )
      // const newOrder = getOrderFromMkmOrder(mkmOrder)

      removeArticleToBePicked(selectedArticle)
      addArticleToWithIssuesList(selectedArticle)
    }
    handleCloseReportIssueDialog()
  }

  const handleChangeOneClickPick = () => {
    localStorage.setItem('oneClickPick', (!oneClickPick).toString())
    setOneClickPick(!oneClickPick)
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box padding={1}>
          <Typography color="primary">{t('orders.picking.title')}</Typography>
        </Box>

        <Box padding={1}>
          <Typography>List {activePickingList?.name}</Typography>
        </Box>
        <Box padding={1}>
          <Button variant="outlined" color="primary" onClick={handleChooseAnotherList}>
            {t('orders.picking.chooseAnotherList')}
          </Button>
        </Box>
        <Box marginLeft={1}>
          <Button
            variant="contained"
            color="primary"
            disabled={
              articlesToPick.length > 0 ||
              (articlesToPick.length === 0 && articlesWithIssues.length === 0)
            }
            onClick={handleClosePickingList}
          >
            {t('orders.picking.closeList')}
          </Button>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box padding={1}>
          <Typography color="primary">{t('orders.view.title')}</Typography>
        </Box>
        <Box padding={1}></Box>
        <Box padding={1}>
          <Button
            variant={view === 'list' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setView('list')}
          >
            <ViewList />
            <Box paddingRight={1} />
            {t('orders.view.list')}
          </Button>
        </Box>

        <Box padding={1}>
          <Button
            variant={view === 'images-vertical' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setView('images-vertical')}
          >
            <MoreVert />
            <Box paddingRight={1} />
            Vertical images
          </Button>
        </Box>

        <Box padding={1}>
          <Button
            variant={view === 'images-horizontal' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setView('images-horizontal')}
          >
            <MoreHoriz />
            <Box paddingRight={1} />
            Horizontal images
          </Button>
        </Box>

        <Box padding={1}>
          <Button
            variant={view === 'singleImage' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setView('singleImage')}
          >
            <CropOriginal />
            <Box paddingRight={1} />
            {t('orders.view.singleImage')}
          </Button>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box padding={1}>
          <Typography color="primary">{t('orders.sortBy')}</Typography>
        </Box>
        <ArticleSorter />
      </Box>

      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Box padding={1}>
          <Typography color="primary">One click pick</Typography>
        </Box>
        <Box padding={1}></Box>
        <Switch name="one-click-pick" checked={oneClickPick} onChange={handleChangeOneClickPick} />
      </Box>

      <Box padding={1}></Box>

      {view === 'list' && (
        <>
          <Box className={styles.toBePickedStrip}>
            <Typography variant="h6">To be picked</Typography>
            {articlesToPick.length > 0 ? (
              <PickingTable
                type="toPick"
                handlePick={handlePick}
                oneClickPick={oneClickPick}
                handleCompletePicking={handleCompletePicking}
                handleReportIssue={handleReportIssue}
              />
            ) : (
              <Typography>No articles here</Typography>
            )}
          </Box>
          <Box padding={1} />

          <Typography variant="h6">Picked</Typography>
          {articlesPicked.length > 0 ? (
            <PickingTable
              type="picked"
              oneClickPick={oneClickPick}
              handleBackToPicking={
                activePickingList?.state === 'open' ? handleBackToPicking : undefined
              }
            />
          ) : (
            <Typography>No articles here</Typography>
          )}
          <Box padding={1} />

          <Typography variant="h6">With issues</Typography>
          {articlesWithIssues.length > 0 ? (
            <PickingTable
              type="withIssues"
              oneClickPick={oneClickPick}
              handleIssues={handleIssues}
              handleBackToPicking={handleBackToPicking}
            />
          ) : (
            <Typography>No articles here</Typography>
          )}
        </>
      )}

      {view === 'images-vertical' && (
        <>
          <Typography variant="h6">To be picked</Typography>
          <PickingImageDisplay
            type="toPick"
            horizontalView={false}
            handlePick={handlePick}
            oneClickPick={oneClickPick}
            handleCompletePicking={handleCompletePicking}
            handleReportIssue={handleReportIssue}
          />

          <Typography variant="h6">Picked</Typography>
          <PickingImageDisplay
            type="picked"
            horizontalView={false}
            oneClickPick={oneClickPick}
            handleBackToPicking={handleBackToPicking}
          />

          <Typography variant="h6">With issues</Typography>
          <PickingImageDisplay
            type="withIssues"
            horizontalView={false}
            oneClickPick={oneClickPick}
            handleBackToPicking={handleBackToPicking}
          />
        </>
      )}

      {view === 'images-horizontal' && (
        <>
          <Typography variant="h6">To be picked</Typography>
          <PickingImageDisplay
            type="toPick"
            horizontalView={true}
            handlePick={handlePick}
            oneClickPick={oneClickPick}
            handleCompletePicking={handleCompletePicking}
            handleReportIssue={handleReportIssue}
          />

          <Typography variant="h6">Picked</Typography>
          <PickingImageDisplay
            type="picked"
            horizontalView={true}
            oneClickPick={oneClickPick}
            handleBackToPicking={handleBackToPicking}
          />

          <Typography variant="h6">With issues</Typography>
          <PickingImageDisplay
            type="withIssues"
            horizontalView={true}
            oneClickPick={oneClickPick}
            handleBackToPicking={handleBackToPicking}
          />
        </>
      )}

      {view === 'singleImage' && (
        <SingleImagePicking
          article={articlesToPick[currentArticleIdx]}
          handlePick={handlePick}
          oneClickPick={oneClickPick}
          handleCompletePicking={handleCompletePicking}
          handleReportIssue={handleReportIssue}
        />
      )}

      <ReportIssueDialog
        open={openReportIssueDialog}
        onClose={handleCloseReportIssueDialog}
        onConfirm={handleConfirmReportIssueDialog}
        type="article"
        order={undefined}
      />
    </>
  )
}
