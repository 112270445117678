import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { Button, CustomTooltip } from 'components/facets'
import { useExpansionData } from 'components/providers/CardDataProvider'
import { useUser } from 'components/providers/UserProvider'
import { useStockStore } from 'lib/hooks/useStockStore'
import { useTranslation } from 'react-i18next'
import { AnyYesNo, CARD_LANGUAGES, CONDITIONS, Expansion } from 'shared'
import { v4 as uuid } from 'uuid'
import { FilterDockProps } from '.'
import { Filter } from './Filter'

export const QUANTITY_FILTER_MAX = 24

const useStyles = makeStyles((theme) =>
  createStyles({
    positionRelative: {
      position: 'relative',
    },
    boldLabel: {
      '&.MuiFormLabel-filled': {
        color: theme.palette.active + '!important',
        fontWeight: 'bold',
      },
      '&.Mui-focused': {
        color: theme.palette.active,
      },
    },
    fontBold: {
      fontWeight: 'bold',
    },
    borderBottomColor: {
      '&:before': {
        borderBottom: 'none',
      },

      '&.Mui-focused': {
        borderBottom: '2px solid' + theme.palette.active + '!important',
      },

      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },

      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },

      '& MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none !important',
      },

      borderBottom: '2px solid' + theme.palette.active + '!important',
      fontWeight: 'bold',
    },
    borderStandard: {
      '& .MuiInput-underline:after': {
        borderBottom: '2px solid' + theme.palette.active + '!important',
      },
      borderBottom: '1px solid' + theme.palette.MUILightGray + '!important',
    },
  })
)

export const FilterPresenter = ({
  filters,
  filtersKey,
  defaultFilters,
  areFiltersTouched,
  setFiltersKey,
  setFilters,
}: Pick<
  FilterDockProps,
  'filters' | 'setFilters' | 'filtersKey' | 'setFiltersKey' | 'defaultFilters' | 'areFiltersTouched'
>): JSX.Element => {
  const expansions = useExpansionData()
  const { t } = useTranslation()

  const classes = useStyles()

  const { activeGame } = useUser()

  const handleChange = (name: string, newValue: unknown): void => {
    setFilters({ ...filters, [name]: newValue })
  }

  const handleResetFilters = (): void => {
    setFilters(defaultFilters)
    setFiltersKey(uuid())
  }

  const anyYesNoOptions = [
    <MenuItem key={'any'} value={AnyYesNo.Any}>
      {t('filters.any')}
    </MenuItem>,
    <MenuItem key={'false'} value={AnyYesNo.No}>
      {t('filters.no')}
    </MenuItem>,
    <MenuItem key={'true'} value={AnyYesNo.Yes}>
      {t('filters.yes')}
    </MenuItem>,
  ]

  const activeStockType = useStockStore((state) => state.activeStockType)

  return (
    <Box key={filtersKey} paddingRight={2} paddingLeft={2} paddingTop={1}>
      <Button
        size="small"
        variant="contained"
        onClick={handleResetFilters}
        disabled={!areFiltersTouched}
        data-testid="reset-filters-btn"
        fullWidth
        color={'primary'}
        style={{ marginBottom: '1rem' }}
      >
        {areFiltersTouched ? t('filters.resetFilters') : t('filters.noActiveFilters')}
      </Button>

      <Filter
        defaultValue={filters.name}
        setFilter={handleChange}
        label={activeStockType === 'singles' ? t('card.cardName') : t('card.itemName')}
        name="name"
      />

      <CustomTooltip
        placement={'top'}
        title={
          <Box padding={1}>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {'Comment Filter'}
            </Typography>{' '}
            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
              {
                'Search by word in the comments. If you want to search by multiple words, separate them by pressing the enter key'
              }
            </Typography>{' '}
            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
              {'Type "" to search for cards with no comment.'}
            </Typography>{' '}
          </Box>
        }
      >
        <Box>
          <Filter
            defaultValue={filters.comment}
            type="switchText"
            setFilter={handleChange}
            label={t('card.comment')}
            name="comment"
          />
        </Box>
      </CustomTooltip>

      <Autocomplete<Expansion>
        options={expansions}
        renderOption={(option) => option.name}
        value={filters.expansion}
        autoHighlight
        getOptionLabel={(option) => `${option.name} ${option.code}`}
        onChange={async (_e, newValue) => {
          handleChange('expansion', newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="expansion"
            margin="dense"
            label={t('card.expansion')}
            type="text"
            fullWidth
            InputLabelProps={{
              classes: {
                root: classes.boldLabel,
              },
            }}
            className={filters.expansion ? classes.borderBottomColor : classes.borderStandard}
          />
        )}
        getOptionSelected={(option, value) => option.name === value.name}
        style={{ marginBottom: '1rem' }}
      />

      {activeStockType === 'singles' && (
        <>
          <Box className={classes.positionRelative} style={{ padding: '3px' }}>
            <Filter
              type="slider"
              sliderProps={{
                valueLabelDisplay: 'auto',
                valueLabelFormat: (key: number) => CONDITIONS[key],
                step: 1,
                marks: true,
                min: 0,
                max: 6,
              }}
              defaultValue={filters.condition}
              name="condition"
              setFilter={handleChange}
              label={t('card.condition')}
              activeFilter={filters.condition[1] - filters.condition[0] !== 6}
            />
          </Box>
          <Box className={classes.positionRelative} style={{ padding: '3px' }}>
            <Filter
              type="slider"
              defaultValue={filters.rarity}
              sliderProps={{
                valueLabelDisplay: 'auto',
                valueLabelFormat: (key: number) => activeGame.rarities[key].filterLabel,
                step: 1,
                marks: true,
                min: 1,
                max: Object.keys(activeGame.rarities).length,
              }}
              name="rarity"
              setFilter={handleChange}
              label={t('card.rarity')}
              activeFilter={
                filters.rarity[0] !== 1 ||
                filters.rarity[1] !== Object.keys(activeGame.rarities).length
              }
            />
          </Box>
        </>
      )}
      <Box className={classes.positionRelative} style={{ padding: '3px' }}>
        <Filter
          defaultValue={filters.quantity}
          type="slider"
          sliderProps={{
            valueLabelDisplay: 'auto',
            step: 1,
            marks: true,
            min: 1,
            max: QUANTITY_FILTER_MAX,
          }}
          name="quantity"
          setFilter={handleChange}
          label={t('card.quantity')}
          activeFilter={filters.quantity[0] !== 1 || filters.quantity[1] !== QUANTITY_FILTER_MAX}
        />
      </Box>
      <Grid spacing={1} container className={classes.positionRelative} style={{ padding: '3px' }}>
        <Filter
          grid
          defaultValue={filters.minPrice}
          name="minPrice"
          setFilter={handleChange}
          label={t('filters.minPrice')}
          inputProps={{ type: 'number' }}
        />

        <Filter
          grid
          name="maxPrice"
          defaultValue={filters.maxPrice}
          setFilter={handleChange}
          label={t('filters.maxPrice')}
          inputProps={{ type: 'number' }}
        />
      </Grid>

      <Grid spacing={1} container className={classes.positionRelative}>
        <Filter
          type="select"
          menuitems={[
            <MenuItem key={'any'} value={'any'}>
              {t('filters.any')}
            </MenuItem>,

            ...CARD_LANGUAGES.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            )),
          ]}
          grid={true}
          setFilter={handleChange}
          defaultValue={filters.language}
          label={t('card.language')}
          name="language"
        />

        {activeStockType === 'singles' &&
          activeGame.extraAttr.map((attribute) => {
            //isSigned to signed
            const parsedAttribute = (attribute[2].toLowerCase() +
              attribute.slice(3)) as keyof typeof filters
            return (
              <Filter
                type="select"
                key={attribute}
                grid={true}
                menuitems={anyYesNoOptions}
                setFilter={handleChange}
                defaultValue={filters[parsedAttribute]}
                label={t(`filters.${parsedAttribute}`)}
                name={parsedAttribute}
              />
            )
          })}

        <Filter
          type="select"
          menuitems={anyYesNoOptions}
          grid={true}
          setFilter={handleChange}
          defaultValue={filters.selected}
          label={t('filters.selected')}
          name="selected"
        />
      </Grid>
    </Box>
  )
}
