import { Box, Checkbox, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { HelpText } from 'components/facets'
import { PowerToolsPaper } from 'components/facets/PowerToolsPaper'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Competitor, CompetitorTactic, PricingStrategy } from 'shared'
import { CompetitorBox } from '../../CompetitorBox'
import { StepBox } from '../StepBox'

interface CompetitorParameterPresenterProps {
  strategy: PricingStrategy
  chosenCompetitor: Competitor
  onNext: (parameterType: CompetitorTactic['parameterType'], parameterValue: number) => void
}

const RankInputField = withStyles((theme) => ({
  root: {
    width: '24px',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '& .MuiInput-input': {
      fontSize: 36,
      color: theme.palette.primary.main,
      padding: 2,
      textAlign: 'center',
    },
  },
}))(TextField) as typeof TextField

const PercentileInputField = withStyles((theme) => ({
  root: {
    width: '48px',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '& .MuiInput-input': {
      fontSize: 36,
      color: theme.palette.primary.main,
      padding: 2,
      textAlign: 'center',
    },
  },
}))(TextField) as typeof TextField

export const CompetitorParameterPresenter = ({
  strategy,
  onNext,
  chosenCompetitor,
}: CompetitorParameterPresenterProps): JSX.Element => {
  const { t } = useTranslation()

  const currentParameter = strategy.base.tactic.parameter
  const currentParameterType = strategy.base.tactic.parameterType

  const [chosenType, setChosenType] = useState<null | 'rank' | 'percentile'>(
    currentParameterType || null
  )

  const [rankInputValue, setRankInputValue] = useState(
    chosenType === 'rank' ? String(currentParameter) : '1'
  )
  const [percentileInputValue, setPercentileInputValue] = useState(
    chosenType === 'percentile' ? String(currentParameter) : '25'
  )

  return (
    <StepBox
      disableNext={!chosenType}
      onNext={() => {
        if (chosenType === 'rank') {
          onNext('rank', Number(rankInputValue) || 1)
        } else if (chosenType === 'percentile') {
          onNext('percentile', Number(percentileInputValue) || 25)
        }
      }}
    >
      <Typography gutterBottom variant="body1">
        {t('strategy.create.choosePreference')}
      </Typography>

      <PowerToolsPaper elevation={3}>
        <Box data-testid={'rank-parameter-box'} display="flex" alignItems="center">
          <Box>
            <Checkbox
              color="primary"
              checked={chosenType === 'rank'}
              onClick={() => setChosenType('rank')}
            />
          </Box>
          <Typography
            variant="h6"
            style={{
              width: 'fit-content',
              display: 'inline-block',
            }}
          >
            {t('strategy.create.IWantToBeThe')}
          </Typography>
          <RankInputField
            onChange={(e) => {
              let newVal = e.target.value.replace(/\D/g, '')
              if (newVal.length > 1) newVal = newVal.slice(1)
              if (newVal === '0') return
              setRankInputValue(newVal)
            }}
            value={rankInputValue}
            onBlur={() => {
              if (!rankInputValue) setRankInputValue('1')
            }}
            onFocus={(event) => {
              setChosenType('rank')

              event.target.select()
            }}
            data-testid="number-of-cheapest-offer"
          />
          <Typography
            variant="h6"
            style={{
              width: 'fit-content',
              display: 'inline-block',
            }}
          >
            . {t('strategy.create.cheapestOfferAmong')}{' '}
            {chosenCompetitor && (
              <HelpText tooltip={<CompetitorBox competitor={chosenCompetitor} />}>
                {chosenCompetitor.name}
              </HelpText>
            )}
          </Typography>
        </Box>
        <Box marginLeft={1.5}>
          <Typography variant="caption" color="textSecondary">
            {t('strategy.create.rankExplainer')}
          </Typography>
        </Box>
      </PowerToolsPaper>
      <Box padding={2} style={{ textTransform: 'uppercase' }} textAlign="center">
        <Typography color="primary" variant="h5">
          <b>{t('common.or')}</b>
        </Typography>
      </Box>
      <PowerToolsPaper elevation={3}>
        <Box data-testid={'percentile-parameter-box'} display="flex" alignItems="center">
          <Box>
            <Checkbox
              color="primary"
              checked={chosenType === 'percentile'}
              onClick={() => setChosenType('percentile')}
            />
          </Box>
          <Typography
            variant="h6"
            style={{
              width: 'fit-content',
              display: 'inline-block',
            }}
          >
            {t('strategy.create.IWantToBeInThe')}
          </Typography>
          <PercentileInputField
            onChange={(e) => {
              let newVal = e.target.value.replace(/\D/g, '')
              if (newVal.length > 2) newVal = newVal.slice(1)
              if (newVal === '0') return

              setPercentileInputValue(newVal)
            }}
            onBlur={() => {
              if (!percentileInputValue) setPercentileInputValue('25')
            }}
            value={percentileInputValue}
            onFocus={(event) => {
              setChosenType('percentile')
              event.target.select()
            }}
            data-testid="number-of-percentile"
          />
          <Typography
            variant="h6"
            style={{
              width: 'fit-content',
              display: 'inline-block',
            }}
          >
            % {t('strategy.create.cheapestOffersAmong')}{' '}
            {chosenCompetitor && (
              <HelpText tooltip={<CompetitorBox competitor={chosenCompetitor} />}>
                {chosenCompetitor.name}
              </HelpText>
            )}
          </Typography>
        </Box>
        <Box marginLeft={1.5}>
          <Typography variant="caption" color="textSecondary">
            {t('strategy.create.percentileExplainer', { count: percentileInputValue as never })}
          </Typography>
        </Box>
        <Box marginLeft={1.5}>
          <Typography variant="caption" color="textSecondary">
            <b>{t('strategy.create.percentileExplainer2')}</b>
          </Typography>
        </Box>
      </PowerToolsPaper>
    </StepBox>
  )
}
