import { Box, Paper, PaperProps, SvgIconTypeMap, useTheme } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

export interface PowerToolsPaperProps extends PaperProps {
  disabled?: boolean
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
}

export const PowerToolsPaper = ({
  children,
  disabled,
  Icon,
  ...rest
}: PowerToolsPaperProps): JSX.Element => {
  const { palette } = useTheme()
  return (
    <Paper
      {...rest}
      style={{
        borderLeft: `3px solid ${disabled ? palette.lightGray : palette.primary.main}`,
        borderRadius: '5px',
        padding: '1rem',
        ...rest.style,
        position: 'relative',
      }}
    >
      {children}
      {Icon && (
        <Box right={25} top={70} position="absolute">
          <Icon style={{ fontSize: '4rem', color: palette.lightBlue }} />
        </Box>
      )}
    </Paper>
  )
}
