import { Grid } from '@material-ui/core'
import { ClickableBox } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { PricingStrategy } from 'shared'
import { PricingStrategyBox } from '../../PricingStrategyBox'

export interface ExistingStrategiesPresenterProps {
  hiddenStrategiesId?: string[]
  handleBoxClick: (strategy: PricingStrategy) => void
}

export const ExistingStrategiesPresenter = ({
  hiddenStrategiesId,
  handleBoxClick,
}: ExistingStrategiesPresenterProps): JSX.Element => {
  const { user } = useUser()

  return (
    <Grid container>
      {user.pricingStrategies
        .filter((pricingStrategy) => !hiddenStrategiesId?.includes(pricingStrategy._id))
        .map((pricingStrategy) => (
          <Grid
            item
            key={pricingStrategy._id}
            //className={strategyWrapper}
            data-testid={`strategy-${pricingStrategy._id}`}
          >
            <ClickableBox
              onClick={() => {
                handleBoxClick(pricingStrategy)
              }}
              width="400px"
            >
              <PricingStrategyBox
                pricingStrategy={pricingStrategy}
                competitors={user.competitors}
              />
            </ClickableBox>
          </Grid>
        ))}
    </Grid>
  )
}
