/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useEffect, useState } from 'react'
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

export enum ApiCallStatus {
  Idle,
  Fetching,
  Success,
  Error,
}

export const useApiCall = <T extends (...args: any[]) => Promise<unknown>>(
  call: (...args: any[]) => ReturnType<T>,
  args?: Parameters<T>,
  lazy = false
) => {
  const [state, setState] = useState<
    | {
        status: ApiCallStatus.Idle | ApiCallStatus.Fetching | ApiCallStatus.Error
        data: Awaited<ReturnType<T>> | undefined
      }
    | { status: ApiCallStatus.Success; data: Awaited<ReturnType<T>> }
  >({
    status: ApiCallStatus.Idle,
    data: undefined,
  })

  const performCall = async (...args: Parameters<T>) => {
    setState((state) => ({ status: ApiCallStatus.Fetching, data: state.data }))
    try {
      const response = await call(...args)
      setState({ status: ApiCallStatus.Success, data: response as Awaited<ReturnType<T>> })
    } catch (err) {
      setState({ status: ApiCallStatus.Error, data: undefined })
    }
  }

  useEffect(() => {
    if (!lazy && args) performCall(...args)
  }, [])

  return { ...state, performCall } as const
}
