import { Box, Typography } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { useLocalStorageState } from 'lib/hooks'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { BulkSettings, Competitor, PricingStrategy } from 'shared'
import { COUNTRIES } from 'utils/constants'
import { EditableElement } from '../EditableElement'
import { EntityBox } from '../EntityBox/EntityBox'
export interface CompetitorBoxProps {
  competitor: Competitor
  disableTitleRow?: boolean
  onDelete?: (id: string, opts?: string | null) => void
  onChange?: (newValue: Competitor) => void
  onClone?: (newValue: Competitor) => void
  disabled?: boolean
  selected?: boolean
}

export const REPUTATION_MAP: Record<number, string> = {
  1: 'Outstanding',
  2: 'Very Good',
  3: 'Good',
  4: 'Average',
}

export const FromCountries = ({
  countries,
  anywhereText,
}: {
  countries: string[]
  anywhereText: string
}): JSX.Element =>
  !countries.length ? (
    <>{anywhereText}</>
  ) : (
    <>
      {countries.map((country) => (
        <Flag
          key={country}
          style={{ padding: '0px 5px' }}
          //@ts-ignore
          fallback={<span>Unknown</span>}
          height="16"
          code={country === 'D' ? 'DE' : country}
          data-testid={`flag-${country === 'D' ? 'DE' : country}`}
        />
      ))}
    </>
  )

export const CompetitorBox = ({
  competitor,
  disableTitleRow = false,
  onDelete,
  onChange,
  onClone,
  disabled = false,
  selected = false,
}: CompetitorBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const viewOnly = !onDelete || !onChange
  const { level, countries, reputation, sellCount, name, excludedUsers } = competitor
  const { user } = useUser()
  const priceSuggestSettings = useLocalStorageState(
    { competitorId: user?.competitors[0]._id, strictLanguage: false, disablePlaysetPricing: false },
    'priceSuggestSettings'
  )[0]

  const handleChange = (
    newValue: unknown,
    attributeName?: keyof Competitor | keyof PricingStrategy | keyof BulkSettings
  ): void => {
    if (!attributeName) return
    const lastCharterDifferentOfSpace = /\S/.test(String(newValue))
    if (attributeName == 'name' && !lastCharterDifferentOfSpace) return
    onChange && onChange({ ...competitor, [attributeName]: newValue })
  }

  return (
    <EntityBox
      competitorOrStrategy
      disabled={disabled}
      viewOnly={viewOnly}
      key={competitor._id}
      onDelete={() => onDelete && onDelete(competitor._id, priceSuggestSettings.competitorId)}
      dataTestid={`delete-competitor-${name}`}
      onClone={onClone ? () => onClone?.(competitor) : undefined}
      hideTitleUnderline
      selected={selected}
      titleRow={
        disableTitleRow ? null : (
          <>
            {' '}
            <EditableElement
              title
              viewOnly={viewOnly}
              value={name}
              attribute="name"
              type="textfield"
              onChange={handleChange}
              existingValue={name}
              tooltipText={t('competitor.tooltip.name')}
              name={t('competitor.name')}
              dataTestid={`competitor-${name}`}
            />
          </>
        )
      }
      contentRow={
        <>
          <Typography style={{ marginLeft: '1px' }} variant="body2">
            <EditableElement
              key={competitor.level}
              showBorder
              attribute="level"
              viewOnly={viewOnly}
              onChange={handleChange}
              name={t('competitor.level')}
              dataTestid={`competitor-level-${name}`}
              tooltipText={t('competitor.tooltip.level')}
              type="select"
              options={[
                { value: 0, label: t('competitor.all') },
                { value: 1, label: 'Professional' },
                { value: 2, label: 'Powerseller' },
              ]}
              value={{ 0: t('competitor.all'), 1: 'Professional', 2: 'Powerseller' }[level]}
            />{' '}
            {t('competitor.sellers')}{' '}
            <EditableElement
              attribute="countries"
              onChange={handleChange}
              options={COUNTRIES.map((country) => ({
                value: country.code,
                label: country.name,
              }))}
              name={t('competitor.countries')}
              showBorder
              viewOnly={viewOnly}
              type="multiple-select"
              existingValue={competitor.countries}
              tooltipText={t('competitor.tooltip.countries')}
              value={t('competitor.from')}
              dataTestid={`competitor-countries-${name}`}
            />{' '}
            <FromCountries countries={countries} anywhereText={t('competitor.fromAnywhere')} />{' '}
            {t('competitor.withReputationOfAtLeast')}{' '}
            <EditableElement
              attribute="reputation"
              onChange={handleChange}
              existingValue={competitor.reputation}
              showBorder
              viewOnly={viewOnly}
              name={t('competitor.reputation')}
              dataTestid={`competitor-reputation-${name}`}
              tooltipText={t('competitor.tooltip.reputation')}
              options={Object.keys(REPUTATION_MAP).map((key) => ({
                value: key,
                label: REPUTATION_MAP[Number(key)],
              }))}
              value={REPUTATION_MAP[reputation]}
            />{' '}
            {t('competitor.andAtLeast')}{' '}
            <EditableElement
              existingValue={String(sellCount)}
              attribute="sellCount"
              onChange={handleChange}
              showBorder
              name={t('competitor.minSales')}
              viewOnly={viewOnly}
              tooltipText={t('competitor.tooltip.minSales')}
              type="number"
              value={String(sellCount || 0)}
              dataTestid={`competitor-minSales-${name}`}
            />{' '}
            {t('competitor.sales')}. {t('competitor.excludedUsers')}:
            <EditableElement
              existingValue={excludedUsers?.join('\n') || ''}
              viewOnly={viewOnly}
              showBorder
              attribute="excludedUsers"
              type="multiple-textfield"
              sameTooltipForViewOnly
              onChange={handleChange}
              value={excludedUsers?.join(', ') || t('competitor.none')}
              tooltipText={
                <Box>
                  {excludedUsers?.map((user) => (
                    <Box margin={0.5} key={user}>
                      {user}
                    </Box>
                  ))}
                  {t('competitor.tooltip.excludedUsers')}
                </Box>
              }
              name={t('competitor.excludedUsers')}
              dataTestid={`competitor-exclueded-user-textArea-${name}`}
            />
          </Typography>
        </>
      }
    />
  )
}
