import { Box, MenuItem, Select, TableSortLabel, Typography } from '@material-ui/core'
import { ArrowRightAlt, Warning } from '@material-ui/icons'
import { useUser } from 'components/providers/UserProvider'
import { useLocalStorageState, useWindowDimensions } from 'lib/hooks'
import { useIntroSnackbar } from 'lib/hooks/useIntroSnackbar'
import { useStockStore } from 'lib/hooks/useStockStore'
import * as React from 'react'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FixedSizeGrid as Grid } from 'react-window'
import { AppCurrency, InventoryArticle } from 'shared'
import { SetState } from 'types'
import { currencySymbol, getProperty } from '../../../../lib/utils'
import { VisualArticle } from './VisualArticle'

interface StockDisplayProps {
  openPriceSuggestDrawer: (article: InventoryArticle) => void
  selectArticle: (article: InventoryArticle) => void
  setStock: SetState<InventoryArticle[]>
  stockLength: number
  filteredStockLength: number
  revertArticleEdit: (article: InventoryArticle) => void
  sortRefresh: string
  articleWithChangedPriceExists: boolean
  emptyStockText: string
  viewOnly: boolean
  areFiltersTouched: boolean
  onDeleteArticle?: (article: InventoryArticle) => void
  totalFilteredStockValues: {
    price: number
    quantity: number
    newPrice: number
    newQuantity: number
  }
  filteredStock: InventoryArticle[]
  parentPage?: string
  selectedCount: number
  selectedStockValue: number
}
type sortFields =
  | 'card.cn'
  | 'card.name'
  | 'card.rarity'
  | 'card.expansionReleaseDate'
  | 'price'
  | 'customComputedField'
type sortTypes = 'string' | 'number' | '% diff'
type sortKeys = 'name' | 'collectorNo' | 'rarity' | 'expansionReleaseDate' | 'price' | 'percentDiff'

interface Sort {
  by: sortFields
  direction: 'asc' | 'desc' | undefined
  type: sortTypes
}

const rangeCardPerRow = (width: number): [number, number] => {
  switch (true) {
    case width > 1600:
      return [5, 9]
    case width > 1400:
      return [4, 7]
    case width > 1200:
      return [3, 6]
    default:
      return [2, 5]
  }
}

function areEqual(
  //@ts-ignore
  { data: prevData, rowIndex, columnIndex, style: prevStyle },
  //@ts-ignore

  { data: nextData, style: nextStyle }
): boolean {
  // Did the style (position/size) change?
  for (const key in prevStyle) {
    if (prevStyle[key] !== nextStyle[key]) {
      return false
    }
  }
  const index = columnIndex + rowIndex * prevData.cardsPerRow

  // Otherwise did this item's data change?
  return prevData.filteredStock[index] === nextData.filteredStock[index]
}

const HeaderRow = ({ children }: { children: ReactNode }): JSX.Element => (
  <Box
    //@ts-ignore
    padding={0.5}
    display="flex"
    alignItems="center"
  >
    {children}
  </Box>
)

const sortOptions: {
  title: string
  field: sortFields
  type: sortTypes
  key: sortKeys
}[] = [
  {
    title: 'card.name',
    field: 'card.name',
    type: 'string',
    key: 'name',
  },
  {
    title: 'card.collectorNo',
    field: 'card.cn',
    type: 'number',
    key: 'collectorNo',
  },
  {
    title: 'card.rarity',
    field: 'card.rarity',
    type: 'number',
    key: 'rarity',
  },
  {
    title: 'card.expansionReleaseDate',
    field: 'card.expansionReleaseDate',
    type: 'string',
    key: 'expansionReleaseDate',
  },
  {
    title: 'card.price',
    field: 'price',
    type: 'number',
    key: 'price',
  },
  {
    title: 'stockPricing.percentDiff',
    type: '% diff',
    field: 'customComputedField',
    key: 'percentDiff',
  },
]

const SortRow = ({
  changeSort,
  articleWithChangedPriceExists,
  cardsPerRow,
  setCardsPerRow,
  minCardPerRow,
  maxCardPerRow,
  sort,
}: {
  changeSort: (field: sortFields, type: sortTypes) => void
  articleWithChangedPriceExists: boolean
  cardsPerRow: number
  setCardsPerRow: (num: number) => void
  minCardPerRow: number
  maxCardPerRow: number
  sort: Sort
}): JSX.Element => {
  const { t } = useTranslation()
  const activeStockType = useStockStore((s) => s.activeStockType)

  return (
    <HeaderRow>
      {sortOptions.map(({ title, field, type, key }) => (
        <TableSortLabel
          key={title}
          active={sort.by === field}
          style={key !== 'percentDiff' || articleWithChangedPriceExists ? {} : { display: 'none' }}
          direction={sort.direction}
          onClick={() => changeSort(field, type)}
        >
          <Typography
            data-testid={`sort-${key}`}
            style={{ fontWeight: sort.by === field ? 'bold' : 400 }}
          >
            {t(title)}
          </Typography>
        </TableSortLabel>
      ))}

      <Typography variant="body1">
        {activeStockType === 'singles'
          ? t('stockPricing.cardsPerRow')
          : t('stockPricing.itemsPerRow')}
      </Typography>
      <Select
        value={cardsPerRow}
        onChange={(e) => {
          setCardsPerRow(e.target.value as number)
        }}
      >
        {[...Array(maxCardPerRow - minCardPerRow + 1).keys()].map((value) => (
          <MenuItem key={value + minCardPerRow} value={value + minCardPerRow}>
            {value + minCardPerRow}
          </MenuItem>
        ))}
      </Select>
    </HeaderRow>
  )
}

const Cell = React.memo(function Cell({
  style,
  rowIndex,
  columnIndex,
  data,
}: {
  style: React.CSSProperties
  rowIndex: number
  columnIndex: number
  data: {
    filteredStock: InventoryArticle[]
    cardsPerRow: number
    selectArticle: (article: InventoryArticle) => void
    onDeleteArticle?: (article: InventoryArticle) => void
    revertArticleEdit: (article: InventoryArticle) => void
    openPriceSuggestDrawer: (article: InventoryArticle) => void
    currency: AppCurrency
    viewOnly: boolean
  }
}): JSX.Element {
  const index = columnIndex + rowIndex * data.cardsPerRow
  const item = data.filteredStock[index]
  const { selectArticle, onDeleteArticle, revertArticleEdit, openPriceSuggestDrawer, currency } =
    data
  const columnWidth = Number(style.width)
  if (item)
    return (
      <div data-testid={`cell-${index}`} key={item.id} style={style}>
        <VisualArticle
          currency={currency}
          openPriceSuggestDrawer={openPriceSuggestDrawer}
          selectArticle={selectArticle}
          onDeleteArticle={onDeleteArticle}
          rowHeight={(columnWidth * 15) / 11}
          revertArticleEdit={revertArticleEdit}
          article={item}
          cardWidth={columnWidth}
          displayOnly={data.viewOnly}
          parentEl="StockDisplay"
        />
      </div>
    )
  else return <div style={style}></div>
}, areEqual)

const SealedWithPropertiesExplanation = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box display="flex">
        <Warning />
        <Typography variant="body1">
          <b>{t('stockPricing.sealedWithPropertiesTitle')}</b>
        </Typography>
        <Warning />
      </Box>
      <Box>
        <Typography variant="body2" gutterBottom>
          <Trans
            components={{
              1: <b></b>,
              2: <i></i>,
              3: <i></i>,
              4: <b></b>,
            }}
            i18nKey="stockPricing.sealedWithPropertiesContent1"
          ></Trans>
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t('stockPricing.sealedWithPropertiesContent2')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t('stockPricing.sealedWithPropertiesContent3')}
        </Typography>
      </Box>
    </Box>
  )
}

export const StockDisplay = React.memo(function StockDisplay({
  viewOnly,
  selectArticle,
  openPriceSuggestDrawer,
  revertArticleEdit,
  setStock,
  stockLength,
  filteredStockLength,
  articleWithChangedPriceExists,
  areFiltersTouched,
  sortRefresh,
  onDeleteArticle,
  totalFilteredStockValues,
  filteredStock,
  emptyStockText,
  parentPage,
  selectedCount,
  selectedStockValue,
}: StockDisplayProps): JSX.Element {
  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()
  const SIDEBAR_WIDTH = 450

  useIntroSnackbar('sealed-with-properties-explanation', <SealedWithPropertiesExplanation />)

  //$% todo fix emptyStockText

  const initialCardsPerRow = Math.floor((width - SIDEBAR_WIDTH) / 200)

  const [cardsPerRowSetting, setCardsPerRowSetting] = useLocalStorageState(
    initialCardsPerRow,
    'cardsPerRow'
  )

  const [cardsPerRow, setCurrentCardsPerRow] = useState(cardsPerRowSetting)
  const columnWidth = useMemo(() => (width - SIDEBAR_WIDTH) / cardsPerRow, [width, cardsPerRow])
  // const classes = useStyles()
  const [minCardPerRow, maxCardPerRow] = rangeCardPerRow(width)
  const { user } = useUser()
  const [sort, setSort] = useState<Sort>({
    by: 'card.name',
    direction: 'asc',
    type: 'string',
  })
  const activeStockType = useStockStore((state) => state.activeStockType)

  const setCardsPerRow = (num: number) => {
    setCardsPerRowSetting(num)
    setCurrentCardsPerRow(num)
  }

  const numOfCardsInStockRef = useRef<number>()
  const gridRef = React.createRef<Grid>()

  useEffect(() => {
    if (Number(numOfCardsInStockRef.current) + 1 === stockLength) {
      //@ts-ignore
      gridRef.current?.scrollToItem?.({ rowIndex: Math.ceil(filteredStockLength / cardsPerRow) })
    }
    numOfCardsInStockRef.current = stockLength
  }, [stockLength])

  useEffect(() => {
    if (stockLength) {
      setStock((stock) => applySort(stock))
    }
  }, [sort, sortRefresh])

  useEffect(() => {
    if (cardsPerRow > maxCardPerRow || cardsPerRow < minCardPerRow) {
      setCardsPerRow(initialCardsPerRow)
    }
  }, [minCardPerRow, maxCardPerRow, cardsPerRow])

  const changeSort = useCallback(
    (field: sortFields, type: sortTypes): void => {
      setSort({
        by: field,
        direction: sort.direction === 'asc' && sort.by === field ? 'desc' : 'asc',
        type,
      })
    },
    [setSort, sort]
  )

  const calcPriceDiff = ({ price, newPrice }: InventoryArticle): number => {
    if (!newPrice) return 0
    return Math.floor((newPrice / price - 1) * 10000) / 100
  }

  const stripChars = (str: string) => {
    if (typeof str === 'string') {
      while (/[^\d]/.test(str)) {
        str = str.replace(/[^\d]/, '')
      }
    }
    return str
  }

  const applySort = (stockToSort: InventoryArticle[]): InventoryArticle[] => {
    let finalStock = [...stockToSort.sort((a, b) => Number(b.idArticle) - Number(a.idArticle))]

    if (sort.type === 'string') {
      finalStock = finalStock.sort((a, b) => {
        const firstItem = getProperty(sort.by, a) || ''
        const secondItem = getProperty(sort.by, b) || ''
        return firstItem.localeCompare(secondItem) * (sort.direction === 'asc' ? 1 : -1)
      })
    } else if (sort.type === 'number')
      finalStock.sort((a, b) => {
        let firstItem: any = getProperty(sort.by, a)
        let secondItem: any = getProperty(sort.by, b)

        firstItem = Number(stripChars(firstItem))
        secondItem = Number(stripChars(secondItem))

        if (sort.by === 'price') {
          firstItem = Number(getProperty('newPrice', a) || firstItem)
          secondItem = Number(getProperty('newPrice', b) || secondItem)
        }

        if (firstItem === secondItem) {
          return 0
        } else return (firstItem > secondItem ? 1 : -1) * (sort.direction === 'asc' ? 1 : -1)
      })
    else if (sort.type === '% diff') {
      finalStock = finalStock.sort(
        (a, b) => (calcPriceDiff(a) - calcPriceDiff(b)) * (sort.direction === 'asc' ? 1 : -1)
      )
    }

    return finalStock
  }

  const getItemKey = useCallback(
    ({ columnIndex, data, rowIndex }) => {
      const index = columnIndex + rowIndex * cardsPerRow
      const item = data[columnIndex + rowIndex * cardsPerRow]
      if (item) return `${item.id}`
      else return `${index}`
    },
    [cardsPerRow]
  )

  const itemData = useMemo(
    () => ({
      cardsPerRow,
      filteredStock,
      selectArticle,
      openPriceSuggestDrawer,
      revertArticleEdit,
      onDeleteArticle,
      viewOnly,
      currency: user?.currency,
    }),
    [cardsPerRow, filteredStock]
  )

  const stockIsEmpty = !stockLength
  const stockIsHiddenByFilters = !!(stockLength && !filteredStockLength)

  return (
    <Box position="relative" style={{ backgroundColor: 'white' }}>
      {!stockIsEmpty && (
        <Box display="flex" justifyContent="space-between">
          <HeaderRow>
            <Box marginRight={1}>
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {parentPage !== 'ListingAndAppraisal' && (
                  <span>{t('stockPricing.results', { count: filteredStockLength })}</span>
                )}
                <span>{areFiltersTouched && ` ${t('stockPricing.filtersActive')}`}</span>
              </Typography>
            </Box>
            <Typography variant="overline" color="primary">
              {totalFilteredStockValues.quantity}
              {totalFilteredStockValues.quantity !== totalFilteredStockValues.newQuantity && (
                <>
                  <ArrowRightAlt style={{ verticalAlign: 'middle', marginBottom: '5px' }} />
                  {totalFilteredStockValues.newQuantity}
                </>
              )}{' '}
              {activeStockType === 'singles'
                ? t('autopriceArticles.summary.cards')
                : t('autopriceArticles.summary.articles')}
            </Typography>
            <Box width="1.5rem"></Box>
            <Typography variant="overline" color="primary">
              {totalFilteredStockValues.price}
              {currencySymbol(user?.currency)}
              {totalFilteredStockValues.price !== totalFilteredStockValues.newPrice && (
                <>
                  <ArrowRightAlt style={{ verticalAlign: 'middle', marginBottom: '5px' }} />
                  {totalFilteredStockValues.newPrice}
                  {currencySymbol(user?.currency)}
                </>
              )}{' '}
              {activeStockType === 'singles'
                ? t('stockPricing.cardValue')
                : t('stockPricing.articleValue')}
            </Typography>
            <Box width="1.5rem"></Box>
            <Typography variant="overline" color="primary">
              {t('stockPricing.selected', { count: selectedCount })}
            </Typography>
            {selectedCount > 0 && (
              <Typography variant="overline" color="primary" style={{ marginLeft: '4px' }}>
                ({selectedStockValue} {currencySymbol(user?.currency)})
              </Typography>
            )}
          </HeaderRow>
          <SortRow
            changeSort={changeSort}
            minCardPerRow={minCardPerRow}
            maxCardPerRow={maxCardPerRow}
            sort={sort}
            setCardsPerRow={setCardsPerRow}
            cardsPerRow={cardsPerRow}
            articleWithChangedPriceExists={articleWithChangedPriceExists}
          />
        </Box>
      )}
      {(stockIsEmpty || stockIsHiddenByFilters) && (
        <Box paddingTop={7} style={{ textAlign: 'center' }} width="100%">
          <Box>
            <Typography variant="h6">
              {stockIsEmpty && emptyStockText}
              {stockIsHiddenByFilters && t('filters.emptyFilter')}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        style={{
          textAlign: 'center',
          overflow: 'hidden',
          height: height - 120,
          width: cardsPerRow * columnWidth + 40,
        }}
      >
        <Grid
          ref={gridRef}
          data-testid="stock-display"
          className="Grid"
          columnCount={cardsPerRow}
          columnWidth={columnWidth}
          height={height - 127}
          rowCount={Math.ceil(filteredStockLength / cardsPerRow)}
          rowHeight={(columnWidth * 15) / 11}
          width={cardsPerRow * columnWidth + 20}
          itemData={itemData}
          itemKey={getItemKey}
        >
          {Cell}
        </Grid>
      </Box>
    </Box>
  )
})
