import { KaboomPresenter } from 'components/domain/dashboard/KaboomPresenter'
import { AppLayout } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const Kaboom = (): JSX.Element => {
  const { user } = useUser()
  if (user?.isAdmin) {
    return (
      <AppLayout>
        <KaboomPresenter />
      </AppLayout>
    )
  } else {
    return <Redirect to="/" />
  }
}
