import { Box, Grid, List, ListItem, Typography, useTheme } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ClosableDialog } from 'components/controls/dialogs'
import { SubscriptionPresenter } from 'components/domain/account'
import { CardmarketConnect } from 'components/domain/cardmarket'
import { AddArticlesIcon, BotIcon, Button, ImportIcon, StockPricingIcon } from 'components/facets'
import { PowerToolsPaper } from 'components/facets/PowerToolsPaper'
import { SquareButton } from 'components/facets/SquareButton'
import { useUser } from 'components/providers/UserProvider'
import { useWindowDimensions } from 'lib/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { FreeTierPresenter } from './FreeTierPresenter'

export const DashboardPresenter = (): JSX.Element => {
  const { user } = useUser()
  const { t } = useTranslation()
  const history = useHistory()
  const { width } = useWindowDimensions()

  const openDialog = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [openMobileDialog, setOpenMobileDialog] = useState(true)
  const theme = useTheme()
  let dashboardSize = {
    squareButtonSize: '300px',
    buttonGridSize: '340px',
    rowButtonSize: '680px',
    squareButtonPadding: '20px',
  }

  switch (true) {
    case width > 1550:
      dashboardSize = {
        squareButtonSize: '300px',
        buttonGridSize: '340px',
        rowButtonSize: '680px',
        squareButtonPadding: '20px',
      }
      break
    case width > 1200 && width < 1550:
      dashboardSize = {
        squareButtonSize: '250px',
        buttonGridSize: '290px',
        rowButtonSize: '530px',
        squareButtonPadding: '0px',
      }
      break
    case width > 960 && width < 1200:
      dashboardSize = {
        squareButtonSize: '220px',
        buttonGridSize: '260px',
        rowButtonSize: '520px',
        squareButtonPadding: '20px',
      }
      break
    case width < 960:
      dashboardSize = {
        squareButtonSize: '300px',
        buttonGridSize: '340px',
        rowButtonSize: '680px',
        squareButtonPadding: '20px',
      }
      break
  }

  const widthWarningDialog = width * 0.8
  const marginLeftWarningDialog = (width - widthWarningDialog) / 2
  const widthTextBoxWarningDialog = widthWarningDialog - 80

  const useStyles = makeStyles(() =>
    createStyles({
      bulletList: {
        backgroundColor: '#0019A2',
        width: '25px',
        height: '25px',
        color: '#ffffff',
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingTop: '2px',
        borderRadius: '50%',
      },
      noPaddingListItem: {
        '&.MuiListItem-gutters': {
          paddingLeft: '0px !important',
        },
      },
      paperPadding: {
        padding: '20px',
        marginBottom: '36px',
        float: 'right',
        width: width > 960 && width < 1200 ? '80%' : '',
        marginLeft: width > 960 && width < 1200 ? '20%' : '',
      },
      buttonGridSize: {
        width: dashboardSize.buttonGridSize,
        height: dashboardSize.buttonGridSize,
        padding: '20px',
      },
      rowButtonsSize: {
        display: 'flex',
        width: dashboardSize.rowButtonSize,
        margin: 'auto',
      },
      mobileDialog: {
        '& .MuiPaper-root': {
          position: 'absolute',
          top: '0',
          left: '0',
          maxWidth: '100%',
          marginTop: '25%',
          marginBottom: '30%',
          marginLeft: `${marginLeftWarningDialog}px`,
          [theme.breakpoints.down('sm')]: {
            maxWidth: `${widthWarningDialog}px`,
            minWidth: `${widthWarningDialog}px`,
          },
        },
        '& .MuiBox-root': {
          [theme.breakpoints.down('sm')]: {
            //64 is the sum of 32px paddingLeft and 32px paddingRight
            maxWidth: `${widthWarningDialog - 64}px`,
            minWidth: `${widthWarningDialog - 64}px`,
          },
        },
      },
      messageBox: {
        [theme.breakpoints.down('sm')]: {
          maxWidth: `${widthWarningDialog - 80}px`,
          minWidth: `${widthWarningDialog}px`,
        },
      },
    })
  )

  const classes = useStyles()

  function handleClick(page: string): void {
    history.push(page)
  }

  return (
    <Box>
      <Box>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
            <Typography variant="h4">
              {t('dashboard.welcome', { username: user?.username })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid item xs={12} sm={12} md={12}>
              <Box>
                {user?.cardmarketUsername ? (
                  <>
                    {user.subscription.allFeaturesDisabled ? (
                      <Box marginTop={5} width="450px" margin="0 auto">
                        <FreeTierPresenter />
                        <Box margin="0 auto" marginTop={2}>
                          <Box padding={2}>
                            <Typography
                              style={{ textAlign: 'center' }}
                              color="primary"
                              gutterBottom
                              variant="body1"
                            >
                              <b>Upgrade your subscription to get the full functionality!</b>
                            </Typography>
                            <PowerToolsPaper>
                              <SubscriptionPresenter subscription={user.subscription} />
                            </PowerToolsPaper>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Typography
                          variant="body1"
                          style={{
                            marginTop: '30px',
                            marginBottom: '15px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          {t('dashboard.whatDoYouDoToday')}
                        </Typography>
                        <Box className={classes.rowButtonsSize}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.buttonGridSize}
                            style={{ paddingLeft: dashboardSize.squareButtonPadding }}
                          >
                            <SquareButton
                              type={'action'}
                              size={dashboardSize.squareButtonSize}
                              IconSize={width > 1550 || width < 960 ? '60px' : '40px'}
                              Icon={AddArticlesIcon}
                              Title={t('dashboard.addArticles')}
                              Description={t('dashboard.addArticlesDesc')}
                              OnClickAction={() => handleClick('/listing-and-appraisal')}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.buttonGridSize}
                            style={{ paddingLeft: dashboardSize.squareButtonPadding }}
                          >
                            <SquareButton
                              type={'action'}
                              size={dashboardSize.squareButtonSize}
                              IconSize={width > 1550 || width < 960 ? '60px' : '40px'}
                              Icon={ImportIcon}
                              Title={t('dashboard.importFile')}
                              Description={t('dashboard.importFileDesc')}
                              OnClickAction={() =>
                                handleClick('/listing-and-appraisal?importCsv=true')
                              }
                            />
                          </Grid>
                        </Box>
                        <Box className={classes.rowButtonsSize}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.buttonGridSize}
                            style={{ paddingLeft: dashboardSize.squareButtonPadding }}
                          >
                            <SquareButton
                              type={'action'}
                              size={dashboardSize.squareButtonSize}
                              IconSize={width > 1550 || width < 960 ? '60px' : '40px'}
                              Icon={StockPricingIcon}
                              Title={t('dashboard.stockPricing')}
                              Description={t('dashboard.stockPricingDesc')}
                              OnClickAction={() => handleClick('/stock-pricing')}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.buttonGridSize}
                            style={{ paddingLeft: dashboardSize.squareButtonPadding }}
                          >
                            <SquareButton
                              type={'action'}
                              size={dashboardSize.squareButtonSize}
                              IconSize={width > 1550 || width < 960 ? '60px' : '40px'}
                              Icon={BotIcon}
                              Title={'The Pricing Bot'}
                              Description={t(`dashboard.pricingBot.enabled`)}
                              OnClickAction={() => handleClick(`/pricing-bot`)}
                            />
                          </Grid>
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="body1" style={{ marginTop: '30px' }}>
                      {t('dashboard.mtgPowerToolsAbleTo')}
                    </Typography>

                    <Grid item xs={12} sm={12} md={12} style={{ display: 'inline-flex' }}>
                      <Box marginTop={2}>
                        <List>
                          <ListItem className={classes.noPaddingListItem}>
                            <Box className={classes.bulletList}>1</Box>
                            <Typography variant="body1">
                              &nbsp;&nbsp;&nbsp;{t('dashboard.priceCards')}
                            </Typography>
                          </ListItem>
                          <ListItem className={classes.noPaddingListItem}>
                            <Box className={classes.bulletList}>2</Box>
                            <Typography variant="body1">
                              &nbsp;&nbsp;&nbsp;{t('dashboard.listCards')}
                            </Typography>
                          </ListItem>
                          <ListItem className={classes.noPaddingListItem}>
                            <Box className={classes.bulletList}>3</Box>
                            <Typography variant="body1">
                              &nbsp;&nbsp;&nbsp;{t('dashboard.csvActions')}
                            </Typography>
                          </ListItem>
                        </List>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: '16px' }}>
                      <Typography variant="body1">{t('dashboard.firstOfAll')}</Typography>
                    </Grid>
                  </>
                )}
                {!user?.cardmarketUsername && (
                  <Grid item xs={12} sm={12} md={12} style={{ marginTop: '16px' }}>
                    <Box>
                      <CardmarketConnect big />
                    </Box>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {openDialog && (
        <>
          <Box alignItems={'center'} justifyContent={'center'}>
            <ClosableDialog
              maxWidth="xs"
              open={openMobileDialog}
              onClose={() => {
                setOpenMobileDialog(false)
              }}
              className={classes.mobileDialog}
            >
              <Box
                padding={3}
                paddingTop={2}
                width={`${widthTextBoxWarningDialog}px`}
                textAlign="center"
                className={classes.messageBox}
              >
                <Typography>{t('dashboard.noMobileSupported')}</Typography>
                <Typography style={{ marginTop: '5px' }}>
                  {t('dashboard.workInProgress')}
                </Typography>
                <Button
                  variant="contained"
                  style={{
                    marginTop: '16px',
                    width: `${widthTextBoxWarningDialog - 100}px`,
                    maxWidth: `${width > 600 ? '300px' : ''}`,
                  }}
                  onClick={() => {
                    setOpenMobileDialog(false)
                  }}
                >
                  {t('common.ok')}
                </Button>
              </Box>
            </ClosableDialog>
          </Box>
        </>
      )}
    </Box>
  )
}
