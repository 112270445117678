import { ClosableDialog, ClosableDialogProps } from 'components/controls/dialogs'
import { useHandleSettingsChange } from 'components/domain/stockPricing/StockPricingPresenter'
import { useUser } from 'components/providers/UserProvider'
import { createUserSubEntity } from 'lib/api'
import { getUniqueNewName } from 'lib/utils'
import { CreateCompetitorPresenter } from './CreateCompetitorPresenter'

export const CreateCompetitorDialog = ({ onClose, open }: ClosableDialogProps): JSX.Element => {
  const { user } = useUser()
  if (!user) return <></>

  const handleChange = useHandleSettingsChange()
  return (
    <ClosableDialog maxWidth="lg" onClose={onClose} open={open}>
      <CreateCompetitorPresenter
        newName={getUniqueNewName(`New Competitor`, user?.competitors)}
        onDone={async (strategy) => {
          try {
            await handleChange(() => createUserSubEntity(strategy, 'competitors'))
            onClose()
          } finally {
          }
        }}
      />
    </ClosableDialog>
  )
}
