import { Box, BoxProps, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((_theme) => ({
  root: {
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
}))

interface ClickableBoxProps extends BoxProps {
  disabled?: boolean
}

export const ClickableBox = ({
  children,
  onClick,
  disabled,
  ...props
}: ClickableBoxProps): JSX.Element => {
  const styles = useStyles()
  return (
    <Box
      className={onClick ? styles.root : disabled ? styles.disabled : undefined}
      onClick={onClick}
      {...props}
    >
      {children}
    </Box>
  )
}
