import { ClosableDialog, ClosableDialogProps } from 'components/controls/dialogs'
import { useHandleSettingsChange } from 'components/domain/stockPricing/StockPricingPresenter'
import { useUser } from 'components/providers/UserProvider'
import { createUserSubEntity, editUserAttribute } from 'lib/api'
import { getUniqueNewName } from 'lib/utils'
import { CreateStrategyPresenter } from './CreateStrategyPresenter'

export const CreateStrategyDialog = ({ onClose, open }: ClosableDialogProps): JSX.Element => {
  const { user, activeGame } = useUser()
  if (!user) return <></>

  const handleChange = useHandleSettingsChange()
  const bulkSettings = user.bulkSettings.filter((el) => {
    return el.gameId === activeGame.idGame
  })

  return (
    <ClosableDialog maxWidth="lg" onClose={onClose} open={open}>
      <CreateStrategyPresenter
        activeGame={activeGame}
        competitorNewName={getUniqueNewName(`New Competitor`, user?.competitors)}
        onCompetitorDone={async (competitor) => {
          try {
            await handleChange(() => createUserSubEntity(competitor, 'competitors'))
          } finally {
          }
        }}
        bulkSettings={bulkSettings}
        competitors={user?.competitors || []}
        newName={getUniqueNewName(`New Pricing Strategy`, user?.pricingStrategies)}
        competitionDisabled={user.subscription.featuresDisabled.includes(
          'stockPricing.competitionPricing'
        )}
        onDone={async (strategy) => {
          try {
            await handleChange(() => createUserSubEntity(strategy, 'pricingStrategies'))
            if (!user.canUsePricingBot) {
              await handleChange(() => editUserAttribute(true, 'canUsePricingBot'))
            }
            onClose()
          } finally {
          }
        }}
      />
    </ClosableDialog>
  )
}
