import { Box, Button, Typography } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { createPickingList } from 'lib/api'
import { useOrderStore } from 'lib/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPaidOrdersId } from 'shared'
import { SelectPickingListDialog } from './SelectPickingListDialog'

export interface PickingListToolbarProps {
  toolNamesWidth: string
  handleChangeOrderState: (orderIds: number[], newState: string) => void
}

export const PickingListToolbar = ({
  toolNamesWidth,
  handleChangeOrderState,
}: PickingListToolbarProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()

  const orders = useOrderStore((state) => state.orders)
  const selectedOrderIds = useOrderStore((state) => state.selectedOrderIds)
  const setSelectedOrderIds = useOrderStore((state) => state.setSelectedOrderIds)
  const [openSelectPickingListDialog, setOpenSelectPickingListDialog] = useState<boolean>(false)
  const [creatingPickingList, setCreatingPickingList] = useState<boolean>(false)
  const [numPaidOrders, setNumPaidOrders] = useState<number>(0)

  useEffect(() => {
    setNumPaidOrders(getPaidOrdersId(orders).length)
  }, [orders])

  const handleCreateFromSelected = async () => {
    setCreatingPickingList(true)
    await createPickingList(user.cardmarketUsername, selectedOrderIds)
    handleChangeOrderState(selectedOrderIds, 'picking')
    setSelectedOrderIds([])
    setCreatingPickingList(false)
  }

  // const handleAddSelected = async () => {
  //   if (selectedOrderIds.length > 0) {
  //     setOpenSelectPickingListDialog(true)
  //     // await createPickingList(user.cardmarketUsername, selectedOrderIds)
  //     // handleChangeOrderState(selectedOrderIds, 'picking')
  //     // setSelectedOrderIds([])
  //   } else {
  //     console.log('must select at least one order')
  //   }
  // }

  const handleCloseSelectPickingListDialog = () => {
    setOpenSelectPickingListDialog(false)
  }

  const handleNewAutomaticList = async () => {
    // automatic list from all paid orders
    setCreatingPickingList(true)
    const paidOrdersId = getPaidOrdersId(orders)
    if (paidOrdersId.length > 0) {
      await createPickingList(user.cardmarketUsername, paidOrdersId)
      handleChangeOrderState(paidOrdersId, 'picking')
    }
    setCreatingPickingList(false)
  }

  const handleCreateLargeList = async () => {
    // automatic list from all paid orders
    setCreatingPickingList(true)
    let paidOrdersId = getPaidOrdersId(orders)
    paidOrdersId = paidOrdersId.slice(0, 70)
    await createPickingList(user.cardmarketUsername, paidOrdersId)
    handleChangeOrderState(paidOrdersId, 'picking')
    setCreatingPickingList(false)
  }

  return (
    <>
      <Box display="flex">
        <Box alignSelf="center" width={toolNamesWidth}>
          <Typography color="primary">{t('orders.picking.title')}</Typography>
        </Box>

        <Box paddingRight={1}>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedOrderIds.length === 0 || creatingPickingList}
            onClick={handleCreateFromSelected}
            data-testid="create-picking-list-from-selected-btn"
          >
            {t('orders.picking.createFromSelected')}
          </Button>
        </Box>

        {/* <Box paddingRight={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddSelected}
            data-testid="add-selected-to-picking-list-btn"
          >
            {t('orders.picking.addSelected')}
          </Button>
        </Box> */}

        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={creatingPickingList}
            onClick={handleNewAutomaticList}
            data-testid="create-picking-list-from-all-btn"
          >
            {numPaidOrders < 99
              ? t('orders.picking.createFromAll')
              : t('orders.picking.createFromOldestPaid')}
          </Button>
        </Box>

        {user.isAdmin && (
          <Box paddingLeft={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={creatingPickingList}
              onClick={handleCreateLargeList}
            >
              Test - Create large picking list
            </Button>
          </Box>
        )}
      </Box>

      <SelectPickingListDialog
        open={openSelectPickingListDialog}
        onClose={handleCloseSelectPickingListDialog}
      />
    </>
  )
}
