import { Box, Typography } from '@material-ui/core'
import { Button, FileIcon } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import csv from 'csvtojson'
import { Dispatch, SetStateAction, useState } from 'react'
import { CSVLink } from 'react-csv'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { InventoryArticle } from 'shared'
import * as XLSX from 'xlsx'
import { theme } from '../../../config'
import { WarningBox } from '../../facets/WarningBox'
import { PreviewCsv } from './PreviewCsv'

export interface ImportFileCsvProps {
  stockType: string
  handleResult: (importedArticles: InventoryArticle[]) => void
  closeExternalDialog?: Dispatch<SetStateAction<boolean>>
}

const POSSIBLE_REQUIRED_HEADER_VALUE: string[] = [
  'qty',
  'amount',
  'qta',
  'qtx',
  'stock',
  'number',
  'count',
  'quantity',
  'cardname',
  'title',
  'name',
  'mkmid',
  'cmid',
  'idproduct',
  'productid',
  'scryfallId',
  'scryfall',
  'tcgplayerId',
  'tcgplayer',
  'language',
  'condition',
  'set',
  'setCode',
  'setName',
  'foil',
  'comment',
  'price',
  'expansion',
  'edition',
  'firsted',
  'first',
  'collector',
  'rarity',
  'cn',
  'status',
  'cardmarket id',
]

export const ImportFileCsv = ({
  stockType,
  handleResult,
  closeExternalDialog,
}: ImportFileCsvProps): JSX.Element => {
  const [csvFileData, setCsvFileData] = useState<Array<
    Record<string, string | number | undefined>
  > | null>(null)
  const { t } = useTranslation()
  const [haveValidFormatFile, setHaveValidFormatFile] = useState(true)
  const [isBiggerOdsFile, setIsBiggerOdsFile] = useState(false)
  const [isBiggerFile, setIsBiggerFile] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState<File[] | null>(null)

  const { activeGame } = useUser()

  const withoutHeader = (fileContent: string): boolean => {
    const csvRow = fileContent.split('\n')
    return (
      POSSIBLE_REQUIRED_HEADER_VALUE.filter((value) => csvRow[0].toLowerCase().includes(value))
        .length < 3
    )
  }

  const previewCsv = (uploadedFile: File[]): void => {
    setIsBiggerOdsFile(false)
    const reader = new FileReader()
    if (
      uploadedFile[0].name.indexOf('.csv') === -1 &&
      uploadedFile[0].name.indexOf('.xls') === -1 &&
      uploadedFile[0].name.indexOf('.xlsx') === -1 &&
      uploadedFile[0].name.indexOf('.xlsm') === -1 &&
      uploadedFile[0].name.indexOf('.ods') === -1
    ) {
      setHaveValidFormatFile(false)
    } else {
      setHaveValidFormatFile(true)
      if (uploadedFile[0].size > 10000000) {
        setIsBiggerFile(true)
      } else {
        setIsBiggerFile(false)
        if (uploadedFile[0].name.indexOf('xls') > -1 || uploadedFile[0].name.indexOf('ods') > -1) {
          if (uploadedFile[0].name.indexOf('ods') > -1 && uploadedFile[0].size > 3000000) {
            setIsBiggerOdsFile(true)
          } else {
            reader.onload = (e) => {
              /* Parse data */

              const fileContent = e.target ? e.target.result : ''
              const parsedData = XLSX.read(fileContent, { type: 'binary' })
              /* Get first worksheet */
              const fileName = parsedData.SheetNames[0]
              const valuesInFile = parsedData.Sheets[fileName]
              const csvData = XLSX.utils.sheet_to_csv(valuesInFile) // generates delimiter-separated-values output.
              csv({
                noheader: withoutHeader(csvData),
                delimiter: [',', ';', '\t', '|', ':'],
                output: 'json',
              })
                .fromString(csvData)
                .then(function (jsonCsv) {
                  setCsvFileData(jsonCsv)
                })
              setAcceptedFile(uploadedFile)
            }
            reader.readAsBinaryString(uploadedFile[0])
          }
        } else {
          reader.onload = () => {
            const stringCsv = reader.result as string

            csv({
              noheader: withoutHeader(stringCsv),
              delimiter: [',', ';', '\t', '|', ':'],
              output: 'json',
              flatKeys: true,
            })
              .fromString(stringCsv)
              .then(function (jsonCsv) {
                setCsvFileData(jsonCsv)
              })

            setAcceptedFile(uploadedFile)
          }
          reader.readAsText(uploadedFile[0])
        }
      }
    }
  }

  return (
    <Box>
      <Box>
        {csvFileData === null ? (
          <>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">{t('csv.uploadYourFile')}</Typography>
              <CSVLink filename={`example-import.csv`} data={activeGame.exampleCsv} target="_blank">
                {t('csv.getExampleCsv')}
              </CSVLink>
            </Box>
            <Box marginTop={3}>
              <Dropzone onDrop={(uploadedFile) => previewCsv(uploadedFile)}>
                {({ getRootProps, getInputProps }) => (
                  //@ts-ignore
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box
                      data-testid="file-drop-box"
                      bgcolor={'primary'}
                      padding={2}
                      style={{
                        border: '2px dashed black',
                        height: '350px',
                        position: 'relative',
                      }}
                    >
                      {' '}
                      {haveValidFormatFile && !isBiggerOdsFile && !isBiggerFile ? (
                        <>
                          <p
                            style={{
                              textAlign: 'center',
                              position: 'absolute',
                              left: '50%',
                              top: '50%',
                              margin: 'auto',
                              transform: 'translate(-50%, -50%)',
                            }}
                          >
                            {t('csv.clickAndDrag')}
                          </p>
                        </>
                      ) : (
                        <>
                          <Box
                            style={{
                              textAlign: 'center',
                              position: 'absolute',
                              left: '50%',
                              top: '50%',
                              margin: 'auto',
                              transform: 'translate(-50%, -50%)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '45%',
                            }}
                          >
                            {!haveValidFormatFile && (
                              <>
                                <Typography
                                  style={{
                                    color: theme.palette.error.main,
                                    fontSize: '1rem',
                                    marginRight: '10px',
                                  }}
                                >
                                  {t('csv.csvPreviewWrongFileType')}
                                </Typography>
                              </>
                            )}

                            {isBiggerOdsFile && (
                              <>
                                <Typography
                                  style={{
                                    color: theme.palette.error.main,
                                    fontSize: '1rem',
                                    marginRight: '10px',
                                  }}
                                >
                                  {t('csv.csvPreviewOdsBiggerFile')}
                                </Typography>
                              </>
                            )}
                            {isBiggerFile && (
                              <>
                                <Typography
                                  style={{
                                    color: theme.palette.error.main,
                                    fontSize: '1rem',
                                    marginRight: '10px',
                                  }}
                                >
                                  {t('csv.csvPreviewBiggerFile')}
                                </Typography>
                              </>
                            )}

                            <WarningBox
                              color={theme.palette.error.main}
                              tooltipText={'csv.csvPreviewWarningTooltip'}
                              Icon={FileIcon}
                              toRight={false}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </div>
                )}
              </Dropzone>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <PreviewCsv
                jsonCsv={csvFileData || {}}
                fileContent={acceptedFile}
                stockType={stockType}
                handleResult={handleResult}
                closeExternalDialog={closeExternalDialog}
              ></PreviewCsv>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                marginTop: '10px',
              }}
            >
              <Typography variant="body2">{t('csv.csvPreviewTextChangeFile')}</Typography>

              <Button
                color="primary"
                onClick={() => {
                  setCsvFileData(null)
                }}
                data-testid="change-file-btn"
              >
                <Box>
                  <Typography variant="body2">{t('csv.csvChangeFile')}</Typography>
                </Box>
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
