import { createContext, useContext } from 'react'
import { InventoryArticle } from 'shared'

export const EditArticleContext = createContext<{
  articleToEdit: InventoryArticle | null
  startEditingArticle: ((value: InventoryArticle, justAdded: boolean) => boolean) | null
  stopEditingArticle: (() => void) | null
  articleRef: { current: InventoryArticle | null | undefined }
  editAfterAdding: boolean
  setEditValue: ((value: boolean) => void) | null
  newArticle: boolean
  setIsNewArticle: ((value: boolean) => void) | null
}>({
  articleToEdit: null,
  startEditingArticle: null,
  stopEditingArticle: null,
  articleRef: { current: null },
  editAfterAdding: false,
  setEditValue: null,
  newArticle: false,
  setIsNewArticle: null,
})

export const useEditArticleContext = () => {
  const state = useContext(EditArticleContext)

  return state
}
