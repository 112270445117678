import { useTheme } from '@material-ui/core'
import { CustomTooltip } from './CustomTooltip'

export interface HelpTextProps {
  tooltip: string | React.ReactElement
  children?: React.ReactNode
  dataTestid?: string
}

export const HelpText = ({ tooltip, children, dataTestid }: HelpTextProps): JSX.Element => {
  const { palette } = useTheme()
  return (
    <CustomTooltip enterDelay={300} interactive title={tooltip}>
      <span style={{ cursor: 'help', color: palette.primary.main }} data-testid={dataTestid}>
        {children}
      </span>
    </CustomTooltip>
  )
}
