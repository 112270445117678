import { Box, Switch, Typography } from '@material-ui/core'
import { AccountSettingSelect } from 'components/controls/misc'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_LANGUAGES } from 'utils/constants'
import { CardmarketConnect } from '../cardmarket'
import { ReferralPresenter } from '../dashboard/ReferralPresenter'

export const AccountSettingsPresenter = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [oneClickPick, setOneClickPick] = useState<boolean>(() =>
    localStorage.getItem('oneClickPick') === 'true' ? true : false
  )

  const handleChangeOneClickPick = () => {
    localStorage.setItem('oneClickPick', (!oneClickPick).toString())
    setOneClickPick(!oneClickPick)
  }

  return (
    <>
      <Box marginTop={2} maxWidth="400px">
        <Typography>{t('subscription.accountsTitle')}</Typography>
        <CardmarketConnect noHelperText />
      </Box>
      <Box marginTop={2}>
        <Typography>{t('common.AppliactionLanguage')}</Typography>
        <AccountSettingSelect
          attribute={'language'}
          options={APP_LANGUAGES}
          onChange={(language) => i18n.changeLanguage(language as string)}
        />
        <Box padding={5}></Box>
        <Typography>One click pick</Typography>
        <Switch name="one-click-pick" checked={oneClickPick} onChange={handleChangeOneClickPick} />
        <Typography>
          With this setting you can decide whether to carry out picking with two clicks (pick & put)
          or with a single click (pick)
        </Typography>
      </Box>
      <Box marginTop={5}>
        <Typography>{t('signup.referral')}</Typography>
        <ReferralPresenter />
      </Box>
    </>
  )
}
