import { Box, Typography } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { useOrderStore } from 'lib/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getOrderState } from 'shared'
import { SidebarButton } from './SidebarButton'
import { SyncButton } from './SyncButton'

export const Sidebar = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()

  const activeTab = useOrderStore((state) => state.activeTab)
  const setActiveTab = useOrderStore((state) => state.setActiveTab)
  const setActivePickingList = useOrderStore((state) => state.setActivePickingList)
  const setExpandedOrder = useOrderStore((state) => state.setExpandedOrder)
  const orders = useOrderStore((state) => state.orders)
  const activeOrdersFilters = useOrderStore((state) => state.activeOrdersFilters)
  const setActiveOrdersFilters = useOrderStore((state) => state.setActiveOrdersFilters)

  const [numOrdersFulfilment, setNumOrdersFulfilment] = useState(0)
  const [numOrdersCancelled, setNumOrdersCancelled] = useState(0)
  const [numOrdersManagePickingLists, setNumOrdersManagePickingLists] = useState(0)
  const [numOrdersPickArticles, setNumOrdersPickArticles] = useState(0)
  const [numOrdersPackage, setNumOrdersPackage] = useState(0)
  // const [numOrdersCompleted, setNumOrdersCompleted] = useState(0)

  useEffect(() => {
    let countOrdersFulfilment = 0
    let countOrdersCancelled = 0
    let countOrdersManagePickingLists = 0
    let countOrdersPickArticles = 0
    let countOrdersPackage = 0
    // let countOrdersCompleted = 0

    orders.forEach((o) => {
      const orderState = getOrderState(o)
      if (!['completed', 'evaluated'].includes(orderState)) {
        countOrdersFulfilment++
      }
      if (['cancelled'].includes(orderState)) {
        countOrdersCancelled++
      }
      if (['paid'].includes(orderState)) {
        countOrdersManagePickingLists++
      }
      if (['picking'].includes(orderState)) {
        countOrdersPickArticles++
      }
      if (['picked', 'packed', 'sent'].includes(orderState)) {
        countOrdersPackage++
      }
      // if (['received', 'evaluated'].includes(orderState)) {
      //   countOrdersCompleted++
      // }
    })
    setNumOrdersFulfilment(countOrdersFulfilment)
    setNumOrdersCancelled(countOrdersCancelled)
    setNumOrdersManagePickingLists(countOrdersManagePickingLists)
    setNumOrdersPickArticles(countOrdersPickArticles)
    setNumOrdersPackage(countOrdersPackage)
    // setNumOrdersCompleted(countOrdersCompleted)
  }, [orders])

  const clearIssueFilter = () => {
    if (activeOrdersFilters.includes('issues')) {
      const newActiveOrdersFilters = [...activeOrdersFilters]
      const idx = newActiveOrdersFilters.indexOf('issues')
      newActiveOrdersFilters.splice(idx, 1)
      setActiveOrdersFilters(newActiveOrdersFilters)
    }
  }

  useEffect(() => {
    setExpandedOrder(undefined)
    clearIssueFilter()
  }, [activeTab])

  const onClick = (tab: number) => {
    setActivePickingList(undefined)
    setActiveTab(tab)
  }

  const TABS = {
    FULFILMENT_CENTER: 0,
    MANAGE_PICKING_LISTS: 1,
    PICK_ARTICLES: 2,
    PACKAGE: 3,
    COMPLETED: 4,
    CARDMARKET_SALES: 5,
  }

  return (
    <>
      <Box paddingLeft={1} paddingTop={1}>
        <Box paddingRight={1}>
          <SyncButton />
        </Box>
        <Box padding={2}></Box>
        <Typography>{t('orders.overview').toUpperCase()}</Typography>
        <SidebarButton
          label="Fulfilment Center"
          subLabel="orders"
          quantity={numOrdersFulfilment}
          secondQuantity={numOrdersCancelled}
          secondLabel="cancelled"
          selected={activeTab === 0}
          onClick={() => onClick(0)}
        />

        <Box padding={2}></Box>
        <Typography>{t('orders.workflow').toUpperCase()}</Typography>
        <SidebarButton
          label="1. Manage Picking Lists"
          subLabel="new orders"
          quantity={numOrdersManagePickingLists}
          selected={activeTab === 1}
          onClick={() => onClick(1)}
        />
        <Box padding={1}></Box>
        <SidebarButton
          label="2. Pick Articles"
          subLabel="picking orders"
          quantity={numOrdersPickArticles}
          selected={activeTab === 2}
          onClick={() => onClick(2)}
        />
        <Box padding={1}></Box>
        <SidebarButton
          label="3. Package, Label, Ship"
          subLabel="orders"
          quantity={numOrdersPackage}
          selected={activeTab === 3}
          onClick={() => onClick(3)}
        />
        {user.isTester?.ordersDataExport && (
          <>
            <Box padding={2}></Box>
            <Typography>{t('orders.dataExports').toUpperCase()}</Typography>
            <SidebarButton
              label={t('orders.cardmarketSales.title')}
              selected={activeTab === TABS.CARDMARKET_SALES}
              onClick={() => onClick(TABS.CARDMARKET_SALES)}
            />
          </>
        )}
        <Box padding={2}></Box>
        {/* <Typography>{t('orders.archive').toUpperCase()}</Typography>
        <SidebarButton
          label="Completed"
          subLabel="orders"
          quantity={numOrdersCompleted}
          selected={activeTab === 4}
          onClick={() => onClick(4)}
        />
        <Box padding={2}></Box> */}
      </Box>
    </>
  )
}
