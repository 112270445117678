import { Box, Grid, Typography } from '@material-ui/core'
import productImageNotAvailable from 'img/productImageNotAvailable.png'
import { useEffect, useState } from 'react'
import { OrderArticle } from 'shared'
import { CardInfoBox } from './CardInfoBox'
import { PickingButton } from './PickingButton'

export interface SingleImagePickingProps {
  article: OrderArticle
  handlePick?: (article: OrderArticle) => void
  handleCompletePicking?: (article: OrderArticle, quantity: number) => void
  handleReportIssue?: (a: OrderArticle) => void
  oneClickPick: boolean
}

export const SingleImagePicking = ({
  article,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
  oneClickPick,
}: SingleImagePickingProps): JSX.Element => {
  const imgSrc = article.idProduct
    ? `https://mtgpowertools.s3.eu-central-1.amazonaws.com/images/mtg/${article.idProduct}.jpg`
    : productImageNotAvailable

  const [hidePickingButton, setHidePickingButton] = useState<boolean>(oneClickPick)

  useEffect(() => {
    setHidePickingButton(oneClickPick)
  }, [oneClickPick])

  return (
    <Grid container spacing={5}>
      {article ? (
        <Box
          width={'100%'}
          className="single-image-container"
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          paddingY={3}
        >
          <Grid item xs={6}>
            <img
              style={{
                width: '350px',
                height: 'auto',
                borderRadius: '8px',
                marginRight: '32px',
                float: 'right',
              }}
              src={imgSrc}
            />
          </Grid>
          <Grid item xs={6} style={{ alignSelf: 'center' }}>
            <PickingButton
              type="reportIssue"
              article={article}
              handleReportIssue={handleReportIssue}
              padding="10px"
              fullWidth
            />
            <Box padding={5} />
            <CardInfoBox article={article} fullScreen transparent={false} />
            <Box padding={3} />
            <PickingButton
              type="pick"
              article={article}
              handlePick={handlePick}
              padding="10px"
              fullWidth
            />
            <Box padding={1} />
            {!hidePickingButton && (
              <PickingButton
                type="put"
                article={article}
                handleCompletePicking={handleCompletePicking}
                padding="10px"
                fullWidth
              />
            )}
          </Grid>

          <Grid item xs={3}></Grid>
        </Box>
      ) : (
        <Grid item xs={12}>
          <Box textAlign="center">
            <Typography variant="h6">No more articles to pick</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
