import { Box, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { AddIcon } from 'components/facets/Icons'
import { useUser } from 'components/providers/UserProvider'
import { createUserSubEntity, deleteUserSubEntity, editUserSubEntity } from 'lib/api'
import { useErrorHandler } from 'lib/hooks'
import { getUniqueNewName } from 'lib/utils'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import {
  bssBulkSettings,
  BulkSettings,
  DBSBulkSettings,
  digimonBulkSettings,
  FABBulkSettings,
  FFBulkSettings,
  IdGame,
  lorcanaBulkSettings,
  magicBulkSettings,
  onePieceBulkSettings,
  pokemonBulkSettings,
  swuBulkSettings,
  vanguardBulkSettings,
  WSBulkSettings,
  yugiohBulkSettings,
} from 'shared'
import { BulkpriceTable } from '../BulkpriceTable'
import { EntityBox } from '../EntityBox/EntityBox'

export const BulkpriceEditor = (): JSX.Element => {
  const { activeGame, user, setUser } = useUser()
  const { handle } = useErrorHandler()
  const { enqueueSnackbar } = useSnackbar()

  if (!user) return <></>

  const bulkPrices = user.bulkSettings.filter((bulkSetting) => {
    return Number(bulkSetting.gameId) === Number(activeGame.idGame)
  })

  const { t } = useTranslation()

  const getDefaultBulkSettingsForGame = (idGame: IdGame) => {
    switch (idGame) {
      case IdGame.Magic:
        return magicBulkSettings
      case IdGame.Yugioh:
        return yugiohBulkSettings
      case IdGame.Pokemon:
        return pokemonBulkSettings
      case IdGame.Vanguard:
        return vanguardBulkSettings
      case IdGame.DBS:
        return DBSBulkSettings
      case IdGame.FF:
        return FFBulkSettings
      case IdGame.WS:
        return WSBulkSettings
      case IdGame.FAB:
        return FABBulkSettings
      case IdGame.Digimon:
        return digimonBulkSettings
      case IdGame.OnePiece:
        return onePieceBulkSettings
      case IdGame.Lorcana:
        return lorcanaBulkSettings
      case IdGame.BSS:
        return bssBulkSettings
      case IdGame.SWU:
        return swuBulkSettings
    }
  }

  const onCreate = async (): Promise<void> => {
    try {
      const defaultBulkSettings = getDefaultBulkSettingsForGame(activeGame.idGame)

      const bulkSettingsForActiveGame = user.bulkSettings.filter(
        (setting) => setting.gameId === activeGame.idGame
      )
      const response = await createUserSubEntity(
        {
          ...defaultBulkSettings,
          name: getUniqueNewName(defaultBulkSettings.name, bulkSettingsForActiveGame),
        },
        'bulkSettings'
      )
      setUser({ ...user!, ...response })
    } catch (error) {
      enqueueSnackbar(t('error.generic'), {
        variant: 'error',
      })
    }
  }

  const onChange = async (bulkSettings: BulkSettings): Promise<void> => {
    try {
      const response = await editUserSubEntity(bulkSettings, 'bulkSettings')
      setUser({ ...user!, ...response })
    } catch (error) {
      enqueueSnackbar(t('error.generic'), {
        variant: 'error',
      })
    }
  }

  const onDelete = async (id: string): Promise<void> => {
    try {
      const response = await deleteUserSubEntity(id, 'bulkSettings')
      setUser({ ...user!, ...response })
    } catch (error) {
      handle(error)
    }
  }

  return (
    <Box data-testid="bulk-table" width="100%">
      <Typography variant="h5">{t('bulk.title')}</Typography>
      <Typography variant="body2">{t('bulk.info')}</Typography>
      {bulkPrices.map((prices) => (
        <BulkpriceTable
          key={prices._id}
          bulkSettings={prices}
          onDelete={onDelete}
          onChange={onChange}
        ></BulkpriceTable>
      ))}
      <Box
        onClick={onCreate}
        marginTop={2}
        padding={2}
        style={{ cursor: 'pointer' }}
        data-testid="add-new-bulk-settings-btn"
      >
        <EntityBox
          titleRow={<wbr />}
          contentRow={
            <Button color="primary">
              <AddIcon />
              <Box marginLeft={1}>
                <Typography variant="body2">{t('bulk.new').toUpperCase()}</Typography>
              </Box>
            </Button>
          }
        />
        {/* <Button color="primary">
          <AddIcon />
          <Box marginLeft={1}>
            <Typography variant="body2">{t('bulk.new')}</Typography>
          </Box>
        </Button> */}
      </Box>
    </Box>
  )
}
