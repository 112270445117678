import { Box, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DialogBox } from 'components/facets'
import { theme } from 'config'
import powertoolsLogo from 'img/purpleTcgPowertools.png'
import { loginUser } from 'lib/auth'
import { useAppContext } from 'lib/hooks'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    width: '500px',
    minHeight: '370px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  form: {
    width: '100%',
    '& *': {
      margin: '3px auto',
    },
  },
  smallSizeDevice: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}))

export const SignInPresenter = (): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const { isAuthenticated, setAuthenticated } = useAppContext()

  useEffect(() => {
    //@ts-ignore
    window['o_options'] = {
      id: 'Outseta',
      domain: 'mtg-powertools.outseta.com',
      load: 'auth',
      tokenStorage: 'local',
      auth: {
        widgetMode: 'login',
        id: 'login_embed',
        mode: 'embed',
        selector: '#login-embed',
        planFamilyUid: 'exmegM9V',
        planPaymentTerm: 'month',
        skipPlanOptions: true,
      },
    }
  }, [])

  useEffect(() => {
    const accessToken = new URLSearchParams(location.search).get('access_token')
    if (accessToken)
      loginUser({ accessToken })
        .then(() => {
          setAuthenticated(true)
        })
        .catch((err) => {
          enqueueSnackbar(err.response?.data?.message || 'Invalid username or password.', {
            variant: 'error',
          })
        })
  }, [enqueueSnackbar, location.search, setAuthenticated])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cdn.outseta.com/outseta.min.js'
    //@ts-ignore
    script['data-o-options'] = 'o_options'

    document.body.appendChild(script)

    return () => {
      //@ts-ignore
      if (window.Outseta) window.Outseta = null
      document.body.removeChild(script)
      return
    }
  }, [])

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <DialogBox>
      <Box padding={1} width="fit-content" margin="0 auto">
        <img width="400px" src={powertoolsLogo} className={classes.smallSizeDevice} />
      </Box>
      <Box className={classes.root}>
        {process.env.NODE_ENV === 'development' && (
          <div>
            <a href="/signin?access_token=1234567890BWzy5VN9">Dev Login</a>
          </div>
        )}
        <div id="login-embed" />
      </Box>
      <Link to="/signup" style={{ display: 'block', width: '100%', textAlign: 'center' }}>
        Don`t have an account? Sign up!
      </Link>
      <Box marginTop={3}>
        <FormHelperText style={{ textAlign: 'center' }}>
          TCG PowerTools is a 3rd party app for Cardmarket created by Trading Card Technologies LLC,
          an official Cardmarket API Partner. Cardmarket is a registered trademark of
          Sammelkartenmarkt GmbH & Co. KG.
        </FormHelperText>
      </Box>
    </DialogBox>
  )
}
