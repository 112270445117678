import { Box, IconButton, makeStyles, Snackbar, Typography, useTheme } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { VisualArticle } from 'components/domain/stockPricing/StockDisplay/VisualArticle'
import { CustomTooltip, HotkeyLegend, RestartIcon } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useHotkeyConfig, useScopelessHotkeys, useWindowDimensions } from 'lib/hooks'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { CardDataItem, CardLanguage, InventoryArticle, RichArticle, Template } from 'shared'
import { v4 as uuid } from 'uuid'

export type ArticleAddHandleSave = (
  article: InventoryArticle | 'previous' | 'next',
  isCopied?: boolean
) => void

interface ArticleAddProps {
  addByExp?: boolean
  disableHotkeys: boolean
  template: Template
  card: CardDataItem
  handleSave: ArticleAddHandleSave
  stopEdit: () => void
  onInputChange?: (newValue: RichArticle | null) => void
  showExistingStock?: boolean
  fixedLegendPosition?: [x: number, y: number]
}

export const ArticleAdd = ({
  addByExp,
  disableHotkeys,
  template,
  card,
  handleSave,
  stopEdit,
  onInputChange,
  showExistingStock = true,
  fixedLegendPosition,
}: ArticleAddProps): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    hotkeyPaper: {
      cursor: 'move',
      '& > *': {
        backgroundColor: `${theme.palette.lightBackground}!important`,
        color: 'black',
      },
    },
  }))

  const classes = useStyles()
  const { t } = useTranslation()
  const hotkeys = useHotkeyConfig()

  const { palette } = useTheme()

  const [draggableRerenderKey, setDraggableRerenderKey] = useState(uuid())

  const textFieldRef = useRef<HTMLInputElement | undefined>()

  const [hasFocus, setHasFocus] = useState(false)
  const { user } = useUser()
  const templateValues = template

  const { width } = useWindowDimensions()

  const initialInputs = new RichArticle({
    card,
    ...templateValues,
    isFoil: card.onlyFoil ? true : templateValues.isFoil ? true : false,
    idArticle: templateValues.id ?? uuid(),
  })

  const [inputs, setInputs] = useState<RichArticle>(initialInputs)

  useEffect(() => {
    setInputs(initialInputs)
    onInputChange?.(initialInputs)
  }, [card])

  useEffect(() => {
    textFieldRef.current?.focus()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): Promise<void> | void => {
    const newInputs = inputs.copy()

    newInputs.setAttribute(
      e.target.name,
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    )

    onInputChange?.(newInputs)

    setInputs(newInputs)
  }

  const hotkeyCallback = (e: KeyboardEvent, fn: () => void, force = false): void => {
    if ((!disableHotkeys && document.activeElement === textFieldRef.current) || force) {
      e.preventDefault()
      e.stopPropagation()
      fn()
    }
  }

  const set = (attribute: keyof typeof template, value: string | number | boolean): void => {
    if (card.onlyFoil && attribute === 'isFoil') return
    const newInputs = inputs.copy()

    newInputs.setAttribute(attribute, value === 'toggle' ? !inputs[attribute] : value)

    onInputChange?.(newInputs)
    setInputs(newInputs)
  }

  const changeQuantity = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (Number.isInteger(Number(e.target.value))) handleChange(e)
  }

  for (const hotkey of hotkeys) {
    const [key, parameter, value] = hotkey

    let parsedValue: string | number = value
    let parsedParameter: string = parameter
    if (parameter === 'language') {
      parsedValue = CardLanguage[value as keyof typeof CardLanguage]
      parsedParameter = 'idLanguage'
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScopelessHotkeys(
      key,
      (e: KeyboardEvent) =>
        hotkeyCallback(e, () => set(parsedParameter as keyof typeof template, parsedValue)),
      [inputs, disableHotkeys, initialInputs]
    )
  }

  useScopelessHotkeys(
    'Esc',
    (e) =>
      hotkeyCallback(e, () => {
        stopEdit()
      }),
    [inputs, disableHotkeys, initialInputs]
  )

  useScopelessHotkeys(
    'Delete',
    (e) =>
      hotkeyCallback(e, () => {
        stopEdit()
      }),
    [inputs, disableHotkeys, initialInputs]
  )

  const confirmChanges = (e: KeyboardEvent) => {
    hotkeyCallback(e, () => {
      const quantity = Math.ceil(Number(inputs.quantity))
      if (!quantity || quantity < 1) return

      // set listedAt date
      inputs.attributes[12] = new Date()

      handleSave(
        new InventoryArticle({
          ...inputs,
          quantity,
        })
      )

      setInputs(
        new RichArticle({
          ...initialInputs,
          idArticle: uuid(),
        })
      )
    })
  }

  useScopelessHotkeys('Space', (e) => confirmChanges(e), [inputs, disableHotkeys, initialInputs])

  useScopelessHotkeys('Enter', (e) => confirmChanges(e), [inputs, disableHotkeys, initialInputs])

  useScopelessHotkeys(
    'c',
    (e) =>
      hotkeyCallback(e, () => {
        const quantity = Math.ceil(Number(inputs.quantity))
        if (!quantity || quantity < 1) return
        handleSave(
          new InventoryArticle({
            ...inputs.toPlainObject(),
            quantity,
            price: initialInputs.price,
            comment: inputs.comment,
            idArticle: uuid(),
          }),
          true
        )
        const newInputs = new RichArticle({
          ...inputs.toPlainObject(),
          price: initialInputs.price,
          idArticle: uuid(),
        })

        setInputs(newInputs)
      }),
    [inputs, disableHotkeys, initialInputs]
  )

  if (addByExp) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScopelessHotkeys('b', (e) => hotkeyCallback(e, () => handleSave('next')), [
      inputs,
      disableHotkeys,
    ])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScopelessHotkeys('v', (e) => hotkeyCallback(e, () => handleSave('previous')), [
      inputs,
      disableHotkeys,
    ])
  }

  return (
    <Box
      key={
        //forces rerender when
        disableHotkeys ? 'lol' : 'mol'
      }
    >
      <Box data-testid="article-being-added-box">
        <Box margin="0 auto" width="fit-content">
          <Typography
            style={{ textAlign: 'center', width: '190px', display: 'block' }}
            variant="overline"
          >
            {card.name} - {card.set}
          </Typography>
          <Box
            width={`${190}px`}
            height={`${190 * 1.39}px`}
            onClick={() => textFieldRef.current?.focus()}
          >
            <VisualArticle
              displayOnly
              rowHeight={190 * 1.39}
              article={new InventoryArticle({ ...inputs })}
              currency={user?.currency}
            />
          </Box>
        </Box>
        <TextField
          key={inputs.id}
          autoFocus
          inputRef={textFieldRef}
          onFocus={(e) => {
            e.target.select()
            setHasFocus(true)
          }}
          onBlur={() => setHasFocus(false)}
          value={inputs.quantity}
          onChange={changeQuantity}
          margin="none"
          label={t('card.quantity')}
          name="quantity"
          fullWidth
          InputProps={{ inputProps: { min: 1 }, autoComplete: 'off' }}
          error={inputs.quantity <= 0}
          data-testid="quanty-input-add-new-article"
          helperText={
            hasFocus ? (
              t('addArticles.hotkeysActive')
            ) : (
              <span style={{ color: palette.primary.main }}>
                <b>{t('addArticles.clickFieldToEditArticle')}</b>
              </span>
            )
          }
        />
        <TextField
          value={inputs.comment}
          onChange={handleChange}
          margin="none"
          label={t('card.comment')}
          name="comment"
          fullWidth
          data-testid="comment-input-add-new-article"
        />
      </Box>

      {!disableHotkeys && /*hasFocus*/ true ? (
        <Draggable
          key={draggableRerenderKey}
          onStop={(_e, data) => {
            if (!fixedLegendPosition) {
              localStorage.setItem('hotkey-legend-x', String(data.x))
              localStorage.setItem('hotkey-legend-y', String(data.y))
            }
          }}
          defaultPosition={{
            x:
              fixedLegendPosition?.[0] ||
              Number(localStorage.getItem('hotkey-legend-x')) ||
              width - 700,
            y: fixedLegendPosition?.[1] || Number(localStorage.getItem('hotkey-legend-y')) || 0,
          }}
        >
          <Snackbar
            className={classes.hotkeyPaper}
            open={true}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            message={
              <>
                <HotkeyLegend
                  article={inputs}
                  onClick={() => textFieldRef.current?.focus()}
                  addByExp={addByExp}
                  disabled={!hasFocus}
                  setInputs={setInputs}
                  showExistingStock={showExistingStock}
                />
              </>
            }
            data-testid="hotkeys-pannel"
          />
        </Draggable>
      ) : null}
      {!fixedLegendPosition && (
        <Box
          position="absolute"
          borderRadius="50%"
          bottom={2}
          right={2}
          zIndex={1399}
          style={{ backgroundColor: palette.lightBackground }}
        >
          <CustomTooltip title="Reset hotkey position">
            <IconButton
              color="primary"
              onClick={() => {
                localStorage.setItem('hotkey-legend-x', String(width - 700))
                localStorage.setItem('hotkey-legend-y', String(0))
                setDraggableRerenderKey(uuid())
              }}
            >
              <RestartIcon />
            </IconButton>
          </CustomTooltip>
        </Box>
      )}
    </Box>
  )
}
