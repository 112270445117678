import { Box, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CustomTooltip } from 'components/facets/CustomTooltip'
import { theme } from 'config'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

export interface ImportMenuButtonProps {
  color: string
  title: string
  content: string
  img?: string
  onClick: () => void
  disabled?: boolean
  imgDisabled?: string
  dataTestid?: string
}

export const ImportMenuButton = memo(function ImportMenuButton({
  color,
  title,
  content,
  img,
  onClick,
  disabled,
  imgDisabled,
  dataTestid,
}: ImportMenuButtonProps): JSX.Element {
  const useStyles = makeStyles(() =>
    createStyles({
      container: {
        border: `2px solid ${disabled ? theme.palette.disabled : color}`,
        minHeight: '100px',
        borderRadius: '10px',
        width: '88%',
        marginLeft: '6%',
        marginRight: '6%',
        display: 'flex',
        padding: '10px',
        transition: 'all 0.15s ease-in',
        cursor: `${disabled ? 'auto' : 'pointer'}`,
        boxShadow: `${disabled ? 'none' : '0px 4px 6px -2px rgba(0,0,0,0.2)'}`,
        '&:hover': {
          transform: `${disabled ? 'scale(1)' : 'scale(1.05)'}`,
          boxShadow: `${disabled ? 'none' : '0px 6px 8px 0px rgba(0,0,0,0.2)'}`,
        },
      },
      title: {
        fontSize: '14px',
        color: `${disabled ? theme.palette.disabled : color}`,
        textTransform: 'uppercase',
      },
      content: {
        fontSize: '12px',
        color: `${disabled ? theme.palette.disabled : ''}`,
      },
      textBox: {
        width: `${disabled ? 'auto' : '70%'}`,
      },
      fullTextBox: {
        width: `${disabled ? 'auto' : '100%'}`,
      },
      imgBox: {
        width: `${disabled ? '72%' : '30%'}`,
        textAlign: 'center',
        position: 'relative',
      },
      imgCenter: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        margin: 'auto',
        right: '0',
        left: '0',
      },
    })
  )
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box
      className={classes.container}
      onClick={() => !disabled && onClick()}
      data-testid={dataTestid}
    >
      {disabled ? (
        <>
          <CustomTooltip
            title={
              <>
                <Typography variant="body1" data-testid="tooltip-free-user-add-price">
                  <b>{t(`subscriptionUpgradeNeededNew`)}</b>
                </Typography>
              </>
            }
          >
            <div>
              <Box className={img ? classes.textBox : classes.fullTextBox}>
                <Typography className={classes.title}>{`${title}`}</Typography>
                <Typography className={classes.content}>{`${content}`}</Typography>
              </Box>
            </div>
          </CustomTooltip>
        </>
      ) : (
        <>
          <Box className={img ? classes.textBox : classes.fullTextBox}>
            <Typography className={classes.title}>{`${title}`}</Typography>
            <Typography className={classes.content}>{`${content}`}</Typography>
          </Box>
        </>
      )}

      {img && (
        <Box className={classes.imgBox}>
          <img
            className={classes.imgCenter}
            alt={`icon-${title}`}
            src={`${disabled ? imgDisabled : img}`}
          />
        </Box>
      )}
    </Box>
  )
})
