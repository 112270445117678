import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { ExpansionAutocomplete } from 'components/controls/misc/ExpansionAutocomplete'
import { useCardThingData } from 'components/providers/CardDataProvider'
import { useUser } from 'components/providers/UserProvider'
import { sendBugReport } from 'lib/api'
import { useDebounce } from 'lib/hooks'
import { useStockStore } from 'lib/hooks/useStockStore'
import { exposeToCypress } from 'lib/utils'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import {
  AppLanguage,
  CardDataItem,
  Expansion,
  IdGame,
  InventoryArticle,
  RichArticle,
  Template,
} from 'shared'
import { v4 as uuid } from 'uuid'
import { useEditArticleContext } from '../useEditArticleContext'
import { ArticleAdd } from './ArticleAdd'
import { ArticleEditHandleSave } from './ArticleEdit'
import AutocompleteInput from './AutocompleteInput'

export interface ProfiAddArticlesProps {
  onAddArticles: (articles: InventoryArticle[]) => void
  template: Template
  disableHotkeys: boolean
  discardChange?: boolean
  onInputChange?: (newValue: RichArticle | null) => void
  showExistingStock?: boolean
}

export type SearchLanguage = 'en' | 'fr' | 'es' | 'de' | 'it'

export const ProfiAddArticles = ({
  onAddArticles,
  template,
  disableHotkeys,
  onInputChange,
  showExistingStock = true,
}: ProfiAddArticlesProps): JSX.Element => {
  const { t } = useTranslation()

  const [expansionCode, setExpansionCode] = useState<string>('')

  const { activeGame } = useUser()

  const debounceTime = 50
  const [state, setState] = useState('editingCard')
  const debouncedState = useDebounce(state, debounceTime)
  const [activeLanguage, setActiveLanguage] = useState<SearchLanguage>('en')
  const activeStockType = useStockStore((state) => state.activeStockType)

  const { cardData, setLanguage } = useCardThingData(activeLanguage)

  const [card, setCard] = useState<CardDataItem | null>(null)
  const autocompleteRef = useRef(null)

  const { startEditingArticle, setEditValue, setIsNewArticle, articleToEdit, stopEditingArticle } =
    useEditArticleContext()

  const handleCardChange = (newValue: CardDataItem): void => {
    setCard(newValue)
    setState('editingAttributes')
  }

  useEffect(() => {
    stopEditingArticle?.()
  }, [activeStockType])

  const CountryFlag = ({ code }: { code: string }): JSX.Element => (
    <Box display="flex" justifyContent="center">
      <Flag
        style={{ padding: '0px 5px' }}
        //@ts-ignore
        fallback={<span>Unknown</span>}
        height="16"
        code={code}
      />
    </Box>
  )

  const handleSave: ArticleEditHandleSave = (article, isCopied) => {
    onAddArticles([article] as InventoryArticle[])
    if (!isCopied) {
      setCard(null)
      setState('editingCard')
      if (stopEditingArticle) {
        stopEditingArticle()
      }
    }
  }

  useEffect(() => {
    if (debouncedState === 'editingCard') {
      if (autocompleteRef.current) {
        //@ts-ignore
        const [inputElement] = autocompleteRef.current.getElementsByTagName('input')
        setTimeout(() => inputElement.focus(), 10)
      }
    }
  }, [debouncedState])

  const allExpansionsOption = {
    name: t('addArticles.allExpansions'),
    code: '',
    _id: 0,
  } as Expansion

  const getTemplateForSealed = (): Template => {
    const newTemplate = { ...template }
    newTemplate.isFirstEd = false
    newTemplate.isFoil = false
    newTemplate.isSigned = false
    newTemplate.isPlayset = false
    return newTemplate
  }

  useEffect(() => {
    if (debouncedState === 'editingCard' && card) {
      let newTemplate = { ...template }
      if (activeStockType === 'sealed') {
        newTemplate = getTemplateForSealed()
      }

      const richArticle = new RichArticle({
        card,
        ...newTemplate,
        isFoil: card.onlyFoil ? true : newTemplate.isFoil ? true : false,
        idArticle: template.id ?? uuid(),
      })
      const inventoryArticle = new InventoryArticle(richArticle)
      if (setIsNewArticle) {
        setIsNewArticle(true)
      }
      if (setEditValue) {
        setEditValue(true)
      }
      if (startEditingArticle) {
        startEditingArticle(inventoryArticle, true)
      }
    }
  }, [debouncedState, card])

  useEffect(() => {
    if (!articleToEdit) {
      setCard(null)
      setState('editingCard')
    }
  }, [articleToEdit])

  const content = (): JSX.Element => {
    return (
      <Box width="100%">
        {debouncedState !== 'editingAttributes' && (
          <>
            <Box>
              <Box padding={1}>
                <AutocompleteInput
                  cardData={cardData}
                  autocompleteRef={autocompleteRef}
                  expansionCode={expansionCode}
                  handleCardChange={handleCardChange}
                  activeLanguage={activeLanguage}
                  rarities={activeGame.rarities}
                  useKeyruneExpansionIcons={activeGame.idGame === IdGame.Magic}
                />
              </Box>
            </Box>
            <Box marginLeft={1}>
              <Typography variant="body1" color="textSecondary">
                {t('addArticles.tryThese')}:{' '}
                <i>
                  {activeStockType === 'sealed'
                    ? activeGame.addByItemExamples.join(', ')
                    : activeGame.addByCardExamples.join(', ')}
                </i>
              </Typography>
            </Box>
          </>
        )}
        {debouncedState === 'editingAttributes' && card && (
          <Box>
            <ArticleAdd
              showExistingStock={showExistingStock}
              template={activeStockType === 'sealed' ? getTemplateForSealed() : template}
              card={card!}
              disableHotkeys={disableHotkeys}
              handleSave={handleSave}
              onInputChange={onInputChange}
              stopEdit={() => {
                setState('editingCard')
                onInputChange?.(null)
                if (stopEditingArticle) {
                  stopEditingArticle()
                }
                setTimeout(() => {
                  if (autocompleteRef.current) {
                    setCard(null)
                    try {
                      //@ts-ignore
                      const [inputElement] = autocompleteRef.current.getElementsByTagName('input')
                      inputElement.focus()
                    } catch (error) {
                      sendBugReport('Could not focus on autocompleteRef')
                    }
                  }
                }, debounceTime + 50)
              }}
            />
          </Box>
        )}
        <Box display={debouncedState === 'editingCard' ? 'block' : 'none'}>
          <Box padding={1} paddingTop={3} display="flex" justifyContent="center">
            <Grid container>
              <Grid item xs={6} style={{ margin: 'auto' }}>
                <Typography>{t('addArticles.searchLanguage')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Select
                    data-testid="language-select"
                    value={activeLanguage}
                    onChange={(e) => {
                      setActiveLanguage(e.target.value as SearchLanguage)

                      setLanguage(e.target.value as SearchLanguage)
                    }}
                  >
                    <MenuItem value="en">
                      <CountryFlag code="gb" />
                    </MenuItem>
                    <MenuItem value="de">
                      <CountryFlag code="de" />
                    </MenuItem>
                    <MenuItem value="it">
                      <CountryFlag code="it" />
                    </MenuItem>
                    <MenuItem value="fr">
                      <CountryFlag code="fr" />
                    </MenuItem>
                    <MenuItem value="es">
                      <CountryFlag code="es" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box padding={1}>
            <ExpansionAutocomplete
              options={[allExpansionsOption]}
              onChange={async (newValue) => {
                setExpansionCode(newValue?.code || '')
              }}
              value={expansionCode}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  exposeToCypress({
    setInputLanguage: (lang: AppLanguage) => setActiveLanguage(lang),
  })

  return content()
}
