import { Box, Typography } from '@material-ui/core'
import { CloneIcon, CustomTooltip } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export const ReferralPresenter = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  return (
    <Box maxWidth="24rem">
      <Box marginBottom={2}>
        <Typography variant="h5" color="primary">
          <b>{t('dashboard.inviteFriend')}</b>
        </Typography>
      </Box>
      <Box>
        <Typography>
          {t('dashboard.yourReferralCode')}:{' '}
          <CustomTooltip title={t('common.copyToClipboard') as string}>
            <Typography
              style={{ cursor: 'pointer' }}
              color="primary"
              component="span"
              onClick={() => {
                navigator.clipboard.writeText('https://tcgpowertools.com/?ref=' + user?._id || '')
                enqueueSnackbar(
                  <Box display="flex" alignItems="center">
                    <CloneIcon /> <Box marginLeft={1}>{t('common.copiedToClipboard')}</Box>
                  </Box>
                )
              }}
            >
              {user?._id}
            </Typography>
          </CustomTooltip>
        </Typography>
        <Box marginTop={1}>
          <a
            href="https://support.tcgpowertools.com/support/kb/articles/Nmdo5zW0/our-referral-program"
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="body1">{t('dashboard.findOutMore')}</Typography>
          </a>
        </Box>
      </Box>
    </Box>
  )
}
