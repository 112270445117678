import { Box, Grid, makeStyles, Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Button, CloneIcon, CustomTooltip } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { getPriceFormat } from 'lib/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Order } from 'shared'

export interface OrderInfoProps {
  order: Order
}

export const OrderInfo = ({ order }: OrderInfoProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()
  t

  const useStyles = makeStyles(() => ({
    info: {
      backgroundColor: '#b5bbdc',
      borderRadius: '10px',
      padding: '15px',
    },
  }))
  const styles = useStyles()

  const [open, setOpen] = useState(false)

  const handleClose = (event: any) => {
    event
    setOpen(false)
  }

  const copyToClipboard = async () => {
    const address = [
      order.shippingAddress?.name || '',
      order.shippingAddress?.extra || '',
      order.shippingAddress?.street || '',
      `${order.shippingAddress?.zip || ''} ${order.shippingAddress?.city || ''}`,
      order.shippingAddress?.country || '',
    ]
      .filter((line) => line.trim() !== '')
      .join('\n')

    try {
      await navigator.clipboard.writeText(address)
      setOpen(true)
    } catch (err) {
      console.error('Error in clipboard copy ', err)
    }
  }

  const openShipmentPage = (order: Order) => {
    window.open(
      `https://www.cardmarket.com/${user.language}/${
        order.pt.articleGames[0] !== undefined ? order.pt.articleGames[0] : 'Magic'
      }/Orders/${order.idOrder}`,
      '_blank'
    )
  }

  return (
    <Box className={styles.info}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="success">
          Address copied to clipboard!
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h6">Order summary</Typography>
          <Typography variant="body2">
            Total items: <b>{order.articleCount}</b>
          </Typography>
          <Typography variant="body2">
            Total value: <b>{getPriceFormat(order.totalValue, order.currencyCode)}</b>
          </Typography>
          <Box padding={2}></Box>
          <Button variant="contained" onClick={() => openShipmentPage(order)}>
            View Shipping Label
          </Button>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6">Buyer</Typography>
          <Typography variant="body2">{order.buyer.address.name}</Typography>
          <Typography variant="body2">[{order.buyer.username}]</Typography>
          <Typography variant="body2">{order.buyer.email}</Typography>
          <Typography variant="body2">{order.buyer.phone}</Typography>
        </Grid>

        <Grid item xs={3}>
          <div className="d-flex flex-row align-items-start">
            <Typography variant="h6">Shipping address</Typography>
            <CustomTooltip title={'Copy address'}>
              <CloneIcon
                className="fa-light fa-copy orders-table-action-icon"
                onClick={copyToClipboard}
              />
            </CustomTooltip>
          </div>
          <Typography variant="body2">{order.shippingAddress?.name}</Typography>
          <Typography variant="body2">{order.shippingAddress?.extra}</Typography>
          <Typography variant="body2">{order.shippingAddress?.street}</Typography>
          <Typography variant="body2">
            {order.shippingAddress?.zip}, {order.shippingAddress?.city}
          </Typography>
          <Typography variant="body2">{order.shippingAddress?.country}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6">Shipping method</Typography>
          {order.shippingMethod?.name.replace('&lt;', '< ')}
          <Typography variant="body2">
            Is letter: {order.shippingMethod?.isLetter ? 'YES' : 'NO'}
          </Typography>
          <Typography variant="body2">
            Is insured: {order.shippingMethod?.isInsured ? 'YES' : 'NO'}
          </Typography>
          <Typography variant="body2">
            Tracking number: {order.trackingNumber ? order.trackingNumber : 'not available'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
