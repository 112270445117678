import { Tooltip, TooltipProps } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 440,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  },
}))(Tooltip)

export const CustomTooltip = (props: TooltipProps): JSX.Element => {
  return <StyledTooltip enterDelay={250} {...props} />
}
