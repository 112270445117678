import { createContext } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { SetState } from 'types'

export interface ContextProviderValue {
  ReactPixel: typeof ReactPixel
  isAuthenticated: boolean
  setAuthenticated: SetState<boolean>
  shouldRedirectToNew: boolean
  setShouldRedirectToNew: SetState<boolean>
}

export default createContext<ContextProviderValue>({
  //@ts-ignore
  ReactPixel: {},
  isAuthenticated: false,
  setAuthenticated: () => undefined,
  shouldRedirectToNew: false,
  setShouldRedirectToNew: () => undefined,
})
