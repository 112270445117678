import { Grid, Typography } from '@material-ui/core'
import { StatusBlock } from 'components/facets/StatusBlock'
import {
  get429Errors,
  getCountOldArticles,
  getCpuStatus,
  getFetcherMetrics,
  getFetcherRuntimeFast,
  getFetcherRuntimeMain,
  getHddStatus,
  getLastProductsUpdate,
  getMemoryStatus,
  getMongoCollectionStatus,
  getMongoQueryResponseTime,
  getNumArticlesFetched,
  getPageLoadingTime,
  getQueueStatus,
  getRedisCacheSize,
  getRedisCacheStatus,
  getRedisMemory,
  getTimeLastArticleDataBackup,
  getTimeLastCategoriesBackup,
  getTimeLastExpansionsBackup,
  getTimeLastProductIdsBackup,
} from 'lib/api'

const ONE_MINUTE = 60 * 1000
const ONE_HOUR = 60 * ONE_MINUTE

const runtimeValueHandler = (value: string) => {
  const parsed = JSON.parse(value)
  return (
    <>
      {parsed &&
        parsed.map(
          (
            item: {
              start: string | null
              end: string | null
            },
            index: number
          ) => (
            <div key={'runtime' + index}>
              start: {item.start} - end: {item.end}
            </div>
          )
        )}
    </>
  )
}

export const StatusPresenter = (): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">Production</Typography>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Production Web CPU'}
          poolingTime={5 * ONE_MINUTE}
          server={'web'}
          env={'production'}
          statusHandler={getCpuStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Production Web RAM'}
          poolingTime={5 * ONE_MINUTE}
          server={'web'}
          env={'production'}
          statusHandler={getMemoryStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Production Worker CPU'}
          poolingTime={5 * ONE_MINUTE}
          server={'worker'}
          env={'production'}
          statusHandler={getCpuStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Production Worker RAM'}
          poolingTime={5 * ONE_MINUTE}
          server={'worker'}
          env={'production'}
          statusHandler={getMemoryStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Redis CPU'}
          poolingTime={5 * ONE_MINUTE}
          server={'redis'}
          env={'production'}
          statusHandler={getCpuStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Redis RAM'}
          poolingTime={5 * ONE_MINUTE}
          server={'redis'}
          env={'production'}
          statusHandler={getMemoryStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Page loading time'}
          poolingTime={5 * ONE_MINUTE}
          env={'production'}
          maxValue={1000}
          statusHandler={getPageLoadingTime}
        ></StatusBlock>
      </Grid>
      {/* HDD */}
      <Grid item>
        <StatusBlock
          title={'HDD Web'}
          poolingTime={ONE_HOUR}
          server={'web'}
          env={'production'}
          statusHandler={getHddStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'HDD Worker'}
          poolingTime={ONE_HOUR}
          server={'worker'}
          env={'production'}
          statusHandler={getHddStatus}
        ></StatusBlock>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">Staging</Typography>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Staging Web CPU'}
          poolingTime={5 * ONE_MINUTE}
          server={'web'}
          env={'staging'}
          statusHandler={getCpuStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Staging Web RAM'}
          poolingTime={5 * ONE_MINUTE}
          server={'web'}
          env={'staging'}
          statusHandler={getMemoryStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Staging Worker CPU'}
          poolingTime={5 * ONE_MINUTE}
          server={'worker'}
          env={'staging'}
          statusHandler={getCpuStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Staging Worker RAM'}
          poolingTime={5 * ONE_MINUTE}
          server={'worker'}
          env={'staging'}
          statusHandler={getMemoryStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Page loading time'}
          poolingTime={5 * ONE_MINUTE}
          env={'staging'}
          maxValue={2000}
          statusHandler={getPageLoadingTime}
        ></StatusBlock>
      </Grid>
      {/* HDD */}
      <Grid item>
        <StatusBlock
          title={'HDD Web'}
          poolingTime={ONE_HOUR}
          server={'web'}
          env={'staging'}
          statusHandler={getHddStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'HDD Worker'}
          poolingTime={ONE_HOUR}
          server={'worker'}
          env={'staging'}
          statusHandler={getHddStatus}
        ></StatusBlock>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">Redis</Typography>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Redis Memory'}
          poolingTime={5 * ONE_MINUTE}
          statusHandler={getRedisMemory}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Network Queue'}
          poolingTime={5 * ONE_MINUTE}
          queue={'network'}
          statusHandler={getQueueStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Processor Queue'}
          poolingTime={5 * ONE_MINUTE}
          queue={'processor'}
          statusHandler={getQueueStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Fetcher Queue'}
          poolingTime={5 * ONE_MINUTE}
          queue={'fetcher'}
          statusHandler={getQueueStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Article data count'}
          poolingTime={ONE_HOUR}
          maxValue={25 * 1000}
          statusHandler={getRedisCacheStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Articles with TTL < 1 day'}
          poolingTime={ONE_HOUR * 6}
          maxValue={200}
          statusHandler={getCountOldArticles}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Last fetcher run'}
          poolingTime={ONE_HOUR}
          minValue={50 * 1000}
          statusHandler={getNumArticlesFetched}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Fetcher speed'}
          poolingTime={5 * ONE_MINUTE}
          statusHandler={getFetcherMetrics}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Last article data backup'}
          poolingTime={ONE_HOUR}
          maxValue={4 * ONE_HOUR}
          statusHandler={getTimeLastArticleDataBackup}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Last product IDs backup'}
          poolingTime={ONE_HOUR}
          maxValue={24 * ONE_HOUR}
          statusHandler={getTimeLastProductIdsBackup}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Last expansions backup'}
          poolingTime={ONE_HOUR}
          maxValue={24 * ONE_HOUR}
          statusHandler={getTimeLastExpansionsBackup}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Last categories backup'}
          poolingTime={ONE_HOUR}
          maxValue={24 * ONE_HOUR}
          statusHandler={getTimeLastCategoriesBackup}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'redisCache file size'}
          poolingTime={ONE_HOUR}
          minValue={4.8}
          statusHandler={getRedisCacheSize}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Fetching times - main queue'}
          poolingTime={ONE_HOUR}
          statusHandler={getFetcherRuntimeMain}
          outputValueHandler={runtimeValueHandler}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Fetching times - fast queue'}
          poolingTime={ONE_HOUR}
          statusHandler={getFetcherRuntimeFast}
          outputValueHandler={runtimeValueHandler}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'429 errors'}
          poolingTime={ONE_HOUR}
          statusHandler={get429Errors}
        ></StatusBlock>
      </Grid>
      {/* HDD */}
      <Grid item>
        <StatusBlock
          title={'HDD Redis'}
          poolingTime={ONE_HOUR}
          server={'redis'}
          env={'production'}
          statusHandler={getHddStatus}
        ></StatusBlock>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">MongoDB</Typography>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Products'}
          poolingTime={ONE_HOUR}
          collection={'products'}
          maxValue={4}
          statusHandler={getMongoCollectionStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Pricing results'}
          poolingTime={ONE_HOUR}
          collection={'pricingResults'}
          maxValue={2}
          statusHandler={getMongoCollectionStatus}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Products query RT'}
          poolingTime={5 * ONE_MINUTE}
          collection={'products'}
          maxValue={45}
          statusHandler={getMongoQueryResponseTime}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Users query RT'}
          poolingTime={5 * ONE_MINUTE}
          collection={'users'}
          maxValue={45}
          statusHandler={getMongoQueryResponseTime}
        ></StatusBlock>
      </Grid>
      <Grid item>
        <StatusBlock
          title={'Last products update'}
          poolingTime={3 * ONE_HOUR}
          maxValue={24}
          statusHandler={getLastProductsUpdate}
        ></StatusBlock>
      </Grid>
    </Grid>
  )
}
