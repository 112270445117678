import { Box, IconButton, Paper, Typography, useTheme } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { OverlayMenu } from 'components/controls/misc/OverlayMenu'
import {
  AddIcon,
  CollapseIcon,
  CustomTooltip,
  DeleteIcon,
  DragIcon,
  ExpandIcon,
} from 'components/facets'
import { useErrorHandler } from 'lib/hooks'
import { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { NodeRenderer } from 'react-sortable-tree'
import {
  ConditionStrictness,
  mergeConditions,
  PricingPlanNode,
  PricingPlanNodeCondition,
} from 'shared'
import { ConditionPresenter } from '../ConditionPresenter'
import { DraggableItemsEnum, PricingPlanContext } from '../PricingPlanPresenter'
import { AffectedStockPresenter } from './AffectedStockPresenter'
import { StrategyChooserPresenter } from './StrategyChooserPresenter'

function isDescendant(older: any, younger: any): boolean {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some((child: any) => child === younger || isDescendant(child, younger))
  )
}

export const MinimalThemeNodeContentRenderer: NodeRenderer = ({
  toggleChildrenVisibility,
  connectDragPreview,
  connectDragSource,
  node,
  draggedNode,
  path,
  treeIndex,
  style,
  canDrag,
}): JSX.Element => {
  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
  const handle = connectDragSource(
    <div>
      <Box>
        <IconButton disabled={!canDrag} style={{ cursor: 'move' }}>
          <DragIcon />
        </IconButton>
      </Box>
    </div>,
    {
      dropEffect: 'copy',
    }
  )

  const canBeDeleted = true

  const {
    setConditionCtx,
    deleteNode,
    changeNode,
    setEditConditionCtx,
    highlightedNodeId,
    isDraggingCondition,
    stockByNode,
    totalStockCount,
    addRowMode,
    addRuleMode,
    setAddRuleMode,
    disallowNesting,
    setSelectedRuleNode,
    setSelectedRulePath,
    tree,
    changeTree,
    selectedTreeIndex,
    setSelectedTreeIndex,
  } = useContext(PricingPlanContext)
  const { palette } = useTheme()
  const conditions: PricingPlanNodeCondition[] = node.conditions || []
  const conditionStrictness: ConditionStrictness = node.conditionStrictness
  const { handle: handleError } = useErrorHandler()
  const { t } = useTranslation()

  const stockInThisNode = stockByNode[node.id] || []

  const [{ isOverCondition }, dropCondition] = useDrop(
    () => ({
      accept: DraggableItemsEnum.Condition,
      drop: (item) => {
        try {
          const newConditions = mergeConditions(item as PricingPlanNodeCondition, node.conditions)

          changeNode(path, {
            ...(node as PricingPlanNode),
            conditions: newConditions,
          })
        } catch (err) {
          handleError(err)
        }
      },
      collect: (monitor) => ({
        isOverCondition: !!monitor.isOver(),
      }),
    }),
    [node]
  )

  const swapElementsInArray = (ar: any[], idx1: number, idx2: number) => {
    const tmp = ar[idx1]
    ar[idx1] = ar[idx2]
    ar[idx2] = tmp
  }

  const nodeContent = connectDragPreview(
    <div style={{ backgroundColor: treeIndex === selectedTreeIndex ? palette.lightBlue : '' }}>
      <div style={{ display: 'flex' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {!disallowNesting && <div>{handle}</div>}

          {disallowNesting && (
            <Box display="flex" flexDirection="column">
              <IconButton
                onClick={() => {
                  swapElementsInArray(tree, treeIndex, treeIndex - 1)
                  changeTree(tree)
                }}
              >
                {treeIndex !== 0 && <ArrowUpward />}
              </IconButton>
              <IconButton
                onClick={() => {
                  swapElementsInArray(tree, treeIndex, treeIndex + 1)
                  changeTree(tree)
                }}
              >
                {treeIndex !== tree.length - 1 && <ArrowDownward />}
              </IconButton>
            </Box>
          )}

          {disallowNesting && (
            <CustomTooltip title={t('priceUpdates.addNewCondition') as string}>
              <IconButton
                onClick={() => {
                  setAddRuleMode(!addRuleMode)
                  setSelectedRuleNode(node)
                  setSelectedRulePath(path)
                  setSelectedTreeIndex(treeIndex)
                }}
                disabled={addRowMode || addRuleMode}
              >
                <AddIcon />
              </IconButton>
            </CustomTooltip>
          )}

          {canBeDeleted && (
            <IconButton
              onClick={() => {
                deleteNode(path)
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>

        <div>
          <div>
            <span>
              <Box display="flex">
                <Box>
                  <div
                    ref={dropCondition}
                    style={{
                      border: isDraggingCondition
                        ? `2px dashed ${
                            isOverCondition ? palette.primary.main : palette.text.secondary
                          }`
                        : 'none',
                      padding: '1px',
                    }}
                  >
                    {conditions.map((condition, index) => (
                      <Box margin={1} key={condition.id} position="relative">
                        <OverlayMenu
                          options={[
                            {
                              label: 'edit',
                              value: 'edit',
                              onClick: () => {
                                setConditionCtx(condition)
                                setEditConditionCtx({
                                  conditionId: condition.id,
                                  path,
                                  node: node as PricingPlanNode,
                                })
                              },
                            },
                            {
                              label: 'copy',
                              value: 'copy',
                              onClick: () => setConditionCtx(condition),
                            },
                            {
                              label: 'delete',
                              value: 'delete',
                              onClick: () => {
                                changeNode(path, {
                                  ...(node as PricingPlanNode),
                                  conditions: conditions.filter(
                                    (filterCondition) => condition.id !== filterCondition.id
                                  ),
                                })
                              },
                              disabled: conditions.length <= 1,
                            },
                          ]}
                        >
                          <ConditionPresenter condition={condition} />
                        </OverlayMenu>
                        {index !== conditions.length - 1 && (
                          <Box
                            onClick={() => {
                              changeNode(path, {
                                ...(node as PricingPlanNode),
                                conditionStrictness:
                                  node.conditionStrictness === ConditionStrictness.all
                                    ? ConditionStrictness.atleastOne
                                    : ConditionStrictness.all,
                              })
                            }}
                            padding={0.5}
                            position="absolute"
                            right={10}
                            bottom={-18}
                            zIndex={1301}
                            style={{ backgroundColor: 'white', cursor: 'pointer' }}
                          >
                            <Typography color="primary" variant="body1">
                              <b>
                                {conditionStrictness === ConditionStrictness.all ? 'AND' : 'OR'}
                              </b>
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </div>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" textAlign="center">
                  <AffectedStockPresenter
                    affectedStock={stockInThisNode}
                    totalStockCount={totalStockCount}
                  />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <StrategyChooserPresenter
                    nodeMethod={node.method}
                    treeIndex={treeIndex}
                    changeNodeMethod={(newMethod) =>
                      changeNode(path, { ...(node as PricingPlanNode), method: newMethod })
                    }
                  />
                </Box>
              </Box>
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Box style={{ cursor: 'initial' }} marginBottom={1} marginTop={1} data-testid="node-wrapper">
      <Box>
        <Paper
          elevation={highlightedNodeId === node.id ? 5 : 1}
          style={{
            border: highlightedNodeId === node.id ? `2px solid ${palette.primary.main}` : 'none',
          }}
        >
          <Box padding={1.5} display="flex" justifyContent="space-between">
            <div>
              <div
                style={{
                  opacity: isDraggedDescendant ? 0.5 : 1,
                  ...style,
                }}
              >
                {nodeContent}
              </div>
            </div>

            <Box display="flex" alignItems="center" paddingRight={1}>
              {toggleChildrenVisibility &&
                node.children &&
                (node.children.length > 0 || typeof node.children === 'function') && (
                  <IconButton
                    size="medium"
                    onClick={() =>
                      toggleChildrenVisibility({
                        node,
                        path,
                        treeIndex,
                      })
                    }
                  >
                    {node.expanded ? (
                      <ExpandIcon color="primary" style={{ fontSize: '2rem' }} />
                    ) : (
                      <CollapseIcon color="primary" style={{ fontSize: '2rem' }} />
                    )}
                  </IconButton>
                )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
