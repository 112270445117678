import { Box, Typography } from '@material-ui/core'
import { loadStripe } from '@stripe/stripe-js'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { createStripeSession, editUserAttribute, setupSepaMandate } from 'lib/api'
import { useConfirm, useErrorHandler } from 'lib/hooks'
import qs from 'query-string'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export const SepaMandatePresenter = (): JSX.Element => {
  const { fetchUser, user, setUser } = useUser()

  if (!user) return <></>

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '')
  const location = useLocation()
  const { t } = useTranslation()

  const revokeSepaMandate = async (): Promise<void> => {
    try {
      const resp = await editUserAttribute(null, 'stripePaymentMethod')
      setUser({ ...user!, ...resp })
    } catch (err) {
      handle(err)
    }
  }

  const [confirmRevoke, ConfirmDialog] = useConfirm(t('areYouSure'), '', revokeSepaMandate)

  const { handle } = useErrorHandler()
  const stripeSessionId = qs.parse(location.search)?.session_id as string

  const startGettingSepaMandate = async (): Promise<void> => {
    try {
      const { sessionId } = await createStripeSession('account')
      const stripe = await stripePromise
      const { error } = await stripe!.redirectToCheckout({
        sessionId,
      })
      if (error) throw error
    } catch (err) {
      handle(err)
    }
  }

  useEffect(() => {
    const handleStripeCallback = async (): Promise<void> => {
      if (stripeSessionId) {
        await setupSepaMandate(stripeSessionId)
        fetchUser()
      }
    }
    handleStripeCallback()
  }, [stripeSessionId])

  return (
    <>
      {!user.stripePaymentMethod ? (
        <Box>
          <Typography variant="body1">{t('account.sepaMandate.description')}</Typography>
          <Box paddingTop={2} width="fit-content" margin="0 auto">
            <Button variant="contained" onClick={startGettingSepaMandate}>
              {t('account.sepaMandate.setup')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography variant="body1">{t('account.sepaMandate.description')}</Typography>
          <Box padding={2} width="fit-content" margin="0 auto">
            <Button onClick={confirmRevoke}>{t('account.sepaMandate.revoke')}</Button>
          </Box>
        </Box>
      )}
      <ConfirmDialog />
    </>
  )
}
