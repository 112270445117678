import { red } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    selected: Palette['primary']
    lightGray: string
    lightBackground: string
    lighterBackground: string
    lightBlue: string
    disabled: string
    active: string
    MUILightGray: string
    black: string
    strongViolet: string
    pastelGreen: string
    pastelRed: string
  }
  interface PaletteOptions {
    selected: PaletteOptions['primary']
    lightGray: string
    lightBlue: string
    lightBackground: string
    lighterBackground: string
    disabled: string
    active: string
    MUILightGray: string
    black: string
    strongViolet: string
    pastelGreen: string
    pastelRed: string
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    gradient: string
    gradientLighter: string
    gradientNew: string
  }
  interface ThemeOptions {
    gradient: string
    gradientLighter: string
    gradientNew: string
  }
}

export const theme = createTheme({
  typography: {
    htmlFontSize: 18,
    fontFamily: 'Ubuntu',
  },

  overrides: {
    MuiTableCell: {
      root: {
        padding: '2px 5px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '4px 24px',
      },
    },
    MuiDrawer: {
      paper: {
        zIndex: 990,
      },
    },
  },
  gradient: 'linear-gradient(90deg, rgba(0,25,162,1) 0%, rgba(76,0,163,1) 100%)',
  gradientLighter: 'linear-gradient(90deg, rgba(0,25,162,0.90) 0%, rgba(76,0,163,0.90) 100%)',
  gradientNew: 'linear-gradient(45deg, #575089CC 0%, #575089FF 70%)',

  palette: {
    primary: {
      main: 'rgb(0,25,162)',
      light: '#b5bbdc',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.64);',
      dark: '#808080',
    },
    error: {
      main: red.A400,
    },
    selected: {
      main: '#ffe000',
    },

    background: {
      default: '#fff',
    },
    lightGray: '#0000008a',
    lightBackground: '#f3f2ff',
    lighterBackground: '#f3f2ff88',
    lightBlue: '#b5bbdc',
    disabled: '#00000042',
    active: '#d415c2',
    MUILightGray: 'rgba(0, 0, 0, 0.42)',
    black: '#000000',
    strongViolet: '#3A0BBE',
    pastelGreen: '#77dd77',
    pastelRed: '#ff9997',
  },
})

/*
#560561
#480569
#260052 primary
#1F0569
#09055E
*/
