import { escapeRegExp, isArticleEdited } from 'lib/utils'
import { Filters, Game, InventoryArticle } from 'shared'
import { CONDITIONS_LOOKUP } from 'utils/constants'
import { QUANTITY_FILTER_MAX } from './FilterDock/FilterPresenter'

export const applyFilters = (
  filters: Filters,
  stock: InventoryArticle[],
  activeGame: Game
): InventoryArticle[] => {
  const {
    edited,
    comment,
    name,
    condition,
    quantity,
    minPrice,
    maxPrice,
    foil,
    playset,
    language,
    selected,
    reverseHolo,
    firstEd,
    exactPrice,
    expansion,
    signed,
    rarity,
  } = filters
  let finalStock = [...stock]

  if (!finalStock.length) {
    return stock
  }

  // setLoading(true)

  if (name) {
    finalStock = finalStock.filter(
      (item) => item.card.name.toLowerCase().indexOf(name.trim().toLowerCase()) !== -1
    )
  }
  if (comment) {
    const wordsToFind = comment
      .replace(/^!/, '')
      .replace(/^;/, '')
      .split('\n')
      .map((line) => escapeRegExp(line.trim()))
      .filter((line) => line)
      .join('|')

    const isEmptyCommentQuery =
      comment.replace('!', '') === '""' || comment.replace('!', '') === "''"

    const regExp = new RegExp(wordsToFind, 'i')
    if (isEmptyCommentQuery) {
      finalStock = finalStock.filter((stockItem) => {
        return comment[0] === '!'
          ? stockItem.currentComment !== ''
          : stockItem.currentComment === ''
      })
    } else if (comment[0] === '!') {
      finalStock = finalStock.filter(
        (stockItem) => !regExp.test(stockItem.newComment || stockItem.comment)
      )
    } else if (comment[0] === ';') {
      const exactWord = comment.slice(1).trim()

      finalStock = finalStock.filter((stockItem) => stockItem.currentComment === exactWord)
    } else {
      finalStock = finalStock.filter((stockItem) =>
        regExp.test(stockItem.newComment || stockItem.comment)
      )
    }
  }

  if (exactPrice) {
    finalStock = finalStock.filter((item) =>
      item.newPrice ? item.newPrice === Number(exactPrice) : item.price === Number(exactPrice)
    )
  }

  if (edited !== 'any')
    finalStock = finalStock.filter((item) =>
      edited ? isArticleEdited(item) : !isArticleEdited(item)
    )

  if (foil !== 'any') finalStock = finalStock.filter((item) => (foil ? item.isFoil : !item.isFoil))

  if (playset !== 'any')
    finalStock = finalStock.filter((item) => (playset ? item.isPlayset : !item.isPlayset))

  if (firstEd !== 'any')
    finalStock = finalStock.filter((item) => (firstEd ? item.isFirstEd : !item.isFirstEd))

  if (signed !== 'any')
    finalStock = finalStock.filter((item) => (signed ? item.isSigned : !item.isSigned))

  if (selected !== 'any')
    finalStock = finalStock.filter((item) => (selected ? item.selected : !item.selected))

  if (reverseHolo !== 'any')
    finalStock = finalStock.filter((item) =>
      reverseHolo ? item.isReverseHolo : !item.isReverseHolo
    )

  if (condition[1] - condition[0] !== 6) {
    finalStock = finalStock.filter(
      (item) =>
        CONDITIONS_LOOKUP[item.condition] >= condition[0] &&
        CONDITIONS_LOOKUP[item.condition] <= condition[1]
    )
  }

  if (quantity[0] !== 1) {
    finalStock = finalStock.filter((item) => item.quantity >= quantity[0])
  }

  if (quantity[1] !== QUANTITY_FILTER_MAX) {
    finalStock = finalStock.filter((item) => item.quantity <= quantity[1])
  }

  if (rarity[0] !== 1) {
    finalStock = finalStock.filter((item) => item.card.rarity >= rarity[0])
  }

  if (rarity[1] !== Object.keys(activeGame.rarities).length) {
    finalStock = finalStock.filter((item) => item.card.rarity <= rarity[1])
  }

  if (minPrice) {
    finalStock = finalStock.filter((item) =>
      item.newPrice ? item.newPrice >= minPrice : item.price >= minPrice
    )
  }

  if (maxPrice) {
    finalStock = finalStock.filter((item) =>
      item.newPrice ? item.newPrice <= maxPrice : item.price <= maxPrice
    )
  }

  if (language !== 'any') {
    finalStock = finalStock.filter((item) => item.idLanguage === language)
  }

  if (expansion) {
    finalStock = finalStock.filter((item) => item.card.set === expansion?.code)
  }

  // setLoading(false)
  return finalStock
}
