import { LoadingScreen } from 'components/facets/LoadingScreen'
import { cardDataDB } from 'config/localforage'
import { getCardData } from 'lib/api'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import React, { useContext, useEffect, useState } from 'react'
import { AppLanguage, Card, Expansion, IdGame, LocalizedCard } from 'shared'
import { ElementWithChildren, SetState } from 'types'
import { useUser } from './UserProvider'

const CardDataContext = React.createContext<{
  cardData: Card[]
  expansions: Expansion[]
}>({ cardData: [], expansions: [] })

export const useCardData = (): Card[] => {
  const value = useContext(CardDataContext)

  if (!value) throw new Error('useCardData was called outside of priceguide context provider')

  return value.cardData
}

export const useCardThingData = (
  nameLanguage: AppLanguage = 'en'
): { cardData: LocalizedCard[]; setLanguage: SetState<AppLanguage> } => {
  const value = useContext(CardDataContext)
  if (!value) throw new Error('useCardData was called outside of priceguide context provider')

  const [language, setLanguage] = useState(nameLanguage)

  const [localizedCardData, setLocalizedCardData] = useState(
    value.cardData.map((card) => {
      return new LocalizedCard({ attributes: card.attributes, language })
    })
  )

  useEffect(() => {
    setLocalizedCardData(
      value.cardData.map((card) => new LocalizedCard({ attributes: card.attributes, language }))
    )
  }, [value.cardData, language])

  return {
    cardData: localizedCardData,
    setLanguage,
  }
}

export const useExpansionData = (): Expansion[] => {
  const value = useContext(CardDataContext)
  return value.expansions
}

export const CardDataContextProvider = ({ children }: ElementWithChildren): JSX.Element => {
  const { activeGame } = useUser()

  const [cardDataState, setCardDataState, loadingFromLocal] = useLocalForageState<{
    cardData: Card[]
    expansions: any
    version?: string
  }>({ cardData: [], expansions: [] }, `${activeGame.name}_cardData`, {
    customLocalDB: cardDataDB,
    hydrate: (cardState) => ({
      ...cardState,
      cardData: cardState.cardData.map((card) => new Card(card)),
    }),
  })

  useEffect(() => {
    const update = async () => {
      try {
        if (!loadingFromLocal) {
          const newCardDataResponse = await getCardData(activeGame.idGame, cardDataState.version)

          if (newCardDataResponse?.clientIsUpToDate) return

          // include generic accessories -- we update these only when we have to update card data for the active game
          const accessoriesDataResponse = await getCardData(0 as IdGame, undefined)
          // since we're calling with version "undefined", we should never get clientIsUpToDate for accessories data
          if (accessoriesDataResponse?.clientIsUpToDate) return

          const newCardData = newCardDataResponse.resourceData
          const itemsData = newCardData.cards.concat(accessoriesDataResponse.resourceData.cards)
          if (itemsData.length) {
            setCardDataState({
              cardData: itemsData.map((attributes) => new Card({ attributes })),
              expansions: newCardData.expansions,
              version: newCardDataResponse.version,
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    update()

    const interval = setInterval(update, 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [loadingFromLocal, cardDataState])

  if (!cardDataState.cardData.length) return <LoadingScreen />

  return (
    <CardDataContext.Provider
      value={{
        cardData: cardDataState.cardData,
        expansions: cardDataState.expansions,
      }}
    >
      {children}
    </CardDataContext.Provider>
  )
}
