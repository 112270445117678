import { DialogActions, DialogProps, DialogTitle } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import { ClosableDialog } from 'components/controls/dialogs'
import { Dispatch, ReactNode, SetStateAction, useState } from 'react'

interface DialogRenderedValueProps {
  title: string | JSX.Element
  actions?: (handleClose: () => void) => ReactNode
  content: ReactNode
  maxWidth?: DialogProps['maxWidth']
}

export const useDialog = (): readonly [
  Dispatch<SetStateAction<boolean>>,
  ({ maxWidth, title, actions, content }: DialogRenderedValueProps) => JSX.Element,
  boolean
] => {
  const [open, setOpen] = useState(false)
  const handleClose = (): void => {
    setOpen(false)
  }

  const RenderedValue = ({
    maxWidth = 'md',
    title,
    actions,
    content,
  }: DialogRenderedValueProps): JSX.Element => {
    return (
      <ClosableDialog
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        {<DialogActions>{actions && actions(handleClose)}</DialogActions>}
      </ClosableDialog>
    )
  }

  return [setOpen, RenderedValue, open] as const
}
