import { Box, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useExpansionData } from 'components/providers/CardDataProvider'
import { useUser } from 'components/providers/UserProvider'
import { recordUsage } from 'lib/api'
import { useStockStore } from 'lib/hooks/useStockStore'
import moment from 'moment'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { Activity, CardExtraAttribute, CardLanguage, InventoryArticle } from 'shared'

interface ExportCsvProps {
  view: 'stockPricing' | 'csvDialog' | 'addArticles'
  stock: InventoryArticle[]
  sealedStock: InventoryArticle[]
  selectedStock?: InventoryArticle[]
}

interface CsvArticleItem {
  cardmarketId: number
  quantity: number
  name: string
  set: string
  setCode: string
  cn?: string | null
  condition: string
  language: string
  isFoil?: boolean
  isPlayset?: boolean
  isSigned?: boolean
  isFirstEd?: boolean
  isReverseHolo?: boolean
  price?: number | null
  comment: string
  nameDE?: string
  nameES?: string
  nameFR?: string
  nameIT?: string
  rarity?: string
  listedAt?: string
}

export const ExportCsv = ({
  stock,
  sealedStock = [],
  view,
  selectedStock = [],
}: ExportCsvProps): JSX.Element => {
  const expansions = useExpansionData()
  const [data, setData] = useState<CsvArticleItem[]>([])
  const { user, activeGame } = useUser()
  const { t } = useTranslation()
  const activeStockType = useStockStore((state) => state.activeStockType)

  const handleDownload = (
    done: (proceed: boolean | undefined) => void,
    onlySelected: boolean
  ): void => {
    const currentStock = activeStockType === 'sealed' ? sealedStock : stock
    const stockToExport = onlySelected ? selectedStock : currentStock

    recordUsage(Activity.ExportCSV, {
      count: stockToExport.length,
      idGame: activeGame.idGame,
    }).then(null)

    setData(
      stockToExport
        .map(
          ({
            card: { name, set, cn, _id, locNames, rarityStr },
            comment,
            quantity,
            price,
            isFoil,
            isPlayset,
            isSigned,
            isFirstEd,
            isReverseHolo,
            condition,
            idLanguage,
            newPrice,
            newComment,
            listedAt,
          }) => {
            const row: CsvArticleItem = {
              cardmarketId: _id,
              quantity,
              name: escapeDoubleQuotes(name),
              // name: `"${name}"`,
              set: escapeDoubleQuotes(expansions.find((exp) => set === exp.code)?.name),
              setCode: set,
              cn: cn,
              condition,
              language: CardLanguage[idLanguage],
              ...(activeGame.extraAttr.includes(CardExtraAttribute.Foil)
                ? { isFoil: isFoil ? isFoil : undefined }
                : {}),
              ...(activeGame.extraAttr.includes(CardExtraAttribute.Playset)
                ? { isPlayset: isPlayset ? isPlayset : undefined }
                : {}),
              ...(activeGame.extraAttr.includes(CardExtraAttribute.FirstEd)
                ? { isFirstEd: isFirstEd ? isFirstEd : undefined }
                : {}),
              ...(activeGame.extraAttr.includes(CardExtraAttribute.ReverseHolo)
                ? { isReverseHolo: isReverseHolo ? isReverseHolo : undefined }
                : {}),
              isSigned: isSigned ? isSigned : undefined,
              ...(newPrice ? { oldPrice: price, price: newPrice } : { price }),
              ...(newComment ? { oldComment: comment, comment: newComment } : { comment }),
              nameDE: locNames ? escapeDoubleQuotes(locNames['DE']) : '',
              nameES: locNames ? escapeDoubleQuotes(locNames['ES']) : '',
              nameFR: locNames ? escapeDoubleQuotes(locNames['FR']) : '',
              nameIT: locNames ? escapeDoubleQuotes(locNames['IT']) : '',
              rarity: rarityStr,
              listedAt: listedAt ? moment(listedAt).format('DD-MM-YYYY HH:mm:ss') : '',
            }
            return row
          }
        )
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
    )
    if (window.Cypress) done(false)
    else done(true)
  }

  const fileName = `export-${user?.username}-${moment(Date.now()).format('DD-MM-YYYY_HH-mm')}.csv`

  return (
    <>
      {view === 'csvDialog' && (
        <CSVLink
          data={data}
          asyncOnClick={true}
          onClick={(_event, done) => {
            handleDownload(done, false)
          }}
          enclosingCharacter={`"`}
          filename={fileName}
        >
          <Button fullWidth variant="contained" color="primary">
            {t('csv.download')}
          </Button>
        </CSVLink>
      )}
      {view === 'stockPricing' && (
        <>
          <Typography>{t('csv.infoText')}</Typography>
          <Box height="10px" />
          {stock.length ? (
            <CSVLink
              data={data}
              asyncOnClick={true}
              onClick={(_event, done) => handleDownload(done, false)}
              filename={fileName}
            >
              <Button fullWidth variant="contained" color="primary">
                {t('csv.downloadStock')}
              </Button>
            </CSVLink>
          ) : (
            t('csv.nothingToExport')
          )}

          <Box height="20px" />
          {selectedStock.length ? (
            <CSVLink
              data={data}
              asyncOnClick={true}
              onClick={(_event, done) => handleDownload(done, true)}
              filename={fileName}
            >
              <Button fullWidth variant="contained" color="primary">
                {t('csv.downloadSelected')}
              </Button>
            </CSVLink>
          ) : (
            <Button disabled fullWidth variant="contained" color="primary">
              {t('csv.downloadSelected')}
            </Button>
          )}

          <Box height="20px" />
        </>
      )}
      {view === 'addArticles' && (
        <>
          <Typography>{t('csv.exportArticles')}</Typography>
          <Box height="10px" />
          {stock.length ? (
            <CSVLink
              data={data}
              asyncOnClick={true}
              onClick={(_event, done) => handleDownload(done, false)}
              filename={fileName}
            >
              <Button fullWidth variant="contained" color="primary">
                {t('csv.download')}
              </Button>
            </CSVLink>
          ) : (
            t('csv.noArticlesEntered')
          )}
        </>
      )}
    </>
  )
}

const escapeDoubleQuotes = (value: string | null | undefined): string =>
  (value || '').replace(/"/g, '""')
