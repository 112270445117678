import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useExpansionData } from 'components/providers/CardDataProvider'
import { Expansion, ExpansionItem } from 'shared'

interface ExpansionAutocompleteProps {
  onChange: (value: ExpansionItem & { name: string }) => void
  options?: Expansion[]
  value: string
}

export const ExpansionAutocomplete = ({
  onChange,
  options = [],
  value,
}: ExpansionAutocompleteProps): JSX.Element => {
  const expansions = useExpansionData()

  return (
    <Autocomplete<Expansion>
      options={[...options, ...expansions.sort((a, b) => a.name.localeCompare(b.name))]}
      renderOption={(option) => option.name}
      value={expansions.find(({ code }) => value === code) || options[0]}
      getOptionLabel={(option) => `${option.name} ${option.code}`}
      onChange={async (_e, newValue) => {
        if (newValue) {
          onChange(newValue)
        } else {
          onChange({ name: 'All Expansions', code: '', _id: 0 })
        }
      }}
      autoHighlight
      getOptionSelected={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          name="expansion"
          margin="dense"
          type="text"
          autoFocus
          onFocus={(e) => e.target.select()}
          fullWidth
        />
      )}
    />
  )
}
