import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Typography,
} from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { addInventoryItem, clearInventory, loadInventory } from 'lib/api'
import { useDialog, useErrorHandler } from 'lib/hooks'
import { useFetchUserStock } from 'lib/hooks/useFetchUserStock'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, Column, Table } from 'react-virtualized'
import {
  CardLanguage,
  IdGame,
  InventoryArticle,
  InventoryFetchState,
  PTInventoryArticle,
  PTInventoryProduct,
  PTProduct,
} from 'shared'
import { getInventoryArticle } from 'utils/lib'
import { StockState } from '../stockPricing'
import { VisualArticle } from '../stockPricing/StockDisplay/VisualArticle'

export const SyncInventoryPresenter = (): JSX.Element => {
  // const { cardData } = useCardThingData('en')

  const { handle } = useErrorHandler()
  const { t } = useTranslation()
  const { user } = useUser()
  const fetchUserStock = useFetchUserStock()

  const [setFetchingDialogOpen, FetchingDialog] = useDialog()
  const [setLoadingDialogOpen, LoadingDialog] = useDialog()
  const [setFinishedImportDialogOpen, FinishedImportDialog] = useDialog()
  const [fetchState, setFetchState] = useState(InventoryFetchState.FETCHING)
  fetchState
  const [stockState, setStockState] = useState<StockState>({
    stock: [] as InventoryArticle[],
    sealedStock: [] as InventoryArticle[],
    fetchedAt: '',
  })
  const [ptInventory, setPtInventory] = useState<PTInventoryProduct[]>([])
  const [ptArticles, setPtArticles] = useState<PTInventoryArticle[]>([])
  const [importedArticles, setImportedArticles] = useState<number>(0)

  const activeGameId = IdGame.Magic

  const fetchStock = useCallback(async (): Promise<StockState> => {
    let newState = {
      stock: [] as InventoryArticle[],
      sealedStock: [] as InventoryArticle[],
      fetchedAt: '',
    }
    setFetchState(InventoryFetchState.FETCHING)
    setFetchingDialogOpen(true)
    try {
      const stockData = await fetchUserStock(activeGameId)
      const sealedStockData = await fetchUserStock(activeGameId, true)

      newState = {
        stock: stockData,
        sealedStock: sealedStockData,
        fetchedAt: new Date().toLocaleString('sk-SK'),
      }

      setFetchState(InventoryFetchState.IDLE)
    } catch (error: any) {
      handle(error)
      if (error?.response?.data?.error?.message === 'missingCardmarketConnection') {
        setFetchState(InventoryFetchState.FAILED_DISCONNECTED_ACCOUNT)
        throw new Error('missingCardmarketConnection')
      } else {
        setFetchState(InventoryFetchState.FAILED_UNKNOWN)
      }
    }
    setFetchingDialogOpen(false)

    return newState
  }, [fetchUserStock])

  useEffect(() => {
    const newPtArticles: PTInventoryArticle[] = []
    ptInventory.forEach((ptInventoryProduct) => {
      ptInventoryProduct.articles.forEach((ptArticle) => {
        newPtArticles.push(ptArticle)
      })
    })
    setPtArticles(newPtArticles)
  }, [ptInventory])

  const diffInventoryArticles = (incomingPtInventoryArticles: PTInventoryArticle[]) => {
    const newPtInventoryArticles: PTInventoryArticle[] = []
    incomingPtInventoryArticles.forEach((incomingPtInventoryArticle) => {
      const existingPtInventoryArticle = ptArticles.find((ptArticle) => {
        return (
          ptArticle.ptProduct.mkmProductId === incomingPtInventoryArticle.idProduct &&
          ptArticle.ptProduct.idLanguage === incomingPtInventoryArticle.idLanguage &&
          ptArticle.ptProduct.isFoil === incomingPtInventoryArticle.isFoil &&
          ptArticle.ptProduct.isFirstEd === incomingPtInventoryArticle.isFirstEd &&
          ptArticle.ptProduct.isSigned === incomingPtInventoryArticle.isSigned &&
          ptArticle.comment === incomingPtInventoryArticle.comment
        )
      })
      if (existingPtInventoryArticle) {
        if (existingPtInventoryArticle.quantity !== incomingPtInventoryArticle.quantity) {
          console.log(`different quantity`)
        } else {
          console.log(`same quantity`)
        }
      } else {
        console.log(`add new article`)
        newPtInventoryArticles.push(incomingPtInventoryArticle)
      }
    })
    return newPtInventoryArticles
  }

  const handleStockImport = async () => {
    setLoadingDialogOpen(true)
    const tmpPtInventoryArticles: PTInventoryArticle[] = []
    for (const inventoryArticle of stockState.stock) {
      const ptProduct: PTProduct = {
        _id: '',
        mkmProductId: inventoryArticle.idProduct,
        condition: inventoryArticle.condition,
        idLanguage: inventoryArticle.idLanguage,
        isFirstEd: inventoryArticle.isFirstEd,
        isReverseHolo: inventoryArticle.isReverseHolo,
        isFoil: inventoryArticle.isFoil,
        isSigned: inventoryArticle.isSigned,
      }
      const ptInventoryArticle: PTInventoryArticle = {
        _id: '',
        ptProduct: ptProduct,
        baseSellPrice: inventoryArticle.price,
        unitBuyPrice: inventoryArticle.price,
        userId: user._id,
        ...inventoryArticle.toPlainObject(),
      }

      tmpPtInventoryArticles.push(ptInventoryArticle)
      // await addInventoryItem(ptInventoryArticle)
    }

    await handleFetchInventory()
    const newPtInventoryArticles = diffInventoryArticles(tmpPtInventoryArticles)
    for (const ptInventoryArticle of newPtInventoryArticles) {
      await addInventoryItem(ptInventoryArticle)
    }
    setImportedArticles(newPtInventoryArticles.length)
    setFinishedImportDialogOpen(true)

    setStockState({
      stock: [] as InventoryArticle[],
      sealedStock: [] as InventoryArticle[],
      fetchedAt: '',
    })
    await handleFetchInventory()
    setLoadingDialogOpen(false)
  }

  const handleImportStock = async () => {
    const newStockState = await fetchStock()
    console.log(newStockState)
    setStockState(newStockState)
  }

  const handleClearInventory = async () => {
    setLoadingDialogOpen(true)
    await clearInventory()
    await handleFetchInventory()
    setLoadingDialogOpen(false)
  }

  const handleFetchInventory = async () => {
    setLoadingDialogOpen(true)
    const ptInventory = await loadInventory()
    console.log(ptInventory)
    setPtInventory(ptInventory)
    setLoadingDialogOpen(false)
  }

  const getArticleLocalizedName = (article: InventoryArticle) => {
    const idLanguage = article.attributes[2]
    if (idLanguage === CardLanguage.English) {
      return article.card.name
    } else if (idLanguage === CardLanguage.German) {
      return article.card.locNames.DE
    } else if (idLanguage === CardLanguage.French) {
      return article.card.locNames.FR
    } else if (idLanguage === CardLanguage.Spanish) {
      return article.card.locNames.ES
    } else if (idLanguage === CardLanguage.Italian) {
      return article.card.locNames.IT
    }
  }

  const getPTArticleLocalizedName = (article: any) => {
    const idLanguage = article.ptProduct.idLanguage
    if (idLanguage === CardLanguage.English) {
      return article.mkmProduct.name
    } else if (idLanguage === CardLanguage.German) {
      return article.mkmProduct.locNames.DE
    } else if (idLanguage === CardLanguage.French) {
      return article.mkmProduct.locNames.FR
    } else if (idLanguage === CardLanguage.Spanish) {
      return article.mkmProduct.locNames.ES
    } else if (idLanguage === CardLanguage.Italian) {
      return article.mkmProduct.locNames.IT
    }
  }

  return (
    <>
      <Box padding={2}>
        <Typography variant="h6">PT Inventory</Typography>
        <Box display="flex">
          <Box width="25%">
            <Button fullWidth variant="contained" onClick={() => handleImportStock()}>
              import my stock from Cardmarket
            </Button>
          </Box>

          <Box width="20%" marginLeft={2}>
            <Button fullWidth variant="contained" onClick={() => handleFetchInventory()}>
              fetch PT inventory
            </Button>
          </Box>

          <Box width="20%" marginLeft={2}>
            <Button fullWidth variant="contained" onClick={() => handleClearInventory()}>
              clear inventory
            </Button>
          </Box>
        </Box>

        <Box marginTop={1} />

        {stockState.stock.length > 0 ? (
          <>
            <Box>
              <Typography variant="body1">
                Almost there. Here are all your items on Cardmarket.
              </Typography>

              <TableContainer component={Paper}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <Table
                      width={width}
                      height={500}
                      headerHeight={30}
                      rowHeight={45}
                      rowCount={stockState.stock.length}
                      rowGetter={({ index }) => stockState.stock[index]}
                    >
                      <Column
                        width={180}
                        label="Name"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.card.name}</>}
                      />
                      <Column
                        width={180}
                        label="Localized name"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => (
                          <>{getArticleLocalizedName(article)}</>
                        )}
                      />
                      <Column
                        width={150}
                        label="Expansion"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.card.expansion}</>}
                      />
                      <Column
                        width={50}
                        label="Foil"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.isFoil.toString()}</>}
                      />
                      <Column
                        width={50}
                        label="Sign"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.isSigned.toString()}</>}
                      />
                      <Column
                        width={80}
                        label="Condition"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.condition}</>}
                      />
                      <Column
                        width={80}
                        label="Language"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => (
                          <>{CardLanguage[article.idLanguage]}</>
                        )}
                      />
                      <Column
                        width={80}
                        label="Quantity"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.quantity}</>}
                      />
                      <Column
                        width={50}
                        label="Playset"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.isPlayset.toString()}</>}
                      />
                      <Column
                        width={40}
                        label="Price"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.price}</>}
                      />
                      <Column
                        width={80}
                        label="Comment"
                        dataKey=""
                        cellRenderer={({ rowData: article }) => <>{article.comment}</>}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </TableContainer>
            </Box>
            <Box marginTop={1}>
              <Button variant="contained" onClick={handleStockImport}>
                Start importing
              </Button>
            </Box>
          </>
        ) : (
          <>Empty MKM stock</>
        )}
      </Box>

      <Box padding={2}>
        <Typography variant="h6">PTArticles</Typography>
        {ptArticles.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    width={width}
                    height={500}
                    headerHeight={30}
                    rowHeight={45}
                    rowCount={ptArticles.length}
                    rowGetter={({ index }) => ptArticles[index]}
                  >
                    <Column
                      width={180}
                      label="Name"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => <>{article.mkmProduct.name}</>}
                    />
                    <Column
                      width={180}
                      label="Localized name"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => (
                        <>{getPTArticleLocalizedName(article)}</>
                      )}
                    />
                    <Column
                      width={150}
                      label="Expansion"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => <>{article.expansion.name}</>}
                    />
                    <Column
                      width={50}
                      label="Foil"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => (
                        <>
                          {article.ptProduct.isFoil ? article.ptProduct.isFoil.toString() : 'false'}
                        </>
                      )}
                    />
                    <Column
                      width={50}
                      label="Sign"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => (
                        <>
                          {article.ptProduct.isSigned
                            ? article.ptProduct.isSigned.toString()
                            : 'false'}
                        </>
                      )}
                    />
                    <Column
                      width={80}
                      label="Condition"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => <>{article.ptProduct.condition}</>}
                    />
                    <Column
                      width={80}
                      label="Language"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => (
                        <>{CardLanguage[article.ptProduct.idLanguage]}</>
                      )}
                    />
                    <Column
                      width={80}
                      label="Quantity"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => <>{article.quantity}</>}
                    />
                    <Column
                      width={50}
                      label="Playset"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => (
                        <>
                          {article.ptProduct.isPlayset
                            ? article.ptProduct.isPlayset.toString()
                            : 'false'}
                        </>
                      )}
                    />
                    <Column
                      width={80}
                      label="Comment"
                      dataKey=""
                      cellRenderer={({ rowData: article }) => <>{article.comment}</>}
                    />
                  </Table>
                )}
              </AutoSizer>
            </TableContainer>
          </>
        ) : (
          <>Empty pt articles</>
        )}
      </Box>

      <Box padding={2}>
        <Typography variant="h6">PT Stock (ptproducts)</Typography>
        {ptInventory.length > 0 ? (
          <>
            <Grid container>
              {ptInventory.map((ptInventoryProduct) => (
                <Grid item key={ptInventoryProduct._id}>
                  <Box height={300} width={250}>
                    <VisualArticle
                      rowHeight={300}
                      article={getInventoryArticle(ptInventoryProduct)}
                      parentEl="ptInventory"
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>Empty PT inventory</>
        )}
      </Box>

      <FetchingDialog
        title={t('stockPricing.loadingStock')}
        content={
          <Box marginTop={3} height="8rem" width="fit-content" margin="0 auto">
            <CircularProgress size={100} />
          </Box>
        }
      />

      <LoadingDialog
        title="Loading..."
        content={
          <Box marginTop={3} height="8rem" width="fit-content" margin="0 auto">
            <CircularProgress size={100} />
          </Box>
        }
      />

      <FinishedImportDialog
        title={`Imported ${importedArticles} articles`}
        content={
          <Box marginTop={3} height="3rem" width="fit-content" margin="0 auto">
            <Button variant="contained" onClick={() => setFinishedImportDialogOpen(false)}>
              OK
            </Button>
          </Box>
        }
      />
    </>
  )
}
