import { Typography } from '@material-ui/core'
import { checkSentry } from 'lib/api'

export const KaboomPresenter = (): JSX.Element => {
  ;(async () => {
    await checkSentry()
  })()

  return <Typography variant="h4">Kaboom test page</Typography>
}
