import { useUser } from 'components/providers/UserProvider'
import { localDB } from 'config/localforage'
import { useCallback, useEffect, useState } from 'react'

export const useLocalForageState = <T>(
  defaultValue: T,
  name: string,
  config: {
    duckFunction?: (value: T) => void
    hydrate?: (value: T) => T
    customLocalDB?: LocalForage
  } = {}
): readonly [T, (newValue: T | ((arg: T) => T)) => void, boolean] => {
  const forageClient = config.customLocalDB || localDB

  const { duckFunction, hydrate } = config
  const [value, setValue] = useState(defaultValue)

  const [loading, setLoading] = useState(true)
  const { activeGame } = useUser()

  useEffect(() => {
    forageClient.getItem(`${activeGame.idGame}-${name}`).then((value) => {
      try {
        //@ts-ignore
        duckFunction && duckFunction(value)
        const hydrated = hydrate ? hydrate(value as T) : value
        setValue((hydrated as T) || defaultValue)
      } catch (err) {
        //@ts-ignore
        setValue(defaultValue)
      }
      setLoading(false)
    })
  }, [])

  const setLocalForageState = useCallback((newValue): void => {
    if (typeof newValue === 'function') {
      setValue((val: any) => {
        forageClient.setItem(`${activeGame.idGame}-${name}`, newValue(val)).catch((err) => {
          console.error(err)
        })
        return newValue(val)
      })
    } else {
      forageClient.setItem(`${activeGame.idGame}-${name}`, newValue).catch((err) => {
        console.error(err)
      })
      setValue(newValue)
    }
  }, [])

  return [value, setLocalForageState, loading]
}
