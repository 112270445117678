import { Box, SvgIconTypeMap, Typography } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from 'components/facets'
import { theme } from 'config'

export interface SquareButtonProps {
  type: 'action' | 'help'
  size: string
  IconSize: string
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  Title: string
  Description?: string
  OnClickAction: () => void
  disabled?: boolean
}

export const SquareButton = ({
  type,
  size,
  Icon,
  IconSize,
  Title,
  Description,
  OnClickAction,
  disabled,
}: SquareButtonProps): JSX.Element => {
  const useStyles = makeStyles(() =>
    createStyles({
      action: {
        cursor: 'pointer',
        transition: 'all 0.2s',
        width: size,
        height: size,
        textAlign: 'center',
        fontSize: '20px',
        marginTop: '10px',
        alignItems: 'baseline',
        display: 'grid',
        background: theme.gradient,
        borderRadius: '10px',
        color: 'white',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0px 4px 8px -2px rgba(0,0,0,0.5)',
          background: theme.gradientLighter,
        },
        '&:disabled': {
          color: 'dark',
          background: theme.gradientLighter,
        },
      },
      help: {
        cursor: 'pointer',
        transition: 'all 0.2s',
        width: size,
        height: size,
        textAlign: 'center',
        fontSize: '20px',
        marginTop: '10px',
        alignItems: 'baseline',
        display: 'grid',
        background: theme.palette.secondary.main,
        borderRadius: '10px',
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0px 4px 8px -2px rgba(0,0,0,0.5)',
        },
      },
      iconBox: {
        marginTop: Description ? '20px' : '50px',
        marginBottom: Description ? '15px' : '35px',
      },
      pageDescriptionStyle: {
        marginTop: '5px',
        textTransform: 'initial',
        marginLeft: '25px',
        marginRight: '25px',
        textAlign: 'center',
      },
    })
  )

  const classes = useStyles()

  return (
    <Button onClick={OnClickAction} className={`${classes[type]}`} disabled={disabled}>
      <Box marginRight={1} className={classes.iconBox}>
        <Icon style={{ fontSize: IconSize }} />
      </Box>
      <Typography
        variant="h6"
        style={{
          fontWeight: 'bold',
          fontSize: Number(size.replace('px', '')) < 230 ? '14px' : '1.1rem',
        }}
      >
        {Title}
      </Typography>
      {Description && (
        <>
          <Typography
            variant="body1"
            className={classes.pageDescriptionStyle}
            style={{
              fontSize: Number(size.replace('px', '')) < 230 ? '12px' : '0.888rem',
            }}
          >
            {Description}
          </Typography>
        </>
      )}
    </Button>
  )
}
