import { createContext, useContext } from 'react'
import { StockState } from '../stockPricing'

export const ExistingStockContext = createContext<{
  stockState: StockState | null
  loadStock: () => void
}>({
  stockState: { stock: [], sealedStock: [], fetchedAt: new Date().toLocaleString('sk-SK') },
  loadStock: () => undefined,
})

export const useExistingStockContext = () => {
  const state = useContext(ExistingStockContext)

  return state
}
