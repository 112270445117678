import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { CropOriginal, LocationSearching } from '@material-ui/icons'
import { ImportArticles } from 'components/domain/addArticles/AddArticleDock/ImportArticles'
import { SealedIcon } from 'components/facets'
import { ActionButton } from 'components/facets/ActionButton'
import { useUser } from 'components/providers/UserProvider'
import { useStockStore } from 'lib/hooks/useStockStore'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Filters, InventoryArticle, STOCK_TYPES } from 'shared'
import { SetState } from 'types'
import { DockAction, InventoryPresenterProps } from '..'
import { FilterPresenter } from './FilterPresenter'
export interface FilterDockProps {
  filters: Filters
  filtersKey: string
  setFiltersKey: SetState<string>
  setFilters: SetState<Filters>
  fetchedAt: string
  defaultFilters: Filters
  areFiltersTouched: boolean
  sealedActive: boolean
  openExportCsvDialog: () => void
  openExportAllProductsCsvDialog: () => void
  loading: boolean
  onAddArticles?: (articles: InventoryArticle[]) => void
  openImportFileDialog: () => void
  refreshStockAction: InventoryPresenterProps<''>['refreshStockAction']
  hideExportButton?: boolean
  extraActions?: DockAction[]
  showAddCardsButton?: boolean
  showFiltersButton?: boolean
  isEmptyInventory?: boolean
}

export const FilterDock = React.memo(function FilterDock({
  filters,
  filtersKey,
  setFiltersKey,
  setFilters,
  fetchedAt,
  defaultFilters,
  areFiltersTouched,
  loading,
  openImportFileDialog,
  openExportAllProductsCsvDialog,
  onAddArticles,
  refreshStockAction,
  extraActions,
  showAddCardsButton,
  showFiltersButton,
  isEmptyInventory,
}: FilterDockProps): JSX.Element {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const { user } = useUser()

  const [activeView, setActiveView] = useState('listing')
  const activeStockType = useStockStore((state) => state.activeStockType)
  const setActiveStockType = useStockStore((state) => state.setActiveStockType)

  const WIDTH = '300px'

  return (
    <Box
      style={{
        minHeight: '100vh',
        width: WIDTH,
        backgroundColor: 'white',
      }}
    >
      <Box
        paddingBottom={0.8}
        paddingTop={0.8}
        marginBottom={0.2}
        style={{
          boxShadow: 'lightgrey 5px 6px 10px -6px',
          backgroundColor: palette.lighterBackground,
        }}
      >
        <Box display="flex" margin="0 auto" width="fit-content">
          {showAddCardsButton && (
            <Box
              borderBottom={
                activeView === 'listing' && activeStockType === STOCK_TYPES.SINGLES
                  ? `solid 2px ${palette.primary.main}`
                  : ''
              }
            >
              <ActionButton
                data-testid="add-cards-btn"
                onClick={() => {
                  setActiveStockType(STOCK_TYPES.SINGLES)
                  setActiveView('listing')
                }}
                text={t('stockPricing.actions.addArticles')}
                Icon={CropOriginal}
              />
            </Box>
          )}
          {showAddCardsButton && (
            <Box
              borderBottom={
                activeView === 'listing' && activeStockType === STOCK_TYPES.SEALED
                  ? `solid 2px ${palette.primary.main}`
                  : ''
              }
            >
              <ActionButton
                data-testid="add-sealed-btn"
                onClick={() => {
                  setActiveStockType(STOCK_TYPES.SEALED)
                  setActiveView('listing')
                }}
                text={t('stockPricing.actions.addAccessories')}
                Icon={SealedIcon}
              />
            </Box>
          )}
          {showFiltersButton && (
            <Box borderBottom={activeView === 'filters' ? `solid 2px ${palette.primary.main}` : ''}>
              <ActionButton
                data-testid="show-filters-btn"
                onClick={() => {
                  setActiveView('filters')
                }}
                disabled={isEmptyInventory}
                text={t('filters.title')}
                Icon={LocationSearching}
              />
            </Box>
          )}
          {refreshStockAction && (
            <ActionButton
              data-testid="get-stock-btn"
              onClick={refreshStockAction.fn}
              disabled={!user?.cardmarketUsername || loading}
              text={refreshStockAction.actionLabel}
              Icon={refreshStockAction.Icon}
              tooltip={refreshStockAction.tooltip?.(fetchedAt)}
            />
          )}
          {extraActions?.map((action) => (
            <Box
              key={action.key}
              borderBottom={
                action.key.includes(activeStockType) ? `solid 2px ${palette.primary.main}` : ''
              }
            >
              <ActionButton
                data-testid={action.key}
                onClick={() => {
                  action.fn()
                }}
                text={action.actionLabel}
                Icon={action.Icon}
                tooltip={action.tooltip?.(fetchedAt)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      {!onAddArticles || activeView === 'filters' ? (
        <FilterPresenter
          filters={filters}
          filtersKey={filtersKey}
          defaultFilters={defaultFilters}
          areFiltersTouched={areFiltersTouched}
          setFiltersKey={setFiltersKey}
          setFilters={setFilters}
        />
      ) : (
        <Box>
          <Box width="100%">
            <Box>
              <ImportArticles
                onAddArticles={onAddArticles}
                openCsvImport={openImportFileDialog}
                openCsvExport={openExportAllProductsCsvDialog}
                tabSelected={'listing'}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
})
