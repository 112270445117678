import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { MultipleSelect } from 'components/controls/form/MultipleSelect'
import { SimpleSelect } from 'components/controls/form/SimpleSelect'
import { ExpansionAutocomplete } from 'components/controls/misc/ExpansionAutocomplete'
import AutocompleteInput from 'components/domain/addArticles/AddArticleDock/AutocompleteInput'
import { Button, CustomTooltip, DeleteIcon } from 'components/facets'
import { useCardThingData } from 'components/providers/CardDataProvider'
import { useUser } from 'components/providers/UserProvider'
import { useErrorHandler } from 'lib/hooks'
import { createContext, useContext, useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import SortableTree, { TreeItem } from 'react-sortable-tree'
//@ts-ignore
import FileExplorerTheme from 'react-sortable-tree-theme-minimal'
import Flag from 'react-world-flags'
import {
  AnyYesNo,
  BaseCondition,
  CardConditionCondition,
  CardDataItem,
  CardExtraAttribute,
  CardExtraCondition,
  CardLanguage,
  CardNameCondition,
  CARD_LANGUAGES,
  CommentCondition,
  conditionConfig,
  ConditionEnum,
  CONDITIONS,
  ConditionStrictness,
  ConditionTypesEnum,
  ExpansionCondition,
  LanguageCondition,
  mergeConditions,
  PriceCondition,
  PriceGuideCondition,
  PriceGuideMetrics,
  PricingPlan,
  PricingPlanNode,
  PricingPlanNodeCondition,
  QuantityCondition,
  RarityCondition,
} from 'shared'
import { SetState } from 'types'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { v4 as uuid } from 'uuid'
import { Filter } from '../FilterDock/Filter'
import { ConditionPresenter } from './ConditionPresenter'
import { DraggableItemsEnum, PricingPlanContext } from './PricingPlanPresenter'
import { MinimalThemeNodePreviewRenderer } from './SortableTree/NodePreviewRenderer'
import { MinimalThemeTreeNodeRenderer } from './SortableTree/TreeNodeRenderer'

export const ConditionMakerContext = createContext<{
  state: any
  setState: SetState<any>
  setValid: SetState<boolean>
}>({
  state: null,
  setState: () => undefined,
  setValid: () => undefined,
})

const useConditionLogic = <T extends Pick<BaseCondition, 'type' | 'value'>>(
  initialState: T
): [state: T, setState: SetState<T>, ready: boolean] => {
  const { state, setState, setValid } = useContext(ConditionMakerContext)
  const [ready, setReady] = useState(false)
  const isValid = conditionConfig[initialState.type].isValid

  const { conditionCtx, setConditionCtx } = useContext(PricingPlanContext)

  useEffect(() => {
    if (conditionCtx) {
      const copy = { ...conditionCtx }
      setState(copy)
      setConditionCtx(null)
    } else {
      setState(initialState)
    }
    setReady(true)
  }, [])
  useEffect(() => {
    setValid(isValid(state))
  }, [state.value])

  return [state, setState, ready]
}

const LanguageInput = (): JSX.Element => {
  const [state, setState, ready] = useConditionLogic<Pick<LanguageCondition, 'value' | 'type'>>({
    value: [],
    type: ConditionTypesEnum.Language,
  })
  const { t } = useTranslation()
  const normalizedValue = Array.isArray(state.value) ? state.value : []
  if (!ready) return <></>

  return (
    <Box>
      <MultipleSelect
        fullWidth
        inputProps={{ name: 'language-multiple-select' }}
        label={t('common.selectLanguages')}
        value={normalizedValue}
        options={CARD_LANGUAGES.map((language) => ({
          ...language,
          label: (
            <Flag
              key={language.value}
              style={{ padding: '0px 5px' }}
              //@ts-ignore
              fallback={<span>Unknown</span>}
              height="12"
              data-testid={`${CardLanguage[language.value]}-flag`}
              code={LANGUAGE_MAP_ICONS[language.value] || 'gg'}
            />
          ),
        }))}
        onChange={(newValue) => {
          setState({ ...state, value: newValue as LanguageCondition['value'] })
        }}
      />
    </Box>
  )
}

const CardExtraInput = ({}): JSX.Element => {
  const [state, setState, ready] = useConditionLogic<Pick<CardExtraCondition, 'value' | 'type'>>({
    value: Object.values(CardExtraAttribute)
      .map((key) => [key, AnyYesNo.Any])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as Record<
      CardExtraAttribute,
      AnyYesNo
    >,
    type: ConditionTypesEnum.CardExtra,
  })

  const { t } = useTranslation()

  const anyYesNoOptions = [
    <MenuItem key={'any'} value={AnyYesNo.Any}>
      {t('filters.any')}
    </MenuItem>,
    <MenuItem key={'false'} value={AnyYesNo.No}>
      {t('filters.no')}
    </MenuItem>,
    <MenuItem key={'true'} value={AnyYesNo.Yes}>
      {t('filters.yes')}
    </MenuItem>,
  ]

  const { activeGame } = useUser()

  if (!ready) return <></>
  return (
    <Box>
      <Grid container spacing={2}>
        {activeGame.extraAttr.map((attribute) => {
          //isSigned to signed
          const parsedAttribute = attribute[2].toLowerCase() + attribute.slice(3)
          return (
            <Filter
              type="select"
              key={attribute}
              grid={true}
              menuitems={anyYesNoOptions}
              setFilter={(_name, newValue) =>
                setState((state: any) => ({
                  ...state,
                  value: { ...state.value, [attribute]: newValue },
                }))
              }
              defaultValue={state.value[attribute]}
              label={t(`filters.${parsedAttribute}`)}
              name={parsedAttribute}
            />
          )
        })}
      </Grid>
    </Box>
  )
}

const CardnameInput = ({}): JSX.Element => {
  const { cardData } = useCardThingData()
  const [state, setState, ready] = useConditionLogic<Pick<CardNameCondition, 'value' | 'type'>>({
    value: [],
    type: ConditionTypesEnum.Printing,
  })

  if (!ready) return <></>
  return (
    <Box>
      <Box marginTop={1} key={state.value.length}>
        <AutocompleteInput
          cardData={cardData}
          card={null}
          handleCardChange={(card: CardDataItem) => {
            setState({
              ...state,
              value: [...state.value.filter(({ _id }) => _id !== card._id), card],
            })
          }}
        />
      </Box>
      <Box>
        {state.value.map((card) => (
          <Box key={card._id} display="flex" alignItems="center">
            {card.name} {card.set}{' '}
            <IconButton
              size="small"
              onClick={() =>
                setState({
                  ...state,
                  value: [...state.value.filter(({ _id }) => _id !== card._id)],
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const ExpansionInput = ({}): JSX.Element => {
  const [state, setState] = useConditionLogic<Pick<ExpansionCondition, 'value' | 'type'>>({
    value: [],
    type: ConditionTypesEnum.Expansion,
  })
  if (!Array.isArray(state.value)) return <></>

  return (
    <Box>
      <Box marginTop={1} key={state.value.length}>
        <ExpansionAutocomplete
          value={''}
          onChange={(expansion) =>
            setState({
              ...state,
              value: [...state.value.filter(({ _id }) => _id !== expansion._id), expansion],
            })
          }
        />
      </Box>
      <Box>
        {state.value.map((expansion) => (
          <Box key={expansion._id} display="flex" alignItems="center">
            {expansion.name} {expansion.code}
            <IconButton
              size="small"
              onClick={() =>
                setState({
                  ...state,
                  value: [...state.value.filter(({ _id }) => _id !== expansion._id)],
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const CardConditionInput = ({}): JSX.Element => {
  const [state, setState] = useConditionLogic<Pick<CardConditionCondition, 'value' | 'type'>>({
    value: { min: ConditionEnum.MT, max: ConditionEnum.PO },
    type: ConditionTypesEnum.CardCondition,
  })

  const { t } = useTranslation()

  return (
    <Box>
      <Box marginTop={2}>
        <Filter
          type="slider"
          sliderProps={{
            valueLabelDisplay: 'auto',
            valueLabelFormat: (key: number) => CONDITIONS[key],
            step: 1,
            marks: true,
            min: 0,
            max: 6,
          }}
          defaultValue={[state.value.min ?? ConditionEnum.MT, state.value.max ?? ConditionEnum.PO]}
          name="condition"
          setFilter={(_name, newValue) => {
            setState({ ...state, value: { min: newValue[0], max: newValue[1] } })
          }}
          label={t('card.condition')}
        />
      </Box>
    </Box>
  )
}

const QuantityInput = ({}): JSX.Element => {
  const [state, setState] = useConditionLogic<Pick<QuantityCondition, 'value' | 'type'>>({
    value: { min: 1, max: 24 },
    type: ConditionTypesEnum.Quantity,
  })

  const { t } = useTranslation()

  return (
    <Box>
      <Box marginTop={2}>
        <Filter
          type="slider"
          sliderProps={{
            valueLabelDisplay: 'auto',
            valueLabelFormat: (key: number) => key,
            step: 1,
            marks: true,
            min: 1,
            max: 24,
          }}
          defaultValue={[state.value.min ?? 1, state.value.max ?? 24]}
          name="quantity"
          setFilter={(_name, newValue) => {
            setState({
              ...state,
              value: { min: newValue[0], max: newValue[1] },
            })
          }}
          label={t('card.quantity')}
        />
      </Box>
    </Box>
  )
}

const RarityInput = ({}): JSX.Element => {
  const [state, setState] = useConditionLogic<Pick<RarityCondition, 'value' | 'type'>>({
    value: { min: 1, max: 24 },
    type: ConditionTypesEnum.Rarity,
  })

  const { t } = useTranslation()
  const { activeGame } = useUser()

  const numberOfRarities = Object.keys(activeGame.rarities).length
  return (
    <Box>
      <Box marginTop={2}>
        <Filter
          type="slider"
          sliderProps={{
            valueLabelDisplay: 'auto',
            valueLabelFormat: (key: number) => activeGame.rarities[key].filterLabel,
            step: 1,
            marks: true,
            min: 1,
            max: Object.keys(activeGame.rarities).length,
          }}
          defaultValue={[state.value.min ?? 1, state.value.max ?? numberOfRarities]}
          name="rarity"
          setFilter={(_name, newValue) => {
            setState({
              ...state,
              value: {
                min: newValue[0],
                max: newValue[1],
              },
            })
          }}
          label={t('card.rarity')}
        />
      </Box>
    </Box>
  )
}

const CommentInput = ({}): JSX.Element => {
  const [state, setState] = useConditionLogic<Pick<CommentCondition, 'value' | 'type'>>({
    value: '',
    type: ConditionTypesEnum.Comment,
  })

  const { t } = useTranslation()

  return (
    <Box>
      <TextField
        fullWidth
        label={t('card.comment')}
        value={state.value}
        onChange={(e) => setState({ ...state, value: e.target.value })}
      />
    </Box>
  )
}

const PriceGuideInput = ({}): JSX.Element => {
  const [state, setState, ready] = useConditionLogic<Pick<PriceGuideCondition, 'value' | 'type'>>({
    value: { metric: PriceGuideMetrics.AVG1 },
    type: ConditionTypesEnum.PriceGuide,
  })

  const { t } = useTranslation()
  const [minInput, setMinInput] = useState(String(state.value.min || ''))
  const [maxInput, setMaxInput] = useState(String(state.value.max || ''))
  if (!ready) return <></>

  return (
    <Box>
      {state.value?.metric && (
        <SimpleSelect<PriceGuideCondition['value']['metric']>
          value={state.value?.metric}
          label="Metric"
          onChange={(newMetric) =>
            setState((state: any) => ({ ...state, value: { ...state.value, metric: newMetric } }))
          }
          options={Object.keys(PriceGuideMetrics).map((key) => ({
            value: key as keyof typeof PriceGuideMetrics,
            label: key,
          }))}
        />
      )}
      <Grid container style={{ marginTop: '0.5rem' }} spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="min"
            fullWidth
            value={minInput}
            label={t('filters.minPrice')}
            //type="number"
            onChange={(e) => {
              setMinInput(e.target.value)
              setState({
                ...state,
                value: {
                  ...state.value,
                  min:
                    !e.target.value || Number.isNaN(Number(e.target.value))
                      ? undefined
                      : Number(e.target.value),
                },
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="max"
            fullWidth
            label={t('filters.maxPrice')}
            value={maxInput}
            //type="number"
            onChange={(e) => {
              setMaxInput(e.target.value)

              setState({
                ...state,
                value: {
                  ...state.value,
                  max:
                    !e.target.value || Number.isNaN(Number(e.target.value))
                      ? undefined
                      : Number(e.target.value),
                },
              })
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const PriceInput = ({}): JSX.Element => {
  const [state, setState, ready] = useConditionLogic<Pick<PriceCondition, 'value' | 'type'>>({
    value: { min: undefined, max: undefined },
    type: ConditionTypesEnum.Price,
  })

  const { t } = useTranslation()
  const [minInput, setMinInput] = useState(String(state.value.min || ''))
  const [maxInput, setMaxInput] = useState(String(state.value.max || ''))
  if (!ready) return <></>

  return (
    <Box>
      <Grid container style={{ marginTop: '0.5rem' }} spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="min"
            fullWidth
            value={minInput}
            label={t('filters.minPrice')}
            //type="number"
            onChange={(e) => {
              setMinInput(e.target.value)
              setState({
                ...state,
                value: {
                  ...state.value,
                  min:
                    !e.target.value || Number.isNaN(Number(e.target.value))
                      ? undefined
                      : Number(e.target.value),
                },
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="max"
            fullWidth
            label={t('filters.maxPrice')}
            value={maxInput}
            //type="number"
            onChange={(e) => {
              setMaxInput(e.target.value)

              setState({
                ...state,
                value: {
                  ...state.value,
                  max:
                    !e.target.value || Number.isNaN(Number(e.target.value))
                      ? undefined
                      : Number(e.target.value),
                },
              })
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const ConditionPreview = ({ condition, valid, disallowNesting }: any) => {
  const canDrag = disallowNesting ? false : valid
  const [isHoveringOnCondition, setHoveringOnCondition] = useState(false)
  const { setIsDraggingCondition } = useContext(PricingPlanContext)

  const [{ isDraggingCondition }, drag] = useDrag(
    () => ({
      type: DraggableItemsEnum.Condition,
      item: { ...condition, id: uuid() },
      canDrag: canDrag,
      collect: (monitor) => ({
        isDraggingCondition: !!monitor.isDragging(),
      }),
    }),
    [condition, canDrag]
  )

  useEffect(() => {
    setIsDraggingCondition(isDraggingCondition)
  }, [isDraggingCondition])

  return (
    <Box paddingLeft={3} paddingRight={3}>
      <div
        key={JSON.stringify(condition)}
        ref={drag}
        style={{ cursor: canDrag ? 'move' : 'not-allowed' }}
        onMouseEnter={() => setHoveringOnCondition(canDrag && true)}
        onMouseLeave={() => setHoveringOnCondition(canDrag && false)}
      >
        <ConditionPresenter
          maxHeight={6}
          paperProps={{ elevation: isHoveringOnCondition ? 5 : 1 }}
          condition={condition}
          displayType={true}
        />
      </div>
    </Box>
  )
}

export interface ConditionMakerPresenterProps {
  addRowMode?: boolean
  setAddRowMode?: SetState<boolean>
  mainTreeData?: TreeItem[]
  changeTree?: SetState<TreeItem[]>
  setHiddenAddButton?: SetState<boolean>
  pricingPlan?: PricingPlan
}

export const ConditionMakerPresenter = ({
  addRowMode,
  setAddRowMode,
  mainTreeData,
  changeTree,
  setHiddenAddButton,
  pricingPlan,
}: ConditionMakerPresenterProps): JSX.Element => {
  const [state, setState] = useState<{
    type: ConditionTypesEnum
    value: PricingPlanNodeCondition['value'] | null
    negated?: boolean
  }>({
    type: ConditionTypesEnum.Language,
    value: null,
    negated: false,
  })

  const {
    conditionCtx,
    setConditionCtx,
    editConditionCtx,
    setEditConditionCtx,
    changeNode,
    addRuleMode,
    setAddRuleMode,
    selectedRuleNode,
    selectedRulePath,
    setSelectedTreeIndex,
  } = useContext(PricingPlanContext)

  useEffect(() => {
    if (conditionCtx) {
      setState(conditionCtx)
    }
  }, [conditionCtx?.id])

  useEffect(() => {
    setHiddenAddButton?.(!!editConditionCtx)
  }, [editConditionCtx])

  const treeNode: TreeItem = {
    conditions: [{ ...state, id: uuid() }],
    id: uuid(),
    children: [],
    conditionStrictness: ConditionStrictness.atleastOne,
    method: undefined,
  }

  const hideOldLayoutElements = () => {
    if (pricingPlan?.disallowNesting) {
      if (addRowMode || addRuleMode || editConditionCtx) {
        return false
      }
    } else {
      return false
    }
    return true
  }

  const [valid, setValid] = useState(true)
  const { t } = useTranslation()
  const { handle: handleError } = useErrorHandler()

  return (
    <ConditionMakerContext.Provider value={{ state, setState, setValid }}>
      <Box hidden={hideOldLayoutElements()}>
        <Box padding={2} minHeight={'12rem'}>
          <Typography variant="caption" color="primary">
            {t('priceUpdates.ruleType')}
          </Typography>
          <Select
            disabled={!!editConditionCtx}
            inputProps={{ name: 'condition-type-select' }}
            label="Condition Type"
            placeholder="Condition Type"
            style={{ marginBottom: '1rem' }}
            fullWidth
            value={state.type}
            onChange={(e) => {
              setState({ ...state, type: e.target.value as ConditionTypesEnum })
              setConditionCtx(null)
            }}
          >
            <Box paddingLeft={2} paddingRight={2}>
              <Typography variant="body1" color="primary" gutterBottom>
                <b>{t('priceUpdates.createRuleBasedOn')}</b>
              </Typography>
              <Divider />
            </Box>
            {Object.keys(ConditionTypesEnum).map((key) => (
              <MenuItem
                key={key}
                value={ConditionTypesEnum[key as keyof typeof ConditionTypesEnum]}
              >
                <Box>
                  <Typography>{t(`priceUpdates.ruleTypes.${key}`)}</Typography>
                  <FormHelperText> {t(`priceUpdates.ruleTypes.${key}Explainer`)}</FormHelperText>
                </Box>
              </MenuItem>
            ))}
          </Select>
          <Grid container>
            <Grid item xs={conditionConfig[state.type].negatable ? 10 : 12}>
              {state.type &&
                {
                  [ConditionTypesEnum.CardExtra]: <CardExtraInput />,
                  [ConditionTypesEnum.Printing]: <CardnameInput />,
                  [ConditionTypesEnum.Expansion]: <ExpansionInput />,
                  [ConditionTypesEnum.Language]: <LanguageInput />,
                  [ConditionTypesEnum.PriceGuide]: <PriceGuideInput />,
                  [ConditionTypesEnum.CardCondition]: <CardConditionInput />,
                  [ConditionTypesEnum.Comment]: <CommentInput />,
                  [ConditionTypesEnum.Quantity]: <QuantityInput />,
                  [ConditionTypesEnum.Rarity]: <RarityInput />,
                  [ConditionTypesEnum.Price]: <PriceInput />,
                }[state.type]}
            </Grid>
            {conditionConfig[state.type].negatable && (
              <Grid item xs={2}>
                <Box marginTop={1.5}>
                  <CustomTooltip title={t('common.negate') as string}>
                    <Switch
                      color="primary"
                      checked={state.negated}
                      onChange={() =>
                        setState((state: any) => ({ ...state, negated: !state.negated }))
                      }
                    />
                  </CustomTooltip>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        {editConditionCtx ? (
          <Box>
            <Button
              color="primary"
              disabled={!valid}
              fullWidth
              onClick={() => {
                const { path, node, conditionId } = editConditionCtx
                changeNode(path, {
                  ...node,
                  conditions: node.conditions.map((condition: any) => {
                    if (condition.id !== conditionId) {
                      return condition
                    } else {
                      return { ...state, id: conditionId }
                    }
                  }),
                })
                setEditConditionCtx(null)
              }}
            >
              Confirm changes
            </Button>
            <Button fullWidth onClick={() => setEditConditionCtx(null)}>
              Stop editing
            </Button>
          </Box>
        ) : (
          <Box>
            <Box>
              <Box minHeight="8rem" padding={1} paddingTop={0}>
                <Box hidden={pricingPlan?.disallowNesting && !addRowMode}>
                  <Typography style={{ textTransform: 'uppercase' }} variant="h6">
                    {t('priceUpdates.addNewRow')}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {t('priceUpdates.addNewRowExplainer')}
                  </Typography>

                  <SortableTree
                    isVirtualized={false}
                    onChange={() => undefined}
                    dndType={DraggableItemsEnum.Node}
                    canDrop={() => false}
                    slideRegionSize={0}
                    //@ts-ignore
                    dragFromOnly={true}
                    canDrag={pricingPlan?.disallowNesting ? false : valid}
                    shouldCopyOnOutsideDrop={true}
                    theme={{
                      ...FileExplorerTheme,
                      nodeContentRenderer: MinimalThemeNodePreviewRenderer,
                      treeNodeRenderer: MinimalThemeTreeNodeRenderer,
                    }}
                    treeData={[treeNode]}
                  />

                  <Box paddingBottom={2} hidden={!pricingPlan?.disallowNesting}>
                    <Button
                      onClick={() => {
                        const newTreeData = mainTreeData
                          ? mainTreeData.concat([treeNode])
                          : [treeNode]
                        changeTree?.(newTreeData)
                      }}
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!valid}
                      data-testid="add-this-row-btn"
                    >
                      {t('priceUpdates.addThisRow')}
                    </Button>
                  </Box>
                </Box>
                <Box hidden={pricingPlan?.disallowNesting && !addRuleMode}>
                  <Typography style={{ textTransform: 'uppercase' }} variant="h6">
                    {t('priceUpdates.addNewCondition')}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {t('priceUpdates.addNewConditionExplainer')}
                  </Typography>

                  <ConditionPreview
                    condition={state}
                    valid={valid}
                    disallowNesting={pricingPlan?.disallowNesting}
                  />

                  <Box paddingTop={3} paddingBottom={2} hidden={!pricingPlan?.disallowNesting}>
                    <Button
                      onClick={() => {
                        try {
                          const newConditions = mergeConditions(
                            { ...state, id: uuid() } as PricingPlanNodeCondition,
                            selectedRuleNode?.conditions
                          )

                          if (selectedRulePath) {
                            changeNode(selectedRulePath, {
                              ...(selectedRuleNode as PricingPlanNode),
                              conditions: newConditions,
                            })
                          }
                        } catch (err) {
                          handleError(err)
                        }
                      }}
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!valid}
                      data-testid="add-this-rule-btn"
                    >
                      {t('priceUpdates.addThisRule')}
                    </Button>
                  </Box>
                </Box>
                <Box hidden={!pricingPlan?.disallowNesting}>
                  <Button
                    onClick={() => {
                      if (addRowMode) {
                        setAddRowMode?.(!addRowMode)
                      } else if (addRuleMode) {
                        setAddRuleMode(!addRuleMode)
                      }
                      setSelectedTreeIndex(null)
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    data-testid="close-add-this-row-rule-btn"
                  >
                    {t('common.close')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </ConditionMakerContext.Provider>
  )
}
