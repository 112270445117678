import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  Button,
  CloseIcon,
  CommentPTIcon,
  CustomTooltip,
  HelpIcon,
  LanguageIcon,
} from 'components/facets'
//@ts-ignore
import NewWindow from 'components/facets/NewWindow.tsx'
import NumberField from 'components/facets/NumberField'
import { PriceSuggestArticle } from 'components/facets/PriceSuggestArticle'
import { usePriceGuide } from 'components/providers/PriceGuideProvider'
import { useUser } from 'components/providers/UserProvider'
import { fetchProduct, getCheapestMatches } from 'lib/api'
import { useLocalStorageState, useScopelessHotkeys } from 'lib/hooks'
import { ApiCallStatus, useApiCall } from 'lib/hooks/useApiCall'
import { useStockStore } from 'lib/hooks/useStockStore'
import { currencySymbol, getPlaysetIcon } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardmarketArticle, InventoryArticle, PriceGuideMetrics } from 'shared'
import { BooleanIconButton } from '../PricingSettings/BooleanIconButton'
import { CompetitorBox } from '../PricingSettings/CompetitorBox'
import { EditableElement } from '../PricingSettings/EditableElement'
import { VisualArticle } from '../StockDisplay/VisualArticle'

export interface PriceSuggestPresenterProps {
  article: InventoryArticle
  onClose: () => void
  priceArticle: (price: number) => void
}

export const PriceSuggestPresenter = ({
  article,
  onClose,
  priceArticle,
}: PriceSuggestPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { user, activeGame } = useUser()

  const [cardmarketPopupShown, setShowCardmarketPopup] = useState(false)
  const { palette } = useTheme()
  const [priceSuggestSettings, setPriceSuggestSettings] = useLocalStorageState(
    { competitorId: user?.competitors[0]._id, strictLanguage: false, disablePlaysetPricing: false },
    'priceSuggestSettings'
  )
  const [productWebsite, setProductWebsite] = useState('')

  const [newPrice, setNewPrice] = useState('')

  const { competitorId, strictLanguage, disablePlaysetPricing } = priceSuggestSettings

  const competitor =
    user?.competitors.find(({ _id }) => _id === competitorId) || user?.competitors[0]

  const priceDataCall = useApiCall<typeof getCheapestMatches>(getCheapestMatches, [
    article,
    competitor._id,
    { strictLanguage, disablePlaysetPricing },
    activeGame.idGame,
  ])

  const articlePriceData = priceDataCall.data

  const { getPriceGuide } = usePriceGuide()

  const priceGuide = getPriceGuide(article.card._id)

  const fetchProductCall = useApiCall<typeof fetchProduct>(fetchProduct, [article.card._id])

  useEffect(() => {
    fetchProductCall.performCall(article.card._id)
  }, [article])

  useEffect(() => {
    if (fetchProductCall.data?.website) {
      setProductWebsite(fetchProductCall.data?.website)
    }
  }, [fetchProductCall])

  const showCardmarketPopup = (): void => {
    setShowCardmarketPopup(false)
    setTimeout(() => setShowCardmarketPopup(true), 200)
  }

  const activeStockType = useStockStore((state) => state.activeStockType)

  useEffect(() => {
    priceDataCall.performCall(
      article,
      competitor._id,
      { strictLanguage, disablePlaysetPricing },
      activeGame.idGame
    )
  }, [competitor?._id, strictLanguage, disablePlaysetPricing])

  useScopelessHotkeys('v', showCardmarketPopup)
  useScopelessHotkeys('enter', () => priceArticle(Number(newPrice)), [newPrice])

  return (
    <Box width="500px" position="relative" marginBottom={6}>
      <Box display="flex" justifyContent="end">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <Box width="360px" margin="0 auto">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box width="12rem">
                <Box height="250px">
                  <VisualArticle
                    rowHeight={250}
                    article={article}
                    currency={user.currency}
                    displayOnly
                    parentEl="PriceSuggestPresenter"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box padding={2}>
                <Typography variant="body1">
                  {article.card.name} {article.card.set}
                </Typography>
                {article.comment && (
                  <Box display="flex">
                    <CommentPTIcon />{' '}
                    <Typography variant="body1" style={{ paddingLeft: '4px' }}>
                      {article.comment}
                    </Typography>
                  </Box>
                )}
                {activeStockType === 'singles' && (
                  <Box marginTop={4}>
                    {Object.keys(PriceGuideMetrics).map((metric) => (
                      <Box key={metric} display="flex" justifyContent="space-between">
                        <Typography variant="body1">
                          <Typography component="span" color="textSecondary">
                            {(article.isShiny ? 'Foil' : '') + metric}{' '}
                          </Typography>{' '}
                        </Typography>
                        <Typography variant="body1">
                          <b>
                            {articlePriceData
                              ? priceGuide[
                                  ((article.isShiny ? 'Foil' : '') +
                                    metric) as keyof typeof PriceGuideMetrics
                                ]
                              : '...'}
                            {currencySymbol(user?.currency)}
                          </b>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box width="450px" marginTop={1}>
            <Box display="flex" alignItems="center" justifyContent="center" marginTop={2}>
              <Typography variant="h6">
                <EditableElement
                  title
                  value={competitor!.name}
                  tooltipText="change"
                  attribute="name"
                  type="select"
                  onChange={(newValue) =>
                    setPriceSuggestSettings({
                      ...priceSuggestSettings,
                      competitorId: newValue as string,
                    })
                  }
                  existingValue={competitor!.name}
                  name={t('competitor.title')}
                  dataTestid={`competitor-${competitor!.name}`}
                  options={
                    user?.competitors?.map((competitor) => ({
                      label: competitor.name,
                      value: competitor._id,
                    })) || []
                  }
                />
              </Typography>
              <CustomTooltip title={<CompetitorBox competitor={competitor!} />}>
                <HelpIcon color="primary" />
              </CustomTooltip>

              {!!activeGame.playsetCount && (
                <EditableElement
                  existingValue={disablePlaysetPricing}
                  attribute="disablePlaysetPricing"
                  onChange={(newValue) =>
                    setPriceSuggestSettings({
                      ...priceSuggestSettings,
                      disablePlaysetPricing: newValue as boolean,
                    })
                  }
                  sameTooltipForViewOnly
                  type="bool"
                  value={
                    <BooleanIconButton
                      active={!disablePlaysetPricing}
                      Icon={getPlaysetIcon(activeGame.playsetCount)}
                    />
                  }
                  tooltipText={t(
                    `strategy.tooltip.playsetPricing.${
                      disablePlaysetPricing ? 'inactive' : 'active'
                    }`,
                    { playsetCount: activeGame.playsetCount }
                  )}
                  name={t('strategy.playsetPricing')}
                />
              )}

              <EditableElement
                existingValue={priceSuggestSettings.strictLanguage}
                attribute="strictLanguage"
                type="bool"
                sameTooltipForViewOnly
                onChange={(newValue) =>
                  setPriceSuggestSettings({
                    ...priceSuggestSettings,
                    strictLanguage: newValue as boolean,
                  })
                }
                value={
                  <BooleanIconButton
                    active={Boolean(priceSuggestSettings.strictLanguage)}
                    Icon={LanguageIcon}
                  />
                }
                tooltipText={t(
                  `strategy.tooltip.strictLanguage.${
                    priceSuggestSettings.strictLanguage ? 'active' : 'inactive'
                  }`
                )}
                name={t('strategy.strictLanguage')}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box>
                {ApiCallStatus.Success === priceDataCall.status && (
                  <Box>
                    {articlePriceData?.cheapestMatches.map((article, index: number) => (
                      <Box padding={0.5} key={index}>
                        <Paper>
                          <Box padding={0.25} paddingLeft={1.5} paddingRight={1.5}>
                            <PriceSuggestArticle article={new CardmarketArticle(article)} />
                          </Box>
                        </Paper>
                      </Box>
                    ))}
                    {!articlePriceData!.cheapestMatches.length && (
                      <Typography style={{ textAlign: 'center' }}>
                        {t('stockPricing.priceSuggest.noMatchesFound')}
                      </Typography>
                    )}
                  </Box>
                )}
                {ApiCallStatus.Fetching === priceDataCall.status && (
                  <Box width="fit-content" margin="0 auto">
                    <CircularProgress color="primary" size={100} />
                  </Box>
                )}
                <Button
                  onClick={showCardmarketPopup}
                  color="primary"
                  fullWidth
                  hotkey="V"
                  style={{ marginBottom: '12px' }}
                >
                  {t('suggest.viewOnCardmarket')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        position="fixed"
        bottom="0"
        width="500px"
        style={{
          backgroundColor: palette.lightBackground,
        }}
      >
        <Box padding={2} display="flex">
          <Box width="400px">
            <NumberField
              data-testid="price-suggest-input-price"
              autoFocus
              name="price"
              fullWidth
              label={t('card.price')}
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value || '')}
            />
          </Box>
          <Box padding={1}>
            <Button
              data-testid="price-suggest-save"
              disabled={!Number(newPrice)}
              variant="contained"
              onClick={() => priceArticle(Number(newPrice))}
            >
              {t('common.save')}
            </Button>
          </Box>
        </Box>
        {cardmarketPopupShown ? (
          <NewWindow
            url={(function () {
              const url =
                `https://cardmarket.com/en/${
                  activeGame.cardmarketName || activeGame.name
                }/Products/Singles/${productWebsite}` || ''
              console.log('rp', url)
              return url
            })()}
          />
        ) : null}
      </Box>
    </Box>
  )
}
