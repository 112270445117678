import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  RadioGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { RadioButton } from 'components/controls/form/RadioButton'
import { BulkIcon, CustomTooltip, HelpText, LocalArticle } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { currencySymbol } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import {
  BulkSettings,
  CardDataItem,
  IdGame,
  PriceModifier,
  PricingStrategy,
  RichArticle,
} from 'shared'
import { BulkpriceTooltipTable } from '../../BulkpriceTooltipTable'
// import { BulkpriceTooltipTable } from '../../BulkpriceTooltipTable'
import { EditableElement } from '../../EditableElement'
import {
  exampleBSSArticles,
  exampleDBSArticles,
  exampleDigimonArticles,
  exampleFABArticles,
  exampleFFArticles,
  exampleLorcanaArticles,
  exampleMagicArticles,
  exampleOnePieceArticles,
  examplePokemonArticles,
  exampleSWUArticles,
  exampleVanguardArticles,
  exampleWSArticles,
  exampleYugiohArticles,
} from './exampleArticles'

export interface ModifierPresenterProps {
  strategy: PricingStrategy
  bulkSettings: BulkSettings[]
  onChange: (strategy: PricingStrategy) => void
}

interface ModifiedPriceProps {
  price: number | null | undefined
  card: CardDataItem
  isPlayset?: boolean
  isFoil?: boolean
  dataTestid?: string
}

export const ModifierPresenter = ({
  strategy,
  onChange,
  bulkSettings,
}: ModifierPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { activeGame, user } = useUser()

  const bulkSetting = bulkSettings?.find(
    ({ _id }) => _id === strategy.bulkSettingsId[activeGame.idGame]
  )

  const ModifiedPrice = ({
    price,
    card,
    isPlayset,
    isFoil,
    dataTestid,
  }: ModifiedPriceProps): JSX.Element => {
    const { finalPrice, usedBulkPrice } = PriceModifier.modifyPrice(
      price!,
      strategy,
      isPlayset ? 4 : 1,
      card.rarity,
      !!isFoil,
      bulkSetting?.priceMap
    )

    return (
      <Box display="flex">
        <Typography variant="h6" color="primary">
          {finalPrice}
          {currencySymbol(user?.currency)}{' '}
        </Typography>
        {usedBulkPrice && (
          <CustomTooltip title={t('autopriceArticles.bulkPriced') as string}>
            <BulkIcon
              style={{ marginLeft: '5px' }}
              color="primary"
              fontSize="large"
              data-testid={dataTestid}
            />
          </CustomTooltip>
        )}
      </Box>
    )
  }

  const getExampleCard = (): Array<RichArticle> => {
    switch (activeGame.idGame) {
      case IdGame.Magic:
        return exampleMagicArticles
      case IdGame.Yugioh:
        return exampleYugiohArticles
      case IdGame.Pokemon:
        return examplePokemonArticles
      case IdGame.FF:
        return exampleFFArticles
      case IdGame.Vanguard:
        return exampleVanguardArticles
      case IdGame.DBS:
        return exampleDBSArticles
      case IdGame.WS:
        return exampleWSArticles
      case IdGame.FAB:
        return exampleFABArticles
      case IdGame.Digimon:
        return exampleDigimonArticles
      case IdGame.OnePiece:
        return exampleOnePieceArticles
      case IdGame.Lorcana:
        return exampleLorcanaArticles
      case IdGame.BSS:
        return exampleBSSArticles
      case IdGame.SWU:
        return exampleSWUArticles
    }
  }

  const exampleCardsList = getExampleCard()

  const getBulkSettingOptions = () => {
    const baseBulkSettingOptions = [
      {
        label: 'Do not apply Bulk Settings',
        value: '',
      },
    ]
    const bulkSettingOptions =
      bulkSettings?.map((bulkSetting) => ({
        label: bulkSetting.name,
        value: bulkSetting._id,
      })) || []
    return baseBulkSettingOptions.concat(bulkSettingOptions)
  }

  return (
    <>
      <Typography gutterBottom variant="body1" data-testid={'description-step1'}>
        {t('strategy.create.modifierDescription')}
      </Typography>
      <Grid container>
        <Grid item xs={5}>
          <Box
            marginTop={4}
            marginLeft={3}
            display={'flex'}
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <Box minWidth="300px" fontSize="1.7rem">
                <EditableElement
                  attribute="modifier"
                  showBorder
                  existingValue={String(strategy.modifier.value)}
                  onChange={(newModifierValue) => {
                    if (typeof newModifierValue !== 'number') return
                    let modifierValue = newModifierValue

                    const strategyCopy = { ...strategy }
                    if (strategy.modifier.kind === 'percent') {
                      modifierValue = Math.max(modifierValue, 0)
                      modifierValue = Math.min(modifierValue, 500)
                      strategyCopy.modifier.value = modifierValue
                    } else if (strategy.modifier.kind === 'number') {
                      modifierValue = Math.min(modifierValue, 1000)
                      strategyCopy.modifier.value =
                        strategyCopy.modifier.value < 0 ? -modifierValue : modifierValue
                    }
                    onChange && onChange(strategyCopy)
                  }}
                  name={t('strategy.modifierValue')}
                  type="number"
                  tooltipText={t('strategy.tooltip.modifierValue')}
                  value={`${Math.abs(strategy.modifier.value)}`}
                  dataTestid={'strategy-modifier-number'}
                />{' '}
                <EditableElement
                  attribute="modifier"
                  showBorder
                  key={strategy.modifier.kind}
                  onChange={() => {
                    const strategyCopy = { ...strategy }
                    if (strategyCopy.modifier.kind === 'number') {
                      strategyCopy.modifier.kind = 'percent'
                      strategyCopy.modifier.value = Math.abs(strategyCopy.modifier.value)
                    } else if (strategyCopy.modifier.kind === 'percent') {
                      strategyCopy.modifier.kind = 'number'
                    }

                    onChange && onChange(strategyCopy)
                  }}
                  name={t('strategy.modifierType')}
                  type="bool"
                  tooltipText={t('strategy.tooltip.modifierType')}
                  value={
                    {
                      number: ` ${strategy.modifier.value === 1 ? 'cent' : 'cents'}`,
                      percent: '%',
                    }[strategy.modifier.kind]
                  }
                  dataTestid={`strategy-kind`}
                />{' '}
                {strategy.modifier.kind === 'number' && (
                  <EditableElement
                    key={strategy.modifier.value > 0 ? 'less' : 'more'}
                    attribute="modifier"
                    showBorder
                    onChange={() => {
                      const strategyCopy = { ...strategy }
                      strategyCopy.modifier.value = -strategyCopy.modifier.value
                      onChange && onChange(strategyCopy)
                    }}
                    name={t('strategy.lessOrMore')}
                    type="bool"
                    tooltipText=" "
                    value={strategy.modifier.value > 0 ? t('strategy.less') : t('strategy.more')}
                    dataTestid={'strategy-LM'}
                  />
                )}{' '}
              </Box>
              <Box marginTop={2}>
                <Typography variant="body1">{t('bulk.title')}</Typography>
                <Typography variant="body2">{t('bulk.infoNewStrategy')}</Typography>
                <Box marginTop={1}>
                  <RadioGroup>
                    {getBulkSettingOptions().map((option) => {
                      return (
                        <ListItem key={option.value} style={{ paddingTop: 0 }}>
                          <RadioButton
                            data-testid={`radio-button-${option.label}`}
                            checked={
                              bulkSetting ? option.value === bulkSetting?._id : option.value === ''
                            }
                            onChange={() => {
                              const pricingStrategyCopy = { ...strategy }
                              pricingStrategyCopy.bulkSettingsId[activeGame.idGame] = option.value
                              onChange && onChange(pricingStrategyCopy)
                            }}
                          />
                          <HelpText
                            tooltip={
                              <Box width="400px" margin="0 auto" textAlign="center">
                                {option.value ? (
                                  <BulkpriceTooltipTable
                                    key={option.value}
                                    bulkSettings={bulkSettings.find(
                                      (setting) => setting._id === option.value
                                    )}
                                  ></BulkpriceTooltipTable>
                                ) : (
                                  t('bulk.tooltip.doNotApply')
                                )}
                              </Box>
                            }
                          >
                            <ListItemText primary={option.label} />
                          </HelpText>
                        </ListItem>
                      )
                    })}
                  </RadioGroup>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Grid container>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">{t('common.examples')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{t('strategy.basePrice')}</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="body1">{t('strategy.modifiedPrice')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {exampleCardsList &&
                exampleCardsList.map((article, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <LocalArticle showCard article={article} key={index} />
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        {article.price}
                        {currencySymbol(user?.currency)}{' '}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">{'=>'}</Typography>
                    </TableCell>
                    <TableCell>
                      <ModifiedPrice
                        price={article.price}
                        isFoil={article.isFoil}
                        card={article.card}
                        key={index}
                        dataTestid={`bulk-icon-${index}`}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
