const cardmarketError = (errorMessage: string): string => {
  const errorDict: Record<string, string> = {
    'FEHLER: Du hast die maximal erlaubte Anzahl an Artikel für dieses Produkt überschritten. Der Artikel wurde NICHT zum verkauf gestellt.':
      'You already have the maximum amount of articles listed for this product.',
    'Das Produkt exisitiert in der Ausführung nicht. Versuche es nochmal.':
      'This product does not exist with the selected attributes.',
    'Fehler: Dieser Artikel ist nicht verfügbar oder kann nicht editiert werden.':
      'This Article is currently uneditable (might be in a shopping cart).',
    'Der Artikel wurde NICHT zum Verkauf gestellt. Als privater Verkäufer darfst Du keine Vorverkaufs-Artikel anbieten.':
      'You cannot list presale articles as a private seller.',
    'Ein Fehler ist geschehen. Die angeforderte(n) Artikel ist/sind nicht zum Kauf verfügbar':
      "This Article can't be edited (might be in a cart, or already has already been sold).",
    'Du bist nicht autorisiert um diese Aktion auszuführen':
      'You are not authorized to perform this action.',
    'Es ist ein Fehler entstanden. Du bist nicht zum verkauf berechtigt':
      'You are not authorized to sell cards. You may need go to cardmarket and activate your account for selling.',
  }

  return errorDict[errorMessage] || errorMessage || 'Unknown Error'
}

export default cardmarketError
