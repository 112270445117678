import { useUser } from 'components/providers/UserProvider'
import { useLazyForageState } from 'lib/hooks/useLazyForageState'
import { createInventoryArticle } from 'shared'
import { ElementWithChildren } from 'types'
import { ExistingStockContext } from '../addArticles/useExistingStockContext'
import { StockState } from '../stockPricing'

export const ExistingStockProvider = ({ children }: ElementWithChildren): JSX.Element => {
  const { user } = useUser()

  const [stockState, , , loadStock] = useLazyForageState<StockState>(
    { stock: [], sealedStock: [], fetchedAt: new Date().toLocaleString('sk-SK') },
    `${user?.cardmarketUsername}-stock`,
    {
      duckFunction: (value) => value.stock.find((article) => article.card._id),
      hydrate: (value) => ({
        ...value,
        stock: value.stock.map((article) => createInventoryArticle(article)),
      }),
    }
  )

  return (
    <ExistingStockContext.Provider
      value={{
        stockState,
        loadStock,
      }}
    >
      {children}
    </ExistingStockContext.Provider>
  )
}
