import { Box, makeStyles, Typography } from '@material-ui/core'
import { FiberManualRecord, Style } from '@material-ui/icons'
import { useOrderStore } from 'lib/hooks'
import { useTranslation } from 'react-i18next'
import { OrderArticle } from 'shared'

export interface PickingButtonProps {
  type: string
  article: OrderArticle
  fullWidth?: boolean
  padding?: string
  handlePick?: (article: OrderArticle, count: number) => void
  handleCompletePicking?: (article: OrderArticle, count: number) => void
  handleReportIssue?: (article: OrderArticle) => void
  handleBackToPicking?: (article: OrderArticle) => void
  handleIssues?: (article: OrderArticle) => void
}

export const PickingButton = ({
  type,
  article,
  fullWidth,
  padding,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
  handleBackToPicking,
  handleIssues,
}: PickingButtonProps): JSX.Element => {
  const { t } = useTranslation()

  const lastPicked = useOrderStore((state) => state.lastPicked)

  const isDisabled = () => {
    if (type === 'pick') {
      return lastPicked?.idArticle === article.idArticle
    } else if (type === 'put') {
      return lastPicked?.idArticle !== article.idArticle
    }
  }
  const disabled = isDisabled()

  const getBgColor = () => {
    if (['pick', 'put', 'backToPicking'].includes(type)) {
      if (disabled) {
        return '#ab9bd1'
      } else {
        return '#350f9f'
      }
    } else if (['reportIssue', 'issues'].includes(type)) {
      if (disabled) {
        return '#705ba5'
      } else {
        return '#f15e3e'
      }
    }
  }

  const getPickIcons = (count: number) => {
    if (count > 0 && count < 5) {
      return [...Array(count).keys()].map((i) => (
        <FiberManualRecord key={i} fontSize="small" style={{ verticalAlign: 'text-bottom' }} />
      ))
    } else {
      return <Style fontSize="small" style={{ verticalAlign: 'text-bottom', marginLeft: '7px' }} />
    }
  }

  const useStyles = makeStyles(() => ({
    button: {
      backgroundColor: getBgColor(),
      color: 'white',
      borderRadius: '15px',
      padding: padding ? padding : '',
      width: fullWidth ? '100%' : '115px',
      cursor: disabled ? '' : 'pointer',
    },
  }))
  const styles = useStyles()

  const getClickHandler = (article: OrderArticle) => {
    if (!disabled) {
      if (type === 'pick') {
        return handlePick?.(article, article.count)
      } else if (type === 'put') {
        return handleCompletePicking?.(article, article.count)
      } else if (type === 'reportIssue') {
        return handleReportIssue?.(article)
      } else if (type === 'backToPicking') {
        return handleBackToPicking?.(article)
      } else if (type === 'issues') {
        return handleIssues?.(article)
      }
    }
  }

  const getButtonBody = () => {
    if (type === 'pick') {
      return (
        <>
          <Typography>
            {t('orders.picking.pick')}{' '}
            <>
              {article.count === 1 ? 'one' : article.count}
              {getPickIcons(article.count)}
            </>
          </Typography>
        </>
      )
    } else if (type === 'put') {
      return (
        <Typography>
          {t('orders.picking.put')} {article.pickingId}
        </Typography>
      )
    } else if (type === 'reportIssue') {
      return <Typography>{t('orders.reportIssue')}</Typography>
    } else if (type === 'backToPicking') {
      return <Typography>{t('orders.backToPicking')}</Typography>
    } else if (type === 'issues') {
      return <Typography>{t('orders.issues')}</Typography>
    }
  }

  return (
    <>
      <Box className={styles.button} onClick={() => getClickHandler(article)}>
        <Box textAlign="center">{getButtonBody()}</Box>
      </Box>
    </>
  )
}
