import { Box, Typography } from '@material-ui/core'
import { ClosableDialog } from 'components/controls/dialogs'
import { Button } from 'components/facets'
import { useTranslation } from 'react-i18next'

interface ConfirmCancellationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmCancellationDialog = ({
  open,
  onClose,
  onConfirm,
}: ConfirmCancellationDialogProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ClosableDialog open={open} onClose={onClose} maxWidth="sm">
      <Box marginTop={2}>
        <Typography variant="h6">{t('account.unsub.title')}</Typography>
      </Box>
      <Box marginTop={3}>
        <Typography>{t('account.unsub.body1')}</Typography>
      </Box>
      <Box marginTop={1}>
        <Typography>{t('account.unsub.body2')}</Typography>
      </Box>
      <Box marginTop={1} style={{ float: 'right' }}>
        <Button variant="outlined" onClick={onClose} style={{ marginRight: '15px' }}>
          {t('common.close')}
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          {t('common.confirm')}
        </Button>
      </Box>
    </ClosableDialog>
  )
}
