import { Box, Grid } from '@material-ui/core'
import NumberField from 'components/facets/NumberField'
import { useUser } from 'components/providers/UserProvider'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkSettings, Competitor, PricingStrategy } from 'shared'
import { EditableElement } from '../EditableElement'
import { EntityBox } from '../EntityBox/EntityBox'

export interface BulkpriceFieldsProps {
  values: BulkSettings['priceMap']
}

export const BulkpriceFields = ({ values }: BulkpriceFieldsProps): JSX.Element => {
  const { activeGame } = useUser()

  return (
    <Grid spacing={1} container>
      {Object.keys(values).map((key) => (
        <Fragment key={key}>
          <Grid item>
            <Box width="60px">
              <NumberField
                value={values[Number(key)].price}
                name={activeGame.rarities[Number(key)].name}
                label={activeGame.rarities[Number(key)].name}
                data-testid={`${activeGame.rarities[Number(key)].name}-bulk-settings`}
              />
            </Box>
          </Grid>
          {activeGame.rarities[Number(key)].separateFoilBulkPrice && (
            <Grid item>
              <Box width="60px">
                <NumberField
                  value={values[Number(key)].foilPrice}
                  name={`foil${activeGame.rarities[Number(key)].name}`}
                  label={`Foil ${activeGame.rarities[Number(key)].name}`}
                />
              </Box>
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  )
}

export interface BulkpriceTooltipTablesProps {
  bulkSettings?: BulkSettings
  onChange?: (newValue: BulkSettings) => void
}

export const BulkpriceTooltipTable = ({
  bulkSettings,
  onChange,
}: BulkpriceTooltipTablesProps): JSX.Element => {
  const user = useUser()

  if (!user) return <></>

  if (!bulkSettings) return <></>

  const [values, setValues] = useState(bulkSettings.priceMap)
  const { t } = useTranslation()

  useEffect(() => {
    const newValues =
      user.user.bulkSettings.find((bulkPrices) => bulkPrices._id === bulkSettings._id)?.priceMap ||
      []
    setValues(newValues)
  }, [user])

  const handleChange = (
    newValue: unknown,
    attributeName?: keyof Competitor | keyof PricingStrategy | keyof BulkSettings
  ): void => {
    if (!attributeName) return
    const lastCharterDifferentOfSpace = /\S/.test(String(newValue))
    if (attributeName == 'name' && !lastCharterDifferentOfSpace) return
    const newBulkSettings = { ...bulkSettings, [attributeName]: newValue }
    onChange && onChange(newBulkSettings)
  }

  return (
    <Box key={bulkSettings._id} width="100%" margin="0 auto" marginTop={2} padding={1}>
      <EntityBox
        bulkSettings
        Icon={null}
        key={bulkSettings._id}
        dataTestid={`delete-bulk-settings-${bulkSettings.name}`}
        titleRow={
          <>
            <EditableElement
              title
              value={bulkSettings.name}
              attribute="name"
              type="textfield"
              onChange={handleChange}
              existingValue={bulkSettings.name}
              tooltipText={t('bulk.tooltip.name')}
              name={t('bulk.name')}
              dataTestid={`bulkSettings-${bulkSettings.name}`}
            />
          </>
        }
        contentRow={
          <>
            <BulkpriceFields values={values!} />
          </>
        }
      />
    </Box>
  )
}
