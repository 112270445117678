import { Box, Typography } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ClosableDialog } from 'components/controls/dialogs'
import { Button, CircularLoading } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { publishChanges } from 'lib/api'
import { useErrorHandler } from 'lib/hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { InventoryArticle, PublishChangesResponse } from 'shared'
import { PublishChangesSummary } from './PublishChangesSummary'

interface PublishChangesDialogProps {
  articles: InventoryArticle[]
  handleChangesPublished: (failed: PublishChangesResponse) => void
  open: boolean
  setOpen: (open: boolean) => void
}

export const PublishChangesDialog = ({
  open,
  articles,
  setOpen,
  handleChangesPublished,
}: PublishChangesDialogProps): JSX.Element => {
  const [publishResults, setPublishResults] = useState<PublishChangesResponse>([])
  const [loading, setLoading] = useState(false)
  const [publishSuccessCount, setPublishSuccessCount] = useState(0)
  const [publishFailureCount, setPublishFailureCount] = useState(0)

  const { activeGame, user } = useUser()
  const { t } = useTranslation()
  const [progress, setProgress] = useState(0)
  const { handle } = useErrorHandler()

  const handleClose = (): void => {
    setPublishResults([])
    setOpen(false)
  }

  useEffect(() => {
    setPublishSuccessCount(
      publishResults.filter(
        (result) => result.attributeChangeSuccess && result.quantityChangeSuccess
      ).length
    )
  }, [publishResults])

  useEffect(() => {
    setPublishFailureCount(publishResults.length - publishSuccessCount)
  }, [publishSuccessCount])

  const getArticlesChanged = (articles: InventoryArticle[]): InventoryArticle[] => {
    const articlesChanged: InventoryArticle[] = []
    for (let i = 0; i < articles.length; i++) {
      if (
        articles[i].newQuantity ||
        articles[i].newPrice !== articles[i].price ||
        articles[i].newComment ||
        articles[i].newCondition
      ) {
        articlesChanged.push(articles[i])
      }
    }
    return articlesChanged
  }
  const articlesChanged = getArticlesChanged(articles)

  const handlePublish = async (): Promise<void> => {
    setLoading(true)
    try {
      const results = await publishChanges(articlesChanged, setProgress, activeGame.idGame)
      setPublishResults(results)
      handleChangesPublished(results)
      setLoading(false)
      handleClose()
    } catch (err) {
      handle(err)
    }
  }
  return (
    <ClosableDialog
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      hideButton={true}
    >
      <Box width="400px">
        <DialogTitle id="form-dialog-title">{t('stockPricing.actions.publishChanges')}</DialogTitle>
      </Box>

      {publishSuccessCount || publishFailureCount ? (
        <>
          <DialogContent>
            {publishSuccessCount ? (
              <Typography variant="body2">
                {t('publish.success', { count: publishSuccessCount })}
              </Typography>
            ) : null}

            {publishFailureCount ? (
              <>
                <Typography variant="body2" color="error">
                  <b>{t('publish.failed', { count: publishFailureCount })}</b>
                </Typography>
              </>
            ) : null}
            <br />
            {publishResults.length && (
              <>
                <CSVLink
                  filename={`publish-${user?.username}-${moment(Date.now()).format(
                    'DD-MM-YYYY_HH-mm'
                  )}.csv`}
                  asyncOnClick={true}
                  onClick={(_event, done) => {
                    if (window.Cypress) done(false)
                    else done(true)
                  }}
                  data={
                    publishResults.map(({ article, quantityError, attributeError }) => {
                      const articleInst = new InventoryArticle(article)

                      return {
                        cardmarketId: articleInst.card._id,
                        name: articleInst.card.name,
                        set: articleInst.card.set,
                        language: articleInst.languageName,
                        condition: articleInst.condition,
                        foil: articleInst.isFoil,
                        playset: articleInst.isPlayset,
                        signed: articleInst.isSigned,
                        firstEd: articleInst.isFirstEd,
                        reverseHolo: articleInst.isReverseHolo,
                        quantity: articleInst.quantity,
                        newQuantity: quantityError || articleInst.newQuantity,
                        comment: articleInst.comment,
                        newComment: attributeError || articleInst.newComment,
                        price: articleInst.price,
                        newPrice: attributeError || articleInst.newPrice,
                      }
                    }) || []
                  }
                  target="_blank"
                >
                  {t('publish.downloadCsv')}
                </CSVLink>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              color="primary"
              data-testid="close-publish-change-btn"
              style={{
                width: '50px',
                height: '30px',
                fontSize: '14px',
                float: 'right',
              }}
            >
              {t('common.close')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Box>
            <DialogContent>
              {loading ? (
                <Box display="flex" flexDirection="column" padding={3} justifyContent="center">
                  {progress === 100 ? (
                    'Finishing...'
                  ) : (
                    <>
                      <CircularLoading value={progress} />

                      <Box marginTop={5}>
                        <Typography>{t('publish.pricingBotWarning1')}</Typography>
                        <Typography>{t('publish.pricingBotWarning2')}</Typography>
                      </Box>
                    </>
                  )}
                </Box>
              ) : (
                <PublishChangesSummary
                  articlesChanged={articlesChanged}
                  onPublishChanges={handlePublish}
                  onCancelClick={handleClose}
                />
              )}
            </DialogContent>
          </Box>
        </>
      )}
    </ClosableDialog>
  )
}
