import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import App from './components/App'
import './config/i18n'

if (process.env.NODE_ENV === 'production')
  Sentry.init({
    dsn: 'https://3a4ceae8abe544ae9ab4cf9914f1a1ee@o410216.ingest.sentry.io/5283933',
  })
ReactDOM.render(<App />, document.getElementById('root'))
