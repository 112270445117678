import { useUser } from 'components/providers/UserProvider'
import { useCallback, useState } from 'react'

export type SetLocalStorageValueFunction<T> = (newValue: T) => void

export function useLocalStorageState<T>(
  initialValue: T,
  key: string,
  options: { gameSpecific?: boolean } = { gameSpecific: true }
) {
  const { activeGame, user } = useUser()
  const { gameSpecific } = options

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const lsKey = `${user!.username}_${gameSpecific ? activeGame?.name : ''}_${key}`
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(lsKey)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        window.localStorage.setItem(lsKey, JSON.stringify(valueToStore))
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error)
      }
    },
    [user?.username, activeGame.name]
  )
  return [storedValue, setValue] as const
}
