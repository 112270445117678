import { Box, MenuItem, Paper, Select } from '@material-ui/core'
import { useOrderStore } from 'lib/hooks'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getGameMetaData, OrderArticle } from 'shared'

export const ArticleSorter = (): JSX.Element => {
  const { t } = useTranslation()
  const articlesToPick = useOrderStore((state) => state.articlesToPick)
  const setArticlesToPick = useOrderStore((state) => state.setArticlesToPick)
  const activePickingList = useOrderStore((state) => state.activePickingList)
  const articlesReadyForSorting = useOrderStore((state) => state.articlesReadyForSorting)
  const expansionMap = useOrderStore((state) => state.expansionMap)

  const possibleSortingFields = [
    {
      code: 'none',
      translation: 'None',
    },
    {
      code: 'game',
      translation: 'Game',
    },
    {
      code: 'product.expansion',
      translation: `${t('card.expansion')}`,
    },
    {
      code: 'extras',
      translation: 'Extras',
    },
    {
      code: 'product.rarity.asc',
      translation: `${t('card.rarity')} (common to rare)`,
    },
    {
      code: 'product.rarity.desc',
      translation: `${t('card.rarity')} (rare to common)`,
    },
    {
      code: 'product.enName',
      translation: `English name`,
    },
    {
      code: 'product.locName',
      translation: `Localized name`,
    },
    {
      code: 'cn',
      translation: `CN`,
    },
    {
      code: 'condition',
      translation: `${t('card.condition')}`,
    },
    {
      code: 'language',
      translation: `${t('card.language')}`,
    },
    {
      code: 'comment',
      translation: `${t('card.comment')}`,
    },
    {
      code: 'price',
      translation: `${t('card.price')}`,
    },
    {
      code: 'color',
      translation: `Color`,
    },
  ]

  const [sortingFields, setSortingFields] = useLocalForageState<string[]>(
    [
      'product.category',
      possibleSortingFields[1].code,
      possibleSortingFields[2].code,
      possibleSortingFields[3].code,
      possibleSortingFields[4].code,
    ],
    'orderSortingFields'
  )

  const getValueForKey = (o: any, k: string): any => {
    if (k === 'product.category') {
      return o.product.category
    } else if (k === 'game') {
      return o.product.idGame
    } else if (k === 'extras') {
      if (o.isFoil || o.isAltered || o.isSigned || o.isFirstEd) {
        return 0
      } else {
        return 1
      }
    } else if (k === 'product.expansion') {
      const expansion = expansionMap[o.product.expansion]
      const code = expansion?.code
      const releaseDate = expansion?.releaseDate
      return `${releaseDate}-${code}`
    } else if (k.includes('product.rarity')) {
      const idGame = o.product.idGame
      if (idGame !== 99) {
        const { cardmarketRarityToNumberMap } = getGameMetaData(idGame)
        if (k.includes('asc')) {
          return cardmarketRarityToNumberMap[o.product.rarity]
        } else {
          return cardmarketRarityToNumberMap[o.product.rarity] * -1
        }
      } else return 1
    } else if (k === 'product.enName') {
      return o.product.enName
    } else if (k === 'product.locName') {
      return o.product.locName
    } else if (k === 'cn') {
      return Number.parseInt(o.product.nr)
    } else if (k === 'condition') {
      return o.condition
    } else if (k === 'language') {
      return o.language.idLanguage
    } else if (k === 'comment') {
      return o.comments.toLowerCase()
    } else if (k === 'price') {
      return o.price
    } else if (k === 'color') {
      return o.product.colors
    }
  }

  const sortFn = (a: OrderArticle, b: OrderArticle, sortingFieldIdx: number): number => {
    const field = sortingFields[sortingFieldIdx]
    // const multiplier = 1
    // let direction = 'asc'
    // if (direction === 'desc') {
    //   multiplier = -1
    // }

    let valueA = getValueForKey(a, field)
    let valueB = getValueForKey(b, field)

    if (!valueA) {
      valueA = ''
    }
    if (!valueB) {
      valueB = ''
    }

    if (valueA > valueB) {
      return 1
    } else if (valueA < valueB) {
      return -1
    } else {
      if (sortingFieldIdx < sortingFields.length - 1) {
        return sortFn(a, b, sortingFieldIdx + 1)
      }
      return 0
    }
  }

  const sortArticles = () => {
    console.log(`sortArticles, list ${activePickingList?.name}, ${articlesToPick.length}`)
    const newArticlesToPick = [...articlesToPick]
    newArticlesToPick.sort((a, b) => sortFn(a, b, 0))
    setArticlesToPick(newArticlesToPick)
  }

  useEffect(() => {
    if (articlesReadyForSorting) {
      sortArticles()
    }
  }, [articlesReadyForSorting, sortingFields])

  const updateSortingFields = (idx: number, value: string) => {
    const newSortingFields = [...sortingFields]
    newSortingFields[idx] = value
    setSortingFields(newSortingFields)
  }

  return (
    <>
      <Box display="flex">
        <Paper elevation={3} style={{ margin: '10px', padding: '10px' }}>
          <Select
            value={sortingFields[1]}
            label="Select column"
            onChange={(e: any) => updateSortingFields(1, e.target.value)}
          >
            {possibleSortingFields.map((f) => {
              return (
                <MenuItem key={f.code} value={f.code}>
                  {f.translation}
                </MenuItem>
              )
            })}
          </Select>
        </Paper>

        <Paper elevation={3} style={{ margin: '10px', padding: '10px' }}>
          <Select
            value={sortingFields[2]}
            label="Select column"
            onChange={(e: any) => updateSortingFields(2, e.target.value)}
          >
            {possibleSortingFields.map((f) => {
              return (
                <MenuItem key={f.code} value={f.code}>
                  {f.translation}
                </MenuItem>
              )
            })}
          </Select>
        </Paper>

        <Paper elevation={3} style={{ margin: '10px', padding: '10px' }}>
          <Select
            value={sortingFields[3]}
            label="Select column"
            onChange={(e: any) => updateSortingFields(3, e.target.value)}
          >
            {possibleSortingFields.map((f) => {
              return (
                <MenuItem key={f.code} value={f.code}>
                  {f.translation}
                </MenuItem>
              )
            })}
          </Select>
        </Paper>

        <Paper elevation={3} style={{ margin: '10px', padding: '10px' }}>
          <Select
            value={sortingFields[4]}
            label="Select column"
            onChange={(e: any) => updateSortingFields(4, e.target.value)}
          >
            {possibleSortingFields.map((f) => {
              return (
                <MenuItem key={f.code} value={f.code}>
                  {f.translation}
                </MenuItem>
              )
            })}
          </Select>
        </Paper>
      </Box>
    </>
  )
}
