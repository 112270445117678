import { Box, MenuItem, SvgIconTypeMap, Typography } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { withStyles } from '@material-ui/styles'
import { CustomTooltip } from './CustomTooltip'

export interface ActionButtonProps {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  text: string
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  disabled?: boolean
  tooltip?: React.ReactNode | string
  withoutPadding?: boolean
  disabledTooltip?: string
  color?: 'primary' | 'secondary'
}

const ActionItem = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '60px',
    height: '86px',
    display: 'block',
    color: theme.palette.lightGray,
    //display: 'flex',
    //alignItems: 'center',
    //justifyContent: 'center',
  },
}))(MenuItem) as typeof MenuItem

export const ActionButton = ({
  text,
  Icon,
  onClick,
  disabled,
  tooltip,
  withoutPadding,
  disabledTooltip,
  color = 'primary',
  ...rest
}: ActionButtonProps): JSX.Element => {
  return (
    <CustomTooltip title={(disabled ? disabledTooltip : tooltip) || ''}>
      <Box
        paddingLeft={withoutPadding ? '0px' : '2px'}
        paddingRight={withoutPadding ? '0px' : '2px'}
      >
        <ActionItem
          {...rest}
          style={{ padding: withoutPadding ? '2px' : '4px', height: 'auto' }}
          disabled={disabled}
          onClick={onClick}
        >
          <Box
            width="fit-content"
            margin="0 auto"
            height="42px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon color={disabled ? 'inherit' : color} style={{ fontSize: '36px' }} />
          </Box>
          <Box
            width="auto"
            textAlign="center"
            height="22px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              style={{
                lineHeight: '110%',
                whiteSpace: 'normal',
                display: 'block',
                marginTop: '-4px',
              }}
              variant="caption"
            >
              {text}
            </Typography>
          </Box>
        </ActionItem>
      </Box>
    </CustomTooltip>
  )
}
