import { Box, Grid, Typography } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IdGame, PricingStrategy } from 'shared'
import { OptionBox } from '../OptionBox'
import { ProgressStepper } from '../ProgressStepper'
import { RestartButton } from '../RestartButton'
import { StepBox } from '../StepBox'
import { ExistingStrategiesPresenter } from './ExistingStrategiesPresenter'
import { FinalizePresenter } from './FinalizePresenter'

export interface MinMaxFlowPresenterProps {
  newName: string
  onRestart: () => void
  onDone: (strategy: PricingStrategy) => void
  dataTestid?: string
}

enum Step {
  Base = 1,
  Strategy1,
  Strategy2,
  Finalize,
}

export const MinMaxFlowPresenter = ({
  newName,
  onRestart,
  onDone,
  dataTestid,
}: MinMaxFlowPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()

  const [step, setStep] = useState<Step>(Step.Base)
  const [progress, setProgress] = useState<Step>(Step.Base)

  const minMaxStrategyIds: string[] = []
  user.pricingStrategies.forEach((strategy) => {
    if (strategy.base.kind === 'minMax') {
      minMaxStrategyIds.push(strategy._id)
    }
  })

  const [strategy, setStrategy] = useState<PricingStrategy>({
    _id: '2',
    name: newName,
    base: {
      kind: 'minMax',
      tactic: { parameter: 'Min' },
    },
    modifier: {
      kind: 'percent',
      value: 95,
    },
    bulkSettingsId: {
      [IdGame.Magic]: '',
      [IdGame.Yugioh]: '',
      [IdGame.Pokemon]: '',
      [IdGame.Vanguard]: '',
      [IdGame.DBS]: '',
      [IdGame.FF]: '',
      [IdGame.WS]: '',
      [IdGame.FAB]: '',
      [IdGame.Digimon]: '',
    },
  })

  const [chosenStrategy1, setChosenStrategy1] = useState<PricingStrategy | undefined>(undefined)
  const [chosenStrategy2, setChosenStrategy2] = useState<PricingStrategy | undefined>(undefined)

  return (
    <Grid style={{ height: '100%' }} container>
      <Grid item xs={1}>
        <ProgressStepper
          activeStep={step}
          progress={progress}
          handleStep={(index) => setStep(index)}
          steps={[
            t('strategy.parameter'),
            t('strategy.strategy1'),
            t('strategy.strategy2'),
            t('strategy.name'),
          ]}
        />
      </Grid>
      <Grid item xs={11}>
        <Box height="100%" padding={3}>
          <Typography variant="h4">
            {t('strategy.create.title')}{' '}
            <RestartButton onClick={onRestart} dataTestid={'restart-strategy-btn'} />
          </Typography>

          <Box height="inherit" paddingTop={1}>
            {
              {
                [Step.Base]: (
                  <StepBox>
                    <Typography variant="body1">
                      {t('strategy.create.minMaxParameterDescription')}
                    </Typography>

                    <Typography variant="caption">
                      {t('strategy.create.minMaxParameterDescription1')}
                    </Typography>

                    <Grid container>
                      {['Min', 'Max'].map((param) => (
                        <Grid xs={6} key={param} item data-testid={`box-${param}`}>
                          <OptionBox
                            onClick={() => {
                              const strategyCopy = { ...strategy }
                              strategyCopy.base.tactic.parameter = param
                              setStrategy(strategyCopy)
                              setStep(Step.Base + 1)
                              setProgress(Math.max(step + 1, progress))
                            }}
                            title={param}
                            width="130px"
                            dataTestid={dataTestid + `-${param}`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </StepBox>
                ),
                [Step.Strategy1]: (
                  <StepBox
                    onNext={() => {
                      setStep(Step.Strategy1 + 1)
                      setProgress(Math.max(step + 1, progress))
                    }}
                  >
                    <Typography variant="body1">
                      {t('strategy.create.minMaxStrategy1Description')}
                    </Typography>
                    <ExistingStrategiesPresenter
                      hiddenStrategiesId={minMaxStrategyIds}
                      handleBoxClick={(chosenStrategy) => {
                        setChosenStrategy1(chosenStrategy)
                        const strategyCopy = { ...strategy }
                        strategyCopy.base.tactic.strategy1 = chosenStrategy._id
                        setStrategy(strategyCopy)
                        setStep(Step.Strategy1 + 1)
                        setProgress(Math.max(step + 1, progress))
                      }}
                    />
                  </StepBox>
                ),
                [Step.Strategy2]: (
                  <StepBox
                    onNext={() => {
                      setStep(Step.Strategy2 + 1)
                      setProgress(Math.max(step + 1, progress))
                    }}
                  >
                    <Typography variant="body1">
                      {t('strategy.create.minMaxStrategy2Description')}
                    </Typography>
                    <ExistingStrategiesPresenter
                      hiddenStrategiesId={[chosenStrategy1?._id ?? '', ...minMaxStrategyIds]}
                      handleBoxClick={(chosenStrategy) => {
                        setChosenStrategy2(chosenStrategy)
                        const strategyCopy = { ...strategy }
                        strategyCopy.base.tactic.strategy2 = chosenStrategy._id
                        setStrategy(strategyCopy)
                        setStep(Step.Strategy2 + 1)
                        setProgress(Math.max(step + 1, progress))
                      }}
                    />
                  </StepBox>
                ),
                [Step.Finalize]: (
                  <StepBox
                    isLast
                    onNext={() => onDone(strategy)}
                    disableNext={strategy.name.trim() === ''}
                  >
                    <FinalizePresenter
                      strategy={strategy}
                      setStrategy={setStrategy}
                      strategy1={chosenStrategy1}
                      strategy2={chosenStrategy2}
                    />
                  </StepBox>
                ),
              }[step]
            }
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
