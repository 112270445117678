import { Box, ClickAwayListener, Grow, Paper, Popper, TextField } from '@material-ui/core'
import { Menu, MultipleMenu } from 'components/controls/form'
import { useScopelessHotkeys } from 'lib/hooks'
import { useState } from 'react'
import { SetState } from 'types'

export interface EditPopperProps {
  options?: { value: number | string; label: string }[]
  title?: boolean
  onChange: (newValue: unknown) => void
  type?: 'select' | 'multiple-select' | 'number' | 'multiple-textfield' | 'textfield' | 'bool'
  existingValue?: string | boolean | string[] | number
  anchorEl: HTMLElement | null
  onDone?: () => void
  dataTestId?: string
}

interface InputProps {
  value: string
  setValue: SetState<string>
  dataTestId?: string
}

const NumberInput = ({ value, setValue, dataTestId }: InputProps): JSX.Element => (
  <Box maxWidth="100px">
    <TextField
      variant="outlined"
      type="number"
      onFocus={(event) => {
        event.target.select()
      }}
      autoFocus
      value={value}
      data-testid={`${dataTestId}`}
      onChange={(e) => setValue(e.target.value)}
    />
  </Box>
)

const TextfieldInput = ({
  value,
  setValue,
  rows,
  dataTestId,
}: InputProps & { rows: number }): JSX.Element => (
  <Box maxWidth="300px">
    <TextField
      rows={rows}
      variant="outlined"
      autoFocus
      onFocus={(event) => {
        event.target.select()
      }}
      multiline={true}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      data-testid={`${dataTestId}`}
    />
  </Box>
)

export const EditPopper = ({
  options = [],
  onChange,
  type = 'select',
  existingValue = '',
  anchorEl,
  onDone,
  dataTestId,
}: EditPopperProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string | boolean | number>(
    !Array.isArray(existingValue) ? existingValue : ''
  )
  const [selected, setSelected] = useState<Array<string | number>>(
    Array.isArray(existingValue) ? existingValue : []
  )

  const handleSubmit = (): void => {
    switch (type) {
      case 'number':
        onChange(Math.round(Number(inputValue)))
        break
      case 'multiple-textfield':
        if (!(typeof inputValue === 'string')) return
        inputValue ? onChange(inputValue.split('\n')) : onChange([])
        break
      case 'textfield':
        if (inputValue) onChange(inputValue)
        break
      case 'multiple-select':
        onChange(selected)
        break
    }
    onDone?.()
  }

  if (type !== 'multiple-textfield')
    useScopelessHotkeys('enter', handleSubmit, [inputValue, selected])

  return (
    <ClickAwayListener disableReactTree={true} onClickAway={handleSubmit}>
      <Popper style={{ zIndex: 100000 }} open={Boolean(anchorEl)} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Box margin={1}>
              <Paper>
                <Box
                  maxHeight="400px"
                  style={{ overflowY: type === 'multiple-select' ? 'scroll' : 'initial' }}
                >
                  {
                    {
                      select: (
                        <Menu
                          value={inputValue as string | number}
                          onChange={(newValue) => {
                            onChange(newValue)
                            onDone?.()
                          }}
                          options={options}
                          dataTestId={`${dataTestId}`}
                        />
                      ),
                      number: (
                        <NumberInput
                          value={inputValue as string}
                          setValue={setInputValue as SetState<string>}
                          dataTestId={`${dataTestId}`}
                        />
                      ),
                      'multiple-select': (
                        <MultipleMenu
                          value={selected}
                          onChange={(newValue) => setSelected(newValue)}
                          options={options}
                          dataTestId={`${dataTestId}`}
                        />
                      ),
                      'multiple-textfield': (
                        <TextfieldInput
                          rows={3}
                          value={inputValue as string}
                          setValue={setInputValue as SetState<string>}
                          dataTestId={`${dataTestId}`}
                        />
                      ),
                      textfield: (
                        <TextfieldInput
                          rows={1}
                          value={inputValue as string}
                          setValue={setInputValue as SetState<string>}
                          dataTestId={`${dataTestId}`}
                        />
                      ),
                      bool: <></>,
                    }[type]
                  }
                </Box>
              </Paper>
            </Box>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  )
}
