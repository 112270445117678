import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export const useErrorHandler = (): { handle: (error: Error | unknown) => void } => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleError = (error: Error | unknown): void => {
    if (axios.isAxiosError(error)) {
      enqueueSnackbar(
        t(`backend.${error?.response?.data?.error?.message}`) || 'Unknown error occured',
        {
          variant: 'error',
        }
      )
    } else {
      if (process.env.NODE_ENV === 'development') console.error(error)
      if (error instanceof Error) enqueueSnackbar(t(error.message), { variant: 'error' })
    }
  }

  return { handle: handleError } as const
}
