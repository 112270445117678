import { Icon, SvgIcon, SvgIconProps } from '@material-ui/core'
import {
  AccountBalance,
  Adb,
  AddCircle,
  AddToPhotos,
  Apartment,
  Assignment,
  AssignmentOutlined,
  BackspaceOutlined,
  BusinessCenter,
  Cached,
  Close,
  CollectionsBookmarkOutlined,
  CommentOutlined,
  CompareArrows,
  CreditCard,
  CropOriginal,
  DeleteOutline,
  DoneAll,
  DoubleArrow,
  DragHandle,
  DragIndicator,
  Edit,
  Euro,
  ExpandLess,
  ExpandMore,
  FiberManualRecord,
  FileCopyOutlined,
  FilterList,
  Forward,
  Group,
  HelpOutline,
  HighlightOff,
  Home,
  HourglassEmpty,
  Info,
  Language,
  Launch,
  LocalAtm,
  LocalOfferOutlined,
  Looks3,
  Looks4,
  LooksOne,
  MenuBook,
  MenuOpen,
  MonetizationOn,
  PeopleAlt,
  PersonAddDisabled,
  PhotoSizeSelectSmallOutlined,
  PriorityHigh,
  PublishOutlined,
  Replay,
  Save,
  SelectAllTwoTone,
  SettingsOutlined,
  ShoppingCart,
  SpeedOutlined,
  SwapVert,
  Update,
  ViewCarousel,
} from '@material-ui/icons'
import AcUnitIcon from '@material-ui/icons/AcUnit'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import EuroIcon from '@material-ui/icons/Euro'
import FlashAutoOutlinedIcon from '@material-ui/icons/FlashAutoOutlined'
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'
import WarningIcon from '@material-ui/icons/Warning'
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone'

export const RightArrowIcon = ArrowForwardIcon
export const CommentPTIcon = CommentOutlined
export const Eurocon = EuroIcon
export const AttentionIcon = WarningIcon

export const DeleteIcon = DeleteOutline
export const ExcludedUsersIcon = PersonAddDisabled
export const AddIcon = AddCircle
export const PlaceholderIcon = AcUnitIcon
export const CompetitorIcon = PeopleAlt
export const CompetitionStrategyIcon = MonetizationOn
export const PriceGuideStrategyIcon = MenuBook
export const MinMaxStrategyIcon = CompareArrows
export const Playset4Icon = Looks4
export const Playset3Icon = Looks3
export const FirstEdIcon = LooksOne
export const BulkIcon = ViewCarousel
export const NMeqEXIcon = DragHandle
export const RefreshStockIcon = Cached
export const SinglesStockIcon = CropOriginal
export const HelpIcon = HelpOutline
export const GetDayPassIcon = ShoppingCart
export const DataFreshnessIcon = FiberManualRecord
export const StatusIcon = FiberManualRecord
export const FilterIcon = FilterList
export const EditIcon = Edit
export const ExclamationPointIcon = PriorityHigh
export const AllSellersIcon = Group
export const ProfessionalSellersIcon = BusinessCenter
export const Warning2ToneIcon = WarningTwoToneIcon
export const LanguageIcon = Language
export const AddArticlesIcon = AddToPhotos
export const PowerSellerSellersIcon = Apartment
export const RestartIcon = Replay
export const ImportDisabledIcon = SyncDisabledIcon
export const HomeIcon = Home
export const SelectAllIcon = SelectAllTwoTone
export const DeselectAllIcon = PhotoSizeSelectSmallOutlined
export const SettingsIcon = SettingsOutlined
export const AutopriceIcon = LocalAtm
export const SetPriceIcon = LocalOfferOutlined
export const GoIcon = DoubleArrow
export const DiscardIcon = Replay
export const PublishChangesIcon = PublishOutlined
export const SaveIcon = Save
export const IsSavingIcon = HourglassEmpty
export const ClearFailedAutopriceIcon = BackspaceOutlined
export const CloseIcon = Close
export const PriceSuggestIcon = Euro
export const InfoIcon = Info
export const TemplateIcon = AssignmentOutlined
export const MarkForPublishIcon = CollectionsBookmarkOutlined
export const AutomaticFix = FlashAutoOutlinedIcon
export const FileIcon = DescriptionOutlinedIcon
export const JumpToIcon = Forward
export const SetQuantityIcon = SwapVert
export const ExpandIcon = ExpandMore
export const CollapseIcon = ExpandLess
export const DragIcon = DragIndicator
export const PriceUpdatesIcon = Update
export const SelectPriceUpdateIcon = MenuOpen
export const CloneIcon = FileCopyOutlined
export const BotIcon = Adb
export const SepaIcon = AccountBalance
export const CreditCardIcon = CreditCard
export const TestIcon = DoneAll
export const StopIcon = HighlightOff
export const ViewIcon = Launch
export const SetConditionIcon = SpeedOutlined
export const ListIcon = Assignment

export const StockPricingIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="0 0 24 24"
    viewBox="0 0 24 24"
    fill="white"
    width="24px"
    height="24px"
    {...props}
  >
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
    </g>
    <g>
      <path d="M20,4H4C2.89,4,2.01,4.89,2.01,6L2,18c0,1.11,0.89,2,2,2h16c1.11,0,2-0.89,2-2V6C22,4.89,21.11,4,20,4z M12,10H8v1h3 c0.55,0,1,0.45,1,1v3c0,0.55-0.45,1-1,1h-1v1H8v-1H6v-2h4v-1H7c-0.55,0-1-0.45-1-1V9c0-0.55,0.45-1,1-1h1V7h2v1h2V10z M16,16.25 l-2-2h4L16,16.25z M14,10l2-2l2,2H14z" />
    </g>
  </SvgIcon>
)

export const DiscordIcon = (): JSX.Element => (
  <Icon>
    <svg
      aria-hidden="true"
      focusable="false"
      width="1em"
      height="1em"
      //style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27z"
        fill="white"
      />
    </svg>
  </Icon>
)
export const ExportIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    //fill=""
    width="48px"
    height="48px"
    {...props}
  >
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
    </g>
    <g>
      <g>
        <path d="M18,2h-8L4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,4v16H6V8.83L10.83,4H18z" />
        <path d="M16,13l-4,4l-4-4l1.41-1.41L11,13.17V9.02L13,9v4.17l1.59-1.59L16,13z" />
      </g>
    </g>
  </SvgIcon>
)
export const ImportIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    fill="black"
    width="36px"
    height="36px"
    {...props}
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M18,20H6V4h7v5h5V20z M8,15.01 l1.41,1.41L11,14.84V19h2v-4.16l1.59,1.59L16,15.01L12.01,11L8,15.01z" />
      </g>
    </g>
  </SvgIcon>
)

export const NewBotIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    aria-hidden="true"
    focusable="false"
    width="36px"
    height="36px"
    //style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#clip0_289:616)">
      <path
        d="M5 15.9995C5 19.8695 8.13 22.9995 12 22.9995C15.87 22.9995 19 19.8695 19 15.9995V11.9995H5V15.9995ZM16.12 4.36945L18.22 2.26945L17.4 1.43945L15.1 3.74945C14.16 3.27945 13.12 2.99945 12 2.99945C10.88 2.99945 9.84 3.27945 8.91 3.74945L6.6 1.43945L5.78 2.26945L7.88 4.36945C6.14 5.63945 5 7.67945 5 9.99945V10.9995H19V9.99945C19 7.67945 17.86 5.63945 16.12 4.36945ZM9 8.99945C8.45 8.99945 8 8.54945 8 7.99945C8 7.44945 8.45 6.99945 9 6.99945C9.55 6.99945 10 7.44945 10 7.99945C10 8.54945 9.55 8.99945 9 8.99945ZM15 8.99945C14.45 8.99945 14 8.54945 14 7.99945C14 7.44945 14.45 6.99945 15 6.99945C15.55 6.99945 16 7.44945 16 7.99945C16 8.54945 15.55 8.99945 15 8.99945Z"
        fill="white"
      />
      <path
        d="M0 15C0 13.8954 0.895431 13 2 13H22C23.1046 13 24 13.8954 24 15V19C24 20.1046 23.1046 21 22 21H2C0.89543 21 0 20.1046 0 19V15Z"
        fill="#FF2D2D"
      />
      <path
        d="M6.66016 20H5.81201L2.59082 15.0693V20H1.74268V13.6016H2.59082L5.8208 18.5542V13.6016H6.66016V20ZM11.7754 17.0425H9.00244V19.3101H12.2236V20H8.15869V13.6016H12.1797V14.2959H9.00244V16.3525H11.7754V17.0425ZM14.6538 17.9829L14.7769 18.8267L14.957 18.0664L16.2227 13.6016H16.9346L18.1694 18.0664L18.3452 18.8398L18.4814 17.9785L19.4746 13.6016H20.3228L18.7715 20H18.0024L16.6841 15.3374L16.583 14.8496L16.4819 15.3374L15.1152 20H14.3462L12.7993 13.6016H13.6431L14.6538 17.9829ZM22.0454 18.1938H21.3115L21.2544 13.6016H22.1069L22.0454 18.1938ZM21.2236 19.5913C21.2236 19.4595 21.2632 19.3496 21.3423 19.2617C21.4243 19.1709 21.5444 19.1255 21.7026 19.1255C21.8608 19.1255 21.981 19.1709 22.063 19.2617C22.145 19.3496 22.186 19.4595 22.186 19.5913C22.186 19.7231 22.145 19.833 22.063 19.9209C21.981 20.0059 21.8608 20.0483 21.7026 20.0483C21.5444 20.0483 21.4243 20.0059 21.3423 19.9209C21.2632 19.833 21.2236 19.7231 21.2236 19.5913Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_289:616">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export const BetaOrdersIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    aria-hidden="true"
    focusable="false"
    width="36px"
    height="36px"
    //style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 100 100"
    {...props}
  >
    <g>
      <path
        d="M89.2,67.6l-2.1-4.9H80l-2.1,4.9h-9.7h-2.8h-6.1v8.8h-33v-8.8h-0.3v-4.1c-0.6,0.7-1.2,1.2-1.8,1.7
		c-2.4,1.6-5.5,2.4-9.2,2.4H7.4v18.2c0,5.2,4.2,9.4,9.4,9.4h65.9c5.2,0,9.4-4.2,9.4-9.4V67.6H89.2z"
      />
      <path
        d="M15.2,35.2c6.1,0,9.2,2.1,10.7,4.6v-4.6h0.3v-6h47.1v6h3.3v6.6l2.9-6.6h8.3l4.4,10.1V19.8c0-5.2-4.2-9.4-9.4-9.4H63
		C61.1,5,55.9,1,49.8,1c-6.1,0-11.3,4-13.3,9.4H16.8c-5.2,0-9.4,4.2-9.4,9.4v15.4H15.2z M49.8,10.4c2.6,0,4.7,2.1,4.7,4.7
		c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7C45,12.5,47.2,10.4,49.8,10.4z"
      />
    </g>
    <g>
      <path
        d="M3.4,38.2h11.8c6.1,0,9.1,2.2,9.1,6.7c0,2.3-0.9,4.2-2.6,5.6c2.3,1.1,3.4,3.1,3.4,6.1c0,2.7-0.9,4.6-2.8,5.9
		c-1.9,1.3-4.4,1.9-7.5,1.9H3.4V38.2z M9.3,43.6v4.8h5.5c1.2,0,2-0.2,2.6-0.6c0.6-0.4,0.9-0.9,0.9-1.6c0-0.9-0.3-1.6-0.9-2
		c-0.6-0.4-1.6-0.6-3.2-0.6H9.3z M9.3,53.7V59h5c1.7,0,3-0.2,3.7-0.6c0.7-0.4,1.1-1.1,1.1-2.1c0-0.9-0.3-1.6-1-2
		c-0.7-0.4-1.8-0.6-3.5-0.6H9.3z"
      />
      <path d="M28.9,38.2h20.1v5.5H34.8v4.9h13.4v5.3H34.8V59h14.6v5.5H28.9V38.2z" />
      <path d="M50.9,38.2h22.7v5.5h-8.5v20.9h-5.8V43.6h-8.4V38.2z" />
      <path d="M81.5,38.2h4.3l11.6,26.3h-6.1l-2.1-4.9h-11l-2.1,4.9h-6L81.5,38.2z M80.1,54.7h6.8l-3.4-7.9L80.1,54.7z" />
    </g>
  </SvgIcon>
)

export const SealedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    aria-hidden="true"
    focusable="false"
    width="36px"
    height="36px"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 310 440"
    {...props}
  >
    <g stroke={props.color ? props.color : 'black'} strokeWidth="20">
      <polygon
        fill="none"
        points="112.53,117.168 18.834,56.393 18.834,89.203 112.857,213.449 "
        id="polygon2"
      />
      <polygon
        fill="none"
        points="121.879,219.577 306.795,182.594 308.568,83.293 121.533,117.863 "
        id="polygon4"
      />
      <polygon
        fill="none"
        points="18.834,105.774 18.834,294.969 113.413,379.385 112.914,230.096 "
        id="polygon6"
      />
      <polygon
        fill="none"
        points="299.464,75.823 204.388,21.729 27.463,50.07 118.68,109.238 "
        id="polygon8"
      />
      <polygon
        fill="none"
        points="121.913,229.77 122.43,381.618 304.079,334.654 306.612,192.829 "
        id="polygon10"
      />
      <path
        d="m 8.834,297.253 v 2.132 l 1.727,1.647 102.908,92.199 v 0 l 199.487,-51.576 4.808,-269.214 v 0 L 207.805,12.167 206.438,11.375 204.542,11.576 13.043,42.253 8.834,42.886 M 306.795,182.594 121.879,219.577 121.533,117.863 308.569,83.293 Z M 18.834,56.393 112.53,117.168 112.857,213.449 18.834,89.203 Z m 0,238.576 V 105.774 l 94.08,124.322 0.499,149.289 z M 27.463,50.07 204.388,21.728 l 96.987,53.74 -182.695,33.77 z m 94.967,331.548 -0.517,-151.849 184.699,-36.941 -2.533,141.826 z"
        id="path12"
      />
    </g>
  </SvgIcon>
)
