import { Box, Divider, FormHelperText, FormLabel, Typography, useTheme } from '@material-ui/core'
import { Button, HelpText } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { calcPriceDiff, formatNumber } from 'lib/utils'
import { Trans, useTranslation } from 'react-i18next'
import { PricedInventoryArticle, PricingStrategy, RichArticle } from 'shared'
import { PricingStrategyBox } from '../PricingSettings/PricingStrategyBox'
import { AutopriceResultTable } from './AutopriceResultTable'

interface AutopriceSummaryProps {
  failures: RichArticle[]
  showFailedAutopricingCards?: boolean
  articlesPriced: PricedInventoryArticle[]
  chosenStrategy: PricingStrategy
  closeDialog: () => void
}

export const AutopriceSummary = ({
  failures,
  showFailedAutopricingCards,
  articlesPriced,
  chosenStrategy,
  closeDialog,
}: AutopriceSummaryProps): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { user } = useUser()
  if (!user) return <></>

  const oldPricesAggregate = articlesPriced.reduce(
    (acc, val) => (acc += val.price * val.quantity),
    0
  )

  const newPricesAggregate = articlesPriced.reduce(
    (acc, val) => (acc += val.newPrice! * val.quantity),
    0
  )

  const cardsAutopriced = articlesPriced.reduce((acc, val) => (acc += Number(val.quantity)), 0)

  const StatsRowItem = ({
    label,
    value,
  }: {
    label: string
    value: number | string | React.ReactNode
  }): JSX.Element => {
    return (
      <Box marginRight={4}>
        <FormLabel style={{ fontSize: '0.75rem' }}>{label}</FormLabel>
        <Box display="flex">{value}</Box>
      </Box>
    )
  }

  return (
    <>
      <Box data-testid="autoprice-summary">
        <Box paddingLeft={'24px'} paddingBottom={1} display="flex">
          <StatsRowItem
            value={
              <HelpText
                dataTestid={`strategyName-${chosenStrategy.name}`}
                tooltip={
                  <PricingStrategyBox
                    competitors={user.competitors}
                    pricingStrategy={chosenStrategy}
                  />
                }
              >
                {chosenStrategy.name}
              </HelpText>
            }
            label={t('autopriceArticles.summary.pricingStrategy')}
          />
          <StatsRowItem
            value={`${articlesPriced.length} (${cardsAutopriced} ${t(
              'autopriceArticles.summary.cards'
            )})`}
            label={t('autopriceArticles.summary.articlesAutopriced')}
          />
          <StatsRowItem
            value={formatNumber(newPricesAggregate, user.currency)}
            label={t('autopriceArticles.summary.newPrice')}
          />
          <StatsRowItem
            value={formatNumber(oldPricesAggregate, user.currency)}
            label={t('autopriceArticles.oldPrice')}
          />
          <StatsRowItem
            value={calcPriceDiff(newPricesAggregate, oldPricesAggregate)}
            label={t('autopriceArticles.percentDiff') + '%'}
          />
        </Box>

        {failures.length ? (
          <Box marginBottom={1} padding={2} bgcolor={theme.palette.lightBackground}>
            <Typography variant="body1">
              {showFailedAutopricingCards
                ? t('autopriceArticles.failedArticles', {
                    count: failures.length,
                  })
                : t('autopriceArticles.failedArticlesCsv', {
                    count: failures.length,
                  })}
            </Typography>
          </Box>
        ) : null}
        <Divider />
        <AutopriceResultTable data={articlesPriced} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormHelperText>
            <Trans
              components={{
                1: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://tcgpowertools.com/post/getting-started-pricing-settings"
                  />
                ),
              }}
              i18nKey="autopriceArticles.successHelperText"
            ></Trans>
          </FormHelperText>
          <Box marginTop={1}>
            <Button
              variant="contained"
              onClick={closeDialog}
              data-testid="autoprice-summary-close-btn"
              style={{
                textAlign: 'center',
                height: '40px',
                fontSize: '16px',
                lineHeight: '20px',
              }}
            >
              {t('autopriceArticles.summary.close')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
