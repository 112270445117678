import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from '@material-ui/core'
import { ReactNode } from 'react'

export const MultipleSelect = <T extends string | number>({
  label,
  onChange,
  children,
  options,
  value,
  ...otherProps
}: Omit<SelectProps, 'onChange' | 'value'> & {
  onChange: (val: T[]) => void
  value: Array<T>
  options: { value: T; label: string | ReactNode }[]
}): JSX.Element => {
  return (
    <Box width="100%">
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <MuiSelect
          {...otherProps}
          fullWidth
          value={value}
          multiple
          renderValue={(selected: unknown): ReactNode => {
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {(selected as string[]).map((value: string) => (
                  <Chip
                    size="small"
                    key={value}
                    label={options.find(({ value: val }) => val === value)?.label}
                  />
                ))}
              </div>
            )
          }}
        >
          {options.map((option) => (
            <MenuItem
              onClick={() => {
                if (value.includes(option.value))
                  onChange(value.filter((val) => val !== option.value))
                else onChange([...value, option.value])
              }}
              key={option.value}
              selected={value.includes(option.value)}
              value={option.value}
            >
              <Checkbox color="primary" checked={value.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  )
}
