import { Box, CircularProgress, Dialog, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { fetchOrders, fetchOrdersFromCardmarketAsync } from 'lib/api'
import { useOrderStore } from 'lib/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const SyncButton = (): JSX.Element => {
  const { t } = useTranslation()
  const { user, fetchUser } = useUser()

  const loadingFromMkm = useOrderStore((state) => state.loadingFromMkm)
  const setLoadingFromMkm = useOrderStore((state) => state.setLoadingFromMkm)
  const setRawOrders = useOrderStore((state) => state.setRawOrders)

  const [lastFetchedAt, setLastFetchedAt] = useState<string | undefined>(undefined)

  const handleSyncOrders = async () => {
    setLoadingFromMkm(true)
    await fetchOrdersFromCardmarketAsync()
    console.log('fetched mkm orders')
    await fetchUser()

    const newOrders = await fetchOrders()
    setRawOrders(newOrders)
  }

  useEffect(() => {
    if (!loadingFromMkm) {
      updateLastFetchedAt()
    }
  }, [loadingFromMkm])

  const updateLastFetchedAt = () => {
    const selectedMkmAccount = user.assignedCardmarketAccounts.find(
      (account) => account.username === user.cardmarketUsername
    )
    if (selectedMkmAccount && selectedMkmAccount.ordersLastFetchedAt) {
      const currentLastFetchedAt = new Date(selectedMkmAccount.ordersLastFetchedAt)
      setLastFetchedAt(currentLastFetchedAt.toLocaleString())
    }
  }

  return (
    <>
      <Box marginBottom={1}>
        <Button
          fullWidth
          variant="outlined"
          data-testid="sync-orders-btn"
          onClick={handleSyncOrders}
        >
          {t('orders.sync')}
        </Button>
      </Box>
      <Typography variant="body2" style={{ color: 'grey' }}>
        {t('orders.lastFetchedAt', { time: lastFetchedAt })}
      </Typography>

      <Dialog open={loadingFromMkm}>
        <Box padding={5}>
          <Box height="8rem" width="fit-content" margin="0 auto">
            <CircularProgress size={100} />
          </Box>
          <Typography>{t('orders.fetchingYourOrdersFromMkm')}</Typography>
        </Box>
      </Dialog>
    </>
  )
}
