import { InventoryArticle, PTInventoryProduct } from 'shared'

export const getInventoryArticle = (ptInventoryProduct: PTInventoryProduct) => {
  const params = {
    id: ptInventoryProduct._id,
    quantity: ptInventoryProduct.quantity,
    price: 0,
    card: {
      _id: Number.parseInt(ptInventoryProduct._id),
      rarity: 0,
      rarityStr: 'bla',
      categoryName: ptInventoryProduct.categoryName,
      cn: null,
      name: '',
      set: ptInventoryProduct.expansion?.code ?? '',
      expansion: '',
      locNames: {
        ['DE']: '',
        IT: '',
        FR: '',
        ES: '',
      },
    },
  }
  const inventoryArticle = new InventoryArticle(params)
  inventoryArticle.condition = ptInventoryProduct.condition
  inventoryArticle.idLanguage = ptInventoryProduct.idLanguage
  return inventoryArticle
}
