import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { loadStripe } from '@stripe/stripe-js'
import { ClosableDialog } from 'components/controls/dialogs'
import { AccountSettingSelect } from 'components/controls/misc'
import { CreditCardPresenter } from 'components/domain/account/CreditCardPresenter'
import { CardmarketConnect } from 'components/domain/cardmarket'
import {
  AppLayout,
  Button,
  CircularLoading,
  CreditCardIcon,
  DialogBox,
  SepaIcon,
} from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { theme } from 'config'
import apiPartnerLogo from 'img/apiPartnerLogo.png'
import powertoolsLogo from 'img/purpleTcgPowertools.png'
import {
  createStripeSession,
  editUserAttribute,
  getConfigKey,
  getSubscriptionOffer,
  setupSepaMandate,
  setUserOutsetaAttributes,
} from 'lib/api'
import { useErrorHandler } from 'lib/hooks'
import { ApiCallStatus, useApiCall } from 'lib/hooks/useApiCall'
import qs from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'
import { APP_LANGUAGES } from 'utils/constants'

const useStyles = makeStyles(() => ({
  root: {
    background: 'linear-gradient(90deg, rgb(60, 56, 95) 0%, rgb(120, 115, 160) 70%)',
    [theme.breakpoints.down('xs')]: {
      padding: '10vh 1vh 10vh 1vh',
      // position: 'absolute',
    },
    padding: '5vh',
    minHeight: '100vh',
  },
}))

enum SignupStepEnum {
  SignUp = 0,
  ConnectCardmarket,
  Settings,
  PricingInfo,
  Finish,
}

const shownSignupSteps = [
  SignupStepEnum.SignUp,
  SignupStepEnum.ConnectCardmarket,
  SignupStepEnum.Settings,
  SignupStepEnum.PricingInfo,
]

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '')

export const FinishSignUp = (): JSX.Element => {
  const classes = useStyles()
  const { user, fetchUser, setUser } = useUser()
  const location = useLocation()
  const { sepaCancel, success: sepaSuccess } = qs.parse(location.search)

  let initialActiveStep: SignupStepEnum = SignupStepEnum.ConnectCardmarket
  if (user?.cardmarketUsername) initialActiveStep = SignupStepEnum.Settings
  if (sepaCancel || sepaSuccess) {
    initialActiveStep = SignupStepEnum.ConnectCardmarket
  }

  const [activeStep, setActiveStep] = useState<SignupStepEnum>(initialActiveStep)

  const [spentTooLongInPricingInfo, setSpentTooLongInPricingInfo] = useState(false)

  const [emailPreferences, setEmailPreferences] = useState({
    gettingStarted: true,
    productNews: true,
    surveys: true,
  })

  const [creditCardDialogOpen, setCreditCardDialogOpen] = useState(false)
  const emailTypes = Object.keys(emailPreferences) as (keyof typeof emailPreferences)[]

  const { handle } = useErrorHandler()
  const stripeSessionId = qs.parse(location.search)?.session_id as string

  const startGettingSepaMandate = async (): Promise<void> => {
    try {
      const { sessionId } = await createStripeSession('finish-signup')
      const stripe = await stripePromise
      const { error } = await stripe!.redirectToCheckout({
        sessionId,
      })
      if (error) throw error
    } catch (err) {
      handle(err)
    }
  }

  useEffect(() => {
    const handleStripeCallback = async (): Promise<void> => {
      if (stripeSessionId) {
        await setupSepaMandate(stripeSessionId)
        fetchUser()
      }
    }
    handleStripeCallback()
  }, [stripeSessionId])

  useEffect(() => {
    if (user?.stripePaymentMethod) {
      setActiveStep(SignupStepEnum.Finish)
    }
  }, [user?.stripePaymentMethod])

  const handleClickPaymentMethod = (method: 'sepa' | 'creditCard'): void => {
    if (method === 'sepa') {
      startGettingSepaMandate()
    } else if (method === 'creditCard') {
      setCreditCardDialogOpen(true)
    }
  }

  const finishSignup = async (): Promise<void> => {
    await editUserAttribute(false, 'hasNotLoggedIn')

    const ptInventoryEnabled = (await getConfigKey('ptInventoryRegistrationActive')) || false
    await editUserAttribute({ ...user.isTester, ptInventory: ptInventoryEnabled }, 'isTester')

    setUser({
      ...user!,
      hasNotLoggedIn: false,
      isTester: { ...user.isTester, ptInventory: ptInventoryEnabled },
    })
  }

  const getSubOfferCall = useApiCall<typeof getSubscriptionOffer>(getSubscriptionOffer, [], true)

  useEffect(() => {
    if (getSubOfferCall.status === ApiCallStatus.Success) {
      setUser({
        ...user!,
        currentSubscriptionOffer: getSubOfferCall.data.currentSubscriptionOffer || undefined,
      })
    }
  }, [getSubOfferCall.status])

  const hasStartedEvaluationRef = useRef(false)
  useEffect(() => {
    const handleCardmarketAccountConnected = async (): Promise<void> => {
      if (user?.cardmarketUsername && !hasStartedEvaluationRef.current) {
        hasStartedEvaluationRef.current = true
        //wait a second
        await new Promise((resolve) => setTimeout(resolve, 1000))

        await getSubOfferCall.performCall()
      }
    }

    handleCardmarketAccountConnected()
  }, [user?.cardmarketUsername])

  useEffect(() => {
    let timeout: any = null
    if (activeStep === SignupStepEnum.PricingInfo && !user?.currentSubscriptionOffer) {
      setTimeout(() => {
        timeout = setSpentTooLongInPricingInfo(true)
      }, 10000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [activeStep, user?.currentSubscriptionOffer])

  const { t, i18n } = useTranslation()

  const [referralCode, setReferralCode] = useState(localStorage.getItem('referralCode') || '')

  if (user?.username && !user.hasNotLoggedIn) {
    return <Redirect to="/" />
  }

  const PTConnector = withStyles({
    alternativeLabel: {},
    active: {
      '& $line': {
        borderColor: theme.palette.strongViolet,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.palette.strongViolet,
      },
    },
    line: {},
  })(StepConnector)

  const onCardmarketConnect = () => {
    setActiveStep(SignupStepEnum.Settings)
  }

  return (
    <AppLayout>
      <Box className={classes.root}>
        <DialogBox>
          <Box position="relative">
            <Box padding={1} width="fit-content" margin="0 auto" display="flex" alignItems="center">
              <Grid container spacing={3}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  <img width="100%" src={powertoolsLogo} />
                </Grid>
                <Grid item xs style={{ textAlignLast: 'center' }}>
                  <img
                    width="auto"
                    height="100px"
                    src={apiPartnerLogo}
                    style={{
                      filter: 'hue-rotate(20deg) brightness(1.75) saturate(0.7) contrast(1.1)',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Stepper alternativeLabel activeStep={activeStep} connector={<PTConnector />}>
                {shownSignupSteps.map((step) => {
                  return (
                    <Step key={SignupStepEnum[step]}>
                      <StepLabel>
                        {t(
                          `${
                            {
                              [SignupStepEnum.SignUp]: 'signup.signup',
                              [SignupStepEnum.ConnectCardmarket]: 'signup.connectCardmarket',
                              [SignupStepEnum.Settings]: 'common.settings',
                              [SignupStepEnum.PricingInfo]: 'signup.pricingInfo',
                              [SignupStepEnum.Finish]: '',
                            }[step]
                          }`
                        )}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </Box>
            {activeStep === SignupStepEnum.ConnectCardmarket && (
              <Box textAlign="center">
                <Box marginTop={3}>
                  <Typography variant="body1">{t('signup.welcome')}</Typography>
                </Box>
                <Grid
                  container
                  style={{
                    maxWidth: '450px',
                    width: '90%',
                    margin: '0 auto',
                    paddingBottom: '6rem',
                    paddingTop: '0.2rem',
                  }}
                >
                  <Grid item xs={12}>
                    <Box marginTop={1} marginBottom={2}>
                      <Typography variant="body1">{t('signup.letsConnectCardmarket')}</Typography>
                    </Box>
                    <Box margin="0 auto" width="fit-content">
                      <Grid
                        spacing={1}
                        container
                        style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}
                      >
                        <CardmarketConnect
                          noHelperText
                          big
                          onConnect={() => onCardmarketConnect()}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeStep === SignupStepEnum.Settings && (
              <Box marginTop={4} marginBottom={3} marginX={6}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
                    <Typography align="right">{t('common.AppliactionLanguage')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <AccountSettingSelect
                        attribute={'language'}
                        options={APP_LANGUAGES}
                        onChange={(newLanguage) => i18n.changeLanguage(newLanguage as string)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography align="right">{t('signup.emailPreferences')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {emailTypes.map((emailType) => (
                      <Box key={emailType}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={emailPreferences[emailType]}
                              onChange={(e) =>
                                setEmailPreferences({
                                  ...emailPreferences,
                                  [emailType]: e.target.checked,
                                })
                              }
                              name={emailType}
                              color="primary"
                            />
                          }
                          label={t(`signup.emailPreference.${emailType}`)}
                        />
                      </Box>
                    ))}
                  </Grid>

                  <Grid item xs={12} md={6} style={{ alignSelf: 'end' }}>
                    <Typography align="right">{t('signup.doYouHaveReferral')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                      fullWidth
                      label={t('signup.referral')}
                    />
                  </Grid>
                </Grid>
                <Box margin={'auto'} marginTop={5} maxWidth="19rem">
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ fontSize: '1.3rem' }}
                    onClick={() => {
                      setUserOutsetaAttributes({
                        emailPreferences,
                        referralCode,
                      })

                      setActiveStep(SignupStepEnum.PricingInfo)
                    }}
                  >
                    {t('common.next')}
                  </Button>
                </Box>
              </Box>
            )}
            {activeStep === SignupStepEnum.PricingInfo && (
              <Box marginTop={3}>
                <Box textAlign="center" maxWidth="500px" margin="0 auto">
                  <Box marginBottom={2}>
                    <Typography variant="body1">{t('signup.pricing')}</Typography>
                  </Box>
                  <Box marginTop={4} marginBottom={2}>
                    <Typography variant="body1">
                      {user?.currentSubscriptionOffer ? (
                        t('signup.yourPriceIs', { price: user?.currentSubscriptionOffer - 0.01 })
                      ) : !spentTooLongInPricingInfo ? (
                        <Box>
                          <CircularLoading size={9} /> {t('signup.evaluatingVolume')}
                        </Box>
                      ) : (
                        t('signup.weWillTellYouLater')
                      )}
                    </Typography>
                    <Typography>{t('signup.trialInfo')}</Typography>
                    <Box marginTop={3}></Box>
                    <Typography>{''}</Typography>
                    <Box marginTop={3}></Box>
                  </Box>
                  <Box paddingTop={5}>
                    <Box paddingTop={2}></Box>
                  </Box>
                  <Box marginTop={5} marginBottom={2}>
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              onClick={() => handleClickPaymentMethod('sepa')}
                              style={{ fontSize: '1.5rem' }}
                              startIcon={<SepaIcon style={{ fontSize: '1.6rem' }} />}
                            >
                              <Typography variant="h6">{t('account.addSepaMandate')}</Typography>
                            </Button>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              onClick={() => handleClickPaymentMethod('creditCard')}
                              style={{ fontSize: '1.5rem' }}
                              startIcon={<CreditCardIcon style={{ fontSize: '1.6rem' }} />}
                            >
                              <Typography variant="h6">{t('account.creditCard')}</Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Box marginTop={3}>
                    <Button onClick={() => setActiveStep(SignupStepEnum.Finish)}>
                      {t('signup.skipBillingInfo')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {activeStep === SignupStepEnum.Finish && (
              <Box>
                <Box textAlign="center">
                  <Box marginBottom={4} marginTop={4}>
                    <Typography variant="h5">
                      <b>{t('signup.allDone')}</b>
                    </Typography>
                  </Box>
                  <Box marginTop={7} paddingBottom={5}>
                    <Typography variant="body1">
                      {
                        <Trans
                          i18nKey={`signup.ifYouNeedHelp`}
                          components={{
                            1: (
                              <a
                                href="https://support.tcgpowertools.com"
                                target="_blank"
                                rel="noreferrer"
                              />
                            ),
                            2: (
                              <a
                                href="https://discord.com/invite/svUvfT5N4K"
                                target="_blank"
                                rel="noreferrer"
                              />
                            ),
                          }}
                        />
                      }
                    </Typography>
                  </Box>
                  <Box marginTop={1} paddingBottom={9}>
                    <Typography variant="body1">
                      {
                        <Trans
                          i18nKey={`signup.bookAMeeting`}
                          components={{
                            1: (
                              <a
                                href="https://calendly.com/simongoertzen/powertools-demo"
                                target="_blank"
                                rel="noreferrer"
                              />
                            ),
                          }}
                        />
                      }
                    </Typography>
                  </Box>
                  <Box marginBottom={8} marginX="auto" maxWidth="40%">
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ fontSize: '1.5rem' }}
                      onClick={async () => {
                        await finishSignup()
                      }}
                    >
                      {t('signup.enterPowerTools')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogBox>
      </Box>
      <ClosableDialog open={creditCardDialogOpen} onClose={() => setCreditCardDialogOpen(false)}>
        <CreditCardPresenter
          onFinish={() => {
            setCreditCardDialogOpen(false)
            setActiveStep(SignupStepEnum.Finish)
          }}
        />
      </ClosableDialog>
    </AppLayout>
  )
}
