import { Box } from '@material-ui/core'
import { PricingBotPresenter } from 'components/domain/stockPricing/PricingSettings/PricingBotPresenter/PricingBotPresenter'
import { useHandleSettingsChange } from 'components/domain/stockPricing/StockPricingPresenter'
import { AppLayout } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const PricingBot = (): JSX.Element => {
  const { user } = useUser()
  if (user?.subscription.allFeaturesDisabled || user?.featureFlags?.banned) {
    return <Redirect to="/" />
  }

  const handleChange = useHandleSettingsChange()
  return (
    <AppLayout>
      <Box padding={3}>
        <PricingBotPresenter handleChange={handleChange} />
      </Box>
    </AppLayout>
  )
}
