import { Box, Dialog, Grid, makeStyles, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { getUserPickingLists } from 'lib/api'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PickingList } from 'shared'

interface SelectPickingListDialogProps {
  open: boolean
  onClose: () => void
}

export const SelectPickingListDialog = ({
  open,
  onClose,
}: SelectPickingListDialogProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()

  const [pickingLists, setPickingLists] = useState<PickingList[]>([])
  const [selectedPickingList, setSelectedPickingList] = useState<PickingList>()

  const useStyles = makeStyles(() => ({
    box: {
      backgroundColor: '#ececee',
      height: '80px',
      width: '80px',
      paddingTop: '25px',
      borderRadius: '10px',
      textAlign: 'center',
      cursor: 'pointer',
      filter: 'drop-shadow(0px 6px 4px grey)',
    },
  }))
  const styles = useStyles()

  const loadPickingLists = async () => {
    setPickingLists(await getUserPickingLists(user.cardmarketUsername, 'open'))
  }

  useEffect(() => {
    if (open) {
      loadPickingLists()
    }
  }, [open])

  const handleClickPickingList = (pl: PickingList) => {
    setSelectedPickingList(pl)
  }

  // const addOrdersToPickingList = async(orders: Order[], pickingListName: string) => {
  //   await
  // }

  // const onConfirm = () => {
  //   console.log(selectedPickingList)
  //   addOrdersToPickingList(orders, selectedPickingList?.name)
  // }

  const handleClose = () => {
    setSelectedPickingList(undefined)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <Box padding={2} width="40vw">
        <Grid container spacing={3}>
          {pickingLists.map((pl) => (
            <Grid key={pl.name} item>
              <Box className={styles.box} onClick={() => handleClickPickingList(pl)}>
                <Box>
                  <Typography variant="h6" color="primary">
                    {pl.name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box marginTop={1} style={{ float: 'right' }}>
          <Button variant="outlined" onClick={handleClose} style={{ marginRight: '10px' }}>
            {t('common.close')}
          </Button>
          <Button
            variant="contained"
            disabled={selectedPickingList ? false : true}
            // onClick={onConfirm}
          >
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
