import { Box, Container, makeStyles } from '@material-ui/core'
import { theme } from 'config'
import qs from 'query-string'
import { Redirect, useLocation } from 'react-router-dom'
import { ElementWithChildren } from 'types'

const useStyles = makeStyles(() => ({
  root: {
    background: 'linear-gradient(90deg, rgb(60, 56, 95) 0%, rgb(120, 115, 160) 70%)',
    [theme.breakpoints.down('xs')]: {
      padding: '10vh 1vh 10vh 1vh',
      // position: 'absolute',
    },
    padding: '5vh',
    minHeight: '100vh',
  },
}))

export const AuthLayout = ({ children }: ElementWithChildren): JSX.Element => {
  // const { isAuthenticated } = useUser()
  const isAuthenticated = false
  const location = useLocation()

  const { ref } = qs.parse(location.search)
  if (ref) localStorage.setItem('referralCode', String(ref))

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {isAuthenticated ? <Redirect to="/" /> : <Container>{children}</Container>}
    </Box>
  )
}
