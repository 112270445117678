import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { ClosableDialog } from 'components/controls/dialogs'
import { Button } from 'components/facets'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useErrorHandler } from '.'

export const useConfirm = (
  title: string,
  body: string,
  fn: () => unknown
): [() => void, () => JSX.Element] => {
  const [open, setOpen] = useState(false)
  const { handle } = useErrorHandler()
  const { t } = useTranslation()
  const handleClose = (): void => {
    setOpen(false)
  }

  const ConfirmDialog = (): JSX.Element => {
    return (
      <ClosableDialog
        maxWidth={'lg'}
        open={open}
        hideButton
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>

        {body && <DialogContent style={{ textAlign: 'center' }}>{body}</DialogContent>}

        <Box height="1rem"></Box>

        {
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClose()
              }}
            >
              {t('common.close')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                try {
                  fn()
                } catch (error) {
                  handle(error as Error)
                }
                handleClose()
              }}
              data-testid="confirm-delete-element-btn"
            >
              {t('common.confirm')}
            </Button>
          </DialogActions>
        }
      </ClosableDialog>
    )
  }
  const confirm = useCallback((): void => setOpen(true), [])
  return [confirm, ConfirmDialog]
}
