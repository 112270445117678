import { Box } from '@material-ui/core'
import { ArrowDownward } from '@material-ui/icons'
import { ImportMenuButton } from 'components/facets/ImportMenuButton'
import { useUser } from 'components/providers/UserProvider'
import { theme } from 'config'
import { useTranslation } from 'react-i18next'
import { PricingSettingsTabs } from 'shared'

export interface PricingSettingsMenuProps {
  onTabClick: (tabId: number) => void
}

export const PricingSettingsMenu = ({ onTabClick }: PricingSettingsMenuProps): JSX.Element => {
  const { user, activeGame } = useUser()

  if (!user) return <></>

  const { t } = useTranslation()

  return (
    <>
      <Box marginTop={4} marginBottom={1}>
        <ImportMenuButton
          color={theme.palette.strongViolet}
          title={`${activeGame.name} ${t('bulk.title')}`}
          content={t('bulk.infoNewStrategy')}
          onClick={() => onTabClick(PricingSettingsTabs.BulkSettings)}
          dataTestid="bulk-settings-btn"
          disabled={false}
        />
      </Box>
      <ArrowDownward fontSize="large" style={{ marginLeft: '45%' }} data-testid="down-arrow" />
      <Box marginBottom={1}>
        <ImportMenuButton
          color={theme.palette.strongViolet}
          title={`${t('competitor.yourCompetitors')}`}
          content={t('competitor.headline')}
          onClick={() => onTabClick(PricingSettingsTabs.Competitors)}
          dataTestid="competitors-btn"
          disabled={false}
        />
      </Box>
      <ArrowDownward fontSize="large" style={{ marginLeft: '45%' }} />
      <Box marginBottom={1}>
        <ImportMenuButton
          color={theme.palette.strongViolet}
          title={`${t('strategy.yourStrategies')}`}
          content={t('strategy.headline')}
          onClick={() => onTabClick(PricingSettingsTabs.Strategies)}
          dataTestid="pricing-strategies-btn"
          disabled={false}
        />
      </Box>
    </>
  )
}
