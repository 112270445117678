import { Box, Grid, Paper } from '@material-ui/core'
import productImageNotAvailable from 'img/productImageNotAvailable.png'
import { useEffect, useState } from 'react'
import { OrderArticle } from 'shared'
import { CardInfoBox } from './CardInfoBox'
import { PickingButton } from './PickingButton'

export interface OrderVisualArticleProps {
  article: OrderArticle
  hideBar?: boolean
  handlePick?: (article: OrderArticle, quantity: number) => void
  handleCompletePicking?: (article: OrderArticle, quantity: number) => void
  handleReportIssue?: (article: OrderArticle) => void
  handleBackToPicking?: (article: OrderArticle) => void
  horizontalView?: boolean
  type: string
  oneClickPick: boolean
}

export const OrderVisualArticle = ({
  article,
  hideBar = false,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
  handleBackToPicking,
  horizontalView,
  type,
  oneClickPick,
}: OrderVisualArticleProps): JSX.Element => {
  const articleWidth = 212
  const articleHeight = 310
  const imgSrc = article.idProduct
    ? `https://mtgpowertools.s3.eu-central-1.amazonaws.com/images/mtg/${article.idProduct}.jpg`
    : productImageNotAvailable
  hideBar

  const [hidePickingButton, setHidePickingButton] = useState<boolean>(oneClickPick)

  useEffect(() => {
    setHidePickingButton(oneClickPick)
  }, [oneClickPick])

  if (horizontalView) {
    return (
      <Box
        className="order-visual-article-container-horizontal"
        display="flex"
        flexDirection="row"
        component={Paper}
      >
        <Box
          className="order-visual-article-img-container-horizontal me-2"
          style={{ width: '36%' }}
        >
          <img src={imgSrc} />
        </Box>
        <Box
          style={{ width: '64%', paddingLeft: '8px' }}
          display="flex"
          flexDirection="column"
          justifyContent={'space-between'}
        >
          <div>
            {type == 'toPick' && (
              <Grid container>
                <Grid item xs={12}>
                  <PickingButton
                    type="reportIssue"
                    article={article}
                    handleReportIssue={handleReportIssue}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            <CardInfoBox article={article} />
          </div>
          {['picked', 'withIssues'].includes(type) && (
            <PickingButton
              type="backToPicking"
              article={article}
              handleBackToPicking={handleBackToPicking}
              fullWidth
            />
          )}
          {type === 'toPick' && (
            <Box>
              <PickingButton fullWidth type="pick" article={article} handlePick={handlePick} />
              <Box padding={0.5}></Box>

              {!hidePickingButton && (
                <PickingButton
                  type="put"
                  fullWidth
                  article={article}
                  handleCompletePicking={handleCompletePicking}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    )
  } else {
    return (
      <Box width={articleWidth} className="container" component={Paper} padding={1}>
        {type == 'toPick' && (
          <Grid container>
            <Grid item xs={12}>
              <PickingButton
                type="reportIssue"
                article={article}
                handleReportIssue={handleReportIssue}
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        <Box width="100%" paddingY={1} className="img">
          <img src={imgSrc} width="100%" height={articleHeight} />
        </Box>

        {type == 'toPick' && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <PickingButton type="pick" article={article} handlePick={handlePick} fullWidth />
              </Grid>
            </Grid>

            <Box padding="5px"></Box>

            <Grid container>
              <Grid item xs={12}>
                {!hidePickingButton && (
                  <PickingButton
                    type="put"
                    fullWidth
                    article={article}
                    handleCompletePicking={handleCompletePicking}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}

        {['picked', 'withIssues'].includes(type) && (
          <PickingButton
            type="backToPicking"
            article={article}
            handleBackToPicking={handleBackToPicking}
            fullWidth
          />
        )}

        <Box padding="5px"></Box>

        <CardInfoBox article={article} />
      </Box>
    )
  }
}
