import { Box, Collapse, Grid, IconButton, Paper, Switch, Typography } from '@material-ui/core'
import { CollapseIcon, CustomTooltip, ExpandIcon } from 'components/facets'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { RichArticle } from 'shared'
import { SetState } from 'types'
import { useExistingStockContext } from '../../useExistingStockContext'
import { ExistingArticle } from './ExistingArticle'
interface ExistingStockPresenterProps {
  article: RichArticle
  setInputs: SetState<RichArticle>
}

export const ExistingStockPresenter = ({
  article,
  setInputs,
}: ExistingStockPresenterProps): JSX.Element => {
  const { stockState, loadStock } = useExistingStockContext()

  const { t } = useTranslation()
  const [{ shouldAutofillExactMatch, isActive }, setListingSettings] = useLocalForageState<{
    shouldAutofillExactMatch: boolean
    isActive: boolean
  }>({ shouldAutofillExactMatch: true, isActive: false }, 'listingSettings')

  const fillCommentAndPrice = ({ comment, price }: { comment: string; price: number }): void => {
    const newInputs = article.copy()
    setTimeout(() => {
      newInputs.setAttribute('price', price)
      newInputs.setAttribute('comment', comment)
      setInputs(newInputs)
    }, 50)
  }

  const initialCommentAndPriceRef = useRef<{ comment: string; price: number } | null>(null)

  const foundArticles =
    stockState?.stock?.filter((stockArticle) => stockArticle.idProduct === article.idProduct) ?? []

  const exactMatchIndex = foundArticles.findIndex((foundArticle) =>
    foundArticle.isSimiliar(article)
  )

  useEffect(() => {
    if (!isActive) return
    if (!stockState) {
      loadStock()
    }
  }, [stockState, isActive])

  useEffect(() => {
    let timeout: any = null
    if (!isActive) return

    const exactMatchArticle = foundArticles[exactMatchIndex]
    timeout = setTimeout(() => {
      if (exactMatchArticle && shouldAutofillExactMatch) {
        if (!initialCommentAndPriceRef.current)
          initialCommentAndPriceRef.current = { comment: article.comment, price: article.price }
        fillCommentAndPrice(exactMatchArticle)
      } else if (initialCommentAndPriceRef.current) {
        fillCommentAndPrice(initialCommentAndPriceRef.current)
        initialCommentAndPriceRef.current = null
      }
    }, 250)

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [exactMatchIndex, shouldAutofillExactMatch, foundArticles.length, isActive])

  return (
    <Box>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() =>
                setListingSettings((settings) => ({ ...settings, isActive: !isActive }))
              }
            >
              {isActive ? <CollapseIcon /> : <ExpandIcon />}
            </IconButton>
            <Typography
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setListingSettings((settings) => ({ ...settings, isActive: !isActive }))
              }
              variant="h6"
            >
              Similar articles in your{' '}
              <span>
                <CustomTooltip
                  title={
                    <Typography>
                      {t('stockPricing.stockFetchedOn')} <b>{stockState?.fetchedAt}.</b>
                      <br />
                      {t('addArticles.existingStock.youCanRefreshIt')}
                    </Typography>
                  }
                >
                  <Typography variant="h6" component="span" color="primary">
                    <b>stock</b>
                  </Typography>
                </CustomTooltip>
              </span>
            </Typography>
          </Box>

          {isActive && (
            <Box display="flex" alignItems="center">
              {t('addArticles.existingStock.autofillValues')}
              <Switch
                data-testid="autofill-values-switch"
                color="primary"
                checked={shouldAutofillExactMatch}
                onChange={(e) =>
                  setListingSettings((settings) => ({
                    ...settings,
                    shouldAutofillExactMatch: e.target.checked,
                  }))
                }
              />
            </Box>
          )}
        </Box>

        <Collapse in={isActive}>
          {foundArticles.length ? (
            <Grid data-testid="found-articles-container" container>
              {foundArticles.map((article, index) => {
                return (
                  <Grid xs={4} item key={article.id}>
                    <Box margin={0.3}>
                      <CustomTooltip title={t('addArticles.existingStock.applyValues') as string}>
                        <Paper
                          elevation={index === exactMatchIndex ? 7 : 1}
                          style={{
                            cursor: 'pointer',
                            border: `1px solid ${index === exactMatchIndex ? 'blue' : 'white'}`,
                          }}
                          onClick={() => {
                            fillCommentAndPrice(article)
                          }}
                        >
                          <ExistingArticle hideTitle showPrice showComment article={article} />
                        </Paper>
                      </CustomTooltip>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Typography color="textSecondary">
              {t('addArticles.existingStock.noneFound')}
            </Typography>
          )}
        </Collapse>
      </Box>
    </Box>
  )
}
