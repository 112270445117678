import { Box, Paper, PaperProps, Typography } from '@material-ui/core'
import { Card, Condition } from 'components/facets'
import { CrossedOut } from 'components/facets/CrossedOut'
import { useUser } from 'components/providers/UserProvider'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import playsetIcon from 'img/playsetIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import Flag from 'react-world-flags'
import {
  AnyYesNo,
  CardConditionCondition,
  CardExtraAttribute,
  CardExtraCondition,
  CardLanguage,
  CardNameCondition,
  CommentCondition,
  ConditionEnum,
  ConditionTypesEnum,
  ExpansionCondition,
  LanguageCondition,
  PriceCondition,
  PriceGuideCondition,
  PricingPlanNodeCondition,
  QuantityCondition,
  RarityCondition,
} from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'

export interface ConditionPresenterProps {
  condition: PricingPlanNodeCondition
  paperProps?: PaperProps
  maxHeight?: number
  displayType?: boolean
}

const PriceGuideConditionPresenter = ({
  condition,
}: {
  condition: PriceGuideCondition
}): JSX.Element => {
  let text = ''

  if (typeof condition.value.min === 'number' && typeof condition.value.max === 'number')
    text = `${condition.value?.metric} between ${condition.value?.min} and ${condition.value?.max}`
  else if (typeof condition.value.min === 'number') {
    text = `${condition.value?.metric} above ${condition.value?.min}`
  } else if (typeof condition.value.max === 'number') {
    text = `${condition.value?.metric} below ${condition.value?.max}`
  }

  return <Typography variant="body2">{text}</Typography>
}
const PriceConditionPresenter = ({ condition }: { condition: PriceCondition }): JSX.Element => {
  let text = ''

  if (typeof condition.value.min === 'number' && typeof condition.value.max === 'number')
    text = `between ${condition.value?.min} and ${condition.value?.max}`
  else if (typeof condition.value.min === 'number') {
    text = `above ${condition.value?.min}`
  } else if (typeof condition.value.max === 'number') {
    text = `below ${condition.value?.max}`
  }

  return <Typography variant="body2">{text}</Typography>
}

const LanguageConditionPresenter = ({
  condition,
}: {
  condition: LanguageCondition
}): JSX.Element => {
  return (
    <>
      <Typography>
        {Array.isArray(condition.value) &&
          condition.value?.map((idLanguage) => (
            <Flag
              key={idLanguage}
              style={{ padding: '0px 5px' }}
              //@ts-ignore
              fallback={<span>Unknown</span>}
              height="12"
              data-testid={`${CardLanguage[idLanguage]}-flag`}
              code={LANGUAGE_MAP_ICONS[idLanguage] || 'gg'}
            />
          ))}
      </Typography>
    </>
  )
}
const ExpansionConditionPresenter = ({
  condition,
}: {
  condition: ExpansionCondition
}): JSX.Element => {
  if (!Array.isArray(condition.value)) return <></>

  return (
    <>
      {condition.value?.map(({ name }) => (
        <Typography style={{ display: 'block' }} key={name} variant="caption">
          {name}
        </Typography>
      ))}
    </>
  )
}

const CardnameConditionPresenter = ({
  condition,
}: {
  condition: CardNameCondition
}): JSX.Element => {
  if (!Array.isArray(condition.value)) return <></>

  return (
    <>
      {condition.value?.map((card) => (
        <Typography
          key={card._id}
          component={'span'}
          style={{ display: 'inline-block', marginRight: '4px' }}
          variant="caption"
        >
          <Card key={card._id} card={card} />
        </Typography>
      ))}
    </>
  )
}

const CommentConditionPresenter = ({ condition }: { condition: CommentCondition }): JSX.Element => {
  if (typeof condition.value !== 'string') return <></>
  return <>{condition.value}</>
}

const extrasIcons = {
  [CardExtraAttribute.Foil]: foilIcon,
  [CardExtraAttribute.FirstEd]: firstEdIcon,
  [CardExtraAttribute.Signed]: signedIcon,
  [CardExtraAttribute.Playset]: playsetIcon,
  [CardExtraAttribute.ReverseHolo]: reverseHoloIcon,
}

const CardExtraConditionPresenter = ({
  condition,
}: {
  condition: CardExtraCondition
}): JSX.Element => {
  return (
    <Box display="flex">
      {Object.keys(condition.value)
        .filter((attribute) => condition.value[attribute as CardExtraAttribute] !== AnyYesNo.Any)
        .map((attribute) => (
          <Box paddingRight={1} key={attribute as CardExtraAttribute}>
            {condition.value[attribute as CardExtraAttribute] ? (
              <img
                alt={attribute}
                src={extrasIcons[attribute as CardExtraAttribute]}
                style={{ width: '18px', height: '18px' }}
              />
            ) : (
              <CrossedOut>
                <img
                  alt={attribute}
                  src={extrasIcons[attribute as CardExtraAttribute]}
                  style={{ width: '18px', height: '18px' }}
                />
              </CrossedOut>
            )}
          </Box>
        ))}
    </Box>
  )
}

const CardConditionConditionPresenter = ({
  condition,
}: {
  condition: CardConditionCondition
}): JSX.Element => {
  if (condition.value.min === condition.value.max)
    return (
      <Typography>
        <Condition value={ConditionEnum[condition.value.min]} />
      </Typography>
    )
  else {
    return (
      <Typography>
        <Condition value={ConditionEnum[condition.value.min]} />
        -
        <Condition value={ConditionEnum[condition.value.max]} />
      </Typography>
    )
  }
}
const QuantityConditionPresenter = ({
  condition,
}: {
  condition: QuantityCondition
}): JSX.Element => {
  if (condition.value.min > 1 && condition.value.max < 24) {
    return (
      <Typography>
        {condition.value.min}
        {' - '}
        {condition.value.max}{' '}
      </Typography>
    )
  } else if (condition.value.min === condition.value.max)
    return <Typography>={condition.value.min}</Typography>
  else if (condition.value.min > 1) {
    return (
      <Typography>
        {'>='}
        {condition.value.min}
      </Typography>
    )
  } else if (condition.value.max < 24) {
    return (
      <Typography>
        {'<='}
        {condition.value.max}
      </Typography>
    )
  } else {
    return <Typography>...</Typography>
  }
}

const RarityConditionPresenter = ({ condition }: { condition: RarityCondition }): JSX.Element => {
  const { activeGame } = useUser()

  const numberOfRarities = Object.keys(activeGame.rarities).length

  if (condition.value.min === condition.value.max) {
    return <Typography>={activeGame.rarities[condition.value.min]?.name}</Typography>
  } else if (condition.value.min > 1 && condition.value.max < numberOfRarities)
    return (
      <Typography>
        {activeGame.rarities[condition.value.min]?.name}
        {' - '}
        {activeGame.rarities[condition.value.max]?.name}
      </Typography>
    )
  else if (condition.value.min > 1) {
    return (
      <Typography>
        {'>='}
        {activeGame.rarities[condition.value.min]?.name}
      </Typography>
    )
  } else if (condition.value.max < numberOfRarities) {
    return (
      <Typography>
        {'<='}
        {activeGame.rarities[condition.value.max]?.name}
      </Typography>
    )
  } else {
    return <Typography>...</Typography>
  }
}

export const ConditionPresenter = ({
  condition,
  paperProps,
  displayType = true,
}: ConditionPresenterProps): JSX.Element => {
  return (
    <Box maxHeight="inherit">
      <Paper elevation={2} {...paperProps}>
        <Box>
          {displayType && (
            <Box paddingLeft={0.5} paddingRight={0.5}>
              <Typography variant="overline" color={condition.negated ? 'error' : 'primary'}>
                {condition.type} {condition.negated ? ' - not' : ''}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" marginTop="-8px">
            <Box
              height="3rem"
              display="flex"
              padding={1}
              paddingTop={displayType ? 0 : 1}
              overflow="auto"
              width="12rem"
            >
              <Box margin="auto" width="100%">
                {
                  {
                    [ConditionTypesEnum.PriceGuide]: (
                      <PriceGuideConditionPresenter condition={condition as PriceGuideCondition} />
                    ),
                    [ConditionTypesEnum.Printing]: (
                      <CardnameConditionPresenter condition={condition as CardNameCondition} />
                    ),
                    [ConditionTypesEnum.Expansion]: (
                      <ExpansionConditionPresenter condition={condition as ExpansionCondition} />
                    ),

                    [ConditionTypesEnum.Language]: (
                      <LanguageConditionPresenter condition={condition as LanguageCondition} />
                    ),
                    [ConditionTypesEnum.CardExtra]: (
                      <CardExtraConditionPresenter condition={condition as CardExtraCondition} />
                    ),
                    [ConditionTypesEnum.CardCondition]: (
                      <CardConditionConditionPresenter
                        condition={condition as CardConditionCondition}
                      />
                    ),
                    [ConditionTypesEnum.Comment]: (
                      <CommentConditionPresenter condition={condition as CommentCondition} />
                    ),
                    [ConditionTypesEnum.Quantity]: (
                      <QuantityConditionPresenter condition={condition as QuantityCondition} />
                    ),
                    [ConditionTypesEnum.Rarity]: (
                      <RarityConditionPresenter condition={condition as RarityCondition} />
                    ),
                    [ConditionTypesEnum.Price]: (
                      <PriceConditionPresenter condition={condition as PriceCondition} />
                    ),
                  }[condition.type]
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
