import Box from '@material-ui/core/Box'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

export type CircularLoadingProps = CircularProgressProps & { value?: number }

export const CircularLoading = ({ value, ...props }: CircularLoadingProps): JSX.Element => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size={100} thickness={5.5} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {typeof value === 'number' && (
          <Typography variant="h6" color="textSecondary">{`${Math.round(value)}%`}</Typography>
        )}
      </Box>
    </Box>
  )
}
