import {
  Box,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { Button } from 'components/facets'
import { useOrderStore } from 'lib/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIssues, Order, OrderArticle } from 'shared'

interface ReportIssueDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (problem: string) => void
  type: string
  order: Order | undefined
  editMode?: boolean
}

export const ReportIssueDialog = ({
  open,
  onClose,
  onConfirm,
  type,
  order,
  editMode = false,
}: ReportIssueDialogProps): JSX.Element => {
  const { t } = useTranslation()

  const selectedArticle = useOrderStore((state) => state.selectedArticle)

  const articleIssueTypes = [
    'missingArticle',
    'wrongCondition',
    'wrongLanguage',
    'wrongFoil',
    'wrongFirstEd',
    'other',
  ]
  const [issueKey, setIssueKey] = useState<string>(articleIssueTypes[0])
  const [issueText, setIssueText] = useState<string | undefined>(
    t(`orders.issueTypes.${articleIssueTypes[0]}`)
  )
  const orderIssueTypes: string[] = []

  const handleChangeSelect = (e: any) => {
    setIssueKey(e.target.value)
    setIssueText(t(`orders.issueTypes.${e.target.value}`))
  }

  const handleChangeTextField = (e: any) => {
    setIssueText(e.target.value)
  }

  useEffect(() => {
    if (editMode) {
      setIssueText(order?.pt.issues?.[0].description)
    }
  }, [editMode])

  const getArticleIssueLabel = (article: OrderArticle | undefined) => {
    let articleIssueLabel = ''
    if (article) {
      if (article.product.expansion) {
        articleIssueLabel = `"${article.product.enName}" from "${article.product.expansion}"`
      } else {
        articleIssueLabel = article.product.enName
      }
    }
    return articleIssueLabel
  }

  const showCurrentIssues = () => {
    if (type === 'article') {
      return (
        <>
          <Box padding={1} textAlign="center">
            <Typography variant="body1">Current issues</Typography>
          </Box>
          {selectedArticle?.issues[0].description}
        </>
      )
    } else if (type === 'order') {
      const articleIssues: string[] = []
      order?.pt.article.forEach((pta) => {
        const article = order.article.find((a) => a.idArticle === pta.idArticle)
        pta.issues?.forEach((i) => {
          const articleLabel = getArticleIssueLabel(article)
          articleIssues.push(`${articleLabel}: ${i.description}`)
        })
      })

      return (
        <>
          <Box padding={1} textAlign="center">
            <Typography variant="body1">Current issues</Typography>
          </Box>
          {!editMode ? (
            <>
              {order?.pt.issues.map((i) => (
                <Box key={i.description}>
                  {i.description}
                  {i.solved ? ` (SOLVED: ${i.solution})` : ''}
                </Box>
              ))}
              {articleIssues.map((i) => (
                <Box key={i}>{i}</Box>
              ))}
            </>
          ) : (
            <>
              <TextField
                fullWidth
                variant="outlined"
                value={issueText}
                onChange={handleChangeTextField}
              ></TextField>
            </>
          )}
        </>
      )
    }
  }

  const getDialogBody = () => {
    if (type === 'article') {
      return (
        <>
          <Box display="flex">
            <RadioGroup value={issueKey} onChange={handleChangeSelect}>
              {articleIssueTypes.map((p) => {
                return (
                  <FormControlLabel
                    key={p}
                    value={p}
                    control={<Radio />}
                    label={t(`orders.issueTypes.${p}`)}
                  />
                )
              })}
            </RadioGroup>
          </Box>
          {issueKey === 'other' && (
            <Box display="flex">
              <TextField fullWidth variant="outlined" onChange={handleChangeTextField}></TextField>
            </Box>
          )}
        </>
      )
    } else if (type === 'order') {
      return (
        <>
          <Box display="flex">
            <RadioGroup value={issueKey} onChange={handleChangeSelect}>
              {orderIssueTypes.map((p) => {
                return (
                  <FormControlLabel
                    key={p}
                    value={p}
                    control={<Radio />}
                    label={t(`orders.issueTypes.${p}`)}
                  />
                )
              })}
            </RadioGroup>
          </Box>
          <Box display="flex">
            <TextField fullWidth variant="outlined" onChange={handleChangeTextField} />
          </Box>
        </>
      )
    }
  }

  const resetFields = () => {
    setIssueKey('')
    setIssueText('')
  }

  const handleClose = () => {
    resetFields()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <Box padding={4} width="30vw">
        {(order && getIssues(order).length > 0) ||
        (selectedArticle && selectedArticle.issues.length > 0) ? (
          showCurrentIssues()
        ) : (
          <>
            <Box marginBottom={3}>
              <Typography variant="h6">{t('orders.reportDialogTitle')}</Typography>
            </Box>
            {getDialogBody()}
          </>
        )}
        <Box marginTop={4} style={{ float: 'right' }}>
          <Button variant="outlined" onClick={handleClose} style={{ marginRight: '15px' }}>
            {t('common.close')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              resetFields()
              if (issueText) {
                onConfirm(issueText)
              }
            }}
          >
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
