export interface CancellationToken {
  cancelled: boolean
  cancel: () => void
}

export const newCancellationToken = (): CancellationToken => {
  const token = {
    cancelled: false,
    cancel: () => {
      console.log('Nothing to cancel')
    },
  }
  token.cancel = () => {
    token.cancelled = true
  }
  return token
}
