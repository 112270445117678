import { CardLanguage } from 'shared'

export const LANGUAGE_MAP_ICONS: Record<string, string> = {
  [CardLanguage.English]: 'gb',
  [CardLanguage.Spanish]: 'es',
  [CardLanguage.French]: 'fr',
  [CardLanguage.German]: 'de',
  [CardLanguage.Italian]: 'it',
  [CardLanguage.Japanese]: 'jp',
  [CardLanguage.Portuguese]: 'pt',
  [CardLanguage.Russian]: 'ru',
  [CardLanguage.Korean]: 'kr',
  [CardLanguage['T-Chinese']]: 'tw',
  [CardLanguage['S-Chinese']]: 'cn',
  [CardLanguage.Dutch]: 'nl',
  //Polish: 'pl',
}

export const LANGUAGES: Record<string, string>[] = [
  { name: 'English' },
  { name: 'German' },
  { name: 'French' },
  { name: 'Spanish' },
  { name: 'Italian' },
  { name: 'Russian' },
  { name: 'Japanese' },
  { name: 'Portuguese' },
  { name: 'Korean' },
  { name: 'T-Chinese' },
  { name: 'S-Chinese' },
  //{ name: 'Polish' },
  { name: 'Dutch' },
]

export const APP_LANGUAGES = [
  { name: 'English', value: 'en' },
  { name: 'German', value: 'de' },
  { name: 'French', value: 'fr' },
  { name: 'Spanish', value: 'es' },
  { name: 'Italian', value: 'it' },
]

export const APP_CURRENCY = [
  { code: 'EUR', symbol: '€' },
  // { code: 'USD', symbol: '$' },
  { code: 'GBP', symbol: '£' },
].sort((a, b) => a.code.localeCompare(b.code))

export const CONDITIONS = [
  { name: 'MT' },
  { name: 'NM' },
  { name: 'EX' },
  { name: 'GD' },
  { name: 'LP' },
  { name: 'PL' },
  { name: 'PO' },
]

export const CONDITIONS_MAP = {
  0: 'MT',
  1: 'NM',
  2: 'EX',
  3: 'GD',
  4: 'LP',
  5: 'PL',
  6: 'PO',
}

export const CONDITIONS_LOOKUP = {
  MT: 0,
  NM: 1,
  EX: 2,
  GD: 3,
  LP: 4,
  PL: 5,
  PO: 6,
}

export const RARITY_FILTER_MAP = {
  0: 'Tkn',
  1: 'Land',
  2: 'C',
  3: 'U',
  4: 'R',
  5: 'M',
}
export const ERROR_REQUIRED = 'Required!'

export const COUNTRIES = [
  { name: 'Austria', code: 'AT' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Germany', code: 'D' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Spain', code: 'ES' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Greece', code: 'GR' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Italy', code: 'IT' },
  { name: 'Lichtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Norway', code: 'NO' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russia', code: 'RU' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Ukraine', code: 'UA' },
].sort((a, b) => a.name.localeCompare(b.name))
