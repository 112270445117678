import {
  ButtonProps,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'
import { Button } from 'components/facets'
import { ReactNode, useState } from 'react'
import { ClosableDialog } from './ClosableDialog'

interface ButtonDialogProps {
  label: string
  title: string
  content: (() => JSX.Element) | JSX.Element
  actions: (handleClose: () => void) => ReactNode
  buttonProps?: ButtonProps
  maxWidth?: DialogProps['maxWidth']
  renderComponent: (label: string, handleClose: () => void) => JSX.Element
}

export const ButtonDialog = ({
  label,
  title,
  content,
  actions,
  buttonProps,
  maxWidth = 'md',
  renderComponent,
}: ButtonDialogProps): JSX.Element => {
  const [open, setOpen] = useState(false)

  const handleClose = (): void => {
    setOpen(false)
  }

  const VisibleComponent = (): JSX.Element =>
    renderComponent ? (
      renderComponent(label, () => setOpen(true))
    ) : (
      <Button color={'primary'} onClick={() => setOpen(true)} {...buttonProps}>
        {label}
      </Button>
    )

  return (
    <>
      <VisibleComponent />
      {open && (
        <ClosableDialog
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>{typeof content === 'function' ? content() : content}</DialogContent>
          <DialogActions>{actions && actions(handleClose)}</DialogActions>
        </ClosableDialog>
      )}
    </>
  )
}
