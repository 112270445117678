import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Popper,
} from '@material-ui/core'
import { GoIcon } from 'components/facets'
import { useScopelessHotkeys } from 'lib/hooks'
import { useState } from 'react'
import { SetState } from 'types'

interface TextfieldPopperProps {
  anchorEl: HTMLElement
  setAnchorEl: SetState<HTMLElement | null>
  onSubmit: (value: string) => void
  name: string
  type?: 'input' | 'autocomplete'
  placeholder: string
  options?: { label: string; value: string }[]
}

export const TextfieldPopper = ({
  anchorEl,
  setAnchorEl,
  onSubmit,
  name,
  placeholder = '',
}: TextfieldPopperProps): JSX.Element => {
  const [inputValue, setInputValue] = useState('')

  useScopelessHotkeys(
    'enter',
    () => {
      if (inputValue) onSubmit(inputValue)
    },
    [inputValue]
  )

  const EndAdornment = (): JSX.Element => (
    <InputAdornment position="end">
      <IconButton
        disabled={!inputValue}
        color="primary"
        data-testid="bulk-edit-btn"
        onClick={() => onSubmit(inputValue)}
        edge="end"
      >
        {<GoIcon />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <ClickAwayListener
      disableReactTree={true}
      onClickAway={() => {
        setInputValue('')
        setAnchorEl(null)
      }}
    >
      <Popper open={!!anchorEl} anchorEl={anchorEl} transition>
        <Box>
          <Paper>
            <OutlinedInput
              autoFocus
              name={name}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={(event) => {
                event.target.select()
              }}
              placeholder={placeholder}
              endAdornment={<EndAdornment />}
            />
          </Paper>
        </Box>
      </Popper>
    </ClickAwayListener>
  )
}
