import { Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { CardDataItem } from 'shared'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
    padding: theme.spacing(1),
    zIndex: 1000000,
  },
  text: {
    color: theme.palette.primary.main,
  },
}))

export const Card = ({ card }: { card: CardDataItem }): JSX.Element => {
  const { name, _id, set } = card
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

  const imgsrc = `https://mtgpowertools.s3.eu-central-1.amazonaws.com/images/mtg/${_id}.jpg`

  const handlePopoverOpen = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.text}
        variant="inherit"
      >
        {name} {set}
      </Typography>
      <Popper id="mouse-over-popover" className={classes.popover} open={open} anchorEl={anchorEl}>
        <img alt={name} height="300px" src={imgsrc} />
      </Popper>
    </div>
  )
}
