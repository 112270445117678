import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Button } from 'components/facets/Button'
import { useUser } from 'components/providers/UserProvider'
import { cleanQueue, drainQueue } from 'lib/api'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'

export const AdminPanel = (): JSX.Element => {
  const { user } = useUser()

  const [queue, setQueue] = useState('')
  const [operation, setOperation] = useState('')
  const [grace, setGrace] = useState(1)
  const [limit, setLimit] = useState(1)
  const [type, setType] = useState('')
  const [password, setPassword] = useState('')

  const QUEUES = ['', 'networker', 'processor', 'articleFetcher', 'productFetcher']
  const OPERATIONS = ['', 'clean', 'drain']
  const TYPES = ['', 'failed', 'active', 'paused', 'completed', 'wait']

  if (!user.isAdmin) {
    return <Redirect to="/" />
  }

  const handleExecute = async () => {
    if (queue === '') {
      return
    }
    if (operation === 'clean') {
      await cleanQueue(queue, grace, limit, type, password)
    } else if (operation === 'drain') {
      await drainQueue(queue, password)
    }
  }

  const handleReset = () => {
    setQueue('')
    setOperation('')
    setGrace(1)
    setLimit(1)
    setType('')
  }

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Queues</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Queue</InputLabel>
            <Select onChange={(e: any) => setQueue(e.target.value)}>
              {QUEUES.map((queue) => (
                <MenuItem key={queue} value={queue}>
                  {queue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Operation</InputLabel>
            <Select onChange={(e: any) => setOperation(e.target.value)}>
              {OPERATIONS.map((operation) => (
                <MenuItem key={operation} value={operation}>
                  {operation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {operation === 'clean' && (
          <>
            <Grid item xs={6}>
              <TextField
                label="Grace period"
                value={grace}
                variant="outlined"
                fullWidth
                onChange={(e: any) => setGrace(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Max number of jobs to clean"
                value={limit}
                variant="outlined"
                fullWidth
                onChange={(e: any) => setLimit(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Status</InputLabel>
                <Select label="Type" onChange={(e: any) => setType(e.target.value)}>
                  {TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <TextField
            label="Password"
            value={password}
            variant="outlined"
            fullWidth
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={handleExecute}>
            Execute
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={handleReset}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
