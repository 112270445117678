import { Box, makeStyles } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

export interface CreditCardPresenterProps {
  onFinish?: () => void
}

const useStyles = makeStyles(() => ({
  root: {
    '& .o--Widget--widget': {
      maxWidth: '45vw',
      overflow: 'hidden',
    },
    '& .o--Profile--profile-photo': {
      display: 'none',
    },
    '& .o--NavDesktop--navDesktop': {
      display: 'none!important',
    },
    '& h1': {
      display: 'none!important',
    },
  },
}))

export const CreditCardPresenter = ({ onFinish }: CreditCardPresenterProps): JSX.Element => {
  const styles = useStyles()

  const { fetchUser, user } = useUser()

  useEffect(() => {
    const polling = setInterval(() => {
      fetchUser()
    }, 2000)

    return () => {
      clearInterval(polling)
    }
  }, [])

  useEffect(() => {
    if (user?.outseta?.hasCreditCard) {
      onFinish?.()
    }
  }, [user?.outseta?.hasCreditCard])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cdn.outseta.com/outseta.min.js'
    //@ts-ignore
    script['data-o-options'] = 'o_options'

    document.body.appendChild(script)

    return () => {
      //@ts-ignore
      if (window.Outseta) window.Outseta = null
      document.body.removeChild(script)
      return
    }
  }, [])

  //@ts-ignore
  window.o_options = {
    id: 'Outseta',
    domain: 'mtg-powertools.outseta.com',
    load: 'profile',
    tokenStorage: 'local',
    profile: {
      id: `profile_embed-${uuid()}`,
      mode: 'embed',
      tab: 'billing',
      selector: '#profile-embed',
    },
  }

  return (
    <Box className={styles.root} marginTop={1.5}>
      <div id="profile-embed"></div>
    </Box>
  )
}
