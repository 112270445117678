import { Box, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { OrdersWelcomeTable } from './OrdersWelcomeTable'

export const OrdersWelcome = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Box paddingTop={18}>
        <Grid container>
          <Grid item sm={12} md={5}>
            <Typography variant="h4" color="primary">
              {t('orders.welcome')}
            </Typography>
            <Box padding={2} />
            <Typography>{t('orders.welcomeBody1')}</Typography>
            <Box padding={1} />
            <Typography>{t('orders.welcomeBody2')}</Typography>

            <ul>
              <li>
                <Typography color="primary">{t('orders.welcomeBullet1')}</Typography>
              </li>
              <li>
                <Typography color="primary">{t('orders.welcomeBullet2')}</Typography>
              </li>
              <li>
                <Typography color="primary">{t('orders.welcomeBullet3')}</Typography>
              </li>
              <li>
                <Typography color="primary">{t('orders.welcomeBullet4')}</Typography>
              </li>
            </ul>

            <Typography>{t('orders.welcomeBody3')}</Typography>
          </Grid>
          <Grid item sm={12} md={7}>
            <OrdersWelcomeTable />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
