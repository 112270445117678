import { Box, Button, Grid, Typography } from '@material-ui/core'
import { AddIcon } from 'components/facets'
import { SubTitle } from 'components/facets/SubTitle'
import { useTranslation } from 'react-i18next'
import { Competitor } from 'shared'
import { CompetitorBox } from '../CompetitorBox'
import { EntityBox } from '../EntityBox/EntityBox'
export interface CompetitorPresenterProps {
  competitors: Array<Competitor>
  onDelete: (id: string) => void
  onCreate: () => void
  onChange: (newValue: Competitor) => void
  onClone: (newValue: Competitor) => void
  disabled: boolean
}

interface AddCompetitorBoxProps {
  onCreate: () => void
  disabled?: boolean
}

export const AddCompetitorBox = ({
  onCreate,
  disabled = false,
}: AddCompetitorBoxProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Box
      onClick={onCreate}
      data-testid="add-new-competitor-btn"
      style={{ cursor: 'pointer', pointerEvents: disabled ? 'none' : 'initial' }}
    >
      <EntityBox
        competitorOrStrategy
        titleRow={<wbr />}
        contentRow={
          <Button disabled={disabled} color="primary">
            <AddIcon />
            <Box marginLeft={1}>
              <Typography variant="body2">{t('competitor.addNew')}</Typography>
            </Box>
          </Button>
        }
      />
    </Box>
  )
}

export const CompetitorPresenter = ({
  competitors,
  onDelete,
  onCreate,
  onChange,
  disabled,
  onClone,
}: CompetitorPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Box>
      <Box>
        <SubTitle>{t('competitor.yourCompetitors')}</SubTitle>
      </Box>
      <Typography gutterBottom variant="body2">
        {t('competitor.descriptionText')}
      </Typography>
      {disabled && (
        <Typography variant="body2" data-testid="tooltip-free-user-competitor">
          <b>{t('subscriptionUpgradeNeededNew')}</b>
        </Typography>
      )}
      <Box>
        <Grid container>
          {competitors.map((competitor) => (
            <Grid
              xs={6}
              key={competitor._id}
              item
              data-testid={`competitor-box-${competitor.name}`}
            >
              <CompetitorBox
                competitor={competitor}
                onDelete={disabled ? undefined : onDelete}
                onChange={disabled ? undefined : onChange}
                disabled={disabled}
                onClone={disabled ? undefined : onClone}
              />
            </Grid>
          ))}
          <Grid xs={6} key={'new'} item>
            <AddCompetitorBox onCreate={onCreate} disabled={disabled} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
