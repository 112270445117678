/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Slider,
  SliderProps,
  TextField,
  TextFieldProps,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { CustomTooltip } from 'components/facets'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AnyYesNo } from 'shared'

const useStyles = makeStyles((theme) =>
  createStyles({
    boldLabel: {
      '&.MuiFormLabel-filled': {
        color: theme.palette.active + '!important',
        fontWeight: 'bold',
      },
      '&.Mui-focused': {
        color: theme.palette.active,
      },
    },
    selectFilterActive: {
      fontWeight: 'bold',
      borderBottom: '2px solid' + theme.palette.active,
      '& input:before': {
        borderBottom: 'none',
      },
      '& MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
      '& :before': {
        borderBottom: 'none',
      },
    },
    selectFilter: {
      borderBottom: '1px solid' + theme.palette.MUILightGray,
    },
    underline: {
      '&:after': {
        borderBottom: '2px solid' + theme.palette.active,
      },
      '&:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-input:empty': {
        borderBottom: '1px solid ' + theme.palette.MUILightGray + '!important',
      },
      '& .MuiInput-input:not([value=""])': {
        borderBottom: '2px solid ' + theme.palette.active + '!important',
        borderBottomColor: theme.palette.active + '!important',
      },
    },
    underlineTextArea: {
      '&.MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '&.MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '&:before': {
        borderBottom: 'none !important',
      },

      '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },

      borderBottom: '2px solid' + theme.palette.active,
    },

    focused: {
      '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid' + theme.palette.active,
      },
      '&.MuiInput-underline.Mui-focused:after': {
        borderBottom: '2px solid' + theme.palette.active,
      },
    },

    sliderActiveFilter: {
      color: theme.palette.active,
    },
    sliderFilter: {
      color: theme.palette.primary.main,
    },
  })
)

interface FilterProps {
  type?: 'slider' | 'switchText' | 'select'
  label: string
  name: string
  inputProps?: TextFieldProps
  sliderProps?: SliderProps
  defaultValue?: any
  grid?: boolean
  menuitems?: JSX.Element[]
  setFilter: (name: string, newValue: any) => void
  activeFilter?: boolean
}

export const Filter = ({
  label,
  name,
  type,
  inputProps,
  menuitems,
  sliderProps,
  defaultValue,
  grid,
  setFilter,
  activeFilter,
}: FilterProps): JSX.Element => {
  const getInitialState = (): any => {
    if (defaultValue !== null && defaultValue !== undefined) {
    }
    return defaultValue
  }

  const classes = useStyles()
  const [value, setValue] = useState(getInitialState())
  const [loaded, setLoaded] = useState(false)
  const [includes, setIncludes] = useState(true)
  const { t } = useTranslation()

  const handleChange = (newValue: any): void => {
    setValue(newValue)
  }

  useEffect(() => {
    const filterValue = includes ? value : `!${value}`
    const handler = setTimeout(() => {
      if (loaded) {
        setFilter(name, filterValue)
      } else {
        setLoaded(true)
      }
    }, 400)
    return () => {
      clearTimeout(handler)
    }
  }, [value, includes])

  const InputComponent = (): JSX.Element => {
    if (type === 'slider')
      return (
        <>
          <InputLabel className={activeFilter ? classes.sliderActiveFilter : ''}>
            {label}
          </InputLabel>

          <Slider
            value={value}
            data-testid={`${name}-slider`}
            onChange={(_e, newValue) => handleChange(newValue)}
            valueLabelDisplay={'auto'}
            {...sliderProps}
            classes={
              activeFilter ? { root: classes.sliderActiveFilter } : { root: classes.sliderFilter }
            }
          />
        </>
      )
    else if (type === 'select')
      return (
        <FormControl fullWidth>
          <InputLabel
            classes={`${value}` !== AnyYesNo.Any ? { root: classes.boldLabel } : { root: '' }}
          >
            {label}
          </InputLabel>
          <Select
            name={name}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            classes={
              `${value}` !== AnyYesNo.Any
                ? { root: classes.selectFilterActive }
                : { root: classes.selectFilter }
            }
          >
            {menuitems?.map((menuItem) => menuItem)}
          </Select>
        </FormControl>
      )
    else if (type === 'switchText')
      return (
        <Grid container>
          <Grid item xs={10}>
            <TextField
              fullWidth
              multiline={true}
              label={label}
              name={name}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              {...inputProps}
              InputProps={{
                style: { fontWeight: 'bold', minHeight: value ? 'initial' : '32px' },
                classes: {
                  root: value ? classes.underlineTextArea : classes.focused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.boldLabel,
                },
              }}
            />
          </Grid>
          <Grid xs={2} item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <CustomTooltip title={String(includes ? t('includes') : t('doesNotInclude'))}>
              <IconButton
                data-testid={`${name}-includes`}
                onClick={() => setIncludes(!includes)}
                size="small"
              >
                {includes ? <CheckIcon /> : <ClearIcon />}
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>
      )
    return (
      <TextField
        fullWidth
        label={label}
        //multiline={true}
        name={name}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        {...inputProps}
        InputProps={{
          style: { fontWeight: 'bold' },
          classes: {
            root: classes.underline,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.boldLabel,
          },
        }}
      />
    )
  }
  if (!grid) return <Box>{InputComponent()}</Box>
  else {
    return (
      <Grid item xs={6}>
        <Box>{InputComponent()}</Box>
      </Grid>
    )
  }
}
