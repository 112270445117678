import { Box } from '@material-ui/core'
import { LoadingScreen } from 'components/facets/LoadingScreen'
import i18n from 'config/i18n'
import Cookie from 'js-cookie'
import { getUser } from 'lib/api'
import { useAppContext } from 'lib/hooks'
import LogRocket from 'logrocket'
import React, { Dispatch, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useLocalStorage from 'react-use-localstorage'
import { AppUser, Game, gameMetaData, IdGame } from 'shared'
import { ElementWithChildren, SetState } from 'types'

interface UserContextValue {
  user: AppUser
  setUser: SetState<AppUser>
  fetchUser: () => Promise<void>
  activeGame: Game
  setActiveGame: Dispatch<string>
  ReactPixel: any
}

const UserContext = React.createContext<UserContextValue>({
  //@ts-ignore
  user: null,
  setUser: () => undefined,
  fetchUser: async () => undefined,
  activeGame: gameMetaData[0],
  ReactPixel: null,
  setActiveGame: () => undefined,
})

export const useUser = (): UserContextValue => {
  const value = useContext(UserContext)

  if (!value) throw new Error('useUser was called outside of priceguide context provider')
  if (!value.user) throw new Error('useUser was called when user was not loaded')

  return value
}

export const UserContextProvider = ({ children }: ElementWithChildren): JSX.Element => {
  const [user, setUser] = useState<AppUser | null>(null)
  const history = useHistory()
  const { setAuthenticated, setShouldRedirectToNew } = useAppContext()

  const [activeGameId, setActiveGame] = useLocalStorage('activeGameId', String(IdGame.Magic))

  const activeGame: Game =
    gameMetaData.find(({ idGame }) => idGame === (Number(activeGameId) || 1)) || gameMetaData[0]

  const fetchUser = async (): Promise<void> => {
    try {
      const data = await getUser()
      setUser({ ...data })
      localStorage.setItem('Outseta.nocode.accessToken', data.outseta?.accessToken ?? '')
      if (data.language && i18n.language !== data.language) i18n.changeLanguage(data.language)

      const isProd = process.env.NODE_ENV === 'production'
      if (isProd && data.isTester?.ptInventory) {
        setShouldRedirectToNew(true)
      }
    } catch (error) {
      console.log(error)
      setAuthenticated(false)
      Cookie.remove('jwt')
      history.push('/signin')
    }
  }

  useEffect(() => {
    const update = async () => {
      await fetchUser()
    }
    update()
  }, [])

  useEffect(() => {
    if (user) {
      LogRocket.identify(user?._id, {
        name: user?.username,
        email: user?.username,
      })
    }
  }, [user?.username])

  useEffect(() => {
    if (user?.hasNotLoggedIn) {
      window.dataLayer?.push({ event: 'app_event', category: 'conversion', action: 'first_login' })
    }
  }, [user?.hasNotLoggedIn])

  return user ? (
    <UserContext.Provider
      value={{
        user,
        //@ts-ignore
        setUser: setUser,
        fetchUser,
        activeGame,
        setActiveGame,
      }}
    >
      <Box key={activeGame.name}>{children}</Box>
    </UserContext.Provider>
  ) : (
    <LoadingScreen />
  )
}
