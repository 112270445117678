import {
  Box,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  SvgIconTypeMap,
  TextField,
  Typography,
} from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { makeStyles } from '@material-ui/core/styles'
import {
  AccountCircle,
  Assignment,
  BugReport as BugReportIcon,
  DoneTwoTone,
  ErrorTwoTone,
  ExitToApp,
  MenuOpen,
  Store,
  WarningTwoTone,
} from '@material-ui/icons'
import { ButtonDialog } from 'components/controls/dialogs'
import { DayPassPresenter } from 'components/domain/account'
import { CardmarketSidebarPresenter } from 'components/domain/cardmarket/CardmarketSidebarPresenter'
import { useUser } from 'components/providers/UserProvider'
import BSSLogo from 'img/bssLogo.png'
import DBSLogo from 'img/DBSLogo.png'
import digimonLogo from 'img/digimonLogo.png'
import FABLogo from 'img/FABLogo.png'
import FFLogo from 'img/FFLogo.png'
import lorcanaLogo from 'img/lorcanaLogo.png'
import magicLogo from 'img/magicLogo.png'
import onePieceLogo from 'img/onePieceLogo.png'
import pokemonLogo from 'img/pokemonLogo.png'
import powertoolsLogo from 'img/powertoolsLogo.png'
import SWULogo from 'img/swuLogo.png'
import vanguardLogo from 'img/vanguardLogo.png'
import WSLogo from 'img/WSLogo.png'
import yugiohLogo from 'img/yugiohLogo.png'
import { getCardmarketStatus, recordUsage, sendBugReport } from 'lib/api'
import { logoutUser } from 'lib/auth'
import { useAppContext } from 'lib/hooks'
import { currentCardmarketAccount } from 'lib/utils'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Activity, CardmarketStatus, gameMetaData, IdGame, SubscriptionId } from 'shared'
import { Button } from './Button'
import { CustomTooltip } from './CustomTooltip'
import { AddArticlesIcon, BotIcon, DiscordIcon, SettingsIcon, StockPricingIcon } from './Icons'

const drawerWidth = 80

const logos = {
  [IdGame.Magic]: magicLogo,
  [IdGame.Yugioh]: yugiohLogo,
  [IdGame.Pokemon]: pokemonLogo,
  [IdGame.Vanguard]: vanguardLogo,
  [IdGame.DBS]: DBSLogo,
  [IdGame.FF]: FFLogo,
  [IdGame.WS]: WSLogo,
  [IdGame.FAB]: FABLogo,
  [IdGame.Digimon]: digimonLogo,
  [IdGame.OnePiece]: onePieceLogo,
  [IdGame.Lorcana]: lorcanaLogo,
  [IdGame.BSS]: BSSLogo,
  [IdGame.SWU]: SWULogo,
}

const useStyles = makeStyles((theme) => {
  return {
    gameSelect: {
      '&:before': {
        border: 'none',
        content: 'none',
      },
      '&:after': {
        border: 'none',
        content: 'none ',
      },
      '& svg': {
        display: 'none',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        padding: '0px',
      },
      padding: '2px',
      color: 'white',
      '& .MuiSelect-icon': {
        color: 'white',
      },
    },
    root: {
      display: 'flex',
      a: {
        color: 'white',
      },
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: theme.gradient,
      color: 'white',
    },
    titleBox: {
      textAlign: 'center',
      minHeight: '128px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      display: 'flex',
    },
  }
})

interface MenuLinkProps {
  path?: string
  text: string
  onClick?: () => void
  Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>
  external?: boolean
  disabled?: boolean
}

export const MenuLink = ({
  path,
  text,
  onClick,
  Icon,
  disabled = false,
  external = false,
}: MenuLinkProps): JSX.Element => {
  const [showIcons, setShowIcons] = useState(true)

  const isActive = path === useLocation().pathname
  const color = 'white'

  useEffect(() => {
    function handleResize() {
      // Check the window's height and set showIcons accordingly
      setShowIcons(window.innerHeight > 730) // You can adjust the height threshold as needed
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check on component mount

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let component = (
    <>
      <ListItem
        disabled={disabled}
        style={{
          background: isActive ? 'rgba(255, 255, 255, 0.18)' : 'transparent',
          padding: '4px',
          textAlign: 'center',
        }}
        onClick={onClick}
        button
        key={text}
      >
        <Box margin="0 auto">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ display: showIcons ? 'block' : 'none' }}
          >
            {Icon ? <Icon style={{ color, fontSize: '24px' }} /> : <MenuOpen style={{ color }} />}
          </Box>
          <Box>
            <Typography variant="caption" style={{ fontWeight: isActive ? 600 : 400 }}>
              {text}
            </Typography>
          </Box>
        </Box>
      </ListItem>
      <Divider />
    </>
  )

  if (disabled) {
    return component
  }

  if (external) {
    component = (
      <a
        style={{
          color,
          textDecoration: 'none',
        }}
        key={path}
        href={path}
        target="_blank"
        rel="noreferrer"
      >
        {component}
      </a>
    )
  } else if (!(typeof onClick === 'function') && path) {
    component = (
      <Link
        key={path}
        style={{
          color,
          textDecoration: 'none',
        }}
        to={path}
      >
        {component}
      </Link>
    )
  }

  return component
}

const cardmarketStatusColors = {
  [CardmarketStatus.normal]: '#3bb957', //green
  [CardmarketStatus.slow]: '#b9933b', //yellow-orangish
  [CardmarketStatus.down]: '#ca2424', //red
}

const cardmarketStatusSnackbar = {
  [CardmarketStatus.normal]: 'success', //green
  [CardmarketStatus.slow]: 'warning', //yellow-orangish
  [CardmarketStatus.down]: 'error', //red
} as const

const CardmarketStatusIcons = {
  [CardmarketStatus.normal]: DoneTwoTone, //green
  [CardmarketStatus.slow]: ErrorTwoTone, //yellow-orangish
  [CardmarketStatus.down]: WarningTwoTone, //red
} as const

export const Sidebar = ({
  setBannerMessage,
}: {
  setBannerMessage: (banner: { _id: string; message: string } | undefined) => void
}): JSX.Element => {
  const { setUser, user, activeGame, setActiveGame, fetchUser } = useUser()
  const { setAuthenticated } = useAppContext()
  const { enqueueSnackbar } = useSnackbar()
  const [gameSelectOpen, setGameSelectOpen] = useState(false)
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()

  const [cardmarketStatus, setCardmarketStatus] = useState<CardmarketStatus>(
    CardmarketStatus.normal
  )

  if (!user) return <></>

  const CardmarketStatusIcon = CardmarketStatusIcons[cardmarketStatus]

  useEffect(() => {
    const fetchStatus = async (): Promise<void> => {
      try {
        const response = await getCardmarketStatus()

        setBannerMessage(response?.banner)

        if (cardmarketStatus !== response.status) {
          setCardmarketStatus(response.status)
          if ([cardmarketStatus, response.status].includes(CardmarketStatus.down))
            enqueueSnackbar(t(`cardmarketStatus.changedTo.${CardmarketStatus[response.status]}`), {
              variant: cardmarketStatusSnackbar[response.status],
            })
        }
      } catch (err) {
        if (cardmarketStatus !== CardmarketStatus.down) {
          setCardmarketStatus(CardmarketStatus.down)
          enqueueSnackbar(t(`cardmarketStatus.changedTo.down`), {
            variant: 'error',
          })
        }
      }
    }
    fetchStatus()
    const statusInterval = setInterval(fetchStatus, 30 * 1000)

    return () => clearInterval(statusInterval)
  }, [cardmarketStatus])

  const handleLogout = (): void => {
    logoutUser()
    setAuthenticated(false)
    //@ts-ignore
    setUser(null)
    history.push('/signin')
  }

  const BugReport = (): JSX.Element => {
    const [inputs, setInputs] = useState({ report: '' })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e.target.type === 'checkbox') {
        setInputs({ ...inputs, [e.target.name]: e.target.checked })
      } else {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
      }
    }

    const handleSubmit = async (handleClose: () => void): Promise<void> => {
      try {
        await sendBugReport(inputs.report)
        setInputs({ report: '' })
        handleClose()
        enqueueSnackbar(t('bugReport.submitted'), {
          variant: 'success',
        })
      } catch (error) {
        enqueueSnackbar(t('error.generic'), {
          variant: 'error',
        })
      }
    }

    return (
      <ButtonDialog
        label={t('navigation.reportABug')}
        renderComponent={(title, onClick) => (
          <MenuLink text={title} Icon={BugReportIcon} onClick={onClick} />
        )}
        title={t('bugReport.title')}
        content={
          <>
            <Typography>{t('bugReport.text')}</Typography>
            <TextField
              value={inputs.report}
              onChange={handleChange}
              margin="dense"
              label={t('bugReport.label')}
              type="text"
              name="report"
              fullWidth
              multiline
            />
          </>
        }
        actions={(handleClose) => (
          <>
            <Button variant="contained" color="primary" onClick={() => handleSubmit(handleClose)}>
              {t('common.submit')}
            </Button>
          </>
        )}
      />
    )
  }

  const isDisabled = () => {
    return user.subscription.allFeaturesDisabled || user.featureFlags?.banned
  }

  const routes = [
    {
      text: t('navigation.listingAndAppraisal'),
      path: '/listing-and-appraisal',
      Icon: AddArticlesIcon,
      disabled: isDisabled(),
    },
    {
      text: t('navigation.pricingSettings'),
      path: '/pricing-settings',
      Icon: SettingsIcon,
      disabled: isDisabled(),
    },
    {
      text: t('navigation.stockPricing'),
      path: '/stock-pricing',
      Icon: StockPricingIcon,
      disabled: isDisabled(),
    },
    {
      text: t('priceUpdates.pricingBot'),
      path: '/pricing-bot',
      Icon: BotIcon,
      disabled: isDisabled(),
    },
    {
      text: t('navigation.orders'),
      path: '/orders',
      Icon: Assignment,
      disabled: isDisabled(),
      hidden: false,
    },
    {
      text: t('navigation.inventory'),
      path: '/inventory',
      Icon: Store,
      disabled: !user.isAdmin,
      hidden: !user.isAdmin,
    },
  ]

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.titleBox}>
        <Box>
          <Box padding={1} data-testid="powertools-logo">
            <Link key="/" to="/">
              <img width="100%" src={powertoolsLogo} />
            </Link>
          </Box>
          <FormControl>
            <Select
              className={classes.gameSelect}
              open={gameSelectOpen}
              onClose={() => setGameSelectOpen(false)}
              value={activeGame.idGame}
              renderValue={() => (
                <>
                  <img width="100%" src={logos[activeGame.idGame]} />
                </>
              )}
              onOpen={() => setGameSelectOpen(true)}
              onChange={(e) => {
                setActiveGame(e.target.value as string)
              }}
              data-testid="select-game"
            >
              {gameMetaData.map((game) => (
                <MenuItem key={game.idGame} value={game.idGame} data-testid={`${game.name}-game`}>
                  <img width="100px" src={logos[game.idGame]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <CardmarketSidebarPresenter />
          </Box>
        </Box>
      </div>
      <List>
        <Divider />

        {routes.map((route) => {
          if (!route.hidden) {
            return <MenuLink key={route.path} {...route} />
          }
        })}
      </List>
      <Box style={{ position: 'absolute', bottom: '0px', width: '100%' }}>
        <List>
          <Divider />
          {false /* daypass currently disabled */ &&
            [SubscriptionId.Free, SubscriptionId.FreeRoca].includes(user!.subscription.id) && (
              <DayPassPresenter
                hasPaymentMethod={user!.outseta?.hasCreditCard || !!user!.stripePaymentMethod}
                cardmarketUsername={user!.cardmarketUsername}
                dayPasses={user!.dayPasses}
                isProfi={currentCardmarketAccount(user!)?.professional === true}
                userId={user!._id}
                fetchUser={fetchUser}
                onSuccessfulPurchase={async () => {
                  await recordUsage(
                    Activity.PurchaseDaypass,
                    { profi: currentCardmarketAccount(user!)?.professional === true },
                    {
                      action: 'day_pass_purchase',
                      value: currentCardmarketAccount(user!)?.professional === true ? 15.99 : 3.99,
                      category: 'conversion',
                    }
                  )
                }}
              />
            )}
          <BugReport />
          <MenuLink
            Icon={DiscordIcon}
            text={t('navigation.discord')}
            path="https://discord.gg/svUvfT5N4K"
            external
          />
          <MenuLink Icon={AccountCircle} text={t('navigation.myAccount')} path="/account" />
          <Box display="flex" marginLeft={1.3} alignItems="center" justifyContent="center">
            <CustomTooltip
              placement="top"
              title={
                <Box padding={1}>
                  <Typography>
                    <span> {t(`cardmarketStatus.${CardmarketStatus[cardmarketStatus]}`)}</span>
                  </Typography>
                  <Typography variant="caption">{t('cardmarketStatus.info')}</Typography>
                </Box>
              }
            >
              <CardmarketStatusIcon style={{ color: cardmarketStatusColors[cardmarketStatus] }} />
            </CustomTooltip>

            <CustomTooltip title={t('navigation.logout') as string}>
              <IconButton onClick={handleLogout}>
                <ExitToApp style={{ color: 'white' }} />
              </IconButton>
            </CustomTooltip>
          </Box>
        </List>
      </Box>
    </Drawer>
  )
}
