import { Box, FormHelperText, Typography } from '@material-ui/core'
import { AllSellersIcon, Button, ClickableBox, ProfessionalSellersIcon } from 'components/facets'
//@ts-ignore
import NewWindow from 'components/facets/NewWindow.tsx'
import { useUser } from 'components/providers/UserProvider'
import { appToken } from 'config'
import Cookie from 'js-cookie'
import { recordUsage, switchCardmarketAccount } from 'lib/api'
import { connectCardMarket } from 'lib/auth'
import { useErrorHandler } from 'lib/hooks'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Activity, roundTo2Decimals } from 'shared'
import { EntityBox } from '../stockPricing/PricingSettings/EntityBox/EntityBox'

interface CardmarketConnectProps {
  noHelperText?: boolean
  big?: boolean
  onConnect?: () => void
}

export const useCardmarketConnect = () => {
  const { user, fetchUser, setUser } = useUser()

  const { handle } = useErrorHandler()
  const handleConnectCardmarket = (): void => {
    setShowPopup(true)
  }
  const [showPopup, setShowPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  const connectCardmarketAccount = useCallback(
    async (cardmarketUsername?: string): Promise<void> => {
      setLoading(true)
      try {
        if (!cardmarketUsername) {
          throw new Error('No cardmarket username provided')
        }
        const response = await switchCardmarketAccount(cardmarketUsername)
        setUser((user) => ({ ...user!, ...response }))
        setLoading(false)
      } catch (e) {
        handleConnectCardmarket()
      }
    },
    [setUser]
  )

  const handleDone = useCallback(async (): Promise<void> => {
    try {
      setShowPopup(false)

      setLoading(true)
      const requestToken = Cookie.get('requestToken')
      if (!requestToken) {
        throw Error(`We couldnt connect your account. If the problem persists, please Contact Us!`)
      }
      await connectCardMarket(requestToken)
      const wasFirstConnect = !user.assignedCardmarketAccounts.length
      await fetchUser()
      setLoading(false)

      Cookie.remove('requestToken')

      await recordUsage(
        Activity.ConnectCardmarket,
        { name: user.cardmarketUsername },
        wasFirstConnect ? { category: 'conversion', action: 'first_cardmarket_connect' } : undefined
      )
    } catch (error) {
      setLoading(false)
      handle(error)
    }
  }, [setLoading, handle, setUser, fetchUser, user.cardmarketUsername])

  const Popup = useMemo(
    () => () =>
      showPopup ? (
        <NewWindow
          url={'https://api.cardmarket.com/ws/v2.0/authenticate/' + appToken}
          onUnload={handleDone}
        />
      ) : (
        <></>
      ),
    [showPopup, handleDone]
  )

  return [connectCardmarketAccount, Popup, loading || showPopup] as const
}

export const CardmarketConnect = ({
  noHelperText = false,
  big = false,
  onConnect,
}: CardmarketConnectProps): JSX.Element => {
  const { user } = useUser()

  const { t } = useTranslation()
  const [connectCardmarketAccount, Popup, loading] = useCardmarketConnect()

  if (!user) return <></>

  return (
    <Box padding={0}>
      {user.cardmarketUsername ? (
        onConnect ? (
          onConnect()
        ) : (
          <>
            <Box>
              <Box>
                {user.assignedCardmarketAccounts
                  .sort((a, b) => a.username.localeCompare(b.username))
                  .map((account) => {
                    const isActive = account.username === user.cardmarketUsername
                    return (
                      <ClickableBox
                        width="100%"
                        marginTop={1}
                        key={account.username}
                        onClick={
                          isActive ? undefined : () => connectCardmarketAccount(account.username)
                        }
                      >
                        <EntityBox
                          disabled={!isActive}
                          outerBoxProps={{ width: '100%' }}
                          Icon={account.professional ? ProfessionalSellersIcon : AllSellersIcon}
                          titleRow={<Typography variant="h6">{account.username}</Typography>}
                          contentRow={
                            <Typography variant="body2">
                              {isActive
                                ? t('connectCardmarket.currentlyActive')
                                : t('connectCardmarket.clickToSwitch')}{' '}
                              {account.last30daysVolume
                                ? `| ${t('connectCardmarket.volume')}: ${roundTo2Decimals(
                                    account.last30daysVolume
                                  )}€`
                                : ``}
                            </Typography>
                          }
                        />
                      </ClickableBox>
                    )
                  })}
                <Button color="primary" fullWidth onClick={() => connectCardmarketAccount()}>
                  {t('connectCardmarket.addAnotherAccount')}
                </Button>
              </Box>
            </Box>
          </>
        )
      ) : (
        <>
          <Box textAlign={big ? 'center' : 'initial'}>
            <Box>
              <Button
                onClick={() => connectCardmarketAccount()}
                color="primary"
                variant="contained"
                disabled={loading}
                style={
                  big
                    ? {
                        width: '100%',
                        textAlign: 'center',
                        height: '50px',
                        fontSize: '20px',
                        lineHeight: '25px',
                      }
                    : {}
                }
              >
                {loading
                  ? t('connectCardmarket.connecting')
                  : t(`connectCardmarket.${loading ? 'dialogOpen' : 'connectButton'}`)}
              </Button>
            </Box>
            {!noHelperText && (
              <>
                <Box display="inline-flex">
                  <FormHelperText>{t('connectCardmarket.helperText1')}</FormHelperText> &nbsp;
                  <FormHelperText>{t('connectCardmarket.helperText2')}</FormHelperText>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      <Popup />
    </Box>
  )
}
