import { Box, Grid, Typography } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { createPickingList, deletePickingList, getUserPickingLists } from 'lib/api'
import { useOrderStore } from 'lib/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPaidOrdersId, PickingList } from 'shared'
import { DeletePickingListDialog } from './DeletePickingListDialog'
import { PickingListBox } from './PickingListBox'

export interface PickingListPresenterProps {
  handleChangeOrderState: (orderIds: number[], newState: string) => void
}

export const PickingListPresenter = ({
  handleChangeOrderState,
}: PickingListPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUser()
  const activeTab = useOrderStore((state) => state.activeTab)
  const orders = useOrderStore((state) => state.orders)
  const [userPickingLists, setUserPickingLists] = useState<PickingList[]>([])
  const [openDeletePickingListDialog, setOpenDeletePickingListDialog] = useState<boolean>(false)
  const [selectedPickingList, setSelectedPickingList] = useState<PickingList | undefined>(undefined)
  const [numPaidOrders, setNumPaidOrders] = useState<number>(0)

  const loadUserPickingLists = async () => {
    const newUserPickingLists = await getUserPickingLists(user.cardmarketUsername, 'open')
    setUserPickingLists(newUserPickingLists)
  }

  useEffect(() => {
    if (activeTab === 2) {
      loadUserPickingLists()
    }
  }, [activeTab])

  useEffect(() => {
    setNumPaidOrders(getPaidOrdersId(orders).length)
  }, [orders])

  const handleShowDeletePickingListDialog = (pickingList: PickingList) => {
    setSelectedPickingList(pickingList)
    setOpenDeletePickingListDialog(true)
  }

  const handleCloseDeletePickingListDialog = () => {
    setSelectedPickingList(undefined)
    setOpenDeletePickingListDialog(false)
  }

  const handleConfirmDeletePickingListDialog = () => {
    if (selectedPickingList) {
      handleDeletePickingList(selectedPickingList)
    }
    handleCloseDeletePickingListDialog()
  }

  const handleDeletePickingList = async (pickingList: PickingList) => {
    if (pickingList) {
      const rollbackedOrderIds = await deletePickingList(pickingList, user.cardmarketUsername)
      handleChangeOrderState(rollbackedOrderIds, 'paid')
      await loadUserPickingLists()
    }
  }

  const handleNewAutomaticList = async () => {
    // automatic list from all paid orders
    const paidOrdersId = getPaidOrdersId(orders)
    await createPickingList(user.cardmarketUsername, paidOrdersId)
    handleChangeOrderState(paidOrdersId, 'picking')
    await loadUserPickingLists()
  }

  return (
    <>
      <Box>
        {userPickingLists.length > 0 && (
          <Box textAlign="center" paddingTop={5}>
            <Typography variant="button" color="primary">
              {t('orders.picking.startPicking')}
            </Typography>

            <Grid container justifyContent="center">
              {userPickingLists.map((pl) => (
                <Grid item key={pl.name + pl.cardmarketUsername + pl.state}>
                  <Box padding={1}>
                    <PickingListBox
                      pickingList={pl}
                      handleShowDeletePickingListDialog={() =>
                        handleShowDeletePickingListDialog(pl)
                      }
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        <Box textAlign="center" paddingTop={5}>
          <Typography variant="button" color="primary">
            {t('orders.picking.createLists')}
          </Typography>

          <Grid container justifyContent="center">
            <Box padding={1}>
              <PickingListBox
                title={t('orders.picking.automaticList')}
                subtitle={
                  numPaidOrders < 99
                    ? t('orders.picking.fromPaidOrders')
                    : t('orders.picking.fromOldestPaidOrders')
                }
                handleNewAutomaticList={handleNewAutomaticList}
                automaticList
              />
            </Box>
            <Box padding={1}>
              <PickingListBox
                title={t('orders.picking.manualList')}
                subtitle={t('orders.picking.fromSelectedOrders')}
              />
            </Box>
          </Grid>
        </Box>
      </Box>

      <DeletePickingListDialog
        open={openDeletePickingListDialog}
        pickingList={selectedPickingList}
        onClose={handleCloseDeletePickingListDialog}
        onConfirm={handleConfirmDeletePickingListDialog}
      />
    </>
  )
}
