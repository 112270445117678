import { makeStyles, Paper, Typography } from '@material-ui/core'
import useInterval from 'lib/hooks/useInterval'
import { useEffect, useState } from 'react'

export interface StatusCallParams {
  server?: string
  env?: string
  queue?: string
  collection?: string
  maxValue?: number
  minValue?: number
}

export interface StatusBlockProps {
  title: string
  poolingTime: number
  server?: string
  env?: string
  queue?: string
  collection?: string
  maxValue?: number
  minValue?: number
  statusHandler: (statusCallParams: StatusCallParams) => Promise<any>
  outputValueHandler?: (value: string) => JSX.Element
}

export const StatusBlock = ({
  title,
  poolingTime,
  server,
  env,
  queue,
  collection,
  maxValue,
  minValue,
  statusHandler,
  outputValueHandler,
}: StatusBlockProps): JSX.Element => {
  const [output, setOutput] = useState<Record<string, string>>()
  const [status, setStatus] = useState<string>('unknown')

  const backgroundColors: { [key: string]: string } = {
    ok: '#2f9638',
    ko: 'red',
    warn: '#f9b000',
    unknown: '#e9e9e9',
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: backgroundColors[`${status}`],
      color: 'white',
    },
    box: {
      backgroundColor: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      border: '2px solid #000',
      boxShadow: '24',
      p: 4,
    },
  }))

  const classes = useStyles()

  const checkStatus = async () => {
    const statusCallParams = {
      server,
      env,
      queue,
      collection,
      maxValue,
      minValue,
    }

    const output = await statusHandler(statusCallParams)
    setOutput(output)
    setStatus(output.status)
  }
  // call function at component loading
  useEffect(() => {
    checkStatus()
  }, [])
  // set interval for polling
  useInterval(checkStatus, poolingTime)

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">
          {output ? (outputValueHandler ? outputValueHandler(output.value) : output.value) : '--'}
        </Typography>
      </Paper>
    </>
  )
}
