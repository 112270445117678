import { Box, TextField, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Competitor, PricingStrategy } from 'shared'
import { SetState } from 'types'
import { PricingStrategyBox } from '../../PricingStrategyBox'

interface FinalizePresenterProps {
  setStrategy: SetState<PricingStrategy>
  strategy: PricingStrategy
  competitors?: Competitor[]
  strategy1?: PricingStrategy
  strategy2?: PricingStrategy
}

export const FinalizePresenter = ({
  strategy,
  setStrategy,
  competitors,
  strategy1,
  strategy2,
}: FinalizePresenterProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Typography gutterBottom variant="body1">
        {t('strategy.create.almostDone')}{' '}
      </Typography>
      <Box margin={2}>
        <TextField
          fullWidth
          variant="outlined"
          value={strategy.name}
          autoFocus
          onFocus={(event) => {
            event.target.select()
          }}
          onChange={(e) =>
            setStrategy({
              ...strategy,
              name: e.target.value,
            })
          }
          label={t('strategy.name')}
          data-testid="pricing-strategy-name"
        />
        <Box width="fit-content" margin="48px auto">
          <PricingStrategyBox
            competitors={competitors}
            pricingStrategy={strategy}
            pricingStrategy1={strategy1}
            pricingStrategy2={strategy2}
          />
        </Box>
      </Box>
    </>
  )
}
