import { Box, Typography } from '@material-ui/core'
import {
  CompetitionStrategyIcon,
  MinMaxStrategyIcon,
  PriceGuideStrategyIcon,
} from 'components/facets'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkSettings, Competitor, Game, PricingStrategy } from 'shared'
import { OptionBox } from '../OptionBox'
import { StepBox } from '../StepBox'
import { CompetitorFlowPresenter } from './CompetitorFlowPresenter'
import { MinMaxFlowPresenter } from './MinMaxFlowPresenter'
import { PriceGuideFlowPresenter } from './PriceGuideFlowPresenter'

export interface CreateStrategyPresenterProps {
  newName: string
  competitorNewName: string
  competitors: Competitor[]
  bulkSettings: BulkSettings[]
  onDone: (strategy: PricingStrategy) => void
  onCompetitorDone: (competitor: Competitor) => void
  competitionDisabled: boolean
  activeGame: Game
}

export const CreateStrategyPresenter = ({
  newName,
  bulkSettings,
  competitors,
  competitorNewName,
  onDone,
  onCompetitorDone,
  competitionDisabled,
  activeGame,
}: CreateStrategyPresenterProps): JSX.Element => {
  const [strategyKind, setStrategyKind] = useState<'articleData' | 'priceGuide' | 'minMax' | null>(
    null
  )
  const { t } = useTranslation()

  return (
    <Box style={{ width: '1000px', height: '640px' }}>
      {!strategyKind ? (
        <>
          <Typography variant="h4">{t('strategy.create.title')}</Typography>

          <StepBox>
            <Box marginTop={2}>
              <Typography variant="body1">{t('strategy.create.baseDescription')}</Typography>
              <Box width="fit-content" margin="0 auto" display="flex">
                <OptionBox
                  width="400px"
                  onClick={() => {
                    setStrategyKind('priceGuide')
                  }}
                  Icon={PriceGuideStrategyIcon}
                  iconSize="8rem"
                  title={`Price Guide`}
                  description={t('strategy.create.priceGuideDescription')}
                  dataTestid={'strategy-price-guide'}
                />
                <OptionBox
                  width="400px"
                  onClick={() => {
                    setStrategyKind('articleData')
                  }}
                  Icon={CompetitionStrategyIcon}
                  title={`Competition`}
                  iconSize="8rem"
                  description={t('strategy.create.competitionDescription')}
                  recommended
                  disabled={competitionDisabled}
                  dataTestid={'strategy-competition'}
                />
              </Box>
              <Box width="fit-content" margin="0 auto" display="flex">
                <OptionBox
                  width="400px"
                  onClick={() => {
                    setStrategyKind('minMax')
                  }}
                  Icon={MinMaxStrategyIcon}
                  title={`Min/Max`}
                  iconSize="8rem"
                  description={t('strategy.create.minMaxDescription')}
                  dataTestid={'strategy-min-max'}
                />
              </Box>
            </Box>
          </StepBox>
        </>
      ) : (
        {
          articleData: (
            <CompetitorFlowPresenter
              onCompetitorDone={onCompetitorDone}
              onDone={onDone}
              activeGame={activeGame}
              competitorNewName={competitorNewName}
              bulkSettings={bulkSettings}
              competitors={competitors}
              key={strategyKind}
              onRestart={() => setStrategyKind(null)}
              newName={newName}
            />
          ),
          priceGuide: (
            <PriceGuideFlowPresenter
              onDone={onDone}
              bulkSettings={bulkSettings}
              key={strategyKind}
              onRestart={() => setStrategyKind(null)}
              newName={newName}
              dataTestid={`strategy-price-guide`}
            />
          ),
          minMax: (
            <MinMaxFlowPresenter
              onDone={onDone}
              key={strategyKind}
              onRestart={() => setStrategyKind(null)}
              newName={newName}
              dataTestid={`strategy-min-max`}
            />
          ),
        }[strategyKind]
      )}
    </Box>
  )
}
