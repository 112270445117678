import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ElementWithChildren } from 'types'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    '& h2': {
      textAlign: 'center',
    },
  },
}))

export const DialogBox = ({ children }: ElementWithChildren): JSX.Element => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={3}>
      {children}
    </Paper>
  )
}
