import { Box, Dialog, TextField, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SolveIssueDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (problem: string) => void
}

export const SolveIssueDialog = ({
  open,
  onClose,
  onConfirm,
}: SolveIssueDialogProps): JSX.Element => {
  const { t } = useTranslation()

  const [solutionText, setSolutionText] = useState()

  const handleChangeTextField = (e: any) => {
    setSolutionText(e.target.value)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Box padding={2}>
        <Box padding={2}>
          <Typography variant="body1">{t('orders.solveDialogTitle')}</Typography>
        </Box>
        <Box marginBottom={2} display="flex">
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            onChange={handleChangeTextField}
          ></TextField>
        </Box>
        <Box marginTop={1} style={{ float: 'right' }}>
          <Button variant="outlined" onClick={onClose} style={{ marginRight: '15px' }}>
            {t('common.close')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (solutionText) {
                onConfirm(solutionText)
              }
            }}
          >
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
