import { Box, Typography } from '@material-ui/core'

export interface ConditionBadgeProps {
  condition: string
}

export const ConditionBadge = ({ condition }: ConditionBadgeProps): JSX.Element => {
  const getConditionBgColor = () => {
    const colorMap: {
      [key: string]: string
    } = {
      MT: '#17a2b8',
      NM: 'rgba(40,167,69,0.9)',
      EX: '#82891e',
      GD: '#ffc107',
      LP: 'rgba(253,126,20,0.9)',
      PL: 'rgba(220,53,69,0.75)',
      PO: '#dc3545',
    }
    return colorMap[condition]
  }

  const getConditionTextColor = () => {
    const colorMap: {
      [key: string]: string
    } = {
      MT: 'white',
      NM: 'white',
      EX: 'white',
      GD: 'black',
      LP: 'white',
      PL: 'white',
      PO: 'white',
    }
    return colorMap[condition]
  }

  return (
    <Box
      width="30px"
      marginRight="2px"
      bgcolor={getConditionBgColor()}
      borderRadius="3px"
      textAlign="center"
    >
      <Typography variant="body2" style={{ color: `${getConditionTextColor()}` }}>
        {condition}
      </Typography>
    </Box>
  )
}
