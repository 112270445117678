import { useUser } from 'components/providers/UserProvider'

export const useHotkeyConfig = (): string[][] => {
  const { activeGame } = useUser()

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { hotkeys } = require(`data/${activeGame!.name}_hotkeys.json`)

  return hotkeys as string[][]
}
