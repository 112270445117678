import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import { KeyboardBackspace } from '@material-ui/icons'
import CommentIcon from '@material-ui/icons/Comment'
import { ExistingStockProvider } from 'components/domain/csv/ExistingStockProvider'
import { Condition, CustomTooltip } from 'components/facets'
import { theme } from 'config'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import playsetIcon from 'img/playsetIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { useLocalStorageState } from 'lib/hooks'
import { useStockStore } from 'lib/hooks/useStockStore'
import { currencySymbol, exposeToCypress } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { AppCurrency, CardLanguage, InventoryArticle, Template } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { ArticleTemplate } from '../ArticleTemplate/ArticleTemplate'
import { ArticleTemplateDialog } from '../ArticleTemplateDialog'
import { AddSingleArticlesByExp } from './AddSingleArticlesByExp'
import { BulkAddByExp } from './BulkAddByExp'
import { ProfiAddArticles } from './ProfiAddArticles'

export interface AddArticleDockProps {
  disableHotkeys: boolean
  discardChange: boolean
  onAddArticles: (articles: InventoryArticle[]) => void
  showThisTab: 'addByCard' | 'addByExp' | 'addBulk'
  handleBackToMenu: () => void
}

interface DefaultValueProps {
  template: Template
  currency?: AppCurrency
}

const useStyles = makeStyles(() => ({
  bottomNav: {
    backgroundColor: 'transparent',
    minWidth: '60px',
  },
  headerTitle: {
    position: 'relative',
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  typeImportBox: {
    marginTop: '-12px',
  },
  typeImportText: {
    textTransform: 'uppercase',
    color: theme.palette.strongViolet,
    fontSize: '19px',
  },
  iconBox: {
    position: 'absolute',
    textAlign: 'center',
    top: '-32px',
    right: '0',
  },
  defautValuesFontSize: {
    fontSize: '13px',
  },
  defautValuesImgHeight: {
    marginTop: '2px',
    height: '15px',
  },
  defautValuesAttributesBox: {
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  defaultValueContainer: {
    minHeight: '40px',
    borderRadius: '10px',
    marginLeft: '5px',
    marginRight: '5px',
    display: 'flex',
    padding: '10px',
  },
}))

export const AddArticleDock = ({
  onAddArticles,
  disableHotkeys,
  showThisTab,
  discardChange,
  handleBackToMenu,
}: AddArticleDockProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<'addByCard' | 'addByExp' | 'addBulk'>(showThisTab)
  const { t } = useTranslation()
  const [articleTemplateDialogOpen, setArticleTemplateDialogOpen] = useState(false)
  const classes = useStyles()
  const activeStockType = useStockStore((state) => state.activeStockType)

  useEffect(() => {
    setSelectedTab(showThisTab)
  }, [showThisTab])

  const [template, setTemplate] = useLocalStorageState<Template>(
    {
      condition: 'NM',
      idLanguage: CardLanguage.English,
      isFoil: false,
      isPlayset: false,
      isFirstEd: false,
      isSigned: false,
      isReverseHolo: false,
      isAltered: false,
      comment: '',
      quantity: 1,
      price: 1000,
    },
    'template'
  )

  exposeToCypress({ articleTemplate: template })

  return (
    <Box>
      <Box>
        <Box>
          <Box className={classes.headerTitle} marginTop={2} sx={{ justifyContent: 'center' }}>
            {showThisTab === 'addByCard' && (
              <>
                <IconButton style={{ padding: '0' }} onClick={handleBackToMenu}>
                  <KeyboardBackspace fontSize="large" color="primary" />
                </IconButton>
                <Box width="2rem"></Box>
                <Typography className={classes.typeImportText}>
                  {activeStockType === 'singles'
                    ? t('addArticles.sigleCardTitleImportButton')
                    : t('addArticles.sigleItemTitleImportButton')}
                </Typography>
              </>
            )}

            {showThisTab === 'addByExp' && (
              <>
                <IconButton style={{ padding: '0' }} onClick={handleBackToMenu}>
                  <KeyboardBackspace fontSize="large" color="primary" />
                </IconButton>
                <Box width="2rem"></Box>
                <Typography className={classes.typeImportText}>
                  {t('addArticles.byExpansionTitleImportButton')}
                </Typography>
              </>
            )}

            {showThisTab === 'addBulk' && (
              <>
                <IconButton style={{ padding: '0' }} onClick={handleBackToMenu}>
                  <KeyboardBackspace fontSize="large" color="primary" />
                </IconButton>
                <Box width="2rem"></Box>
                <Typography className={classes.typeImportText}>
                  {t('addArticles.bulkTitleImportButton')}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <ExistingStockProvider>
        <Box padding={1}>
          {selectedTab === 'addByCard' && (
            <ProfiAddArticles
              disableHotkeys={disableHotkeys}
              onAddArticles={onAddArticles}
              template={template}
              discardChange={discardChange}
            />
          )}
          {selectedTab === 'addByExp' && (
            <AddSingleArticlesByExp
              disableHotkeys={disableHotkeys}
              onAddArticles={onAddArticles}
              template={template}
            />
          )}
          {selectedTab === 'addBulk' && (
            <BulkAddByExp onAddArticles={onAddArticles} template={template} />
          )}
          <Box marginTop={10}>
            <Typography className={classes.typeImportText} style={{ fontSize: '13px' }}>
              {t('addArticles.defaultValues')}
            </Typography>
            {/* <DefaultValue template={template} currency={user?.currency} /> */}
            <ArticleTemplate setTemplate={setTemplate} template={template} />
          </Box>
        </Box>
      </ExistingStockProvider>
      {articleTemplateDialogOpen && (
        <ArticleTemplateDialog
          open={articleTemplateDialogOpen}
          setOpen={setArticleTemplateDialogOpen}
          setTemplate={setTemplate}
          template={template}
        />
      )}
    </Box>
  )
}

export const DefaultValue = ({ template, currency = 'EUR' }: DefaultValueProps): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <Box style={{ display: 'flex', marginTop: '2px' }}>
        <Flag
          style={{
            paddingRight: '3px',
            height: '15px',
            marginTop: '3px',
          }}
          //@ts-ignore
          fallback={<span>Unknown</span>}
          code={LANGUAGE_MAP_ICONS[template.idLanguage] || 'gg'}
        />

        {template.isFoil && (
          <Box
            className={classes.defautValuesImgHeight}
            style={{ marginTop: '-1px', marginLeft: '2px', marginRight: '2px' }}
          >
            <img alt="foil" src={foilIcon} style={{ width: '18px' }} />
          </Box>
        )}

        {template.isReverseHolo && (
          <Box className={classes.defautValuesAttributesBox}>
            <img
              alt="reverse holo"
              className={classes.defautValuesImgHeight}
              src={reverseHoloIcon}
            />
          </Box>
        )}

        {template.isPlayset && (
          <Box className={classes.defautValuesAttributesBox}>
            <img alt="playset" className={classes.defautValuesImgHeight} src={playsetIcon} />
          </Box>
        )}
        {template.isFirstEd && (
          <Box className={classes.defautValuesAttributesBox}>
            <img alt="first edition" src={firstEdIcon} className={classes.defautValuesImgHeight} />
          </Box>
        )}
        {template.isSigned && (
          <Box className={classes.defautValuesAttributesBox}>
            <img alt="signed" className={classes.defautValuesImgHeight} src={signedIcon} />
          </Box>
        )}

        {template.comment && (
          <CustomTooltip title={<Typography variant="body1">{template.comment}</Typography>}>
            <CommentIcon
              data-testid={``}
              style={{
                fontSize: `21px`,
                marginLeft: '3px',
              }}
            />
          </CustomTooltip>
        )}
      </Box>

      <Box style={{ display: 'flex', marginTop: '3px' }}>
        <Box style={{ fontSize: '14px', paddingRight: '3px' }}>
          <Condition id="AddArticleDock-condition" value={template.condition} noPadding={true} />
        </Box>

        <Typography className={classes.defautValuesFontSize}>
          {`| ${template.quantity}x | ${template.price}${currencySymbol(currency)} `}
        </Typography>
      </Box>
    </>
  )
}
