import { Box, Dialog, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useTranslation } from 'react-i18next'
import { PickingList } from 'shared'

interface DeletePickingListDialogProps {
  pickingList?: PickingList
  open: boolean
  onClose: () => void
  onConfirm: (pickingList: PickingList) => void
}

export const DeletePickingListDialog = ({
  pickingList,
  open,
  onClose,
  onConfirm,
}: DeletePickingListDialogProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Box padding={2}>
        <Box padding={2}>
          <Typography variant="body1">
            Are you sure you want to delete the selected picking list and revert all associated
            orders to the 'Paid' state?
          </Typography>
        </Box>
        <Box marginTop={1} style={{ float: 'right' }}>
          <Button variant="outlined" onClick={onClose} style={{ marginRight: '15px' }}>
            {t('common.close')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (pickingList) onConfirm(pickingList)
            }}
          >
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
