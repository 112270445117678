import {
  Box,
  DialogContent,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TableBody,
  Typography,
} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { ClosableDialog } from 'components/controls/dialogs'
import { AutomaticFix, Button, CircularLoading, ImportDisabledIcon } from 'components/facets'
import { WarningBox } from 'components/facets/WarningBox'
import { useUser } from 'components/providers/UserProvider'
import { theme } from 'config'
import { CSVImportResponse, importFile } from 'lib/api'
import useHeaderColumnNamesState from 'lib/hooks/useHeaderColumnNamesState'
import { exposeToCypress } from 'lib/utils'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { CardExtraAttribute, IdGame, InventoryArticle, isSingle } from 'shared'

const PREVIEW_ROWS_SIZE = 10

const defaultIgnoreColumns = [
  'setCode',
  'nameDE',
  'nameES',
  'nameFR',
  'nameIT',
  'rarity',
  'listedAt',
]

export interface PreviewCsvProps {
  jsonCsv: Array<Record<string, string | number | undefined>>
  fileContent: File[] | null
  stockType: string
  handleResult: (importedArticles: InventoryArticle[]) => void
  closeExternalDialog?: Dispatch<SetStateAction<boolean>>
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  error: {
    border: '1px solid' + theme.palette.error.main,
    background: 'rgba(255,0,0,0.2)',
    borderRadius: '5px',
    padding: '3px',
  },
})

const belongToThisSet = (value: string, set: Array<string>): boolean =>
  set.filter((word) => value.toLowerCase().split(/\s+/g).includes(word)).length > 0

const finders: Record<string, (x: string) => boolean> = {
  quantity: (value: string) =>
    belongToThisSet(value, [
      'qty',
      'qta',
      'qtx',
      'quantity',
      'number',
      'stock',
      'num',
      'count',
      'amount',
      '',
    ]),
  name: (value: string) => belongToThisSet(value, ['name', 'nome', 'card', 'cardname', 'title']),
  expansion: (value: string) =>
    belongToThisSet(value, [
      'set',
      'name',
      'exp',
      'expansion',
      'expcod',
      'codexpansion',
      'codexp',
      'idexpansion',
      'edition',
      'setname',
      'setcode',
      'code',
    ]),
  cn: (value: string) => belongToThisSet(value, ['collector', 'number', 'collectornum', 'cn']),
  condition: (value: string) => belongToThisSet(value, ['condition', 'status', 'stato', 'state']),
  language: (value: string) => belongToThisSet(value, ['language', 'lan', 'lingua', 'lang']),
  foil: (value: string) => belongToThisSet(value, ['foil', 'isfoil', 'finish']),
  playset: (value: string) => belongToThisSet(value, ['playset', 'isplayset', 'bundle']),
  signed: (value: string) => belongToThisSet(value, ['signed', 'issigned']),
  firstEd: (value: string) =>
    belongToThisSet(value, [
      'firsted',
      'first',
      'edition',
      'primaedizione',
      'prima',
      'edizione',
      'isfirst',
      'isfirsted',
    ]),
  reverseHolo: (value: string) =>
    belongToThisSet(value, ['reverseholo', 'isreverseholo', 'reversefoil', 'isreversefoil']),
  price: (value: string) => belongToThisSet(value, ['price', 'prezzo', '€', '$', 'cash']),
  comment: (value: string) => belongToThisSet(value, ['comment', 'commenti', 'remark', 'critics']),
  mkmId: (value: string) =>
    belongToThisSet(value, ['mkm', 'mkmid', 'cardmarket', 'cmid', 'cardmarketid']),
  scryfallId: (value: string) => belongToThisSet(value, ['scryfallid', 'scryfall']),
  tcgplayerId: (value: string) => belongToThisSet(value, ['tcgplayerid', 'tcgplayer']),
}

const checkGame = (found: Array<string>, gameCode: number): Array<string> => {
  if (gameCode === IdGame.Magic) {
    const firstEdIndex = found.findIndex((colName) => colName === 'firstEd')
    if (firstEdIndex > -1) found[firstEdIndex] = 'ToBeSet'
    return found
  } else {
    return found
  }
}
const getSmartColumnNames = (
  jsonCsv: Array<Record<string, string | number | undefined>>,
  gameCode: number
): Array<string> => {
  const headerNames = Object.keys(jsonCsv[0] || {})

  const found: Array<string> = []
  for (let i = 0; i < headerNames.length; i++) {
    let colNameFound = false
    for (const key in finders) {
      if (finders[key](headerNames[i]) && !found.find((foundedValue) => foundedValue === key)) {
        found.push(key)
        colNameFound = true
        break
      }
    }
    if (!colNameFound) {
      if (defaultIgnoreColumns.includes(headerNames[i])) {
        found.push('-')
      } else {
        found.push('ToBeSet')
      }
    }
  }

  return checkGame(found, gameCode)
}

export const PreviewCsv = ({
  jsonCsv,
  stockType,
  handleResult,
  closeExternalDialog,
}: PreviewCsvProps): JSX.Element => {
  const tableHeader = Object.keys(jsonCsv[0] || {})
  const { t } = useTranslation()
  const { activeGame } = useUser()
  const initialColumnNames = getSmartColumnNames(jsonCsv, activeGame.idGame)
  const [columnNames, setColumnNames, saveColumnNames] = useHeaderColumnNamesState(
    tableHeader,
    initialColumnNames
  )
  const [response, setResponse] = useState<CSVImportResponse | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [importFeedback, setImportFeedback] = useState<InventoryArticle[]>([])
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const CSV_COLUMN_IDENTIFIERS = [
    'quantity',
    'name',
    'expansion',
    'cn',
    'condition',
    'language',
    // 'foil',
    // 'playset',
    'signed',
    'price',
    'comment',
    'mkmId',
    'scryfallId',
    'tcgplayerId',
  ]

  //Important: the elements of this array and CSV_COLUMN_IDENTIFIERS must remain in the same order.
  // example: Quantity is the first element for both etc...
  const CSV_COLUMN_IDENTIFIERS_TRANSLATE = [
    t('csv.csvPreviewColumnIdentifiersQuantity'),
    t('csv.csvPreviewColumnIdentifiersName'),
    t('csv.csvPreviewColumnIdentifiersExpansion'),
    t('csv.csvPreviewColumnIdentifiersCn'),
    t('csv.csvPreviewColumnIdentifiersCondition'),
    t('csv.csvPreviewColumnIdentifiersLanguage'),
    // t('csv.csvPreviewColumnIdentifiersFoil'),
    // t('csv.csvPreviewColumnIdentifiersPlayset'),
    t('csv.csvPreviewColumnIdentifiersSigned'),
    t('csv.csvPreviewColumnIdentifiersPrice'),
    t('csv.csvPreviewColumnIdentifiersComment'),
    'Cardmarket ID',
    'Scryfall ID',
    'Tcgplayer ID',
  ]

  const MAX_NUM_COLUMNS = 30

  if (activeGame.extraAttr.includes(CardExtraAttribute.FirstEd)) {
    CSV_COLUMN_IDENTIFIERS.push('firstEd')
    CSV_COLUMN_IDENTIFIERS_TRANSLATE.push(t('csv.csvPreviewColumnIdentifiersFirstEd'))
  }
  if (activeGame.extraAttr.includes(CardExtraAttribute.ReverseHolo)) {
    CSV_COLUMN_IDENTIFIERS.push('reverseHolo')
    CSV_COLUMN_IDENTIFIERS_TRANSLATE.push(t('csv.csvPreviewColumnIdentifiersReverseHolo'))
  }
  if (activeGame.extraAttr.includes(CardExtraAttribute.Foil)) {
    CSV_COLUMN_IDENTIFIERS.push('foil')
    CSV_COLUMN_IDENTIFIERS_TRANSLATE.push(t('csv.csvPreviewColumnIdentifiersFoil'))
  }
  if (activeGame.extraAttr.includes(CardExtraAttribute.Playset)) {
    CSV_COLUMN_IDENTIFIERS.push('playset')
    CSV_COLUMN_IDENTIFIERS_TRANSLATE.push(t('csv.csvPreviewColumnIdentifiersPlayset'))
  }

  const updateColumnNames = (state: string, index: number): void => {
    const newState = [...columnNames]
    newState[index] = state
    setColumnNames(newState)
  }

  exposeToCypress({
    fileImport: { columnNames, setColumnNames, updateColumnName: updateColumnNames },
  })

  const getSelectOptions = (): Array<string> => {
    const result = []
    for (let i = 0; i < CSV_COLUMN_IDENTIFIERS.length; i++) {
      const foundValue = columnNames.find(
        (word) => word !== 'ToBeSet' && word === CSV_COLUMN_IDENTIFIERS[i]
      )
      if (foundValue === undefined) result.push(CSV_COLUMN_IDENTIFIERS[i])
    }
    return result
  }
  const initialOptionValues = getSelectOptions()
  const [optionValues, setOptionValues] = useState<Array<string>>(initialOptionValues)
  const [previousSelectValue, setPreviousSelectValue] = useState<string>('')

  const getTableRow = (row: Record<string, string | number | undefined>): Array<JSX.Element> => {
    const rowValues = Object.values(row)
    return rowValues.map((rowValue, index) => (
      <TableCell key={`${rowValue}-${index}`}>{rowValue}</TableCell>
    ))
  }

  const getTableBody = (): Array<JSX.Element> =>
    jsonCsv
      .slice(0, PREVIEW_ROWS_SIZE)
      .map((row: Record<string, string | number | undefined>, i: number) => (
        <TableRow key={`${Object.values(row).join('-')}-${i}`}>{getTableRow(row)}</TableRow>
      ))

  const handleChange = (name: string, colNumber: number): void => {
    updateColumnNames(name, colNumber)

    const newOptions = optionValues.filter((word) => word !== name)
    newOptions.push(previousSelectValue)
    setOptionValues(newOptions)
  }

  const getHeaderRequireElementStatus = (): Record<string, boolean> => {
    const requiredValue: Record<string, boolean> = {
      quantity: false,
      // price: false,
      mkmId: false,
      scryfallId: false,
      tcgplayerId: false,
      name: false,
      expansion: false,
      cn: false,
    }

    for (const key in requiredValue) {
      requiredValue[key] = columnNames.find((word) => key.localeCompare(word) === 0) ? true : false
    }

    return requiredValue
  }

  const isDisabled = (): boolean => {
    const requiredValue = getHeaderRequireElementStatus()

    if (columnNames.filter((word) => word === 'ToBeSet').length > 0) return true

    if (
      requiredValue.quantity &&
      // requiredValue.price &&
      (requiredValue.mkmId ||
        requiredValue.scryfallId ||
        requiredValue.tcgplayerId ||
        (requiredValue.name && requiredValue.expansion) ||
        (requiredValue.cn && requiredValue.expansion))
    ) {
      return false
    } else {
      return true
    }
  }

  const getConditionAndLanguageStatus = (): Record<string, boolean> => {
    const values: Record<string, boolean> = {
      language: false,
      condition: false,
    }

    for (const key in values) {
      values[key] = columnNames.find((word) => key.localeCompare(word) === 0) ? true : false
    }

    return values
  }

  const enableSetDefaultValue = (): boolean => {
    const defaultValueSatatus = getConditionAndLanguageStatus()

    if (!defaultValueSatatus.language || !defaultValueSatatus.condition) {
      return true
    } else {
      return false
    }
  }

  const getSetDefaultValueMessage = (): string | undefined => {
    const defaultValueSatatus = getConditionAndLanguageStatus()
    const error = ''
    if (!defaultValueSatatus.language && !defaultValueSatatus.condition)
      return error + t('csv.csvPreviewErrorDefaultValueWillSet')

    if (!defaultValueSatatus.language && defaultValueSatatus.condition)
      return error + t('csv.csvPreviewErrorDefaultLanguageWillSet')

    if (defaultValueSatatus.language && !defaultValueSatatus.condition)
      return error + t('csv.csvPreviewErrorDefaultConditionWillSet')
  }

  const hasValidValue = (value: string | null): boolean => value?.localeCompare('ToBeSet') !== 0

  const isDisabledOption = (option: string): boolean =>
    !!columnNames.find((word) => word === option)

  const getOptionsValue = (): Array<JSX.Element> => {
    return CSV_COLUMN_IDENTIFIERS.map((option, index) => (
      <MenuItem
        key={option}
        value={option}
        disabled={isDisabledOption(option)}
        data-testid={`select-column-option-${CSV_COLUMN_IDENTIFIERS_TRANSLATE[index]}`}
      >
        {CSV_COLUMN_IDENTIFIERS_TRANSLATE[index]}
      </MenuItem>
    ))
  }

  const getSelectValue = (colNumber: number): JSX.Element => {
    return (
      <Select
        value={columnNames[colNumber]}
        onChange={(e) => {
          handleChange(e.target.value as string, colNumber)
        }}
        onOpen={(e) => {
          const input = e.target as HTMLElement
          setPreviousSelectValue(input.innerText)
        }}
        style={{
          width: '100%',
          minWidth: '100%',
          marginTop: '5px',
          marginBottom: '5px',
        }}
        className={!hasValidValue(columnNames[colNumber]) ? classes.error : ''}
        data-testid={`chose-name-of-${colNumber}-column`}
      >
        <MenuItem value={'ToBeSet'} disabled={true} data-testid="select-column-option-ToBeSet">
          {t('csv.csvPreviewChoseLabel')}
        </MenuItem>
        <MenuItem value={'-'} data-testid="select-column-option-Ignore-this">
          {t('csv.csvPreviewIgnoreThis')}
        </MenuItem>
        {getOptionsValue()}
      </Select>
    )
  }

  const getDescribeErrorMessage = (): string => {
    let errorMessage = ''
    const requiredValues = getHeaderRequireElementStatus()
    if (columnNames.find((word) => word === 'ToBeSet'))
      return (errorMessage = errorMessage + t('csv.csvPreviewErrorColumnNotSet'))
    if (!requiredValues.quantity)
      return (errorMessage = errorMessage + t('csv.csvPreviewErrorMissQuantity'))
    if (
      !requiredValues.name ||
      !requiredValues.expansion ||
      !requiredValues.tcgplayerId ||
      !requiredValues.mkmId
    )
      return (errorMessage = errorMessage + t('csv.csvPreviewErrorMissId'))
    return ''
  }

  const isValidCsv = (): boolean => {
    if (tableHeader.length <= MAX_NUM_COLUMNS) {
      return true
    } else {
      return false
    }
  }

  const csvNotEmpty = (): boolean => {
    if (jsonCsv.length > 0) {
      return true
    } else {
      return false
    }
  }

  const showWarningBox = (icon: typeof SvgIcon, color: string): JSX.Element => {
    return (
      <WarningBox
        color={color}
        tooltipText={'csv.csvPreviewWarningTooltip'}
        Icon={icon}
        toRight={false}
      />
    )
  }

  const sendArticleToServer = async () => {
    setIsLoading(true)
    setHasError(false)
    saveColumnNames()

    const payload = jsonCsv.map((row) => {
      const fixedRow = Object.values(row).reduce((acc, item, index) => {
        acc[columnNames[index]] = item
        return acc
      }, {} as Record<string, unknown>)
      return fixedRow
    })

    try {
      const response = await importFile(payload, activeGame.idGame)

      const articles = response.parsedArticles.filter((article) =>
        stockType === 'singles' ? isSingle(article.card) : !isSingle(article.card)
      )
      setResponse({ failedArticles: response.failedArticles, articles: articles })

      handleResult(articles)
      setImportFeedback(articles)

      setIsLoading(false)
      setIsDialogOpen(true)
    } catch (error) {
      enqueueSnackbar(t('csv.csvImportErrorInFile'), {
        variant: 'error',
      })
      setHasError(true)
      setIsLoading(false)
      setIsDialogOpen(true)
    }
  }

  const setWarningBoxHeight = (): string => {
    if (isDisabled() && enableSetDefaultValue()) {
      return '40px'
    } else {
      return '0px'
    }
  }

  return (
    <Box data-testid="preview-csv">
      <Box>
        <Typography variant="h6">{t('csv.csvPreviewDetail')}</Typography>
        <Typography>{t('csv.csvPreviewInfo')}</Typography>
      </Box>

      <Box
        style={{
          position: 'relative',
          marginTop: '10px',
          paddingTop: isDisabled() || enableSetDefaultValue() ? '30px' : '0',
        }}
      >
        {isValidCsv() && csvNotEmpty() ? (
          <>
            <Box
              style={{
                position: 'relative',
                minHeight: setWarningBoxHeight(),
                display: 'block',
              }}
            >
              <Box
                style={{
                  position: 'absolute',
                  top: '-30px',
                  right: '0px',
                  display: isDisabled() ? 'flex' : 'none',
                }}
              >
                <Box
                  style={{
                    color: theme.palette.error.main,
                    marginRight: '10px',
                    marginTop: '11px',
                    fontSize: '18px',
                  }}
                >
                  <Typography>{`${getDescribeErrorMessage()}`}</Typography>
                </Box>
                {showWarningBox(ImportDisabledIcon, theme.palette.error.main)}
              </Box>
              <Box
                style={{
                  position: 'absolute',
                  top: isDisabled() ? '17px' : '-30px',
                  right: '0px',
                  display: enableSetDefaultValue() ? 'flex' : 'none',
                }}
              >
                <Box
                  style={{
                    color: theme.palette.primary.main,
                    marginRight: '10px',
                    marginTop: '11px',
                    fontSize: '18px',
                  }}
                  data-testid={'warnig-box-set-default-value'}
                >
                  <Typography>{getSetDefaultValueMessage()}</Typography>
                </Box>
                {showWarningBox(AutomaticFix, theme.palette.primary.main)}
              </Box>
            </Box>
            <Box
              id={'previewContent'}
              marginTop={3}
              style={{
                height: 'auto',
                borderRadius: '5px',
                border: isLoading
                  ? `solid 2px ${theme.palette.black}`
                  : `solid 2px ${theme.palette.primary.main}`,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                backgroundColor: isLoading ? `rgba(0,0,0,0.2)` : ``,
              }}
            >
              <TableContainer
                component={Paper}
                style={{ backgroundColor: 'rgba(39, 12, 162, 0.08)' }}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: '#ffffff', height: '100px!important' }}>
                    <TableRow key={'selectHeaderRow'}>
                      {tableHeader.map((columnName: string, i: number) => (
                        <TableCell key={`${columnName}-${i}`}>{getSelectValue(i)}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow key={'headerRow'}>
                      {tableHeader.map((columnName) => (
                        <TableCell key={columnName}>{columnName}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getTableBody()}
                    {jsonCsv.length > PREVIEW_ROWS_SIZE && (
                      <TableRow key={'continueRow'}>
                        {tableHeader.map((empty, i) => (
                          <TableCell key={`${empty}-${i}`}>...</TableCell>
                        ))}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => sendArticleToServer()}
                style={{
                  width: '50%',
                  textAlign: 'center',
                  height: '60px',
                  fontSize: '25px',
                  marginBottom: '25px',
                  marginTop: '25px',
                  marginLeft: '25%',
                  marginRight: '25%',
                }}
                disabled={isDisabled() || isLoading}
                data-testid={'import-csv-data-btn'}
              >
                {t('csv.csvPreviewImport')}
              </Button>
            </Box>
            {isLoading && (
              <>
                <Box
                  padding={3}
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    transform: 'translate(50%,-50%)',
                    borderRadius: '5px',
                    border: `3px solid ${theme.palette.primary.main}`,
                    minWidth: '350px',
                  }}
                >
                  <Box margin={2}>
                    <Typography style={{ marginRight: '10px', fontSize: '1rem' }}>
                      {t('csv.csvPreviewDialogLoadingImport')}
                    </Typography>
                  </Box>
                  <Box>
                    <CircularLoading />
                  </Box>
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Box
              marginTop={3}
              style={{
                height: '100px',
                borderRadius: '5px',
                border: `solid 2px #270ca2`,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                color: theme.palette.error.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
              }}
            >
              <Box style={{}}>
                {!isValidCsv() && (
                  <>
                    <Typography style={{ marginRight: '10px', fontSize: '1rem' }}>
                      {t('csv.csvPreviewErrorToManyColums', { maxNumColumns: MAX_NUM_COLUMNS })}
                    </Typography>
                  </>
                )}
                {!csvNotEmpty() && (
                  <>
                    <Typography style={{ marginRight: '10px', fontSize: '1rem' }}>
                      {t('csv.csvPreviewEmptyFile')}
                    </Typography>
                  </>
                )}
                <Typography style={{ marginRight: '10px', fontSize: '1rem' }}>
                  {t('csv.csvPreviewChangeFile')}
                </Typography>
              </Box>
              <Box>{showWarningBox(ImportDisabledIcon, theme.palette.error.main)}</Box>
            </Box>
          </>
        )}
        {isDialogOpen && (
          <ClosableDialog
            disableBackdropClick
            hideButton
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            maxWidth="xl"
            aria-labelledby="form-dialog-title"
          >
            {hasError ? (
              <>
                <DialogTitle>{t('csv.csvPreviewDialogImportFinishedErroTitle')}</DialogTitle>
              </>
            ) : (
              <>
                <DialogTitle>{t('csv.csvPreviewDialogImportFinishedTitle')}</DialogTitle>
              </>
            )}
            <DialogContent>
              <Box>
                {hasError ? (
                  <>
                    <Typography variant="body1">
                      {t('csv.csvPreviewDialogImportFinishedCheckConnection')}{' '}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Box marginBottom={1}>
                      <Typography variant="body1">
                        {`${importFeedback.length} / ${jsonCsv.length}`}
                        &nbsp;
                        {t('csv.csvPreviewDialogImportFinishedArticles')}
                      </Typography>
                    </Box>
                    <Box>
                      {response !== null && !!response.failedArticles?.length && (
                        <Typography variant="body1">
                          {t('csv.csvPreviewDialogImportFinishedArticlesNotImported')} &nbsp;
                          <CSVLink
                            filename={`import-errors.csv`}
                            asyncOnClick={true}
                            onClick={(_event, done) => {
                              if (window.Cypress) done(false)
                              else done(true)
                            }}
                            data={response.failedArticles || []}
                            target="_blank"
                          >
                            {t('csv.csvPreviewDialogImportFinishedLinkFile')}
                          </CSVLink>
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </Box>
              <Box textAlign={'center'} marginTop={3}>
                <Button
                  color="primary"
                  // variant="contained"
                  onClick={() => setIsDialogOpen(false)}
                  // onClick={() => tryImportAgainClicked()}
                  style={{ minWidth: '140px' }}
                  data-testid="try-import-again-btn"
                >
                  {t('csv.csvPreviewDialogTryAgain')}
                </Button>
                {!hasError && (
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        closeExternalDialog !== undefined && closeExternalDialog(false)
                      }
                      style={{ minWidth: '140px' }}
                      data-testid="go-to-import-btn"
                    >
                      {t('csv.csvPreviewDialogImport')}
                    </Button>
                  </>
                )}
              </Box>
            </DialogContent>
          </ClosableDialog>
        )}
      </Box>
    </Box>
  )
}
