import { Box, Typography, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TreeItem } from 'react-sortable-tree'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import { PricingPlan, PricingStrategy } from 'shared'
import { SetState } from 'types'
import { ConditionMakerPresenter } from './ConditionMakerPresenter'

interface PricingPlanSidebarProps {
  onStrategyDelete: (id: string) => void
  onStrategyChange: (newValue: PricingStrategy) => void
  onStrategyClone: (newValue: PricingStrategy) => void
  width: number
  pricingPlan: PricingPlan
  addRowMode: boolean
  setAddRowMode: SetState<boolean>
  mainTreeData: TreeItem[]
  changeTree: SetState<TreeItem[]>
  setHiddenAddButton: SetState<boolean>
}

export const PricingPlanSidebar = ({
  width,
  pricingPlan,
  addRowMode,
  setAddRowMode,
  mainTreeData,
  changeTree,
  setHiddenAddButton,
}: PricingPlanSidebarProps): JSX.Element => {
  const { palette } = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      position="absolute"
      style={{ backgroundColor: palette.lightBackground, overflowY: 'scroll' }}
      top="0"
      left="0"
      width={`${width}px`}
      height={'100%'}
    >
      <Box position="relative" padding={2} marginBottom={5}>
        <Box marginBottom={2}>
          <Box textAlign={'center'} marginBottom={1} display="block">
            <Typography color="primary" variant="h6">
              {t('priceUpdates.ruleDesigner')}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {t('priceUpdates.ruleDesignerExplainer')}
            </Typography>
          </Box>
          <ConditionMakerPresenter
            addRowMode={addRowMode}
            setAddRowMode={setAddRowMode}
            mainTreeData={mainTreeData}
            changeTree={changeTree}
            setHiddenAddButton={setHiddenAddButton}
            pricingPlan={pricingPlan}
          />
        </Box>
      </Box>
    </Box>
  )
}
