import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from '@material-ui/core'
import { ReactNode } from 'react'

export const SimpleSelect = <T extends string | number>({
  value,
  options,
  onChange,
  label,
  ...props
}: Omit<SelectProps, 'onChange' | 'value'> & {
  onChange: (val: T) => void
  value: T
  options: { value: T; label: string | ReactNode }[]
}): JSX.Element => {
  return (
    <FormControl style={{ width: '100%' }}>
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      <MuiSelect
        {...props}
        value={value}
        fullWidth
        onChange={(e) => {
          onChange(e.target.value as T)
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
