import { Box, Dialog } from '@material-ui/core'
import {
  CardDataItem,
  Expansion,
  InventoryArticle,
  LocalizedCard,
  RichArticle,
  Template,
} from 'shared'
import { SetState } from 'types'
import { ArticleEdit } from '../addArticles/AddArticleDock/ArticleEdit'
import { useEditArticleContext } from '../addArticles/useEditArticleContext'

export interface EditArticleModalProps {
  addByExp?: boolean
  disableHotkeys: boolean
  template: Template
  card: CardDataItem
  stopEdit?: () => void
  onInputChange?: (newValue: RichArticle | null) => void
  showExistingStock?: boolean
  editArticle?: (newArticle: InventoryArticle) => void
  copyEditedArticle?: (newArticle: InventoryArticle) => void
  open: boolean
  setOpen: SetState<boolean>
  expansion: (Expansion & { name: string }) | null
  counter?: number
  setCounter?: (value: number) => void
  cardsInExpansion?: LocalizedCard[]
}

export const EditArticleModal = ({
  addByExp,
  disableHotkeys,
  template,
  card,
  stopEdit,
  editArticle,
  copyEditedArticle,
  open,
  setOpen,
  expansion,
  counter,
  setCounter,
  cardsInExpansion,
}: EditArticleModalProps) => {
  const { articleToEdit, stopEditingArticle } = useEditArticleContext()

  const handleClose = () => {
    stopEditingArticle?.()
    stopEdit?.()
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="EditArticle"
      aria-describedby="EditArticleDescription"
      data-testid="article-edit-modal"
      BackdropProps={{
        style: {
          backgroundColor: 'rgb(0, 0, 0, 0.8)',
        },
      }}
      transitionDuration={50}
    >
      <Box padding={2}>
        <ArticleEdit
          addByExp={addByExp}
          disableHotkeys={disableHotkeys}
          template={template}
          card={card}
          newPrice={articleToEdit?.newPrice}
          stopEdit={handleClose}
          expansion={expansion}
          counter={counter}
          setCounter={setCounter}
          cardsInExpansion={cardsInExpansion}
          handleSave={(article, isCopied) => {
            if (isCopied && article instanceof InventoryArticle) {
              copyEditedArticle?.(article)
            } else if (article instanceof RichArticle) {
              const editedArticle = new InventoryArticle({
                ...article.toPlainObject(),
                idArticle: articleToEdit ? articleToEdit.idArticle : undefined,
                failedAutopricing: articleToEdit ? articleToEdit.failedAutopricing : false,
              })
              editArticle?.(editedArticle)
              handleClose()
            }
          }}
        />
      </Box>
    </Dialog>
  )
}
