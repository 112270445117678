import { SyncInventoryPresenter } from 'components/domain/inventory/SyncInventoryPresenter'
import { AppLayout } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const Inventory = (): JSX.Element => {
  const { user } = useUser()

  if (!user || user?.subscription.allFeaturesDisabled || !user.isAdmin) {
    return <Redirect to="/" />
  }

  return (
    <AppLayout noDOMLayout>
      {/* <PTInventoryPresenter /> */}
      {/* <AddArticlesPresenter /> */}
      <SyncInventoryPresenter />
    </AppLayout>
  )
}
