import { Box, Divider, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { Button } from 'components/facets'
import { useCardData, useExpansionData } from 'components/providers/CardDataProvider'
import { useUser } from 'components/providers/UserProvider'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Expansion, GameRarity, InventoryArticle, isSingle, RichArticle } from 'shared'
import { v4 as uuid } from 'uuid'
import { ProfiAddArticlesProps } from './ProfiAddArticles'

export const BulkAddByExp = ({
  template,
  onAddArticles,
}: Omit<ProfiAddArticlesProps, 'disableHotkeys'>): JSX.Element => {
  const expansions = useExpansionData()
  const { t } = useTranslation()
  const { activeGame } = useUser()
  const [expansion, setExpansion] = useState<Expansion | null>(null)

  const initialQuantities: Partial<Record<GameRarity, number>> = {}
  Object.keys(activeGame.rarities).forEach(
    (key) => (initialQuantities[activeGame.rarities[Number(key)].name] = 0)
  )

  const [quantities, setQuantities] = useState<Record<string, number>>(
    initialQuantities as Record<string, number>
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist()
    setQuantities((quantities) => ({
      ...quantities,
      [e.target.name]: Number(e.target.value),
    }))
  }

  const cards = useCardData()
    .filter((card) => card.set === expansion?.code && isSingle(card))
    .sort((a, b) =>
      a.rarity === b.rarity ? a.name.localeCompare(b.name) : a.rarity < b.rarity ? 1 : -1
    )

  const handleSave = async (): Promise<void> => {
    const articlesFromExp: RichArticle[] = []

    cards.forEach((card) => {
      const quantity = Number(quantities[activeGame.rarities[card.rarity].name])
      if (quantity) {
        const newThing = new InventoryArticle({
          ...template,
          card: card.toPlainObject(),
          id: uuid(),
        })

        newThing.quantity = quantity

        articlesFromExp.push(newThing)
      }
    })

    const inventoryArticlesFromExp = articlesFromExp.map((article) => {
      return new InventoryArticle(article)
    })
    onAddArticles(inventoryArticlesFromExp as InventoryArticle[])
  }

  const countsPerRarity = Object.keys(activeGame.rarities).map((rarityKey) => ({
    name: activeGame.rarities[Number(rarityKey)].name,
    count: cards.filter(({ rarity }) => rarity === Number(rarityKey)).length,
  }))

  const totalArticlesToAdd = Object.keys(activeGame.rarities).reduce(
    (acc, val) =>
      acc +
      Number(!!quantities[activeGame.rarities[Number(val)].name]) *
        countsPerRarity[Number(val) - 1]?.count,
    0
  )
  const totalCardsToAdd = Object.keys(activeGame.rarities).reduce(
    (acc, val) =>
      acc +
      quantities[activeGame.rarities[Number(val)].name] * countsPerRarity[Number(val) - 1]?.count,
    0
  )
  return (
    <>
      <Autocomplete<Expansion>
        options={expansions.sort((a, b) => a.name.localeCompare(b.name))}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue)
          const parts = parse(option.name, matches)

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          )
        }}
        value={expansion}
        getOptionLabel={(option) => `${option.name} ${option.code}`}
        onChange={async (_e, newValue) => {
          setExpansion(newValue)
          setQuantities(initialQuantities as Record<string, number>)
        }}
        data-testid="add-by-expansion-input"
        autoHighlight
        getOptionSelected={(option, value) => option.name === value.name}
        renderInput={(params) => (
          <TextField
            {...params}
            name="expansion"
            margin="dense"
            label={t('card.expansion')}
            type="text"
            autoFocus
            fullWidth
          />
        )}
      />

      {expansion && (
        <>
          <Grid container>
            <Box>
              <Grid container>
                <Grid item xs={9}>
                  <b>TOTAL</b>
                </Grid>
                <Grid item xs={3}>
                  <b data-testid="all-card-number">{cards.length}</b>
                </Grid>
                <Divider />
                {countsPerRarity.map((rarity, idx) => (
                  <React.Fragment key={idx}>
                    <Grid item xs={9}>
                      {rarity.name}
                    </Grid>
                    <Grid item xs={3} data-testid={`${rarity.name}-qtx`}>
                      {rarity.count}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            {Object.keys(quantities).map((key) => (
              <Grid style={{ padding: '2px' }} key={key} item xs={4}>
                <TextField
                  name={key}
                  margin="dense"
                  value={quantities[key]}
                  onChange={handleChange}
                  label={key}
                  disabled={countsPerRarity.find(({ name }) => name === key)?.count === 0}
                  type="number"
                  fullWidth
                  data-testid={`input-value-${key}`}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            ))}
          </Grid>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            fullWidth
            disabled={totalArticlesToAdd === 0}
            style={{ marginTop: '10px' }}
            data-testid="add-by-bulk-btn"
          >
            {totalArticlesToAdd === 0
              ? t('addArticles.chooseQuanties')
              : t('addArticles.addCards', { count: totalArticlesToAdd }) +
                '\n ' +
                t('addArticles.numberOfCards', { count: totalCardsToAdd })}
          </Button>
        </>
      )}
    </>
  )
}
