import { ListItem } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import playsetIcon from 'img/playsetIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { currencySymbol } from 'lib/utils'
import Flag from 'react-world-flags'
import { CardmarketArticle, REPUTATIONS, SellerLevel, SELLER_LEVELS } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { Condition } from './Condition'
import { CustomTooltip } from './CustomTooltip'
import { AllSellersIcon, PowerSellerSellersIcon, ProfessionalSellersIcon } from './Icons'

export const ArticleLine = ({ article }: { article: CardmarketArticle }): JSX.Element => {
  const {
    quantity,
    isFoil,
    isPlayset,
    isFirstEd,
    isSigned,
    condition,
    isReverseHolo,
    idLanguage,
    seller,
  } = article

  const { user } = useUser()

  const price = article[user?.currency === 'GBP' ? 'priceGBP' : 'price']

  const SellerIcon = {
    [SellerLevel.Private]: AllSellersIcon,
    [SellerLevel.Professional]: ProfessionalSellersIcon,
    [SellerLevel.Powerseller]: PowerSellerSellersIcon,
  }[seller.isCommercial]

  return (
    <ListItem>
      {quantity}x <Condition id="ArticleLine-condition" value={condition} />{' '}
      <Flag
        style={{ padding: '0px 5px' }}
        //@ts-ignore
        fallback={<span>Unknown</span>}
        height="12"
        code={LANGUAGE_MAP_ICONS[idLanguage] || 'gg'}
      />{' '}
      {isFoil && <img alt="foil" height="18px" src={foilIcon} />}{' '}
      {isReverseHolo && <img alt="reverseholo" height="18px" src={reverseHoloIcon} />}{' '}
      {isPlayset && <img alt="playset" height="18px" src={playsetIcon} />}
      {isFirstEd && <img alt="firstEd" height="18px" src={firstEdIcon} />}
      {isSigned && <img alt="signed" height="18px" src={signedIcon} />}
      {` | ${seller.username}(${seller.sellCount}) | ${seller.country} | 
        `}
      <CustomTooltip title={`${SELLER_LEVELS[Number(seller.isCommercial)]}`}>
        <SellerIcon />
      </CustomTooltip>
      {`
      | ${REPUTATIONS[Number(seller.reputation)]} | `}
      {price}
      {currencySymbol(user?.currency)}
    </ListItem>
  )
}
