import { IconButton, SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

interface BooleanIconButtonProps {
  disabled?: boolean
  active: boolean
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  dataTestid?: string
}

export const BooleanIconButton = ({
  disabled,
  active,
  Icon,
  dataTestid,
}: BooleanIconButtonProps): JSX.Element => (
  <IconButton
    style={{ cursor: disabled ? 'help' : 'pointer' }}
    disableRipple={disabled}
    size="small"
    color={active ? 'primary' : 'default'}
    data-testid={`${dataTestid}`}
  >
    <Icon />
  </IconButton>
)
