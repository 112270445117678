import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { ArrowDropDown, HelpOutline, Mood, SentimentDissatisfied } from '@material-ui/icons'

export const OrdersWelcomeTable = (): JSX.Element => {
  const useStyles = makeStyles(() => ({
    column: {
      width: '65%',
      margin: 'auto',
    },
    pill: {
      width: '65%',
      margin: 'auto',
      backgroundColor: '#ececee',
      borderRadius: '10px',
      paddingTop: '3px',
      paddingBottom: '3px',
    },
    specialPill: {
      width: '65%',
      margin: 'auto',
      backgroundColor: '#6c62a8',
      color: 'white',
      borderRadius: '10px',
      paddingTop: '3px',
      paddingBottom: '3px',
    },
    downArrow: {
      marginTop: '2px',
      marginBottom: '-3px',
    },
  }))

  const styles = useStyles()

  return (
    <>
      <Grid container style={{ textAlign: 'center' }}>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <SentimentDissatisfied fontSize="large" color="primary" />
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Standard
              </Typography>
            </Box>
            <Box className={styles.pill}>New order (unpaid)</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.pill}>New order (paid)</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
          </Grid>
          <Grid item xs={4}>
            <Mood fontSize="large" color="primary" />
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                PowerTools
              </Typography>
            </Box>
            <Box className={styles.pill}>New order (unpaid)</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.pill}>New order (paid)</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} style={{ alignSelf: 'center' }}>
            <Box className={styles.column}>
              <HelpOutline fontSize="large" />
              <Typography variant="subtitle2">
                You have no way to manage what happens to your order while being prepared
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ alignSelf: 'center' }}>
            <Box className={styles.specialPill}>Picking</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.specialPill}>Packing</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.specialPill}>Shipping</Box>
          </Grid>
          <Grid item xs={4} style={{ alignSelf: 'center', textAlign: 'left' }}>
            <Typography variant="subtitle2" color="primary">
              We added more steps based on the most widespread order handling methods
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.pill}>Shipped order</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.pill}>Concluded (arrived)</Box>
          </Grid>
          <Grid item xs={4}>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.pill}>Shipped order</Box>
            <ArrowDropDown color="primary" className={styles.downArrow} />
            <Box className={styles.pill}>Concluded (arrived)</Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
    </>
  )
}
