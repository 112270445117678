import { Box, Grid, MenuItem, Select, Switch, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { useUser } from 'components/providers/UserProvider'
import { SetLocalStorageValueFunction } from 'lib/hooks'
import { useStockStore } from 'lib/hooks/useStockStore'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CARD_LANGUAGES, Template } from 'shared'
import { CONDITIONS } from 'utils/constants'

interface ArticleTemplateProps {
  template: Template
  setTemplate: SetLocalStorageValueFunction<Template>
}

export const ArticleTemplate = ({ template, setTemplate }: ArticleTemplateProps): JSX.Element => {
  const { activeGame } = useUser()
  const { t } = useTranslation()
  const [inputs, setInputs] = useState<Template>(template)
  const activeStockType = useStockStore((state) => state.activeStockType)

  const sanitizeInput = (fieldName: string, fieldValue: any): any => {
    if (fieldName === 'price' && fieldValue < 0) {
      return 0.01
    }
    if (fieldName === 'quantity' && fieldValue < 0) {
      return 1
    }
    return fieldValue
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: React.ChangeEvent<any>): void => {
    if (e.target.type === 'checkbox') {
      setInputs({ ...inputs, [e.target.name]: e.target.checked })
    } else {
      const sanitizedValue = sanitizeInput(e.target.name, e.target.value)
      setInputs({ ...inputs, [e.target.name]: sanitizedValue })
    }
  }

  React.useEffect(() => {
    setTemplate(inputs)
  }, [inputs])

  const ExtraAttributes = (): JSX.Element => {
    return (
      <>
        {activeGame.extraAttr.map((attribute) => {
          return (
            <Grid container key={attribute}>
              <Grid item xs={8} style={{ margin: 'auto' }}>
                <Typography>{t(`common.${attribute}`)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  color="primary"
                  name={attribute}
                  checked={!!inputs[attribute as keyof typeof template]}
                  onChange={handleChange}
                ></Switch>
              </Grid>
            </Grid>
          )
        })}
      </>
    )
  }

  return (
    <>
      <Box data-testid="template-form" display="flex">
        <Grid container>
          <Grid item xs={8} style={{ margin: 'auto' }}>
            <Typography>{t('card.quantity')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={inputs.quantity}
              onChange={handleChange}
              margin="dense"
              type="number"
              name="quantity"
              data-testid="template-quantity"
              fullWidth
            />
          </Grid>

          <Grid item xs={8} style={{ margin: 'auto' }}>
            <Typography>{t('card.price')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={inputs.price}
              onChange={handleChange}
              margin="dense"
              type="number"
              name="price"
              data-testid="template-price"
              fullWidth
            />
          </Grid>

          {activeStockType === 'singles' && (
            <>
              <Grid item xs={8} style={{ margin: 'auto' }}>
                <Typography>{t('card.condition')}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  value={inputs.condition}
                  name="condition"
                  data-testid="template-condition"
                  onChange={handleChange}
                >
                  {CONDITIONS.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </>
          )}

          <Grid item xs={8} style={{ margin: 'auto' }}>
            <Typography>{t('card.language')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Select
              fullWidth
              value={inputs.idLanguage}
              name="idLanguage"
              data-testid="template-idLanguage"
              onChange={handleChange}
            >
              {CARD_LANGUAGES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {activeStockType === 'singles' && <ExtraAttributes />}

          <Grid container>
            <Grid item xs={12}>
              <TextField
                value={inputs.comment}
                onChange={handleChange}
                name="comment"
                data-testid="template-comment"
                margin="dense"
                helperText={t('card.comment')}
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
