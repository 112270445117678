import hotkeys from 'hotkeys-js'
import { useCallback, useEffect } from 'react'

export function useScopelessHotkeys(
  key: string,
  callback: (e: KeyboardEvent) => void,
  deps: unknown[] = []
): void {
  const memoisedCallback = useCallback(callback, deps)

  useEffect(() => {
    hotkeys.filter = () => {
      return true
    }
    hotkeys(key, memoisedCallback)

    return () => hotkeys.unbind(key)
  }, [memoisedCallback, key])
}
