import { Box, makeStyles, Typography } from '@material-ui/core'
import { AppLayout } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const Jobs = (): JSX.Element => {
  const { user } = useUser()

  if (!user) {
    return <Redirect to="/" />
  }

  const Section = ({ title, children }: { title: string; children: JSX.Element }) => (
    <Box my={5}>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body1">{children}</Typography>
    </Box>
  )

  const SplashTitle = () => {
    const useStyles = makeStyles(() => ({
      root: {
        backgroundColor: '#b5bbdc',
        borderRadius: '30px',
        background: 'linear-gradient(90deg, #b5bbdc 0%, #685ca4 100%)',
        color: 'white',
      },
    }))
    const classes = useStyles()

    return (
      <Box p={5} className={classes.root}>
        <Typography variant="h3" align="center">
          <b>WORK WITH US!</b>
        </Typography>
      </Box>
    )
  }

  return (
    <AppLayout noDOMLayout>
      <Box m={5} maxWidth={1600}>
        <SplashTitle />

        <Section title="Job Announcement: Customer Care Representative">
          <>
            <p>
              <b>Position</b>: Customer Care Representative
            </p>
            <p>
              <b>Location</b>: Remote (Europe)
            </p>
            <p>
              <b>Employment Type</b>: Full-time
            </p>
          </>
        </Section>

        <Section title="About Us">
          <>
            At TCG PowerTools, we pride ourselves on delivering exceptional service to our
            customers. As one of the leading softwares in the TCG business, we value dedication,
            innovation, and a customer-first attitude. Join our team and be part of a dynamic,
            growing company that values its employees and strives for excellence.
          </>
        </Section>

        <Section title="Job Description">
          <>
            We are seeking a passionate and dedicated Customer Care Representative to join our team.
            The ideal candidate will be the first point of contact for our customers, providing
            exceptional support and ensuring their needs are met with professionalism and
            efficiency.
          </>
        </Section>

        <Section title="Responsibilities">
          <>
            <ul>
              <li>
                Respond to customer inquiries via chat and email in a timely and professional
                manner.
              </li>
              <li>
                Resolve customer issues and complaints with empathy and efficiency, ensuring
                customer satisfaction.
              </li>
              <li>
                Maintain a deep understanding of our products and services to provide accurate
                information to customers.
              </li>
              <li>Collaborate with other departments to resolve complex customer issues.</li>
              <li>
                Provide feedback to management regarding customer needs and suggestions for
                improvements.
              </li>
            </ul>
          </>
        </Section>

        <Section title="Qualifications">
          <>
            <ul>
              <li>
                Proven experience in a customer service role, preferably in a call center or similar
                environment.
              </li>
              <li>Excellent communication skills, both written and verbal.</li>
              <li>Problem-solving skills and the ability to think on your feet.</li>
              <li>Ability to remain calm and professional under pressure.</li>
              <li>Ability to work flexible hours, including evenings and weekends if required.</li>
            </ul>
          </>
        </Section>

        <Section title="What We Offer">
          <>
            <ul>
              <li>Competitive salary.</li>
              <li>Opportunities for professional growth and development.</li>
              <li>A supportive and collaborative team environment.</li>
            </ul>
          </>
        </Section>

        <Section title="How to Apply">
          <>
            If you are passionate about customer service and are looking for a rewarding career with
            a dynamic and quickly growing company, we want to hear from you! Please submit your
            resume and a cover letter detailing your relevant experience and why you would be a
            great fit for this role to{' '}
            <a href="mailto:team@tcgpowertools.com?subject=Custom Care Application">
              team@tcgpowertools.com
            </a>
            .
          </>
        </Section>
      </Box>
    </AppLayout>
  )
}
