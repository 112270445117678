import { AppLayout } from 'components/facets'
import { AccountPresenter } from '../domain/account'

export const Account = (): JSX.Element => {
  return (
    <AppLayout>
      <AccountPresenter />
    </AppLayout>
  )
}
