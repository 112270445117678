import { Box, Dialog, makeStyles, Typography } from '@material-ui/core'
import { PlayCircleFilled } from '@material-ui/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface YoutubeEmbedProps {
  title: string
  videoId: string
}

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%', // 16:9 aspect ratio
    paddingTop: '30px',
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  container: {
    position: 'relative',
  },
  overlayTop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    textAlign: 'center',
    marginTop: '29%',
  },
  overlayBottom: {
    opacity: 0.5,
    zIndex: 0,
  },
  text: {
    color: 'white',
    fontSize: '16px',
    textAlign: 'center',
  },
}))

export const YoutubeEmbed = ({ title, videoId }: YoutubeEmbedProps): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const classes = useStyles()

  const url = `https://www.youtube.com/embed/${videoId}`
  const thumbnailUrl = `http://img.youtube.com/vi/${videoId}/0.jpg`

  return (
    <>
      <Box margin={2} width={200} onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
        <Box marginBottom={1}>
          <Typography variant="body1">{t(`videoTutorials.${title}`)}</Typography>
        </Box>
        <div className={classes.container}>
          <div className={classes.overlayTop}>
            <PlayCircleFilled fontSize="large" />
          </div>
          <div className={classes.overlayBottom}>
            <img src={thumbnailUrl} width="200px" />
          </div>
        </div>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <Box className={classes.videoContainer}>
          <iframe
            src={url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube video"
          />
        </Box>
      </Dialog>
    </>
  )
}
