import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { Button, CustomTooltip } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { theme } from '../../../config'
import { AccountSettingsPresenter } from './AccountSettingsPresenter'
import { SepaMandatePresenter } from './SepaMandatePresenter'
import { SubscriptionPresenter } from './SubscriptionPresenter'

type OutsetaTab = 'plan' | 'profile' | 'billing' | 'sepaMandate' | 'settings'

const useStyles = makeStyles(() => ({
  root: {
    '& .o--Widget--widget': {
      maxWidth: '45vw',
      overflow: 'hidden',
    },
    '& .o--Profile--profile-photo': {
      display: 'none',
    },
    '& .o--NavDesktop--navDesktop': {
      display: 'none!important',
    },
    '& h1': {
      display: 'none!important',
    },
  },
}))

export const AccountPresenter = (): JSX.Element => {
  const { t } = useTranslation()
  const { user, fetchUser } = useUser()
  const [activeTab, setActiveTab] = useState<OutsetaTab>('profile')
  const { search } = useLocation()

  if (!user) return <></>

  const query = qs.parse(search)

  const styles = useStyles()

  //@ts-ignore
  window.o_options = {
    id: 'Outseta',
    domain: 'mtg-powertools.outseta.com',
    load: 'profile',
    tokenStorage: 'local',
    profile: {
      id: `profile_embed-${uuid()}`,
      mode: 'embed',
      tab: activeTab,
      selector: '#profile-embed',
    },
  }

  const changeTab = (tab: OutsetaTab): void => {
    //@ts-ignore
    window?.Outseta?.profile?.setTab(tab)
    setActiveTab(tab)
  }

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cdn.outseta.com/outseta.min.js'
    //@ts-ignore
    script['data-o-options'] = 'o_options'

    document.body.appendChild(script)

    return () => {
      //@ts-ignore
      if (window.Outseta) window.Outseta = null
      document.body.removeChild(script)
      return
    }
  }, [])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null
    if (activeTab === 'billing') {
      interval = setInterval(fetchUser, 3000)
    }

    return () => {
      if (interval) clearInterval(interval)
      return
    }
  }, [activeTab])

  useEffect(() => {
    if (query.success) {
      changeTab('sepaMandate')
    }
  }, [])

  const tabs = [
    {
      tab: 'profile',
      transKey: 'profile',
      disabled: false,
      disabledTooltip: null,
      dataTestid: 'profile-tab',
    },
    {
      tab: 'plan',
      transKey: 'plan',
      disabled: false,
      disabledTooltip: null,
      dataTestid: 'plan-tab',
    },
    {
      tab: 'billing',
      transKey: 'creditCard',
      disabled: !!user.stripePaymentMethod,
      disabledTooltip: 'removeMandateFirst',
      dataTestid: 'add-credit-card-tab',
    },
    {
      tab: 'sepaMandate',
      transKey: 'sepaMandate.title',
      disabled: !!user.outseta?.hasCreditCard,
      disabledTooltip: 'removeCardFirst',
      dataTestid: 'sepa-mandate-tab',
    },
    {
      tab: 'settings',
      transKey: 'settings',
      disabled: false,
      disabledTooltip: null,
      dataTestid: 'settings-tab',
    },
  ] as const

  return (
    <Box>
      <Typography variant="h4">{t('account.title')}</Typography>
      <Box marginTop={4} className={styles.root}>
        <Grid container spacing={3} direction="row" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box style={{ position: 'relative', bottom: '0px' }}>
              <Box style={{ display: 'flex' }}>
                {tabs.map(({ tab, transKey, disabled, disabledTooltip, dataTestid }) => (
                  <CustomTooltip key={tab} title={disabled ? `account.${disabledTooltip}` : ''}>
                    <Box padding={2}>
                      <Button
                        onClick={() => changeTab(tab)}
                        disabled={disabled}
                        style={{
                          color:
                            activeTab === tab ? theme.palette.primary.main : theme.palette.black,
                          fontSize: '17px',
                          textTransform: 'capitalize',
                          maxWidth: '130px',
                        }}
                        data-testid={dataTestid}
                      >
                        {t(`account.${transKey}`)}
                      </Button>
                    </Box>
                  </CustomTooltip>
                ))}
              </Box>
              {activeTab === 'plan' && <SubscriptionPresenter subscription={user.subscription!} />}
              {activeTab === 'sepaMandate' && (
                <Box paddingLeft={3}>
                  <SepaMandatePresenter />
                </Box>
              )}
              <Box
                style={{
                  display: ['profile', 'billing'].includes(activeTab) ? 'initial' : 'none',
                }}
              >
                <Box id="profile-embed" style={{ float: 'left' }}></Box>
              </Box>
              {activeTab === 'settings' && (
                <Box paddingLeft={3}>
                  <AccountSettingsPresenter />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={2}>
        {!user.outseta?.accessToken && (
          <>
            <Typography variant="body1">{t('account.changeSettingsUnavailable')}</Typography>
          </>
        )}
      </Box>
    </Box>
  )
}
