import { Box, Typography } from '@material-ui/core'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import playsetIcon from 'img/playsetIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { CardLanguage, RichArticle } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { Condition } from '../../../../facets/Condition'

interface ExistingArticleProps {
  article: RichArticle
  showCard?: boolean
  showPrice?: boolean
  showComment?: boolean
  lineBreak?: boolean
  hideTitle?: boolean
}

export const ExistingArticle = (props: ExistingArticleProps): JSX.Element => {
  const {
    article: {
      isFoil,
      isPlayset,
      condition,
      idLanguage,
      isFirstEd,
      isSigned,
      quantity,
      isReverseHolo,
      comment,
      price,
    },
  } = props
  const { t } = useTranslation()
  return (
    <Box padding={1}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {quantity}x
          <Condition id="ExistingArticle-condition" value={condition} />{' '}
          <Flag
            style={{ padding: '0px 5px' }}
            //@ts-ignore
            fallback={<span>Unknown</span>}
            height="12"
            data-testid={`${CardLanguage[idLanguage]}-flag`}
            code={LANGUAGE_MAP_ICONS[idLanguage] || 'gg'}
          />{' '}
          {isFoil && <img data-testid="is-foil" alt="foil" height="18px" src={foilIcon} />}{' '}
          {isPlayset && (
            <img data-testid="is-playset" alt="playset" height="18px" src={playsetIcon} />
          )}
          {isFirstEd && (
            <img data-testid="is-first-ed" alt="first edition" height="18px" src={firstEdIcon} />
          )}
          {isReverseHolo && (
            <img
              data-testid="is-first-ed"
              alt="first edition"
              height="18px"
              src={reverseHoloIcon}
            />
          )}
          {isSigned && (
            <img data-testid="is-signed" alt="first edition" height="18px" src={signedIcon} />
          )}
        </Box>
        <Typography component={'span'} color="primary">
          {price}€
        </Typography>
      </Box>
      <Box
        style={{
          textAlign: 'initial',
        }}
      >
        {comment ? (
          <Typography
            style={{
              width: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {comment}
          </Typography>
        ) : (
          <Typography color="textSecondary">
            <i>{t('common.noComment')}</i>
          </Typography>
        )}
      </Box>
    </Box>
  )
}
