import {
  Box,
  Checkbox,
  makeStyles,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { SealedIcon } from 'components/facets'
import { useOrderStore } from 'lib/hooks'
import { getGameCodeFromName, truncateText } from 'lib/utils'
import { useEffect, useState } from 'react'
import { AutoSizer, Column, Index, Table } from 'react-virtualized'
import Flag from 'react-world-flags'
import { getOrderState, Order, orderContainsSealed } from 'shared'
import { ColorBox } from './ColorBox'

export interface CompactOrdersTableProps {
  handleSelectRow: (e: any, idOrder: number) => void
}

export const CompactOrdersTable = ({ handleSelectRow }: CompactOrdersTableProps): JSX.Element => {
  // const { t } = useTranslation()
  const filteredOrders = useOrderStore((state) => state.filteredOrders)
  const filteredArticles = useOrderStore((state) => state.filteredArticles)
  const orders = useOrderStore((state) => state.orders)
  const articles = useOrderStore((state) => state.articles)
  const activeTab = useOrderStore((state) => state.activeTab)
  const expandedOrder = useOrderStore((state) => state.expandedOrder)
  const setExpandedOrder = useOrderStore((state) => state.setExpandedOrder)
  const expandedRowIndex = useOrderStore((state) => state.expandedRowIndex)
  const setExpandedRowIndex = useOrderStore((state) => state.setExpandedRowIndex)
  const selectedOrderIds = useOrderStore((state) => state.selectedOrderIds)
  const setFilteredArticles = useOrderStore((state) => state.setFilteredArticles)

  const MAX_CHARS = 20

  useEffect(() => {
    if (filteredArticles && filteredArticles.length > 0) {
      const idOrder = filteredArticles[0].idOrder
      const order = orders.find((o) => o.idOrder === idOrder)
      const newFilteredArticles = filteredArticles.map((oa) => {
        return order?.article.find((a) => a.idArticle === oa.idArticle) || oa
      })
      setFilteredArticles(newFilteredArticles)
    }
  }, [orders])

  useEffect(() => {
    setFilteredArticles(articles)
  }, [articles])

  const getFlagFromCountry = (country: string | undefined) => {
    if (country === 'D') {
      country = 'DE'
    }

    return (
      <Flag
        fallback={<span>Unknown</span>}
        height="10px"
        code={country || 'gg'}
        style={{ alignSelf: 'center' }}
      />
    )
  }

  const handleExpandRow = (order: Order, rowIndex: number) => {
    setExpandedOrder(order)
    setExpandedRowIndex(rowIndex)
  }

  const handleReduceRow = () => {
    setExpandedOrder(undefined)
    setExpandedRowIndex(undefined)
  }

  const useStyles = makeStyles(() => ({
    row: {
      borderBottom: 'solid 1px lightgrey',
    },
    selectedRow: {
      backgroundColor: 'lightgrey',
    },
  }))
  const styles = useStyles()

  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  //update windows size when change, so we have responsive design
  useEffect(() => {
    function updateWindowDimensions() {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <>
      <TableContainer component={Paper}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              width={width}
              height={windowHeight - 300}
              headerHeight={30}
              rowHeight={45}
              rowCount={filteredOrders.length}
              rowGetter={({ index }) => filteredOrders[index]}
              rowClassName={(info: Index) => {
                if (info.index === expandedRowIndex) {
                  return styles.selectedRow
                }

                return styles.row
              }}
            >
              {activeTab === 1 && (
                <Column
                  width={70}
                  label="Select"
                  dataKey=""
                  cellRenderer={({ rowData: order }) => (
                    <Checkbox
                      checked={selectedOrderIds.includes(order.idOrder)}
                      disabled={getOrderState(order) === 'picking'}
                      onClick={(e: any) => handleSelectRow(e, order.idOrder)}
                    />
                  )}
                />
              )}

              <Column
                width={width * 0.17}
                label="Status"
                dataKey=""
                cellRenderer={({ rowData: order }) => (
                  <ColorBox
                    type="state"
                    name={getOrderState(order)}
                    state={getOrderState(order)}
                    active={true}
                  ></ColorBox>
                )}
              />

              <Column
                width={25}
                label=""
                dataKey=""
                cellRenderer={({ rowData: order }) => (
                  <>
                    <Tooltip title={order.pt.articleGames}>
                      <span
                        className={`ss-${getGameCodeFromName(
                          order.pt.articleGames
                        )} ss-game-icon ss-fw`}
                        style={{ fontSize: '1.5rem' }}
                      ></span>
                    </Tooltip>
                  </>
                )}
              />

              <Column
                width={width * 0.06}
                label=""
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order }) => (
                  <>
                    {orderContainsSealed(order) && (
                      <Tooltip title="Contains sealed">
                        <span>
                          <SealedIcon style={{ verticalAlign: 'middle' }} />
                        </span>
                      </Tooltip>
                    )}
                  </>
                )}
              />

              <Column
                width={width * 0.3}
                label="Buyer"
                dataKey=""
                cellRenderer={({ rowData: order }) => (
                  <>
                    <Box display="flex">
                      {getFlagFromCountry(order.shippingAddress?.country)}
                      <Box paddingLeft={1}>{truncateText(order.buyer.username, MAX_CHARS)}</Box>
                    </Box>
                    <Box>{order.buyer.address.name}</Box>
                  </>
                )}
              />

              {[1, 3].includes(activeTab) && (
                <Column
                  width={width * 0.07}
                  label="PID"
                  dataKey=""
                  cellRenderer={({ rowData: order }) => (
                    <>
                      {order.pt.picking && order.pt.picking.pickingId
                        ? order.pt.picking.pickingId
                        : '-'}
                    </>
                  )}
                />
              )}

              <Column
                width={90}
                label=""
                dataKey=""
                cellRenderer={({ rowData: order, rowIndex }) => (
                  <>
                    {order.idOrder === expandedOrder?.idOrder ? (
                      <Box onClick={() => handleReduceRow()} style={{ cursor: 'pointer' }}>
                        <Box display="flex" alignItems="end">
                          <Typography style={{ fontSize: '0.65rem' }}>{order.idOrder}</Typography>
                          <ArrowBackIos fontSize="small" style={{ marginLeft: '10px' }} />
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        onClick={() => handleExpandRow(order, rowIndex)}
                        style={{ cursor: 'pointer' }}
                      >
                        <Box display="flex" alignItems="end">
                          <Typography style={{ fontSize: '0.65rem' }}>{order.idOrder}</Typography>
                          <ArrowForwardIos fontSize="small" style={{ marginLeft: '10px' }} />
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              />
            </Table>
          )}
        </AutoSizer>
      </TableContainer>
    </>
  )
}
