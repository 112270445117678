import { Backdrop, Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { exportOrders, fetchOrdersFromCardmarket } from 'lib/api'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  escapeDoubleQuotes,
  getCardmarketOrderPhoneNumber,
  getYesterday,
  isValidDate,
  MKMOrder,
  MKMOrderData,
} from 'shared'

export const CardmarketSalesPresenter = (): JSX.Element => {
  const { t } = useTranslation()
  const { user, fetchUser } = useUser()
  const theme = useTheme()

  const [loading, setLoading] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(getYesterday())

  const EXPORT_WITHOUT_ARTICLES_HEADERS = [
    'idOrder',
    'buyer',
    'seller',
    'status',
    'dateBought',
    'datePaid',
    'dateSent',
    'dateReceived',
    'dateLost',
    'dateCancelled',
    'shippingMethod',
    'trackingNumber',
    'articles',
    'articleValue',
    'totalValue',
    'currencyCode',
    'temporaryEmail',
    'isPresale',
    'shippingAddressName',
    'shippingAddressExtra',
    'shippingAddressStreet',
    'shippingAddressCity',
    'shippingAddressZip',
    'shippingAddressCountry',
    'phone',
    'buyerVAT',
    'note',
  ]

  const EXPORT_WITH_ARTICLES_HEADERS = [
    ...EXPORT_WITHOUT_ARTICLES_HEADERS,
    'items',
    'idArticle',
    'idProduct',
    'expansion',
    'name',
    'idLanguage',
    'language',
    'condition',
    'price',
    'comments',
    'isFoil',
    'isReverseHolo',
    'isSigned',
    'isAltered',
    'isPlayset',
    'isFirstEd',
  ]

  const getOrderRowFromOrder = (o: MKMOrderData) => {
    return {
      idOrder: o.idOrder,
      buyer: o.buyer.username,
      seller: user.cardmarketUsername,
      status: o.state.state,
      dateBought: o.state.dateBought,
      datePaid: o.state.datePaid,
      dateSent: o.state.dateSent,
      dateReceived: o.state.dateReceived,
      dateLost: o.state.dateLost,
      dateCancelled: o.state.dateCanceled,
      shippingMethod: `"${escapeDoubleQuotes(o.shippingMethod?.name)}"`,
      trackingNumber: o.trackingNumber,
      articles: o.articleCount,
      articleValue: o.articleValue,
      totalValue: o.totalValue,
      currencyCode: o.currencyCode,
      temporaryEmail: o.temporaryEmail,
      isPresale: o.isPresale,
      shippingAddressName: `"${escapeDoubleQuotes(o.shippingAddress?.name)}"`,
      shippingAddressExtra: `"${escapeDoubleQuotes(o.shippingAddress?.extra)}"`,
      shippingAddressStreet: `"${escapeDoubleQuotes(o.shippingAddress?.street)}"`,
      shippingAddressCity: `"${escapeDoubleQuotes(o.shippingAddress?.city)}"`,
      shippingAddressZip: o.shippingAddress?.zip,
      shippingAddressCountry: `"${escapeDoubleQuotes(o.shippingAddress?.country)}"`,
      phone: getCardmarketOrderPhoneNumber(o),
      buyerVAT: o.buyer.vat,
      note: `"${escapeDoubleQuotes(o.note)}"`,
    }
  }

  const getArticleCsvData = (orders: MKMOrder[]) => {
    const output: {
      idOrder: number
      buyer: string | undefined
      seller: string | undefined
      status: string | undefined
      dateBought: Date | undefined
      datePaid: Date | undefined
      dateSent: Date | undefined
      dateReceived: Date | undefined
      dateLost: Date | undefined
      dateCancelled: Date | undefined
      items: number
      idArticle: number
      idProduct: number
      expansion: string
      name: string
      idLanguage: number
      language: string
      condition: string
      price: number
      comments: string
      isFoil: boolean
      isReverseHolo: boolean
      isSigned: boolean
      isAltered: boolean
      isPlayset: boolean
      isFirstEd: boolean
    }[] = []
    orders.forEach((o) => {
      o.data.article.forEach((a) => {
        const orderRow = getOrderRowFromOrder(o.data)
        const articleRow = {
          ...orderRow,
          items: a.count,
          idArticle: a.idArticle,
          idProduct: a.idProduct,
          expansion: `"${escapeDoubleQuotes(a.product.expansion)}"`,
          name: `"${escapeDoubleQuotes(a.product.locName)}"`,
          idLanguage: a.language.idLanguage,
          language: a.language.languageName,
          condition: a.condition,
          price: a.price,
          comments: `"${escapeDoubleQuotes(a.comments)}"`,
          isFoil: a.isFoil ? a.isFoil : false,
          isReverseHolo: a.isReverseHolo ? a.isReverseHolo : false,
          isSigned: a.isSigned ? a.isSigned : false,
          isAltered: a.isAltered ? a.isAltered : false,
          isPlayset: a.isPlayset ? a.isPlayset : false,
          isFirstEd: a.isFirstEd ? a.isFirstEd : false,
        }
        output.push(articleRow)
      })
    })
    return output
  }

  const getOrdersLastFetchedAt = () => {
    let ordersLastFetchedAt = new Date()
    const cardmarketUsername = user.cardmarketUsername
    const cardmarketAccount = user.assignedCardmarketAccounts.find(
      (a) => a.username === cardmarketUsername
    )
    if (cardmarketAccount && cardmarketAccount.ordersLastFetchedAt) {
      ordersLastFetchedAt = new Date(cardmarketAccount.ordersLastFetchedAt)
    }
    return ordersLastFetchedAt
  }

  const handleOrdersDownload = async (exportWithArticles: boolean): Promise<void> => {
    const ordersLastFetchedAt = getOrdersLastFetchedAt()
    // compare selectedDate with ordersLastFetchedAt converting them to Berlin time zone
    // also, set selectedDate time to 23:59:59
    const momSelectedDate = moment(selectedDate)
      .utcOffset('de')
      .set({ hour: 23, minute: 59, second: 59 })
    const momOrdersLastFetchedAt = moment(ordersLastFetchedAt).utcOffset('de')

    if (momOrdersLastFetchedAt.isBefore(momSelectedDate)) {
      setLoading(true)
      await fetchOrdersFromCardmarket()
      await fetchUser()
      setLoading(false)
    }
    const ordersToExport: MKMOrder[] = await exportOrders(selectedDate)
    let csvData = []
    if (exportWithArticles) {
      csvData = getArticleCsvData(ordersToExport)
    } else {
      csvData = ordersToExport.map((o) => getOrderRowFromOrder(o.data))
    }
    downloadCSV(csvData, exportWithArticles)
  }

  const getPrintableSelectedDate = (): string => {
    return selectedDate.toISOString().split('T')[0]
  }

  const getFilename = (exportWithArticles: boolean): string => {
    return `export-orders${exportWithArticles ? '-articles' : ''}-${
      user.cardmarketUsername
    }-${getPrintableSelectedDate()}.csv`
  }

  const downloadCSV = (data: any[], exportWithArticles: boolean) => {
    // Create a dynamic CSV file based on the provided data
    const headerRow = exportWithArticles
      ? EXPORT_WITH_ARTICLES_HEADERS
      : EXPORT_WITHOUT_ARTICLES_HEADERS
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      encodeURIComponent(
        [headerRow.join(','), ...data.map((row) => Object.values(row).join(','))].join('\n')
      )

    // Create a virtual link element to trigger the download
    const link = document.createElement('a')
    link.href = csvContent
    link.download = getFilename(exportWithArticles)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleChangeDate = (date: Date) => {
    if (isValidDate(date)) {
      setSelectedDate(date)
    }
  }

  const oneYearAgo = () => {
    const today = new Date()
    const lastYear = new Date(today)
    lastYear.setFullYear(today.getFullYear() - 1)
    return lastYear
  }

  const getMinAllowedDate = () => {
    return Math.max(new Date(user.createdAt).getTime(), oneYearAgo().getTime())
  }

  return (
    <>
      {loading && (
        <Backdrop
          open={loading}
          style={{ color: theme.palette.lightGray, zIndex: theme.zIndex.modal }}
        >
          <CircularProgress />
        </Backdrop>
      )}

      <Grid container>
        <Grid item xs={12}>
          <Box padding={1}>
            <Typography variant="h4" color="primary">
              {t('orders.cardmarketSales.title')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box padding={1}>
            <Typography>{t('orders.cardmarketSales.selectDay')}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} lg={2}>
          <Box padding={1}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              minDate={getMinAllowedDate()}
              maxDate={getYesterday()}
              value={selectedDate}
              onChange={handleChangeDate}
              onFocus={undefined}
              onBlur={undefined}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Box padding={1}>
            <Button variant="outlined" onClick={() => handleOrdersDownload(true)}>
              {t('orders.cardmarketSales.exportOrdersWithArticles')}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Box padding={1}>
            <Button variant="outlined" onClick={() => handleOrdersDownload(false)}>
              {t('orders.cardmarketSales.exportOrdersWithoutArticles')}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box paddingTop={10} />
          <Box padding={1}>
            <Typography variant="body2">{t('orders.cardmarketSales.caution')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
