import { Box } from '@material-ui/core'
import { AddArticleDock } from 'components/domain/addArticles/AddArticleDock/AddArticleDock'
import { ImportMenuButton } from 'components/facets/ImportMenuButton'
import { useUser } from 'components/providers/UserProvider'
import { theme } from 'config'
import bulkIcon from 'img/bulk.svg'
import bulkDisabledIcon from 'img/bulk_disabled.svg'
import downloadIcon from 'img/download.svg'
import addExpansionIcon from 'img/expansion.svg'
import addExpansionDisabledIcon from 'img/expansion_disabled.svg'
import csvImport from 'img/importCsv.svg'
import singleCardIcon from 'img/singleCard.svg'
import singleCardDisabledIcon from 'img/singleCard_disabled.svg'
import { useStockStore } from 'lib/hooks/useStockStore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FeatureName, InventoryArticle } from 'shared'

export interface ImportArticlesProps {
  onAddArticles: (articles: InventoryArticle[]) => void
  openCsvImport: () => void
  openCsvExport: () => void
  tabSelected: string
}

export const ImportArticles = ({
  onAddArticles,
  openCsvImport,
  openCsvExport,
}: ImportArticlesProps): JSX.Element => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(true)
  const [discardArticleChanges, setDiscardArticleChanges] = useState(false)
  const [tabName, setTabName] = useState<'addByCard' | 'addByExp' | 'addBulk'>('addByCard')

  const { activeGame, user } = useUser()
  const activeStockType = useStockStore((state) => state.activeStockType)

  function handleClick(tab: 'addByCard' | 'addByExp' | 'addBulk'): void {
    setTabName(tab)
    setShowMenu(!showMenu)
  }

  function handleBackToMenu(): void {
    setShowMenu(!showMenu)
    setDiscardArticleChanges(!discardArticleChanges)
  }

  useEffect(() => {
    setShowMenu(true)
    setDiscardArticleChanges(true)
  }, [activeStockType])

  const isDisabled = (addArticlesMode: FeatureName): boolean => {
    return user!.subscription.featuresDisabled.includes(addArticlesMode)
  }

  return (
    <Box>
      {showMenu && (
        <Box>
          <Box marginTop={2}>
            <ImportMenuButton
              color={theme.palette.strongViolet}
              title={
                activeStockType === 'singles'
                  ? t('addArticles.sigleCardTitleImportButton')
                  : t('addArticles.sigleItemTitleImportButton')
              }
              content={
                activeStockType === 'singles'
                  ? t('addArticles.sigleCardContentImportButton')
                  : t('addArticles.sigleItemContentImportButton')
              }
              img={singleCardIcon}
              onClick={() => handleClick('addByCard')}
              dataTestid="go-to-add-by-card-btn"
              disabled={isDisabled('addArticles.addByCard')}
              imgDisabled={singleCardDisabledIcon}
            />
          </Box>

          {activeStockType === 'singles' && (
            <>
              <Box marginTop={2}>
                <ImportMenuButton
                  color={theme.palette.strongViolet}
                  title={t('addArticles.byExpansionTitleImportButton')}
                  content={t('addArticles.byExpansionContentImportButton')}
                  img={addExpansionIcon}
                  onClick={() => handleClick('addByExp')}
                  disabled={isDisabled('addArticles.addByExp')}
                  imgDisabled={addExpansionDisabledIcon}
                  dataTestid="go-to-add-by-exp-btn"
                />
              </Box>

              <Box marginTop={2}>
                <ImportMenuButton
                  color={theme.palette.strongViolet}
                  title={t('addArticles.bulkTitleImportButton')}
                  content={t('addArticles.bulkContentImportButton')}
                  img={bulkIcon}
                  onClick={() => handleClick('addBulk')}
                  disabled={isDisabled('addArticles.addBulk')}
                  imgDisabled={bulkDisabledIcon}
                  dataTestid="go-to-add-by-bulk-btn"
                />
              </Box>
            </>
          )}

          <Box marginTop={2}>
            <ImportMenuButton
              color={theme.palette.strongViolet}
              title={t('addArticles.csvImportTitleImportButton')}
              content={
                activeStockType === 'singles'
                  ? t('addArticles.csvImportContentImportButton')
                  : t('addArticles.csvImportContentSealedButton')
              }
              img={csvImport}
              onClick={() => openCsvImport()}
              dataTestid="go-to-add-by-csv-btn"
            />
          </Box>

          <Box marginTop={2}>
            <ImportMenuButton
              color={theme.palette.strongViolet}
              title={t('addArticles.csvExportTitleImportButton')}
              content={t('addArticles.csvExportContentImportButton', { gameName: activeGame.name })}
              img={downloadIcon}
              onClick={() => openCsvExport()}
              dataTestid="go-to-export-csv-products-btn"
            />
          </Box>
        </Box>
      )}
      {!showMenu && (
        <Box>
          <AddArticleDock
            disableHotkeys={false}
            onAddArticles={onAddArticles}
            showThisTab={tabName}
            discardChange={discardArticleChanges}
            handleBackToMenu={handleBackToMenu}
          />
        </Box>
      )}
    </Box>
  )
}
