import {
  Box,
  DialogTitle,
  Drawer,
  IconButton,
  Snackbar,
  SvgIconTypeMap,
  Typography,
} from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { Alert } from '@material-ui/lab'
import { ClosableDialog } from 'components/controls/dialogs'
import { ExportCsv } from 'components/domain/csv'
import { CloseIcon, HelpButton } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useDialog } from 'lib/hooks'
import { useStockStore } from 'lib/hooks/useStockStore'
import { exposeToCypress } from 'lib/utils'
import qs from 'query-string'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AnyYesNo, Filters, InventoryArticle, PlainRichArticle, roundTo2Decimals } from 'shared'
import { newCancellationToken } from 'utils/cancellationToken'
import { v4 as uuid } from 'uuid'
import { theme } from '../../../config'
import { ExportAllProductsCsv } from '../csv/ExportAllProductsCsv'
import { ImportFileCsv } from '../csv/ImportFileCsv'
import { ActionsPresenter } from './ActionsPresenter'
import { applyFilters } from './applyFilters'
import { AutopricePresenter } from './AutopricePresenter'
import { FilterDock } from './FilterDock'
import { QUANTITY_FILTER_MAX } from './FilterDock/FilterPresenter'
import { PriceSuggestPresenter } from './PriceSuggestPresenter/PriceSuggestPresenter'
import { PricingSettings } from './PricingSettings'
import { StockDisplay } from './StockDisplay'
import { TabBox } from './TabBox'

export interface StockState {
  fetchedAt: string
  stock: InventoryArticle[]
  sealedStock: InventoryArticle[]
  entityId?: string
}
export type DockAction = {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  actionLabel: string
  shouldConfirm?: boolean
  fn: () => void
  tooltip?: (fetchedAt: string) => JSX.Element
  key: string
}
export interface InventoryPresenterProps<Tab extends string> {
  refreshStockAction?: DockAction
  extraDockActions?: DockAction[]
  allowAddingArticles?: boolean
  tabs: readonly Tab[]
  determineTab: (article: InventoryArticle) => Tab
  splitStockByTab: (stock: InventoryArticle[]) => Record<Tab, InventoryArticle[]>
  emptyStockText: string
  viewOnly?: boolean
  hideSettingsButton?: boolean
  hideExportButton?: boolean
  setStockState: (newValue: StockState | ((arg: StockState) => StockState)) => void
  stockState: StockState
  openPublishDialog?: () => void
  parentPage?: string
  disabledActions?: string[]
}

const mapThroughActiveTab = (
  fn: (article: InventoryArticle, index: number) => InventoryArticle,
  stock: InventoryArticle[],
  activeStock: InventoryArticle[]
): InventoryArticle[] => {
  let cnt = 0
  const newStock = stock.map((article, index) => {
    if (article.id !== activeStock[cnt]?.id) {
      return article
    } else {
      cnt++
    }
    return fn(article, index)
  })
  return newStock
}

const filterThroughActiveTab = (
  fn: (article: InventoryArticle) => boolean,
  stock: InventoryArticle[],
  isArticleInActiveTab: (article: InventoryArticle) => boolean
): InventoryArticle[] => {
  const newStock = stock.filter((article) => {
    if (!isArticleInActiveTab(article)) {
      return true
    }
    return fn(article)
  })
  return newStock
}

export const InventoryPresenter = <Tab extends string>({
  determineTab,
  splitStockByTab,
  tabs,
  emptyStockText,
  allowAddingArticles = false,
  refreshStockAction,
  viewOnly = false,
  setStockState,
  stockState,
  hideExportButton,
  extraDockActions,
  openPublishDialog,
  parentPage,
  disabledActions,
}: InventoryPresenterProps<Tab>): JSX.Element => {
  const { activeGame } = useUser()
  const activeStockType = useStockStore((state) => state.activeStockType)

  const setStock = (
    newStock:
      | ((oldStock: InventoryArticle[], stockType: string) => InventoryArticle[])
      | InventoryArticle[]
  ): void => {
    if (typeof newStock === 'function') {
      setStockState((oldStockState) => ({
        ...oldStockState,
        fetchedAt: oldStockState.fetchedAt,
        stock: newStock(oldStockState.stock, 'singles'),
        sealedStock: newStock(oldStockState.sealedStock, 'sealed'),
      }))
    } else {
      if (activeStockType === 'singles') {
        setStockState({ ...stockState, stock: newStock })
      } else if (activeStockType === 'sealed') {
        setStockState({ ...stockState, sealedStock: newStock })
      }
      setCurrentStock(newStock)
    }
  }

  exposeToCypress({
    setArticles: (articles: PlainRichArticle[]) =>
      setStock(articles.map((article) => new InventoryArticle(article))),
    clearArticles: () => setStock([]),
  })

  const { stock, sealedStock, fetchedAt } = stockState

  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])
  const [filtersKey, setFiltersKey] = useState('this is used to force refresh')
  const [sortRefresh, setSortRefresh] = useState('arbitraryString')
  const [importFileDialogOpen, setImportFileDialogOpen] = useState(false)
  const [currentStock, setCurrentStock] = useState(
    activeStockType === 'singles' ? stock : sealedStock
  )

  useEffect(() => {
    // update current stock
    if (activeStockType === 'singles') {
      setCurrentStock(stockState.stock)
    } else if (activeStockType === 'sealed') {
      setCurrentStock(stockState.sealedStock)
    }
  }, [setStockState, stockState, activeStockType])

  const isFirstRender = useRef(true)

  const location = useLocation()

  useEffect(() => {
    const { importCsv, settingsTab } = qs.parse(location.search)
    if (isFirstRender.current) {
      if (importCsv) setImportFileDialogOpen(true)
      else if (settingsTab) setPricingSettingsDialogOpen(true)
      isFirstRender.current = false
    }
  }, [])

  const reapplySort = (): void => setSortRefresh(uuid())
  const cancellationToken = newCancellationToken()

  const [priceSuggestDrawer, setPriceSuggestDrawer] = useState<{
    open: boolean
    article: InventoryArticle | null
  }>({
    open: false,
    article: null,
  })
  const [autopriceDialogOpen, setAutopriceDialogOpen] = useState(false)

  const [pricingSettingsDialogOpen, setPricingSettingsDialogOpen] = useState(false)

  const [setExportCsvDialogOpen, ExportCsvDialog] = useDialog()

  const [setExportAllProductsCsvDialogOpen, ExportAllProductsCsvDialog] = useDialog()

  const { t } = useTranslation()
  const defaultFilters: Filters = useMemo(
    () => ({
      name: '',
      comment: '',
      edited: AnyYesNo.Any,
      condition: [0, 6],
      rarity: [1, Object.keys(activeGame.rarities).length],
      quantity: [1, QUANTITY_FILTER_MAX],
      minPrice: null,
      maxPrice: null,
      foil: AnyYesNo.Any,
      playset: AnyYesNo.Any,
      failedAutopricing: AnyYesNo.Any,
      language: AnyYesNo.Any,
      selected: AnyYesNo.Any,
      firstEd: AnyYesNo.Any,
      signed: AnyYesNo.Any,
      reverseHolo: AnyYesNo.Any,
      bulk: AnyYesNo.Any,
      exactPrice: null,
      expansion: null,
    }),
    [activeGame]
  )

  const [filters, setFilters] = useState(defaultFilters)
  const [selectedStockValue, setSelectedStockValue] = useState(0)

  const areFiltersTouched = !(JSON.stringify(filters) === JSON.stringify(defaultFilters))

  const openPriceSuggestDrawer = useCallback(
    (article: InventoryArticle): void => {
      setPriceSuggestDrawer({ open: true, article })
    },
    [setPriceSuggestDrawer]
  )

  const priceArticle = (price: number): void => {
    if (price > 0) {
      const newStock = currentStock.map((article) => {
        if (article.id === priceSuggestDrawer.article?.id) {
          return new InventoryArticle({
            ...article,
            failedAutopricing: false,
            newPrice: price,
          })
        } else return article
      })
      setStock(newStock)
    }
    setPriceSuggestDrawer({ open: false, article: null })
  }

  const handleDeleteArticle = useCallback(
    (article: InventoryArticle | 'selected' | 'all'): void => {
      setStock((oldStock) => {
        const shouldBeDeleted = (item: InventoryArticle): boolean => {
          if (article === 'selected') return !!item.selected
          if (article === 'all') return true
          return item.id === article.id
        }

        const newStock = filterThroughActiveTab(
          (item) => !shouldBeDeleted(item),
          oldStock,
          (article) => determineTab(article) === activeTab
        )

        return newStock
      })
    },
    [determineTab, filterThroughActiveTab, activeTab]
  )

  const stockByTab = useMemo(() => splitStockByTab(currentStock), [splitStockByTab, currentStock])

  const clearFailedAutoprice = useCallback(
    (target: 'selected' | 'all'): void => {
      const newStock = mapThroughActiveTab(
        (item) => {
          if (target === 'all' || (target === 'selected' && item.selected)) {
            const { failedAutopricing, ...newArticle } = item
            return new InventoryArticle({ ...newArticle })
          } else return item
        },
        currentStock,
        stockByTab[activeTab]
      )

      setStock(newStock)
    },
    [setStock, mapThroughActiveTab, stockByTab, activeTab]
  )
  const handleBulkEdit = useCallback(
    (
      name: 'newPrice' | 'newComment' | 'newQuantity' | 'newCondition' | 'readyToPublish',
      value: string | number | boolean | ((article: InventoryArticle) => string | number | boolean),
      all = false
    ): void => {
      const newStock = mapThroughActiveTab(
        (article) => {
          if (article.selected || all) {
            if (name === 'newPrice') article.failedAutopricing = false

            const newArticle = article.copy()
            //@ts-ignore
            newArticle[name] = typeof value === 'function' ? value(article) : value

            if (
              newArticle[name] ===
              newArticle[
                name.slice(3).toLowerCase() as 'price' | 'quantity' | 'comment' | 'condition'
              ]
            ) {
              newArticle[name] = undefined
            }
            return newArticle
          } else return article
        },
        currentStock,
        stockByTab[activeTab]
      )

      setStock(newStock)
    },
    [setStock, mapThroughActiveTab, stockByTab, currentStock]
  )

  const selectedStock = useMemo(
    () => stockByTab[activeTab].filter((stockArticle) => stockArticle.selected),
    [activeTab, stockByTab]
  )

  useEffect(() => {
    let sum = 0
    selectedStock.forEach((article) => {
      if (article.newPrice) {
        sum += article.newPrice
      } else {
        sum += article.price
      }
    })
    setSelectedStockValue(roundTo2Decimals(sum))
  }, [selectedStock])

  useEffect(() => {
    if (!stockByTab[activeTab].length) {
      const nonEmptyTab = tabs.find((tab) => stockByTab[tab].length)
      if (nonEmptyTab) setActiveTab(nonEmptyTab)
      else setActiveTab(tabs[0])
    }
  }, [stockByTab[activeTab]])

  const revertArticleEdit = useCallback(
    (article: InventoryArticle | 'selected' | 'all'): void => {
      const shouldBeReverted = (item: InventoryArticle): boolean => {
        if (article === 'selected') return !!item.selected
        if (article === 'all') return true
        return item.id === article.id
      }

      setStock((oldStock) => {
        const newStock = mapThroughActiveTab(
          (item) => {
            if (shouldBeReverted(item)) {
              const newArt = item.copy()
              newArt.revertChanges()
              return newArt
            } else return item
          },
          oldStock,
          stockByTab[activeTab]
        )

        return newStock
      })
    },
    [setStock, stockByTab, activeTab, mapThroughActiveTab]
  )

  useEffect(() => {
    reapplySort()
  }, [activeTab, stockState.fetchedAt])

  const [filteredStock, selectedArticlesHiddenByFilters] = useMemo(() => {
    const filteredStock = applyFilters(filters, stockByTab[activeTab], activeGame)
    const selectedFilteredStockLength = filteredStock.reduce(
      (acc, val) => acc + (val.selected ? 1 : 0),
      0
    )
    const selectedArticlesHiddenByFilters = selectedStock.length - selectedFilteredStockLength
    return [filteredStock, selectedArticlesHiddenByFilters]
  }, [applyFilters, filters, stockByTab, activeTab, activeGame])

  const selectArticle = useCallback(
    (article: InventoryArticle, shiftSelect = false): void => {
      // eslint-disable-next-line prettier/prettier
      // const Article = article as unknown as InventoryArticle
      setStock((oldStock) => {
        let lastPreviousSelectedIndex: null | number = null
        let selectedIndex: null | number = null
        let newStock = oldStock.map((stockArticle, index) => {
          if (stockArticle.selected && selectedIndex === null) lastPreviousSelectedIndex = index
          if (stockArticle.id === article.id) {
            selectedIndex = index
            const newArticle = article.copy()
            newArticle.toggleSelected()
            return newArticle
          } else return stockArticle
        })

        if (shiftSelect && lastPreviousSelectedIndex !== null && selectedIndex !== null) {
          newStock = mapThroughActiveTab(
            (stockArticle, index) => {
              if (index > lastPreviousSelectedIndex! && index < selectedIndex!) {
                const newArticle = stockArticle.copy()
                newArticle.toggleSelected()
                return newArticle
              } else return stockArticle
            },
            newStock,
            filteredStock
          )
        }
        return newStock
      })
    },
    [setStock, filteredStock]
  )

  const selectAllResults = useCallback((): void => {
    setStock((oldStock) => {
      let cnt = 0
      const newStock = oldStock.map((stockArticle) => {
        if (cnt < filteredStock.length && stockArticle.id === filteredStock[cnt].id) {
          cnt++
          const newArt = stockArticle.copy()
          newArt.selected = true
          return newArt
        } else {
          return stockArticle
        }
      })
      return newStock
    })
  }, [setStock, filteredStock])

  const unselectAll = useCallback((): void => {
    setStock((oldStock) =>
      oldStock.map((stockArticle) => {
        const newArt = stockArticle.copy()
        newArt.selected = false
        return newArt
      })
    )
  }, [setStock])

  const openImportFileDialog = useCallback(() => setImportFileDialogOpen(true), [])
  const openExportCsvDialog = useCallback(() => setExportCsvDialogOpen(true), [])
  const openExportAllProductsCsvDialog = useCallback(
    () => setExportAllProductsCsvDialogOpen(true),
    []
  )

  const handleAddArticles = (articles: InventoryArticle[]) => {
    setStock(currentStock.concat(articles))
  }

  // const handleAddArticles = useCallback(
  //   (articles) => {
  //     setStock((stock, stockType) => {
  //       if (stockType === activeStockType) {
  //         console.log(stockType, activeStockType)
  //         return stock.concat(articles)
  //       }
  //       return stock
  //     })
  //   },
  //   [setStock]
  // )

  const openAutopriceDialog = useCallback(() => setAutopriceDialogOpen(true), [])

  const totalFilteredStockValues = useMemo(() => {
    return filteredStock.reduce(
      (a, b) => {
        const currentPrice = b.currentPrice ? b.currentPrice : 0
        const currentQuantity = b.currentQuantity ? b.currentQuantity : 0
        return {
          price: roundTo2Decimals(a.price + b.price * b.quantity),
          quantity: Number(a.quantity) + Number(b.quantity),
          newPrice: roundTo2Decimals(a.newPrice + currentPrice * currentQuantity),
          newQuantity: Number(a.newQuantity) + Number(currentQuantity),
        }
      },
      { price: 0, quantity: 0, newPrice: 0, newQuantity: 0 }
    )
  }, [filteredStock])

  const boxStyle = useMemo(
    () => ({ backgroundColor: theme.palette.lightBlue, minHeight: '100vh' }),
    []
  )

  type Command = 'selectAllResults' | 'revertArticleEdit' | 'handleBulkEdit'

  const commandMap: Record<Command, (...args: any[]) => void> = {
    selectAllResults,
    revertArticleEdit,
    handleBulkEdit,
  }

  const [command, setCommand] = useState<{ name: Command; args: any[] } | null>(null)

  useEffect(() => {
    if (command && commandMap[command.name]) {
      //@ts-ignore
      commandMap[command.name](...command.args)
      setCommand(null)
    }
  }, [command])

  const selectAllResultsCommand = useCallback(
    () => setCommand({ name: 'selectAllResults', args: [] }),
    [setCommand]
  )
  const revertArticleEditCommand = useCallback(
    (...args: Parameters<typeof revertArticleEdit>) =>
      setCommand({ name: 'revertArticleEdit', args: args }),
    [setCommand]
  )

  const handleBulkEditCommand = useCallback(
    (...args: Parameters<typeof handleBulkEdit>) =>
      setCommand({ name: 'handleBulkEdit', args: args }),
    [setCommand]
  )

  return (
    <>
      <Box style={boxStyle} display="flex">
        <Box marginRight={1}>
          <FilterDock
            openExportCsvDialog={openExportCsvDialog}
            fetchedAt={fetchedAt}
            openImportFileDialog={openImportFileDialog}
            openExportAllProductsCsvDialog={openExportAllProductsCsvDialog}
            areFiltersTouched={areFiltersTouched}
            sealedActive={false}
            filters={filters}
            loading={false} // $% todo see if this matters
            filtersKey={filtersKey}
            setFiltersKey={setFiltersKey}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            onAddArticles={allowAddingArticles ? handleAddArticles : undefined}
            refreshStockAction={refreshStockAction}
            extraActions={extraDockActions}
            hideExportButton={hideExportButton}
            showAddCardsButton={parentPage === 'ListingAndAppraisal'}
            showFiltersButton={parentPage === 'ListingAndAppraisal'}
            isEmptyInventory={currentStock.length === 0}
          />
        </Box>
        <Box style={{ width: 'auto', position: 'relative' }}>
          {currentStock.length > 0 && (
            <Box marginTop={1} marginBottom="-6px" display="flex" justifyContent="space-between">
              <Box display="flex">
                {tabs.map(
                  (tab, index) =>
                    !!(index === 0 || stockByTab[tab].length) && (
                      <TabBox
                        key={tab}
                        active={activeTab === tab}
                        text={tab}
                        data-testid={`stockTab-${tab}`}
                        count={stockByTab[tab].length}
                        onClick={() => setActiveTab(tab)}
                        selectedCount={selectedStock.length}
                      >
                        {!viewOnly && (
                          <ActionsPresenter
                            selectAllResults={selectAllResultsCommand}
                            unselectAll={unselectAll}
                            handleDeleteArticle={handleDeleteArticle}
                            clearFailedAutoprice={clearFailedAutoprice}
                            revertArticleEdit={revertArticleEditCommand}
                            handleBulkEdit={handleBulkEditCommand}
                            openAutopriceDialog={openAutopriceDialog}
                            openPublishDialog={openPublishDialog}
                            openExportCsvDialog={openExportCsvDialog}
                            selectedStockExists={!!selectedStock.length}
                            selectedStockLength={selectedStock.length}
                            disabledActions={disabledActions}
                            //@ts-ignore
                            activeTab={activeTab}
                          />
                        )}
                      </TabBox>
                    )
                )}
              </Box>
            </Box>
          )}

          <StockDisplay
            filteredStock={filteredStock}
            viewOnly={viewOnly}
            sortRefresh={sortRefresh}
            emptyStockText={emptyStockText}
            openPriceSuggestDrawer={openPriceSuggestDrawer}
            selectArticle={selectArticle}
            filteredStockLength={filteredStock.length}
            stockLength={currentStock.length}
            totalFilteredStockValues={totalFilteredStockValues}
            setStock={setStock}
            revertArticleEdit={revertArticleEdit}
            articleWithChangedPriceExists={filteredStock.some((article) => article.newPrice)}
            areFiltersTouched={areFiltersTouched}
            onDeleteArticle={['articles'].includes(activeTab) ? handleDeleteArticle : undefined}
            parentPage={parentPage}
            selectedCount={selectedStock.length}
            selectedStockValue={selectedStockValue}
          />
        </Box>
        <Drawer
          //TransitionComponent={Transition}
          disableEnforceFocus
          anchor="right"
          open={priceSuggestDrawer.open}
          onClose={() => setPriceSuggestDrawer({ ...priceSuggestDrawer, open: false })}
        >
          {priceSuggestDrawer.article && (
            <PriceSuggestPresenter
              article={priceSuggestDrawer.article!}
              onClose={() => setPriceSuggestDrawer({ open: false, article: null })}
              priceArticle={priceArticle}
            />
          )}
        </Drawer>
        <ClosableDialog
          maxWidth={'lg'}
          open={autopriceDialogOpen}
          disableBackdropClick
          onClose={() => {
            cancellationToken.cancel()
            setAutopriceDialogOpen(false)
          }}
        >
          <DialogTitle id="form-dialog-title">
            {t('autopriceArticles.title')}
            <HelpButton
              small
              dataTestid={'info-autoprice-articles-dialog'}
              tooltip={
                <Box>
                  <Typography gutterBottom variant="body2" data-testid="autoprice-help-tooltip">
                    {t('autopriceArticles.help1')}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {t('autopriceArticles.help2')}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {t('autopriceArticles.help3')}
                  </Typography>
                  <Typography variant="body2">
                    For more information, check out{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.tcgpowertools.com/support/kb/categories"
                      data-testid={'pricing-settings-our-doc'}
                    >
                      our Knowledge Base
                    </a>
                  </Typography>
                </Box>
              }
            />
          </DialogTitle>
          <AutopricePresenter
            reapplySort={reapplySort}
            articles={currentStock}
            articlesToPrice={selectedStock}
            setStock={setStock}
            showFailedAutopricingCards
            closeDialog={() => setAutopriceDialogOpen(false)}
            cancellationToken={cancellationToken}
          />
        </ClosableDialog>
        <Drawer
          disableEnforceFocus
          anchor="right"
          open={pricingSettingsDialogOpen}
          onClose={() => setPricingSettingsDialogOpen(false)}
        >
          <Box padding={3} paddingBottom={0} position="relative">
            <DialogTitle disableTypography id="form-dialog-title">
              <Typography variant="h4">
                {t('pricingSettings.title')}
                <HelpButton
                  tooltip={
                    <Typography variant="body2">
                      For more information, check out{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://support.tcgpowertools.com/support/kb/categories"
                      >
                        our Knowledge Base
                      </a>
                    </Typography>
                  }
                />
              </Typography>
            </DialogTitle>
            <Box position="absolute" top="5px" right="5px">
              <IconButton
                data-testid="close-drawer-btn"
                onClick={() => setPricingSettingsDialogOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box width="1100px" maxWidth="90vw" margin="0 auto">
              <PricingSettings />
            </Box>
          </Box>
        </Drawer>
        <ExportCsvDialog
          title={t('csv.export')}
          content={
            <ExportCsv
              view="stockPricing"
              stock={stock}
              sealedStock={sealedStock}
              selectedStock={selectedStock}
            />
          }
        />
        <ExportAllProductsCsvDialog
          title={t('csv.exportAllProducts', { gameName: activeGame.name })}
          content={<ExportAllProductsCsv />}
        />
        <ClosableDialog
          maxWidth="xl"
          fullWidth={true}
          onClose={() => {
            setImportFileDialogOpen(false)
          }}
          open={importFileDialogOpen}
        >
          <Box minWidth={'780px'} margin={3}>
            <ImportFileCsv
              stockType={activeStockType}
              handleResult={(inventory) => {
                setStock([...inventory, ...currentStock])
              }}
              closeExternalDialog={setImportFileDialogOpen}
            />
          </Box>
        </ClosableDialog>
      </Box>
      <Snackbar
        open={!!selectedArticlesHiddenByFilters}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="warning">
          {t('selectedArticlesHiddenByFilters', { count: selectedArticlesHiddenByFilters })}
        </Alert>
      </Snackbar>
    </>
  )
}
