import { Box, Container, Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { verifyCorrectVersion } from 'components/App'
import { Button, Sidebar } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import Cookie from 'js-cookie'
import { useAppContext, useLocalStorageState } from 'lib/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'
import { ElementWithChildren } from 'types'

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '80px',
    padding: '40px 50px 0px 50px',
    maxWidth: '80vw',
    width: 'auto',
    minWidth: '800px',
  },
}))

export const AppLayout = ({
  children,
  noDOMLayout = false,
}: ElementWithChildren & { noDOMLayout?: boolean }): JSX.Element => {
  const { user } = useUser()
  const { isAuthenticated } = useAppContext()
  const { t } = useTranslation()
  const [bannerMessage, setBannerMessage] = useState<{ _id: string; message: string } | undefined>(
    undefined
  )

  const [ignoredBannerMessages, setIgnoredBannerMessages] = useLocalStorageState<string[]>(
    [],
    'ignoredBannerMessages'
  )

  const bannerMessageToShow = ignoredBannerMessages.includes(bannerMessage?._id ?? '')
    ? ''
    : bannerMessage?.message

  const location = useLocation()
  const [updateAvailableDialogOpen, setUpdateAvailableDialogOpen] = useState(false)
  useEffect(() => {
    const onFocus = () => {
      verifyCorrectVersion(() => setUpdateAvailableDialogOpen(true))
    }
    window.addEventListener('focus', onFocus)
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  useEffect(() => {
    const isInventoryTester = user?.isTester?.ptInventory
    const isProd = process.env.NODE_ENV === 'production'
    if (
      isAuthenticated &&
      isInventoryTester &&
      isProd &&
      !window.location.href.includes('new.tcgpowertools.com')
    ) {
      console.log('redirecting')
      window.location.href = `https://new.tcgpowertools.com/login-redirect?j=${Cookie.get('jwt')}`
    }
  }, [user])

  const classes = useStyles()
  if (user?.hasNotLoggedIn && location.pathname !== '/finish-signup')
    return <Redirect to="/finish-signup" />
  else if (location.pathname === '/finish-signup') return <Box>{children}</Box>
  else if (!isAuthenticated) return <Redirect to="/signin" />
  else
    return (
      <>
        {bannerMessageToShow && (
          <Box
            padding={0.5}
            width="100vw"
            height="40px"
            style={{ backgroundColor: 'orange', textAlign: 'center' }}
          >
            <Box paddingLeft={12} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body1">
                {/* eslint-disable-next-line */}
                <span dangerouslySetInnerHTML={{ __html: bannerMessageToShow }} />
              </Typography>
              <Button
                onClick={() =>
                  setIgnoredBannerMessages([...ignoredBannerMessages, bannerMessage!._id])
                }
                color="primary"
              >
                {t('common.ok')}
              </Button>
            </Box>
          </Box>
        )}
        <>
          <Sidebar setBannerMessage={setBannerMessage} />
          {noDOMLayout ? (
            <Box marginLeft={'80px'}>{children}</Box>
          ) : (
            <Container className={classes.root}>{children}</Container>
          )}
          {updateAvailableDialogOpen && (
            <Dialog fullWidth maxWidth="sm" open={updateAvailableDialogOpen}>
              <Box textAlign="center" padding={4}>
                <Typography variant="h4" color="primary">
                  {t('misc.weHaveUpdates')}
                </Typography>
                <Box marginTop={3} marginBottom={3}>
                  <Typography variant="h6">{t('misc.weHaveUpdatesExplainer')}</Typography>
                </Box>
                <Box width="20rem" margin="0 auto">
                  <Button
                    style={{ fontSize: '1.3rem' }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    //@ts-ignore
                    onClick={() => window.location.reload(true)}
                  >
                    {t('misc.refreshToUpdate')}
                  </Button>
                  <Button onClick={() => setUpdateAvailableDialogOpen(false)} fullWidth>
                    {t('misc.willDoLater')}
                  </Button>
                </Box>
              </Box>
            </Dialog>
          )}
        </>
      </>
    )
}
