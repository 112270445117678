import { Box, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DialogBox } from 'components/facets'
import { theme } from 'config'
import powertoolsLogo from 'img/purpleTcgPowertools.png'
import { loginUser } from 'lib/auth'
import { useAppContext } from 'lib/hooks'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    width: '700px',
    minHeight: '370px',
    '& .o--payment-term': {
      display: 'none',
    },
    '& .o--plan-info': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  form: {
    width: '100%',
    '& *': {
      margin: '3px auto',
    },
  },
  smallSizeDevice: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  smallSizeDeviceContainerBox: {
    [theme.breakpoints.down('sm')]: {
      '& .o--Widget--widgetBody': {
        padding: '0 !important',
      },
    },
  },
}))

export const SignUpPresenter = (): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const { setAuthenticated, ReactPixel } = useAppContext()

  useEffect(() => {
    //@ts-ignore
    window.o_options = {
      id: 'Outseta',
      domain: 'mtg-powertools.outseta.com',
      load: 'auth',
      auth: {
        widgetMode: 'register',
        planUid: 'RyW1VgmB',
        planPaymentTerm: 'month',
        skipPlanOptions: true,
        id: 'signup_embed',
        mode: 'embed',
        selector: '#signup-embed',
      },
    }
  }, [])

  useEffect(() => {
    const accessToken = new URLSearchParams(location.search).get('access_token')
    if (accessToken)
      loginUser({ accessToken })
        .then(() => {
          ReactPixel.track('CompleteRegistration')
          setAuthenticated(true)
        })
        .catch((err) => {
          enqueueSnackbar(err.response?.data?.message || 'Invalid username or password.', {
            variant: 'error',
          })
        })
  }, [enqueueSnackbar, location.search, setAuthenticated])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cdn.outseta.com/outseta.min.js'
    //@ts-ignore
    script['data-o-options'] = 'o_options'

    document.body.appendChild(script)

    return () => {
      //@ts-ignore
      if (window.Outseta) window.Outseta = null
      document.body.removeChild(script)
      return
    }
  }, [])

  return (
    <DialogBox>
      <Box className={classes.root}>
        <Box padding={1} width="fit-content" margin="0 auto">
          <img width="400px" src={powertoolsLogo} className={classes.smallSizeDevice} />
        </Box>

        <div className={classes.smallSizeDeviceContainerBox} id="signup-embed"></div>
      </Box>
      <a href="/signin" style={{ display: 'block', width: '100%', textAlign: 'center' }}>
        Already have an account? Log in!
      </a>
      <Box marginTop={3}>
        <FormHelperText style={{ textAlign: 'center' }}>
          TCG PowerTools is a 3rd party app for Cardmarket created by Trading Card Technologies LLC,
          an official Cardmarket API Partner. Cardmarket is a registered trademark of
          Sammelkartenmarkt GmbH & Co. KG.
        </FormHelperText>
      </Box>
    </DialogBox>
  )
}
