import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import React, { useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { map as mapThroughTree, toggleExpandedForAll } from 'react-sortable-tree'
import {
  AnyYesNo,
  ConditionStrictness,
  ConditionTypesEnum,
  PricingPlan,
  PricingPlanNode,
} from 'shared'
import { NodePresenter } from '../../PricingPlan/NodePresenter'

interface CreatePlanPresenterProps {
  onCreate: (plan: Partial<PricingPlan>) => void
}

type TemplatePlan = Pick<PricingPlan, 'defaultNode' | 'nodes' | 'disallowNesting'>

enum PlanTab {
  Standard = 0,
  Advanced,
}

const makePreviewStrategyId = (t: any, strategyName: string): string =>
  `preview_${t(`priceUpdates.exampleStrategies.${strategyName}`)}`

const getExampleStandardPlans = (t: any): { planKey: string; pricingPlan: TemplatePlan }[] => [
  {
    planKey: 'singleStrategy',
    pricingPlan: {
      defaultNode: {
        id: 'default',
        method: makePreviewStrategyId(t, 'yourStrategy'),
      },

      nodes: [],
    },
  },
  {
    planKey: 'specificPart',
    pricingPlan: {
      defaultNode: {
        id: 'default',
        method: null,
      },
      nodes: [
        {
          conditions: [
            {
              value: [2, 1],
              type: ConditionTypesEnum.Language,
              id: 'db86828e-d1c1-42dc-b038-a12d1be49d4a',
            },
          ],
          children: [],
          conditionStrictness: ConditionStrictness.atleastOne,
          id: 'd7b24ae8-58ac-414f-8e8b-39aca84121cc',
          method: makePreviewStrategyId(t, 'yourStrategy'),
        },
      ],
    },
  },

  {
    planKey: 'someExceptions',
    pricingPlan: {
      defaultNode: {
        id: 'default',
        method: makePreviewStrategyId(t, 'yourStrategy'),
      },
      nodes: [
        {
          conditions: [
            {
              value: {
                metric: 'AVG30',
                min: 500,
              },
              type: ConditionTypesEnum.PriceGuide,
              id: 'e73b2092-0c84-44ac-bee8-a0bb49aedde8',
            },
            {
              value: {
                isFoil: AnyYesNo.Any,
                isSigned: AnyYesNo.Yes,
                isPlayset: AnyYesNo.Any,
                isFirstEd: AnyYesNo.Any,
                isReverseHolo: AnyYesNo.Any,
              },
              type: ConditionTypesEnum.CardExtra,
              id: 'f05ee770-482b-47b3-8a1d-a02fe2ab2a1b',
            },
            {
              value: '###',
              type: ConditionTypesEnum.Comment,
              id: '0a0b1643-3af5-4ea4-aa2c-c0148f9379e0',
            },
          ],
          children: [],
          conditionStrictness: ConditionStrictness.atleastOne,
          id: 'd7b24ae8-58ac-414f-8e8b-39aca84121cc',
          method: null,
        },
      ],
    },
  },
  {
    planKey: 'multipleStrategies',
    pricingPlan: {
      defaultNode: {
        id: 'default',
        method: makePreviewStrategyId(t, 'baseStrategy'),
      },
      nodes: [
        {
          conditions: [
            {
              value: [7],
              type: ConditionTypesEnum.Language,
              id: '3b6a0646-c5f0-4cd5-ace0-311438205d88',
            },
          ],
          children: [],
          conditionStrictness: ConditionStrictness.atleastOne,
          id: '62071182-7e86-4c4a-8f88-87a4da24e082',
          method: makePreviewStrategyId(t, 'japaneseStrategy'),
        },
        {
          conditions: [
            {
              value: [1],
              type: ConditionTypesEnum.Language,
              id: '9c8455c2-224c-4d7d-9814-971849541289',
              negated: true,
            },
          ],
          children: [],
          conditionStrictness: ConditionStrictness.atleastOne,
          id: 'ebe7cd71-7250-43fd-8070-fc6eee69bbff',
          method: makePreviewStrategyId(t, 'notEnglishStrategy'),
        },
      ],
    },
  },
]

const exampleAdvancedPlan: {
  pricingPlan: TemplatePlan
  planKey: string
} = {
  planKey: 'nesting',
  pricingPlan: {
    disallowNesting: false,

    defaultNode: {
      id: 'default',
      method: null,
    },
    nodes: [
      {
        conditions: [
          {
            value: {
              metric: 'AVG1',
              max: 0.5,
            },
            type: ConditionTypesEnum.PriceGuide,
            id: 'asdqweqwew',
          },
        ],
        children: [
          {
            children: [
              {
                children: [
                  {
                    children: [],
                    conditionStrictness: ConditionStrictness.atleastOne,
                    conditions: [
                      {
                        value: {
                          min: 4,
                          max: 6,
                        },
                        type: ConditionTypesEnum.CardCondition,
                        id: '5eeadb4c-95fc-4350-b65b-587c9b3127bf',
                      },
                    ],
                    id: 'cab151ed-5b29-4fbd-84a7-c4be26b3c520',
                    method: 'e63b3404-5a46-46a6-abd3-205ad8573361',
                  },
                ],
                conditionStrictness: ConditionStrictness.atleastOne,
                conditions: [
                  {
                    value: [10, 7, 9],
                    type: ConditionTypesEnum.Language,
                    id: '8d035826-1302-4138-85da-9ad0e8a0c359',
                  },
                ],
                id: '68685c1d-0b08-4639-8d62-e04ae32727f8',
                method: 'e63b3404-5a46-46a6-abd3-205ad8573361',
              },
              {
                children: [
                  {
                    children: [],
                    conditionStrictness: ConditionStrictness.atleastOne,
                    conditions: [
                      {
                        value: {
                          min: 4,
                          max: 6,
                        },
                        type: ConditionTypesEnum.CardCondition,
                        id: 'dd867bd9-f364-4482-a52e-1e8c09a64cdb',
                      },
                    ],
                    id: '1f402faf-8f05-44f2-9c7c-18242dbfa7f2',
                    method: 'e63b3404-5a46-46a6-abd3-205ad8573361',
                  },
                ],
                conditionStrictness: ConditionStrictness.atleastOne,
                conditions: [
                  {
                    value: [1],
                    type: ConditionTypesEnum.Language,
                    negated: true,
                    id: '9afde99e-4fec-4494-8d76-90821c4219b0',
                  },
                ],
                id: '52b69a8c-6db6-4368-8af0-f120c769fb1c',
                method: 'e63b3404-5a46-46a6-abd3-205ad8573361',
              },
              {
                children: [],
                conditionStrictness: ConditionStrictness.atleastOne,
                conditions: [
                  {
                    value: {
                      min: 4,
                      max: 6,
                    },
                    type: ConditionTypesEnum.CardCondition,
                    id: '5f07ff96-ee1c-4bab-a4fa-bb8791ab1c2e',
                  },
                ],
                id: '03fe481b-5363-42f8-8446-896f88cdf37d',
                method: 'e63b3404-5a46-46a6-abd3-205ad8573361',
              },
            ],
            conditionStrictness: ConditionStrictness.atleastOne,
            conditions: [
              {
                value: {
                  isFoil: AnyYesNo.Yes,
                  isSigned: AnyYesNo.Any,
                  isPlayset: AnyYesNo.Any,
                  isFirstEd: AnyYesNo.Any,
                  isReverseHolo: AnyYesNo.Any,
                },
                type: ConditionTypesEnum.CardExtra,
                id: '99ad26df-66b8-4b65-9e28-446f130b6ae9',
              },
            ],
            id: '56503581-f9d8-4d84-ad1c-1abaa7596a49',
            method: 'e63b3404-5a46-46a6-abd3-205ad8573361',
          },
        ],
        conditionStrictness: ConditionStrictness.atleastOne,
        id: 'f2a596dc-bae0-43f2-920e-45667c5bfcdc',
        method: null,
      },
    ],
  },
}

const removePreviewMethods = (plan: TemplatePlan): TemplatePlan => {
  return {
    ...plan,
    defaultNode: {
      ...plan.defaultNode,
      method: plan.defaultNode.method === null ? null : undefined,
    },
    nodes: mapThroughTree({
      treeData: plan.nodes,
      getNodeKey: (node: any) => node.id,
      callback: (thing: any) => {
        return { ...thing.node, method: thing.node.method === null ? null : undefined }
      },
      ignoreCollapsed: false,
    }) as PricingPlanNode[],
  }
}

const PricingPlanPreview = React.memo(
  ({
    plan,
    onCreate,
    showStartBlankButton,
  }: {
    plan: { pricingPlan: TemplatePlan; planKey: string }
    showStartBlankButton?: boolean
    onCreate: (plan: Partial<PricingPlan>) => void
  }): JSX.Element => {
    const { t } = useTranslation()

    return (
      <React.Fragment key={plan.planKey}>
        <Grid item xs={5} key={`${plan.planKey}-body`}>
          <Box>
            <Typography variant="h6">
              <b>
                <i> {t(`priceUpdates.examplePlans.${plan.planKey}`)}</i>
              </b>
            </Typography>
          </Box>
          <Box padding={2}>
            <Typography variant="body1">
              {t(`priceUpdates.examplePlans.${plan.planKey}Explainer`)}
            </Typography>
            <Box padding={2} textAlign="center">
              <Button
                variant="outlined"
                onClick={() => onCreate(removePreviewMethods(plan.pricingPlan))}
                fullWidth
                color="primary"
              >
                {t('priceUpdates.startWithThis')}
              </Button>

              {showStartBlankButton && (
                <Box style={{ textTransform: 'uppercase' }}>
                  <Typography style={{ marginTop: '4px', marginBottom: '4px' }} variant="body2">
                    {t(`common.or`)}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => onCreate({ disallowNesting: false })}
                    fullWidth
                    color="primary"
                  >
                    {t('priceUpdates.startWithBlankAdvanced')}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={7}
          key={plan.planKey}
          style={{ marginLeft: '-45px', overflowX: 'hidden', cursor: 'not-allowed' }}
        >
          <Box
            padding={2}
            position="relative"
            style={{
              pointerEvents: 'none',
              transform: 'scale(0.8)',
              minWidth: '800px',
            }}
          >
            {plan.pricingPlan.disallowNesting !== false && (
              <DndProvider backend={HTML5Backend}>
                <NodePresenter
                  pricingPlan={plan.pricingPlan as unknown as PricingPlan}
                  tree={
                    toggleExpandedForAll({
                      treeData: plan.pricingPlan.nodes,
                    }) as unknown as PricingPlanNode[]
                  }
                  changeTree={() => undefined}
                  hiddenAddButton={true}
                />
              </DndProvider>
            )}
          </Box>
        </Grid>
      </React.Fragment>
    )
  }
)

const StandardPlansTab = React.memo(({ onCreate }: CreatePlanPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const exampleStandardPlans = useMemo(() => getExampleStandardPlans(t), [])
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography variant="body1">{t('priceUpdates.standardPlansIntro')}</Typography>
      </Box>

      <Grid container alignItems={'center'} style={{ justifyContent: 'center' }}>
        {exampleStandardPlans.map((plan) =>
          false ? (
            <>helo</>
          ) : (
            <PricingPlanPreview plan={plan} onCreate={onCreate} key={plan.planKey} />
          )
        )}
      </Grid>
    </Box>
  )
})

const AdvancedPlansTab = React.memo(({ onCreate }: CreatePlanPresenterProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <Typography variant="body1">{t('priceUpdates.advancedPlansIntro')}</Typography>
      </Box>
      <Grid
        container
        alignItems={'center'}
        style={{ justifyContent: 'center', paddingTop: '25px' }}
      >
        <PricingPlanPreview plan={exampleAdvancedPlan} showStartBlankButton onCreate={onCreate} />
      </Grid>
    </Box>
  )
})

export const CreatePlanPresenter = React.memo(function ({
  onCreate,
}: CreatePlanPresenterProps): JSX.Element {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<PlanTab>(PlanTab.Standard)

  return (
    <>
      <Typography variant="h5">{t('priceUpdates.chooseStartingPoint')}</Typography>

      <Tabs
        value={activeTab}
        onChange={(_e, newVal) => {
          setTimeout(() => setActiveTab(newVal), 300)
        }}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab data-testid="standard-plans-tab" label={`${t('common.standard')}`} />
        <Tab data-testid="advanced-plans-tab" label={`${t('common.advanced')}`} />
      </Tabs>
      <Box padding={2} minWidth="1220px" minHeight="85vh">
        {activeTab === PlanTab.Standard && <StandardPlansTab onCreate={onCreate} />}

        {activeTab === PlanTab.Advanced && <AdvancedPlansTab onCreate={onCreate} />}
      </Box>
    </>
  )
})
