import { Box, Typography } from '@material-ui/core'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Skeleton } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { theme } from 'config'
import { useScopelessHotkeys, useWindowDimensions } from 'lib/hooks'
import { useStockStore } from 'lib/hooks/useStockStore'
import {
  Children,
  cloneElement,
  createContext,
  createRef,
  forwardRef,
  isValidElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { VariableSizeList } from 'react-window'
import { CardDataItem, Game, isSingle, LocalizedCard } from 'shared'
import { filterOptions } from './filterOptions'

const LISTBOX_PADDING = 12 // px

function renderRow(props: any): JSX.Element {
  const { data, index, style } = props

  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  //@ts-ignore
  return <div ref={ref} {...props} {...outerProps} />
})

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData = Children.toArray(children)
  const classes = useStyles()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const itemCount = itemData.length
  const itemSize = 212

  const listRef = createRef()
  const scrollRef = useRef(0)
  useScopelessHotkeys(
    'down',
    () => {
      scrollRef.current = scrollRef.current + 1
      if (scrollRef.current >= itemCount) scrollRef.current = 0

      //@ts-ignore
      listRef.current?.scrollToItem(scrollRef.current)
    },
    [listRef, scrollRef, itemCount]
  )
  useScopelessHotkeys(
    'up',
    () => {
      scrollRef.current = scrollRef.current - 1
      if (scrollRef.current < 0) scrollRef.current = itemCount - 1
      //@ts-ignore
      listRef.current?.scrollToItem(scrollRef.current)
    },
    [listRef, scrollRef, itemCount]
  )

  const getChildSize = (child: typeof ListSubheader): number => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 96
    }

    return itemSize
  }

  const getHeight = (): number => {
    return Math.min(itemCount * itemSize, width - 100)
  }

  return (
    //@ts-ignore
    <div ref={ref} data-testid="articles-list">
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          //@ts-ignore
          ref={listRef}
          className={classes.listbox}
          itemData={itemData}
          style={{
            overflowY: 'hidden',
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            left: `-16px`,
            marginTop: '4px',
          }}
          height="350px"
          width={
            itemCount > 1
              ? getHeight() + 2 * LISTBOX_PADDING
              : getHeight() + 2 * LISTBOX_PADDING + 17
          }
          key={itemCount}
          layout="horizontal"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index] as typeof ListSubheader)}
          overscanCount={3}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
        <Box
          // style={{ backgroundColor: 'white' }}
          position="absolute"
          left="0"
          top="9px"
          width={`${itemCount > 1 ? '500px' : '250px'}`}
          height="16px"
        >
          <Typography
            variant="body2"
            style={{ fontSize: '12px', color: theme.palette.primary.main }}
          >
            {t('addArticles.helpToChoseCard')}
          </Typography>
        </Box>
      </OuterElementContext.Provider>
    </div>
  )
})

const useStyles = makeStyles({
  listbox: {
    maxHeight: '100vh',
    "& .MuiAutocomplete-option[data-focus='true']": {
      backgroundColor: '#4c00a32b',
    },
  },
  option: {
    width: '200px',
  },
})

const cardLabel = (card: CardDataItem, rarities: Partial<Game['rarities']> = {}): string => {
  const rarityLabel = rarities[card.rarity]?.filterLabel || ''
  if (card.name && card.set) {
    return `${card.name} ${card.set} ${rarityLabel}`
  } else if (card.name) {
    return `${card.name}`
  } else {
    return ''
  }
}

const normalizeSetCodeForKeyrune = (setCode: string): string => {
  const code = setCode.trim().toLowerCase()
  if ((code.length === 4 && code[0] === 'x') || code[0] === 'p') return code.slice(1)
  else return code
}

export interface AutocompleteInputProps {
  autocompleteRef?: any
  card?: CardDataItem | null
  expansionCode?: string
  handleCardChange: (newCard: CardDataItem) => void
  activeLanguage?: 'en' | 'fr' | 'es' | 'de' | 'it'
  rarities?: Game['rarities']
  useKeyruneExpansionIcons?: boolean
  cardData: LocalizedCard[]
}

const getDebounceSpeed = (pauses: number[]) => {
  if (!pauses.length) return 2000
  else return (pauses.reduce((acc, pause) => (acc += pause), 0) / pauses.length) * 2
}

export default function AutocompleteInput(props: AutocompleteInputProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()

  let options = props.cardData

  if (props.expansionCode) options = options.filter(({ set }) => set === props.expansionCode)

  const [inputValue, setInputValue] = useState('')
  const [debouncedInputValue, setDebouncedInputValue] = useState('')
  const [inputEnterPauses, setInputEnterPauses] = useState<number[]>([])
  const activeStockType = useStockStore((state) => state.activeStockType)

  const debounceSpeed = getDebounceSpeed(inputEnterPauses)

  useEffect(
    () => {
      // Update debounced value after delay
      let handler: any = null
      if (inputValue.length > 2) {
        handler = setTimeout(() => {
          setDebouncedInputValue(inputValue)
        }, 400)
      } else {
        setDebouncedInputValue(inputValue)
      }
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        if (handler) clearTimeout(handler)
      }
    },
    [inputValue, debounceSpeed] // Only re-call effect if value or delay changes
  )
  useEffect(
    () => {
      const start = Date.now()
      // Update debounced value after delay

      return () => {
        if (inputValue.length)
          setInputEnterPauses((inputEnterPauses) => [
            Math.min(Date.now() - start, 2000),
            ...inputEnterPauses.slice(0, 9),
          ])
      }
    },
    [inputValue] // Only re-call effect if value or delay changes
  )

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [debouncedInputValue])

  const getFilteredOptions = (options: LocalizedCard[]) => {
    if (activeStockType === 'singles') {
      return options.filter((card) => isSingle(card))
    } else if (activeStockType === 'sealed') {
      return options.filter((card) => !isSingle(card))
    }
    return options
  }

  const memoizedOptions = useMemo(() => {
    if (inputValue.length < 3) return []
    const filteredOptions = getFilteredOptions(options)
    return filterOptions(filteredOptions, debouncedInputValue, props.activeLanguage)
    // let output: CardDataItem[] = []
    // if (activeStockType === 'singles') {
    //   output = items.filter((item) => isSingle(item))
    // } else if (activeStockType === 'sealed') {
    //   output = items.filter((item) => !isSingle(item))
    // }
    // return output
  }, [debouncedInputValue])

  useEffect(() => {
    setInputValue('')
  }, [activeStockType])

  return (
    <>
      <Autocomplete
        autoHighlight={true}
        clearOnEscape={true}
        inputValue={inputValue}
        filterOptions={(x) => x}
        onInputChange={(e) => {
          //@ts-ignore
          const newValue = e?.target?.value
          if (typeof newValue === 'string') {
            setInputValue(newValue)
            newValue.length > 2 && !memoizedOptions.length && setLoading(true)
          }
        }}
        disableClearable={true}
        freeSolo={true}
        getOptionLabel={cardLabel}
        id="card-autocomplete"
        noOptionsText={t('addArticles.noCardsFound')}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string' && newValue._id) {
            props.handleCardChange(newValue)
            setInputValue('')
          }
        }}
        openOnFocus={false}
        classes={classes}
        ref={props.autocompleteRef}
        value={''}
        //@ts-ignore
        ListboxComponent={ListboxComponent}
        options={loading ? [] : memoizedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            variant="outlined"
            label={activeStockType === 'singles' ? t('card.cardName') : t('card.itemName')}
            placeholder={
              activeStockType === 'singles'
                ? t('addArticles.startTypingCard')
                : t('addArticles.startTypingItem')
            }
            fullWidth
          />
        )}
        renderOption={(option) => (
          <div className={classes.option}>
            <Typography style={{ textAlign: 'center' }} variant="body2">
              <b>
                {props.useKeyruneExpansionIcons && props.rarities ? (
                  <span>
                    {cardLabel(option)}{' '}
                    <i
                      className={`ss ss-2x ss-${normalizeSetCodeForKeyrune(
                        option.set
                      )} ss-${props.rarities[option.rarity].name.toLowerCase()}`}
                    ></i>
                  </span>
                ) : (
                  cardLabel(option, props.rarities)
                )}
              </b>
            </Typography>
            <img
              alt={option.name || ''}
              width="180px"
              src={`https://mtgpowertools.s3.eu-central-1.amazonaws.com/images/mtg/${option._id}.jpg`}
            ></img>
          </div>
        )}
      />

      {loading && (
        <Box position="relative" bottom="0">
          <Box
            padding={4}
            display="flex"
            style={{
              overflowY: 'hidden',
              position: 'absolute',
              backgroundColor: 'white',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              left: `-16px`,
              marginTop: '4px',
              zIndex: 1500,
            }}
            height="350px"
            maxWidth="850px"
            width="90vw"
          >
            {[1, 2, 3, 4].map((index) => {
              return (
                <Box key={index} width="180px" height="400px" marginRight={3} paddingTop={2}>
                  <Skeleton variant="text" animation="wave" height={20} />
                  <Skeleton variant="rect" width={180} height={254} animation="wave" />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </>
  )
}
