import {
  Box,
  makeStyles,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { CropOriginal } from '@material-ui/icons'
import { ConditionBadge } from 'components/facets/ConditionBadge'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import productImageNotAvailable from 'img/productImageNotAvailable.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { getPlaysetCoefficient } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { OrderArticle } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { ColorBox } from './ColorBox'

export interface ArticlesTableProps {
  articles: OrderArticle[]
  setSelectedArticle: (article: OrderArticle) => void
  setOpenReportIssueDialog: (open: boolean) => void
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    height: 'auto',
  },
  paperLarge: {
    height: '90vh',
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    cursor: 'pointer',
  },
  imgLarge: {
    width: 'auto',
    height: '100%',
    padding: '30px',
  },
}))

export const ArticlesTable = ({
  articles,
  setSelectedArticle,
  setOpenReportIssueDialog,
}: ArticlesTableProps): JSX.Element => {
  const { t } = useTranslation()
  const iconHeight = '17px'

  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedImg, setSelectedImg] = useState('')
  const [isImageLarge, setIsImageLarge] = useState(false)
  const classes = useStyles()

  const handlePopoverOpen = (event: any, img: string) => {
    setAnchorEl(event.currentTarget)
    const imgsrc = img
      ? `https://mtgpowertools.s3.eu-central-1.amazonaws.com/images/mtg/${img}.jpg`
      : productImageNotAvailable
    setSelectedImg(imgsrc)
    setIsImageLarge(false)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleImageClick = () => {
    setIsImageLarge((prev) => !prev)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    function updateWindowDimensions() {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <>
      <Box style={{ maxHeight: windowHeight - 400, overflowY: 'auto' }} component={Paper}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <b>{t('card.name')}</b>
                </TableCell>
                <TableCell>
                  <b>{t('card.expansion')}</b>
                </TableCell>
                <TableCell>
                  <b>{t('card.cn')}</b>
                </TableCell>
                <TableCell>
                  <b>{t('card.rarity')}</b>
                </TableCell>
                <TableCell>
                  <b>{t('orders.quantity')}</b>
                </TableCell>
                <TableCell>
                  <b>{t('orders.properties')}</b>
                </TableCell>
                <TableCell>
                  <b>{t('orders.comments')}</b>
                </TableCell>
                <TableCell>
                  <b>{t('orders.issues')}</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((a: OrderArticle) => (
                <TableRow key={a.idArticle}>
                  <TableCell>
                    <CropOriginal
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      className="card-img-popover"
                      onClick={(event) => handlePopoverOpen(event, a.idProduct?.toString())}
                    />
                  </TableCell>
                  <TableCell>
                    <Box>{a.product.locName}</Box>
                    {a.language.languageName !== 'English' && <Box>[{a.product.enName}]</Box>}
                  </TableCell>
                  <TableCell>{a.product.expansion}</TableCell>
                  <TableCell>
                    {a.product.idGame !== 99 && (
                      <>
                        {a.product.abbreviation}-{a.product.nr}
                      </>
                    )}
                  </TableCell>
                  <TableCell>{a.product.rarity}</TableCell>
                  <TableCell>
                    {a.isPlayset ? a.count * getPlaysetCoefficient(a.product.idGame) : a.count}
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Flag
                        fallback={<span>Unknown</span>}
                        height="10px"
                        code={LANGUAGE_MAP_ICONS[a.language.idLanguage] || 'gg'}
                        style={{ alignSelf: 'center' }}
                      />
                      <Box padding="3px" />
                      <ConditionBadge condition={a.condition} />
                      {a.isFoil && (
                        <img
                          alt="foil"
                          height={iconHeight}
                          src={foilIcon}
                          style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                        />
                      )}
                      {a.isSigned && (
                        <img
                          alt="signed"
                          height={iconHeight}
                          src={signedIcon}
                          style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                        />
                      )}
                      {a.isFirstEd && (
                        <img
                          alt="firstEd"
                          height={iconHeight}
                          src={firstEdIcon}
                          style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                        />
                      )}
                      {a.isReverseHolo && (
                        <img
                          alt="reverseHolo"
                          height={iconHeight}
                          src={reverseHoloIcon}
                          style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                        />
                      )}
                    </Box>
                  </TableCell>
                  {/* <TableCell>{getPriceFormat(a.price, a.currencyCode)}</TableCell> */}
                  <TableCell>{a.comments}</TableCell>
                  <TableCell>
                    {a.issues?.length > 0 && (
                      <ColorBox
                        type="prop"
                        name="issues"
                        state="issues"
                        active
                        clickable
                        numIssues={a.issues?.length}
                        onClick={() => {
                          setSelectedArticle(a)
                          setOpenReportIssueDialog(true)
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: isImageLarge ? classes.paperLarge : classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={
                isImageLarge
                  ? { vertical: 'center', horizontal: 'center' }
                  : { vertical: 'bottom', horizontal: 'left' }
              }
              transformOrigin={
                isImageLarge
                  ? { vertical: 'center', horizontal: 'center' }
                  : { vertical: 'bottom', horizontal: 'right' }
              }
              onClose={handlePopoverClose}
            >
              <img
                src={selectedImg}
                alt="Detail"
                className={isImageLarge ? classes.imgLarge : classes.img}
                onClick={handleImageClick}
              />
            </Popover>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
