import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldInputProps, useField, useFormikContext } from 'formik'
import { ReactNode } from 'react'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      width: '100%',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

interface InputWrapperProps<T> {
  name: string
  children: (
    field: FieldInputProps<T>,
    setFieldValue: (field: string, value: T, shouldValidate?: boolean | undefined) => void
  ) => ReactNode
}

export const InputWrapper = <T extends unknown>(props: InputWrapperProps<T>): JSX.Element => {
  const classes = useStyles()
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  return (
    <Box className={classes.root} margin={1}>
      {props.children(field, setFieldValue)}
    </Box>
  )
}
