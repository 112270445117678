import { makeStyles } from '@material-ui/styles'
import { CustomTooltip } from './CustomTooltip'
import { HelpIcon } from './Icons'

interface HelpButtonProps {
  tooltip: string | React.ReactElement
  small?: boolean
  dataTestid?: string
}

export const HelpButton = ({
  small = false,
  tooltip,
  dataTestid,
}: HelpButtonProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      top: small ? '4px' : '5px',
      left: '4px',
      cursor: 'help',
    },
  }))
  const classes = useStyles()
  return (
    <CustomTooltip enterDelay={300} interactive title={tooltip}>
      <HelpIcon
        fontSize={small ? 'default' : 'large'}
        color={'primary'}
        className={classes.root}
        data-testid={dataTestid}
      />
    </CustomTooltip>
  )
}
