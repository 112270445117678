import { Box } from '@material-ui/core'
import { CircularLoading } from 'components/facets'
import { usePriceGuide } from 'components/providers/PriceGuideProvider'
import { useUser } from 'components/providers/UserProvider'
import { autopriceArticles, recordUsage, sendDevNotification } from 'lib/api'
import { useErrorHandler } from 'lib/hooks'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Activity,
  InventoryArticle,
  PricedInventoryArticle,
  PriceGuidePricingOperation,
  PriceReport,
  PricingStrategy,
} from 'shared'
import { SetState } from 'types'
import { CancellationToken } from 'utils/cancellationToken'
import { StartAutopricingPresenter } from '../StartAutopricingPresenter'
import { AutopriceSummary } from './AutopriceSummary'

interface AutopricePresenterProps {
  articles: InventoryArticle[]
  articlesToPrice: InventoryArticle[]
  setStock: SetState<InventoryArticle[]>
  cancellationToken: CancellationToken
  closeDialog: () => void
  showFailedAutopricingCards?: boolean
  setDone?: SetState<boolean>
  reapplySort?: () => void
}

export const AutopricePresenter = ({
  articles,
  articlesToPrice,
  setStock,
  closeDialog,
  showFailedAutopricingCards = false,
  setDone = () => undefined,
  reapplySort = () => undefined,
  cancellationToken,
}: AutopricePresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { activeGame, user } = useUser()

  const [isLoading, setLoading] = useState(false)
  const [articlesPriced, setArticlesPriced] = useState<PricedInventoryArticle[]>([])
  const [progress, setProgress] = useState(0)
  const [failures, setFailures] = useState<InventoryArticle[]>([])
  const [chosenStrategyId, setChosenStrategyId] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const { handle } = useErrorHandler()
  const { getPriceGuide } = usePriceGuide()
  if (!user) return <></>

  const chosenStrategy = user.pricingStrategies.find(({ _id }) => _id === chosenStrategyId)

  const mergePricedArticlesToInitial = (
    pricedArticles: PricedInventoryArticle[]
  ): (InventoryArticle | PricedInventoryArticle)[] => {
    const sortedLocalArticles = (articles as InventoryArticle[]).sort((a, b) =>
      a.id!.localeCompare(b.id!)
    )
    const sortedPricedArticles = pricedArticles.sort((a, b) => a.id!.localeCompare(b.id!))

    const mergedStock = []
    let cnt = 0
    for (let i = 0; i < sortedLocalArticles.length; i++) {
      if (sortedLocalArticles[i]?.id === sortedPricedArticles[cnt]?.id) {
        const articleToPush = sortedPricedArticles[cnt]
        articleToPush.newComment = sortedLocalArticles[i].newComment
        mergedStock.push(articleToPush)
        cnt += 1
      } else {
        mergedStock.push(sortedLocalArticles[i])
      }
    }

    return mergedStock
  }
  const applyResultsOfPricing = (
    localArticles: InventoryArticle[],
    autopricingResult: Record<string, PriceReport>
  ): {
    mergedStock: (InventoryArticle | PricedInventoryArticle)[]
    failed: PricedInventoryArticle[]
    successful: PricedInventoryArticle[]
  } => {
    const sortedLocalArticles = localArticles.sort((a, b) => a.id!.localeCompare(b.id!))

    const mergedStock = []
    const failed = []
    const successful = []
    for (let i = 0; i < sortedLocalArticles.length; i++) {
      const article = sortedLocalArticles[i]

      const result = autopricingResult[article?.id]
      if (!result) {
        mergedStock.push(article)
        continue
      }

      if (result.failure) {
        const failedArticle = new PricedInventoryArticle({
          ...article,
          priceReport: result,
          failedAutopricing: true,
        })
        mergedStock.push(failedArticle)
        failed.push(failedArticle)
      } else {
        const successfulArticle = new PricedInventoryArticle({
          ...article,
          failedAutopricing: false,
          priceReport: result,
          newPrice: result.price,
        })
        mergedStock.push(successfulArticle)
        successful.push(successfulArticle)
      }
    }

    return { mergedStock, failed, successful }
  }

  const runCoreArticleDataPricing = async (strategy: PricingStrategy | undefined) => {
    const articlesAttributes = articlesToPrice.map((article) => article.attributes)
    const autopricingResults = await autopriceArticles(
      [
        {
          articles: articlesAttributes,
          idGame: activeGame.idGame,
          pricingStrategyId: strategy?._id ?? '',
        },
      ],
      setProgress,
      cancellationToken
    )

    const { successful, mergedStock, failed } = applyResultsOfPricing(articles, autopricingResults)

    return { successful, mergedStock, failed }
  }

  const handleArticleDataPricing = async (strategy: PricingStrategy | undefined) => {
    try {
      const { successful, mergedStock, failed } = await runCoreArticleDataPricing(strategy)

      setDone(true)
      setArticlesPriced(successful)
      setFailures(failed)

      setStock(mergedStock)
      reapplySort()

      await recordUsage(Activity.AutopriceArticles, {
        count: articlesToPrice.length,
        idGame: activeGame.idGame,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
      //@ts-ignore
      if (error?.message !== 'cancelled') {
        enqueueSnackbar(t('error.autopricing'), { variant: 'error' })

        await sendDevNotification(
          `Autopricing (${articlesToPrice.length}) failed for ${user.username}: ${error}`
        )
      } else {
        enqueueSnackbar(t('autopriceArticles.cancelled'), { variant: 'info' })
      }
      setLoading(false)
    }
  }

  const runCorePriceGuidePricing = async (strategy: PricingStrategy | undefined) => {
    recordUsage(Activity.PriceGuideArticles, {
      name: user.cardmarketUsername,
      idGame: activeGame.idGame,
    })

    const numOfSteps = Math.round(articlesToPrice.length / 500) + 1
    for (let i = 0; i < numOfSteps; i++) {
      setProgress((i / numOfSteps) * 100)
      await new Promise((resolve, _reject) => setTimeout(resolve, 10))
    }
    setProgress(100)
    await new Promise((resolve, _reject) => setTimeout(resolve, 10))

    if (!strategy) return

    const pricedArticles = articlesToPrice.map((article) => {
      const priceGuide = getPriceGuide(article.card._id)
      if (!priceGuide) {
        return new PricedInventoryArticle({
          ...article,
          failedAutopricing: true,
          priceReport: { failure: 'Price guide missing', pricingStrategy: strategy },
        })
      } else {
        try {
          const chosenBulkSettingsId = strategy.bulkSettingsId[activeGame.idGame]
          const bulkSettings =
            user.bulkSettings.find((bulk) => bulk._id === chosenBulkSettingsId)?.priceMap || {}

          const priceReport = new PriceGuidePricingOperation({
            article,
            strategy: strategy,
            bulkSettings: bulkSettings,
            currency: user.currency,
            gameSettings: {
              firstEdEnabled: false,
              idGame: activeGame.idGame,
              playsetCount: activeGame.playsetCount || 4,
            },
          }).execute(priceGuide)

          return new PricedInventoryArticle({
            ...article,
            newPrice: priceReport.price,
            priceReport,
          })
        } catch (err) {
          return new PricedInventoryArticle({
            ...article,
            failedAutopricing: true,
            priceReport: {
              failure: 'Reference price missing in price guide',
              pricingStrategy: chosenStrategy,
            },
          })
        }
      }
    })

    return pricedArticles
  }

  const handlePriceGuidePricing = async (strategy: PricingStrategy | undefined) => {
    try {
      const pricedArticles = await runCorePriceGuidePricing(strategy)
      setLoading(false)
      if (pricedArticles) {
        const failedArticles = pricedArticles.filter((article) => article.priceReport.failure)

        const successfulArticles = pricedArticles.filter((article) => !article.priceReport.failure)

        setArticlesPriced(successfulArticles)
        setDone(true)
        setFailures(failedArticles)

        const merged = mergePricedArticlesToInitial(pricedArticles)

        setStock(merged)
        reapplySort()
      }
    } catch (err) {
      setLoading(false)
      handle(err)
    }
  }

  const handlePrice = async (): Promise<void> => {
    setLoading(true)

    if (chosenStrategy?.base.kind === 'minMax') {
      try {
        const strategy1 = user.pricingStrategies.find(
          (ps) => ps._id === chosenStrategy.base.tactic.strategy1
        )
        const strategy2 = user.pricingStrategies.find(
          (ps) => ps._id === chosenStrategy.base.tactic.strategy2
        )

        let strategy1Results: PricedInventoryArticle[] | undefined
        if (strategy1?.base.kind === 'articleData') {
          const { successful, failed } = await runCoreArticleDataPricing(strategy1)
          strategy1Results = successful.concat(failed)
        } else {
          strategy1Results = await runCorePriceGuidePricing(strategy1)
        }

        let strategy2Results: PricedInventoryArticle[] | undefined
        if (strategy2?.base.kind === 'articleData') {
          const { successful, failed } = await runCoreArticleDataPricing(strategy2)
          strategy2Results = successful.concat(failed)
        } else {
          strategy2Results = await runCorePriceGuidePricing(strategy2)
        }

        // compare results
        const comparedArticles: PricedInventoryArticle[] = []
        const failedArticles: PricedInventoryArticle[] = []
        strategy1Results?.forEach((strategy1Article) => {
          const strategy2Article = strategy2Results?.find(
            (article) => article.id === strategy1Article.id
          )

          if (strategy1Article.failedAutopricing) {
            if (strategy2Article && !strategy2Article.failedAutopricing) {
              comparedArticles.push(strategy2Article)
            } else {
              failedArticles.push(strategy1Article)
            }
          } else {
            if (strategy2Article && !strategy2Article.failedAutopricing) {
              const strategy1Price = strategy1Article.newPrice || 0
              const strategy2Price = strategy2Article.newPrice || 0
              const comparisonOperator = chosenStrategy.base.tactic.parameter.toLowerCase()

              if (
                (comparisonOperator === 'max' && strategy2Price > strategy1Price) ||
                (comparisonOperator === 'min' && strategy2Price < strategy1Price)
              ) {
                comparedArticles.push(strategy2Article)
              } else {
                comparedArticles.push(strategy1Article)
              }
            } else {
              comparedArticles.push(strategy1Article)
            }
          }
        })

        setDone(true)
        setArticlesPriced(comparedArticles)
        setFailures(failedArticles)

        const merged = mergePricedArticlesToInitial(comparedArticles.concat(failedArticles))

        setStock(merged)
        reapplySort()

        setLoading(false)
      } catch (err) {
        setLoading(false)
        handle(err)
      }
    } else if (chosenStrategy?.base.kind === 'articleData') {
      await handleArticleDataPricing(chosenStrategy)
    } else {
      await handlePriceGuidePricing(chosenStrategy)
    }
  }

  return (
    <Box minWidth="300px">
      {articlesPriced.length || failures.length ? (
        <Box padding={2}>
          <AutopriceSummary
            failures={failures}
            chosenStrategy={chosenStrategy!}
            articlesPriced={articlesPriced}
            showFailedAutopricingCards={showFailedAutopricingCards}
            closeDialog={closeDialog}
          />
        </Box>
      ) : (
        <>
          {isLoading ? (
            <Box padding={5} width="fit-content" margin="0 auto">
              {progress === 100 ? 'Finishing...' : <CircularLoading value={progress} />}
            </Box>
          ) : (
            <StartAutopricingPresenter
              chosenStrategyId={chosenStrategyId}
              setChosenStrategyId={setChosenStrategyId}
              competitors={user.competitors}
              pricingStrategies={user.pricingStrategies}
              onStartAutopricing={handlePrice}
              articles={articlesToPrice}
              competitionDisabled={user.subscription.featuresDisabled.includes(
                'stockPricing.competitionPricing'
              )}
              activeGameId={activeGame.idGame}
            />
          )}
        </>
      )}
    </Box>
  )
}
