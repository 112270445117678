import { Box, Checkbox, FormControlLabel, Paper, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { editUserAttribute } from 'lib/api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const FreeTierPresenter = (): JSX.Element => {
  const { t } = useTranslation()
  const { user, setUser, fetchUser } = useUser()

  const [checked, setChecked] = useState(false)

  const sendConsent = async () => {
    await editUserAttribute(true, 'consentedToBranding')
    setUser((user) => ({ ...user, consentedToBranding: true }))
    await fetchUser()
  }

  return (
    <Paper>
      <Box padding={2} textAlign="center">
        <Typography variant="overline">{t('misc.noActiveSubscription')}</Typography>

        <Typography gutterBottom variant="body1">
          &bull; {t('freeTier.noLonger')}
        </Typography>
        <Typography gutterBottom variant="body1">
          &bull; {t('freeTier.maxArticles')}
        </Typography>

        {!user.consentedToBranding && (
          <Box>
            <Box margin="0 auto" width="fit-content">
              <Box display={'flex'} alignItems={'center'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="publish-changes-checkbox"
                      onChange={() => setChecked(!checked)}
                      name="checkedB"
                      checked={checked}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" align={'left'}>
                      {t('freeTier.confirm')}
                    </Typography>
                  }
                />
              </Box>
            </Box>
            <Button onClick={sendConsent} disabled={!checked} color="primary" variant="outlined">
              {t('freeTier.continue')}
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  )
}
