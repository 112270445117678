import { Box, ListItem, Typography } from '@material-ui/core'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import playsetIcon from 'img/playsetIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import Flag from 'react-world-flags'
import { CardLanguage, RichArticle } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { Card } from './Card/Card'
import { Condition } from './Condition'

interface LocalArticleProps {
  article: RichArticle
  showCard?: boolean
  showPrice?: boolean
  showComment?: boolean
  lineBreak?: boolean
  hideTitle?: boolean
}

export const LocalArticle = (props: LocalArticleProps): JSX.Element => {
  const {
    article: {
      quantity,
      isFoil,
      isPlayset,
      condition,
      idLanguage,
      card,
      isFirstEd,
      isSigned,
      isReverseHolo,
      comment,
      price,
    },
    lineBreak,
    showCard = false,
    showComment,
    showPrice,
    hideTitle,
  } = props

  const shortenLongName = (name: string): string =>
    name.length < 21 ? name : name.slice(0, 21) + '...'

  const title = hideTitle
    ? `${quantity}x`
    : `${quantity}x ${shortenLongName(card.name)} ${card.set}`
  const attributes = (
    <>
      <Condition value={condition} />{' '}
      <Flag
        style={{ padding: '0px 5px' }}
        //@ts-ignore
        fallback={<span>Unknown</span>}
        height="12"
        data-testid={`${CardLanguage[idLanguage]}-flag`}
        code={LANGUAGE_MAP_ICONS[idLanguage] || 'gg'}
      />{' '}
      {isFoil && <img data-testid="is-foil" alt="foil" height="18px" src={foilIcon} />}{' '}
      {isPlayset && <img data-testid="is-playset" alt="playset" height="18px" src={playsetIcon} />}
      {isFirstEd && (
        <img data-testid="is-first-ed" alt="first edition" height="18px" src={firstEdIcon} />
      )}
      {isReverseHolo && (
        <img data-testid="is-first-ed" alt="first edition" height="18px" src={reverseHoloIcon} />
      )}
      {isSigned && (
        <img data-testid="is-signed" alt="first edition" height="18px" src={signedIcon} />
      )}
      {showComment && (
        <span style={{ padding: '0 3px' }}>
          {comment.length <= 15 ? comment : comment.slice(0, 15) + '...'}
        </span>
      )}
      {showPrice && (
        <Typography component={'span'} color="primary">
          {price}€
        </Typography>
      )}
    </>
  )
  if (lineBreak) {
    return (
      <Box
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: '95%',
        }}
      >
        <ListItem>{showCard ? <Card card={card} /> : title}</ListItem>
        <ListItem>{attributes}</ListItem>
      </Box>
    )
  } else {
    return (
      <ListItem>
        {showCard ? <Card card={card} /> : title}
        {attributes}
      </ListItem>
    )
  }
}
