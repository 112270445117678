import { Backdrop, Box, CircularProgress, Drawer, Grid, Typography } from '@material-ui/core'
import { ClosableDialog } from 'components/controls/dialogs'
import { Button } from 'components/facets'
import { IntroSnackbar } from 'components/facets/IntroSnackbar'
import { useUser } from 'components/providers/UserProvider'
import { theme } from 'config'
import { localDB } from 'config/localforage'
import {
  changeBotActivation,
  createUserSubEntity,
  deleteUserSubEntity,
  editUserSubEntity,
  getPriceUpdateArticles,
  getPriceUpdates,
  getUser,
  recordUsage,
  runPriceUpdate,
} from 'lib/api'
import { ApiCallStatus, useApiCall } from 'lib/hooks/useApiCall'
import { useIntroSnackbar } from 'lib/hooks/useIntroSnackbar'
import { currentCardmarketAccount, getUniqueNewName } from 'lib/utils'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Activity,
  createInventoryArticle,
  InventoryArticle,
  PricingPlan,
  PRICING_DEFAULTS,
  User,
} from 'shared'
import { StockState } from '../..'
import { PriceUpdatePresenter } from '../../PriceUpdates/PriceUpdatesPresenter'
import { PricingPlanPresenter } from '../../PricingPlan/PricingPlanPresenter'
import { CreatePlanPresenter } from './CreatePlanPresenter'
import { IntroCreatePlanPresenter } from './IntroCreatePlanPresenter'
import { PricingBotActivityPresenter } from './PricingBotActivityPresenter'
import { PricingPlanBox } from './PricingPlanBox'

export interface PricingBotPresenterProps {
  handleChange: (apiRequest: () => Promise<Partial<User>>) => Promise<void>
}

const usePriceUpdateArticles = (
  updateId: string | null
): [StockState, (newValue: StockState | ((arg: StockState) => StockState)) => void, boolean] => {
  const priceUpdateArticlesCall = useApiCall<typeof getPriceUpdateArticles>(
    getPriceUpdateArticles,
    undefined,
    true
  )

  const [loading, setLoading] = useState(false)

  const [inventoryState, setInventoryState] = useState<StockState>({
    stock: [],
    sealedStock: [] as InventoryArticle[],
    fetchedAt: new Date().toLocaleString('sk-SK'),
    entityId: String(updateId),
  })

  useEffect(() => {
    if (updateId) {
      setLoading(true)

      localDB.getItem(`update-${updateId}`).then((value: any) => {
        if (!value?.stock?.length) {
          priceUpdateArticlesCall.performCall(updateId)
        } else {
          try {
            const hydrated = {
              //@ts-ignore
              ...value,
              entityId: updateId,
              stock: value.stock.map((article: any) => createInventoryArticle(article)),
            }

            setInventoryState(hydrated)
          } catch (err) {
            console.log('incorrect cached value')
          }
        }

        setLoading(false)
      })
    } else {
      setInventoryState({
        stock: [],
        sealedStock: [] as InventoryArticle[],
        fetchedAt: new Date().toLocaleString('sk-SK'),
      })
    }
  }, [updateId])

  useEffect(() => {
    if (priceUpdateArticlesCall.status === ApiCallStatus.Success) {
      const newInventoryStock = {
        ...inventoryState,
        stock: priceUpdateArticlesCall.data,
        entityId: updateId!,
      }
      localDB.setItem(`update-${updateId}`, newInventoryStock)
      setInventoryState(newInventoryStock)
    }
  }, [priceUpdateArticlesCall.status])

  return [
    inventoryState,
    setInventoryState,
    loading || priceUpdateArticlesCall.status === ApiCallStatus.Fetching,
  ]
}

const IntroContent = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <Typography variant="body1">
          <b>{t('priceUpdates.yourBot')}</b>
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" gutterBottom>
          {t('priceUpdates.howTo1')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t('priceUpdates.howTo2')}
        </Typography>{' '}
        <Typography variant="body2" gutterBottom>
          {t('priceUpdates.howTo3')}
        </Typography>
      </Box>
    </Box>
  )
}

export const PricingBotPresenter = ({ handleChange }: PricingBotPresenterProps): JSX.Element => {
  const [pricingPlanCtx, setPricingPlanCtx] = useState<PricingPlan | null>(null)
  const { user, activeGame, setUser } = useUser()
  const { t } = useTranslation()
  const [createPlanDialogOpen, setCreatePlanDialogOpen] = useState(false)
  const [introCreatePlanDialogOpen, setIntroCreatePlanDialogOpen] = useState(false)
  const [planIdTestRunning, setPlanIdTestRunning] = useState<string>('')
  useIntroSnackbar('pricing-bot-intro', <IntroContent />)
  if (!user) return <></>

  const pricingPlans = user.pricingPlans.filter((plan) => plan.idGame === activeGame.idGame)

  const onPricingPlanChange = useCallback(
    async (pricingPlan: PricingPlan): Promise<void> => {
      setPricingPlanCtx(pricingPlan)
      //handleChange(() => editUserSubEntity(pricingPlan, 'pricingPlans'), 'pricingPlans')
    },
    [setPricingPlanCtx]
  )

  // const activePlanId = user.activeBots.find(
  //   (bot) => bot.account === user.cardmarketUsername && bot.idGame === activeGame.idGame
  // )?.planId

  const [activePlanId, setActivePlanId] = useState(
    user.activeBots.find(
      (bot) => bot.account === user.cardmarketUsername && bot.idGame === activeGame.idGame
    )?.planId
  )

  const activeGamePricingPlans = user.pricingPlans.filter(
    (plan) => plan.idGame === activeGame.idGame
  )

  // const activePlan = activeGamePricingPlans.find((plan) => plan._id === activePlanId)

  const [activePlan, setActivePlan] = useState(
    activeGamePricingPlans.find((plan) => plan._id === activePlanId)
  )

  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const updateBotActivation = async () => {
      setActivePlanId(activePlan?._id)
      if (user.cardmarketUsername) {
        await changeBotActivation(
          currentCardmarketAccount(user)!,
          activeGame.idGame,
          activePlan ? activePlan._id : 'none'
        )
      }
      const newUser = await getUser()
      setUser(newUser)
      setShowSpinner(false)
    }

    setShowSpinner(true)
    updateBotActivation()
  }, [activePlan])

  useEffect(() => {
    setActivePlan(activeGamePricingPlans.find((plan) => plan._id === activePlanId))
  }, [activePlanId])

  const [selectedPlanId, setSelectedPlanId] = useState(
    (activePlan?._id || activeGamePricingPlans?.[0]?._id) ?? ''
  )

  useEffect(() => {
    if (!activeGamePricingPlans.find(({ _id }) => _id === selectedPlanId)) {
      setSelectedPlanId((activePlan?._id || activeGamePricingPlans?.[0]?._id) ?? '')
    }
  }, [activeGamePricingPlans.length])

  const pricingPlanJson = JSON.stringify(pricingPlanCtx)

  useEffect(() => {
    let timeout: any = null
    if (pricingPlanCtx?._id) {
      timeout = setTimeout(
        () => handleChange(() => editUserSubEntity(pricingPlanCtx, 'pricingPlans')),
        300
      )
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [pricingPlanJson])

  const [showBotRun, setShowBotRun] = useState<string | null>(null)

  const [priceUpdateArticles, , loading] = usePriceUpdateArticles(showBotRun)

  const onPriceUpdateClose = useCallback(() => {
    setShowBotRun(null)
  }, [setShowBotRun])

  const priceUpdatesCall = useApiCall<typeof getPriceUpdates>(getPriceUpdates, [activeGame.idGame])

  useEffect(() => {
    const pollingInterval = setInterval(() => {
      priceUpdatesCall.performCall(activeGame.idGame)
    }, 5000)

    return () => {
      clearInterval(pollingInterval)
    }
  }, [])

  const shownBotRun = priceUpdatesCall.data?.updates.find((update) => update._id === showBotRun)

  const onPlanEdit = useCallback((plan) => setPricingPlanCtx(plan), [setPricingPlanCtx])

  const onTest = async (testPlan: PricingPlan) => {
    try {
      setPlanIdTestRunning(testPlan._id)
      await runPriceUpdate(testPlan._id, activeGame.idGame, user.cardmarketUsername)
      recordUsage(Activity.BotTestRun, {
        plan: pricingPlans.find((plan) => plan._id === testPlan._id),
      })
    } catch (e) {
      console.error(e)
    }
  }

  const onPlanCreate = useCallback(
    async (plan) => {
      setCreatePlanDialogOpen(false)
      try {
        await handleChange(async () => {
          const response = await createUserSubEntity(
            {
              name: getUniqueNewName(`${activeGame.name} Program`, user?.pricingPlans),
              idGame: activeGame.idGame,
              settings: {
                maxValueDecrease: PRICING_DEFAULTS.maxDecrease,
                maxValueIncrease: PRICING_DEFAULTS.maxIncrease,
              },
              defaultNode: { method: undefined, id: 'default' },
              nodes: [],
              disallowNesting: true,
              ...plan,
            } as Omit<PricingPlan, '_id'>,
            'pricingPlans'
          )
          //#$ remove ignores
          //@ts-ignore
          if (response?.[response?.length - 1]) {
            //@ts-ignore
            setPricingPlanCtx(response?.[response?.length - 1] as PricingPlan)
          }
          return response
        })
      } finally {
      }
    },
    [handleChange, activeGame.idGame, user.pricingPlans.length]
  )

  const onStrategyClone = useCallback(
    async (plan) => {
      try {
        await handleChange(() =>
          createUserSubEntity(
            {
              ...plan,
              name: getUniqueNewName(`New Pricing Program`, user?.pricingPlans),
              idGame: activeGame.idGame,
              settings: {
                maxValueDecrease: PRICING_DEFAULTS.maxDecrease,
                maxValueIncrease: PRICING_DEFAULTS.maxIncrease,
              },
            } as Omit<PricingPlan, '_id'>,
            'pricingPlans'
          )
        )
      } finally {
      }
    },
    [handleChange, activeGame.idGame]
  )

  const onStrategyChange = useCallback(
    async (pricingStrategy) => {
      handleChange(() => editUserSubEntity(pricingStrategy, 'pricingStrategies'))
    },
    [handleChange]
  )

  const onStrategyDelete = useCallback(
    async (id) => {
      handleChange(() => deleteUserSubEntity(id, 'pricingStrategies'))
    },
    [handleChange]
  )

  const onPricingPlanDrawerClose = useCallback(() => setPricingPlanCtx(null), [setPricingPlanCtx])

  return (
    <>
      {showSpinner && (
        <Backdrop
          open={showSpinner}
          style={{ color: theme.palette.lightGray, zIndex: theme.zIndex.modal }}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Box>
        <Box padding={2}>
          <Box display="flex" alignItems={'center'}>
            <Box>
              <Typography variant="h5" style={{ textTransform: 'uppercase' }}>
                <b>
                  BOT {activePlan ? 'online' : 'offline'}
                  {activePlan && `: ${activePlan.name}`}
                </b>
              </Typography>
              <Box marginTop={1}>
                <Typography variant="body2">
                  {t('priceUpdates.planSettings.sealedWarning')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid container spacing={4}>
          <Grid item lg={6} md={12}>
            <Button
              fullWidth
              variant={activeGamePricingPlans.length ? 'outlined' : 'contained'}
              color="primary"
              onClick={async () => {
                setIntroCreatePlanDialogOpen(true)
              }}
            >
              {t('priceUpdates.createNewPlan')}
            </Button>
            <Box>
              {activeGamePricingPlans.map((plan) => (
                <Box key={plan.name} marginY={2}>
                  <PricingPlanBox
                    plan={plan}
                    onEdit={onPlanEdit}
                    activePlan={activePlan}
                    setActivePlan={setActivePlan}
                    onTest={onTest}
                    isRunning={planIdTestRunning === plan._id}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item lg={6} md={12}>
            <PricingBotActivityPresenter
              onViewUpdate={(updateId) => setShowBotRun(updateId)}
              activePlan={activePlan}
              selectedPlanId={selectedPlanId}
              setSelectedPlanId={setSelectedPlanId}
              isRunning={planIdTestRunning !== ''}
              setIsRunning={setPlanIdTestRunning}
              updates={
                priceUpdatesCall.data?.updates
                  ?.filter(
                    (update) =>
                      update.details?.cardmarketUsername === user.cardmarketUsername &&
                      update.idGame === activeGame.idGame
                  )
                  .sort(
                    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                  ) ?? []
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Drawer
        disableEnforceFocus
        onClose={() => setPricingPlanCtx(null)}
        anchor={'right'}
        open={!!pricingPlanCtx}
      >
        {!!pricingPlanCtx && (
          <Box width={'100vw'} minWidth={'50rem'}>
            <PricingPlanPresenter
              onClose={onPricingPlanDrawerClose}
              pricingPlan={pricingPlanCtx}
              onChange={onPricingPlanChange}
              onStrategyDelete={onStrategyDelete}
              onStrategyChange={onStrategyChange}
              onStrategyClone={onStrategyClone}
            />
          </Box>
        )}
      </Drawer>

      <Drawer open={!!showBotRun} anchor="right" onClose={() => setShowBotRun(null)}>
        {showBotRun && (
          <PriceUpdatePresenter
            onClose={onPriceUpdateClose}
            articles={priceUpdateArticles.stock ?? []}
            loading={loading}
            updateType={shownBotRun?.details?.type}
            updateId={priceUpdateArticles.entityId}
          />
        )}
      </Drawer>

      <ClosableDialog
        maxWidth="md"
        open={introCreatePlanDialogOpen}
        onClose={() => setIntroCreatePlanDialogOpen(false)}
      >
        {introCreatePlanDialogOpen && (
          <IntroCreatePlanPresenter
            setIntroCreatePlanDialogOpen={setIntroCreatePlanDialogOpen}
            setCreatePlanDialogOpen={setCreatePlanDialogOpen}
          />
        )}
      </ClosableDialog>

      <ClosableDialog
        maxWidth="lg"
        open={createPlanDialogOpen}
        onClose={() => setCreatePlanDialogOpen(false)}
      >
        {createPlanDialogOpen && <CreatePlanPresenter onCreate={onPlanCreate} />}
      </ClosableDialog>

      <IntroSnackbar />
    </>
  )
}
