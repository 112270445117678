import { Box, Checkbox, ListItemText, MenuItem } from '@material-ui/core'
interface MenuProps<T> {
  dataTestId?: string
  options: {
    value: T
    label: string
  }[]
  value: T[]
  onChange: (newValue: T[]) => void
}

export const MultipleMenu = <T extends string | number>({
  dataTestId,
  value,
  onChange,
  options,
}: MenuProps<T>): JSX.Element => {
  return (
    <Box data-testid={`MultipleOptionMenu-${dataTestId}`}>
      {options.map((option, index) => {
        return (
          <MenuItem
            onClick={() => {
              if (value.includes(option.value))
                onChange(value.filter((val) => val !== option.value))
              else onChange([...value, option.value])
            }}
            key={index}
            selected={value.includes(option.value)}
            value={option.value}
          >
            <Checkbox color="primary" checked={value.includes(option.value)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        )
      })}
    </Box>
  )
}
