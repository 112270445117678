import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { Button, CustomTooltip } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { cancelSubscription } from 'lib/api'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface CancellationPresenterProps {
  onDone: () => void
}

enum UnsubReason {
  takingBreak = 'takingBreak',
  notEnoughValue = 'notEnoughValue',
  badSupport = 'badSupport',
  badPerformance = 'badPerformance',
  differentSolution = 'differentSolution',
  notSellingAnymore = 'notSellingAnymore',
  somethingElse = 'somethingElse',
}

export const CancellationPresenter = ({ onDone }: CancellationPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { fetchUser } = useUser()

  const [unsubReasons, setUnsubReasons] = useState<UnsubReason[]>([])
  const [unsubComment, setUnsubComment] = useState('')

  const textFieldRef = useRef<HTMLDivElement | null>(null)

  return (
    <Box marginTop={4}>
      <Typography variant="h6">{t('account.unsubReason.title')}</Typography>

      <FormGroup>
        {Object.values(UnsubReason).map((unsubReason) => (
          <FormControlLabel
            key={unsubReason}
            checked={unsubReasons.includes(unsubReason)}
            onClick={() => {
              if (
                !unsubReasons.includes(unsubReason) &&
                unsubReason === UnsubReason.somethingElse
              ) {
                textFieldRef?.current?.focus()
                //@ts-ignore
                const [inputElement] = textFieldRef?.current?.getElementsByTagName('textarea')
                inputElement?.focus()
              }
              unsubReasons.includes(unsubReason)
                ? setUnsubReasons(unsubReasons.filter((r) => r !== unsubReason))
                : setUnsubReasons([...unsubReasons, unsubReason])
            }}
            control={<Checkbox color="primary" />}
            label={t(`account.unsubReason.${unsubReason}`)}
          />
        ))}
        <Box marginTop={2} width="100%">
          <TextField
            ref={textFieldRef}
            fullWidth={true}
            multiline={true}
            rows={4}
            variant="outlined"
            value={unsubComment}
            onChange={(e) => setUnsubComment(e.target.value)}
            label={t('account.unsubReason.comment')}
          ></TextField>
        </Box>
      </FormGroup>

      <CustomTooltip
        title={
          unsubReasons.includes(UnsubReason.somethingElse) && !(unsubComment.length > 2)
            ? 'You chose "Other reason" - please specify it in the comment field.'
            : ''
        }
      >
        <Box>
          <Button
            disabled={
              unsubReasons.includes(UnsubReason.somethingElse) && !(unsubComment.length > 2)
            }
            fullWidth
            onClick={async () => {
              await cancelSubscription({ unsubComment, unsubReasons: unsubReasons.join(',') })
              fetchUser()
              onDone()
            }}
          >
            {t('subscription.stop')}
          </Button>
        </Box>
      </CustomTooltip>
    </Box>
  )
}
