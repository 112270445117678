import DateFnsUtils from '@date-io/date-fns'
import { IconButton } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { theme } from 'config'
import i18n from 'config/i18n'
//@ts-ignore
import detectBrowserLanguage from 'detect-browser-language'
import Cookie from 'js-cookie'
import { getCurrentVersion } from 'lib/api'
import LogRocket from 'logrocket'
import meta from 'meta.json'
import { SnackbarProvider } from 'notistack'
import { createRef, useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Context from 'utils/context'
import {
  Account,
  Dashboard,
  FinishSignUp,
  ListingAndAppraisal,
  NotFound,
  SignIn,
  StockPricing,
} from './pages'
import { AdminPanel } from './pages/AdminPanel'
import { Debug } from './pages/Debug'
import { Ebay } from './pages/Ebay'
import { Inventory } from './pages/Inventory'
import { Jobs } from './pages/Jobs'
import { Kaboom } from './pages/Kaboom'
import { Orders } from './pages/Orders'
import { PricingBot } from './pages/PricingBot'
import { PricingSettings } from './pages/PricingSettings'
import { SignUp } from './pages/SignUp'
import { Status } from './pages/Status'
import { AppRerenderer } from './providers/AppRerender'
import { CardDataContextProvider } from './providers/CardDataProvider'
import { PriceGuideContextProvider } from './providers/PriceGuideProvider'
import { UserContextProvider } from './providers/UserProvider'

//@ts-ignore
global.appVersion = meta.version

const tagManagerArgs = {
  gtmId: 'GTM-NZKQF7X',
}

const hostname = window.location.hostname
const isOldApp = hostname.includes('app.tcgpowertools.com')
if (process.env.NODE_ENV === 'production') {
  // keep logrocket for 70% of users that use app.tcgpowertools.com
  if (!isOldApp || (isOldApp && Math.random() < 0.7)) {
    LogRocket.init('3cze7h/powertools')
  }
}

TagManager.initialize(tagManagerArgs)

export const verifyCorrectVersion = async (onIncorrectVersion: () => void): Promise<void> => {
  const response = await getCurrentVersion()
  //@ts-ignore
  if (response.data.version !== global.appVersion) {
    //@ts-ignore
    console.log('version mismatch', response.data.version, global.appVersion)
    if (caches) {
      caches.keys().then(function (names) {
        for (const name of names) caches.delete(name)
      })
    }
    onIncorrectVersion()
  } else {
    //@ts-ignore
    console.log('versions match', global.appVersion)
  }
}

function App(): JSX.Element {
  //const { username } = getUserFromCookie()

  const [isAuthenticated, setAuthenticated] = useState(!!Cookie.get('jwt'))
  const [shouldRedirectToNew, setShouldRedirectToNew] = useState(false)
  const browserLanguage = (detectBrowserLanguage() || 'en').substring(0, 2)

  i18n.changeLanguage(browserLanguage)

  useEffect(() => {
    //@ts-ignore
    verifyCorrectVersion(() => window.location.reload(true))
  }, [])

  const notistackRef = createRef()
  const onClickDismiss = (key: string | number) => () => {
    ;(notistackRef.current as { closeSnackbar: (key: string | number) => void }).closeSnackbar(key)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <IconButton
              data-testid="snackbar-close-btn"
              style={{ color: 'white' }}
              onClick={onClickDismiss(key)}
            >
              <Close />
            </IconButton>
          )}
          maxSnack={3}
        >
          <Context.Provider
            value={{
              ReactPixel,
              isAuthenticated,
              setAuthenticated,
              shouldRedirectToNew,
              setShouldRedirectToNew,
            }}
          >
            <BrowserRouter>
              <div className="app">
                <CssBaseline />
                <Switch>
                  <Route path="/signin">
                    <SignIn />
                  </Route>
                  <Route path="/signup">
                    <SignUp />
                  </Route>
                  <Route path="/b4ckoffic3/status">
                    <Status />
                  </Route>
                  <UserContextProvider>
                    <Route path="/b4ckoffic3/controls">
                      <AdminPanel />
                    </Route>
                    <CardDataContextProvider>
                      <PriceGuideContextProvider>
                        <AppRerenderer>
                          {/*@ts-ignore*/}
                          <Route exact path="/">
                            <Dashboard />
                          </Route>
                          <Route path="/listing-and-appraisal">
                            <ListingAndAppraisal />
                          </Route>
                          <Route path="/account">
                            <Account />
                          </Route>
                          <Route path="/finish-signup">
                            <FinishSignUp />
                          </Route>
                          <Route path="/stock-pricing">
                            <StockPricing />
                          </Route>
                          <Route path="/pricing-bot">
                            <PricingBot />
                          </Route>
                          <Route path="/d3bug">
                            <Debug />
                          </Route>
                          <Route path="/kaboom">
                            <Kaboom />
                          </Route>
                          <Route path="/pricing-settings">
                            <PricingSettings />
                          </Route>
                          <Route path="/orders">
                            <Orders />
                          </Route>
                          <Route path="/ebay">
                            <Ebay />
                          </Route>
                          <Route path="/inventory">
                            <Inventory />
                          </Route>
                          <Route path="/jobs">
                            <Jobs />
                          </Route>
                        </AppRerenderer>
                      </PriceGuideContextProvider>
                    </CardDataContextProvider>
                  </UserContextProvider>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </div>
            </BrowserRouter>
          </Context.Provider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export default App
