import { Box, Checkbox, FormControlLabel, Switch, Typography } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { ActionButton, DeleteIcon, EditIcon, IsSavingIcon, TestIcon } from 'components/facets'
import { PowerToolsPaper } from 'components/facets/PowerToolsPaper'
import { useUser } from 'components/providers/UserProvider'
import { deleteUserSubEntity, editUserSubEntity } from 'lib/api'
import { useConfirm } from 'lib/hooks'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { PricingPlan } from 'shared'
import { SetState } from 'types'
import { useHandleSettingsChange } from '../../StockPricingPresenter'
import { EditableElement } from '../EditableElement'

interface PricingPlanBoxProps {
  plan: PricingPlan
  onEdit: (plan: PricingPlan) => void
  activePlan: PricingPlan | undefined
  setActivePlan: SetState<PricingPlan | undefined>
  onTest: (plan: PricingPlan) => void
  isRunning: boolean
}

export const PricingPlanBox = memo(
  ({
    plan,
    onEdit,
    activePlan,
    setActivePlan,
    onTest,
    isRunning,
  }: PricingPlanBoxProps): JSX.Element => {
    const { t } = useTranslation()
    const { user } = useUser()
    const handleChange = useHandleSettingsChange()

    const [confirmDeletePlan, ConfirmDeletePlanDialog] = useConfirm(
      t('common.confirmDelete'),
      '',
      async () => await handleChange(() => deleteUserSubEntity(plan._id, 'pricingPlans'))
    )

    const changeSettings = async (
      attribute: keyof PricingPlan['settings'],
      plan: PricingPlan,
      newValue: unknown
    ): Promise<void> => {
      await handleChange(() =>
        editUserSubEntity(
          {
            ...plan,
            settings: {
              ...plan.settings,
              [attribute]:
                attribute === 'maxValueIncreaseEnabled' ? Boolean(newValue) : Number(newValue),
            },
          },
          'pricingPlans'
        )
      )
    }

    return (
      <PowerToolsPaper>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h6">
              <EditableElement
                value={plan.name}
                attribute="name"
                type="textfield"
                title
                viewOnly={false}
                onChange={(newValue) =>
                  handleChange(() =>
                    editUserSubEntity({ ...plan, name: newValue as string }, 'pricingPlans')
                  )
                }
                existingValue={plan.name}
                tooltipText={t('strategy.name')}
                name={`${t('priceUpdates.pricingPlan')} ${t('strategy.name')}`}
                dataTestid={`plan-${plan.name}`}
              />
            </Typography>
            <Box>
              <Box display="flex" padding={1} alignItems="center">
                <ArrowDownward />
                <Typography variant="body2">
                  {t('priceUpdates.planSettings.maxValueDecrease')}:{' '}
                  <EditableElement
                    onChange={(newValue) =>
                      changeSettings('maxValueDecrease', plan, Number(newValue) >= 0 ? newValue : 5)
                    }
                    name={t('priceUpdates.planSettings.maxValueDecrease')}
                    viewOnly={false}
                    type="number"
                    existingValue={plan.settings.maxValueDecrease}
                    tooltipText={t('priceUpdates.planSettings.maxValueDecreaseExplainer')}
                    value={`${plan.settings.maxValueDecrease}%`}
                    showBorder
                    dataTestid="pricing-plan-settings-maxValueDecrease"
                  />
                </Typography>
              </Box>
              <Box display="flex" padding={1} alignItems="center">
                <FormControlLabel
                  label={
                    <>
                      <Box display="flex" alignItems="center">
                        <ArrowUpward />
                        <Typography variant="body2">
                          {t('priceUpdates.planSettings.maxValueIncrease')}:{' '}
                        </Typography>
                      </Box>
                    </>
                  }
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={plan.settings.maxValueIncreaseEnabled}
                      onChange={(e) =>
                        changeSettings('maxValueIncreaseEnabled', plan, e.target.checked)
                      }
                    />
                  }
                  style={{ marginLeft: '0px', marginRight: '1px' }}
                />
                {/* 
                {t('priceUpdates.planSettings.maxValueIncrease')}: */}
                {plan.settings.maxValueIncreaseEnabled && (
                  <EditableElement
                    onChange={(newValue) =>
                      changeSettings(
                        'maxValueIncrease',
                        plan,
                        Number(newValue) >= 0 ? newValue : 10
                      )
                    }
                    name={t('priceUpdates.planSettings.maxValueIncrease')}
                    viewOnly={false}
                    type="number"
                    existingValue={plan.settings.maxValueIncrease}
                    tooltipText={t('priceUpdates.planSettings.maxValueIncreaseExplainer')}
                    value={`${plan.settings.maxValueIncrease}%`}
                    showBorder
                    dataTestid="pricing-plan-settings-maxValueIncrease"
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box display="flex">
            <Box marginTop="8px" marginX="2px">
              <Switch
                data-testid="pricing-bot-switch"
                onChange={(e) => {
                  setActivePlan(e.target.checked ? plan : undefined)
                }}
                checked={plan._id === activePlan?._id}
                color="primary"
                disabled={user?.subscription.featuresDisabled.includes('pricingBot')}
              />
              <Box
                width="auto"
                textAlign="center"
                height="22px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  color="secondary"
                  style={{
                    lineHeight: '110%',
                    whiteSpace: 'normal',
                    display: 'block',
                    marginTop: '-4px',
                  }}
                  variant="caption"
                >
                  {t('priceUpdates.activate')}
                </Typography>
              </Box>
            </Box>
            <ActionButton
              color="primary"
              onClick={() => onTest(plan)}
              tooltip={t('priceUpdates.explainTest')}
              text={t('priceUpdates.test')}
              data-test-id={`test-plan-${plan.name}`}
              disabled={isRunning}
              Icon={!isRunning ? TestIcon : IsSavingIcon}
            />{' '}
            <ActionButton
              color="primary"
              onClick={() => onEdit(plan)}
              text={t('common.edit')}
              data-test-id={`edit-plan-${plan.name}`}
              Icon={EditIcon}
            />{' '}
            <ActionButton
              color="primary"
              onClick={async () => {
                confirmDeletePlan()
              }}
              text={t('common.delete')}
              data-test-id={`delete-plan-${plan.name}`}
              Icon={DeleteIcon}
            />
          </Box>
        </Box>

        <ConfirmDeletePlanDialog />
      </PowerToolsPaper>
    )
  }
)
