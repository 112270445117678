import { Box, Typography } from '@material-ui/core'
import { Button } from 'components/facets/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SetState } from 'types'

export interface IntroCreatePlanPresenterProps {
  setIntroCreatePlanDialogOpen: SetState<boolean>
  setCreatePlanDialogOpen: SetState<boolean>
}

export const IntroCreatePlanPresenter = React.memo(function ({
  setIntroCreatePlanDialogOpen,
  setCreatePlanDialogOpen,
}: IntroCreatePlanPresenterProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Box>
      <Box paddingBottom={2}>
        <Typography variant="h5">{t('priceUpdates.planIntro')}</Typography>
      </Box>
      <Box paddingBottom={2}>
        <Typography variant="body1" gutterBottom>
          {t('priceUpdates.planHowTo1')}
        </Typography>
      </Box>
      <Box paddingBottom={2}>
        <Typography variant="body1" gutterBottom>
          {t('priceUpdates.planHowTo2')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" gutterBottom>
          {t('priceUpdates.planHowTo3')}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={() => {
            setIntroCreatePlanDialogOpen(false)
            setCreatePlanDialogOpen(true)
          }}
          variant="contained"
        >
          {t('common.next')}
        </Button>
      </Box>
    </Box>
  )
})
