import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import {
  AttentionIcon,
  CommentPTIcon,
  Eurocon,
  RightArrowIcon,
  SetConditionIcon,
  SetQuantityIcon,
} from 'components/facets/Icons'
import { useUser } from 'components/providers/UserProvider'
import { currencySymbol } from 'lib/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InventoryArticle } from 'shared'
import { VisualArticle } from '../../StockDisplay/VisualArticle'

export interface articlesChangedProps {
  articlesChanged: InventoryArticle[]
  onPublishChanges: () => Promise<void>
  onCancelClick: () => void
}

export const PublishChangesSummary = ({
  articlesChanged,
  onPublishChanges,
  onCancelClick,
}: articlesChangedProps): JSX.Element => {
  const [canPublish, setCanPublish] = useState<boolean>(false)
  const { t } = useTranslation()
  const { user } = useUser()

  const sumPrice = (articles: InventoryArticle[], oldPrice: boolean): number => {
    let totalPrice = 0
    for (let i = 0; i < articles.length; i++) {
      if (oldPrice && articles[i].newPrice) {
        const oldArticlePrice = articles[i].price
        totalPrice = totalPrice + oldArticlePrice
      }
      if (!oldPrice && articles[i].newPrice) {
        totalPrice = totalPrice + (articles[i].newPrice || 0)
      }
    }
    return Math.round(totalPrice * 100) / 100
  }

  const getPercentDifferentString = (articles: InventoryArticle[]): string => {
    let percent = (sumPrice(articles, false) * 100) / sumPrice(articles, true) - 100
    percent = Math.round(percent * 100) / 100
    if (percent > 0) {
      return '+' + percent
    } else {
      return String(percent)
    }
  }

  const changePublishAutorization = (): void => {
    setCanPublish(!canPublish)
  }

  const findPriceDifference = (article: InventoryArticle): number => {
    return article.newPrice ? article.price - article.newPrice : 0
  }

  const find4ArticlesWithLargestDecreasedPrice = (
    articles: InventoryArticle[]
  ): InventoryArticle[] => {
    const priceDifferenceList = articles.sort(function (a, b) {
      return findPriceDifference(b) - findPriceDifference(a)
    })
    return priceDifferenceList.filter((article) => findPriceDifference(article) > 0).slice(0, 4)
  }

  return (
    <Box data-testid="publish-changes-summary" width={'900px'}>
      <Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="body1" color={'textSecondary'}>
            {t('publish.changeInArticleValue')}
          </Typography>
          <Typography variant="body1" color={'textSecondary'} align={'right'}>
            {t('publish.amountOfEditedArticles')}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} paddingLeft={3}>
          <Box display={'flex'} marginTop={-2} alignItems="center" width={'70%'}>
            <Typography variant="h5" color={'textSecondary'}>
              {' '}
              {sumPrice(articlesChanged, true)}
              {currencySymbol(user?.currency)} &nbsp;
            </Typography>
            <RightArrowIcon style={{ fontSize: '30px' }} /> &nbsp;
            <Typography style={{ fontSize: '30px' }} variant="h5" color={'primary'}>
              <b>
                {' '}
                &nbsp;{sumPrice(articlesChanged, false)}
                {currencySymbol(user?.currency)} &nbsp;
              </b>
            </Typography>
            <Typography variant="h5" color={'textSecondary'}>
              (&nbsp;
              {articlesChanged.length > 0 && getPercentDifferentString(articlesChanged) !== 'NaN'
                ? getPercentDifferentString(articlesChanged) + '%'
                : '0%'}
              &nbsp;)
            </Typography>
          </Box>
          <Box justifyContent={'flex-end'} width={'30%'} display={'flex'}>
            <Box>
              <Typography
                variant="h4"
                color={'primary'}
                align={'right'}
                style={{ paddingRight: '10px', fontSize: '48px', marginTop: '-8px' }}
              >
                {articlesChanged.length}
              </Typography>
            </Box>
            <Box>
              <Box alignItems={'center'} display={'flex'} justifyContent={'flex-end'}>
                <Typography variant="body1" align={'right'} style={{ paddingRight: '10px' }}>
                  {articlesChanged.filter((article) => article.newQuantity).length}
                  {'   '}
                </Typography>
                <SetQuantityIcon style={{ fontSize: '20px' }} />
              </Box>
              <Box alignItems={'center'} display={'flex'} justifyContent={'flex-end'}>
                <Typography variant="body1" align={'right'} style={{ paddingRight: '10px' }}>
                  {
                    articlesChanged.filter(
                      (article) => article.newPrice && article.newPrice !== article.price
                    ).length
                  }
                  {'   '}
                </Typography>
                <Eurocon style={{ fontSize: '20px' }} />
              </Box>
              <Box alignItems={'center'} display={'flex'} justifyContent={'flex-end'}>
                <Typography variant="body1" align={'right'} style={{ paddingRight: '10px' }}>
                  {articlesChanged.filter((article) => article.newComment).length}
                  {'   '}
                </Typography>
                <CommentPTIcon style={{ fontSize: '20px' }} />
              </Box>
              <Box alignItems={'center'} display={'flex'} justifyContent={'flex-end'}>
                <Typography variant="body1" align={'right'} style={{ paddingRight: '10px' }}>
                  {articlesChanged.filter((article) => article.newCondition).length}
                  {'   '}
                </Typography>
                <SetConditionIcon style={{ fontSize: '20px' }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        {Boolean(find4ArticlesWithLargestDecreasedPrice(articlesChanged).length) && (
          <Box>
            <Typography variant="body1" color={'textSecondary'}>
              {t('publish.largestPriceDecreases')}
            </Typography>
            <Box padding={1}>
              <Box display={'flex'}>
                {find4ArticlesWithLargestDecreasedPrice(articlesChanged).map((article) => (
                  <Box key={article.idArticle}>
                    <Box width={`${884 / 4}px`} height={`${(884 / 4) * 1.39}px`}>
                      <VisualArticle
                        currency={user?.currency}
                        displayOnly
                        rowHeight={(884 / 4) * 1.39}
                        article={article}
                        parentEl="PublishChangesSummary"
                      />
                      <br />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box
              paddingTop={2}
              display={'flex'}
              justifyContent={'left'}
              width="fit-content"
              margin="0 auto"
            >
              <Box>
                <AttentionIcon style={{ fontSize: '50px' }} />
              </Box>
              <Box paddingLeft={3}>
                <Typography
                  variant="body1"
                  color={'initial'}
                  align={'left'}
                  style={{ fontWeight: 600 }}
                >
                  {t('publish.makeSure')}
                </Typography>
                <Typography
                  variant="body1"
                  color={'initial'}
                  align={'left'}
                  style={{ fontWeight: 600 }}
                >
                  {t('publish.makeSure2')}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {articlesChanged.length > 0 ? (
          <Box paddingTop={2} display={'flex'} justifyContent={'space-between'}>
            <Box paddingLeft={2} display={'flex'} alignItems={'center'}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="publish-changes-checkbox"
                    onChange={changePublishAutorization}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={t('publish.reviewDone')}
              />
            </Box>
            <Box display="flex">
              <Button
                onClick={onCancelClick}
                color="primary"
                data-testid="cancel-publish-changes-btn"
                style={{
                  width: '100px',
                  height: '50px',
                  fontSize: '18px',
                  marginRight: '10px',
                }}
              >
                {t('common.close')}
              </Button>
              <Button
                variant="contained"
                onClick={onPublishChanges}
                color="primary"
                tooltip={canPublish ? '' : t('publish.mustCheck')}
                data-testid="confirm-publish-changes-btn"
                disabled={!canPublish}
                style={{
                  width: '150px',
                  height: '50px',
                  fontSize: '20px',
                  border: '1px solid #999999',
                }}
              >
                {t('common.publish')}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Box paddingTop={2} display={'flex'} justifyContent={'space-between'}>
              <Box paddingLeft={2} display={'flex'} alignItems={'center'}>
                <Typography variant="body1" align={'left'}>
                  {t('publish.noRealChanges')}
                </Typography>
              </Box>
              <Box display="flex">
                <Button
                  onClick={onCancelClick}
                  variant="contained"
                  color="primary"
                  data-testid="cancel-publish-changes-btn"
                  style={{
                    width: '100px',
                    height: '50px',
                    fontSize: '18px',
                    marginRight: '10px',
                  }}
                >
                  {t('common.close')}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
