import { Box, Menu, MenuItem } from '@material-ui/core'
import { TextfieldPopper } from 'components/controls/misc'
import {
  ActionButton,
  ActionButtonProps,
  AutopriceIcon,
  Button,
  ClearFailedAutopriceIcon,
  CommentPTIcon,
  DeleteIcon,
  DeselectAllIcon,
  DiscardIcon,
  ExportIcon,
  PublishChangesIcon,
  SelectAllIcon,
  SetConditionIcon,
  SetPriceIcon,
  SetQuantityIcon,
} from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useConfirm } from 'lib/hooks'
import { useStockStore } from 'lib/hooks/useStockStore'
import { getArticleQty } from 'lib/utils'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InventoryArticle, ListingTab, StockTab, UpdatesTab } from 'shared'
import { CONDITIONS } from 'utils/constants'

interface ActionsPresenterProps {
  activeTab: ListingTab | StockTab | UpdatesTab
  selectedStockExists: boolean
  openAutopriceDialog: () => void
  handleBulkEdit: (
    name: 'newPrice' | 'newComment' | 'newQuantity' | 'newCondition' | 'readyToPublish',
    value: string | number | boolean | ((article: InventoryArticle) => number | string | boolean),
    all?: boolean
  ) => void
  revertArticleEdit: (article: InventoryArticle | 'all' | 'selected') => void
  openPublishDialog?: () => void
  unselectAll: () => void
  selectAllResults: () => void
  clearFailedAutoprice: (target: 'all' | 'selected') => void
  handleDeleteArticle: (article: InventoryArticle | 'all' | 'selected') => void
  selectedStockLength: number
  openExportCsvDialog: () => void
  disabledActions?: string[]
}

interface MenuActionButtonProps extends Omit<ActionButtonProps, 'onClick'> {
  options?: { label: string; onClick: () => void; disabled?: boolean; dataTestId?: string }[]
}

const MenuActionButton = ({ options, ...props }: MenuActionButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <>
      <ActionButton onClick={(e) => setAnchorEl(e.currentTarget)} {...props} />

      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          //keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {options &&
            options.map((option) => (
              <MenuItem
                key={option.label}
                data-value={option}
                disabled={option.disabled}
                onClick={() => {
                  option.onClick()
                  setAnchorEl(null)
                }}
                data-testid={option.dataTestId}
              >
                {option.label}
              </MenuItem>
            ))}
        </Menu>
      )}
    </>
  )
}

export const ActionsPresenter = React.memo(function ActionsPresenter({
  activeTab,
  selectedStockExists,
  openAutopriceDialog,
  handleBulkEdit,
  revertArticleEdit,
  openPublishDialog,
  clearFailedAutoprice,
  selectAllResults,
  unselectAll,
  handleDeleteArticle,
  selectedStockLength,
  openExportCsvDialog,
  disabledActions,
}: ActionsPresenterProps): JSX.Element {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [type, setType] = useState<null | 'newPrice' | 'newComment' | 'newQuantity'>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()
  const activeStockType = useStockStore((state) => state.activeStockType)

  const [confirmRevertArticleEdit, ConfirmRevertArticleEditDialog] = useConfirm(
    t('stockPricing.actions.discard'),
    t('stockPricing.actions.discardChangesConfirm', { count: selectedStockLength }),
    () => revertArticleEdit('selected')
  )

  const [confirmDeleteArticle, ConfirmDeleteArticleDialog] = useConfirm(
    t('stockPricing.actions.delete'),
    t('stockPricing.actions.deleteConfirm', { count: selectedStockLength }),
    () => handleDeleteArticle('selected')
  )

  const [confirmClearFailedAutoprice, ConfirmClearFailedAutopriceDialog] = useConfirm(
    t('stockPricing.actions.clearFailedAutopricing'),
    t('stockPricing.actions.clearFailedAutopricingConfirm', { count: selectedStockLength }),
    () => clearFailedAutoprice('selected')
  )

  const [confirmUnmarkForPublish, ConfirmUnmarkForPublishDialog] = useConfirm(
    t('stockPricing.actions.unmarkForPublish'),
    t('stockPricing.actions.unmarkForPublishConfirm', { count: selectedStockLength }),
    () => handleBulkEdit('readyToPublish', false)
  )

  const getDisabledTooltip = () => {
    if (!selectedStockExists) {
      return t('stockPricing.tooltips.mustSelectArticles')
    } else if (user?.subscription.featuresDisabled.includes('autopriceArticles.autoprice')) {
      return t('subscriptionUpgradeNeededNew')
    }
  }

  const handleOpenExportCsvDialog = () => {
    if (!user?.subscription.featuresDisabled.includes('addArticles.exportCsv')) {
      openExportCsvDialog()
    }
  }

  const handleOpenAutopriceDialog = () => {
    if (!user?.subscription.featuresDisabled.includes('autopriceArticles.autoprice')) {
      openAutopriceDialog()
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box>
          <Box marginTop="-3px" width="fit-content">
            <Button
              data-testid="select-all-results-btn"
              startIcon={<SelectAllIcon />}
              color="primary"
              onClick={selectAllResults}
            >
              {t('stockPricing.selectAllResults')}
            </Button>
          </Box>
          <Box marginLeft={0.3} width="fit-content">
            <Button
              onClick={unselectAll}
              size="small"
              color="secondary"
              data-testid="reset-selection-btn"
              startIcon={<DeselectAllIcon />}
            >
              {t('stockPricing.resetSelection')}
            </Button>
          </Box>
        </Box>
      </Box>
      {['edited', 'articles', 'failedAutopricing', 'unedited'].includes(activeTab) && (
        <ActionButton
          data-testid="open-export-csv-dialog-btn"
          text={t('csv.export')}
          onClick={handleOpenExportCsvDialog}
          Icon={ExportIcon}
          color="secondary"
          disabled={user?.subscription.featuresDisabled.includes('addArticles.exportCsv')}
          disabledTooltip={t('subscriptionUpgradeNeededNew')}
        />
      )}
      {['edited', 'articles', 'failedAutopricing'].includes(activeTab) && (
        <ActionButton
          text={t('stockPricing.actions.discard')}
          data-testid="discard-changes-menu-btn"
          Icon={DiscardIcon}
          color="secondary"
          disabled={!selectedStockExists}
          disabledTooltip={t('stockPricing.tooltips.mustSelectArticles')}
          onClick={() => confirmRevertArticleEdit()}
        />
      )}
      {['articles', 'readyToPublish'].includes(activeTab) && (
        <ActionButton
          text={t('stockPricing.actions.delete')}
          data-testid="delete-articles-menu-btn"
          Icon={DeleteIcon}
          color="secondary"
          disabled={!selectedStockExists}
          disabledTooltip={t('stockPricing.tooltips.mustSelectArticles')}
          onClick={() => confirmDeleteArticle()}
        />
      )}

      {['failedAutopricing'].includes(activeTab) && (
        <ActionButton
          text={t('stockPricing.actions.clearFailedAutopricing')}
          Icon={ClearFailedAutopriceIcon}
          color="secondary"
          tooltip={t('stockPricing.tooltips.clearFailedAutopricing')}
          disabled={!selectedStockExists}
          disabledTooltip={t('stockPricing.tooltips.mustSelectArticles')}
          onClick={() => confirmClearFailedAutoprice()}
        />
      )}
      {['unedited', 'edited', 'articles', 'failedAutopricing'].includes(activeTab) && (
        <ActionButton
          text={t('stockPricing.bulkEdit.setComment')}
          onClick={(e) => {
            setType('newComment')
            setAnchorEl(e.currentTarget)
          }}
          Icon={CommentPTIcon}
          data-testid="set-newComment-btn"
          color="secondary"
          disabled={!selectedStockExists}
          disabledTooltip={t('stockPricing.tooltips.mustSelectArticles')}
        />
      )}
      {['unedited', 'edited', 'articles', 'failedAutopricing'].includes(activeTab) &&
        !disabledActions?.includes('setCondition') && (
          <MenuActionButton
            text={t('stockPricing.bulkEdit.setCondition')}
            data-testid="set-condition-btn"
            Icon={SetConditionIcon}
            color="secondary"
            disabled={!selectedStockExists || activeStockType !== 'singles'}
            disabledTooltip={
              activeStockType === 'singles'
                ? t('stockPricing.tooltips.mustSelectArticles')
                : t('stockPricing.tooltips.noConditionForSealed')
            }
            options={CONDITIONS.map((c) => {
              return {
                label: c.name,
                onClick: () => handleBulkEdit('newCondition', c.name, false),
                dataTestId: c.name,
              }
            })}
          />
        )}
      {['unedited', 'edited', 'articles', 'failedAutopricing'].includes(activeTab) && (
        <ActionButton
          text={t('stockPricing.bulkEdit.setPrice')}
          onClick={(e) => {
            setType('newPrice')
            setAnchorEl(e.currentTarget)
          }}
          Icon={SetPriceIcon}
          data-testid="set-newPrice-btn"
          color="secondary"
          disabled={!selectedStockExists}
          disabledTooltip={t('stockPricing.tooltips.mustSelectArticles')}
        />
      )}

      {['unedited', 'edited', 'failedAutopricing'].includes(activeTab) && (
        <ActionButton
          text={t('stockPricing.bulkEdit.setQuantity')}
          onClick={(e) => {
            setType('newQuantity')
            setAnchorEl(e.currentTarget)
          }}
          Icon={SetQuantityIcon}
          data-testid="set-setQuantity-btn"
          color="secondary"
          disabled={!selectedStockExists}
          disabledTooltip={t('stockPricing.tooltips.mustSelectArticles')}
        />
      )}
      {['unedited', 'edited', 'articles', 'failedAutopricing'].includes(activeTab) && (
        <ActionButton
          text={t('autopriceArticles.autoprice')}
          onClick={handleOpenAutopriceDialog}
          data-testid="autoprice-dialog-btn"
          disabled={
            !selectedStockExists ||
            user?.subscription.featuresDisabled.includes('autopriceArticles.autoprice')
          }
          disabledTooltip={getDisabledTooltip()}
          Icon={AutopriceIcon}
          color="secondary"
        />
      )}
      {['edited'].includes(activeTab) && openPublishDialog && (
        <ActionButton
          data-testid="publish-changes-btn"
          text={t('stockPricing.actions.publishChanges')}
          onClick={openPublishDialog}
          Icon={PublishChangesIcon}
          color="secondary"
        />
      )}
      {['readyToPublish'].includes(activeTab) && (
        <ActionButton
          text={t('stockPricing.actions.unmarkForPublish')}
          Icon={ClearFailedAutopriceIcon}
          color="secondary"
          tooltip={t('stockPricing.tooltips.unmarkForPublish')}
          onClick={() => confirmUnmarkForPublish()}
        />
      )}
      {['articles'].includes(activeTab) && openPublishDialog && (
        <ActionButton
          data-testid="publish-articles-btn"
          text={t('stockPricing.actions.publishArticles')}
          disabled={!selectedStockExists}
          disabledTooltip={t('stockPricing.tooltips.mustSelectArticles')}
          onClick={openPublishDialog}
          Icon={PublishChangesIcon}
          color="secondary"
        />
      )}

      {anchorEl && type && (
        <TextfieldPopper
          placeholder={
            {
              newPrice: '0.25, 10 or 90%, 120%...',
              newComment: 'new cmnt, or + add',
              newQuantity: '0, 4 or +2, -1...',
            }[type]
          }
          name={type}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onSubmit={(inputValue) => {
            if (type === 'newPrice') {
              let isPercentage = false
              if (inputValue.includes('%')) {
                inputValue = inputValue.replace('%', '')
                isPercentage = true
              }

              const newValue = Number(inputValue.replace(',', '.'))
              if (newValue >= 0.02 && newValue < 1000000) {
                handleBulkEdit(
                  'newPrice',
                  isPercentage
                    ? (article: InventoryArticle) =>
                        Math.round(Number(article.newPrice || article.price) * newValue) / 100
                    : Number(newValue)
                )
                setAnchorEl(null)
              } else {
                enqueueSnackbar(t('error.setPriceValidation'), { variant: 'error' })
              }
            } else if (type === 'newComment') {
              let input = inputValue.trim()
              if (input[0] === '+') {
                input = input.substring(1)
                handleBulkEdit('newComment', (article) => `${article.comment} ${input}`)
              } else {
                handleBulkEdit('newComment', input)
              }
              setAnchorEl(null)
            } else if (type === 'newQuantity') {
              if (Number.isNaN(Number(inputValue))) return
              let newValue = (_article: InventoryArticle): number => Number(inputValue)
              if (inputValue.includes('-') || inputValue.includes('+')) {
                newValue = (article: InventoryArticle) =>
                  Math.max(getArticleQty(article) + Number(inputValue), 0)
              }
              handleBulkEdit('newQuantity', newValue)
              setAnchorEl(null)
            }
          }}
        />
      )}

      <ConfirmRevertArticleEditDialog />
      <ConfirmDeleteArticleDialog />
      <ConfirmClearFailedAutopriceDialog />
      <ConfirmUnmarkForPublishDialog />
    </>
  )
})
