import { Box, Grid, IconButton, SvgIconTypeMap, Typography } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { ClosableDialog } from 'components/controls/dialogs'
import {
  Button,
  ClickableBox,
  CustomTooltip,
  FirstEdIcon,
  LanguageIcon,
  NMeqEXIcon,
} from 'components/facets'
import { getPlaysetIcon } from 'lib/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkSettings, CardExtraAttribute, Competitor, Game, IdGame, PricingStrategy } from 'shared'
import { CompetitorBox } from '../../CompetitorBox'
import { CreateCompetitorPresenter } from '../CreateCompetitorPresenter'
import { ProgressStepper } from '../ProgressStepper'
import { RestartButton } from '../RestartButton'
import { StepBox } from '../StepBox'
import { CompetitorParameterPresenter } from './CompetitorParameterPresenter'
import { FinalizePresenter } from './FinalizePresenter'
import { ModifierPresenter } from './ModifierPresenter'

export interface CompetitorFlowPresenterProps {
  newName: string
  onRestart: () => void
  bulkSettings: BulkSettings[]
  competitors: Competitor[]
  onDone: (strategy: PricingStrategy) => void
  onCompetitorDone: (competitor: Competitor) => void
  competitorNewName: string
  activeGame: Game
}

enum Step {
  Base = 1,
  Rank,
  Modifier,
  Settings,
  Finalize,
}

interface AttributeSettingProps {
  attribute: keyof Pick<
    PricingStrategy,
    'NMeqEX' | 'disablePlaysetPricing' | 'strictLanguage' | 'strictFirstEd'
  >
  checkBoxText: string
  description: string
  invert?: boolean
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
}

export const CompetitorFlowPresenter = ({
  newName,
  onRestart,
  competitors,
  onCompetitorDone,
  bulkSettings,
  onDone,
  activeGame,
  competitorNewName,
}: CompetitorFlowPresenterProps): JSX.Element => {
  const [step, setStep] = useState<Step>(Step.Base)
  const [progress, setProgress] = useState<Step>(Step.Base)
  const [competitor, setCompetitor] = useState<Competitor>()
  const [strategy, setStrategy] = useState<PricingStrategy>({
    _id: '1',
    name: newName,
    disablePlaysetPricing: false,
    NMeqEX: false,
    base: {
      kind: 'articleData',
      tactic: {
        competitor: '',
        parameter: null, //rank
      },
    },
    modifier: {
      kind: 'number',
      value: 1,
    },
    bulkSettingsId: {
      [IdGame.Magic]: '',
      [IdGame.Yugioh]: '',
      [IdGame.Pokemon]: '',
      [IdGame.Vanguard]: '',
      [IdGame.DBS]: '',
      [IdGame.FF]: '',
      [IdGame.WS]: '',
      [IdGame.FAB]: '',
      [IdGame.Digimon]: '',
    },
  })
  const [createCompetitorDialogOpen, setCreateCompetitorDialogOpen] = useState(false)
  const chosenCompetitor = competitors.find(({ _id }) => _id === strategy.base.tactic.competitor)
  const { t } = useTranslation()

  const [userAttributeChoices, setUserAttributeChoices] = useState<
    Record<AttributeSettingProps['attribute'], null | boolean>
  >({
    NMeqEX: null,
    disablePlaysetPricing: !!activeGame.playsetCount ? null : false,
    strictLanguage: null,
    strictFirstEd: activeGame.extraAttr.includes(CardExtraAttribute.FirstEd) ? null : false,
  })

  const AttributeSetting = ({
    attribute,
    description,
    checkBoxText,
    invert = false,
    Icon,
  }: AttributeSettingProps): JSX.Element => {
    const active =
      userAttributeChoices[attribute] === null
        ? null
        : invert
        ? !userAttributeChoices[attribute]
        : userAttributeChoices[attribute]

    return (
      <Box>
        <Box paddingLeft={2} display={'flex'} alignItems={'center'}>
          <Typography variant="h6" align={'left'}>
            {checkBoxText}
          </Typography>
          <CustomTooltip title={t('iconOfThisSettings') as string}>
            <IconButton
              size="small"
              style={{ marginLeft: '5px', cursor: 'help' }}
              data-testid={`icon-${attribute}`}
            >
              <Icon color={active ? 'primary' : 'inherit'} />
            </IconButton>
          </CustomTooltip>
        </Box>
        <Box paddingLeft={2} paddingRight={7}>
          <Typography variant="body1">{description}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-evenly" padding={1}>
          <Button
            data-testid={`${attribute}-${invert ? 'no' : 'yes'}-button`}
            variant={active === true ? 'contained' : 'outlined'}
            onClick={() => {
              const strategyCopy = { ...strategy, [attribute]: invert ? false : true }
              setStrategy(strategyCopy)
              setUserAttributeChoices((choices) => ({
                ...choices,
                [attribute]: invert ? false : true,
              }))
            }}
          >
            {t(`strategy.create.${attribute}.yes`)}
          </Button>
          <Button
            data-testid={`${attribute}-${invert ? 'yes' : 'no'}-button`}
            variant={active === false ? 'contained' : 'outlined'}
            onClick={() => {
              const strategyCopy = { ...strategy, [attribute]: invert ? true : false }
              setStrategy(strategyCopy)
              setUserAttributeChoices((choices) => ({
                ...choices,
                [attribute]: invert ? true : false,
              }))
            }}
          >
            {t(`strategy.create.${attribute}.no`)}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Grid style={{ height: '100%' }} container>
      <Grid item xs={1}>
        <ProgressStepper
          activeStep={step}
          progress={progress}
          handleStep={(index) => setStep(index)}
          steps={[
            t('competitor.title'),
            `${t('competitor.title')} - ${t('common.settings')}`,
            t('strategy.modifier'),
            t('common.settings'),
            t('strategy.name'),
          ]}
        />
      </Grid>
      <Grid item xs={11}>
        <Box height="100%" padding={3}>
          <Typography variant="h4">
            {t('strategy.create.title')}{' '}
            <RestartButton onClick={onRestart} dataTestid="restart-btn-competition-strategy" />
          </Typography>

          <Box height="inherit" paddingTop={1} data-testid="competition-strategy-box">
            {
              {
                [Step.Base]: (
                  <StepBox>
                    <Typography gutterBottom variant="body1">
                      {t('strategy.create.competitorDescription')}
                    </Typography>
                    <Typography gutterBottom variant="caption">
                      {t('strategy.create.competitorDescription2')}{' '}
                    </Typography>

                    <Grid container>
                      {competitors.map((comp) => (
                        <Grid
                          xs={6}
                          key={comp._id}
                          item
                          data-testid={`competitor-box-${comp.name}`}
                        >
                          <ClickableBox
                            onClick={() => {
                              const strategyCopy = { ...strategy }
                              strategyCopy.base.tactic.competitor = comp._id
                              setCompetitor(comp)
                              setStrategy(strategyCopy)
                              setStep(Step.Base + 1)
                              setProgress(Math.max(step + 1, progress))
                            }}
                          >
                            <CompetitorBox competitor={comp} selected={competitor === comp} />
                          </ClickableBox>
                        </Grid>
                      ))}
                    </Grid>
                  </StepBox>
                ),
                [Step.Rank]: (
                  <CompetitorParameterPresenter
                    strategy={strategy}
                    chosenCompetitor={chosenCompetitor!}
                    onNext={(parameterType, parameterValue) => {
                      setStep(Step.Rank + 1)
                      setProgress(Math.max(step + 1, progress))

                      const strategyCopy = { ...strategy }
                      strategyCopy.base.tactic.parameter = parameterValue
                      strategyCopy.base.tactic.parameterType = parameterType
                      setStrategy(strategyCopy)
                    }}
                  />
                ),
                [Step.Modifier]: (
                  <StepBox
                    onNext={() => {
                      setStep(Step.Modifier + 1)
                      setProgress(Math.max(step + 1, progress))
                    }}
                  >
                    <ModifierPresenter
                      bulkSettings={bulkSettings}
                      strategy={strategy}
                      onChange={(strategy) => setStrategy(strategy)}
                    />
                  </StepBox>
                ),
                [Step.Settings]: (
                  <StepBox
                    disableNext={Object.keys(userAttributeChoices).some(
                      (key) =>
                        userAttributeChoices[key as keyof typeof userAttributeChoices] === null
                    )}
                    onNext={() => {
                      setStep(Step.Settings + 1)
                      setProgress(Math.max(step + 1, progress))
                    }}
                  >
                    <AttributeSetting
                      Icon={NMeqEXIcon}
                      attribute="NMeqEX"
                      description={t('strategy.create.NMeqEXDescription')}
                      checkBoxText={t('strategy.create.NMeqEXTitle')}
                    />
                    {!!activeGame.playsetCount && (
                      <AttributeSetting
                        Icon={getPlaysetIcon(activeGame.playsetCount)}
                        attribute="disablePlaysetPricing"
                        invert
                        description={t('strategy.create.playsetPricingDescription', {
                          playsetCount: activeGame.playsetCount,
                        })}
                        checkBoxText={t('strategy.create.playsetPricingTitle')}
                      />
                    )}
                    {!!activeGame.extraAttr.includes(CardExtraAttribute.FirstEd) && (
                      <AttributeSetting
                        Icon={FirstEdIcon}
                        attribute="strictFirstEd"
                        description={t('strategy.create.strictFirstEdDescription')}
                        checkBoxText={t('strategy.create.strictFirstEdTitle')}
                      />
                    )}
                    <AttributeSetting
                      Icon={LanguageIcon}
                      attribute="strictLanguage"
                      description={t('strategy.create.strictLanguageDescription')}
                      checkBoxText={t('strategy.create.strictLanguageTitle')}
                    />

                    <Box paddingLeft={7} paddingRight={7}>
                      <Typography variant="body1"></Typography>
                    </Box>
                  </StepBox>
                ),
                [Step.Finalize]: (
                  <StepBox
                    isLast
                    onNext={() => onDone(strategy)}
                    disableNext={strategy.name.trim() === ''}
                  >
                    <FinalizePresenter
                      competitors={competitors}
                      strategy={strategy}
                      setStrategy={setStrategy}
                    />
                  </StepBox>
                ),
              }[step]
            }
          </Box>
        </Box>
      </Grid>

      <ClosableDialog
        maxWidth="lg"
        open={createCompetitorDialogOpen}
        onClose={() => setCreateCompetitorDialogOpen(false)}
      >
        <CreateCompetitorPresenter
          onDone={(competitor) => {
            onCompetitorDone(competitor)
            setCreateCompetitorDialogOpen(false)
          }}
          key={String(createCompetitorDialogOpen)}
          onRestart={() => setCreateCompetitorDialogOpen(false)}
          newName={competitorNewName}
        />
      </ClosableDialog>
    </Grid>
  )
}
