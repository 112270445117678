import { Box } from '@material-ui/core'
import { SimpleTable } from 'components/controls/tables'
import {
  ArticleLine,
  BulkIcon,
  CustomTooltip,
  DataFreshnessIcon,
  LocalArticle,
} from 'components/facets'
import { calcPriceDiff } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import { CardmarketArticle, DataFreshness, PricedInventoryArticle, PriceReport } from 'shared'

export const getBase = (data: PriceReport['base']): JSX.Element => {
  if (data?.type === 'competition' && typeof data.value !== 'number') {
    return <ArticleLine article={new CardmarketArticle(data.value)} />
  } else {
    return <>{data?.value}</>
  }
}

export const AutopriceResultTable = ({ data }: { data: PricedInventoryArticle[] }): JSX.Element => {
  const { t } = useTranslation()

  type ResultDataItem = PricedInventoryArticle & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
    diff: number
  }

  const freshnessColor = {
    [DataFreshness.twoHours]: 'lightgreen',
    [DataFreshness.oneDay]: 'green',
    [DataFreshness.twoDays]: 'orange',
  }

  return (
    <SimpleTable<ResultDataItem>
      maxWidth="1200px"
      minWidth="900px"
      options={{ pageSize: 10 }}
      columns={[
        {
          title: t('card.name'),
          defaultSort: 'desc',
          field: 'card.name',
          render: (article) => (
            <Box width="300px">
              <LocalArticle article={article} />
            </Box>
          ),
        },
        {
          title: t('autopriceArticles.base'),
          defaultSort: 'desc',
          field: 'priceReport.base.type',
          render: ({ priceReport }) => {
            return (
              <Box display="flex" alignItems="center" width="600px">
                <Box marginRight={0.5} marginTop={0.5}>
                  <CustomTooltip
                    title={
                      t(`autopriceArticles.freshness.${priceReport.base?.freshness}`) as string
                    }
                  >
                    <DataFreshnessIcon
                      style={{ color: freshnessColor[priceReport.base?.freshness || 1] }}
                    />
                  </CustomTooltip>
                </Box>
                {getBase(priceReport.base)}
              </Box>
            )
          },
        },
        {
          title: t('card.price'),
          field: 'newPrice',
          render: ({ priceReport, newPrice }) => (
            <Box display="flex" alignItems="center">
              {newPrice}{' '}
              {priceReport.base?.usedBulkPrice ? (
                <Box marginLeft={0.5} marginTop={0.3}>
                  <CustomTooltip title={t(`autopriceArticles.bulkPriced`) as string}>
                    <BulkIcon />
                  </CustomTooltip>
                </Box>
              ) : (
                ''
              )}
            </Box>
          ),
        },
        {
          title: t('autopriceArticles.oldPrice'),
          field: 'price',
        },
        {
          title: t('autopriceArticles.percentDiff'),
          field: 'diff',
          render: ({ diff }) => `${diff}%`,
        },
      ]}
      data={
        data.map((item) => ({
          ...item.toPlainObject(),
          diff: calcPriceDiff(item.newPrice!, item.price),
        })) as ResultDataItem[]
      }
      title={t('autopriceArticles.pricedArticles')}
    />
  )
}
