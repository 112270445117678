import { Box, Dialog, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useTranslation } from 'react-i18next'
import { Order } from 'shared'

interface UnlinkOrderDialogProps {
  open: boolean
  order: Order
  onClose: () => void
  onConfirm: () => void
}

export const UnlinkOrderDialog = ({
  open,
  order,
  onClose,
  onConfirm,
}: UnlinkOrderDialogProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Box padding={2}>
        <Box padding={2}>
          <Typography variant="h6">{t('orders.unlinkOrderDialogTitle')}</Typography>
        </Box>
        <Box marginBottom={2} display="flex">
          <Typography variant="body1">
            {t('orders.unlinkOrderDialogContent', { idOrder: order.idOrder })}
          </Typography>
        </Box>
        <Box marginTop={1} style={{ float: 'right' }}>
          <Button variant="outlined" onClick={onClose} style={{ marginRight: '15px' }}>
            {t('common.close')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirm()
            }}
          >
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
