import { Box, MenuItem } from '@material-ui/core'
interface MenuProps<T> {
  dataTestId?: string
  options: {
    value: T
    label: string
    disabled?: boolean
  }[]
  value: T
  onChange: (newValue: T) => void
}

export const Menu = <T extends string | number>({
  dataTestId,
  options,
  value,
  onChange,
}: MenuProps<T>): JSX.Element => (
  <Box data-testid={`menu-${dataTestId}`}>
    {options.map((option, index) => {
      return (
        <MenuItem
          onClick={() => {
            onChange(option.value)
          }}
          key={index}
          disabled={option.disabled}
          value={option.value}
          selected={String(value) === option.value}
        >
          {option.label}
        </MenuItem>
      )
    })}
  </Box>
)
