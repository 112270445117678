import { Box, Dialog, TextField, Typography } from '@material-ui/core'
import { Button } from 'components/facets'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getOrderTrackingNumber, Order } from 'shared'

interface TrackingNumberDialogProps {
  order: Order
  open: boolean
  onClose: () => void
  onConfirm: (trackingNumber: string) => void
}

export const TrackingNumberDialog = ({
  order,
  open,
  onClose,
  onConfirm,
}: TrackingNumberDialogProps): JSX.Element => {
  const { t } = useTranslation()

  const [trackingNumber, setTrackingNumber] = useState<string>(getOrderTrackingNumber(order))

  const handleChangeTrackingNumber = (e: any) => {
    setTrackingNumber(e.target.value)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Box padding={2}>
        <Box padding={2}>
          <Typography variant="body1">
            <TextField
              fullWidth
              label="Insert tracking number"
              value={trackingNumber}
              onChange={handleChangeTrackingNumber}
            />
          </Typography>
        </Box>
        <Box marginTop={1} style={{ float: 'right' }}>
          <Button variant="outlined" onClick={onClose} style={{ marginRight: '15px' }}>
            {t('common.close')}
          </Button>
          <Button variant="contained" onClick={() => onConfirm(trackingNumber)}>
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
