import { Box, CircularProgress } from '@material-ui/core'
import powertoolsLogo from 'img/whiteTcgPowertools.png'

export const LoadingScreen = (): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      color="black"
      height="100vh"
      width="100vw"
      style={{
        background: 'linear-gradient(90deg, rgb(60, 56, 95) 0%, rgb(120, 115, 160) 70%)',
      }}
      justifyContent="center"
    >
      <Box>
        <Box padding={1} width="fit-content" margin="0 auto">
          <Box maxWidth="600px" width="90vw">
            <img width="100%" src={powertoolsLogo} />
          </Box>
        </Box>
        <Box width="fit-content" margin="20px auto">
          <CircularProgress style={{ color: 'white' }} size={100} />
        </Box>
      </Box>
    </Box>
  )
}
