import { Box, BoxProps } from '@material-ui/core'
import { Button } from 'components/facets'
import { useTranslation } from 'react-i18next'

export interface StepBoxProps extends BoxProps {
  onNext?: () => void
  disableNext?: boolean
  isLast?: boolean
}

export const StepBox = ({
  children,
  disableNext = false,
  isLast = false,
  onNext,
  ...props
}: StepBoxProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Box height="90%" width="100%" minWidth="700px" position="relative" {...props}>
      {children}
      {onNext && (
        <Box position="absolute" bottom={-5} right={10}>
          <Button
            disabled={disableNext}
            onClick={onNext}
            variant="contained"
            data-testid="next-btn"
          >
            {t(`common.${isLast ? 'done' : 'next'}`)}
          </Button>
        </Box>
      )}
    </Box>
  )
}
