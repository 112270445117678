import { Box, FormHelperText, Grid, Typography } from '@material-ui/core'
import { AttentionIcon, Button, ClickableBox, HelpText } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useStockStore } from 'lib/hooks/useStockStore'
import { useTranslation } from 'react-i18next'
import { Competitor, IdGame, InventoryArticle, PricingStrategy } from 'shared'
import { SetState } from 'types'
import { PricingStrategyBox } from '../PricingSettings/PricingStrategyBox'

export interface StartAutopricingPresenterProps {
  pricingStrategies: PricingStrategy[]
  competitors: Competitor[]
  chosenStrategyId?: string | null
  setChosenStrategyId: SetState<string>
  articles: InventoryArticle[]
  onStartAutopricing: () => void
  competitionDisabled: boolean
  activeGameId: IdGame
}

export const StartAutopricingPresenter = ({
  pricingStrategies,
  competitors,
  chosenStrategyId,
  setChosenStrategyId,
  articles,
  onStartAutopricing,
  competitionDisabled,
}: StartAutopricingPresenterProps): JSX.Element => {
  const chosenPricingStrategy = pricingStrategies.find(({ _id }) => _id === chosenStrategyId)
  const articlesToPrice = articles
  const { user, activeGame } = useUser()
  const { t } = useTranslation()
  const activeStockType = useStockStore((state) => state.activeStockType)
  const autopriceArticleLimit = user?.isTester?.autopricingLimit || 10000
  let startAutopricingButtonTooltip = ''
  if (chosenPricingStrategy?.base.kind === 'articleData') {
    if (articlesToPrice.length > autopriceArticleLimit)
      startAutopricingButtonTooltip += t('autopriceArticles.outOfLimits', {
        number: autopriceArticleLimit,
      })
  }

  const isStrategyDisabled = (strategy: PricingStrategy): boolean => {
    return activeStockType === 'sealed' && strategy.base.kind !== 'articleData'
  }

  return (
    <Box data-testid="start-autopricing-box">
      {chosenPricingStrategy ? (
        <Box maxWidth="850px">
          <Box width="fit-content" margin="0 auto" padding={3} alignItems={'center'}>
            <Box marginBottom={2}>
              <Typography gutterBottom variant="body1" data-testid="article-to-change-label">
                {articlesToPrice.length} {t('autopriceArticles.willBeAutopriced')}{' '}
                <HelpText
                  tooltip={
                    <PricingStrategyBox
                      pricingStrategy={chosenPricingStrategy}
                      competitors={competitors}
                    />
                  }
                  dataTestid={`strategy-${chosenPricingStrategy.name}`}
                >
                  {chosenPricingStrategy.name}
                </HelpText>
              </Typography>
              {articlesToPrice.length !== articles.length && (
                <>
                  <Typography variant="body1">
                    <b>
                      <HelpText
                        tooltip={<Typography variant="body2">{t('card.isBulkTooltip')}</Typography>}
                      >
                        Bulk
                      </HelpText>
                    </b>
                  </Typography>
                  <FormHelperText>{t('autopriceArticles.bulkHelper')}</FormHelperText>
                </>
              )}
            </Box>
            {chosenPricingStrategy.base.kind === 'priceGuide' && (
              <Box
                paddingTop={2}
                display={'flex'}
                justifyContent={'left'}
                width="fit-content"
                margin="0 auto"
              >
                <Box>
                  <AttentionIcon style={{ fontSize: '60px' }} />
                </Box>
                <Box paddingLeft={3}>
                  <Typography
                    variant="body1"
                    color={'initial'}
                    align={'left'}
                    style={{ fontWeight: 600 }}
                  >
                    {t('autopriceArticles.priceGuideWarning1')}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={'initial'}
                    align={'left'}
                    style={{ fontWeight: 600 }}
                  >
                    {t('autopriceArticles.priceGuideWarning2')}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={'initial'}
                    align={'left'}
                    style={{ fontWeight: 600 }}
                  >
                    {t('autopriceArticles.priceGuideWarning3')}
                  </Typography>
                </Box>
              </Box>
            )}
            {articlesToPrice.length > autopriceArticleLimit && (
              <>
                <Typography
                  variant="body1"
                  color={'initial'}
                  align={'left'}
                  style={{ fontWeight: 600 }}
                  data-testid="warning-test-autopriceLimit-over"
                >
                  {t('autopriceArticles.outOfLimits', { number: autopriceArticleLimit })}
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              style={{ marginRight: '16px' }}
              onClick={() => setChosenStrategyId('')}
              color="primary"
              data-testid="change-strategy-btn"
            >
              {t('autopriceArticles.changeStrategy')}
            </Button>
            <Button
              disabled={
                chosenPricingStrategy.base.kind === 'articleData' &&
                (articlesToPrice.length > autopriceArticleLimit ||
                  !activeGame.competitionPricingEnabled)
              }
              tooltip={startAutopricingButtonTooltip}
              onClick={onStartAutopricing}
              variant="contained"
              data-testid="start-autopricing-btn"
            >
              {t('autopriceArticles.startAutopricing')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box width={'1050px'} padding={3}>
          <Typography variant="body1">
            <b>{t('autopriceArticles.chooseStrategy')}:</b>
          </Typography>
          <Grid container>
            {pricingStrategies
              .filter(
                ({ _id, base }) =>
                  !_id.includes('bulk') && !(competitionDisabled && base.kind === 'articleData')
              )
              .map((pricingStrategy) => (
                <Grid
                  item
                  key={pricingStrategy._id}
                  //className={strategyWrapper}
                  data-testid={`strategy-${pricingStrategy._id}`}
                >
                  <ClickableBox
                    onClick={
                      isStrategyDisabled(pricingStrategy)
                        ? undefined
                        : () => setChosenStrategyId(pricingStrategy._id)
                    }
                    disabled={isStrategyDisabled(pricingStrategy)}
                    width="500px"
                  >
                    <PricingStrategyBox
                      pricingStrategy={pricingStrategy}
                      competitors={competitors}
                    />
                  </ClickableBox>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </Box>
  )
}
