import { Box, StepConnector, StepLabel } from '@material-ui/core'
import Step from '@material-ui/core/Step'
import { StepIconProps } from '@material-ui/core/StepIcon'
import Stepper from '@material-ui/core/Stepper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { CustomTooltip } from 'components/facets'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
    stepper: {
      padding: theme.spacing(3),
      height: '500px',
    },
    completed: {
      display: 'inline-block',
    },
  })
)

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: theme.gradient,
    },
  },
  completed: {
    '& $line': {
      backgroundImage: theme.gradient,
    },
  },
  line: {
    width: 4,
    height: '120%',
    marginLeft: 6,
    marginTop: -3,
    border: 0,
    backgroundColor: '#ccc',
    borderRadius: 1,
  },
}))(StepConnector)

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: theme.gradient,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: theme.gradient,
  },
}))

function ColorlibStepIcon(props: StepIconProps): JSX.Element {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <span style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>{props.icon}</span>
    </div>
  )
}

export interface ProgressStepperProps {
  activeStep: number
  steps: string[]
  progress: number
  handleStep: (index: number) => void
}

export const ProgressStepper = ({
  activeStep,
  steps,
  handleStep,
  progress,
}: ProgressStepperProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div>
      <Box width="fit-content" position="fixed" height="fit-content">
        <Stepper
          connector={<ColorlibConnector />}
          className={classes.stepper}
          orientation="vertical"
          activeStep={activeStep - 1}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <CustomTooltip title={label}>
                <StepLabel
                  style={{ cursor: progress >= index + 1 ? 'pointer' : 'initial' }}
                  onClick={() => progress >= index + 1 && handleStep(index + 1)}
                  StepIconComponent={ColorlibStepIcon}
                  StepIconProps={{ completed: progress > index + 1 }}
                  data-testid={`step-${index}`}
                />
              </CustomTooltip>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  )
}
