import { Box, Button, Grid, GridSize, Typography } from '@material-ui/core'
import { AddIcon } from 'components/facets/Icons'
import { useTranslation } from 'react-i18next'
import { EntityBox } from '../EntityBox/EntityBox'
import { PricingStrategyBox } from '../PricingStrategyBox'
import { PricingStrategiesPresenterProps } from './PricingStrategiesPresenter'

export const AddStrategyBox = ({
  onCreate,
}: {
  onCreate: PricingStrategiesPresenterProps['onCreate']
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box
      onClick={onCreate}
      style={{ cursor: 'pointer' }}
      data-testid="add-new-pricing-strategy-btn"
    >
      <EntityBox
        competitorOrStrategy
        titleRow={<wbr />}
        contentRow={
          <Button color="primary">
            <AddIcon />
            <Box marginLeft={1}>
              <Typography variant="body2">{t('strategy.addNew')}</Typography>
            </Box>
          </Button>
        }
      />
    </Box>
  )
}

export const PricingStrategiesEditor = ({
  pricingStrategies,
  competitors,
  onDelete,
  onCreate,
  onChange,
  onClone,
  competitionDisabled,
  rowCount,
  bulkSettings,
}: PricingStrategiesPresenterProps & { rowCount: 1 | 2 | 3 | 4 }): JSX.Element => {
  return (
    <Grid container>
      {pricingStrategies.map((pricingStrategy) => (
        <Grid
          xs={(12 / rowCount) as GridSize}
          item
          key={pricingStrategy._id}
          data-testid={`strategy-box-${pricingStrategy.name}`}
        >
          <Box>
            <PricingStrategyBox
              competitors={competitors}
              pricingStrategy={pricingStrategy}
              onDelete={
                competitionDisabled && pricingStrategy.base.kind === 'articleData'
                  ? undefined
                  : onDelete
              }
              onChange={
                competitionDisabled && pricingStrategy.base.kind === 'articleData'
                  ? undefined
                  : onChange
              }
              competitionDisabled={competitionDisabled}
              onClone={
                competitionDisabled && pricingStrategy.base.kind === 'articleData'
                  ? undefined
                  : onClone
              }
              bulkSettings={bulkSettings}
            />
          </Box>
        </Grid>
      ))}
      <Grid xs={(12 / rowCount) as GridSize} key={'new'} item>
        <AddStrategyBox onCreate={onCreate} />
      </Grid>
    </Grid>
  )
}
