import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select as MuiSelect,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { CustomTooltip, JumpToIcon } from 'components/facets'
import { useCardThingData, useExpansionData } from 'components/providers/CardDataProvider'
import { theme } from 'config'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardDataItem, Expansion, ExpansionSortOptions, isSingle, LocalizedCard } from 'shared'
import { ArticleAdd } from './ArticleAdd'
import { ArticleEditHandleSave } from './ArticleEdit'
import { ProfiAddArticlesProps } from './ProfiAddArticles'

export const AddSingleArticlesByExp = ({
  onAddArticles,
  disableHotkeys,
  template,
}: ProfiAddArticlesProps): JSX.Element => {
  const expansions = useExpansionData()
  const [expansion, setExpansion] = useState<(Expansion & { name: string }) | null>(null)
  const [counter, setCounter] = useState(0)
  const [expansionSorter, setExpansionSorter] = useState(ExpansionSortOptions.rarityCn)
  const [cardsInExpansion, setCardsInExpansion] = useState<LocalizedCard[]>([])
  const { t } = useTranslation()
  const { cardData: cards } = useCardThingData()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  anchorEl?.ATTRIBUTE_NODE

  // const [editArticleModalOpen, setEditArticleModalOpen] = useState(false)

  const sortCardInExpansion = (cards: LocalizedCard[]): LocalizedCard[] => {
    if (expansionSorter === 'rarityCn')
      cards = cards.sort((a, b) =>
        (a.rarity === b.rarity ? Number(a.cn) > Number(b.cn) : a.rarity < b.rarity) ? 1 : -1
      )

    if (expansionSorter === 'cn') {
      const numbersCn: LocalizedCard[] = []
      const stringsCn: LocalizedCard[] = []
      cards.forEach((c) => {
        if (Number(c.cn)) {
          numbersCn.push(c)
        } else {
          stringsCn.push(c)
        }
      })
      numbersCn.sort((a, b) => (Number(a.cn) > Number(b.cn) ? 1 : -1))
      stringsCn.sort((a, b) => {
        if (a.cn && b.cn) {
          return a.cn.localeCompare(b.cn)
        } else {
          return 0
        }
      })
      cards = numbersCn.concat(stringsCn)
    }

    if (expansionSorter === 'alphabet') cards = cards.sort((a, b) => a.name.localeCompare(b.name))

    if (expansionSorter === 'rarityAlphabet')
      cards = cards.sort((a, b) =>
        (a.rarity === b.rarity ? a.name.localeCompare(b.name) > 0 : a.rarity < b.rarity) ? 1 : -1
      )
    return cards
  }

  useEffect(() => {
    if (!expansion) {
      setCardsInExpansion([])
    } else {
      setCounter(0)
    }
  }, [expansion])

  const handleSave: ArticleEditHandleSave = async (article, isCopied) => {
    if (article === 'previous') {
      if (counter >= 1) setCounter((prevValue) => (prevValue -= 1))
      return
    }
    if (article === 'next') {
      if (counter < cards.length - 1) setCounter((prevValue) => (prevValue += 1))
      return
    }

    onAddArticles([article])

    if (!isCopied) {
      if (counter < cards.length - 1) {
        setCounter((prevValue) => (prevValue += 1))
      } else {
        setCounter(0)
        setExpansion(null)
      }
    }
  }

  const sleep = (ms: number): Promise<void> => {
    return new Promise<void>((resolve, _reject) => {
      setTimeout(() => resolve(), ms)
    })
  }
  const card = cardsInExpansion[counter]

  // useEffect(() => {
  //   if (card) {
  //     setEditArticleModalOpen(true)
  //   } else {
  //     setEditArticleModalOpen(false)
  //   }
  // }, [card])

  if (!cards.length) return <></>

  return (
    <Box>
      <Box style={{ marginTop: '20px' }}>
        <InputLabel style={{ color: theme.palette.primary.main, fontSize: '0.7rem' }}>
          {t('addArticles.sorterSelectLabel')}
        </InputLabel>
        <MuiSelect
          onChange={(e) => {
            setExpansionSorter(String(e.target.value) as ExpansionSortOptions)
          }}
          defaultValue={'default'}
          value={expansionSorter}
          data-testid="add-by-expansion-sorter-select"
          style={{ width: '100%', marginBottom: '10px' }}
        >
          <MenuItem value={ExpansionSortOptions.rarityCn} data-testid="default-option">
            {t('addArticles.sortByRarityAndCn')}
          </MenuItem>
          <MenuItem value={ExpansionSortOptions.cn} data-testid="cn-option">
            {t('addArticles.sortByCn')}
          </MenuItem>
          <MenuItem value={ExpansionSortOptions.alphabet} data-testid="alphabet-option">
            {t('addArticles.sortByRarity')}
          </MenuItem>
          <MenuItem value={ExpansionSortOptions.rarityAlphabet} data-testid="rarityAlphabet-option">
            {t('addArticles.sortByRarityAlphabet')}
          </MenuItem>
        </MuiSelect>
      </Box>
      <Autocomplete<Expansion>
        options={expansions.sort((a, b) => a.name.localeCompare(b.name))}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue)
          const parts = parse(option.name, matches)

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          )
        }}
        value={expansion}
        autoHighlight={true}
        data-testid="add-by-expansion-input"
        getOptionLabel={(option) => `${option.name} ${option.code}`}
        onChange={async (_e, newValue) => {
          await sleep(50)
          setExpansion(newValue)
          const filteredCards = cards.filter(
            (card) => card.set === newValue?.code && isSingle(card)
          )

          setCardsInExpansion(sortCardInExpansion(filteredCards))
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="expansion"
            margin="dense"
            label={t('card.expansion')}
            type="text"
            autoFocus
            fullWidth
          />
        )}
        getOptionSelected={(option, value) => option.name === value.name}
      />

      {card && (
        <>
          <Box>
            <Grid container>
              <Grid item xs={8} style={{ placeItems: 'center', alignSelf: 'center' }}>
                <Typography color="textSecondary" variant="caption" style={{ textAlign: 'center' }}>
                  {t('csv.csvPreviewColumnIdentifiersCn')}: {card.cn}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="caption">
                    {counter + 1}/{cardsInExpansion.length}
                  </Typography>
                  <CustomTooltip title={t('addArticles.jumpTo') as string}>
                    <IconButton
                      data-testid="jump-to-btn"
                      size="small"
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                      <JumpToIcon />
                    </IconButton>
                  </CustomTooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <ArticleAdd
              addByExp={true}
              disableHotkeys={disableHotkeys}
              template={template}
              card={card.toPlainObject()}
              handleSave={handleSave}
              stopEdit={() => {
                setCounter(0)
                setExpansion(null)
              }}
            />
          </Box>
        </>
      )}

      {anchorEl && (
        <ClickAwayListener
          disableReactTree={true}
          onClickAway={() => {
            setAnchorEl(null)
          }}
        >
          <Popper open={!!anchorEl} anchorEl={anchorEl} transition>
            <Box width="200px">
              <Paper>
                <Autocomplete<CardDataItem & { index: number }>
                  options={cardsInExpansion.map((card, index) => ({
                    ...card.toPlainObject(),
                    index,
                  }))}
                  //renderOption={(option) => option.name}
                  value={null}
                  autoHighlight={true}
                  data-testid="add-by-expansion-input"
                  getOptionLabel={(option) => `${option.name} ${option.cn ? option.cn : ''}`}
                  onChange={async (_e, newValue) => {
                    await sleep(50)

                    setCounter(newValue?.index || 0)
                    setAnchorEl(null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      fullWidth
                      name={'jump-to-card-input'}
                      variant="outlined"
                      onFocus={(event) => {
                        event.target.select()
                      }}
                    />
                  )}
                />
              </Paper>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  )
}
