import { Box, Dialog, DialogProps, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useScopelessHotkeys } from 'lib/hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    color: theme.palette.grey[500],
  },
}))

export interface ClosableDialogProps extends DialogProps {
  onClose: () => void
  onEnter?: () => void
  hideButton?: boolean
}

export const ClosableDialog = ({
  onClose,
  onEnter,
  children,
  hideButton,
  ...props
}: ClosableDialogProps): JSX.Element => {
  const classes = useStyles()

  if (onEnter) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScopelessHotkeys(
      'enter',
      (e) => {
        e.preventDefault()
        e.stopPropagation()

        onEnter()
      },
      [onEnter]
    )
  }

  return (
    <Dialog disableEnforceFocus disableBackdropClick {...props} onClose={onClose}>
      {!hideButton && onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          data-testid="close-btn-closableDialog"
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box padding={4} minWidth="400px">
        {children}
      </Box>
    </Dialog>
  )
}
