import { Box, Typography, useTheme } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { CustomTooltip } from 'components/facets/CustomTooltip'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkSettings, Competitor, PricingStrategy } from 'shared'
import { EditPopper } from '../EditPopper'
export interface EditableElementProps {
  value: string | React.ReactNode
  options?: { value: number | string; label: string }[]
  tooltipText: string | React.ReactNode
  name: string
  viewOnly?: boolean
  title?: boolean
  attribute?: keyof Competitor | keyof PricingStrategy | keyof BulkSettings
  onChange: (
    newValue: unknown,
    attributeName?: keyof Competitor | keyof PricingStrategy | keyof BulkSettings
  ) => void
  type?: 'select' | 'multiple-select' | 'number' | 'multiple-textfield' | 'textfield' | 'bool'
  existingValue?: string | boolean | string[] | number
  viewOnlyTooltipText?: string | React.ReactNode
  sameTooltipForViewOnly?: boolean
  dataTestid?: string
  showBorder?: boolean
}

const TooltipWrapper = ({
  children,
  tooltipText,
  name,
  actionText,
  enterDelay,
}: {
  children: React.ReactElement
  tooltipText: React.ReactNode
  name: string
  actionText: string
  enterDelay?: number
}): JSX.Element => (
  <span>
    <CustomTooltip
      enterDelay={enterDelay}
      placement={'top'}
      title={
        <Box padding={1}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {name}
          </Typography>
          <Typography variant="body2">{tooltipText}</Typography>
          {actionText && (
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {actionText}
            </Typography>
          )}
        </Box>
      }
    >
      {children}
    </CustomTooltip>
  </span>
)

export const EditableElement = ({
  value,
  options = [],
  tooltipText,
  viewOnlyTooltipText,
  name,
  onChange,
  type = 'select',
  attribute,
  viewOnly,
  title = false,
  existingValue = '',
  showBorder = false,
  sameTooltipForViewOnly,
  dataTestid,
}: EditableElementProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { palette } = useTheme()
  const { t } = useTranslation()
  let appliedTooltipText = tooltipText
  if (viewOnly && !sameTooltipForViewOnly) appliedTooltipText = viewOnlyTooltipText

  const EditableText = withStyles((theme) => ({
    root: {
      color: viewOnly && !appliedTooltipText ? 'inherit' : theme.palette.primary.main,
      display: 'inline',
      cursor: viewOnly ? 'help' : 'pointer',
      textTransform: 'uppercase',
      fontSize: 'inherit',
      transition: '0.1s all ease',
      '&:hover': { fontWeight: 'bold' },
      '&::before': {
        display: 'block',
        content: 'attr(title)',
        fontWeight: 'bold',
        height: '0',
        overflow: 'hidden',
        visibility: 'hidden',
      },
      marginRight: title ? '8px' : 'initial',
    },
  }))(Typography) as typeof Typography

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (viewOnly) return
    if (type === 'bool') onChange(!existingValue, attribute)
    else setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const editableStyles = {
    padding: '3px',
    margin: '2px',
    border: `1.5px dashed ${palette.lightBlue}`,
  }

  return (
    <>
      <span
        style={{
          pointerEvents: appliedTooltipText ? 'initial' : 'none',
          display: 'inline-block',
          ...(!viewOnly && showBorder ? editableStyles : {}),
        }}
        onClick={handleClick}
        data-testid={`${dataTestid}`}
      >
        <TooltipWrapper
          actionText={viewOnly ? '' : t('common.clickToChange')}
          tooltipText={appliedTooltipText}
          name={name}
          enterDelay={250}
        >
          {typeof value === 'string' ? (
            <EditableText
              variant={title ? 'body1' : 'body2'}
              variantMapping={{ body1: 'span', body2: 'span' }}
            >
              {value}
            </EditableText>
          ) : (
            <div>{value}</div>
          )}
        </TooltipWrapper>
      </span>
      {anchorEl && (
        <EditPopper
          onChange={(val) => onChange(val, attribute)}
          options={options}
          type={type}
          existingValue={existingValue}
          anchorEl={anchorEl}
          onDone={() => {
            setAnchorEl(null)
          }}
          dataTestId={`${dataTestid}-input`}
        />
      )}
    </>
  )
}
