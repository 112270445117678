import { Box, makeStyles, Typography } from '@material-ui/core'
import { ArrowRightAlt } from '@material-ui/icons'
import CommentIcon from '@material-ui/icons/Comment'
import { useEditArticleContext } from 'components/domain/addArticles/useEditArticleContext'
import { Condition, CustomTooltip, DeleteIcon, DiscardIcon, EditIcon } from 'components/facets'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import playsetIcon from 'img/playsetIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { currencySymbol } from 'lib/utils'
import * as React from 'react'
import Flag from 'react-world-flags'
import {
  AppCurrency,
  CardExtraAttribute,
  CardLanguage,
  InventoryArticle,
  isSingle,
  PricedInventoryArticle,
} from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { PriceReportPresenter } from './PriceReportPresenter'

export interface VisualArticleProps {
  openPriceSuggestDrawer?: (article: InventoryArticle) => void
  selectArticle?: (article: InventoryArticle, shiftSelect?: boolean) => void
  rowHeight: number
  revertArticleEdit?: (article: InventoryArticle) => void
  article: InventoryArticle | PricedInventoryArticle
  displayOnly?: boolean
  cardWidth?: number
  onDeleteArticle?: (article: InventoryArticle) => void
  currency?: AppCurrency
  parentEl?: string
}

const maxIconSize = {
  containerFontSize: '1.35rem',
  containerPadding: '20px 0px',
  flag: '20',
  imgHeight: '30px',
  iconHeight: '1.70rem',
  margin: '5px',
  revertChangeBottom: '45px',
  iconBarWidth: '15%',
}

const setIconSize = (cardWidth: number, largerIconBar?: boolean): Record<string, string> => {
  switch (true) {
    case cardWidth > 300:
      return maxIconSize
    case cardWidth < 200:
      return {
        containerFontSize: '0.8rem',
        containerPadding: '3px',
        flag: '10',
        imgHeight: '15px',
        iconHeight: '1rem',
        margin: '3px',
        revertChangeBottom: '10px',
        iconBarWidth: largerIconBar ? '45%' : '10%',
      }
    default:
      return {
        containerFontSize: '1.1rem',
        containerPadding: '20px 0px',
        flag: '12',
        imgHeight: '18px',
        iconHeight: '1.35rem',
        margin: '0',
        revertChangeBottom: '45px',
        iconBarWidth: largerIconBar ? '45%' : '10%',
      }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '95%',
    margin: '2.5% 4%',
    cursor: 'pointer',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    border: '1px solid black',
    borderRadius: '3px',
    position: 'relative',
    color: 'white',
    textAlign: 'center',
    fontSize: '1.1rem',
    backgroundColor: 'black',
    boxShadow: '0px 0px 8px -1px rgba(0,0,0,1)',
  },
  selected: {
    backgroundColor: theme.palette.active,
    transform: 'scale(1.03)',
    border: `5px solid ${theme.palette.active}`,
  },

  priceTag: {
    background: theme.gradient,
    position: 'absolute',
    bottom: '-1px',
    left: '-1px',
    height: 'auto',
    width: '72% !important',
    borderRadius: '7px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 6px 6px -3px, rgba(0, 0, 0, 0.14) 0px 10px 14px 1px, rgba(0, 0, 0, 0.12) 0px 4px 18px 3px;',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: '0px 4px 8px -2px rgba(0,0,0,0.5)',
      background: theme.gradientLighter,
    },
  },
  with50percent: {
    width: '50% !important',
  },
  edited: {
    color: 'yellow',
    width: '100%',
  },
}))

const setIconBarHeight = (
  selected: boolean | undefined,
  cardWidth: number,
  rowHeight: number
): string => {
  if (selected && cardWidth > 200) {
    return (rowHeight - 15) * 0.95 + 'px'
  }

  if (!selected && cardWidth > 200) {
    return (rowHeight - 3) * 0.95 + 'px'
  }

  if (!selected && cardWidth < 200) {
    return rowHeight * 0.95 + 'px'
  }
  if (selected && cardWidth < 200) {
    return (rowHeight - 5) * 0.95 + 'px'
  }
  return rowHeight * 0.94 + 'px'
}

const showPrice = (
  price: InventoryArticle['price'] | undefined | null,
  currency: AppCurrency = 'EUR'
): string => {
  if (price !== undefined && price !== null && price - Math.floor(price) !== 0) {
    return String((Math.round(price * 100) / 100).toFixed(2)) + currencySymbol(currency)
  }
  return price ? `${price}${currencySymbol(currency)}` : '???'
}

const calcPriceDiff = (
  price: InventoryArticle['price'],
  newPrice: InventoryArticle['newPrice']
): number => {
  if (!newPrice) return 0
  return Math.floor((newPrice / price - 1) * 10000) / 100
}

const editedPriceTag = (
  price: number,
  newPrice: number,
  cardWidth: number,
  currency: AppCurrency
): JSX.Element => {
  const priceDiffPercentage = calcPriceDiff(price, newPrice)

  const renderPercentage = (): JSX.Element => {
    let green = 0,
      red = 0
    let priceDiffValue: number | string = Math.min(Math.abs(priceDiffPercentage), 30)
    if (priceDiffPercentage > 0) {
      green = 75 + priceDiffValue * 6
      priceDiffValue = `+${priceDiffPercentage}`
    } else {
      red = 75 + priceDiffValue * 6
      priceDiffValue = `${priceDiffPercentage}`
    }

    return (
      <span
        style={{
          color: `rgb(${255 - green}, ${255 - red}, ${255 - green - red})`,
        }}
      >
        {priceDiffValue}%
      </span>
    )
  }

  return (
    <>
      <span>{`${showPrice(newPrice, currency)}`}</span>

      <br />
      <Typography
        style={{
          color: 'lightgray',
          marginLeft: '5px',
          fontSize: '12px',
          fontFamily: 'ubuntu',
        }}
        variant="body1"
      >
        <span
          style={{
            color: '#ffffff',
            fontSize: cardWidth < 170 ? '9px' : '12px',
            textDecoration: 'line-through',
          }}
        >{` (${price}${currencySymbol(currency)})`}</span>
        &nbsp;
        {renderPercentage()}
      </Typography>
    </>
  )
}

export const VisualArticle = React.memo(function VisualArticle({
  openPriceSuggestDrawer,
  selectArticle,
  rowHeight,
  revertArticleEdit,
  article,
  displayOnly = false,
  cardWidth,
  onDeleteArticle,
  currency = 'EUR',
  parentEl = '',
}: VisualArticleProps): JSX.Element {
  const classes = useStyles()
  const {
    quantity,
    isFoil,
    isPlayset,
    isReverseHolo,
    isSigned,
    isFirstEd,
    condition,
    idLanguage,
    card,
    price,
    comment,
    newPrice,
    newComment,
    newCondition,
    selected,
    newQuantity,
    hasNewComment,
    currentComment,
  } = article

  const { startEditingArticle } = useEditArticleContext()

  const largerIconBar = article.hasEditedAttribute() || article.newQuantity !== undefined

  const imgsrc = `https://mtgpowertools.s3.eu-central-1.amazonaws.com/images/mtg/${card._id}.jpg`
  const cssStyle =
    parentEl === 'ArticleEdit' ? maxIconSize : setIconSize(cardWidth || 0, largerIconBar)

  const handlePricingClick = async (
    e: React.MouseEvent,
    article: InventoryArticle
  ): Promise<void> => {
    e.stopPropagation()
    openPriceSuggestDrawer && openPriceSuggestDrawer(article)
  }

  const handleRevertClick = (e: React.MouseEvent, article: InventoryArticle): void => {
    e.stopPropagation()
    revertArticleEdit && revertArticleEdit(article)
  }

  const handleDeleteClick = (e: React.MouseEvent, article: InventoryArticle): void => {
    e.stopPropagation()
    onDeleteArticle && onDeleteArticle(article)
  }

  const priceTag = (
    <Box
      data-testid="article-price-tag"
      style={{
        fontSize: '1.35rem',
        display: newPrice ? 'block' : 'flex',
        alignItems: 'center',
        height: '50px',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
      }}
    >
      {newPrice ? (
        editedPriceTag(price, newPrice, cardWidth || 0, currency)
      ) : (
        <span>{showPrice(price, currency)}</span>
      )}
    </Box>
  )

  // blink function
  // const blink = async (el: HTMLElement | null) => {
  //   const blinkSpeed = 100
  //   if (el) {
  //     el.style.visibility = 'hidden'
  //     await sleep(blinkSpeed)
  //     el.style.visibility = ''
  //     await sleep(blinkSpeed)
  //     el.style.visibility = 'hidden'
  //     await sleep(blinkSpeed)
  //     el.style.visibility = ''
  //   }
  // }

  // keep track of first rendering: in this case we don't have to
  // activate blinking of the attributes
  // const firstLayoutRef = {
  //   quantity: useRef(true),
  //   isFoil: useRef(true),
  //   condition: useRef(true),
  //   flag: useRef(true),
  //   playset: useRef(true),
  //   signed: useRef(true),
  // }

  // const getElForBlinking = (id: string) => {
  //   if (!firstLayoutRef[id as keyof typeof firstLayoutRef].current) {
  //     const el = document.getElementById(`ArticleEdit-${id}`)
  //     blink(el)
  //   } else {
  //     firstLayoutRef[id as keyof typeof firstLayoutRef].current = false
  //   }
  // }

  // React.useEffect(() => {
  //   getElForBlinking('isFoil')
  // }, [isFoil])

  // React.useEffect(() => {
  //   getElForBlinking('quantity')
  // }, [quantity])

  // React.useEffect(() => {
  //   getElForBlinking('condition')
  // }, [condition])

  // React.useEffect(() => {
  //   getElForBlinking('flag')
  // }, [idLanguage])

  // React.useEffect(() => {
  //   getElForBlinking('playset')
  // }, [isPlayset])

  // React.useEffect(() => {
  //   getElForBlinking('signed')
  // }, [isSigned])

  return (
    <>
      <Box
        data-testid={`article-${article.idArticle}`}
        onClick={(e) => {
          if (displayOnly) return
          if (e.shiftKey) document?.getSelection()?.removeAllRanges()
          selectArticle && selectArticle(article, e.shiftKey)
        }}
        className={`${classes.root} ${selected ? classes.selected : ''}`}
        data-selected={selected ? true : undefined}
        style={{
          backgroundImage: `url(${imgsrc})`,
        }}
      >
        <Box
          boxShadow={4}
          style={{
            backgroundColor: '#00000099',
            position: 'absolute',
            // height: `${selected ? (rowHeight - 15) * 0.95 : (rowHeight - 3) * 0.95}px`,
            height: setIconBarHeight(selected, cardWidth || 0, rowHeight),
            bottom: parentEl === 'ArticleEdit' ? '-1px' : '0',
            right: parentEl === 'ArticleEdit' ? '-42px' : '0',
            width: `${cssStyle.iconBarWidth}`,
            minWidth: '45px',
            borderRadius: '5%',
          }}
        >
          <Box
            padding={cssStyle.containerPadding}
            style={{
              fontSize: `${cssStyle.containerFontSize}`,
            }}
          >
            <Box
              style={{
                marginBottom: `${cssStyle.margin}`,
                fontSize: '13px',
                textTransform: 'uppercase',
              }}
            >
              {article.card.set}
            </Box>
            {article.isColdFoil && <Box marginLeft={'4px'}>&#10052;&#65039;</Box>}
            {article.isRainbowFoil && <Box marginLeft={'4px'}>&#127752;</Box>}
            <Box
              id={`${parentEl}-quantity`}
              style={{
                marginBottom: `${cssStyle.margin}`,
              }}
              data-testid="visual-article-quantity"
            >
              {typeof newQuantity === 'number' ? (
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'lightgray',
                    }}
                  >
                    <Box style={{ fontSize: '70%' }}>{quantity}x</Box>
                    <ArrowRightAlt />
                    <Box style={{ color: newQuantity === 0 ? 'red' : 'yellow' }}>
                      {newQuantity}x
                    </Box>
                  </Box>
                </Box>
              ) : (
                `${quantity}x`
              )}
            </Box>
            {newCondition ? (
              <Box>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'lightgray',
                  }}
                >
                  <Box style={{ fontSize: '70%' }}>
                    <Condition id={`${parentEl}-condition`} value={condition} />
                  </Box>
                  <ArrowRightAlt />
                  <Condition id={`${parentEl}-newCondition`} value={newCondition} />
                </Box>
              </Box>
            ) : (
              <Box data-testid="visual-article-condition">
                {isSingle(card) && <Condition id={`${parentEl}-condition`} value={condition} />}
              </Box>
            )}
            <Box>
              <Flag
                id={`${parentEl}-flag`}
                data-testid={`${CardLanguage[idLanguage]}-flag`}
                style={{
                  padding: '0px 5px',
                  marginBottom: `${cssStyle.margin}`,
                  marginTop: `${cssStyle.margin}`,
                }}
                //@ts-ignore
                fallback={<span>Unknown</span>}
                height={`${cssStyle.flag}`}
                code={LANGUAGE_MAP_ICONS[idLanguage] || 'gg'}
              />
            </Box>
            {isFoil && (
              <Box
                id="ArticleEdit-isFoil"
                data-testid={`${CardExtraAttribute.Foil}`}
                style={{
                  marginBottom: `${cssStyle.margin}`,
                }}
              >
                <img alt="foil" height={`${cssStyle.imgHeight}`} src={foilIcon} />
              </Box>
            )}
            {isReverseHolo && (
              <Box
                data-testid={`${CardExtraAttribute.ReverseHolo}`}
                style={{
                  marginBottom: `${cssStyle.margin}`,
                }}
              >
                <img alt="foil" height={`${cssStyle.imgHeight}`} src={reverseHoloIcon} />
              </Box>
            )}
            {isPlayset && (
              <Box
                id={`${parentEl}-playset`}
                data-testid={`${CardExtraAttribute.Playset}`}
                style={{
                  marginBottom: `${cssStyle.margin}`,
                }}
              >
                <img alt="playset" height={`${cssStyle.imgHeight}`} src={playsetIcon} />
              </Box>
            )}
            {isFirstEd && (
              <Box
                data-testid={`${CardExtraAttribute.FirstEd}`}
                style={{
                  marginBottom: `${cssStyle.margin}`,
                }}
              >
                <img alt="first edition" height={`${cssStyle.imgHeight}`} src={firstEdIcon} />
              </Box>
            )}
            {isSigned && (
              <Box
                id={`${parentEl}-signed`}
                data-testid={`${CardExtraAttribute.Signed}`}
                style={{
                  marginBottom: `${cssStyle.margin}`,
                }}
              >
                <img alt="signed" height={`${cssStyle.imgHeight}`} src={signedIcon} />
              </Box>
            )}
            {(comment || hasNewComment) && (
              <Box>
                <CustomTooltip title={<Typography variant="body1">{currentComment}</Typography>}>
                  <CommentIcon
                    data-testid={`${currentComment}-icon`}
                    className={hasNewComment ? classes.edited : ''}
                    style={{
                      fontSize: `${cssStyle.iconHeight}`,
                      marginBottom: `${cssStyle.margin}`,
                    }}
                  />
                </CustomTooltip>
              </Box>
            )}

            <Box
              position="absolute"
              sx={{
                bottom: `${cssStyle.revertChangeBottom}`,
                left: '0px',
                width: '100%',
                marginTop: `${cssStyle.margin}`,
                //@ts-ignore
                '& button': { color: 'white', padding: '0' },
              }}
            >
              {(newPrice || newComment || newCondition || typeof newQuantity === 'number') &&
                !displayOnly &&
                revertArticleEdit !== undefined && (
                  <DiscardIcon
                    data-testid="discard-article-changes-btn"
                    onClick={(e) => handleRevertClick(e, article)}
                    style={{
                      fontSize: `${cssStyle.iconHeight}`,
                    }}
                  />
                )}
              {!displayOnly && startEditingArticle && (
                <EditIcon
                  data-testid="edit-article-changes-btn"
                  onClick={(e) => {
                    e.stopPropagation()

                    startEditingArticle(article, false)
                    //if (success) handleDeleteClick(e, article)
                  }}
                  style={{
                    fontSize: `${cssStyle.iconHeight}`,
                  }}
                />
              )}
              {!displayOnly && onDeleteArticle && (
                <DeleteIcon
                  data-testid="delete-article-changes-btn"
                  onClick={(e) => handleDeleteClick(e, article)}
                  style={{
                    fontSize: `${cssStyle.iconHeight}`,
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        {parentEl !== 'ptInventory' && (
          <Box
            style={{
              //pointerEvents: displayOnly ? 'none' : 'initial',
              cursor: displayOnly ? 'initial' : 'cursor',
            }}
            className={`${classes.priceTag} ${newPrice ? classes.edited : ''}  ${
              cardWidth !== null && cardWidth !== undefined && cardWidth > 300
                ? classes.with50percent
                : ''
            }`}
            onClick={(e) => {
              if (!displayOnly) handlePricingClick(e, article)
            }}
          >
            <Box>
              {article instanceof PricedInventoryArticle ? (
                <CustomTooltip title={<PriceReportPresenter priceReport={article.priceReport} />}>
                  <Box>{priceTag}</Box>
                </CustomTooltip>
              ) : (
                priceTag
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
})
