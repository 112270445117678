import { Box, Paper, Typography, useTheme } from '@material-ui/core'
import { AddIcon } from 'components/facets'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SortableTree, { TreeItem } from 'react-sortable-tree'
//@ts-ignore
import FileExplorerTheme from 'react-sortable-tree-theme-minimal'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import { DefaultPricingPlanNode, PricingPlan as IPricingPlan } from 'shared'
import { SetState } from 'types'
import { v4 as uuid } from 'uuid'
import { DraggableItemsEnum, PricingPlanContext } from './PricingPlanPresenter'
import { AffectedStockPresenter } from './SortableTree/AffectedStockPresenter'
import { MinimalThemeNodeContentRenderer } from './SortableTree/NodeContentRenderer'
import { StrategyChooserPresenter } from './SortableTree/StrategyChooserPresenter'
import { MinimalThemeTreeNodeRenderer } from './SortableTree/TreeNodeRenderer'

export interface NodePresenterProps {
  pricingPlan: IPricingPlan
  tree: TreeItem[]
  changeTree: SetState<TreeItem[]>
  addRowMode?: boolean
  setAddRowMode?: SetState<boolean>
  hiddenAddButton?: boolean
  addRuleMode?: boolean
}

const DefaultNode = ({ node }: { node: DefaultPricingPlanNode }): JSX.Element => {
  const { palette } = useTheme()

  const { changeDefaultNode, highlightedNodeId, stockByNode, totalStockCount } =
    useContext(PricingPlanContext)
  const stockInThisNode = stockByNode['default'] || []
  const { t } = useTranslation()

  return (
    <Box style={{ cursor: 'initial' }} marginBottom={1} data-testid="default-node">
      <Box>
        <Paper
          elevation={highlightedNodeId === 'default' ? 5 : 1}
          style={{
            border: highlightedNodeId === node.id ? `2px solid ${palette.primary.main}` : 'none',
          }}
        >
          <Box padding={1.5} display="flex" alignItems="center">
            <Box>
              <Typography variant="h5" color="primary" style={{ textTransform: 'uppercase' }}>
                {t('priceUpdates.defaultForAllArticles')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" textAlign="center">
              <AffectedStockPresenter
                affectedStock={stockInThisNode}
                totalStockCount={totalStockCount}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <StrategyChooserPresenter
                nodeMethod={node.method}
                changeNodeMethod={(newMethod) => changeDefaultNode({ ...node, method: newMethod })}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export const NodePresenter = ({
  pricingPlan,
  tree,
  changeTree,
  addRowMode,
  setAddRowMode,
  hiddenAddButton,
  addRuleMode,
}: NodePresenterProps): JSX.Element => {
  const [rerenderKey, setRerenderKey] = useState(uuid())
  const { t } = useTranslation()

  useEffect(() => {
    setRerenderKey(uuid())
  }, [changeTree])

  const hideAddRowButton = () => {
    if (!pricingPlan?.disallowNesting) {
      return true
    } else {
      if (hiddenAddButton) {
        return true
      }
      if (addRowMode || addRuleMode) {
        return true
      }
    }
    return false
  }

  return (
    <Box>
      <DefaultNode node={pricingPlan.defaultNode} />
      <Box height="inherit">
        <SortableTree
          maxDepth={pricingPlan.disallowNesting ? 1 : 10}
          isVirtualized={false}
          key={rerenderKey}
          dndType={DraggableItemsEnum.Node}
          theme={{
            ...FileExplorerTheme,
            nodeContentRenderer: MinimalThemeNodeContentRenderer,
            treeNodeRenderer: MinimalThemeTreeNodeRenderer,
          }}
          getNodeKey={({ node }) => {
            return node.id
          }}
          treeData={tree}
          onChange={(treeData) => {
            changeTree(treeData as IPricingPlan['nodes'])
            setRerenderKey(uuid())
          }}
        />
      </Box>
      <Box hidden={hideAddRowButton()}>
        <Paper
          onClick={() => {
            setAddRowMode?.(!addRowMode)
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box padding={1.5}>
            <Box display="flex">
              <Box marginRight={3} marginTop="auto">
                <AddIcon color="primary" />
              </Box>
              <Typography variant="h5" color="primary" style={{ textTransform: 'uppercase' }}>
                {!addRowMode ? t('priceUpdates.addNewRow') : t('priceUpdates.cancelAddNewRow')}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
