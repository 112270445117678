import { Box, Grid, Typography } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { AppLayout, Button } from 'components/facets'
import { YoutubeEmbed } from 'components/facets/YoutubeEmbed'
import { useState } from 'react'
import { VIDEO_TUTORIALS } from 'shared'
import { DashboardPresenter } from '../domain/dashboard'

export const Dashboard = (): JSX.Element => {
  const [showTutorials, setShowTutorials] = useState<boolean>(false)

  return (
    <AppLayout>
      <Grid container spacing={1}>
        <Grid item lg={10}>
          <DashboardPresenter />
        </Grid>
        <Grid item lg={2}>
          <Box width={250}>
            <Button fullWidth variant="contained" onClick={() => setShowTutorials(!showTutorials)}>
              <Typography>video tutorials</Typography>
              <span style={{ width: 30 }} />
              {showTutorials ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          </Box>
          {showTutorials && (
            <Box width="250px" marginTop={1} maxHeight={500} overflow="auto">
              {VIDEO_TUTORIALS.map((video) => (
                <YoutubeEmbed key={video.title} title={video.title} videoId={video.videoId} />
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </AppLayout>
  )
}
