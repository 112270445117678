import { Box } from '@material-ui/core'
import { StatusPresenter } from 'components/domain/dashboard/StatusPresenter'

export const Status = (): JSX.Element => {
  return (
    <Box padding={4}>
      <StatusPresenter />
    </Box>
  )
}
