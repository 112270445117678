import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { CustomTooltip } from './CustomTooltip'

export interface ButtonProps extends MuiButtonProps {
  hotkey?: string
  bgColor?: string
  tooltip?: string | React.ReactElement
}

export const Button = ({ variant, bgColor, tooltip, ...props }: ButtonProps): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    contained: {
      color: 'white',
      '&:hover': {
        boxShadow: '0px 4px 8px -2px rgba(0,0,0,0.5)',
        background: theme.palette.primary,
      },
      my: 4,
      borderRadius: '3rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
      boxShadow: '4px 4px 5px 2px rgb(0 0 0 / 30%)',
      background: theme.gradientNew,
    },
    outlined: {
      color: bgColor || theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '3rem',
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
      },
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    text: {
      textTransform: 'none',
    },
  }))
  const classes = useStyles()
  const buttonComponent = (
    <MuiButton className={`${!props.disabled ? classes[variant || 'text'] : ''}`} {...props}>
      {props.children}
    </MuiButton>
  )

  if (tooltip)
    return (
      <CustomTooltip title={tooltip}>
        <div>{buttonComponent}</div>
      </CustomTooltip>
    )

  if (props.hotkey)
    return (
      <CustomTooltip title={<Typography>{props.hotkey}</Typography>}>
        <span>{buttonComponent}</span>
      </CustomTooltip>
    )

  return buttonComponent
}
