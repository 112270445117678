import { Box, Typography } from '@material-ui/core'
import {
  BulkIcon,
  CompetitionStrategyIcon,
  FirstEdIcon,
  LanguageIcon,
  MinMaxStrategyIcon,
  NMeqEXIcon,
  PriceGuideStrategyIcon,
} from 'components/facets/Icons'
import { useUser } from 'components/providers/UserProvider'
import { getPlaysetIcon } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import { BulkSettings, CardExtraAttribute, Competitor, PricingStrategy } from 'shared'
import { BooleanIconButton } from '../BooleanIconButton'
import { BulkpriceTooltipTable } from '../BulkpriceTooltipTable'
import { CompetitorBox } from '../CompetitorBox'
import { EditableElement } from '../EditableElement'
import { EntityBox } from '../EntityBox/EntityBox'

export interface PricingStrategyBoxProps {
  pricingStrategy: PricingStrategy
  competitors?: Array<Competitor>
  onDelete?: (id: string) => void
  onChange?: (newValue: PricingStrategy) => void
  onClone?: (newValue: PricingStrategy) => void
  hideIcon?: boolean
  competitionDisabled?: boolean
  bulkSettings?: Array<BulkSettings>
  pricingStrategy1?: PricingStrategy
  pricingStrategy2?: PricingStrategy
}

export const PricingStrategyBox = ({
  pricingStrategy,
  competitors,
  hideIcon = true,
  onDelete,
  onChange,
  onClone,
  competitionDisabled = false,
  bulkSettings,
  pricingStrategy1,
  pricingStrategy2,
}: PricingStrategyBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const viewOnly = !onChange || !onDelete
  const { user, activeGame } = useUser()

  const handleChange = (
    newValue: unknown,
    attributeName?: keyof Competitor | keyof PricingStrategy | keyof BulkSettings
  ): void => {
    if (!attributeName) return
    const lastCharterDifferentOfSpace = /\S/.test(String(newValue))
    if (attributeName == 'name' && !lastCharterDifferentOfSpace) return
    onChange && onChange({ ...pricingStrategy, [attributeName]: newValue })
  }

  const isDisabled = pricingStrategy.base.kind === 'articleData' && competitionDisabled

  const competitor = competitors?.find(({ _id }) => _id === pricingStrategy.base.tactic.competitor)
  const currentBulkSettingId =
    pricingStrategy.bulkSettingsId && pricingStrategy.bulkSettingsId[activeGame.idGame]
      ? pricingStrategy.bulkSettingsId[activeGame.idGame]
      : ''
  const currentBulkSetting = bulkSettings
    ? bulkSettings.find((settings) => settings._id === currentBulkSettingId)
    : null

  const getBulkSettingOptions = () => {
    const baseBulkSettingOptions = [
      {
        label: 'none',
        value: '',
      },
    ]
    const bulkSettingOptions =
      bulkSettings?.map((bulkSetting) => ({
        label: bulkSetting.name,
        value: bulkSetting._id,
      })) || []
    return baseBulkSettingOptions.concat(bulkSettingOptions)
  }

  const getContentStandardStrategy = () => {
    return (
      <>
        <EditableElement
          attribute="modifier"
          viewOnly={viewOnly}
          showBorder
          existingValue={String(pricingStrategy.modifier.value)}
          onChange={(newModifierValue) => {
            if (typeof newModifierValue !== 'number') return
            let modifierValue = newModifierValue

            const pricingStrategyCopy = { ...pricingStrategy }
            if (pricingStrategy.modifier.kind === 'percent') {
              modifierValue = Math.max(modifierValue, 0)
              modifierValue = Math.min(modifierValue, 500)
              pricingStrategyCopy.modifier.value = modifierValue
            } else if (pricingStrategy.modifier.kind === 'number') {
              modifierValue = Math.min(modifierValue, 1000)
              pricingStrategyCopy.modifier.value =
                pricingStrategyCopy.modifier.value < 0 ? -modifierValue : modifierValue
            }
            onChange && onChange(pricingStrategyCopy)
          }}
          name={t('strategy.modifierValue')}
          type="number"
          tooltipText={t('strategy.tooltip.modifierValue')}
          value={`${Math.abs(pricingStrategy.modifier.value)}`}
          dataTestid={`strategy-value-${pricingStrategy.name}`}
        />
        <EditableElement
          attribute="modifier"
          viewOnly={viewOnly}
          showBorder
          key={pricingStrategy.modifier.kind}
          onChange={() => {
            const pricingStrategyCopy = { ...pricingStrategy }
            if (pricingStrategyCopy.modifier.kind === 'number') {
              pricingStrategyCopy.modifier.kind = 'percent'
              pricingStrategyCopy.modifier.value = Math.abs(pricingStrategyCopy.modifier.value)
            } else if (pricingStrategyCopy.modifier.kind === 'percent') {
              pricingStrategyCopy.modifier.kind = 'number'
            }

            onChange && onChange(pricingStrategyCopy)
          }}
          name={t('strategy.modifierType')}
          type="bool"
          tooltipText={t('strategy.tooltip.modifierType')}
          value={
            {
              number: ` ${pricingStrategy.modifier.value === 1 ? 'cent' : 'cents'}`,
              percent: '%',
            }[pricingStrategy.modifier.kind]
          }
          dataTestid={`strategy-kind-${pricingStrategy.name}`}
        />{' '}
        {pricingStrategy.modifier.kind === 'number' && (
          <EditableElement
            key={pricingStrategy.modifier.value > 0 ? 'less' : 'more'}
            attribute="modifier"
            showBorder
            viewOnly={viewOnly}
            onChange={() => {
              const pricingStrategyCopy = { ...pricingStrategy }
              pricingStrategyCopy.modifier.value = -pricingStrategyCopy.modifier.value
              onChange && onChange(pricingStrategyCopy)
            }}
            name={t('strategy.lessOrMore')}
            type="bool"
            tooltipText=" "
            value={pricingStrategy.modifier.value > 0 ? t('strategy.less') : t('strategy.more')}
            dataTestid={`strategy-LM-${pricingStrategy.name}`}
          />
        )}{' '}
        {
          { number: t('strategy.thanThe'), percent: t('strategy.ofThe') }[
            pricingStrategy.modifier.kind
          ]
        }{' '}
        {pricingStrategy.base.kind === 'articleData' && (
          <>
            <EditableElement
              onChange={(newRank) => {
                const rank = newRank as number
                const pricingStrategyCopy = { ...pricingStrategy }
                pricingStrategyCopy.base.tactic.parameter = rank > 0 ? rank : 1
                onChange && onChange(pricingStrategyCopy)
              }}
              name={
                pricingStrategy.base.tactic.parameterType === 'percentile' ? 'Percentile' : 'Rank'
              }
              showBorder
              existingValue={String(pricingStrategy.base.tactic.parameter)}
              tooltipText={`${t(
                `strategy.tooltip.${
                  pricingStrategy.base.tactic.parameterType === 'percentile'
                    ? 'percentileCheapest'
                    : 'cheapest'
                }`,
                { count: pricingStrategy.base.tactic.parameter }
              )}`}
              type="number"
              viewOnly={viewOnly}
              value={`${pricingStrategy.base.tactic.parameter}.`}
              dataTestid={`strategy-cheapest-${pricingStrategy.name}`}
            />{' '}
            <EditableElement
              onChange={() => {
                const pricingStrategyCopy = { ...pricingStrategy }
                if (pricingStrategy.base.tactic.parameterType === 'percentile')
                  pricingStrategyCopy.base.tactic.parameterType = 'rank'
                else pricingStrategyCopy.base.tactic.parameterType = 'percentile'

                onChange && onChange(pricingStrategyCopy)
              }}
              name={t('strategy.parameterType')}
              showBorder
              existingValue={String(pricingStrategy.base.tactic.parameterType)}
              tooltipText={t('strategy.tooltip.parameterType')}
              type="bool"
              viewOnly={viewOnly}
              value={`${t(
                `strategy.${
                  pricingStrategy.base.tactic.parameterType === 'percentile'
                    ? 'percentileCheapest'
                    : 'cheapest'
                }`
              )}`}
              dataTestid={`strategy-parameterType-${pricingStrategy.name}`}
            />{' '}
          </>
        )}{' '}
        <EditableElement
          onChange={() => {
            if (pricingStrategy.base.kind === 'articleData')
              onChange &&
                onChange({
                  ...pricingStrategy,
                  base: { kind: 'priceGuide', tactic: { parameter: 'AVG1' } },
                })
            else if (pricingStrategy.base.kind === 'priceGuide')
              onChange &&
                competitors &&
                onChange({
                  ...pricingStrategy,
                  base: {
                    kind: 'articleData',
                    tactic: { competitor: competitors[0]._id, parameter: 1 },
                  },
                })
          }}
          name={t('strategy.base')}
          showBorder
          viewOnly={viewOnly || competitionDisabled}
          tooltipText={t('strategy.tooltip.base')}
          key={pricingStrategy.base.kind}
          type="bool"
          value={
            {
              articleData: t('strategy.offer'),
              priceGuide: t('strategy.value'),
              minMax: t('strategy.value'),
            }[pricingStrategy.base.kind]
          }
          dataTestid={`strategy-type-${pricingStrategy.name}`}
        />{' '}
        {
          {
            articleData: (
              <>
                {t('strategy.from')}{' '}
                <EditableElement
                  showBorder
                  onChange={(newCompetitorId) => {
                    const pricingStrategyCopy = { ...pricingStrategy }
                    pricingStrategyCopy.base.tactic.competitor = newCompetitorId
                    onChange && onChange(pricingStrategyCopy)
                  }}
                  name={t('competitor.title')}
                  viewOnly={viewOnly}
                  tooltipText={t('strategy.tooltip.competitor')}
                  viewOnlyTooltipText={competitor && <CompetitorBox competitor={competitor} />}
                  options={
                    competitors?.map((competitor) => ({
                      label: competitor.name,
                      value: competitor._id,
                    })) || []
                  }
                  value={competitor?.name || ''}
                  dataTestid={`strategy-competitorName-${pricingStrategy.name}`}
                />
              </>
            ),
            priceGuide: (
              <>
                {t('strategy.of')}{' '}
                <EditableElement
                  showBorder
                  onChange={(newParameter) => {
                    const pricingStrategyCopy = { ...pricingStrategy }
                    pricingStrategyCopy.base.tactic.parameter = newParameter
                    onChange && onChange(pricingStrategyCopy)
                  }}
                  name={t('strategy.parameter')}
                  viewOnly={viewOnly}
                  tooltipText={t('strategy.tooltip.parameter')}
                  options={[
                    { label: 'Trend', value: 'Trend' },
                    { label: 'AVG1', value: 'AVG1' },
                    { label: 'AVG7', value: 'AVG7' },
                    { label: 'AVG30', value: 'AVG30' },
                  ]}
                  value={pricingStrategy.base.tactic.parameter}
                  dataTestid={`strategy-trendOption-${pricingStrategy.name}`}
                />
              </>
            ),
            minMax: (
              <>
                {t('strategy.of')}{' '}
                <EditableElement
                  showBorder
                  onChange={(newParameter) => {
                    const pricingStrategyCopy = { ...pricingStrategy }
                    pricingStrategyCopy.base.tactic.parameter = newParameter
                    onChange && onChange(pricingStrategyCopy)
                  }}
                  name={t('strategy.parameter')}
                  viewOnly={viewOnly}
                  tooltipText={t('strategy.tooltip.parameter')}
                  options={[
                    { label: 'Trend', value: 'Trend' },
                    { label: 'AVG1', value: 'AVG1' },
                    { label: 'AVG7', value: 'AVG7' },
                    { label: 'AVG30', value: 'AVG30' },
                  ]}
                  value={pricingStrategy.base.tactic.parameter}
                  dataTestid={`strategy-trendOption-${pricingStrategy.name}`}
                />
              </>
            ),
          }[pricingStrategy.base.kind]
        }
      </>
    )
  }

  const getViewOnlyTooltipTextMinMax = (strategy: PricingStrategy | undefined) => {
    if (strategy?.base.kind === 'articleData') {
      const competitor = competitors?.find((c) => c._id === strategy.base.tactic.competitor)
      if (competitor) {
        return <CompetitorBox competitor={competitor} />
      }
    } else if (strategy?.base.kind === 'priceGuide') {
      return <Box>{strategy.name}</Box>
    }
  }

  const getContentMinMaxStrategy = () => {
    const parameter = pricingStrategy.base.tactic.parameter
    const strategy1 =
      pricingStrategy1 ??
      user.pricingStrategies.find((ps) => ps._id === pricingStrategy.base.tactic.strategy1)
    const strategy2 =
      pricingStrategy2 ??
      user.pricingStrategies.find((ps) => ps._id === pricingStrategy.base.tactic.strategy2)

    return (
      <>
        <EditableElement
          showBorder
          onChange={(newOperator) => {
            const pricingStrategyCopy = { ...pricingStrategy }
            pricingStrategyCopy.base.tactic.parameter = newOperator
            onChange && onChange(pricingStrategyCopy)
          }}
          name="Operator"
          viewOnly={viewOnly}
          tooltipText="Comparison operator"
          options={
            ['Min', 'Max'].map((operator) => ({
              value: operator,
              label: operator.toUpperCase(),
            })) || []
          }
          value={parameter}
          dataTestid={`comparison-operator-${pricingStrategy.name}`}
        />{' '}
        between{' '}
        <EditableElement
          showBorder
          onChange={(newStrategyId) => {
            const pricingStrategyCopy = { ...pricingStrategy }
            pricingStrategyCopy.base.tactic.strategy1 = newStrategyId
            onChange && onChange(pricingStrategyCopy)
          }}
          name="First strategy"
          viewOnly={viewOnly}
          tooltipText={getViewOnlyTooltipTextMinMax(strategy1)}
          sameTooltipForViewOnly
          options={user.pricingStrategies
            .filter((strat) => strat.base.kind !== 'minMax')
            .map((strat) => {
              return { value: strat._id, label: strat.name }
            })}
          value={strategy1?.name}
          dataTestid={`strategy1-${pricingStrategy.name}`}
        />{' '}
        and{' '}
        <EditableElement
          showBorder
          onChange={(newStrategyId) => {
            const pricingStrategyCopy = { ...pricingStrategy }
            pricingStrategyCopy.base.tactic.strategy2 = newStrategyId
            onChange && onChange(pricingStrategyCopy)
          }}
          name="Second strategy"
          viewOnly={viewOnly}
          tooltipText={getViewOnlyTooltipTextMinMax(strategy2)}
          sameTooltipForViewOnly
          options={user.pricingStrategies
            .filter((strat) => strat.base.kind !== 'minMax')
            .map((strat) => {
              return { value: strat._id, label: strat.name }
            })}
          value={strategy2?.name}
          dataTestid={`strategy2-${pricingStrategy.name}`}
        />
      </>
    )
  }

  return (
    <EntityBox
      competitorOrStrategy
      disabled={isDisabled}
      Icon={
        (!hideIcon &&
          (pricingStrategy.base.kind === 'minMax'
            ? MinMaxStrategyIcon
            : pricingStrategy.base.kind === 'articleData'
            ? CompetitionStrategyIcon
            : PriceGuideStrategyIcon)) ||
        null
      }
      key={pricingStrategy._id}
      viewOnly={viewOnly}
      onDelete={() => onDelete && onDelete(pricingStrategy._id)}
      onClone={onClone ? () => onClone(pricingStrategy) : undefined}
      dataTestid={`delete-strategy-${pricingStrategy.name}`}
      titleRow={
        <>
          <EditableElement
            value={pricingStrategy.name}
            attribute="name"
            type="textfield"
            title
            viewOnly={viewOnly}
            onChange={handleChange}
            existingValue={pricingStrategy.name}
            tooltipText={t('strategy.tooltip.name')}
            name={t('strategy.name')}
            dataTestid={`strategy-${pricingStrategy.name}`}
          />
          {pricingStrategy.base.kind !== 'minMax' && (
            <EditableElement
              dataTestid={`bulk-select-${pricingStrategy.name}`}
              existingValue={currentBulkSettingId}
              options={getBulkSettingOptions()}
              sameTooltipForViewOnly
              onChange={(newBulkSettingId) => {
                const bulkSettingId = newBulkSettingId as string
                const pricingStrategyCopy = { ...pricingStrategy }
                pricingStrategyCopy.bulkSettingsId = {
                  ...pricingStrategyCopy.bulkSettingsId,
                  [activeGame.idGame]: bulkSettingId,
                }
                onChange && onChange(pricingStrategyCopy)
              }}
              viewOnly={viewOnly}
              value={
                <BooleanIconButton
                  disabled={viewOnly}
                  active={currentBulkSettingId != ''}
                  Icon={BulkIcon}
                  dataTestid={`bulk-icon-strategy-${pricingStrategy.name}`}
                />
              }
              tooltipText={
                <Box width={'400px'}>
                  {currentBulkSetting ? (
                    <BulkpriceTooltipTable
                      key={currentBulkSetting._id}
                      bulkSettings={currentBulkSetting}
                    ></BulkpriceTooltipTable>
                  ) : (
                    ''
                  )}
                  {t(
                    `strategy.tooltip.bulkPricing.${
                      currentBulkSettingId != '' ? 'active' : 'inactive'
                    }`
                  )}
                </Box>
              }
              name={t('strategy.bulkPricing')}
            />
          )}
          {pricingStrategy.base.kind === 'articleData' && (
            <>
              {' '}
              {!!activeGame.playsetCount && (
                <EditableElement
                  existingValue={pricingStrategy.disablePlaysetPricing}
                  attribute="disablePlaysetPricing"
                  onChange={handleChange}
                  viewOnly={viewOnly}
                  sameTooltipForViewOnly
                  type="bool"
                  value={
                    <BooleanIconButton
                      disabled={viewOnly}
                      active={!pricingStrategy.disablePlaysetPricing}
                      Icon={getPlaysetIcon(activeGame.playsetCount)}
                      dataTestid={`playset-icon-strategy-${pricingStrategy.name}`}
                    />
                  }
                  tooltipText={t(
                    `strategy.tooltip.playsetPricing.${
                      pricingStrategy.disablePlaysetPricing ? 'inactive' : 'active'
                    }`,
                    { playsetCount: activeGame.playsetCount }
                  )}
                  name={t('strategy.playsetPricing')}
                />
              )}
              <EditableElement
                existingValue={pricingStrategy.NMeqEX}
                attribute="NMeqEX"
                type="bool"
                viewOnly={viewOnly}
                sameTooltipForViewOnly
                onChange={handleChange}
                value={
                  <BooleanIconButton
                    disabled={viewOnly}
                    active={Boolean(pricingStrategy.NMeqEX)}
                    Icon={NMeqEXIcon}
                    dataTestid={`NMeqEX-icon-strategy-${pricingStrategy.name}`}
                  />
                }
                tooltipText={t(
                  `strategy.tooltip.NMeqEX.${pricingStrategy.NMeqEX ? 'active' : 'inactive'}`
                )}
                name={t('strategy.NMeqEX')}
              />
              <EditableElement
                existingValue={pricingStrategy.strictLanguage}
                attribute="strictLanguage"
                type="bool"
                viewOnly={viewOnly}
                sameTooltipForViewOnly
                onChange={handleChange}
                value={
                  <BooleanIconButton
                    disabled={viewOnly}
                    active={Boolean(pricingStrategy.strictLanguage)}
                    Icon={LanguageIcon}
                    dataTestid={`strictLanguage-icon-strategy-${pricingStrategy.name}`}
                  />
                }
                tooltipText={t(
                  `strategy.tooltip.strictLanguage.${
                    pricingStrategy.strictLanguage ? 'active' : 'inactive'
                  }`
                )}
                name={t('strategy.strictLanguage')}
              />
              {activeGame.extraAttr.includes(CardExtraAttribute.FirstEd) && (
                <EditableElement
                  existingValue={pricingStrategy.strictFirstEd}
                  attribute="strictFirstEd"
                  type="bool"
                  viewOnly={viewOnly}
                  sameTooltipForViewOnly
                  onChange={handleChange}
                  value={
                    <BooleanIconButton
                      disabled={viewOnly}
                      active={Boolean(pricingStrategy.strictFirstEd)}
                      Icon={FirstEdIcon}
                      dataTestid={`strictFirstEd-icon-strategy-${pricingStrategy.name}`}
                    />
                  }
                  tooltipText={t(
                    `strategy.tooltip.strictFirstEd.${
                      pricingStrategy.strictFirstEd ? 'active' : 'inactive'
                    }`
                  )}
                  name={t('strategy.strictFirstEd')}
                />
              )}
            </>
          )}
        </>
      }
      contentRow={
        <Typography variant="body2">
          {pricingStrategy.base.kind === 'minMax'
            ? getContentMinMaxStrategy()
            : getContentStandardStrategy()}
        </Typography>
      }
    />
  )
}
