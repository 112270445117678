import { Box } from '@material-ui/core'
import { ElementWithChildren } from 'types'

export const CrossedOut = ({ children }: ElementWithChildren): JSX.Element => {
  return (
    <Box
      style={{
        position: 'relative',
        width: 'fit-content',
        height: 'fit-content',
      }}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
      <svg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <line
          style={{
            stroke: 'rgb(255, 0, 0, 0.6)',
            strokeWidth: 1.5,
          }}
          x1="0"
          y1="100%"
          x2="100%"
          y2="0"
        />
        <line
          style={{
            stroke: 'rgb(255, 0, 0, 0.6)',
            strokeWidth: 1.5,
          }}
          x1="0"
          y1="0"
          x2="100%"
          y2="100%"
        />
      </svg>
    </Box>
  )
}
