import { Box, Fab, IconButton } from '@material-ui/core'
import { StockState } from 'components/domain/stockPricing'
import { CloseIcon } from 'components/facets'
import { usePriceGuide } from 'components/providers/PriceGuideProvider'
import { useUser } from 'components/providers/UserProvider'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import React, { createContext, useCallback, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  changeNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll,
  TreeItem,
  TreePath,
} from 'react-sortable-tree'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import {
  createInventoryArticle,
  DefaultPricingPlanNode,
  getNodeForArticle,
  InventoryArticle,
  PricingPlan,
  PricingPlanNode,
  PricingPlanNodeCondition,
  PricingStrategy,
  RichArticle,
} from 'shared'
import { SetState } from 'types'
import { NodePresenter } from './NodePresenter'
import { PricingPlanSidebar } from './PricingPlanSidebar'
export interface PricingPlanPresenterProps {
  onStrategyDelete: (id: string) => void
  onStrategyChange: (newValue: PricingStrategy) => void
  onStrategyClone: (newValue: PricingStrategy) => void
  pricingPlan: PricingPlan
  onChange: (newValue: PricingPlan) => Promise<void>
  onClose: () => void
}

export type PathType = TreePath['path']

type EditConditionCtx = {
  conditionId: string
  path: PathType
  node: PricingPlanNode
} | null
export const PricingPlanContext = createContext<{
  conditionCtx: PricingPlanNodeCondition | null
  setConditionCtx: SetState<PricingPlanNodeCondition | null>
  editConditionCtx: EditConditionCtx
  setEditConditionCtx: SetState<EditConditionCtx>
  deleteNode: (path: PathType) => void //treedata
  changeNode: (path: PathType, node: PricingPlanNode) => void
  changeDefaultNode: (defaultNode: DefaultPricingPlanNode) => void
  testedArticle: RichArticle | null
  setTestedArticle: SetState<RichArticle | null>
  highlightedNodeId: string
  isDraggingCondition: boolean
  isDraggingStrategy: boolean
  setIsDraggingCondition: SetState<boolean>
  setIsDraggingStrategy: SetState<boolean>
  stockByNode: Record<string, InventoryArticle[]>
  totalStockCount: number
  addRowMode: boolean
  addRuleMode: boolean
  setAddRuleMode: SetState<boolean>
  disallowNesting: boolean
  selectedRuleNode: TreeItem | null
  setSelectedRuleNode: SetState<TreeItem | null>
  selectedRulePath: PathType | null
  setSelectedRulePath: SetState<PathType | null>
  tree: TreeItem[]
  changeTree: SetState<TreeItem[]>
  selectedTreeIndex: number | null
  setSelectedTreeIndex: SetState<number | null>
}>({
  conditionCtx: null,
  setConditionCtx: () => undefined,
  editConditionCtx: null,
  setEditConditionCtx: () => undefined,
  deleteNode: () => undefined,
  changeNode: () => undefined,
  changeDefaultNode: () => undefined,
  testedArticle: null,
  setTestedArticle: () => undefined,
  highlightedNodeId: '',
  isDraggingCondition: false,
  isDraggingStrategy: false,
  setIsDraggingCondition: () => undefined,
  setIsDraggingStrategy: () => undefined,
  stockByNode: {},
  totalStockCount: 0,
  addRowMode: false,
  addRuleMode: false,
  setAddRuleMode: () => undefined,
  disallowNesting: false,
  selectedRuleNode: {},
  setSelectedRuleNode: () => undefined,
  selectedRulePath: [],
  setSelectedRulePath: () => undefined,
  tree: [],
  changeTree: () => undefined,
  selectedTreeIndex: null,
  setSelectedTreeIndex: () => undefined,
})

const SIDEBAR_WIDTH = 350
export enum DraggableItemsEnum {
  Node = 'node',
  Condition = 'condition',
  Strategy = 'strategy',
}

const transformTreeToNodes = (tree: TreeItem[] = []): PricingPlanNode[] => {
  if (!tree.length) return []
  return tree.map((branch) => {
    const { children, conditionStrictness, conditions, id, method } = branch
    return {
      children: transformTreeToNodes(children as TreeItem[]),
      conditionStrictness,
      conditions,
      id,
      method,
    }
  })
}

export const PricingPlanPresenter = React.memo(function ({
  onStrategyDelete,
  onStrategyChange,
  onStrategyClone,
  pricingPlan,
  onChange,
  onClose,
}: PricingPlanPresenterProps): JSX.Element {
  const [conditionCtx, setConditionCtx] = useState<PricingPlanNodeCondition | null>(null)
  const [editConditionCtx, setEditConditionCtx] = useState<EditConditionCtx>(null)
  const [testedArticle, setTestedArticle] = useState<RichArticle | null>(null)
  const [highlightedNodeId] = useState<string>('')

  const [isDraggingCondition, setIsDraggingCondition] = useState(false)
  const [isDraggingStrategy, setIsDraggingStrategy] = useState(false)
  const [addRowMode, setAddRowMode] = useState(false)
  const [addRuleMode, setAddRuleMode] = useState(false)
  const [hiddenAddButton, setHiddenAddButton] = useState(addRowMode || addRuleMode)
  const [selectedRuleNode, setSelectedRuleNode] = useState<TreeItem | null>(null)
  const [selectedRulePath, setSelectedRulePath] = useState<PathType | null>(null)
  const [selectedTreeIndex, setSelectedTreeIndex] = useState<number | null>(null)

  const { getPriceGuide } = usePriceGuide()
  const [tree, setTree] = useState(
    toggleExpandedForAll({ treeData: pricingPlan.nodes }) as TreeItem[]
  )

  const { user } = useUser()

  const [inventoryState] = useLocalForageState<StockState>(
    { stock: [], sealedStock: [], fetchedAt: new Date().toLocaleString('sk-SK') },
    `${user?.cardmarketUsername}-stock`,
    {
      duckFunction: (value) => value.stock.find((article) => article.card._id),
      hydrate: (value) => ({
        ...value,
        stock: value.stock.map((article) => createInventoryArticle(article)),
      }),
    }
  )

  const changeTree: SetState<TreeItem[]> = useCallback(
    (val) => {
      if (val instanceof Function) {
        const resolvedValue = val(tree)
        onChange({ ...pricingPlan, nodes: transformTreeToNodes(resolvedValue) })
        setTree(resolvedValue)
      } else {
        onChange({ ...pricingPlan, nodes: transformTreeToNodes(val) })
        setTree(val)
      }
      setAddRowMode(false)
    },
    [pricingPlan, tree, onChange]
  )

  const deleteNode = useCallback(
    (path: PathType) => {
      changeTree(
        removeNodeAtPath({
          path,
          treeData: tree,
          getNodeKey: ({ node }) => {
            return node.id
          },
        })
      )
    },
    [changeTree, tree]
  )

  const changeNode = useCallback(
    (path: PathType, node: TreeItem) => {
      changeTree(
        changeNodeAtPath({
          treeData: tree,
          path: path,
          newNode: node,
          getNodeKey: ({ node }) => {
            return node.id
          },
        })
      )
    },
    [changeTree, tree]
  )

  const changeDefaultNode = useCallback(
    (defaultNode: DefaultPricingPlanNode) => {
      onChange({ ...pricingPlan, defaultNode })
    },
    [pricingPlan, onChange]
  )

  const nodesString = JSON.stringify(pricingPlan.nodes)

  const stockByNode = useMemo(() => {
    const byNode: Record<string, InventoryArticle[]> = {}
    inventoryState.stock.forEach((article) => {
      const { id } = getNodeForArticle(pricingPlan, article, getPriceGuide(article.idProduct))
      if (!byNode[id]) byNode[id] = []
      byNode[id].push(article)
    })

    return byNode
  }, [nodesString, inventoryState.stock.length])

  return (
    <DndProvider backend={HTML5Backend}>
      <PricingPlanContext.Provider
        value={{
          conditionCtx,
          setConditionCtx,
          deleteNode,
          changeNode,
          editConditionCtx,
          setEditConditionCtx,
          changeDefaultNode,
          testedArticle,
          setTestedArticle,
          highlightedNodeId,
          isDraggingCondition,
          isDraggingStrategy,
          setIsDraggingCondition,
          setIsDraggingStrategy,
          stockByNode,
          totalStockCount: inventoryState.stock.length,
          addRowMode,
          addRuleMode,
          setAddRuleMode,
          disallowNesting: pricingPlan.disallowNesting ? true : false,
          selectedRuleNode,
          setSelectedRuleNode,
          selectedRulePath,
          setSelectedRulePath,
          tree,
          changeTree,
          selectedTreeIndex,
          setSelectedTreeIndex,
        }}
      >
        <Box>
          <PricingPlanSidebar
            width={SIDEBAR_WIDTH}
            onStrategyDelete={onStrategyDelete}
            onStrategyChange={onStrategyChange}
            onStrategyClone={onStrategyClone}
            pricingPlan={pricingPlan}
            addRowMode={addRowMode}
            setAddRowMode={setAddRowMode}
            mainTreeData={tree}
            changeTree={changeTree}
            setHiddenAddButton={setHiddenAddButton}
          />
          <Box marginLeft={`${SIDEBAR_WIDTH}px`}>
            <Box width="fit-content" marginLeft="auto" position="absolute" top="1rem" right="1rem">
              <Box display="inline" marginLeft={1}>
                <Fab size="small" color="inherit">
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Fab>
              </Box>
            </Box>
            <Box padding={2}>
              <NodePresenter
                tree={tree}
                changeTree={changeTree}
                pricingPlan={pricingPlan}
                addRowMode={addRowMode}
                setAddRowMode={setAddRowMode}
                hiddenAddButton={hiddenAddButton}
                addRuleMode={addRuleMode}
              />
            </Box>
          </Box>
        </Box>
      </PricingPlanContext.Provider>
    </DndProvider>
  )
})
