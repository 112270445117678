import { Order, OrderArticle, ORDER_FILTERS, PickingList } from 'shared'
import create from 'zustand'

export interface OrderStoreState {
  orders: Order[]
  rawOrders: Order[]
  filteredOrders: Order[]
  articles: OrderArticle[]
  filteredArticles: OrderArticle[]
  view: string
  filterByOrder: number[]
  startDate: Date
  endDate: Date
  expansionMap: {
    [key: string]: {
      code: string
      releaseDate: Date
    }
  }
  categoryMap: {
    [key: number]: {
      category: string
      colors: string
    }
  }
  activeTab: number
  layout: string
  selectedArticle: OrderArticle | undefined
  activeOrdersFilters: string[]
  selectedOrderIds: number[]
  activePickingList: PickingList | undefined
  articlesToPick: OrderArticle[]
  articlesPicked: OrderArticle[]
  articlesWithIssues: OrderArticle[]
  expandedOrder: Order | undefined
  lastPicked: OrderArticle | undefined
  articlesReadyForSorting: boolean
  expandedRowIndex: number | undefined
  loadingFromMkm: boolean
  loadingFromPt: boolean
  setOrders: (newOrders: Order[]) => void
  setRawOrders: (newRawOrders: Order[]) => void
  setFilteredOrders: (newOrders: Order[]) => void
  setArticles: (newArticles: OrderArticle[]) => void
  setFilteredArticles: (newArticles: OrderArticle[]) => void
  setView: (newView: string) => void
  setFilterByOrder: (newFilterByOrder: number[]) => void
  setStartDate: (newStartDate: Date) => void
  setEndDate: (newEndDate: Date) => void
  setExpansionMap: (newExpansionMap: { [key: string]: { code: string; releaseDate: Date } }) => void
  setCategoryMap: (newCategoryMap: {
    [key: number]: {
      category: string
      colors: string
    }
  }) => void
  setActiveTab: (activeTab: number) => void
  setLayout: (newLayout: string) => void
  setSelectedArticle: (newSelectedArticle: OrderArticle | undefined) => void
  setActiveOrdersFilters: (newActiveOrdersFilters: string[]) => void
  setSelectedOrderIds: (newSelectedOrderIds: number[]) => void
  setActivePickingList: (newActivePickingList: PickingList | undefined) => void
  setArticlesToPick: (newArticlesToPick: OrderArticle[]) => void
  setArticlesPicked: (newArticlesPicked: OrderArticle[]) => void
  setArticlesWithIssues: (newArticlesWithIssues: OrderArticle[]) => void
  setExpandedOrder: (newExpandedOrder: Order | undefined) => void
  setLastPicked: (newLastPicked: OrderArticle | undefined) => void
  setArticlesReadyForSorting: (newArticlesReadyForSorting: boolean) => void
  setExpandedRowIndex: (newExpandedRowIndex: number | undefined) => void
  setLoadingFromMkm: (newLoadingFromMkm: boolean) => void
  setLoadingFromPt: (newLoadingFromPt: boolean) => void
}

const getDefaultStartDate = () => {
  const d = new Date()
  d.setMonth(d.getMonth() - 18)
  return d
}

export const useOrderStore = create<OrderStoreState>()((set) => ({
  orders: [],
  rawOrders: [],
  filteredOrders: [],
  articles: [],
  filteredArticles: [],
  view: 'list',
  filterByOrder: [],
  startDate: getDefaultStartDate(),
  endDate: new Date(),
  expansionMap: {},
  categoryMap: {},
  activeTab: -1,
  layout: 'text',
  selectedArticle: undefined,
  activeOrdersFilters: ORDER_FILTERS.filter((f) => f.defaultActive).map((f) => f.state),
  selectedOrderIds: [],
  activePickingList: undefined,
  articlesToPick: [],
  articlesPicked: [],
  articlesWithIssues: [],
  expandedOrder: undefined,
  lastPicked: undefined,
  articlesReadyForSorting: false,
  expandedRowIndex: undefined,
  loadingFromMkm: false,
  loadingFromPt: false,
  setOrders: (newOrders: Order[]) => set({ orders: newOrders }),
  setRawOrders: (newRawOrders: Order[]) => set({ rawOrders: newRawOrders }),
  setFilteredOrders: (newFilteredOrders: Order[]) => set({ filteredOrders: newFilteredOrders }),
  setArticles: (newArticles: OrderArticle[]) => set({ articles: newArticles }),
  setFilteredArticles: (newFilteredArticles: OrderArticle[]) =>
    set({ filteredArticles: newFilteredArticles }),
  setView: (newView: string) => set({ view: newView }),
  setFilterByOrder: (newFilterByOrder: number[]) => set({ filterByOrder: newFilterByOrder }),
  setStartDate: (newStartDate: Date) => set({ startDate: newStartDate }),
  setEndDate: (newEndDate: Date) => set({ endDate: newEndDate }),
  setExpansionMap: (newExpansionMap: { [key: string]: { code: string; releaseDate: Date } }) =>
    set({ expansionMap: newExpansionMap }),
  setCategoryMap: (newCategoryMap: {
    [key: number]: {
      category: string
      colors: string
    }
  }) => set({ categoryMap: newCategoryMap }),
  setActiveTab: (newActiveTab: number) => set({ activeTab: newActiveTab }),
  setLayout: (newLayout: string) => set({ layout: newLayout }),
  setSelectedArticle: (newSelectedArticle: OrderArticle | undefined) =>
    set({ selectedArticle: newSelectedArticle }),
  setActiveOrdersFilters: (newActiveOrdersFilters: string[]) =>
    set({ activeOrdersFilters: newActiveOrdersFilters }),
  setSelectedOrderIds: (newSelectedOrderIds: number[]) =>
    set({ selectedOrderIds: newSelectedOrderIds }),
  setActivePickingList: (newActivePickingList: PickingList | undefined) =>
    set({ activePickingList: newActivePickingList }),
  setArticlesToPick: (newArticlesToPick: OrderArticle[]) =>
    set({ articlesToPick: newArticlesToPick }),
  setArticlesPicked: (newArticlesPicked: OrderArticle[]) =>
    set({ articlesPicked: newArticlesPicked }),
  setArticlesWithIssues: (newArticlesWithIssues: OrderArticle[]) =>
    set({ articlesWithIssues: newArticlesWithIssues }),
  setExpandedOrder: (newExpandedOrder: Order | undefined) =>
    set({ expandedOrder: newExpandedOrder }),
  setLastPicked: (newLastPicked: OrderArticle | undefined) => set({ lastPicked: newLastPicked }),
  setArticlesReadyForSorting: (newArticlesReadyForSorting: boolean) =>
    set({ articlesReadyForSorting: newArticlesReadyForSorting }),
  setExpandedRowIndex: (newExpandedRowIndex: number | undefined) =>
    set({ expandedRowIndex: newExpandedRowIndex }),
  setLoadingFromMkm: (newLoadingFromMkm: boolean) => set({ loadingFromMkm: newLoadingFromMkm }),
  setLoadingFromPt: (newLoadingFromPt: boolean) => set({ loadingFromPt: newLoadingFromPt }),
}))
