import { Box, Typography, useTheme } from '@material-ui/core'
import { CustomTooltip } from 'components/facets'
import { SubTitle } from 'components/facets/SubTitle'
import { useUser } from 'components/providers/UserProvider'
import { useTranslation } from 'react-i18next'
import { BulkSettings, Competitor, PricingStrategy } from 'shared'
import { PricingStrategiesEditor } from './PricingStrategiesEditor'

export interface PricingStrategiesPresenterProps {
  pricingStrategies: Array<PricingStrategy>
  competitors: Array<Competitor>
  onDelete: (id: string) => void
  onCreate: () => void
  onChange: (newValue: PricingStrategy) => void
  onClone: (newValue: PricingStrategy) => void
  competitionDisabled: boolean
  bulkSettings: Array<BulkSettings>
}

export const PricingStrategiesPresenter = ({
  pricingStrategies,
  competitors,
  onDelete,
  onCreate,
  onChange,
  onClone,
  competitionDisabled,
  bulkSettings,
}: PricingStrategiesPresenterProps): JSX.Element => {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const { activeGame } = useUser()

  return (
    <Box>
      <Box>
        <SubTitle>{t('strategy.yourStrategies')}</SubTitle>
      </Box>
      <Typography gutterBottom variant="body2">
        {t('strategy.descriptionText')}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t('strategy.descriptionTextCompetition')}
      </Typography>

      <CustomTooltip
        title={
          <Box padding={1}>
            <Typography variant={'body2'}>
              <b>{t('card.set')}:</b> {t('strategy.conditions.set')}
            </Typography>
            <Typography variant={'body2'}>
              <b>{t('card.condition')}:</b> {t('strategy.conditions.condition')}
            </Typography>
            <Typography variant={'body2'}>
              <b>{t('card.extra')}:</b> {t('strategy.conditions.extra')}
            </Typography>
            <Typography variant={'body2'}>
              <b>{t('card.quantity')}:</b>{' '}
              {t('strategy.conditions.quantity', { playsetCount: activeGame.playsetCount })}
            </Typography>
            <Typography variant={'body2'}>
              <b>{t('card.language')}:</b> {t('strategy.conditions.language')}
            </Typography>
          </Box>
        }
      >
        <Typography
          variant="body2"
          gutterBottom
          style={{ color: palette.primary.main, cursor: 'help', width: 'fit-content' }}
        >
          {t('strategy.equalOrBetter')}
        </Typography>
      </CustomTooltip>
      {competitionDisabled && (
        <Typography gutterBottom variant="body2">
          <b>{t('competitionDisabled')}</b>
        </Typography>
      )}
      <Box padding={2}>
        <PricingStrategiesEditor
          pricingStrategies={pricingStrategies}
          competitors={competitors}
          onDelete={onDelete}
          onCreate={onCreate}
          onChange={onChange}
          onClone={onClone}
          rowCount={2}
          competitionDisabled={competitionDisabled}
          bulkSettings={bulkSettings}
        />
      </Box>
    </Box>
  )
}
