import { Box, Button, Grid, TextareaAutosize, TextField, Typography } from '@material-ui/core'
import NewWindow from 'components/facets/NewWindow'
import { useCardThingData } from 'components/providers/CardDataProvider'
import { useUser } from 'components/providers/UserProvider'
import Cookie from 'js-cookie'
import {
  createEbayAddTask,
  createEbayEndTask,
  ebayDisconnect,
  ebayListItems,
  ebayRemoveItems,
  generateEbayAuthUrl,
  getEbayAccessToken,
  getEbayOrders,
  getEbayResultFile,
  getEbayTask,
  getEbayUser,
  getUser,
  uploadEbayFile,
} from 'lib/api'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import {
  AddFixedPriceItemRequest,
  BulkDataExchangeRequestsClosing,
  BulkDataExchangeRequestsOpening,
  CardDataItem,
  createInventoryArticle,
  EndFixedPriceItemRequest,
  InventoryArticle,
  sleep,
} from 'shared'
import AutocompleteInput from '../addArticles/AddArticleDock/AutocompleteInput'
import { StockState } from '../stockPricing'
import { VisualArticle } from '../stockPricing/StockDisplay/VisualArticle'

const baseXmlHeader = '<?xml version="1.0" encoding="UTF-8"?>'

export const EbayPresenter = (): JSX.Element => {
  const { user } = useUser()

  const [output, setOutput] = useState<string>('')
  const [taskId, setTaskId] = useState<string>('')
  const [inputXml, setInputXml] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [itemId, setItemId] = useState<string>('')
  const { cardData } = useCardThingData('en')
  const [stagedArticle, setStagedArticle] = useState<InventoryArticle | null>(null)
  const [openEbayAuthWindow, setOpenEbayAuthWindow] = useState<boolean>(false)
  const [ebayAuthUrl, setEbayAuthUrl] = useState<string>('')

  const showEbaySingleCalls = true
  const showAddSingleCards = false

  const handleGenerateEbayAuthUrl = async () => {
    const ebayAuthUrl = await generateEbayAuthUrl()
    setOutput(ebayAuthUrl)
  }

  // const handleGetAccessToken = async () => {
  //   const ebayAccessToken = await getEbayAccessToken()
  //   setOutput(JSON.stringify(ebayAccessToken))
  // }

  const handleGetUser = async () => {
    const user = await getEbayUser()
    setOutput(JSON.stringify(user))
  }

  const handleGetOrders = async () => {
    const orders = await getEbayOrders()
    setOutput(JSON.stringify(orders))
  }

  const handleCreateTask = async () => {
    const resp = await createEbayAddTask()
    setOutput(JSON.stringify(resp))
  }

  const handleGetTask = async () => {
    if (taskId) {
      const resp = await getEbayTask(taskId)
      setOutput(JSON.stringify(resp))
    }
  }

  const handleUpdateTaskId = (e: any) => {
    setTaskId(e.target.value)
  }

  const readInputXml = (file: File[]) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      const fileContent = e.target ? e.target.result : ''
      setInputXml(fileContent as string)
    }

    file.forEach((f) => {
      reader.readAsText(f)
      setFile(f)
    })
  }

  const handleUploadFile = async () => {
    if (file) {
      const resp = await uploadEbayFile(taskId, file.name, inputXml)
      setOutput(JSON.stringify(resp))
    }
  }

  const handleGetResultFile = async () => {
    if (taskId) {
      const resp = await getEbayResultFile(taskId)
      setOutput(JSON.stringify(resp))
    }
  }

  const handleCreateEndTask = async () => {
    const resp = await createEbayEndTask()
    setOutput(JSON.stringify(resp))
  }

  const handleUploadEndItemFile = async () => {
    if (itemId) {
      const inputXml = EndFixedPriceItemRequest.replace('#SITE_ID#', '77')
        .replace('#VERSION#', '1285')
        .replace('#ITEM_ID#', itemId)
      const resp = await uploadEbayFile(taskId, 'prova', inputXml)
      setOutput(JSON.stringify(resp))
    }
  }

  const handleUpdateItemId = (e: any) => {
    setItemId(e.target.value)
  }

  const createListingTask = async () => {
    let resp = await createEbayAddTask()
    const taskId = resp.replace('https://api.ebay.com/sell/feed/v1/task/', '')
    resp = await getEbayTask(taskId)
    if (resp.status === 'CREATED') {
      return taskId
    }
  }

  const createRemoveTask = async () => {
    let resp = await createEbayEndTask()
    const taskId = resp.replace('https://api.ebay.com/sell/feed/v1/task/', '')
    resp = await getEbayTask(taskId)
    if (resp.status === 'CREATED') {
      return taskId
    }
  }

  const uploadListingFile = async (taskId: string, fileName: string, fileContent: string) => {
    await uploadEbayFile(taskId, fileName, fileContent)
    // polling check for task status
    let resp = await getEbayTask(taskId)
    while (['CREATED', 'QUEUED', 'IN_PROCESS'].includes(resp.status)) {
      resp = await getEbayTask(taskId)
      sleep(2000)
    }
    return resp
  }

  const getBulkDataExchangeRequestsOpening = () => {
    return BulkDataExchangeRequestsOpening.replace('#SITE_ID#', '77').replace('#VERSION#', '1285')
  }

  const createInputListingXml = (articles: InventoryArticle[]) => {
    const requestBody = articles.map((a) => {
      return AddFixedPriceItemRequest.replace('#VERSION#', '1285')
        .replace('#TITLE#', a.card.name)
        .replace('#SKU#', a.idProduct.toString())
        .replace('#PRICE#', a.price.toString())
        .replace('#PRODUCT_ID#', a.idProduct.toString())
        .replace('#EXPANSION_NAME#', a.card.expansion)
        .replace('#CARD_NAME#', a.card.name)
        .replace('#RARITY#', a.card.rarityStr ?? '')
        .replace('#QUANTITY#', a.quantity.toString())
    })

    return (
      baseXmlHeader +
      getBulkDataExchangeRequestsOpening() +
      requestBody.join() +
      BulkDataExchangeRequestsClosing
    )
  }

  const createInputRemoveXml = (articles: InventoryArticle[]) => {
    const requestBody = articles.map((a) => {
      return EndFixedPriceItemRequest.replace('#SKU#', a.idProduct.toString())
    })

    return (
      baseXmlHeader +
      getBulkDataExchangeRequestsOpening() +
      requestBody +
      BulkDataExchangeRequestsClosing
    )
  }

  const handleListItem = async (article: InventoryArticle) => {
    const status = await ebayListItems([article])
    console.log(status)
  }

  const handleListItems = async (articles: InventoryArticle[]) => {
    const status = await ebayListItems(articles)
    console.log(status)
  }

  const handleUpdateItem = async (article: InventoryArticle) => {
    const taskId = await createListingTask()
    const listingInput = createInputListingXml([article])
    const resp = await uploadListingFile(taskId, 'listing', listingInput)
    console.log(resp.status)
  }

  const handleUpdateItems = async (articles: InventoryArticle[]) => {
    const taskId = await createListingTask()
    const listingInput = createInputListingXml(articles)
    const resp = await uploadListingFile(taskId, 'listing', listingInput)
    console.log(resp.status)
  }

  const handleRemoveItem = async (article: InventoryArticle) => {
    const taskId = await createRemoveTask()
    const removeInput = createInputRemoveXml([article])
    const resp = await uploadListingFile(taskId, 'listing', removeInput)
    console.log(resp.status)
  }

  const handleRemoveItems = async (articles: InventoryArticle[]) => {
    const status = await ebayRemoveItems(articles)
    console.log(status)
  }

  const handleDisconnect = async () => {
    await ebayDisconnect()
  }

  const handleCardChange = (card: CardDataItem) => {
    const article = new InventoryArticle({
      id: card._id.toString(),
      card: card,
    })
    setStagedArticle(article)
  }

  const handleConnect = async () => {
    setEbayAuthUrl(await generateEbayAuthUrl())
    setOpenEbayAuthWindow(true)
  }

  useEffect(() => {
    async function fetchAccessToken() {
      const authToken = Cookie.get('authToken')
      if (authToken) {
        const encodedAuthToken = encodeURIComponent(authToken)
        await getEbayAccessToken(encodedAuthToken)
        const newUser = await getUser()
        console.log(newUser.eBay)
      }
    }

    if (openEbayAuthWindow) {
      fetchAccessToken()
    }
  }, [openEbayAuthWindow])

  const [inventoryState, setInventoryState] = useLocalForageState<StockState>(
    { stock: [], sealedStock: [], fetchedAt: new Date().toLocaleString('sk-SK') },
    `${user?.cardmarketUsername}-file`,
    {
      duckFunction: (value) => value.stock.find((article) => article.card._id),
      hydrate: (value) => ({
        ...value,
        stock: value.stock.map((article) => createInventoryArticle(article)),
      }),
    }
  )
  setInventoryState

  return (
    <>
      <Box padding={2}>
        {showEbaySingleCalls && (
          <Grid container>
            <Grid item xs={4}>
              <Box paddingY={1}>
                <Button variant="contained" onClick={handleGenerateEbayAuthUrl}>
                  generate Ebay Auth Url
                </Button>
              </Box>

              {/* <Box paddingY={1}>
                <Button variant="contained" onClick={handleGetAccessToken}>
                  get access token
                </Button>
              </Box> */}

              <Box paddingY={1}>
                <Button variant="contained" onClick={handleCreateTask}>
                  create add item task
                </Button>
              </Box>

              <Box paddingY={1} display="flex">
                <Button variant="contained" onClick={handleGetTask}>
                  get task
                </Button>
                <Box paddingX={1} />
                <TextField value={taskId} label="Task ID" onChange={handleUpdateTaskId} />
              </Box>

              <Box paddingY={1} display="flex">
                <Button variant="contained" onClick={handleUploadFile}>
                  upload file
                </Button>
                <Box
                  style={{
                    marginLeft: '10px',
                    border: '2px dashed black',
                  }}
                >
                  <Dropzone onDrop={(uploadedFile) => readInputXml(uploadedFile)}>
                    {({ getRootProps, getInputProps }) => (
                      //@ts-ignore
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Box padding={1}>{file ? file.name : 'Click or drop XML here'}</Box>
                      </div>
                    )}
                  </Dropzone>
                </Box>
              </Box>

              <Box paddingY={1} display="flex">
                <Button variant="contained" onClick={handleGetResultFile}>
                  get result file
                </Button>
                <Box paddingX={1} />
              </Box>

              <Box paddingY={1} display="flex">
                <Button variant="contained" onClick={handleCreateEndTask}>
                  create end item task
                </Button>
                <Box paddingX={1} />
              </Box>

              <Box paddingY={1} display="flex">
                <Button variant="contained" onClick={handleUploadEndItemFile}>
                  upload end item file
                </Button>
                <Box paddingX={1} />
                <TextField value={itemId} label="Item ID" onChange={handleUpdateItemId} />
              </Box>

              <Box paddingY={1}>
                <Button variant="contained" onClick={handleGetOrders}>
                  get orders
                </Button>
              </Box>

              <Box paddingY={1}>
                <Button variant="contained" onClick={handleGetUser}>
                  get user
                </Button>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <TextareaAutosize
                maxRows={30}
                maxLength={200}
                value={output}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        )}

        <Box>
          {user.eBay?.accessToken ? (
            <>
              Your eBay account is connected
              <Box display="flex" marginY={1}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    handleListItems(inventoryState.stock)
                  }}
                >
                  list on ebay
                </Button>
                <Box margin="10px" />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    handleUpdateItems(inventoryState.stock)
                  }}
                >
                  update on ebay
                </Button>
                <Box margin="10px" />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    handleRemoveItems(inventoryState.stock)
                  }}
                >
                  remove from ebay
                </Button>
                <Box margin="10px" />
                <Button fullWidth variant="contained" onClick={handleDisconnect}>
                  disconnect
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box marginY={1}>Your eBay account is NOT connected</Box>
              <Button variant="contained" onClick={handleConnect}>
                Connect your eBay account
              </Button>
            </>
          )}
        </Box>

        {showAddSingleCards && (
          <Box width="300px" paddingTop={2}>
            <AutocompleteInput handleCardChange={handleCardChange} cardData={cardData} />
            <Box margin="10px" />
            {stagedArticle && (
              <Box>
                <Box height={300} width={250}>
                  <VisualArticle rowHeight={300} article={stagedArticle} />
                </Box>
              </Box>
            )}
            <Box margin="10px" />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (stagedArticle) {
                  handleListItem(stagedArticle)
                }
              }}
            >
              list item on ebay
            </Button>
            <Box margin="10px" />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (stagedArticle) {
                  handleUpdateItem(stagedArticle)
                }
              }}
            >
              update item on ebay
            </Button>
            <Box margin="10px" />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (stagedArticle) {
                  handleRemoveItem(stagedArticle)
                }
              }}
            >
              remove item from ebay
            </Button>
          </Box>
        )}

        <Box marginY={2}>
          <Typography variant="h6">Test inventory</Typography>

          <Grid container>
            {inventoryState.stock.map((a) => (
              <Grid item key={a.idArticle}>
                <Box height={300} width={250}>
                  <VisualArticle rowHeight={300} article={a} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {openEbayAuthWindow ? <NewWindow url={ebayAuthUrl} /> : null}
    </>
  )
}
