import { Box } from '@material-ui/core'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { getPriceFormat } from 'lib/utils'
import moment from 'moment'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import {
  escapeDoubleQuotes,
  getIssues,
  getOrderState,
  Order,
  OrderArticle,
  PTOrderIssue,
} from 'shared'
interface ExportOrdersProps {
  orders: Order[]
  filteredOrders: Order[]
  articles: OrderArticle[]
}

interface CsvOrderItem {
  idOrder: number
  customer: string
  status: string
  dateBought: Date
  datePaid: Date
  dateSent: Date
  dateReceived: Date
  shippingMethod: string | undefined
  trackingNumber: string
  temporaryEmail: string
  isPresale: boolean
  shippingAddressName: string | undefined
  shippingAddressExtra: string | undefined
  shippingAddressStreet: string | undefined
  shippingAddressCity: string | undefined
  shippingAddressZip: string | undefined
  shippingAddressCountry: string | undefined
  phone: string | undefined
  buyerVAT: string | undefined
  updatedAt: string
  articleCategories: string
  articles: number
  articleValue: number
  totalValue: number
  currencyCode: string
  note: string
  PID: string | undefined
  issues: string | undefined
}

interface CsvOrderArticleItem {
  idOrder: number | undefined
  customer: string | undefined
  status: string | undefined
  pos: string | undefined
  items: number
  category: string
  expansion: string
  name: string
  language: string
  isFoil: boolean
  isSigned: boolean
  isPlayset: boolean
  isAltered: boolean
  condition: string
  price: string
  comments: string
  issues: string | undefined
}

export const ExportOrders = ({
  orders,
  filteredOrders,
  articles,
}: ExportOrdersProps): JSX.Element => {
  const { user } = useUser()
  const { t } = useTranslation()
  const [orderData, setOrderData] = useState<CsvOrderItem[]>([])
  const [articleData, setArticleData] = useState<CsvOrderArticleItem[]>([])

  const fileFormats = ['csv', 'xlsx']
  // const [fileFormat, setFileFormat] = useState(fileFormats[0])
  const fileFormat = fileFormats[0]

  const getTextIssues = (issues: PTOrderIssue[]) => {
    const textIssues = issues.map((i) => i.description)
    return textIssues.join('\n')
  }

  const getPhoneNumber = (o: Order) => {
    const phoneNumber = o.phoneNumber || o.buyer.phone
    if (phoneNumber) {
      return `'${phoneNumber}'`
    } else {
      return ''
    }
  }

  const getRowFromOrder = (o: Order) => {
    return {
      idOrder: o.idOrder,
      customer: o.buyer.username,
      status: getOrderState(o),
      dateBought: o.state.dateBought,
      datePaid: o.state.datePaid,
      dateSent: o.state.dateSent,
      dateReceived: o.state.dateReceived,
      shippingMethod: o.shippingMethod?.name,
      trackingNumber: o.trackingNumber,
      temporaryEmail: o.temporaryEmail,
      isPresale: o.isPresale,
      shippingAddressName: o.shippingAddress?.name,
      shippingAddressExtra: escapeDoubleQuotes(o.shippingAddress?.extra),
      shippingAddressStreet: o.shippingAddress?.street,
      shippingAddressCity: o.shippingAddress?.city,
      shippingAddressZip: o.shippingAddress?.zip,
      shippingAddressCountry: o.shippingAddress?.country,
      phone: getPhoneNumber(o),
      buyerVAT: o.buyer.vat,
      updatedAt: moment(o.pt.updatedAt).format('DD.MM.YYYY HH:mm'),
      articleCategories: o.pt?.articleCategories?.join(', '),
      articles: o.articleCount,
      articleValue: o.articleValue,
      totalValue: o.totalValue,
      currencyCode: o.currencyCode,
      note: o.note,
      PID: o.pt?.picking?.pickingId,
      issues: getTextIssues(getIssues(o)),
    }
  }

  const handleOrdersDownload = (done: (proceed: boolean | undefined) => void): void => {
    setOrderData(
      orders.map((o) => {
        const row: CsvOrderItem = getRowFromOrder(o)
        return row
      })
    )
    if (window.Cypress) done(false)
    else done(true)
  }

  const handleFilteredOrdersDownload = (done: (proceed: boolean | undefined) => void): void => {
    setOrderData(
      filteredOrders.map((o) => {
        const row: CsvOrderItem = getRowFromOrder(o)
        return row
      })
    )
    if (window.Cypress) done(false)
    else done(true)
  }

  const handleArticlesDownload = (done: (proceed: boolean | undefined) => void): void => {
    setArticleData(
      articles.map((a) => {
        const row: CsvOrderArticleItem = {
          idOrder: a.idOrder,
          customer: a.buyer?.username,
          status: a.state?.state,
          pos: a.pos,
          items: a.count,
          category: '',
          expansion: escapeDoubleQuotes(a.product.expansion),
          name: escapeDoubleQuotes(a.product.locName),
          language: a.language.languageName,
          condition: a.condition,
          price: getPriceFormat(a.price, a.currencyCode),
          comments: a.comments,
          isFoil: a.isFoil,
          isSigned: a.isSigned,
          isPlayset: a.isPlayset,
          isAltered: a.isAltered,
          issues: getTextIssues(a.issues),
        }
        return row
      })
    )
    if (window.Cypress) done(false)
    else done(true)
  }

  const fileName = `${user?.cardmarketUsername}-${moment(Date.now()).format(
    'DD-MM-YYYY_HH-mm'
  )}.${fileFormat}`

  // const handleChangeFileFormat = (e: any) => {
  //   setFileFormat(e.target.value)
  // }

  return (
    <>
      {/* <FormLabel>
        <Typography>{t('orders.chooseFormat')}</Typography>
      </FormLabel>
      <RadioGroup value={fileFormat} onChange={handleChangeFileFormat}>
        <Box display="flex">
          {fileFormats.map((f) => (
            <FormControlLabel key={f} value={f} control={<Radio />} label={f} />
          ))}
        </Box>
      </RadioGroup> */}
      <Box marginY={2}>
        <CSVLink
          data={orderData}
          asyncOnClick={true}
          onClick={(_event, done) => {
            handleOrdersDownload(done)
          }}
          enclosingCharacter={`"`}
          filename={`orders-${fileName}`}
          style={{ textDecoration: 'none' }}
        >
          <Button fullWidth variant="contained" color="primary">
            {t('orders.downloadOrders')}
          </Button>
        </CSVLink>
      </Box>

      <Box marginY={2}>
        <CSVLink
          data={orderData}
          asyncOnClick={true}
          onClick={(_event, done) => {
            handleFilteredOrdersDownload(done)
          }}
          enclosingCharacter={`"`}
          filename={`filteredOrders-${fileName}`}
          style={{ textDecoration: 'none' }}
        >
          <Button fullWidth variant="contained" color="primary">
            {t('orders.downloadFilteredOrders')}
          </Button>
        </CSVLink>
      </Box>

      <Box marginTop={1}>
        <CSVLink
          data={articleData}
          asyncOnClick={true}
          onClick={(_event, done) => {
            handleArticlesDownload(done)
          }}
          enclosingCharacter={`"`}
          filename={`articles-${fileName}`}
          style={{ textDecoration: 'none' }}
        >
          <Button fullWidth variant="contained" color="primary">
            {t('orders.downloadArticles')}
          </Button>
        </CSVLink>
      </Box>
    </>
  )
}
