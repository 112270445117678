import { Box } from '@material-ui/core'
import { ClosableDialog } from 'components/controls/dialogs'
import { useUser } from 'components/providers/UserProvider'
import { useLocalForageState } from 'lib/hooks/useLocalForageState'
import { useStockStore } from 'lib/hooks/useStockStore'
import { useSnackbar } from 'notistack'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createInventoryArticle, InventoryArticle, ListingTab } from 'shared'
import { PublishDialog } from '../addArticles/PublishDialog'
import { EditArticleContext } from '../addArticles/useEditArticleContext'
import { InventoryPresenter, StockState } from '../stockPricing'
import { EditArticleModal } from './EditArticleModal'
import { ImportFileCsv } from './ImportFileCsv'

const resolveFn = (): unknown => undefined
const tabs: ListingTab[] = ['articles', 'failedAutopricing', 'readyToPublish', 'published']
export const ListingAndAppraisalPresenter = (): JSX.Element => {
  const [importFileDialogOpen, setImportFileDialogOpen] = useState(false)
  const [publishArticlesDialogOpen, setPublishArticlesDialogOpen] = useState(false)
  const [editArticleModalOpen, setEditArticleModalOpen] = useState(false)

  const [articleToEdit, setArticleToEdit] = useState<InventoryArticle | null>(null)
  const [editAfterAdding, setEditAfterAdding] = useState(false)
  const [newArticle, setNewArticle] = useState(false)
  const { user } = useUser()

  const [inventoryState, setInventoryState] = useLocalForageState<StockState>(
    { stock: [], sealedStock: [], fetchedAt: new Date().toLocaleString('sk-SK') },
    `${user?.cardmarketUsername}-file`,
    {
      duckFunction: (value) => value.stock.find((article) => article.card._id),
      hydrate: (value) => ({
        ...value,
        stock: value.stock.map((article) => createInventoryArticle(article)),
        sealedStock: value.sealedStock.map((article) => createInventoryArticle(article)),
      }),
    }
  )

  const activeStockType = useStockStore((state) => state.activeStockType)

  const setEditValue = useCallback((value: boolean) => setEditAfterAdding(value), [])
  const setIsNewArticle = useCallback((value: boolean) => setNewArticle(value), [])
  const articleRef = useRef<InventoryArticle | null>()
  const startEditingArticle = useCallback(
    (value: InventoryArticle, justAdded: boolean) => {
      if (articleToEdit) {
        enqueueSnackbar(t('addArticles.alreadyEditing'), { variant: 'error' })
        return false
      }
      setArticleToEdit(value)
      articleRef.current = value
      if (!justAdded) {
        setEditArticleModalOpen(true)
      }
      return true
    },
    [articleToEdit, articleRef]
  )

  const stopEditingArticle = useCallback(() => setArticleToEdit(null), [])

  const editArticle = useCallback(
    (newArticle) => {
      setInventoryState((stockState) => ({
        ...stockState,
        stock: stockState.stock.map((article) => {
          if (article.id === newArticle.id) {
            return newArticle
          }
          return article
        }),
        sealedStock: stockState.sealedStock.map((article) => {
          if (article.id === newArticle.id) {
            return newArticle
          }
          return article
        }),
      }))
    },
    [articleToEdit, setInventoryState]
  )

  const copyEditedArticle = useCallback(
    (newArticle) => {
      setInventoryState((stockState) => ({
        ...stockState,
        stock: [...stockState.stock, newArticle],
      }))
    },
    [articleToEdit, setInventoryState]
  )

  // const handleSave: ArticleEditHandleSave = async (newArticle) => {
  //   if (newArticle !== 'next' && newArticle !== 'previous') {
  //     setInventoryState((stockState) => ({
  //       ...stockState,
  //       stock: [...stockState.stock, newArticle],
  //     }))
  //     setIsNewArticle(false)
  //     stopEditingArticle()
  //     setEditArticleModalOpen(false)
  //   }
  // }

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const determineTab = useCallback((article: InventoryArticle): ListingTab => {
    if (article.failedAutopricing) return 'failedAutopricing'
    else if (article.readyToPublish) return 'readyToPublish'
    else if (article.published) return 'published'
    else return 'articles'
  }, [])

  const splitStockByTab = useCallback(
    (stock: InventoryArticle[]) => {
      const stockByTab: Record<ListingTab, InventoryArticle[]> = {
        articles: [],
        failedAutopricing: [],
        readyToPublish: [],
        published: [],
      }

      stock.forEach((article) => {
        stockByTab[determineTab(article)].push(article)
      })

      return stockByTab
    },
    [determineTab]
  )

  const handleArticlesPublished = (failed: InventoryArticle[]): void => {
    setInventoryState({
      ...inventoryState,
      stock: [
        ...inventoryState.stock.filter((article) => !article.selected),
        ...failed.map((article) => {
          article.readyToPublish = true
          return article
        }),
      ],
      sealedStock: [
        ...inventoryState.sealedStock.filter((article) => !article.selected),
        ...failed.map((article) => {
          article.readyToPublish = true
          return article
        }),
      ],
    })
  }

  const openPublishDialog = useCallback(() => {
    setPublishArticlesDialogOpen(true)
  }, [setPublishArticlesDialogOpen])

  return (
    <>
      <EditArticleContext.Provider
        value={{
          articleToEdit,
          startEditingArticle,
          stopEditingArticle,
          articleRef,
          editAfterAdding,
          setEditValue,
          newArticle,
          setIsNewArticle,
        }}
      >
        <InventoryPresenter<ListingTab>
          tabs={tabs}
          splitStockByTab={splitStockByTab}
          determineTab={determineTab}
          allowAddingArticles
          emptyStockText={t('stockPricing.noArticles')}
          stockState={inventoryState}
          setStockState={setInventoryState}
          openPublishDialog={openPublishDialog}
          hideExportButton
          hideSettingsButton
          parentPage="ListingAndAppraisal"
        />
        <ClosableDialog
          maxWidth="xl"
          fullWidth={true}
          onClose={() => {
            //@ts-ignore
            resolveFn([])
            setImportFileDialogOpen(false)
          }}
          open={importFileDialogOpen}
        >
          <Box minWidth={'780px'} margin={3}>
            <ImportFileCsv
              stockType={activeStockType}
              handleResult={(inventory) => {
                //@ts-ignore
                resolveFn(inventory)
              }}
              closeExternalDialog={setImportFileDialogOpen}
            />
          </Box>
        </ClosableDialog>
        {articleToEdit && (
          <EditArticleModal
            disableHotkeys={false}
            template={{ ...articleToEdit.toPlainObject() }}
            card={articleToEdit.card}
            editArticle={editArticle}
            copyEditedArticle={copyEditedArticle}
            open={editArticleModalOpen}
            setOpen={setEditArticleModalOpen}
            expansion={null}
          />
        )}
      </EditArticleContext.Provider>
      {publishArticlesDialogOpen && (
        <PublishDialog
          articles={
            activeStockType === 'singles'
              ? inventoryState.stock.filter((article) => article.selected)
              : inventoryState.sealedStock.filter((article) => article.selected)
          }
          onArticlesPublished={handleArticlesPublished}
          open={publishArticlesDialogOpen}
          setOpen={setPublishArticlesDialogOpen}
        />
      )}
    </>
  )
}
