import {
  Box,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core'
import { ViewIcon } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PricingJobType, PricingPlan, PricingResult } from 'shared'
import { SetState } from 'types'

interface PricingBotActivityPresenterProps {
  updates: PricingResult[]
  onViewUpdate: (updateId: string) => void
  activePlan?: PricingPlan
  selectedPlanId: string
  setSelectedPlanId: SetState<string>
  isRunning: boolean
  setIsRunning: SetState<string>
}

enum PriceUpdateTab {
  Nightly = 0,
  Test,
}

export const PriceUpdatesTable = ({
  updates,
  onViewUpdate,
}: {
  updates: PricingResult[]
  onViewUpdate: (updateId: string) => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Table style={{ maxWidth: '30rem' }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>{t('common.date')}</b>
          </TableCell>
          <TableCell>
            <b>{t('priceUpdates.programUsed')}</b>
          </TableCell>
          <TableCell align="center">
            <b>{t('priceUpdates.viewResults')}</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {updates.map((update) => (
          <TableRow key={update._id}>
            <TableCell>{moment(update.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{update.details?.pricingPlan?.name}</TableCell>
            <TableCell align="center">
              <IconButton size="small" onClick={() => onViewUpdate(update._id)}>
                <ViewIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const PricingBotActivityPresenter = ({
  updates,
  onViewUpdate,
  selectedPlanId,
  setSelectedPlanId,
  activePlan,
  isRunning,
  setIsRunning,
}: PricingBotActivityPresenterProps): JSX.Element => {
  const { user } = useUser()

  selectedPlanId.length
  setSelectedPlanId.length
  activePlan?.disallowNesting
  isRunning.valueOf()
  setIsRunning.length

  if (!user) return <></>

  const [activeTab, setActiveTab] = useState<PriceUpdateTab>(PriceUpdateTab.Nightly)

  const nightlyRuns = updates.filter(
    (update) => update.details?.type === PricingJobType.Nightly && update.status === 'published'
  )
  const testRuns = updates.filter(
    (update) => update.details?.type === PricingJobType.TestRun && update.status === 'done'
  )

  const numOfTestUpdates = testRuns.length
  useEffect(() => {
    if (isRunning) {
      setIsRunning('')
      setActiveTab(PriceUpdateTab.Test)
    }
  }, [numOfTestUpdates])

  const { t } = useTranslation()
  return (
    <Box>
      <Box>
        <Paper elevation={2}>
          <Tabs
            value={activeTab}
            onChange={(_e, newVal) => {
              setActiveTab(newVal)
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={`${t('priceUpdates.recentCompletedRuns')}`} />
            <Tab label={`${t('priceUpdates.recentTestRuns')}`} />
          </Tabs>
          <Box padding={2}>
            {activeTab === PriceUpdateTab.Nightly &&
              (nightlyRuns.length ? (
                <PriceUpdatesTable onViewUpdate={onViewUpdate} updates={nightlyRuns} />
              ) : (
                <Typography variant="caption" color="textSecondary">
                  {t('priceUpdates.noRecentCompletedRuns')}
                </Typography>
              ))}

            {activeTab === PriceUpdateTab.Test &&
              (testRuns.length ? (
                <PriceUpdatesTable onViewUpdate={onViewUpdate} updates={testRuns} />
              ) : (
                <Typography variant="caption" color="textSecondary">
                  {t('priceUpdates.noRecentTestRuns')}
                </Typography>
              ))}
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
