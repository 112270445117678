import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Typography,
  useTheme,
} from '@material-ui/core'
import { CustomTooltip } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { PricingPlanNode, PricingStrategy } from 'shared'
import { PricingStrategyBox } from '../../PricingSettings/PricingStrategyBox'
import { DraggableItemsEnum, PricingPlanContext } from '../PricingPlanPresenter'

interface StrategyChooserPresenterProps {
  nodeMethod: PricingPlanNode['method']
  changeNodeMethod: (newMethod: PricingPlanNode['method']) => void
  treeIndex?: number
}

export const StrategyChooserPresenter = ({
  nodeMethod,
  changeNodeMethod,
  treeIndex,
}: StrategyChooserPresenterProps): JSX.Element => {
  const { user } = useUser()
  const { isDraggingStrategy } = useContext(PricingPlanContext)
  const { palette } = useTheme()
  const { t } = useTranslation()
  const [{ isOverStrategy }, dropStrategy] = useDrop(
    () => ({
      accept: DraggableItemsEnum.Strategy,
      drop: (item: PricingStrategy) => {
        changeNodeMethod(item._id)
      },
      collect: (monitor) => ({
        isOverStrategy: !!monitor.isOver(),
      }),
    }),
    [nodeMethod]
  )

  const strategies = [...user?.pricingStrategies]

  if (nodeMethod?.includes('preview')) {
    strategies.push({ name: nodeMethod.slice(8), _id: nodeMethod } as PricingStrategy)
  }

  const chosenPricingStrategy = strategies.find(({ _id }) => _id === nodeMethod)

  return (
    <div ref={dropStrategy}>
      <Box
        minWidth="200px"
        padding={2}
        data-testid={`method-drop-${treeIndex}`}
        style={{
          border: isDraggingStrategy
            ? `2px dashed ${isOverStrategy ? palette.primary.main : palette.text.secondary}`
            : 'none',
        }}
      >
        <FormControl variant="outlined" style={{ width: '100%' }}>
          {nodeMethod === undefined && (
            <InputLabel id="demo-simple-select-label">Select a Strategy</InputLabel>
          )}
          <MuiSelect
            error={nodeMethod === undefined}
            value={
              nodeMethod === null
                ? 'ignore'
                : (chosenPricingStrategy?._id as string | undefined | null)
            }
            fullWidth
            onChange={(e) => {
              changeNodeMethod(
                (e.target.value === 'ignore' ? null : e.target.value) as string | null
              )
            }}
          >
            <MenuItem key={'ignore'} value={'ignore'}>
              <CustomTooltip title={t('priceUpdates.ignoreExplainer') as string}>
                <Typography style={{ textTransform: 'uppercase' }}>
                  {t('priceUpdates.ignore')}
                </Typography>
              </CustomTooltip>
            </MenuItem>
            {strategies.map((strategy) => (
              <MenuItem value={strategy._id} key={strategy._id}>
                <CustomTooltip
                  title={
                    <PricingStrategyBox
                      competitors={user?.competitors}
                      pricingStrategy={strategy}
                    />
                  }
                >
                  <Typography color="primary" style={{ textTransform: 'uppercase' }}>
                    <b>{strategy.name}</b>
                  </Typography>
                </CustomTooltip>
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
      </Box>
    </div>
  )
}
