import { Box } from '@material-ui/core'
import { EditPopper } from 'components/domain/stockPricing/PricingSettings/EditPopper'
import { ReactNode, useState } from 'react'

export interface OverlayMenuProps {
  children: ReactNode
  options: { value: number | string; label: string; onClick: () => void; disabled?: boolean }[]
}

export const OverlayMenu = ({ children, options }: OverlayMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  return (
    <>
      <Box
        onClick={(e) => setAnchorEl(e.currentTarget as HTMLElement)}
        style={{ cursor: 'pointer' }}
      >
        {children}
      </Box>
      {anchorEl && (
        <EditPopper
          onDone={() => setAnchorEl(null)}
          options={options}
          anchorEl={anchorEl}
          onChange={(chosenValue) => {
            options.find(({ value }) => value === chosenValue)?.onClick()
            setAnchorEl(null)
          }}
        />
      )}
    </>
  )
}
