import { Box, Typography } from '@material-ui/core'
import { InventoryPresenter } from 'components/domain/stockPricing'
import { CloseIcon } from 'components/facets'
import { useIntroSnackbar } from 'lib/hooks/useIntroSnackbar'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InventoryArticle, PricedInventoryArticle, PricingJobType, UpdatesTab } from 'shared'

export interface PriceUpdatePresenterProps {
  articles: InventoryArticle[]
  loading: boolean
  updateType?: PricingJobType
  onClose: () => void
  updateId?: string
}

const IntroContent = ({ updateType }: { updateType?: PricingJobType }) => {
  const { t } = useTranslation()

  if (updateType === PricingJobType.Nightly) {
    return (
      <Box>
        <Box>
          <Typography variant="body1">
            <b>{t('priceUpdates.stockUpdated')}</b>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" gutterBottom>
            {t('priceUpdates.stockUpdateHowTo')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('priceUpdates.stockUpdateHowTo2')}
          </Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <Box>
        <Typography variant="body1">
          <b>{t('priceUpdates.testRunDone')}</b>
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" gutterBottom>
          {t('priceUpdates.testRunHowTo')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t('priceUpdates.testRunHowTo2')}
        </Typography>
      </Box>
    </Box>
  )
}

export const PriceUpdatePresenter = React.memo(
  ({ articles, onClose, updateType, updateId }: PriceUpdatePresenterProps): JSX.Element => {
    const { t } = useTranslation()
    useIntroSnackbar(`price-update-intro-${updateType}`, <IntroContent updateType={updateType} />)

    const [stockState, setStockState] = useState({
      stock: articles,
      sealedStock: [] as InventoryArticle[],
      fetchedAt: new Date().toLocaleString('sk-SK'),
    })

    useEffect(() => {
      setStockState({
        stock: articles,
        sealedStock: [],
        fetchedAt: new Date().toLocaleString('sk-SK'),
      })
    }, [updateId])

    const determineTab = useCallback(
      (article: InventoryArticle | PricedInventoryArticle): UpdatesTab => {
        if (article instanceof PricedInventoryArticle && article.priceReport?.failure) {
          if (article.priceReport?.failure === 'overLimit') return 'overLimit'
          else return 'failedAutopricing'
        } else if (article.isEdited()) return 'updated'
        else return 'notUpdated'
      },
      []
    )

    const splitStockByTab = useCallback(
      (stock: InventoryArticle[]) => {
        const stockByTab: Record<UpdatesTab, InventoryArticle[]> = {
          updated: [],
          overLimit: [],
          notUpdated: [],
          failedAutopricing: [],
        }

        stock.forEach((article) => {
          stockByTab[determineTab(article)].push(article)
        })

        return stockByTab
      },
      [determineTab]
    )

    const extraActions = useMemo(
      () => [
        {
          Icon: CloseIcon,
          fn: onClose,
          tooltip: () => <>{t('common.close')}</>,
          actionLabel: t('common.close'),
          key: 'close-stock-drawer-btn',
        },
      ],
      [onClose]
    )

    return (
      <InventoryPresenter<UpdatesTab>
        viewOnly
        tabs={['updated', 'overLimit', 'failedAutopricing', 'notUpdated']}
        determineTab={determineTab}
        splitStockByTab={splitStockByTab}
        emptyStockText={t('priceUpdates.noArticles')}
        stockState={stockState}
        setStockState={setStockState}
        hideSettingsButton
        extraDockActions={extraActions}
      />
    )
  }
)
