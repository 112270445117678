import localForage from 'localforage'

localForage.config({
  name: 'Powertools-v2',
  version: 2,
  storeName: 'store',
  description: 'Powertools user data',
})

export const localDB = localForage

export const cardDataDB = localForage.createInstance({
  name: 'Powertools-card-data',
  storeName: 'store',
  description: 'Powertools card data',
})
