import { Box, makeStyles, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { Button, CustomTooltip } from 'components/facets'
import { useOrderStore } from 'lib/hooks'
import { useTranslation } from 'react-i18next'
import { PickingList } from 'shared'

export interface PickingListBoxProps {
  title?: string
  subtitle?: string
  pickingList?: PickingList
  automaticList?: boolean
  handleShowDeletePickingListDialog?: (pickingList: PickingList) => void
  handleNewAutomaticList?: () => void
}

export const PickingListBox = ({
  title,
  subtitle,
  pickingList,
  automaticList,
  handleShowDeletePickingListDialog,
  handleNewAutomaticList,
}: PickingListBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const setActiveTab = useOrderStore((state) => state.setActiveTab)
  const setActivePickingList = useOrderStore((state) => state.setActivePickingList)

  const useStyles = makeStyles(() => ({
    box: {
      backgroundColor: '#ececee',
      width: '250px',
      height: '200px',
      borderRadius: '10px',
    },
  }))
  const styles = useStyles()

  const listName = pickingList?.name
  const numOrders = pickingList?.numOrders
  const numArticles = pickingList?.numArticles

  const handleStartPickingItems = () => {
    setActivePickingList(pickingList)
  }

  const handleNewListManualSelection = () => {
    setActiveTab(1)
  }

  return (
    <>
      <Box className={styles.box} paddingY={2}>
        {title && (
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="h6" color="primary">
            {subtitle}
          </Typography>
        )}
        {listName && (
          <Typography variant="h4" color="primary">
            List {listName}
          </Typography>
        )}
        {numOrders && numArticles && (
          <Typography color="secondary">
            {numOrders} orders | {numArticles} articles
          </Typography>
        )}
        {listName && (
          <Box paddingTop={1}>
            <Typography color="secondary" variant="body2">
              Created {new Date(pickingList.createdAt).toLocaleString()}
            </Typography>
          </Box>
        )}
        <Box paddingTop={1}>
          {listName && (
            <CustomTooltip title="Delete picking list and revert all associated orders to the 'Paid' state">
              <Delete
                onClick={() => handleShowDeletePickingListDialog?.(pickingList)}
                style={{ cursor: 'pointer' }}
              />
            </CustomTooltip>
          )}
        </Box>
        <Box paddingTop={1}>
          {listName && (
            <>
              <Button variant="contained" onClick={handleStartPickingItems}>
                {t('orders.picking.startPickingShort')}
              </Button>
            </>
          )}
          {title &&
            (automaticList ? (
              <Button variant="contained" onClick={handleNewAutomaticList}>
                {t('orders.picking.createList')}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleNewListManualSelection}>
                {t('orders.picking.createList')}
              </Button>
            ))}
        </Box>
      </Box>
    </>
  )
}
