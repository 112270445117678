import { useCardData } from 'components/providers/CardDataProvider'
import { getUserStock } from 'lib/api'
import { useCallback, useMemo } from 'react'
import { Article, Card, IdGame, InventoryArticle } from 'shared'

export const useFetchUserStock = () => {
  const cardData = useCardData()

  // const cardDataSorted = useMemo(() => cardData.sort((a, b) => a._id - b._id), [cardData])

  const cardDataMap = useMemo(() => {
    const map = new Map<number, Card>()
    for (const card of cardData) {
      map.set(card._id, card)
    }
    return map
  }, [cardData])

  const fetchUserStock = useCallback(
    async (idGame: IdGame, sealed = false, genericSealed = false) => {
      const data = await getUserStock(idGame, sealed, genericSealed)

      const result: InventoryArticle[] = []
      // let cnt = 0
      // for (const card of cardDataSorted) {
      //   if (cnt >= data.length) break
      //   while (cnt < data.length && new Article({ attributes: data[cnt] }).idProduct === card._id) {
      //     result.push(new InventoryArticle({ attributes: data[cnt], card: card.toPlainObject() }))
      //     cnt++
      //   }
      // }

      for (const d of data) {
        const article = new Article({ attributes: d })
        const card = cardDataMap.get(article.idProduct)
        if (card) {
          result.push(new InventoryArticle({ attributes: d, card: card.toPlainObject() }))
        }
      }

      return result
    },
    [cardData]
  )

  return fetchUserStock
}
