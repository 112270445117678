import { Box, FormControlLabel, MenuItem, Select, Switch, Typography } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { ClosableDialog } from 'components/controls/dialogs'
import { Button } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { SetLocalStorageValueFunction, useScopelessHotkeys } from 'lib/hooks'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CARD_LANGUAGES, Template } from 'shared'
import { SetState } from 'types'
import { CONDITIONS } from 'utils/constants'

interface ArticleTemplateDialogProps {
  open: boolean
  setOpen: SetState<boolean>
  template: Template
  setTemplate: SetLocalStorageValueFunction<Template>
}

export const ArticleTemplateDialog = ({
  open,
  setOpen,
  template,
  setTemplate,
}: ArticleTemplateDialogProps): JSX.Element => {
  const { activeGame } = useUser()
  const { t } = useTranslation()
  const [inputs, setInputs] = useState<Template>(template)
  const { enqueueSnackbar } = useSnackbar()
  const quantityCondition = inputs.quantity < 1 || inputs.quantity > 255 ? true : false

  const handleClose = (): void => {
    setOpen(false)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: React.ChangeEvent<any>): void => {
    if (e.target.type === 'checkbox') {
      setInputs({ ...inputs, [e.target.name]: e.target.checked })
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value })
    }
  }

  const handleSave = (): void => {
    setTemplate(inputs)
    handleClose()

    enqueueSnackbar(t('template.successSet'), {
      variant: 'success',
    })
  }

  useScopelessHotkeys(
    'alt+enter',
    () => {
      if (!quantityCondition) handleSave()
    },
    [inputs]
  )

  const ExtraAttributes = (): JSX.Element => {
    return (
      <>
        {activeGame.extraAttr.map((attribute) => {
          return (
            <FormControlLabel
              key={attribute}
              control={<Switch color="primary" />}
              label={t(`common.${attribute}`)}
              labelPlacement="end"
              name={attribute}
              checked={!!inputs[attribute as keyof typeof template]}
              onChange={handleChange}
            />
          )
        })}
      </>
    )
  }

  return (
    <ClosableDialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t('template.setTitle')}</DialogTitle>
      <DialogContent>
        <Box data-testid="template-form" style={{ width: '600px', margin: '0 auto' }}>
          <TextField
            value={inputs.quantity}
            onChange={handleChange}
            margin="dense"
            label={t('card.quantity')}
            type="number"
            name="quantity"
            fullWidth
          />
          <TextField
            value={inputs.price}
            onChange={handleChange}
            margin="dense"
            label={t('card.price')}
            type="number"
            name="price"
            fullWidth
          />
          <Select fullWidth value={inputs.condition} name="condition" onChange={handleChange}>
            {CONDITIONS.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <Select fullWidth value={inputs.idLanguage} name="idLanguage" onChange={handleChange}>
            {CARD_LANGUAGES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <ExtraAttributes />

          <TextField
            value={inputs.comment}
            onChange={handleChange}
            name="comment"
            margin="dense"
            label={t('card.comment')}
            type="text"
            fullWidth
          />
        </Box>

        <Box padding={1} margin={3} bgcolor="#4c00a32b" width="600px" style={{ margin: '0 auto' }}>
          <Typography>{t('template.helper')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          hotkey="Alt+Enter"
          variant="contained"
          onClick={() => handleSave()}
          color="primary"
          data-testid="set-template-btn"
          disabled={quantityCondition}
        >
          {t('template.set')}
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}
