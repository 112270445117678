import {
  Box,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { VisualArticle } from 'components/domain/stockPricing/StockDisplay/VisualArticle'
import { HotkeyLegend } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useHotkeyConfig, useScopelessHotkeys } from 'lib/hooks'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardDataItem,
  CardLanguage,
  Expansion,
  InventoryArticle,
  LocalizedCard,
  RichArticle,
  sleep,
  Template,
} from 'shared'
import { v4 as uuid } from 'uuid'

export type ArticleEditHandleSave = (
  article: InventoryArticle | 'previous' | 'next',
  isCopied?: boolean
) => void

interface ArticleEditProps {
  addByExp?: boolean
  disableHotkeys: boolean
  template: Template
  card: CardDataItem
  newPrice: number | undefined
  handleSave: ArticleEditHandleSave
  stopEdit: () => void
  onInputChange?: (newValue: RichArticle | null) => void
  showExistingStock?: boolean
  fixedLegendPosition?: [x: number, y: number]
  expansion: (Expansion & { name: string }) | null
  counter?: number
  setCounter?: (value: number) => void
  cardsInExpansion?: LocalizedCard[]
}

export const ArticleEdit = ({
  addByExp,
  disableHotkeys,
  template,
  card,
  newPrice,
  handleSave,
  stopEdit,
  onInputChange,
  showExistingStock = true,
  expansion,
  counter,
  setCounter,
  cardsInExpansion,
}: ArticleEditProps): JSX.Element => {
  const { t } = useTranslation()
  const hotkeys = useHotkeyConfig()

  const { palette } = useTheme()

  const textFieldRef = useRef<HTMLInputElement | undefined>()

  const [hasFocus, setHasFocus] = useState(false)
  const { user } = useUser()
  const templateValues = template

  const initialInputs = new RichArticle({
    card,
    ...templateValues,
    isFoil: card.onlyFoil ? true : templateValues.isFoil ? true : false,
    idArticle: templateValues.id ?? uuid(),
  })

  const [inputs, setInputs] = useState<RichArticle>(initialInputs)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setInputs(initialInputs)
    onInputChange?.(initialInputs)
  }, [card])

  useEffect(() => {
    textFieldRef.current?.focus()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): Promise<void> | void => {
    const newInputs = inputs.copy()

    newInputs.setAttribute(
      e.target.name,
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    )

    onInputChange?.(newInputs)

    setInputs(newInputs)
  }

  const hotkeyCallback = (e: KeyboardEvent, fn: () => void, force = false): void => {
    if ((!disableHotkeys && document.activeElement === textFieldRef.current) || force) {
      e.preventDefault()
      e.stopPropagation()
      fn()
    }
  }

  const set = (attribute: keyof typeof template, value: string | number | boolean): void => {
    if (card.onlyFoil && attribute === 'isFoil') return
    const newInputs = inputs.copy()

    newInputs.setAttribute(attribute, value === 'toggle' ? !inputs[attribute] : value)

    onInputChange?.(newInputs)
    setInputs(newInputs)
  }

  const changeQuantity = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (Number.isInteger(Number(e.target.value))) handleChange(e)
  }

  for (const hotkey of hotkeys) {
    const [key, parameter, value] = hotkey

    let parsedValue: string | number = value
    let parsedParameter: string = parameter
    if (parameter === 'language') {
      parsedValue = CardLanguage[value as keyof typeof CardLanguage]
      parsedParameter = 'idLanguage'
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScopelessHotkeys(
      key,
      (e: KeyboardEvent) =>
        hotkeyCallback(e, () => set(parsedParameter as keyof typeof template, parsedValue)),
      [inputs, disableHotkeys, initialInputs]
    )
  }
  useScopelessHotkeys(
    'Delete',
    (e) =>
      hotkeyCallback(e, () => {
        stopEdit()
      }),
    [inputs, disableHotkeys, initialInputs]
  )

  const confirmChanges = (e: KeyboardEvent) => {
    hotkeyCallback(e, () => {
      const quantity = Math.ceil(Number(inputs.quantity))
      if (!quantity || quantity < 1) return

      handleSave(
        new InventoryArticle({
          ...inputs,
          quantity,
          newPrice: newPrice,
        })
      )
      // if (!editAfterAdding) {
      //   const quantity = Math.ceil(Number(inputs.quantity))
      //   if (!quantity || quantity < 1) return

      //   handleSave(
      //     new InventoryArticle({
      //       ...inputs,
      //       quantity,
      //     })
      //   )

      //   setInputs(
      //     new RichArticle({
      //       ...initialInputs,
      //       idArticle: uuid(),
      //     })
      //   )
      // } else if (setEditValue) {
      // setEditValue?.(false)
      // }
    })
  }

  useScopelessHotkeys('Space', (e) => confirmChanges(e), [inputs, disableHotkeys, initialInputs])

  useScopelessHotkeys('Enter', (e) => confirmChanges(e), [inputs, disableHotkeys, initialInputs])

  useScopelessHotkeys(
    'c',
    (e) =>
      hotkeyCallback(e, () => {
        const quantity = Math.ceil(Number(inputs.quantity))
        if (!quantity || quantity < 1) return
        handleSave(
          new InventoryArticle({
            ...inputs.toPlainObject(),
            quantity,
            price: initialInputs.price,
            comment: inputs.comment,
            idArticle: uuid(),
          }),
          true
        )
        const newInputs = new RichArticle({
          ...inputs.toPlainObject(),
          price: initialInputs.price,
          idArticle: uuid(),
        })

        setInputs(newInputs)
      }),
    [inputs, disableHotkeys, initialInputs]
  )

  if (addByExp) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScopelessHotkeys('b', (e) => hotkeyCallback(e, () => handleSave('next')), [
      inputs,
      disableHotkeys,
    ])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScopelessHotkeys('v', (e) => hotkeyCallback(e, () => handleSave('previous')), [
      inputs,
      disableHotkeys,
    ])
  }

  // this is for limiting the use of tab to the quantity and comment fields
  const focusableEls = [
    document.getElementById('article-edit-quantity'),
    document.getElementById('article-edit-comment'),
  ]
  const firstFocusableEl = focusableEls[0]
  const lastFocusableEl = focusableEls[focusableEls.length - 1]
  const KEYCODE_TAB = 9
  document.getElementById('article-edit-container')?.addEventListener('keydown', function (e) {
    if (e.key === 'Tab' || e.keyCode === KEYCODE_TAB) {
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl?.focus()
          e.preventDefault()
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl?.focus()
          e.preventDefault()
        }
      }
    }
  })

  return (
    <Box
      id="article-edit-container"
      key={
        //forces rerender when
        disableHotkeys ? 'lol' : 'mol'
      }
      margin="0 auto"
    >
      <Grid container justifyContent="center">
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{ alignSelf: 'center' }}>
          <HotkeyLegend
            article={inputs}
            onClick={() => textFieldRef.current?.focus()}
            addByExp={addByExp}
            disabled={!hasFocus}
            setInputs={setInputs}
            showExistingStock={!showExistingStock}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          {addByExp && expansion && counter !== undefined && cardsInExpansion && setAnchorEl && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box>
                <Typography>
                  {counter + 1}/{cardsInExpansion.length}
                </Typography>
              </Box>
              <Box marginLeft={2} width="200px">
                <Paper>
                  <Autocomplete<CardDataItem & { index: number }>
                    options={cardsInExpansion.map((card, index) => ({
                      ...card.toPlainObject(),
                      index,
                    }))}
                    //renderOption={(option) => option.name}
                    value={null}
                    autoHighlight={true}
                    data-testid="add-by-expansion-input"
                    getOptionLabel={(option) => `${option.name} ${option.cn ? option.cn : ''}`}
                    onChange={async (_e, newValue) => {
                      await sleep(50)

                      setCounter?.(newValue?.index || 0)
                      setAnchorEl(null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        fullWidth
                        name={'jump-to-card-input'}
                        variant="outlined"
                        onFocus={(event) => {
                          event.target.select()
                        }}
                        label={t('addArticles.jumpTo')}
                      />
                    )}
                  />
                </Paper>
              </Box>
              {/* <CustomTooltip title={t('addArticles.jumpTo') as string}>
                <IconButton
                  data-testid="jump-to-btn"
                  size="small"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <JumpToIcon />
                </IconButton>
              </CustomTooltip> */}
            </Box>
          )}
          <Box data-testid="article-being-added-box">
            <Box margin="0">
              <Typography style={{ textAlign: 'center', display: 'block' }} variant="h6">
                {card.name}
              </Typography>
              <Typography style={{ textAlign: 'center', display: 'block' }} variant="h6">
                [{(card.expansion as unknown as Expansion)?.name || card.expansion}]
              </Typography>
              <Box
                width={`${250}px`}
                height={`${250 * 1.39}px`}
                onClick={() => textFieldRef.current?.focus()}
                margin="0"
              >
                <VisualArticle
                  displayOnly
                  rowHeight={250 * 1.39}
                  article={new InventoryArticle({ ...inputs })}
                  currency={user?.currency}
                  parentEl="ArticleEdit"
                />
              </Box>
            </Box>
            <TextField
              id="article-edit-quantity"
              key={inputs.id}
              autoFocus
              inputRef={textFieldRef}
              onFocus={(e) => {
                e.target.select()
                setHasFocus(true)
              }}
              onBlur={() => setHasFocus(false)}
              value={inputs.quantity}
              onChange={changeQuantity}
              margin="none"
              label={t('card.quantity')}
              name="quantity"
              fullWidth
              InputProps={{ inputProps: { min: 1 }, autoComplete: 'off' }}
              error={inputs.quantity <= 0}
              data-testid="quanty-input-add-new-article"
              helperText={
                hasFocus ? (
                  t('addArticles.hotkeysActive')
                ) : (
                  <span style={{ color: palette.primary.main }}>
                    <b>{t('addArticles.clickFieldToEditArticle')}</b>
                  </span>
                )
              }
            />
            <TextField
              id="article-edit-comment"
              value={inputs.comment}
              onChange={handleChange}
              margin="none"
              label={t('card.comment')}
              name="comment"
              fullWidth
              data-testid="comment-input-add-new-article"
            />
          </Box>
        </Grid>
      </Grid>

      {anchorEl && cardsInExpansion && (
        <ClickAwayListener
          disableReactTree={true}
          onClickAway={() => {
            setAnchorEl(null)
          }}
        >
          <Popper open={!!anchorEl} anchorEl={anchorEl} transition>
            <Box width="200px">
              <Paper>
                <Autocomplete<CardDataItem & { index: number }>
                  options={cardsInExpansion.map((card, index) => ({
                    ...card.toPlainObject(),
                    index,
                  }))}
                  //renderOption={(option) => option.name}
                  value={null}
                  autoHighlight={true}
                  data-testid="add-by-expansion-input"
                  getOptionLabel={(option) => `${option.name} ${option.cn ? option.cn : ''}`}
                  onChange={async (_e, newValue) => {
                    await sleep(50)

                    setCounter?.(newValue?.index || 0)
                    setAnchorEl(null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      fullWidth
                      name={'jump-to-card-input'}
                      variant="outlined"
                      onFocus={(event) => {
                        event.target.select()
                      }}
                    />
                  )}
                />
              </Paper>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  )
}
