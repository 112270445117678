import { OrdersPresenter } from 'components/domain/orders'
import { AppLayout } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const Orders = (): JSX.Element => {
  const { user } = useUser()

  if (!user || user?.subscription.allFeaturesDisabled || user?.featureFlags?.banned) {
    return <Redirect to="/" />
  }

  return (
    <AppLayout noDOMLayout>
      <OrdersPresenter />
    </AppLayout>
  )
}
