import { Box, Menu, MenuItem, Typography } from '@material-ui/core'
import { CustomTooltip } from 'components/facets'
import { useUser } from 'components/providers/UserProvider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCardmarketConnect } from '.'

interface ConditionMakerPresenterProps {
  helo?: boolean
}

export const CardmarketSidebarPresenter = ({}: ConditionMakerPresenterProps): JSX.Element => {
  const { user } = useUser()

  const [connectCardmarketAccount, Popup] = useCardmarketConnect()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const hasMultipleAccounts = user.assignedCardmarketAccounts.length > 1
  const { t } = useTranslation()
  return (
    <>
      <CustomTooltip
        title={hasMultipleAccounts ? (t('connectCardmarket.clickToSwitch') as string) : ''}
      >
        <Box
          data-testid="cardmarket-account-menu"
          style={{ cursor: hasMultipleAccounts ? 'pointer' : 'initial' }}
          onClick={hasMultipleAccounts ? (e) => setAnchorEl(e.currentTarget) : () => undefined}
        >
          <Typography
            style={{ color: user.cardmarketUsername ? 'white' : 'red' }}
            variant="caption"
          >
            <b>{user.cardmarketUsername || 'No Account Connected!'}</b>
          </Typography>
          <Popup />
        </Box>
      </CustomTooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {user.assignedCardmarketAccounts.map((account) => (
          <MenuItem
            key={account.username}
            onClick={() => {
              setAnchorEl(null)

              connectCardmarketAccount(account.username)
            }}
          >
            <Typography>{account.username}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
