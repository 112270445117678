export const Condition = ({
  id,
  value,
  noPadding,
}: {
  id?: string
  value: string
  noPadding?: boolean
}): JSX.Element => {
  const CONDITION_COLOR: Record<string, string> = {
    MT: 'darkgreen',
    NM: 'lightgreen',
    EX: 'gold',
    GD: 'goldenrod',
    LP: 'orange',
    PL: 'brown',
    PO: 'maroon',
  }

  return (
    <span
      id={id}
      style={{
        color: `${CONDITION_COLOR[value]}`,
        padding: `${noPadding ? '0px' : '0px 5px'}`,
        fontWeight: 'bold',
      }}
    >
      {value}
    </span>
  )
}
