import { Box, makeStyles } from '@material-ui/core'
import { useOrderStore } from 'lib/hooks'
import { useEffect, useState } from 'react'
import { AutoSizer } from 'react-virtualized'
import { FixedSizeList } from 'react-window'
import { OrderArticle } from 'shared'
import { OrderVisualArticle } from './OrderVisualArticle'

export interface PickingImageDisplayProps {
  type: string
  handlePick?: (article: OrderArticle, quantity: number) => void
  handleCompletePicking?: (article: OrderArticle, quantity: number) => void
  handleReportIssue?: (article: OrderArticle) => void
  handleBackToPicking?: (article: OrderArticle) => void
  horizontalView: boolean
  oneClickPick: boolean
}

export const PickingImageDisplay = ({
  type,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
  handleBackToPicking,
  horizontalView,
  oneClickPick,
}: PickingImageDisplayProps): JSX.Element => {
  const articlesToPick = useOrderStore((state) => state.articlesToPick)
  const articlesPicked = useOrderStore((state) => state.articlesPicked)
  const articlesWithIssues = useOrderStore((state) => state.articlesWithIssues)

  const cardWidth = 212
  const spacing = 50
  const cardWithHorizontal = 380
  const itemWidth = (horizontalView ? cardWithHorizontal : cardWidth) + spacing
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  let articles: OrderArticle[] = []

  useEffect(() => {
    function updateWindowDimensions() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  if (type === 'toPick') {
    articles = articlesToPick
  } else if (type === 'picked') {
    articles = articlesPicked
  } else if (type === 'withIssues') {
    articles = articlesWithIssues
  }

  const useStyles = makeStyles(() => ({
    toPick: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: 'white',
    },
    picked: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#70bf4c',
      color: 'white',
      width: 'calc(100vw - 300px) !important',
    },
    withIssues: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#f15e3e',
      color: 'white',
      width: 'calc(100vw - 300px) !important',
    },
    imageList: {
      flexWrap: 'nowrap',
      padding: '20px',
    },
  }))
  const styles = useStyles()

  return (
    <div
      style={{ height: horizontalView ? '275px' : '600px', width: '100%' }}
      className={styles[type as keyof typeof styles]}
    >
      <AutoSizer>
        {({ height }) => (
          <FixedSizeList
            layout="horizontal"
            height={height}
            itemCount={articles.length}
            itemSize={itemWidth}
            itemData={articlesToPick}
            width={windowWidth - 320}
          >
            {({ index, style }: { index: number; style: any }) => {
              return (
                <Box style={style}>
                  {type === 'toPick' && (
                    <OrderVisualArticle
                      article={articles[index]}
                      handlePick={handlePick}
                      handleCompletePicking={handleCompletePicking}
                      handleReportIssue={handleReportIssue}
                      horizontalView={horizontalView}
                      hideBar
                      type="toPick"
                      oneClickPick={oneClickPick}
                    />
                  )}

                  {type === 'picked' && (
                    <OrderVisualArticle
                      article={articles[index]}
                      handleBackToPicking={handleBackToPicking}
                      horizontalView={horizontalView}
                      hideBar
                      type="picked"
                      oneClickPick={oneClickPick}
                    />
                  )}

                  {type === 'withIssues' && (
                    <OrderVisualArticle
                      article={articles[index]}
                      handleBackToPicking={handleBackToPicking}
                      horizontalView={horizontalView}
                      hideBar
                      type="withIssues"
                      oneClickPick={oneClickPick}
                    />
                  )}
                </Box>
              )
            }}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  )
}
