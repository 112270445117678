import { IconButton } from '@material-ui/core'
import { CustomTooltip, RestartIcon } from 'components/facets'
import { useTranslation } from 'react-i18next'

interface RestartButtonProps {
  onClick: () => void
  dataTestid?: string
}

export const RestartButton = ({ onClick, dataTestid }: RestartButtonProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <CustomTooltip enterDelay={300} interactive title={t('common.startAgain') as string}>
      <IconButton style={{ marginBottom: '10px' }} onClick={onClick} data-testid={dataTestid}>
        <RestartIcon fontSize={'large'} color={'primary'} />
      </IconButton>
    </CustomTooltip>
  )
}
